import { workshopModuleTagApi } from '../../../../entities/workshop-tag';
import { workshopModuleApi } from '../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../shared/libs/redux';

export const useBindWorkshopModuleTag = (moduleId?: string) => {
	const dispatch = useAppDispatch();

	const [bindTag] = workshopModuleTagApi.useBindMutation();

	const onBindTag = async ({ id }: {id: number}) => {
		if (id && moduleId) {
			await bindTag({ moduleId, tags: [id] }).unwrap();

			dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));
		}
	};

	return { onBindTag };
};
