import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import IComponentStructure from '../../components/IComponentStructure';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import FigureInjectArea from './FigureInjectArea';
import CSSCursor from '../../cursor/CSSCursor';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IFigureTexture from '../../graphic/figure/IFigureTexture';
import FigureComponent from '../../components/figure/FigureComponent';
import BorderStyle from '../../graphic/figure/BorderStyle';
import FigureGraphic from '../../graphic/figure/FigureGraphic';

interface IFigureDefaultStructureProps {
	x: number,
	y: number,
	width: number,
	height: number,
	componentOffset: number,
}

export interface IFigureInjectorDependencies extends IComponentInjectorDependencies {}

/**
 * Инжектор для добавления фигур в структуру.
 */
class FigureInjector extends ConstructorInjector<IFigureInjectorDependencies> {
	private readonly DEFAULT_WIDTH = 200;
	private readonly DEFAULT_HEIGHT = 200;
	private readonly MIN_CUSTOM_WIDTH = 21;
	private readonly MIN_CUSTOM_HEIGHT = 21;

	private readonly figureInjectArea: FigureInjectArea;

	constructor() {
		super();
		this.figureInjectArea = new FigureInjectArea();

		this.addPostInjectDependenciesListener(dependencies => {
			const rootElement = dependencies.componentTree.getWorkAreaElement();
			this.figureInjectArea.connectDependencies({
				rootElement,
				mousePositionObserver: this.dependencies.mousePositionObserver,
			});
			this.figureInjectArea.injectDependencies();
		});
	}

	/**
	 * Запускает инжекцию фигуры.
	 */
	public run = (): void => {
		// Включаем режим вставки компонента
		this.dependencies.cursorView.enableComponentInjectMode();
		// Устанавливаем курсор в режим определения области
		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		// Устанавливаем флаг процесса вставки
		this.isProcess = true;
		// Отключаем готовность к вставке (для реагирования на `onMouseUp`)
		this.setNotReadyInject();
	};

	/**
	 * Инжектирует фигуру в структуру.
	 */
	public inject = (): void => {
		const isReady = this.isReadyInject();
		if (!isReady) {
			throw new ManipulatorError('injector not ready inject component');
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();

		// Получаем область вставки фигуры
		const area = this.figureInjectArea.getArea();
		const injectParams = this.calculateAreaComponentInjectionParams(area);
		const isDefaultSize = area.height === 0 || area.width === 0;
		let width: number;
		let height: number;
		if (isDefaultSize) {
			width = this.DEFAULT_WIDTH;
			height = this.DEFAULT_HEIGHT;
		} else {
			const heightSmallerThenMin = area.height < this.MIN_CUSTOM_HEIGHT;
			const widthSmallerThenMin = area.width < this.MIN_CUSTOM_WIDTH;

			if (heightSmallerThenMin && widthSmallerThenMin) {
				height = this.MIN_CUSTOM_HEIGHT;
				width = this.MIN_CUSTOM_WIDTH;
			} else if (widthSmallerThenMin) {
				height = area.height;
				width = this.MIN_CUSTOM_WIDTH;
			} else if (heightSmallerThenMin) {
				height = this.MIN_CUSTOM_HEIGHT;
				width = area.width;
			} else {
				height = area.height;
				width = area.width;
			}
		}

		const x = area.x - injectParams.x;
		const y = area.y - injectParams.y;

		const structure = this.getDefaultStructure({
			x,
			y,
			width,
			height,
			componentOffset: injectParams.componentOffset,
		});

		let figureComponent: FigureComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			figureComponent = tools.componentFactory.createComponent<FigureComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<FigureGraphic>(GraphicType.FIGURE, figureComponent);
				graphic.setStructure(() => graphicStructure);
				figureComponent.appendGraphic(graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, figureComponent);

			this.callPostInjectListeners(figureComponent);
		});

		this.stop();
		this.callSingleUseInjectListeners(figureComponent!);
	};

	/**
	 * Останавливает инжекцию фигуры.
	 */
	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.isProcess = false;
		this.figureInjectArea.clearArea();
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 */
	public override onMouseDown = () => {
		const position = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.figureInjectArea.start(position);
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 */
	public override onMouseUp = () => {
		this.setReadyInject();
		this.inject();
		this.figureInjectArea.stop();
	};

	/**
	 * Возвращает структуру фигуры по умолчанию.
	 */
	protected getDefaultStructure = (props: IFigureDefaultStructureProps): IComponentStructure<null> => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		return {
			id: componentId,
			type: SketchComponentType.FIGURE,
			offset: props.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.FIGURE,
					frame: {
						x: props.x,
						y: props.y,
						width: props.width,
						height: props.height,
						rotate: 0,
						layer: 100,
					},
					offset: 0,
					texture: {
						background: '#ffffff',
						borderColor: '#000',
						borderWidth: 1,
						borderStyle: BorderStyle.SOLID,
						radius: {
							topLeft: 0,
							topRight: 0,
							bottomLeft: 0,
							bottomRight: 0,
						},
					} as IFigureTexture,
				},
			],
			texture: null,
			components: null,
		};
	};
}

export default FigureInjector;
