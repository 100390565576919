import SpatialArea from '../SpatialArea';
import SpatialAreaType from '../SpatialAreaType';
import IFrameArea from '../IFrameArea';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import ManipulatorError from '../../../../../utils/manipulator-error/ManipulatorError';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import IGraphic from '../../../../../graphic/IGraphic';

interface ISpatialFrameAreaData extends ISpatialAreaBaseData<IGraphic> {}

/** Область для графики компонентов. */
class SpatialGraphicArea extends SpatialArea<ISpatialFrameAreaData> {
	readonly type: SpatialAreaType = SpatialAreaType.GRAPHIC;

	private readonly graphic: IGraphic;

	constructor(graphic: IGraphic) {
		super();
		this.graphic = graphic;
	}

	public getData = (): ISpatialFrameAreaData => ({
		graphic: this.graphic,
	});

	public getGlobalFrameArea = (): IFrameArea => {
		const position = this.graphic.getGlobalPosition();
		const configuration = this.graphic.getFrameConfiguration();
		return {
			...position,
			width: configuration.width,
			height: configuration.height,
			rotate: configuration.rotate,
		};
	};

	public getRelatedFrames = (): IAreaSizeMutable[] => {
		const component = this.graphic.getParentComponent();
		if (component === null) {
			throw new ManipulatorError('component not found');
		}

		return component.getGraphics();
	};

	public isResizeType = (): boolean => false;

	public isAllowMutatePosition = (): boolean => !this.graphic.isEnableEditMode();
}

export default SpatialGraphicArea;
