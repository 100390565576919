import { RootState } from '../../reducers/reducer';

const all = (state: RootState) => state.tagGroup;

const tagsGroupModalsActive = (state: RootState) => all(state).modalsActive;

const tagsGroup = (state: RootState) => all(state).tagsGroupData;

export {
	all,
	tagsGroup,
	tagsGroupModalsActive,
};
