import html2canvas from 'html2canvas';
import ManipulatorError from './manipulator-error/ManipulatorError';

class GraphicBlobGenerator {
	private removeMargin(div:HTMLElement): void {
		div.style.margin = '0';
	}

	public generate = ((graphicElement:HTMLElement, callback: (bytes: string, size: number) => void) => {
		this.removeMargin(graphicElement);

		graphicElement.style.position = 'absolute';
		graphicElement.style.left = '-5000px';
		graphicElement.style.top = '-5000px';

		document.body.append(graphicElement);
		setTimeout(() => {
			html2canvas(graphicElement)
				.then((canvas) => new Promise<Blob>((resolve) => {
					canvas.toBlob((blob) => {
						resolve(blob as Blob);
					}, 'image/jpeg', 0.5);
				}))
				.then((blob) => {
					const reader = new FileReader();

					reader.onload = () => {
						const blobAsDataUrl = reader.result;
						if (!blobAsDataUrl) {
							throw new ManipulatorError('invalid blob');
						}

						callback(blobAsDataUrl.toString(), blob.size);
					};

					reader.readAsDataURL(blob);
				})
				.catch(() => {
					throw new ManipulatorError('error generate graphic blob');
				})
				.finally(() => {
					graphicElement.remove();
				});
		}, 0);
	});
}

export default GraphicBlobGenerator;
