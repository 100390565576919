import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { URI_CUSTOM_TEMPLATE, URI_CUSTOM_TEMPLATE_DUPLICATE } from './consts';
import { ITemplateIds } from '../../types';
import { ISearchQueryParams } from '../../../../shared/types/api';
import { IGetCustomTemplates } from './types';
import { ICustomTemplate } from '../model/types';

export const customTemplateAPI = createApi({
	reducerPath: 'customTemplateAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['custom'],
	endpoints: builder => ({
		duplicate: builder.mutation<void, ITemplateIds>({
			query: (data) => ({
				url: URI_CUSTOM_TEMPLATE_DUPLICATE,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['custom'],
		}),
		getAll: builder.query<IGetCustomTemplates, ISearchQueryParams>({
			query: (data) => ({
				url: URI_CUSTOM_TEMPLATE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['custom'],
		}),
		get: builder.query<ICustomTemplate, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_CUSTOM_TEMPLATE}/${id}`,
				method: 'GET',
			}),
			providesTags: ['custom'],
		}),
	}),
});
