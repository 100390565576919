import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../shared/libs/redux';
import { personalAccountSlice } from '../../../redux/reducers/personalAccountReducer';

interface BasicTariffOnlyProps {
	children?: ReactNode,
}

export const BasicTariffOnly = ({ children }: BasicTariffOnlyProps) => {
	const isBasicTariff = useAppSelector(personalAccountSlice.selectors.isBasicTariff);

	if (!isBasicTariff) {
		return <Navigate to="/" state={{ replace: true }} />;
	}

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{children}
		</>
	);
};
