import CurrentTableSize from './CurrentTableSize';
import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import InjectTableTool from './InjectTableTool';
import IBaseUseCases from '../../../../../use-cases/base/IBaseUseCases';

interface IGridTableSizeProps {
	useCases: IBaseUseCases,
	currentTableSize: CurrentTableSize,
	injectTableTool: InjectTableTool,
}

class GridTableSize {
	private readonly ROW_COUNT = 10;
	private readonly COLUMN_COUNT = 10;
	private readonly ELEMENT_CLASS_NAME = 'table-constructor__grid';

	private readonly element: HTMLElement;
	private readonly useCases: IBaseUseCases;
	private readonly currentTableSize: CurrentTableSize;
	private readonly sizeBoxes: HTMLElement[][] = [];

	// Нужен для закрытия панели создания таблицы
	private readonly tableConstructor: InjectTableTool;

	constructor(props: IGridTableSizeProps) {
		this.useCases = props.useCases;
		this.currentTableSize = props.currentTableSize;
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
			fnMouseDown: this.onElementClick,
		});
		this.tableConstructor = props.injectTableTool;

		for (let i = 0; i < this.ROW_COUNT; i++) {
			const rowCells = [];
			for (let j = 0; j < this.COLUMN_COUNT; j++) {
				const cell = HTMLGenerator.getDiv({
					className: 'table-constructor__grid-item',
				});
				cell.onmousemove = this.onCellMouseMove.bind(this, i, j);
				rowCells.push(cell);
				this.element.append(cell);
			}
			this.sizeBoxes.push(rowCells);
		}
	}

	public tableGridConstruct = (focusRow: number, focusColumn: number) => {
		this.sizeBoxes[0][0].classList.add('table-constructor__grid-item_active');
		this.dropActiveCell();
		for (let k = 0; k < focusRow + 1; k++) {
			for (let l = 0; l < focusColumn + 1; l++) {
				this.sizeBoxes[k][l].classList.add('table-constructor__grid-item_active');
			}
		}
		this.currentTableSize.setValues(focusRow + 1, focusColumn + 1);
	};

	public dropActiveCell = () => {
		this.sizeBoxes.forEach(row => {
			row.forEach(cell => {
				cell.classList.remove('table-constructor__grid-item_active');
			});
		});
	};

	public getElement = () => this.element;

	private onElementClick = () => {
		const rowCount = this.currentTableSize.getRowCount();
		const columnCount = this.currentTableSize.getColumnCount();
		this.useCases.createEmptyTable(rowCount, columnCount);

		// 	Закрыть панель создания таблицы
		this.tableConstructor.disable();
	};

	private onCellMouseMove = (rowCount: number, columnCount: number) => {
		this.tableGridConstruct(rowCount, columnCount);
	};
}

export default GridTableSize;
