import { combineReducers } from 'redux';
import collectionReducer from './collectionReducer';
import personalAccountReducer from './personalAccountReducer';
import sketchReducer from './sketchReducer';
import offerReducer from './offerReducer';
import teamReducer from './teamReducer';
import usersReducer from './usersReducer';
import permissionsReducer from './permissionsReducer';
import legalEntityReducer from './legalEntityReducer';
import categoryReducer from './module/categoryReducer';
import tagReducer from './module/tagReducer';
import tagGroupReducer from './module/tagGroupReducer';
import workshopTemplateReducer from '../../entities/templates/workshop/template/model/slice';
import { moduleAPI } from '../../entities/modules/module/api/api';
import { tagAPI } from '../../entities/modules/tag/api/api';
import { tagGroupAPI } from '../../entities/modules/tag-group/api/api';
import { legalEntityAPI } from '../../entities/legal-entity/api/api';
import { teamAPI } from '../../entities/team/api/api';
import { roleAPI } from '../../entities/role/api/api';
import { permissionAPI } from '../../entities/permission/api/api';
import { employeeAPI } from '../../entities/employee/api/api';
import { templateTagAPI } from '../../entities/templates/tags/api/api';
import { fileAPI } from '../../entities/file/api/api';
import { managerAPI } from '../../entities/manager/api/api';
import { sketchAPI } from '../../entities/sketch/api/api';
import { templatePrivateAPI } from '../../entities/templates/private/api/api';
import { organizationAPI } from '../../entities/organization/api/api';
import { offerAPI } from '../../entities/offer/api/api';
import { accountRolesAPI } from '../../entities/user/role/api/api';
import { teamTemplateAPI } from '../../entities/templates/team/api/api';
import { accountAPI } from '../../entities/user/account/api/api';
import { galleryAPI } from '../../entities/templates/gallery/api/api';
import { authAPI } from '../../entities/user/auth/api/api';
import { adminAPI } from '../../entities/admin/api/api';
import { tariffAPI } from '../../entities/tariff/api/api';
import { statusAPI } from '../../entities/user/status/api/api';
import { workshopTagAPI } from '../../entities/templates/workshop/tag/api/api';
import { workshopTemplateAPI } from '../../entities/templates/workshop/template/api/api';
import moduleWindowReducer from '../../entities/modules/module/model/slice';
import galleryTemplateReducer from '../../entities/templates/gallery/model/slice';
import privateTemplateReducer from '../../entities/templates/private/model/slice';
import teamTemplateReducer from '../../entities/templates/team/model/slice';
import { customTemplateAPI } from '../../entities/templates/custom/api/api';
import customTemplateReducer from '../../entities/templates/custom/model/slice';
import {
	createWorkshopModuleReducer,
} from '../../features/manage-workshop-module/model/create-module/slice/createWorkshopModuleSlice';
import { workshopModuleTagApi, workshopTagReducer } from '../../entities/workshop-tag';
import { workshopTagGroupApi, workshopTagGroupReducer } from '../../entities/workshop-tag-group';
import { workshopModuleApi, workshopModuleReducer } from '../../entities/workshop-module';
import { workshopCategoryApi } from '../../entities/workshop-category';
import { templateGalleryModuleApi } from '../../entities/template-gallery-module';
import { templateModuleReducer } from '../../entities/template-module';
import { templateModuleCategoryApi } from '../../entities/template-module-category/api/templateModuleCategoryApi';
import aiReducer from '../../features/ai/model/slice/aiSlice';
import socketReducer from '../../features/websocket/model/slice';

export const reducers = combineReducers({
	webSocket: socketReducer,
	ai: aiReducer,
	collection: collectionReducer,
	personalAccount: personalAccountReducer,
	moduleWindow: moduleWindowReducer,
	sketch: sketchReducer,
	offer: offerReducer,
	galleryTemplate: galleryTemplateReducer,
	privateTemplate: privateTemplateReducer,
	teamTemplate: teamTemplateReducer,
	workshopTemplate: workshopTemplateReducer,
	team: teamReducer,
	user: usersReducer,
	permissions: permissionsReducer,
	legalEntity: legalEntityReducer,
	customTemplate: customTemplateReducer,
	category: categoryReducer,
	tag: tagReducer,
	workshopTag: workshopTagReducer,
	tagGroup: tagGroupReducer,
	workshopTagGroup: workshopTagGroupReducer,
	createWorkshopModule: createWorkshopModuleReducer,
	workshopModule: workshopModuleReducer,
	templateModule: templateModuleReducer,
	[moduleAPI.reducerPath]: moduleAPI.reducer,
	[workshopModuleApi.reducerPath]: workshopModuleApi.reducer,
	[templateGalleryModuleApi.reducerPath]: templateGalleryModuleApi.reducer,
	[templateModuleCategoryApi.reducerPath]: templateModuleCategoryApi.reducer,
	[workshopTagAPI.reducerPath]: workshopTagAPI.reducer,
	[workshopTemplateAPI.reducerPath]: workshopTemplateAPI.reducer,
	[authAPI.reducerPath]: authAPI.reducer,
	[statusAPI.reducerPath]: statusAPI.reducer,
	[customTemplateAPI.reducerPath]: customTemplateAPI.reducer,
	[adminAPI.reducerPath]: adminAPI.reducer,
	[tariffAPI.reducerPath]: tariffAPI.reducer,
	[galleryAPI.reducerPath]: galleryAPI.reducer,
	[accountAPI.reducerPath]: accountAPI.reducer,
	[teamTemplateAPI.reducerPath]: teamTemplateAPI.reducer,
	[accountRolesAPI.reducerPath]: accountRolesAPI.reducer,
	[offerAPI.reducerPath]: offerAPI.reducer,
	[organizationAPI.reducerPath]: organizationAPI.reducer,
	[templatePrivateAPI.reducerPath]: templatePrivateAPI.reducer,
	[sketchAPI.reducerPath]: sketchAPI.reducer,
	[fileAPI.reducerPath]: fileAPI.reducer,
	[managerAPI.reducerPath]: managerAPI.reducer,
	[templateTagAPI.reducerPath]: templateTagAPI.reducer,
	[employeeAPI.reducerPath]: employeeAPI.reducer,
	[permissionAPI.reducerPath]: permissionAPI.reducer,
	[teamAPI.reducerPath]: teamAPI.reducer,
	[legalEntityAPI.reducerPath]: legalEntityAPI.reducer,
	[roleAPI.reducerPath]: roleAPI.reducer,
	[tagGroupAPI.reducerPath]: tagGroupAPI.reducer,
	[tagAPI.reducerPath]: tagAPI.reducer,
	[workshopModuleTagApi.reducerPath]: workshopModuleTagApi.reducer,
	[workshopTagGroupApi.reducerPath]: workshopTagGroupApi.reducer,
	[workshopCategoryApi.reducerPath]: workshopCategoryApi.reducer,
});

export type RootState = ReturnType<typeof reducers>
