import { useEffect, useRef } from "react";
import { useAppSelector } from '../../shared/libs/redux';
import { getOnCloseAiModalFunc } from '../../features/ai/model/slice/aiSlice';

export const useOutSideClick = (callback: () => void, skip?: boolean, exception_id?: string) => {
	const ref = useRef<any>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const colorex = document.getElementsByClassName("colorex-container")[0]
				.getElementsByClassName("colorex")[0];

			if (ref.current
				&& !ref.current.contains(event.target as Node)
				&& ref.current.clientWidth > 0
				&& !skip
			) {
				if (exception_id && document.getElementById(exception_id)
					?.contains(event.target as Node) || ((colorex?.contains(event.target as Node) && !!colorex))) {
					return;
				}
				// мутация
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [skip]);

	return ref;
};
