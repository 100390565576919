import { useSelector } from 'react-redux';
import { workshopModuleActions, workshopModuleApi, workshopModuleSlice } from '../../../../../entities/workshop-module';
import { notificationError } from '../../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';

export const useDeleteWorkshopModule = () => {
	const dispatch = useAppDispatch();

	const [deleteModule, requestData] = workshopModuleApi.useDeleteWorkshopModuleMutation();

	const id = useSelector(workshopModuleSlice.selectors.getDeletingModuleId);

	const onDeleteModule = async () => {
		try {
			if (id) {
				await deleteModule({ moduleId: id })
					.unwrap();

				dispatch(workshopModuleActions.setIsDeletingModuleModalActive(false));
				dispatch(workshopModuleActions.setDeletingModuleId(undefined));
			}
		} catch (err) {
			notificationError('Произошла ошибка при удалении модуля', 'Мы уже устраняем эту проблему!');
		}
	};

	return {
		onDeleteModule,
		requestData,
	};
};
