import React from 'react';
import { useMultipleTeamUsers } from '../../../lib/useMultipleTeamUsers';
import { MultipleUserSelection } from '../../../../../../widgets/entity/multiple-selection';

const TeamMultipleSelection = () => {
	const {
		onBind,
		isBinding,
		availableUsers,
		isTeamUserSelectionModalActive,
		handleIsTeamUserSelectionModalActive,
	} = useMultipleTeamUsers();

	return (
		<MultipleUserSelection
			onBind={onBind}
			isBinding={isBinding}
			btnText="Добавить в команду"
			availableUsers={availableUsers?.accounts}
			isEntityUserSelectionModalActive={isTeamUserSelectionModalActive}
			handleIsEntityUserSelectionModalActive={handleIsTeamUserSelectionModalActive}
		/>
	);
};

export default TeamMultipleSelection;
