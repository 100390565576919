import HTMLGenerator from '../utils/HTMLGenerator';
import IDescartesPosition from '../utils/IDescartesPosition';
import MousePositionObserver from '../utils/observers/MousePositionObserver';
import IFrameArea from './spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import Dependent from '../utils/dependent/Dependent';

export interface IManipulatorAreaDependencies {
	rootElement: HTMLElement,
	mousePositionObserver: MousePositionObserver
}

/** Базовая сущность для динамического создания визуальных областей. */
abstract class ManipulatorArea<Dependencies extends IManipulatorAreaDependencies> extends Dependent<Dependencies> {
	private readonly DEFAULT_DELAY_CREATE_AREA_PX = 0;
	private readonly CSS_PROPERTY_HEIGHT = '--height';
	private readonly CSS_PROPERTY_WIDTH = '--width';
	private readonly CSS_PROPERTY_LEFT = '--left';
	private readonly CSS_PROPERTY_TOP = '--top';

	private readonly postUpdateAreaEvents: VoidFunction[];
	private readonly element: HTMLElement;

	private initializePosition: IDescartesPosition | null;
	private currentArea: IFrameArea;
	private delayCreateAreaPx: number;
	private isDelayCrossed: boolean;
	private isActivated: boolean;

	protected constructor() {
		super();
		this.delayCreateAreaPx = this.DEFAULT_DELAY_CREATE_AREA_PX;
		this.element = HTMLGenerator.getDiv();
		this.postUpdateAreaEvents = [];
		this.initializePosition = null;
		this.isDelayCrossed = false;
		this.currentArea = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
		};

		this.addPostInjectDependenciesListener((dependencies) => {
			dependencies.mousePositionObserver.subscribe(this.update);
		});
	}

	public start = (position: IDescartesPosition) => {
		this.initializePosition = position;
		this.currentArea.x = position.x;
		this.currentArea.y = position.y;
		this.isActivated = true;
		this.appendElement();
	};

	public stop = () => {
		this.initializePosition = null;
		this.isActivated = false;
		this.removeElement();
	};

	public addPostUpdateAreaEvent = (event: () => void) => {
		this.postUpdateAreaEvents.push(event);
	};

	public setDelayCreateAreaPx = (px: number) => {
		this.delayCreateAreaPx = px;
	};

	public setAreaClassName = (className: string) => {
		this.element.className = className;
	};

	public forceUpdate = () => {
		const currentPosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.update(currentPosition);
	};

	public onScroll = (offset: number) => {
		if (this.initializePosition === null) {
			return;
		}

		const currentPosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.initializePosition.y -= offset;
		this.update(currentPosition);
	};

	public isAreaActivated = () => this.isActivated;
	public getArea = (): IFrameArea => ({ ...this.currentArea });

	public clearArea = () => {
		this.currentArea.width = 0;
		this.currentArea.height = 0;
		this.currentArea.rotate = 0;
		this.currentArea.x = 0;
		this.currentArea.y = 0;
	};

	private update = (position: IDescartesPosition) => {
		if (this.initializePosition === null) {
			return;
		}

		if (!this.isDelayCrossed) {
			const currentDelayX = Math.abs(this.initializePosition.x - position.x);
			const currentDelayY = Math.abs(this.initializePosition.y - position.y);

			if (currentDelayX > this.delayCreateAreaPx || currentDelayY > this.delayCreateAreaPx) {
				this.isDelayCrossed = true;
				this.appendElement();
			} else {
				this.isDelayCrossed = false;
			}
		}

		const area: IFrameArea = {
			x: position.x > this.initializePosition.x ? this.initializePosition.x : position.x,
			y: position.y > this.initializePosition.y ? this.initializePosition.y : position.y,
			width: Math.abs(position.x - this.initializePosition.x),
			height: Math.abs(position.y - this.initializePosition.y),
			rotate: 0,
		};
		this.currentArea = area;

		this.updateElementSize(area);

		this.reportPostEvents();
	};

	private updateElementSize = (area: IFrameArea) => {
		const {
			x, y, height, width,
		} = area;
		this.element.style.setProperty(this.CSS_PROPERTY_LEFT, `${x}px`);
		this.element.style.setProperty(this.CSS_PROPERTY_TOP, `${y}px`);
		this.element.style.setProperty(this.CSS_PROPERTY_WIDTH, `${width}px`);
		this.element.style.setProperty(this.CSS_PROPERTY_HEIGHT, `${height}px`);
	};

	private reportPostEvents = () => {
		this.postUpdateAreaEvents.forEach(event => event());
	};

	private appendElement = () => {
		this.dependencies.rootElement.append(this.element);
	};

	private removeElement = () => {
		this.element.remove();
		this.updateElementSize({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
		});
	};
}

export default ManipulatorArea;
