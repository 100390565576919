import React from 'react';
import css from './entity-wrapper.module.scss';

const EntityWrapper: React.FC = ({ children }) => (
	<div className={css.wrapper}>
		{children}
	</div>
);

export default EntityWrapper;
