import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IOnStartMutationListenerProps from '../spatial-quadrants/area-mutators/events/IOnStartMutationListenerProps';
import MagneticLine from './magnetic-line/MagneticLine';
import MagneticPagePaddingLine from './magnetic-line/MagneticPagePaddingLine';
import DirectionVector from './DirectionVector';
import MagneticPageSideLine from './magnetic-line/MagneticPageSideLine';
import MagneticGraphicSideLine from './magnetic-line/MagneticGraphicSideLine';
import IFrameArea from '../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import IFrameConfiguration from '../../frame/IFrameConfiguration';
import MagneticLineEntity from './MagneticLineEntity';
import GraphicType from '../../graphic/GraphicType';
import IPageTexture from '../../graphic/page/IPageTexture';
import IGraphic from '../../graphic/IGraphic';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';
import { AnyAreaMutator } from '../../Types';
import IMagneticLines from './IMagneticLines';
import IComponentTree from '../../component-tree/IComponentTree';

/**
 * Компонент, который обеспечивает появление магнитных линий и притяжение объектов графики к этим линиям
 * в процессе их перемещения. Компонент делит область около линий на две основные зоны:
 * 1. Область выравнивания: Эта область имеет больший размер и применяется,
 * когда объект графики попадает в неё. При попадании в эту область на экране появляются визуальные магнитные линии.
 * 2. Область притяжения: Эта область применяется в процессе перемещения объектов графики.
 * Когда объект приближается к этой области, он притягивается к границе ближайшей магнитной линии.
 * Это позволяет улучшить точность размещения объектов и упростить процесс их выравнивания.
 * 	+---------------------------------------|-----------------------||
 * 	|										|						||
 * 	|	Область выравнивания				|	Область притяжения	|| <--- Магнитная линия
 * 	|										|						||
 * 	+---------------------------------------|-----------------------||
 */
abstract class MagneticLines<ComponentTreeType extends IComponentTree> implements IMagneticLines {
	private readonly magneticLines: MagneticLineEntity[];

	protected readonly componentTree: ComponentTreeType;

	// Привязана ли к линии в текущий момент область
	protected isMagnet;

	protected mutator: AnyAreaMutator | null;
	protected initiatorGraphic: IGraphic | null;
	protected startConfiguration: IFrameConfiguration | null;

	protected constructor(componentTree: ComponentTreeType) {
		this.mutator = null;
		this.isMagnet = false;
		this.magneticLines = [];
		this.initiatorGraphic = null;
		this.startConfiguration = null;
		this.componentTree = componentTree;
	}

	/**
	 * Инициирует начало процесса притяжения к магнитным линиям при перемещении объектов графики.
	 * @param props Необходимые сущности для запуска процесса.
	 */
	public start = (props: IOnStartMutationListenerProps) => {
		this.mutator = props.mutator;

		this.initiatorGraphic = this.calculateInitiatorGraphic(props);
		this.startConfiguration = this.calculateStartConfiguration();

		const lines = this.createMagneticLines();
		this.magneticLines.push(...lines.map(line => new MagneticLineEntity(line)));
	};

	/**
	 * Завершает процесс притяжения к магнитным линиям при перемещении объектов графики.
	 */
	public stop = () => {
		if (this.mutator === null) {
			return;
		}

		this.isMagnet = false;
		this.mutator.clearCorrectValues();
		this.destroyMagneticLines();
		this.mutator = null;
		this.startConfiguration = null;
	};

	public getStartConfiguration = () => this.startConfiguration;
	public getInitiatorGraphic = () => this.initiatorGraphic;
	public getMagneticLines = (): MagneticLineEntity[] => [...this.magneticLines];
	public getIsMagnet = () => this.isMagnet;
	public setIsMagnet = (isMagnet: boolean) => {
		this.isMagnet = isMagnet;
	};

	protected abstract createMagneticLines: () => MagneticLine[];
	protected abstract calculateStartConfiguration: () => IFrameConfiguration;
	protected abstract calculateInitiatorGraphic: (props: IOnStartMutationListenerProps) => IGraphic;

	/**
	 * Уничтожает все запущенные магнитные линии.
	 */
	private destroyMagneticLines = () => {
		this.magneticLines.forEach(entity => entity.line.remove());
		this.magneticLines.length = 0;
	};
}

export default MagneticLines;
