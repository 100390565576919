/**
 * Ошибки валидации структуры дерева компонентов.
 */
enum ValidateErrors {
	pageCountError = 'pageCountError',
	layerSequencesError = 'layerSequencesError',
	layerDuplicateError = 'layerDuplicateError',
	absenceGraphicsError = 'absenceGraphicsError',
	registrationLayersError = 'registrationLayersError',
}

export default ValidateErrors;
