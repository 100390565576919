import { MainButton } from '../../../../components/ui/main-button/main-button';
import { useSendOffer } from '../api/useSendOffer';
import ISketchStructure from '../../../../components/Sketch/ISketchStructure';

interface SendOfferButtonProps {
	name: string
	structure: ISketchStructure | undefined,
}

export const SendOfferButton = ({
	name,
	structure,
}: SendOfferButtonProps) => {
	const {
		sendOffer,
		isLoading,
	} = useSendOffer();

	const onSendOffer = async () => sendOffer(structure, name);

	return (
		<MainButton btnStyle="bright" disabled={isLoading} onClick={onSendOffer}>
			Отправить
		</MainButton>
	);
};
