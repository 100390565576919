import { EmptyPlugList } from '../../../../../../../widgets/templates/empty-list';
import { DEFAULT_TO_TARIFF_URL } from '../../../../../../../shared/ui/to-tariff-button/model/data';
import useGetCustomTemplates from '../utils/useGetCustomTemplates';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import { CustomTemplateList } from './list/template-list';
import css from './template-custom-page.module.scss';

export const TemplateCustomPage = () => {
	const templates = useAppSelector(state => state.customTemplate.customTemplates);

	const { isLoading } = useGetCustomTemplates();

	return (
		<div className={css.wrapper}>
			{
				((templates && templates.length) || isLoading)
					? <CustomTemplateList templates={templates} isDataLoading={isLoading} />
					: (
						<EmptyPlugList
							isCreateBtn={false}
							btnText="Перейти к тарифам"
							onClick={() => window.open(DEFAULT_TO_TARIFF_URL, '_blank')}
							title="Закажите свой персональный шаблон"
							description={(
								<span>
									Получите продающий шаблон, разработанный по&nbsp;правилам маркетинга&nbsp;и дизайна
								</span>
							)}
						/>
					)
			}
		</div>
	);
};
