import ToolBarTool from '../../ToolBarTool';
import TableConstructorPanel from './TableConstructorPanel';
import SvgCollection from '../../../../../utils/SvgCollection';
import Utils from '../../../../../utils/impl/Utils';
import IBaseUseCases from '../../../../../use-cases/base/IBaseUseCases';

/**
 * Элемент интерфейса для создания нового компонента таблицы.
 */
class InjectTableTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Таблица';

	private readonly tableConstructor: TableConstructorPanel;

	constructor(useCases: IBaseUseCases) {
		super('panel-components__table');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.TABLE_TOOL);

		this.tableConstructor = new TableConstructorPanel(useCases, this);
	}

	protected override onEnable = () => {
		this.rootElement.append(this.tableConstructor.getElement());
		this.hideHint();
		this.onClickObserver();
		this.enableListeners.forEach(listener => listener());
	};

	protected override onDisable = () => {
		this.tableConstructor.getElement().remove();
		document.body.removeEventListener('click', this.onClickOutsideElement, false);
	};

	private onClickObserver = () => {
		document.body.addEventListener('click', this.onClickOutsideElement);
	};

	private onClickOutsideElement = (event: Event) => {
		if (
			event.target !== this.rootElement
			&& event.target !== this.tableConstructor.getElement()
			&& !this.rootElement.contains(event.target as HTMLElement)
		) {
			this.disable();
			event.stopPropagation();
		}
	};
}

export default InjectTableTool;
