import InterfacePanel from '../../../InterfacePanel';
import ActionForwardTool from './actions/ActionForwardTool';
import ActionBackTool from './actions/ActionBackTool';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';

/**
 * Панель для навигации по действиям пользователя.
 */
class ActionPanel extends InterfacePanel {
	private readonly backTool: ActionBackTool;
	private readonly forwardTool: ActionForwardTool;

	constructor(useCases: IBaseUseCases) {
		super();
		this.forwardTool = new ActionForwardTool();
		this.backTool = new ActionBackTool();

		this.setRootElementClassName('panel-action');
		this.rootElement.append(
			this.backTool.getElement(),
			this.forwardTool.getElement(),
		);

		this.backTool.addClickListener(useCases.undo);
		this.forwardTool.addClickListener(useCases.redo);
	}

	public sync = (undoCommandCount: number, redoCommandCount: number) => {
		undoCommandCount < 1 ? this.backTool.disableHighlight() : this.backTool.enableHighlight();
		redoCommandCount < 1 ? this.forwardTool.disableHighlight() : this.forwardTool.enableHighlight();
	};
}

export default ActionPanel;
