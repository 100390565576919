import React from 'react';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import { ReactComponent as CopyLinkIcon } from './images/copy-link.svg';
import css from './copy-link.module.scss';
import { copyBuffer } from '../../../../helper';
import { notificationSuccess } from '../../../../components/Notifications/callNotifcation';

interface CopyLinkButtonProps {
	disabled?: boolean,
}

const onCopyLink = () => {
	copyBuffer(window.location.href);
	notificationSuccess('Ссылка скопирована успешно!');
};

export const CopyLinkButton = ({ disabled }: CopyLinkButtonProps) => (
	<MainButton 
		onClick={onCopyLink} 
		btnStyle="light" 
		type="button"
		disabled={disabled}
	>
		<div className={css.btn}>
			<CopyLinkIcon />
			Копировать ссылку
		</div>
	</MainButton>
);
