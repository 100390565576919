import _ from 'lodash';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ITableCellTexture from './cells/ITableCellTexture';
import TableComponent from '../../components/table/TableComponent';
import Utils from '../../utils/impl/Utils';
import { notificationError } from '../../../Notifications/callNotifcation';
import TableCellContext from './cells/context/TableCellContext';
import { ITokenText, Token, TokenType } from '../../mechanics/mext/parser';

/**
 * Сущность для изменения сетки ячеек таблицы.
 */
class TableGridMutator {
	private readonly postMutationListeners: ((component: TableComponent) => VoidFunction)[];

	constructor() {
		this.postMutationListeners = [];
	}

	/**
	 * Объединяет ячейки в фокусе.
	 * @param component компонент таблицы, в которой будет происходить объединение.
	 */
	public mergeFocusCell = (component: TableComponent) => {
		const cellContexts = component.getCellContexts();
		const focusCellContexts = component.getFocusCellContexts();
		if (focusCellContexts === null || focusCellContexts.length < 2) {
			notificationError('Объединение ячеек', 'Ячеек в фокусе меньше двух.');
			return;
		}

		const isAccessMerge = this.validateMerge(focusCellContexts);
		if (!isAccessMerge) {
			notificationError('Объединение ячеек', 'Фигура, образованная выбранными ячейками, '
				+ 'не соответствует прямоугольнику. Пожалуйста, выберите ячейки таким образом, '
				+ 'чтобы они образовывали прямоугольник.');
			return;
		}

		let row = Number.MAX_SAFE_INTEGER;
		let column = Number.MAX_SAFE_INTEGER;
		const tokens: Token[][] = [];
		for (let i = 0; i < focusCellContexts.length; i++) {
			const {
				row: currentRow,
				column: currentColumn,
				content: currentContent,
			} = focusCellContexts[i].getTexture();

			if (row > currentRow) {
				row = currentRow;
			}
			if (column > currentColumn) {
				column = currentColumn;
			}

			tokens.push(currentContent.tokens);
		}

		const targetCellContext: TableCellContext = cellContexts.filter(context => {
			const {
				row: currentRow,
				column: currentColumn,
			} = context.getTexture();
			return row === currentRow && currentColumn === column;
		})[0];

		if (targetCellContext === undefined) {
			throw new ManipulatorError('target cell not found');
		}

		let rowSpan = 0;
		let columnSpan = 0;
		for (let i = 0; i < focusCellContexts.length; i++) {
			const {
				row: cellRow,
				column: cellColumn,
				rowSpan: currentRowSpan,
				columnSpan: currentColumnSpan,
			} = focusCellContexts[i].getTexture();
			if (cellRow === row) {
				columnSpan += currentColumnSpan;
			}
			if (cellColumn === column) {
				rowSpan += currentRowSpan;
			}
		}

		const updatedTextures: ITableCellTexture[] = [];
		cellContexts.forEach(cell => {
			const texture = cell.getTexture();
			if (cell === targetCellContext) {
				texture.rowSpan = rowSpan;
				texture.columnSpan = columnSpan;
				texture.content.tokens = tokens.flat();
				updatedTextures.push(texture);
			}
			if (!focusCellContexts.includes(cell)) {
				updatedTextures.push(texture);
			}
		});

		this.applyChanges(component, updatedTextures);

		if (!component.focusOnlyCellContextByID(targetCellContext.getID())) {
			throw new ManipulatorError('error focus only cell');
		}
	};

	/**
	 * Разбивает объединенные ячейки.
	 * @param component компонент таблицы, в которой будет происходить разбивка.
	 */
	public splitFocusCells = (component: TableComponent) => {
		const cellContexts = component.getCellContexts();
		const focusCellContexts = component.getFocusCellContexts();
		if (focusCellContexts === null) {
			return;
		}

		const isAccessSplit = this.validateSplit(focusCellContexts);
		if (!isAccessSplit) {
			return;
		}

		const createdCells: ITableCellTexture[] = [];
		const updatedTextures: ITableCellTexture[] = [];
		focusCellContexts.forEach(cell => {
			const texture = cell.getTexture();
			if (texture.columnSpan === 1 && texture.rowSpan === 1) {
				updatedTextures.push(texture);
				return;
			}

			for (let currentRow = texture.row; currentRow < texture.row + texture.rowSpan; currentRow++) {
				for (
					let currentColumn = texture.column;
					currentColumn < texture.column + texture.columnSpan;
					currentColumn++) {
					const createdTexture = this.getSingleCellTexture(currentRow, currentColumn, texture.background);
					createdCells.push(createdTexture);
					updatedTextures.push(createdTexture);
				}
			}
			createdCells[0].content.tokens = texture.content.tokens;
		});

		cellContexts.forEach(cell => {
			const isFocusCell = focusCellContexts.includes(cell);
			if (isFocusCell) {
				return;
			}
			const texture = cell.getTexture();
			updatedTextures.push(texture);
		});

		this.applyChanges(component, updatedTextures);

		const firstSplitCell = createdCells[0];
		component.focusOnlyCellContextByID(firstSplitCell.id);
	};

	/**
	 * Удаляет колонки, которые пересекают ячейки в фокусе.
	 * @param component компонент, в котором будет происходить удаление колонок.
	 */
	public deleteFocusColumns = (component: TableComponent) => {
		const focusCellContext = component.getFocusCellContexts();
		if (focusCellContext === null) {
			notificationError('Удаление колонки', 'Не найдена ячейка в фокусе.');
			return;
		}

		const columnIndexes = this.getColumnIndexes(focusCellContext);

		if (columnIndexes.length === component.getColumnCount()) {
			notificationError('Удаление колонки', 'Удаление всех столбцов невозможно.');
			return;
		}
		columnIndexes.forEach((columnIndex, index) => {
			this.deleteColumn(component, columnIndex - index);
		});
	};

	/**
	 * Удаляет строки, которые пересекают ячейки в фокусе.
	 * @param component компонент таблицы, в котором будет происходить удаление строк.
	 */
	public deleteFocusRows = (component: TableComponent) => {
		const focusCellContexts = component.getFocusCellContexts();
		if (focusCellContexts === null) {
			notificationError('Удаление строки', 'Не найдена ячейка в фокусе.');
			return;
		}

		const rowIndexes = this.getRowIndexes(focusCellContexts);

		if (rowIndexes.length === component.getRowCount()) {
			notificationError('Удаление строки', 'Удаление всех строк невозможно.');
			return;
		}

		rowIndexes.forEach((rowIndex, index) => {
			this.deleteRow(component, rowIndex - index);
		});
	};

	/**
	 * Добавляет новую колонку таблицы перед самой крайней слева ячейкой в фокусе.
	 * @param component - компонент таблицы, в котором происходит добавление колонок.
	 */
	public addColumnBefore(component: TableComponent) {
		const targetIndex = component.getLeftmostColumnIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);
		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем колонку если она находится после строки от которой добавляем
			if (cell.columnSpan > 1) {
				if (targetIndex > cell.column && targetIndex <= cell.column + cell.columnSpan - 1) cell.columnSpan += 1;
			}
			if (cell.column >= targetIndex) cell.column += 1;
		}

		// Получаем шаблон стилей строки
		const patternColumn = textures.filter(texture => texture.column === targetIndex);
		for (let i = 0; i < patternColumn.length; i++) {
			const patternCell = patternColumn[i];

			const cell = this.getSingleCellTexture(patternCell.row, targetIndex, patternCell.background);
			cell.content.tokens = this.getStylesFromPattern(cell, patternCell);
			cell.rowSpan = patternCell.rowSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getColumnMultipliers();
		multipliers.splice(targetIndex, 0, 1);

		component.mutateColumnMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	}

	/**
	 * Добавляет новые колонки таблицы после самой крайней справа ячейки в фокусе.
	 * @param component - компонент таблицы в которой происходит добавление колонок.
	 */
	public addColumnAfter(component: TableComponent) {
		const targetIndex = component.getRightmostColumnIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.columnSpan > 1) {
				if (targetIndex >= cell.column && targetIndex < cell.column + cell.columnSpan - 1) cell.columnSpan += 1;
			}
			if (cell.column > targetIndex) cell.column += 1;
		}

		// Получаем шаблон стилей строки
		const patternColumn = textures.filter(texture => (texture.column === targetIndex && texture.columnSpan === 1)
			|| targetIndex === (texture.column + texture.columnSpan - 1));

		for (let i = 0; i < patternColumn.length; i++) {
			const patternCell = patternColumn[i];

			const cell = this.getSingleCellTexture(patternCell.row, targetIndex + 1, patternCell.background);
			cell.content.tokens = this.getStylesFromPattern(cell, patternCell);
			cell.rowSpan = patternCell.rowSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getColumnMultipliers();
		multipliers.splice(targetIndex + 1, 0, 1);

		component.mutateColumnMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	}

	/**
	 * Добавляет новую строку ПОД выделенными ячейками (новая строка будет находиться над самой верхней ячейкой в
	 * выделении).
	 * @param component - Компонент таблицы.
	 */
	public addRowUnder(component: TableComponent) {
		const targetIndex = component.getLowestIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть row у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.rowSpan > 1) {
				if (targetIndex >= cell.row && targetIndex < cell.row + cell.rowSpan - 1) cell.rowSpan += 1;
			}
			if (cell.row > targetIndex) cell.row += 1;
		}

		// Получаем шаблон стилей строки
		const patternRow = textures.filter(texture => (texture.row === targetIndex && texture.rowSpan === 1)
			|| targetIndex === (texture.row + texture.rowSpan - 1));

		for (let i = 0; i < patternRow.length; i++) {
			const patternCell = patternRow[i];

			const cell = this.getSingleCellTexture(targetIndex + 1, patternCell.column, patternCell.background);
			cell.content.tokens = this.getStylesFromPattern(cell, patternCell);
			cell.columnSpan = patternCell.columnSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getRowsMultipliers();
		multipliers.splice(targetIndex + 1, 0, 1);

		component.mutateRowMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	}

	/**
	 * Добавляет новую строку НАД выделенными ячейками новая строка будет находиться над самой верхней ячейков в
	 * выделении.
	 * @param component - Компонент таблицы.
	 */
	public addRowOver(component: TableComponent) {
		const targetIndex = component.getHighestIndex();
		if (targetIndex === null) return;

		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть row у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			// Сдвигаем строку если она находится после строки от которой добавляем
			if (cell.rowSpan > 1) {
				if (targetIndex > cell.row && targetIndex <= cell.row + cell.rowSpan - 1) cell.rowSpan += 1;
			}
			if (cell.row >= targetIndex) cell.row += 1;
		}

		// Получаем шаблон стилей строки
		const patternRow = textures.filter(texture => texture.row === targetIndex);

		for (let i = 0; i < patternRow.length; i++) {
			const patternCell = patternRow[i];

			const cell = this.getSingleCellTexture(targetIndex, patternCell.column, patternCell.background);
			cell.content.tokens = this.getStylesFromPattern(cell, patternCell);
			cell.columnSpan = patternCell.columnSpan;
			updatedTextures.push(cell);
		}

		const multipliers = component.getRowsMultipliers();
		multipliers.splice(targetIndex, 0, 1);

		component.mutateRowMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	}

	public addPostMutationListener = (listener: (component: TableComponent) => VoidFunction) => {
		this.postMutationListeners.push(listener);
	};

	private applyChanges = (component: TableComponent, updatedTextures: ITableCellTexture[]) => {
		component.mutateCellTextures(updatedTextures);
		component.applyMutations();

		const contexts = component.getCellContexts();
		contexts.forEach(context => context.enableMutationMode());

		this.callPostMutationListeners(component);
	};

	private callPostMutationListeners = (component: TableComponent) => {
		this.postMutationListeners.forEach(listener => listener(component));
	};

	/**
	 * Возвращает массив индексов колонок, которые пересекают ячейки, отсортированный по возрастанию.
	 * @param contexts ячейки.
	 */
	private getColumnIndexes = (contexts: TableCellContext[]): number[] => {
		const focusColumnIndexes: Set<number> = new Set<number>();
		contexts.forEach(context => {
			const { column, columnSpan } = context.getTexture();

			for (let i = column; i < column + columnSpan; i++) {
				focusColumnIndexes.add(i);
			}
		});
		return [...focusColumnIndexes].sort();
	};

	/**
	 * Возвращает массив индексов строк, которые пересекают ячейки, отсортированный по возрастанию.
	 * @param contexts ячейки.
	 */
	private getRowIndexes = (contexts: TableCellContext[]): number[] => {
		const focusRowIndexes: Set<number> = new Set<number>();
		contexts.forEach(context => {
			const { row, rowSpan } = context.getTexture();
			for (let i = row; i < row + rowSpan; i++) {
				focusRowIndexes.add(i);
			}
		});
		return [...focusRowIndexes].sort((a, b) => a - b);
	};

	/**
	 * Удаляет колонку по индексу.
	 * @param component компонент таблицы.
	 * @param targetIndex индекс колонки для удаления.
	 */
	private deleteColumn = (component: TableComponent, targetIndex: number) => {
		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			if (cell.columnSpan > 1) {
				if (targetIndex >= cell.column && targetIndex <= cell.column + cell.columnSpan - 1) {
					cell.columnSpan -= 1;
				}
				if (cell.column === targetIndex) cell.column += 1;
			}

			if (cell.column === targetIndex) {
				// Если ячейка находиться в удаляемой колонке, вырезаем её
				updatedTextures.splice(i, 1);
				i--;
			}

			if (cell.column > targetIndex) {
				cell.column -= 1;
			}
		}
		const multipliers = component.getColumnMultipliers();

		multipliers.splice(targetIndex, 1);

		component.mutateColumnMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	};

	/**
	 * Удаляет строку по индексу.
	 * @param component компонент таблицы.
	 * @param targetIndex индекс строки для удаления.
	 */
	private deleteRow = (component: TableComponent, targetIndex: number) => {
		const textures = component.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		// Сдвинуть column у каждой ячейки после targetIndex
		for (let i = 0; i < updatedTextures.length; i++) {
			const cell = updatedTextures[i];

			if (cell.rowSpan > 1) {
				if (targetIndex >= cell.row && targetIndex <= cell.row + cell.rowSpan - 1) {
					cell.rowSpan -= 1;
				}
				if (cell.row === targetIndex) cell.row += 1;
			}

			if (cell.row === targetIndex) {
				// Если ячейка находиться в удаляемой строке, вырезаем её
				updatedTextures.splice(i, 1);
				i--;
			}

			if (cell.row > targetIndex) {
				cell.row -= 1;
			}
		}
		const multipliers = component.getRowsMultipliers();
		multipliers.splice(targetIndex, 1);

		component.mutateRowMultipliers(multipliers);
		this.applyChanges(component, updatedTextures);
	};

	/**
	 * Выполняет проверку, образуют ли ячейки правильный прямоугольник. Вычисляет площадь ожидаемого
	 * прямоугольника и сравнивает её с площадью ячеек.
	 * @param contexts Контексты ячеек таблицы.
	 */
	private validateMerge = (contexts: TableCellContext[]): boolean => {
		let minColumn = Number.MAX_SAFE_INTEGER;
		let minRow = Number.MAX_SAFE_INTEGER;
		let maxColumn = Number.MIN_SAFE_INTEGER;
		let maxRow = Number.MIN_SAFE_INTEGER;

		contexts.forEach(context => {
			const {
				column, row, columnSpan, rowSpan,
			} = context.getTexture();

			minColumn = Math.min(minColumn, column);
			minRow = Math.min(minRow, row);
			maxColumn = Math.max(maxColumn, column + columnSpan);
			maxRow = Math.max(maxRow, row + rowSpan);
		});

		const width = maxColumn - minColumn;
		const height = maxRow - minRow;
		const area = width * height;

		let cellsArea = 0;
		contexts.forEach(context => {
			const { columnSpan, rowSpan } = context.getTexture();
			cellsArea += columnSpan * rowSpan;
		});

		return area === cellsArea;
	};

	private validateSplit = (contexts: TableCellContext[]): boolean => {
		let isValid = false;
		contexts.forEach(context => {
			const { rowSpan, columnSpan } = context.getTexture();
			if (rowSpan > 1 || columnSpan > 1) {
				isValid = true;
			}
		});
		return isValid;
	};

	private getSingleCellTexture = (row: number, column: number, background: string): ITableCellTexture => ({
		id: Utils.Generate.UUID4(),
		column,
		row,
		background,
		rowSpan: 1,
		content: Utils.Component.getDefaultTextModel(),
		columnSpan: 1,
	});

	private getStylesFromPattern = (
		cell: ITableCellTexture,
		pattern: ITableCellTexture,
	) => {
		const cellTextToken = cell.content.tokens.find(token => token.type === TokenType.Text) as ITokenText;
		const patternTextToken = pattern.content.tokens.find(token => token.type === TokenType.Text) as ITokenText;

		const tokens = [...cell.content.tokens];
		for (let i = 0; i < tokens.length; i++) {
			if (tokens[i].type === TokenType.Text) {
				tokens[i] = { ...patternTextToken, value: cellTextToken.value };
			}
		}
		return tokens;
	};

	private validateControlPosition = (
		texture: ITableCellTexture,
		column: number,
		row: number,
	): boolean => texture.row === row && texture.column === column;
}

export default TableGridMutator;
