import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ISketchStructure from '../../components/Sketch/ISketchStructure';

interface IInitialState {
	sketch_structure: ISketchStructure | undefined,
	managerId: number | undefined | null,
	name: string,
	deadline: string | null | undefined,
	preview: string | null,
}

const initialState: IInitialState = {
	sketch_structure: undefined,
	deadline: undefined,
	managerId: null,
	name: '',
	preview: null,
};

export const sketchSlice = createSlice({
	name: 'sketch',
	initialState,
	reducers: {
		setSketchStructure: (state, action: PayloadAction<ISketchStructure>) => {
			state.sketch_structure = action.payload;
		},
		setSketchName: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
		},
		setSketchManagerId: (state, action: PayloadAction<number | undefined | null>) => {
			state.managerId = action.payload;
		},
		setSketchDeadline: (state, action: PayloadAction<string | null | undefined>) => {
			state.deadline = action.payload;
		},
		setSketchPreview: (state, action: PayloadAction<string | null>) => {
			state.preview = action.payload;
		},
	},
});

export const {
	setSketchDeadline,
	setSketchManagerId,
	setSketchName,
	setSketchPreview,
	setSketchStructure,
} = sketchSlice.actions;

export default sketchSlice.reducer;
