import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleTagApi, workshopTagActions } from '../../../../entities/workshop-tag';
import BlockHeader from '../../../../components/pages/features/moduleWindow/editModuleWindow/blockHeader/BlockHeader';
import { ModuleTag } from '../../../../entities/modules/tag/ui/module-tag';
import { EmptyList } from '../../../../components/ui/empty-list/empty-list';
import { EditWorkshopTagModal } from '../edit-workshop-tag-modal/EditWorkshopTagModal';
import { DeleteWorkshopTagModal } from '../delete-workshop-tag-modal/DeleteWorkshopTagModal';
import { CreateWorkshopTagModal } from '../create-workshop-tag-modal/CreateWorkshopTagModal';
import css from './EditWorkshopTags.module.scss';
import { classNames } from '../../../../shared/libs/classNames';

interface EditWorkshopTagsProps {
	className?: string,
}

export const EditWorkshopTags = ({ className }: EditWorkshopTagsProps) => {
	const dispatch = useAppDispatch();

	const { data: tags } = workshopModuleTagApi.useGetWorkshopTagsQuery({
		page: 1,
		limit: 127,
		search: '',
	});

	const onDeletingTag = (id: number) => {
		dispatch(workshopTagActions.setIsDeleteModalActive(true));
		dispatch(workshopTagActions.setDeletingTagId(id));
	};
	const onEditingTag = (id: number) => {
		dispatch(workshopTagActions.setIsEditingTagModalActive(true));
		dispatch(workshopTagActions.setEditingTagId(id));
	};

	const onCreateTag = () => {
		dispatch(workshopTagActions.setIsCreateModalActive(true));
	};

	return (
		<div className={classNames('', {}, [className])}>
			<BlockHeader
				title="Теги"
				onBtnClick={onCreateTag}
				btnText="Добавить тег"
			>
				{
					tags?.moduleTags
						? (
							<div className={css.tags_block}>
								{
									tags.moduleTags.map(tag => {
										const { id } = tag;

										return (
											<ModuleTag
												tag={tag}
												onDelete={() => onDeletingTag(id)}
												onEdit={() => onEditingTag(id)}
												key={id}
											/>
										);
									})
								}
							</div>
						)
						: (
							<EmptyList
								title="Создайте первый тег к модулю"
								needBtn={false}
							/>
						)
				}
			</BlockHeader>
			<EditWorkshopTagModal />
			<DeleteWorkshopTagModal />
			<CreateWorkshopTagModal />
		</div>
	);
};
