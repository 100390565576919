import React from 'react';
import css from './skeleton.module.scss';

interface SkeletonTemplatesProps {
	count: number;
}

export const SkeletonTemplates = ({ count }: SkeletonTemplatesProps) => (
	<div className={css.wrapper}>
		{
			Array.from({ length: count }, (_, index) => (
				<div key={index} className={css.skeleton_loading_templates} />
			))
		}
	</div>
);
