import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import React, { forwardRef } from 'react';
import { popUpTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';
import { ContextMenuContent } from './content/context-menu-content';
import { IContextMenuProps } from '../model/types';

const portal = document.getElementById('portal');

export const ContextMenu = forwardRef<HTMLDivElement, IContextMenuProps>(({
	show,
	data,
	position,
}, ref) => {
	const wrapperStyles: React.CSSProperties = {
		position: 'absolute',
		left: position.x,
		top: position.y,
		display: 'inline-block',
		zIndex: 100,
	};

	return (
		<>
			{
				ReactDOM.createPortal(
					<div
						ref={ref}
						onContextMenu={e => e.preventDefault()}
						style={wrapperStyles}
					>
						<CSSTransition
							nodeRef={ref}
							in={show}
							timeout={200}
							unmountOnExit
							classNames={popUpTransitionClasses}
						>
							<ContextMenuContent data={data} />
						</CSSTransition>
					</div>,
					portal as Element,
				)
			}
		</>
	);
});
