import { Route, Routes } from 'react-router-dom';
import React from 'react';
import RedirectPageContainer from '../../components/pages/registration/redirectPage/RedirectPageContainer';
import PrivacyPolicy from '../../components/pages/privacyPolicy/PrivacyPolicy';
import Registration from '../../components/pages/registration/Registration';
import Authorization from '../../components/pages/registration/authorization/Authorization';

export const UnauthorizedRoutes = () => (
	<Routes>
		<Route path="/confirm/:pass" element={<RedirectPageContainer />} />
		<Route path="/privacy" element={<PrivacyPolicy />} />
		<Route path="/registration" element={<Registration />} />
		<Route path="/registration/:offer" element={<Registration />} />
		<Route path="*" element={<Authorization />} />
	</Routes>
);
