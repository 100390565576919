import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import css from './tag-groups-acroll-search.module.scss';
import { ReactComponent as CleanIcon } from '../images/close.svg';
import { useSelectedGroupTags } from '../libs/useSelectedGroupTags';
import { TagsScrollSearchDropdown } from './dropdown/tags-scroll-search-dropdown';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import { tagGroupAPI } from '../../../../entities/modules/tag-group/api/api';
import { TagScrollSearchGroup } from './group/group';
import { TagGroupsScrollSearchInput } from './scroll-search-input/scroll-search-input';
import { workshopTagGroupApi } from '../../../../entities/workshop-tag-group';
import { TypeOfTags } from '../../../template-modules-modal/model/types';
import {
	IGetWorkshopTagGroupResponse,
	IWorkshopTagsGroup,
} from '../../../../entities/workshop-tag-group/model/types/types';
import { IGetTagGroupResponse } from '../../../../entities/modules/tag-group/api/types';
import { WorkshopTagsScrollSearchDropdown } from './dropdown/workshop-tags-scroll-search-dropdown';
import { ITagData } from '../../../../entities/modules/tag/api/types';

interface TagGroupsScrollSearchProps {
	scrollDistance: number,
	typeOfTags: TypeOfTags,
}

export const TagGroupsScrollSearch = ({ scrollDistance, typeOfTags }: TagGroupsScrollSearchProps) => {
	const [openGroupId, setOpenGroupId] = useState<number>();
	const [searchValue, setSearchValue] = useState('');
	const [isSearchBlockVisible, setIsSearchBlockVisible] = useState(false);

	const tagsRef = useRef(null);

	const debouncedSearch = useDebounce(searchValue.trim(), 200);
	const { isLoading, data: tagGroupsData } = typeOfTags === TypeOfTags.WORKSHOP_TAGS
		? workshopTagGroupApi.useGetWorkshopGroupsQuery({
			page: 1,
			limit: 127,
			search: '',
		})
		: tagGroupAPI.useGetAllQuery({
			page: 1,
			limit: 127,
			search: debouncedSearch,
		});
	let groupTags: IWorkshopTagsGroup[] | ITagData[] = [];
	if	(typeOfTags === TypeOfTags.WORKSHOP_TAGS && !isLoading) {
		groupTags = (tagGroupsData as IGetWorkshopTagGroupResponse).groupTags;
	} else if (typeOfTags === TypeOfTags.PRIVATE_TAGS && !isLoading) {
		if	((tagGroupsData as IGetTagGroupResponse).tagGroups) {
			groupTags = (tagGroupsData as IGetTagGroupResponse).tagGroups;
		}
	} else groupTags = [];
	// const groupTags = typeOfTags === TypeOfTags.WORKSHOP_TAGS && !isLoading
	// 	? (tagGroupsData as IGetWorkshopTagGroupResponse).groupTags
	// 	: (tagGroupsData as IGetTagGroupResponse).tagGroups;

	const {
		onSelectTag,
		selectedTags,
		onCleanSelectedTagList,
	} = useSelectedGroupTags();

	const onGroupClick = (id: number) => {
		setOpenGroupId(id);
	};

	const onCleanList = () => {
		if (isSearchBlockVisible || openGroupId) {
			setOpenGroupId(undefined);
			onCleanSelectedTagList();
			setIsSearchBlockVisible(false);
		}
	};

	useEffect(() => () => onCleanList(), []);

	return (
		<>
			<div className={css.container}>
				{
					!isLoading && groupTags
						? groupTags.map(group => (
							<TagScrollSearchGroup
								data={group}
								key={group.id}
								onClick={onGroupClick}
								isOpen={openGroupId === group.id}
							/>
						))
						: ''
				}
				<div className={css.reset_tags} role="presentation" onClick={onCleanSelectedTagList}>
					Сбросить теги
				</div>
				<CleanIcon
					onClick={onCleanList}
					className={cx(isSearchBlockVisible ? css.close_icon : css.close_icon_margin)}
				/>
				<TagGroupsScrollSearchInput
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					isSearchBlockVisible={isSearchBlockVisible}
					setIsSearchBlockVisible={setIsSearchBlockVisible}
				/>
			</div>
			{
				typeOfTags === TypeOfTags.WORKSHOP_TAGS
					? (
						<WorkshopTagsScrollSearchDropdown
							ref={tagsRef}
							onSelectTag={onSelectTag}
							currentIds={selectedTags}
							groupId={openGroupId?.toString()}
						/>
					)
					: (
						<TagsScrollSearchDropdown
							ref={tagsRef}
							onSelectTag={onSelectTag}
							currentIds={selectedTags}
							groupId={openGroupId?.toString()}
						/>
					)
			}
			
		</>
	);
};
