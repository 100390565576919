import React from 'react';
import css from './user-organization-info.module.scss';
import { UserOrganizationInfoList } from './organization-list/organization-list';
import { IUserOrganizationItem } from '../model/types';

interface UserOrganizationInfoProps {
	name: string,
	data: IUserOrganizationItem[] | undefined,
	icon: React.ReactNode,
}

export const UserOrganizationInfo = ({
	name,
	icon,
	data,
}: UserOrganizationInfoProps) => {
	const a = '';
	return (
		<div className={css.wrapper}>
			<div className={css.name}>{name}</div>
			<div className={css.list_wrapper}>
				{icon}
				{
					data
						? <UserOrganizationInfoList data={data} />
						: <div className={css.no_data}>Нет данных</div>
				}
			</div>
		</div>
	);
};
