import ITableGraphicTexture from '../ITableGraphicTexture';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import TableGraphic from '../TableGraphic';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import TableComponent from '../../../components/table/TableComponent';
import TableCell from './TableCell';
import { TableGridMap } from '../TableGridMap';

/**
 * Слой в графике таблицы для загрузки, отображения и хранения ячеек.
 */
class TableCellsLayer {
	private readonly ELEMENT_CLASS_NAME = 'graphic-table_cells-layer';
	private readonly MIN_CELL_HEIGHT = 32;

	private readonly element: HTMLElement;
	private readonly parentGraphic: TableGraphic;

	private columnsMultipliers: number[];
	private rowsMultipliers: number[];
	private columnsCount: number;
	private rowsCount: number;
	private startRow: number;
	// Хранилище для ячеек, которые отображаются в текущий момент
	private currentRenderCells: TableCell[];
	private borderColor: string;

	constructor(parentGraphic: TableGraphic) {
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
		});
		this.parentGraphic = parentGraphic;
		this.columnsMultipliers = [];
		this.rowsMultipliers = [];
		this.columnsCount = 0;
		this.startRow = 0;
		this.rowsCount = 0;
		this.borderColor = '';
		this.currentRenderCells = [];
	}

	public render = () => {
		this.renderColumns();
		this.renderRows();
		this.renderCells();
	};

	public renderCells = () => {
		const parentComponent = this.getParentComponent();
		const cells = parentComponent.getCells();
		const gridAreas = parentComponent.getGridAreas();
		const actualAreas = gridAreas.slice(this.startRow, this.startRow + this.rowsCount);
		const actualCells = this.getCellsForCellMap(cells, actualAreas);
		const isRendered = this.compareCurrentCells(actualCells);

		if (isRendered) {
			return;
		}

		this.clearLayer();
		this.prepareActualCells(actualCells);
		this.injectCellElements(actualCells);

		this.setCurrentRenderCells(actualCells);
	};

	/**
	 * Устанавливает на элемент значения ширины столбцов основываясь на хранящихся значениях.
	 */
	public renderColumns = () => {
		this.element.style.gridTemplateColumns = this.columnsMultipliers
			.map(multiplier => `${multiplier}fr`)
			.join(' ');
	};

	/**
	 * Устанавливает на элемент значения ширины столбцов основываясь на хранящихся значениях.
	 */
	public renderRows = () => {
		const defaultHeight = this.parentGraphic.getDefaultRowHeight();
		const heights = this.rowsMultipliers.map(multiplier => defaultHeight * multiplier);
		this.element.style.gridTemplateRows = `${heights.map(height => `${height}px`).join(' ')}`;
	};

	public setColumnsMultipliers = (multipliers: number[]) => {
		this.columnsMultipliers = multipliers;
	};

	public setRowsMultipliers = (multipliers: number[]) => {
		this.rowsMultipliers = multipliers;
	};

	public getColumnMultipliers = (): number[] => [...this.columnsMultipliers];

	public getRowsMultipliers = (): number[] => [...this.rowsMultipliers];

	public setColumns = (columns: number) => {
		this.columnsCount = columns;
	};

	public getColumns = (): number => this.columnsCount;

	public setRowCount = (count: number) => {
		this.rowsCount = count;
	};

	public getRowCount = (): number => this.rowsCount;

	public setStartRow = (row: number) => {
		this.startRow = row;
	};

	public getStartRow = (): number => this.startRow;

	public setBorderColor = (color: string) => {
		this.borderColor = color;
		this.element.style.setProperty('--border-color', color);
	};

	public getBorderColor = () => this.borderColor;

	public enableMutationMode = () => {
		const component = this.getParentComponent();
		const contexts = component.getCellContexts();

		for (let i = 0; i < contexts.length; i++) {
			contexts[i].enableMutationMode();
		}
	};

	public disableMutationMode = () => {
		const component = this.getParentComponent();
		const contexts = component.getCellContexts();

		for (let i = 0; i < contexts.length; i++) {
			contexts[i].disableFocus();
			contexts[i].disableMutationMode();
		}
	};

	public getElement = (): HTMLElement => this.element;
	public getCurrentRenderCells = () => [...this.currentRenderCells];

	/**
	 * Очищает поле с ячейками, физически удаляя HTML элементы ячеек из DOM представления.
	 */
	private clearLayer = () => {
		this.element.innerHTML = '';
	};

	/**
	 * Добавляет HTML элементы ячеек в контейнер.
	 * @param cells - коллекция ячеек.
	 */
	private injectCellElements = (cells: TableCell[]) => {
		if (this.startRow === null) {
			throw new ManipulatorError('start row not initialized');
		}

		const elements = cells.map(cell => cell.getElement());

		this.element.append(...elements);
	};

	private getParentComponent = (): TableComponent => {
		const parentComponent = this.parentGraphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}
		return parentComponent as TableComponent;
	};

	/**
	 * Устанавливает минимальную высоту ячейки.
	 * Добавлено, чтобы объединенные строки визуально не сливались в одну.
	 */
	private renderMinHeightRow = () => {
		if (this.rowsCount === null) {
			throw new ManipulatorError('row count not initialized');
		}
		this.element.style.gridTemplateRows = `repeat(${this.rowsCount}, minmax(${this.MIN_CELL_HEIGHT}px, auto))`;
	};

	/**
	 * Возвращает ячейки, входящие в карту ячеек.
	 * @param cells - все существующие ячейки.
	 * @param actualAreas - карта, по которой будут отобраны ячейки.
	 */
	private getCellsForCellMap = (cells: TableCell[], actualAreas: TableGridMap): TableCell[] => {
		const actualCells: TableCell[] = [];

		const areas = actualAreas.flat();
		cells.forEach(cell => {
			const id = cell.getID();
			if (areas.includes(id)) {
				actualCells.push(cell);
			}
		});

		return actualCells;
	};

	private setCurrentRenderCells = (cells: TableCell[]) => {
		this.currentRenderCells.length = 0;
		this.currentRenderCells = [...cells];
	};

	private compareCurrentCells = (actualCells: TableCell[]): boolean => {
		if (actualCells.length !== this.currentRenderCells.length) {
			return false;
		}
		for (let i = 0; i < actualCells.length; i++) {
			if (this.currentRenderCells[i] !== actualCells[i]) {
				return false;
			}
		}
		return true;
	};

	private prepareActualCells = (cells: TableCell[]) => {
		for (let i = 0; i < cells.length; i++) {
			cells[i].setStartRow(this.startRow);
		}
	};
}

export default TableCellsLayer;
