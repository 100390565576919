import HTMLGenerator from '../../../utils/HTMLGenerator';
import ElementContainer from '../../../utils/ElementContainer';
import HTMLElementName from '../../../utils/HTMLElementName';

/**
 * Базовый элемент для всех инструментов в боковом меню.
 */
abstract class ToolBarTool extends ElementContainer<HTMLDivElement> {
	private readonly elementEnableClassName: string;
	protected readonly clickListeners: VoidFunction[];
	protected readonly enableListeners: VoidFunction[];

	protected readonly hintElement: HTMLElement;

	private isEnable = false;

	protected constructor(className: string) {
		super(HTMLElementName.DIV);
		this.addRootElementClickListener(this.onClick);
		this.addRootElementMouseEnterListener(this.onMouseEnter);
		this.addRootElementMouseLeaveListener(this.onMouseLeave);

		this.clickListeners = [];
		this.enableListeners = [];
		this.elementEnableClassName = `${className}_active`;

		this.hintElement = HTMLGenerator.getDiv({ className: 'hint left' });
	}

	public setHint = (text: string) => {
		this.hintElement.textContent = text;
	};

	public enable = (): void => {
		if (this.isEnable) {
			return;
		}
		this.isEnable = true;
		this.rootElement.classList.add(this.elementEnableClassName);
		this.onEnable();
	};

	public disable = (): void => {
		if (!this.isEnable) {
			return;
		}
		this.rootElement.classList.remove(this.elementEnableClassName);
		this.isEnable = false;
		this.onDisable();
	};

	public addEnableListener = (listener: VoidFunction) => {
		this.enableListeners.push(listener);
	};

	public addClickListener = (listener: VoidFunction) => {
		this.clickListeners.push(listener);
	};

	protected onEnable = () => {
		this.enableListeners.forEach(listener => listener());
	};

	protected onDisable = () => {
		// nothing
	};

	protected showHint(): void {
		if (this.isEnable) {
			return;
		}
		this.rootElement.append(this.hintElement);
	}

	protected hideHint(): void {
		this.hintElement.remove();
	}

	private onClick = () => {
		this.enable();
		this.clickListeners.forEach(listener => listener());
	};

	private onMouseEnter = () => {
		this.showHint();
	};

	private onMouseLeave = () => {
		this.hideHint();
	};
}

export default ToolBarTool;
