import HTMLGenerator from '../utils/HTMLGenerator';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';

type SizePanelElements = {
	topTriggersElement: HTMLElement,
	bottomTriggersElement: HTMLElement,
	sizeElement: HTMLElement,
}

/**
 * Панель размеров фрейма.
 */
class SizePanel {
	private readonly TRIGGER_CLASS_NAME = 'page-frame__angle-trigger';
	private readonly TRIGGER_POSTFIX_TOP = 'top';
	private readonly TRIGGER_POSTFIX_BOTTOM = 'bottom';
	private readonly SIZE_PANEL_CLASS_NAME = 'page-frame__size-panel';
	private readonly SIZE_SEPARATOR = 'x';

	private readonly sizeElement: HTMLElement;
	private readonly widthText: HTMLElement;
	private readonly heightText: HTMLElement;
	private readonly topTriggers: HTMLElement;
	private readonly bottomTriggers: HTMLElement;

	constructor() {
		this.topTriggers = HTMLGenerator.getDiv({
			className: `${this.TRIGGER_CLASS_NAME} ${this.TRIGGER_POSTFIX_TOP}`,
		});
		this.bottomTriggers = HTMLGenerator.getDiv({
			className: `${this.TRIGGER_CLASS_NAME} ${this.TRIGGER_POSTFIX_BOTTOM}`,
		});

		this.sizeElement = HTMLGenerator.getDiv({
			className: this.SIZE_PANEL_CLASS_NAME,
		});
		this.widthText = HTMLGenerator.getParagraph();
		this.heightText = HTMLGenerator.getParagraph();
		this.buildSizeFrame();
	}

	public disable = () => {
		this.topTriggers.remove();
		this.bottomTriggers.remove();
		this.sizeElement.remove();
	};

	public setSize = (width: number, height: number) => {
		if (width < 0 || height < 0) {
			throw new ManipulatorError('frame sizes cannot be negative');
		}
		this.widthText.textContent = width.toString();
		this.heightText.textContent = height.toString();
	};

	public getElements = (): SizePanelElements => ({
		topTriggersElement: this.topTriggers,
		bottomTriggersElement: this.bottomTriggers,
		sizeElement: this.sizeElement,
	});

	public getSizeElement = (): HTMLElement => this.sizeElement;

	private buildSizeFrame = () => {
		const separator = HTMLGenerator.getSpan({
			text: this.SIZE_SEPARATOR,
		});
		this.sizeElement.append(this.widthText, separator, this.heightText);
	};
}

export default
SizePanel;
