import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../redux/reducers/reducer';
import { all, tagsGroupModalsActive } from '../../../../../../../../../redux/selectors/module/tagGroup';
import {
	setBindingGroupId,
	setIsBindingTagGroupModalActive,
} from '../../../../../../../../../redux/reducers/module/tagGroupReducer';
import MultipleSelectionBoard from '../../../../../../../../ui/multipleSelectionBoard/MultipleSelectionBoard';
import css from './multipleTagSelectionWindow.module.scss';
import { notificationError, notificationSuccess } from '../../../../../../../../Notifications/callNotifcation';
import { tagGroupAPI } from '../../../../../../../../../entities/modules/tag-group/api/api';
import { tagAPI } from '../../../../../../../../../entities/modules/tag/api/api';
import { NewModal } from '../../../../../../../../ui/modals/new-modal';
import { ModuleTag } from '../../../../../../../../../entities/modules/tag/ui/module-tag';

const MultipleTagSelectionWindow = () => {
	const dispatch = useDispatch();

	const [bindingIds, setBingingIds] = useState<string[]>([]);

	const isBindingTagGroupModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isBindingTagGroupModalActive);

	const bindingGroupId = useSelector((
		state: RootState,
	) => all(state).bindingGroupId);

	const handleBindingTagGroupModalActive = (value: boolean) => {
		dispatch(setIsBindingTagGroupModalActive(value));

		if (!value) dispatch(setBindingGroupId(null));
	};

	const cleanBindingIds = () => {
		setBingingIds([]);
	};

	const [bind] = tagGroupAPI.useBindMutation();

	const { data: tagsData } = tagAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	const onBindTag = async (tags: number[]) => {
		try {
			if (bindingGroupId !== null) {
				await bind({
					group: bindingGroupId,
					tags,
				})
					.unwrap();
				handleBindingTagGroupModalActive(false);
				if (tags.length > 1) {
					notificationSuccess('Теги успешно добавлены в группу!');
				} else {
					notificationSuccess('Тег успешно добавлен в группу!');
				}
			}
		} catch (e) {
			notificationError('Возникла ошибка при добавлении тега в группу...');
		}
	};

	const onBinding = async () => {
		const data = bindingIds.map(id => Number(id));

		if (data) {
			await onBindTag(data);
			cleanBindingIds();
		}
	};

	const items = useMemo(() => tagsData?.moduleTags?.map(tag => (
		{
			id: tag.id.toString(),
			elem: <ModuleTag tag={tag} styles={css.tag} />,
		}
	)), [tagsData]);

	useEffect(() => {
		if (!isBindingTagGroupModalActive) cleanBindingIds();
	}, [isBindingTagGroupModalActive]);

	return (
		<NewModal
			modalInModalActive
			active={isBindingTagGroupModalActive}
			closeModal={handleBindingTagGroupModalActive}
		>
			<MultipleSelectionBoard
				items={items}
				onBinding={onBinding}
				bindingIds={bindingIds}
				setBindingIds={setBingingIds}
				btnText="Добавить теги в группу"
				notFoundText="Тегов не найдено..."
			/>
		</NewModal>
	);
};

export default MultipleTagSelectionWindow;
