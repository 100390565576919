import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, providesList } from '../../../../shared/api/api';
import {
	IBindTagRequest,
	ICreateModuleTagResponse,
	IGetAvailableTagsRequest,
	IGetTagRequest,
	IGetTagResponse,
	IUnbindTagRequest,
	IUpdateTagRequest,
} from './types';
import { URI_TAG, URI_TAG_AVAILABLE } from './consts';

export const tagAPI = createApi({
	reducerPath: 'tagAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['tag'],
	endpoints: builder => ({
		create: builder.mutation<ICreateModuleTagResponse, { name: string }>({
			query: (data) => ({
				url: URI_TAG,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['tag'],
		}),
		getAll: builder.query<IGetTagResponse, IGetTagRequest>({
			query: (data) => ({
				url: URI_TAG,
				method: 'GET',
				params: data,
			}),
			providesTags: (result) => providesList(result?.moduleTags, 'tag'),
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `${URI_TAG}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['tag'],
		}),
		update: builder.mutation<void, IUpdateTagRequest>({
			query: ({
				id,
				name,
			}) => ({
				url: `${URI_TAG}/${id}`,
				method: 'PUT',
				body: {
					name,
				},
			}),
			invalidatesTags: ['tag'],
		}),
		getAvailable: builder.query<IGetTagResponse, IGetAvailableTagsRequest>({
			query: ({
				moduleId,
				...data
			}) => ({
				url: `${URI_TAG_AVAILABLE(moduleId)}/available`,
				method: 'GET',
				params: data,
			}),
			providesTags: ['tag'],
		}),
		bind: builder.mutation<void, IBindTagRequest>({
			query: ({
				id,
				tags,
			}) => ({
				url: URI_TAG_AVAILABLE(id),
				method: 'POST',
				body: {
					tags,
				},
			}),
		}),
		unbind: builder.mutation<void, IUnbindTagRequest>({
			query: ({
				id,
				tags,
			}) => ({
				url: URI_TAG_AVAILABLE(id),
				method: 'DELETE',
				body: {
					tags,
				},
			}),
		}),
	}),
});
