import React from 'react';
import cx from 'classnames';
import css from './info-icon.module.scss';

interface TemplateInfoIconProps {
	children: React.ReactNode,
	type: 'light' | 'bright',
}

export const TemplateInfoIcon = ({
	type,
	children,
}: TemplateInfoIconProps) => (
	<div className={cx(css.wrapper, css[type])}>
		{children}
	</div>
);
