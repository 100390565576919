import React from 'react';
import cx from 'classnames';
import { useAppDispatch } from '../../../shared/libs/redux';
import { ICategoryData } from '../../../entities/modules/category/api/types';
import {
	setDeletingCategoryId,
	setEditingCategoryId,
	setIsDeletingCategoryModalActive,
	setIsEditCategoryModalActive,
} from '../../../redux/reducers/module/categoryReducer';
import { ReactComponent as EditCategoryIcon } from '../images/edit.svg';
import { ReactComponent as DeleteCategoryIcon } from '../images/delete.svg';
import { ReactComponent as DraggableIcon } from '../images/draggable.svg';
import css from './category-card.module.scss';

export interface CategoryCardProps {
	isActive?: boolean,
	category: ICategoryData,
	draggable?: boolean,
	dragging?: boolean,
	underDragging?: boolean,
	handleCurrentCategory?: (id: number) => void,
}

export const CategoryCard = ({
	category,
	draggable,
	dragging,
	underDragging,
	isActive,
	handleCurrentCategory,
}: CategoryCardProps) => {
	const {
		id,
		name,
	} = category;

	const dispatch = useAppDispatch();

	const onEditCategory = (e: React.MouseEvent) => {
		e.stopPropagation();

		dispatch(setEditingCategoryId(id));
		dispatch(setIsEditCategoryModalActive(true));
	};

	const onDeleteCategory = (e: React.MouseEvent) => {
		e.stopPropagation();

		dispatch(setDeletingCategoryId(id));
		dispatch(setIsDeletingCategoryModalActive(true));
	};

	const onCategoryClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		if (handleCurrentCategory) {
			handleCurrentCategory(id);
		}
	};

	return (
		<div
			className={cx(
				css.wrapper,
				draggable && css.draggable,
				dragging && css.dragging,
				isActive && css.active,
				underDragging && css.underdragging,
			)}
			onClick={onCategoryClick}
			role="presentation"
		>
			{draggable && <div className={css.drag_icon}><DraggableIcon /></div>}
			<div className={css.text}>{name}</div>
			<div className={css.action_block}>
				<div
					onClick={onEditCategory}
					role="presentation"
				>
					<EditCategoryIcon />
				</div>
				<div
					onClick={onDeleteCategory}
					role="presentation"
				>
					<DeleteCategoryIcon />
				</div>
			</div>
		</div>
	);
};
