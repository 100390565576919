import React from 'react';
import css from './advanced-search-tag-dropdown.module.scss';
import { TagDropdownWrapper } from '../../../tag-dropdown-wrapper';
import { CreateTagDropdown } from '../../../create-tag-dropdown';
import { useAdvancedSearchSelectedTags } from '../../../../shared/libs/templates/useAdvancedSearchSelectedTags';
import { ITagData } from '../../../../entities/modules/tag/api/types';
import { ITemplateTag } from '../../../../entities/templates/tags/api/types';
import { IWorkshopTemplateTag } from '../../../../entities/templates/workshop/tag/api/types';

interface AdvancedSearchTagDropdownProps {
	search: string,
	selectedTags: ITagData[],
	isDropdownVisible: boolean,
	tags: ITagData[] | undefined,
	setSearch: (value: string) => void,
	createTag: (value: string) => Promise<ITemplateTag | IWorkshopTemplateTag | null>,
	setSelectedTags: (value: ITagData[]) => void,
}

export const AdvancedSearchTagDropdown = ({
	tags,
	search,
	setSearch,
	createTag,
	selectedTags,
	setSelectedTags,
	isDropdownVisible,
}: AdvancedSearchTagDropdownProps) => {
	const {
		handleTag,
		resetTags,
		selectedTagIds,
	} = useAdvancedSearchSelectedTags(selectedTags, setSelectedTags);

	return (
		<TagDropdownWrapper isDropdownVisible={isDropdownVisible}>
			<h5 className={css.title}>Теги</h5>
			<input
				type="search"
				value={search}
				maxLength={35}
				className={css.dropdown_input}
				onChange={e => setSearch(e.target.value)}
				placeholder="Поиск по тегам"
			/>
			<CreateTagDropdown
				creatingTagName={search}
				data={tags}
				createTag={() => createTag(search)}
				selectedTags={selectedTagIds}
				onTagClick={handleTag}
			/>
			<button
				type="button"
				onClick={resetTags}
				className={css.reset_btn}
			>
				Сбросить выбранные теги
			</button>
		</TagDropdownWrapper>
	);
};
