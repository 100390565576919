import { useNavigate } from 'react-router-dom';
import { offerAPI } from '../../../../entities/offer/api/api';
import ISketchStructure from '../../../../components/Sketch/ISketchStructure';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useSendOffer = () => {
	const navigate = useNavigate();

	const [createOffer, { isLoading }] = offerAPI.useCreateMutation();

	const sendOffer = async (structure: ISketchStructure | undefined, name: string) => {
		if (structure && name) {
			try {
				const res = await createOffer({
					name,
					deadline: '',
					manager: null,
					template: structure.id,
				})
					.unwrap();
				navigate(`/collection/commercial-panel/${res.offer.id}`);
			} catch (err) {
				notificationError('Возникла ошибка при отправке КП...');
			}
		}
	};

	return {
		sendOffer,
		isLoading,
	};
};
