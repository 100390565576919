import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import css from './inputField.module.scss';
import Sign from './sign/Sign';

export interface InputFieldProps {
    placeholder: string,
    type: 'text' | 'email' | 'tel' | 'number' | 'birthday' | 'hidePassword' | 'visiblePassword',
    status: 'success' | 'error' | 'clear',
    value: string,
    disabled?: boolean,
    children?: React.ReactNode,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
    signClick?: () => void,
	maxLength?: number,
    keyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
}

const statusClasses = {
	success: css.success,
	error: css.error,
	clear: '',
};

const InputField: React.FC<InputFieldProps> = ({
	signClick,
	keyDown = () => null,
	maxLength,
	children,
	onChange,
	disabled,
	status,
	value,
	type,
	onBlur,
	placeholder, 
}: InputFieldProps) => {
	InputField.defaultProps = {
		signClick: undefined,
		keyDown: undefined,
		disabled: undefined,
		children: undefined,
		maxLength: undefined,
	};
	let style = '';
	const [isFocus, setIsFocus] = useState(false);

	switch (status) {
	case 'error':
		style = statusClasses.error;
		break;
	case 'success':
		style = statusClasses.success;
		break;
	default:
		style = statusClasses.clear;
		break;
	}

	return (
		<div className={css.wrapper}>
			{children}
			{
				// eslint-disable-next-line no-nested-ternary
				type === 'tel' || type === 'birthday'
					? type === 'tel'
						? (
							<InputMask
								className={`${isFocus ? css.focused : ''} ${css.field}  ${value ? style : ''}`}
								placeholder={placeholder}
								onBlur={() => { setIsFocus(false); }}
								onChange={e => onChange(e)}
								value={value}
								disabled={disabled}
								onFocus={() => { setIsFocus(true); }}
								onKeyDown={e => keyDown(e)}
								mask="+7 999 999 99 99"
							/>
						)
						: (
							<input
								className={`${isFocus ? css.focused : ''} ${css.field}  ${value ? style : ''}`}
								placeholder={placeholder}
								onBlur={() => { setIsFocus(false); }}
								onChange={e => onChange(e)}
								value={value}
								disabled={disabled}
								onFocus={() => { setIsFocus(true); }}
								onKeyDown={e => keyDown(e)}
								maxLength={maxLength}
							/>
						)
					: (
						<input
							className={`${isFocus ? css.focused : ''} ${css.field}  ${value ? style : ''}`}
							type={
								// eslint-disable-next-line no-nested-ternary
								type === 'hidePassword' || type === 'visiblePassword'
									? type === 'hidePassword'
										? 'password'
										: 'text'
									: type
							}
							placeholder={placeholder}
							onBlur={() => { setIsFocus(false); }}
							onChange={e => onChange(e)}
							value={value}
							disabled={disabled}
							onFocus={() => { setIsFocus(true); }}
							onKeyDown={e => keyDown(e)}
							maxLength={maxLength}
						/>
					)
			}
			{value && (
				<Sign
					type={type}
					status={status}
					signClick={signClick}
				/>
			)}
			{
				type === 'birthday' && (
					<Sign
						type={type}
						status={status}
						signClick={signClick}
					/>
				)
			}
		</div>
	);
};

export default InputField;
