import AreaMutator from '../AreaMutator';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import IAreaPositionMutationBody from '../events/position/IAreaPositionMutationBody';
import AreaMutationEventType from '../events/AreaMutationEventType';
import IComponent from '../../../../components/IComponent';
import SpatialGraphicArea from '../../spatial-tree/spatial-area/areas/SpatialGraphicArea';
import { AnySpatialArea } from '../../../../Types';
import IFrameArea from '../../spatial-tree/spatial-area/IFrameArea';
import IMutableEntity from '../IMutableEntity';

/** Отвечает за изменение позиции любой области. */
class AreaPositionMutator extends AreaMutator<IAreaPositionMutationBody> {
	constructor() {
		super(AreaMutationEventType.POSITION);
		this.disableMaintainAspectRatio();
	}

	public postStartEvent = (areas: AnySpatialArea[]): void => {
		// nothing
	};

	public postStopEvent = (): void => {
		// nothing
	};

	public startMoveComponents = (
		initiatorArea: AnySpatialArea,
		focusComponents: IComponent[],
	) => {
		const areas: AnySpatialArea[] = [];

		focusComponents.forEach(component => {
			const graphics = component.getGraphics();
			graphics.forEach(graphic => {
				const area = new SpatialGraphicArea(graphic);
				areas.push(area);
			});
		});

		this.start(initiatorArea, ...areas);
	};

	protected updateOffsets = (offsetX: number, offsetY: number): void => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('offsets not initialize');
		}

		this.absoluteAreaOffsets.x += offsetX;
		this.absoluteAreaOffsets.y += offsetY;
	};

	protected getMutationEventBody = (): IAreaPositionMutationBody => ({});
	protected correctAspectRatioValues = (
		currentValues: IFrameArea,
		entity: IMutableEntity,
	): IFrameArea => currentValues;
}

export default AreaPositionMutator;
