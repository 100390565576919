import IFrameConfiguration from './IFrameConfiguration';
import HTMLGenerator from '../utils/HTMLGenerator';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';

/**
 * Физическая область для хранения графических элементов.
 * @abstract
 */
abstract class Frame {
	private readonly DEFAULT_MIN_WIDTH = 1;
	private readonly DEFAULT_MIN_HEIGHT = 1;
	private readonly LOWER_LIMIT_WIDTH = 6;
	private readonly LOWER_LIMIT_HEIGHT = 6;

	private readonly FRAME_CLASS_NAME = 'page-frame';
	private readonly CSS_PROPERTY_LEFT = '--left';
	private readonly CSS_PROPERTY_TOP = '--top';
	private readonly CSS_PROPERTY_HEIGHT = '--height';
	private readonly CSS_PROPERTY_WIDTH = '--width';
	private readonly CSS_PROPERTY_ROTATE = '--rotate';
	private readonly CSS_PROPERTY_LAYER = '--layer';

	protected readonly frameElement: HTMLElement;

	private readonly frameConfiguration: IFrameConfiguration;

	private minWidth: number;
	private minHeight: number;
	private postChangeListeners: (() => void)[] | null;

	protected constructor() {
		this.frameElement = HTMLGenerator.getDiv({
			className: this.FRAME_CLASS_NAME,
		});
		this.frameConfiguration = {
			x: -1,
			y: -1,
			layer: -1,
			height: 0,
			width: 0,
			rotate: 0,
		};
		this.minWidth = this.DEFAULT_MIN_WIDTH;
		this.minHeight = this.DEFAULT_MIN_HEIGHT;
		this.postChangeListeners = null;
	}

	public getFrameElement = (): HTMLElement => this.frameElement;
	public getFrameConfiguration = (): IFrameConfiguration => ({
		...this.frameConfiguration,
	});

	public setFrameConfiguration = (fn: (prev: IFrameConfiguration) => IFrameConfiguration) => {
		const currentConfiguration = this.getFrameConfiguration();
		let updateConfiguration = fn(currentConfiguration);

		updateConfiguration = {
			y: Math.round(updateConfiguration.y),
			x: Math.round(updateConfiguration.x),
			width: Math.round(updateConfiguration.width),
			height: Math.round(updateConfiguration.height),
			rotate: Math.round(updateConfiguration.rotate),
			layer: updateConfiguration.layer,
		};

		let isChanged = false;
		if (currentConfiguration.x !== updateConfiguration.x) {
			this.frameElement.style.setProperty(this.CSS_PROPERTY_LEFT, `${updateConfiguration.x}px`);
			this.frameConfiguration.x = updateConfiguration.x;
			isChanged = true;
		}
		if (currentConfiguration.layer !== updateConfiguration.layer) {
			this.frameElement.style.setProperty(this.CSS_PROPERTY_LAYER, `${updateConfiguration.layer}`);
			this.frameConfiguration.layer = updateConfiguration.layer;
			isChanged = true;
		}
		if (currentConfiguration.height !== updateConfiguration.height) {
			if (updateConfiguration.height >= this.minHeight) {
				this.frameElement.style.setProperty(this.CSS_PROPERTY_HEIGHT, `${updateConfiguration.height}px`);
				this.frameConfiguration.height = updateConfiguration.height;
				isChanged = true;
			}
		}
		if (currentConfiguration.width !== updateConfiguration.width) {
			if (updateConfiguration.width >= this.minWidth) {
				this.frameElement.style.setProperty(this.CSS_PROPERTY_WIDTH, `${updateConfiguration.width}px`);
				this.frameConfiguration.width = updateConfiguration.width;
				isChanged = true;
			}
		}
		if (currentConfiguration.y !== updateConfiguration.y) {
			this.frameElement.style.setProperty(this.CSS_PROPERTY_TOP, `${updateConfiguration.y}px`);
			this.frameConfiguration.y = updateConfiguration.y;
			isChanged = true;
		}
		if (currentConfiguration.rotate !== updateConfiguration.rotate) {
			this.frameElement.style.setProperty(
				this.CSS_PROPERTY_ROTATE,
				`rotate(${updateConfiguration.rotate}deg)`,
			);
			this.frameConfiguration.rotate = updateConfiguration.rotate;
			isChanged = true;
		}

		if (isChanged) {
			this.callFrameConfigurationChangeListeners();
		}
	};

	public appendFrame = (frame: Frame) => {
		const frameElement = frame.getFrameElement();
		this.frameElement.append(frameElement);
	};

	/** removeFrame удаляет элемент */
	public removeFrame = (): void => {
		this.frameElement.remove();
	};

	public addFrameConfigurationChangeListener = (event: () => void) => {
		if (this.postChangeListeners === null) {
			this.postChangeListeners = [];
		}
		this.postChangeListeners.push(event);
	};

	public callFrameConfigurationChangeListeners = () => {
		if (this.postChangeListeners !== null) {
			this.postChangeListeners.forEach((listener) => listener());
		}
	};

	public setMinWidth = (value: number) => {
		if (value < this.LOWER_LIMIT_WIDTH) {
			throw new ManipulatorError(`the minimum width cannot be less than ${this.LOWER_LIMIT_WIDTH}`);
		}
		this.minWidth = value;
	};

	public setMinHeight = (value: number) => {
		if (value < this.LOWER_LIMIT_HEIGHT) {
			throw new ManipulatorError(`the minimum height cannot be less than ${this.LOWER_LIMIT_HEIGHT}`);
		}
		this.minHeight = value;
	};

	public getFrameAspectRatio = (): number => this.frameConfiguration.width / this.frameConfiguration.height;

	public getMinWidth = (): number => this.minWidth;
	public getMinHeight = (): number => this.minHeight;
}

export default Frame;
