import InterfacePanel from '../../../InterfacePanel';
import InjectTextTool from './InjectTextTool';
import InjectPictureTool from './InjectPictureTool';
import InjectTableTool from './tableTool/InjectTableTool';
import InjectFigureTool from './InjectFigureTool';
import ToolBarTool from '../ToolBarTool';
import ComponentInjector from '../../../../component-injector/ComponentInjector';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';
import InjectLineTool from './InjectLineTool';

/**
 * Панель для навигации добавления новых компонентов.
 */
class InjectComponentPanel extends InterfacePanel {
	private readonly textTool: InjectTextTool;
	private readonly pictureTool: InjectPictureTool;
	private readonly tableTool: InjectTableTool;
	private readonly figureTool: InjectFigureTool;
	private readonly lineTool: InjectLineTool;
	private readonly tools: ToolBarTool[];

	constructor(useCases: IBaseUseCases, componentInjector: ComponentInjector) {
		super();
		this.textTool = new InjectTextTool();
		this.textTool.addEnableListener(() => {
			const isRunning = useCases.runInjectText();
			if (!isRunning) {
				this.textTool.disable();
				return;
			}
			componentInjector.addSingleUseInjectListener(this.textTool.disable);
		});

		this.pictureTool = new InjectPictureTool();
		this.pictureTool.addEnableListener(() => {
			useCases.runInjectPicture();
			componentInjector.addSingleUseInjectListener(this.pictureTool.disable);
		});

		this.tableTool = new InjectTableTool(useCases);
		this.tableTool.addEnableListener(componentInjector.stopInjectors);

		this.figureTool = new InjectFigureTool();
		this.figureTool.addEnableListener(() => {
			useCases.runInjectFigure();
			componentInjector.addSingleUseInjectListener(this.figureTool.disable);
		});

		this.lineTool = new InjectLineTool();
		this.lineTool.addEnableListener(() => {
			useCases.runInjectLine();
			componentInjector.addSingleUseInjectListener(this.lineTool.disable);
		});

		this.tools = [
			this.textTool,
			this.tableTool,
			this.pictureTool,
			this.figureTool,
			this.lineTool,
		];

		this.tools.forEach(tool => {
			this.rootElement.append(tool.getElement());
			tool.addEnableListener(this.disableToolsExpect.bind(this, tool));
		});

		this.setRootElementClassName('panel-components');
	}

	public disableTools = () => {
		this.tools.forEach(tool => tool.disable());
	};

	/**
	 * Отключает все инструменты, кроме указанного.
	 * @param exceptTool - Исключаемые инструмент.
	 */
	private disableToolsExpect = (exceptTool: ToolBarTool) => {
		this.tools.forEach(tool => {
			if (tool === exceptTool) {
				return;
			}
			tool.disable();
		});
	};
}

export default InjectComponentPanel;
