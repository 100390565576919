import { useState } from 'react';

export const useHint = () => {
	const [isHintVisible, setIsHintVisible] = useState(false);

	const onMouseEnter = () => setIsHintVisible(true);
	const onMouseLeave = () => setIsHintVisible(false);

	return {
		isHintVisible,
		onMouseEnter,
		onMouseLeave,
	};
};
