import React from 'react';
import { ACCOUNT_STATUS } from '../../../../../entities/user/account/api/types';
import { StatusAccess } from '../../../../../app/providers/status-access';
import { MainLayout } from '../../../layouts/main/ui/main';
import { IntegratorTabs } from './tabs/tabs';
import css from './integrator.module.scss';
import { IntegratorTabBlock } from './tab-block/tab-block';

const Integrator = () => (
	<StatusAccess accessStatus={[ACCOUNT_STATUS.INTEGRATOR, ACCOUNT_STATUS.SERVICE_ADMIN]}>
		<MainLayout>
			<div className={css.wrapper}>
				<div className={css.left_side_bar}>
					<IntegratorTabs />
				</div>
				<IntegratorTabBlock />
			</div>
		</MainLayout>
	</StatusAccess>
);

export default Integrator;
