import React from 'react';
import { useDispatch } from 'react-redux';
import BlockHeader from '../../blockHeader/BlockHeader';
import css from './tagBlock.module.scss';
import {
	setDeletingTagId,
	setEditingTagId,
	setIsCreatingTagModalActive,
	setIsDeletingTagModalActive,
	setIsEditingTagModalActive,
} from '../../../../../../../redux/reducers/module/tagReducer';
import { tagAPI } from '../../../../../../../entities/modules/tag/api/api';
import { EmptyList } from '../../../../../../ui/empty-list/empty-list';
import { ModuleTag } from '../../../../../../../entities/modules/tag/ui/module-tag';

const TagBlock = () => {
	const dispatch = useDispatch();

	const { data: tagsData } = tagAPI.useGetAllQuery({
		limit: 127,
		page: 1,
	});

	const openCreateModal = () => {
		dispatch(setIsCreatingTagModalActive(true));
	};

	const onDeletingTag = (id: number) => {
		dispatch(setDeletingTagId(id));
		dispatch(setIsDeletingTagModalActive(true));
	};

	const onEditingTag = (id: number) => {
		dispatch(setEditingTagId(id));
		dispatch(setIsEditingTagModalActive(true));
	};

	return (
		<BlockHeader
			title="Теги"
			onBtnClick={openCreateModal}
			btnText="Добавить тег"
		>
			{
				tagsData?.moduleTags
					? (
						<div className={css.tags_block}>
							{
								tagsData.moduleTags.map(tag => {
									const { id } = tag;

									return (
										<ModuleTag
											tag={tag}
											onDelete={() => onDeletingTag(id)}
											onEdit={() => onEditingTag(id)}
											key={id}
										/>
									);
								})
							}
						</div>
					)
					: (
						<EmptyList
							title="Создайте первый тег к модулю"
							needBtn={false}
						/>
					)
			}
		</BlockHeader>
	);
};

export default TagBlock;
