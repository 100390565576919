import MagneticLine from './magnetic-line/MagneticLine';
import IFrameArea from '../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import DirectionVector from './DirectionVector';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IDescartesPosition from '../../utils/IDescartesPosition';

/**
 * Собирательная сущность магнитной линии, предоставляющая удобный интерфейс
 * для взаимодействия с ней.
 */
class MagneticLineEntity {
	// Отступ, при котором появляется магнитная линия
	private readonly VISUALIZE_LINE_OFFSET = 5;
	// Отступ, при котором графика притягивается к линии
	private readonly MAGNETIZATION_OFFSET = 5;

	public readonly line: MagneticLine;
	public readonly linePosition: IDescartesPosition;

	constructor(line: MagneticLine) {
		this.line = line;
		this.linePosition = line.getPosition();
	}

	/**
	 * Находится ли полученная область в зоне появления линии.
	 * @param frameArea - область для сканирования.
	 */
	public isVisualizeZone = (frameArea: IFrameArea) => this.isCollision(frameArea, this.VISUALIZE_LINE_OFFSET);

	/**
	 * Находится ли полученная область в зоне притягивания графики к линии.
	 * @param frameArea - проверяемая область.
	 */
	public isMagnetizationZone = (frameArea: IFrameArea) => this.isCollision(frameArea, this.MAGNETIZATION_OFFSET);

	/**
	 * Проверяет, пересекает ли область переданный отступ от линии.
	 * @param frameConfiguration - проверяемая область.
	 * @param offset - отступ от магнитной линии.
	 * @returns DirectionVector в случае пересечения, в противном - `null`;
	 */
	private isCollision = (frameConfiguration: IFrameArea, offset: number): DirectionVector | null => {
		switch (this.line.getDirection()) {
		case DirectionVector.LEFT:
			return this.isCollisionX(frameConfiguration, offset);
		case DirectionVector.RIGHT:
			return this.isCollisionX(frameConfiguration, offset);
		case DirectionVector.TOP:
			return this.isCollisionY(frameConfiguration, offset);
		case DirectionVector.BOTTOM:
			return this.isCollisionY(frameConfiguration, offset);
		case DirectionVector.MIDDLE_X:
			return this.isCollisionY(frameConfiguration, offset);
		case DirectionVector.MIDDLE_Y:
			return this.isCollisionX(frameConfiguration, offset);
		default: throw new ManipulatorError('direction not found');
		}
	};

	/**
	 * Проверяет, пересекает ли область границу горизонтальным ребром, а также горизонтальной серединой.
	 * @param frameConfiguration - проверяемая область.
	 * @param offset - отступ от магнитной линии.
	 */
	private isCollisionX = (frameConfiguration: IFrameArea, offset: number): DirectionVector | null => {
		if (this.isPositionInRange(frameConfiguration.x, this.linePosition.x, offset)) {
			return DirectionVector.LEFT;
		}
		if (this.isPositionInRange(
			frameConfiguration.x + (frameConfiguration.width / 2),
			this.linePosition.x,
			offset,
		)) {
			return DirectionVector.MIDDLE_X;
		}
		if (this.isPositionInRange(frameConfiguration.x + frameConfiguration.width, this.linePosition.x, offset)) {
			return DirectionVector.RIGHT;
		}
		return null;
	};

	/**
	 * Проверяет, пересекает ли область границу вертикальным ребром, а также вертикальной серединой.
	 * @param frameConfiguration - проверяемая область.
	 * @param offset - отступ от магнитной линии.
	 */
	private isCollisionY = (frameConfiguration: IFrameArea, offset: number): DirectionVector | null => {
		if (this.isPositionInRange(frameConfiguration.y, this.linePosition.y, offset)) {
			return DirectionVector.TOP;
		}
		if (this.isPositionInRange(
			frameConfiguration.y + (frameConfiguration.height / 2),
			this.linePosition.y,
			offset,
		)) {
			return DirectionVector.MIDDLE_Y;
		}
		if (this.isPositionInRange(frameConfiguration.y + frameConfiguration.height, this.linePosition.y, offset)) {
			return DirectionVector.BOTTOM;
		}
		return null;
	};

	/**
	 * Входит ли позиция в диапазон.
	 * @param position - координата позиции.
	 * @param prop - значение, от которого будет отсчитываться тригерная зона.
	 * @param offset - отступ зоны от основного значения в одну сторону.
	 */
	private isPositionInRange = (position: number, prop: number, offset: number) => position > prop - offset
		&& position < prop + offset;
}

export default MagneticLineEntity;
