export const calculateDuration = (
	date1: Date | null,
	date2: Date | null,
) => {
	const time1 = date1?.getTime() || 0;
	const time2 = date2?.getTime() || 0;

	const difference = Math.round((time2 - time1) / (1000 * 60));

	return difference;
};
