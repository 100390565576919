import { ISelectAbleCardData } from '../../../../../shared/ui';

export const defaultWorkshopPublishFilterData: ISelectAbleCardData = {
	id: 'all',
	text: 'Все',
};

export const galleryWorkshopPublishFilterData: ISelectAbleCardData = {
	id: 'gallery',
	text: 'Опубликованные в галерее',
};

export const userWorkshopPublishFilterData: ISelectAbleCardData = {
	id: 'user',
	text: 'Опубликованные у пользователя',
};

export const defaultWorkshopTariffFilterData: ISelectAbleCardData = {
	id: 'tariff',
	text: 'Тариф',
};
