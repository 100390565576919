import React from 'react';
import css from './roles.module.scss';
import { ReactComponent as WhiteBtnPlusIcon } from '../../../../../shared/images/white-plus.svg';
import { ReactComponent as EmptyRolesIcon } from '../images/empty-roles.svg';
import { MainButton } from '../../../../ui/main-button/main-button';

interface EmptyRolesListProps {
	isHaveRightToShowCreateRoleModal: () => void,
}

const EmptyRoles = (props: EmptyRolesListProps) => {
	const { isHaveRightToShowCreateRoleModal } = props;

	return (
		<div className={css.noRolesBlock}>
			<div className={css.picture}>
				<EmptyRolesIcon />
			</div>
			<div className={css.title}>Нет ролей</div>
			<div className={css.descr}>
				Создайте первую роль кликнув на
				<br />
				{' '}
				кнопку ниже
			</div>
			<MainButton
				type="button"
				onClick={isHaveRightToShowCreateRoleModal}
				btnStyle="bright"
				styles={css.empty_button}
			>
				<WhiteBtnPlusIcon />
				Создать роль
			</MainButton>
		</div>
	);
};

export default EmptyRoles;
