import cx from 'classnames';
import { IWorkshopTemplateTag } from '../../../../../../../../../../entities/templates/workshop/tag/api/types';
import css from './gallery-category.module.scss';

interface GalleryCategoryProps {
	selected?: boolean,
	category: IWorkshopTemplateTag,
	onClick: (category: IWorkshopTemplateTag) => void,
}

export const GalleryCategory = ({
	onClick,
	category,
	selected,
}: GalleryCategoryProps) => {
	const { name } = category;

	const handleClick = () => onClick(category);

	return (
		<button type="button" className={cx(css.wrapper, selected && css.selected)} onClick={handleClick}>
			{name}
		</button>
	);
};
