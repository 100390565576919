import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWorkshopTagGroupScheme } from '../types/WorkshopTagGroupScheme';

const initialState: IWorkshopTagGroupScheme = {
	deletingGroupId: undefined,
	isDeleteGroupModalActive: false,

	editingGroupId: undefined,
	editGroupName: undefined,
	isEditingGroupModalActive: false,

	createdGroupTags: [],
	createGroupName: undefined,
	isCreateGroupModalActive: false,

	bindingGroupId: undefined,
	bindingTags: [],
	isBindingGroupModalActive: false,

	unbindingGroupId: undefined,
	unbindingTags: [],
	isUnbindingGroupModalActive: false,
};

export const workshopTagGroupSlice = createSlice({
	name: 'workshopTagGroup',
	initialState,
	selectors: {
		getIsCreateGroupModalActive: (state) => state.isCreateGroupModalActive || false,
		getIsDeleteGroupModalActive: (state) => state.isDeleteGroupModalActive || false,
		getIsEditingGroupModalActive: (state) => state.isEditingGroupModalActive || false,
		getDeletingGroupId: (state) => state.deletingGroupId,
		getEditingGroupId: (state) => state.editingGroupId,
		getCreateGroupName: (state) => state.createGroupName || '',
		getEditGroupName: (state) => state.editGroupName || '',
		getCreatedGroupTags: (state) => state.createdGroupTags || [],
		getBindingGroupId: (state) => state.bindingGroupId,
		getBindingTags: (state) => state.bindingTags || [],
		getIsBindingGroupModalActive: (state) => state.isBindingGroupModalActive || false,

		getUnbindingGroupId: (state) => state.unbindingGroupId,
		getUnbindingTags: (state) => state.unbindingTags || [],
		getIsUnbindingGroupModalActive: (state) => state.isUnbindingGroupModalActive || false,
	},
	reducers: {
		setIsCreateGroupModalActive(state, action: PayloadAction<boolean>) {
			state.isCreateGroupModalActive = action.payload;
		},
		setIsDeleteGroupModalActive(state, action: PayloadAction<boolean>) {
			state.isDeleteGroupModalActive = action.payload;
		},
		setIsEditingGroupModalActive(state, action: PayloadAction<boolean>) {
			state.isEditingGroupModalActive = action.payload;
		},
		addCreatedGroupTag(state, action: PayloadAction<number>) {
			state.createdGroupTags.push(action.payload);
		},
		removeCreatedGroupTag(state, action: PayloadAction<number>) {
			state.createdGroupTags = state.createdGroupTags.filter((tag) => tag !== action.payload);
		},
		setCreatedGroupTags(state, action: PayloadAction<number[]>) {
			state.createdGroupTags = action.payload;
		},
		setDeletingGroupId(state, action: PayloadAction<number>) {
			state.deletingGroupId = action.payload;
		},
		setEditingGroupId(state, action: PayloadAction<number | undefined>) {
			state.editingGroupId = action.payload;
		},
		setCreateGroupName(state, action: PayloadAction<string>) {
			state.createGroupName = action.payload;
		},
		setEditGroupName(state, action: PayloadAction<string>) {
			state.editGroupName = action.payload;
		},
		setBindingGroupId(state, action: PayloadAction<number | undefined>) {
			state.bindingGroupId = action.payload;
		},
		setBindingTags(state, action: PayloadAction<number[]>) {
			state.bindingTags = action.payload;
		},
		setIsBindingGroupModalActive(state, action: PayloadAction<boolean>) {
			state.isBindingGroupModalActive = action.payload;
		},

		setUnbindingGroupId(state, action: PayloadAction<number | undefined>) {
			state.unbindingGroupId = action.payload;
		},
		setUnbindingTags(state, action: PayloadAction<number[]>) {
			state.unbindingTags = action.payload;
		},
		setIsUnbindingGroupModalActive(state, action: PayloadAction<boolean>) {
			state.isUnbindingGroupModalActive = action.payload;
		},
	},
});

export const { actions: workshopTagGroupActions, reducer: workshopTagGroupReducer } = workshopTagGroupSlice;
