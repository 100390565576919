import axios, { AxiosInstance } from 'axios';
import { ERROR_MESSAGE_LIST, notificationError } from '../components/Notifications/callNotifcation';
import Emoji from './group/emoji';
import File from './group/file';

export const SERVER_ENVIRONMENT_RELEASE = 'release';
export const SERVER_ENVIRONMENT_TEST = 'testing';
export const SERVER_ENVIRONMENT_DEV = 'dev';

const BASE_API_URL_PRODUCTION = 'https://wakadoo.ru/api/';
const BASE_API_URL_TEST = 'https://test.wakadoo.ru/api/';
const BASE_API_URL_DEV = 'http://localhost:25504/api/';

/* Слой доступа к данным */
class DAL {
	private readonly axios: AxiosInstance;

	public emoji: Emoji;
	public file: File;

	constructor() {
		const serverEnvironment = process.env.REACT_APP_ENVIRONMENT_SERVER;
		let apiUrl;
		switch (serverEnvironment) {
		case SERVER_ENVIRONMENT_RELEASE:
			apiUrl = BASE_API_URL_PRODUCTION;
			break;
		case SERVER_ENVIRONMENT_TEST:
			apiUrl = BASE_API_URL_TEST;
			break;
		default:
			apiUrl = BASE_API_URL_DEV;
		}

		this.axios = axios.create({
			baseURL: apiUrl,
			withCredentials: true,
		});
		this.setInterceptors();

		this.emoji = new Emoji(this.axios);
		this.file = new File(this.axios);
	}

	private setInterceptors = () => {
		this.axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response) {
					switch (error.response.status) {
					case 400: {
						if (process.env.NODE_ENV === 'development') console.error(error.response.data);
						notificationError(ERROR_MESSAGE_LIST.ERROR, 'Введенные данные некорректны!');
						return Promise.reject(error);
					}
					// case 401: {
					//     (store.dispatch as ThunkDispatch<any, any, any>)(logoutAccount)
					//     return new Promise(() => { })
					// }
					default: {
						console.error(error.response.data);
						return Promise.reject(error);
					}
					}
				} else if (error.request) {
					notificationError(
						'Ошибка получения данных!',
						'Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время!',
					);
					return Promise.reject(error);
				} else {
					if (axios.isCancel(error)) {
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						return new Promise(() => {
						});
					}
					return Promise.reject(error);
				}
			},
		);
	};
}

const API = new DAL();

export default API;
