import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../../utils/SvgCollection';
import Utils from '../../../../../utils/impl/Utils';
import IBaseUseCases from '../../../../../use-cases/base/IBaseUseCases';

class ExcelLoader {
	private readonly element: HTMLElement;
	private readonly ELEMENT_CLASS_NAME = 'table-constructor__load-excel';

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		this.useCases = useCases;
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
			fnMouseDown: this.onElementClick,
		});
		Utils.DOM.injectSVG(this.element, SvgCollection.EXCEL_LOADER);

		const loadExcelButton = HTMLGenerator.getSpan({
			className: 'table-constructor__load-excel-btn',
			text: 'Загрузить Excel-таблицу',
		});
		this.element.append(loadExcelButton);
	}

	public getElement = () => this.element;

	private onElementClick = () => {
		this.useCases.createTableFromXLSX();
	};
}

export default ExcelLoader;
