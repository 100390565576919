import ImageState, { IImageStateDependencies } from '../foundation/image/ImageState';
import PictureGraphic from './PictureGraphic';

interface IPictureTextureStateDependencies extends IImageStateDependencies {}

/**
 * Хранить в себе текущее состояние отображения изображения. Состояние представляется блоками,
 * наследованными от `PictureGraphicBlock`.
 */
class PictureTextureState extends ImageState<IPictureTextureStateDependencies> {
	constructor(graphic: PictureGraphic) {
		super();
		this.connectDependencies({
			graphic,
		});
		this.injectDependencies();
	}
}

export default PictureTextureState;
