import { templateModuleCategoryApi } from '../../../../entities/template-module-category';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useLikeCategory = () => {
	const [likeCategory] = templateModuleCategoryApi.useCreateFavoriteCategoryMutation();

	const onLikeCategory = async (categoryId?: number) => {
		try {
			if (categoryId) {
				await likeCategory({ categoryId }).unwrap();
			}
		} catch (error) {
			notificationError('Не удалось добавить категорию в избранные');
		}
	};

	return { onLikeCategory };
};
