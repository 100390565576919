import { useRef, useState } from 'react';

export const useInputFocus = (searchLength: number, setDropdownVisible: (value: boolean) => void) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const [focused, setFocused] = useState(false);

	// * Когда инпут в фокусе
	const onFocusInput = () => {
		if (!focused) {
			setFocused(true);
			setDropdownVisible(true);
		}
	};

	// * Когда инпут вне фокуса
	const onBlurInput = () => {
		if (focused) setFocused(false);
	};

	const focusInput = () => {
		if (!focused) {
			inputRef.current?.focus();
			setDropdownVisible(true);
		}
	};

	const isFocused = (searchLength > 0) || focused;

	return {
		isFocused,
		onBlurInput,
		onFocusInput,
		inputRef,
		focusInput,
	};
};
