import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import css from './choiceBlock.module.scss';
import RolesList from './RolesList';
import TeamsList from './TeamsList';
import { ReactComponent as OpenArrowIcon } from '../../image/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../image/close-arrow.svg';
import DefineBlock, { DefineChoiceBlockProps } from './DefineBlock';
import { IRoleItem } from '../../entities/role/api/types';
import { dropDownTransitionClasses } from '../../styles/transitionStyles/transitionStyles';
import { useOutSideClick } from '../../hooks/mouse/useNewOutSideClick';
import { IAccount } from '../../entities/user/account/api/types';

export const ITEMS_TEAMS = 'ITEMS_TEAMS';
export const ITEMS_ROLES = 'ITEMS_ROLES';

interface ChoiceBlockProps extends DefineChoiceBlockProps {
	roles?: IRoleItem[] | undefined,
	users?: IAccount[] | undefined,
	isBlockShow?: boolean,
	isNeedChoiceBlock?: boolean
	setBlockShown?: (value: boolean) => void,
	onChoicedRole?: (role: IRoleItem) => void,
	onChoicedUser?: (user: IAccount | null) => void
}

const ChoiceBlock = ({
	onChoicedRole,
	setBlockShown,
	isNeedChoiceBlock,
	isBlockShow,
	choicedUser,
	roles,
	items,
	users,
	choicedRole,
	onChoicedUser,
}: ChoiceBlockProps) => {
	const dropDownRef = useRef(null);

	// * При закрытии блока выбора
	const onCloseChoiceBlock = () => {
		if (!setBlockShown) return;

		setBlockShown(false);
	};

	const choiceBlockRef = useOutSideClick(onCloseChoiceBlock);

	const choicedBlockStyles = { cursor: `${(roles || users) ? 'pointer' : 'default'}` };
	const hiddenBlockStyles = {
		minHeight: `${(roles && roles.length === 1) || (users && users.length === 1) ? 51 : 0}px`,
		maxHeight: '175px',
	};

	return (
		<div className={css.wrapper} ref={choiceBlockRef}>
			<div className={css.content}>
				{
					isNeedChoiceBlock && (
						<div
							className={cx(css.choicedWrapper, isBlockShow && css.open)}
							style={choicedBlockStyles}
							onClick={
								() => (setBlockShown
									&& (roles || users) && setBlockShown(!isBlockShow))
							}
							role="presentation"
						>
							<DefineBlock items={items} choicedRole={choicedRole} choicedUser={choicedUser} />
							<div className={css.arrow}>
								{isBlockShow ? <OpenArrowIcon /> : <CloseArrowIcon />}
							</div>
						</div>
					)
				}
			</div>
			<CSSTransition
				in={isBlockShow}
				classNames={dropDownTransitionClasses}
				nodeRef={dropDownRef}
				timeout={200}
				unmountOnExit
			>
				<div
					className={css.hiddenBlock}
					style={hiddenBlockStyles}
					ref={dropDownRef}
				>
					{roles && <RolesList roles={roles} onChoicedRole={onChoicedRole} />}
					{
						users && (
							<TeamsList
								users={users}
								onChoicedUser={onChoicedUser}
								setBlockShown={setBlockShown}
							/>
						)
					}
				</div>
			</CSSTransition>
		</div>
	);
};

export default ChoiceBlock;
