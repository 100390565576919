import React, { useMemo } from 'react';
import { MultipleSelection } from '../../../../components/ui/multiple-selection';
import UserCard from './user-card/user-card';
import { TSelection } from '../../../../components/ui/multiple-selection/model/types';
import { IAccount } from '../../../../entities/user/account/api/types';

interface MultipleUserSelectionProps {
	btnText: string,
	isBinding: boolean,
	selection?: TSelection,
	availableUsers?: IAccount[],
	onBind: (emails: string[]) => void,
	isEntityUserSelectionModalActive: boolean,
	handleIsEntityUserSelectionModalActive: (value: boolean) => void,
}

export const MultipleUserSelection = ({
	onBind,
	btnText,
	isBinding,
	availableUsers,
	selection = 'multiple',
	isEntityUserSelectionModalActive,
	handleIsEntityUserSelectionModalActive,
}: MultipleUserSelectionProps) => {
	const items = useMemo(() => availableUsers?.map(({
		email,
		name,
		photo,
	}) => (
		{
			id: email,
			elem: <UserCard name={name} photo={photo} />,
		}
	)), [availableUsers]);

	return (
		<MultipleSelection
			items={items}
			onAdd={onBind}
			btnText={btnText}
			isLoading={isBinding}
			selection={selection}
			notFoundText="Пользователей не найдено..."
			isActive={isEntityUserSelectionModalActive}
			setIsActive={handleIsEntityUserSelectionModalActive}
		/>
	);
};
