import { useNavigate } from 'react-router-dom';
import React from 'react';
import { ITeamTemplateData } from '../../../../../../../../../entities/templates/team/api/types';
import { TemplateCard } from '../../../../../../../../../widgets/templates/template-card';
import useContextMenu from '../../../../../../../../../hooks/mouse/useContextMenu';
import { TeamTemplateContextMenu } from './context-menu/context-menu';
import { useTeamContextMenuActions } from '../utils/useTeamContextMenuActions';
import { useAppDispatch } from '../../../../../../../../../shared/libs/redux';
import css from './team-template.module.scss';
import { setSelectedTeamTemplate } from '../../../../../../../../../entities/templates/team/model/slice';

interface TeamTemplateCardProps {
	template: ITeamTemplateData,
}

export const TeamTemplateCard = ({ template }: TeamTemplateCardProps) => {
	const { id } = template;

	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	// * Перейти на панель шаблона
	const toTemplatePanel = () => navigate(`/collection/template-team-panel/${id}`);

	const {
		onMoveTemplate,
		setIsActionMenuOpened,
		onCopyLink,
		onSendOffer,
		isActionMenuOpened,
		onDelete,
	} = useTeamContextMenuActions(id);

	const {
		menuRef,
		menuPosition,
		handleItemClick,
		wrapperRef,
	} = useContextMenu({
		setMenuVisible: setIsActionMenuOpened,
	});

	const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		dispatch(setSelectedTeamTemplate(template));
		handleItemClick(e);
	};

	return (
		<div
			role="presentation"
			ref={wrapperRef}
			className={css.wrapper}
			onClick={toTemplatePanel}
			onContextMenu={onContextMenu}
		>
			<TemplateCard
				template={template}
				selected={false}
				isEditableTitle={false}
			/>
			<TeamTemplateContextMenu
				menuRef={menuRef}
				onDelete={onDelete}
				onCopyLink={onCopyLink}
				onSendOffer={onSendOffer}
				menuPosition={menuPosition}
				onMoveTemplate={onMoveTemplate}
				isActionMenuOpened={isActionMenuOpened}
				setIsActionMenuOpened={setIsActionMenuOpened}
			/>
		</div>
	);
};
