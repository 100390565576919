import React from 'react';
import { TagProps } from './TagMenu';
import { ReactComponent as TagChoicedIcon } from '../../../pages/collection/section/template/images/tag-choiced.svg';
import css from './tag.module.scss';

const Tag: React.FC<TagProps> = ({
	choiced,
	name,
	onClick,
}: TagProps) => (
	<div
		className={css.tagWrapper}
		onClick={onClick}
		role="presentation"
	>
		{
			choiced && (
				<div className={css.choiceIcon}>
					<TagChoicedIcon />
				</div>
			)
		}
		<div className={css.text}>{name}</div>
	</div>
);

export default Tag;
