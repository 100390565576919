import React from 'react';
import { useMoveTeamTemplates } from '../../utils/useMoveTeamTemplates';
import { DeleteModalWrapper } from '../../../../../../../ui/delete-modal-wrapper/delete-modal-wrapper';

export const MoveTeamTemplateModal = () => {
	const {
		active,
		setActive,
		moveTemplate,
	} = useMoveTeamTemplates();

	return (
		<DeleteModalWrapper
			title="Подтверждение перемещения"
			description="Вы действительно хотите переместить шаблон?"
			isActive={active}
			setIsActive={setActive}
			onDelete={moveTemplate}
		/>
	);
};
