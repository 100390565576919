import { templatePrivateAPI } from '../../../../../entities/templates/private/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { teamTemplateAPI } from '../../../../../entities/templates/team/api/api';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { ITagData } from '../../../../../entities/modules/tag/api/types';

export const useBindTemplateTag = (
	type: 'TEAM_TEMPLATE' | 'PRIVATE_TEMPLATE',
	template: string,
) => {
	const dispatch = useAppDispatch();

	const [bindPrivateTag] = templatePrivateAPI.useBindMutation();
	const [bindTeamTag] = teamTemplateAPI.useBindMutation();

	const onBindPrivateTemplateTag = async (tag: ITagData) => {
		const { id } = tag;

		try {
			await bindPrivateTag({
				tagId: id.toString(),
				templateId: template,
			})
				.unwrap();

			dispatch(templatePrivateAPI.util?.invalidateTags(['templates']));

			notificationSuccess('Тег успешно добавлен шаблону!');
		} catch (e) {
			notificationError('Произошла ошибка при добавлении тега...');
		}
	};

	const onUnbindTeamTemplateTag = async (tag: ITagData) => {
		const { id } = tag;
		try {
			await bindTeamTag({
				tagId: id.toString(),
				templateId: template,
			})
				.unwrap();

			dispatch(teamTemplateAPI.util?.invalidateTags(['team']));

			notificationSuccess('Тег успешно добавлен шаблону!');
		} catch (e) {
			notificationError('Произошла ошибка при добавлении тега...');
		}
	};

	if (type === 'PRIVATE_TEMPLATE') return onBindPrivateTemplateTag;

	return onUnbindTeamTemplateTag;
};
