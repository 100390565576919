import React, { useRef } from 'react';
import cx from 'classnames';
import css from './createInput.module.scss';

interface CreateInputProps {
	text: string,
	subTitle: string,
	maxLength?: number,
	defaultValue?: string,
	onChange: (value: string) => void,
	cssClass?: string,
}

const CreateInput = ({
	text,
	subTitle,
	onChange,
	maxLength,
	cssClass,
	defaultValue,
}: CreateInputProps) => {
	const [focused, setFocused] = React.useState(false);

	const onFocus = () => setFocused(true);
	const onBlur = () => setFocused(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const onActiveInput = () => {
		inputRef.current?.focus();
	};

	return (
		<button
			type="button"
			className={cx(css.wrapper, cssClass, focused && css.focused)}
			onClick={onActiveInput}
		>
			<input
				type="text"
				value={text}
				onChange={(e) => onChange(e.target.value)}
				className={css.input}
				placeholder={defaultValue}
				maxLength={maxLength}
				ref={inputRef}
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			{
				text && (
					<div className={css.subtitle}>
						{subTitle}
					</div>
				)
			}
		</button>
	);
};

export default CreateInput;
