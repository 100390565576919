import IGraphic from '../../../../IGraphic';
import ImageGraphicBlock from '../../ImageGraphicBlock';
import HTMLGenerator from '../../../../../utils/HTMLGenerator';

/**
 * Блок для отображения состояния изображения на этапе загрузки изображения на сервер.
 */
class LoadingPictureBlock extends ImageGraphicBlock {
	private readonly ELEMENT_CLASS_NAME = 'picture-block__loading';
	private readonly SKELETON_CLASS_NAME = 'picture-block__loading-picture';

	private readonly skeletonElement: HTMLElement;
	constructor(graphic: IGraphic) {
		super(graphic);

		this.setElementClassName(this.ELEMENT_CLASS_NAME);

		this.skeletonElement = HTMLGenerator.getDiv({
			className: this.SKELETON_CLASS_NAME,
		});

		this.element.append(this.skeletonElement);
	}
}

export default LoadingPictureBlock;
