import { useMemo, useState } from 'react';
import { fixCalendarDate } from '../../../../../shared/libs/utils/fix-calendar-date';

export const useTariffDate = () => {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

	const startDate = new Date();

	const formattedDate = useMemo(() => fixCalendarDate(selectedDate?.toString()), [selectedDate]);

	const handleDateWithCalendar = (date: Date | null) => {
		setSelectedDate(date);
		if (date) {
			setIsCalendarOpen(true);
		} else {
			setIsCalendarOpen(false);
		}
	};

	return {
		selectedDate,
		startDate,
		formattedDate,
		handleDateWithCalendar,
		setSelectedDate,
		setIsCalendarOpen,
		isCalendarOpen,
	};
};
