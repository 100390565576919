import { workshopCategoryApi } from '../../../entities/workshop-category';
import { useAppDispatch } from '../../../shared/libs/redux';
import { workshopModuleApi } from '../../../entities/workshop-module';

export const useBindWorkshopModuleCategory = (moduleId?: string) => {
	const dispatch = useAppDispatch();

	const [bindCategory] = workshopCategoryApi.useBindMutation();

	const onBindCategory = async ({ id }: {id: number}) => {
		if (id && moduleId) {
			await bindCategory({ moduleId, categories: [id] }).unwrap();

			dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));
		}
	};

	return { onBindCategory };
};
