import AreaPositionMutator from './AreaPositionMutator';
import KeyboardObserver from '../../../../utils/observers/KeyboardObserver';

/**
 * Мутатор позиции с возможностью клонирования компонента через его перемещение.
 */
class AreaPositionWithCloneMutator extends AreaPositionMutator {
	private readonly keyboardObserver: KeyboardObserver;
	private readonly fnClone: VoidFunction;

	/* Флаг указывает можно ли копировать в мутации (от startMoveComponents() до this.stop() ) чтобы не
	копировать на каждый mouseMove */
	private isCanCopy;

	constructor(keyboardObserver: KeyboardObserver, fnClone: VoidFunction) {
		super();
		this.isCanCopy = true;
		this.keyboardObserver = keyboardObserver;
		this.fnClone = fnClone;

		this.addPrevMutationListener(this.onClone);
		this.addStopMutationListener(this.onStopMutation);
	}

	private onClone = () => {
		if (this.isCanCopy && this.isBlindZoneOverstepping() && this.keyboardObserver.isAltPressed) {
			this.fnClone();
			this.isCanCopy = false;
		}
	};

	private readonly onStopMutation = () => {
		this.isCanCopy = true;
	};
}

export default AreaPositionWithCloneMutator;
