import React from 'react';
import { useAppSelector } from '../../../../../../../../shared/libs/redux';
import { DeleteModalWrapper } from '../../../../../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useDeleteTeamTemplate } from '../../utils/useDeleteTeamTemplate';

export const DeleteTeamTemplateModal = () => {
	const isActive = useAppSelector(state => state.teamTemplate.isConfirmTeamDeleteModalActive);

	const {
		isLoading,
		setActive,
		deleteTemplate,
	} = useDeleteTeamTemplate();

	return (
		<DeleteModalWrapper
			onDelete={deleteTemplate}
			isActive={isActive}
			isLoading={isLoading}
			setIsActive={setActive}
			description="Вы действительно хотите удалить шаблон без возможности восстановления?"
		/>
	);
};
