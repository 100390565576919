import IGraphic from '../graphic/IGraphic';
import GraphicComponent from './GraphicComponent';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import IComponent from './IComponent';
import IComponentUniter from './IComponentUniter';

/**
 * Компонент, содержащий алгоритмы адаптации для содержания внутренних компонентов.
 */
abstract class UniterComponent<ComponentTextureType, GraphicType extends IGraphic>
	extends GraphicComponent<ComponentTextureType, GraphicType>
	implements IComponentUniter {
	public override readonly isUniter: boolean = true;

	constructor() {
		super();
	}

	/**
	 * Синхронизирует размеры графики на основе положения дочерних компонентов.
	 * Графика группы изменяется под расположение и размеры дочерней графики.
	 */
	public syncSizeFrames = () => {
		const components = this.getComponents();
		if (components === null) {
			return;
		}

		const groupGraphics = this.getGraphics();
		const mapOffsetGraphics = this.getOffsetGraphics(components);
		this.syncUniterGraphics(mapOffsetGraphics, groupGraphics);
	};

	public syncSingleComponentsEditMode = () => {
		const components = this.getComponents();
		if (!components) return;

		components.forEach((component) => {
			if (!this.isEnableEditMode())component.disableEditMode();
		});
	};

	/**
	 * Абсолютный offset вложенной графики относительно групповой графики
	 * @param components
	 */
	private getOffsetGraphics = (components: IComponent[]): Map<number, IGraphic[]> => {
		const graphicOffsetsMap = new Map<number, IGraphic[]>();

		// Массив всех вложенных график переданных компонентов
		const componentGraphics: IGraphic[] = [];
		components.forEach((component) => {
			const graphics = component.getGraphics();
			if (graphics === null) {
				throw new ManipulatorError('graphics is null');
			}
			componentGraphics.push(...graphics);
		});

		// Сформировать отражение offsetGraphics (графики и офсеты)
		componentGraphics.forEach((graphic) => {
			// Важно помнить, что offset графики всегда начинается с 0, на какой странице бы она не находилась
			const parentComponent = graphic.getParentComponent();
			if (parentComponent === null) return;
			const parentComponentGraphics = parentComponent.getGraphics();
			const componentOffset = parentComponent.getOffset();
			if (componentOffset === null) return;
			let offset = 0;
			if (componentOffset === 0) {
				offset = graphic.getOffset();
			} else {
				for (let i = 0; i < parentComponentGraphics.length; i++) {
					if (parentComponentGraphics[i] === graphic) {
						offset = i + componentOffset;
						break;
					}
				}
			}

			let currentGraphics = graphicOffsetsMap.get(offset);
			if (currentGraphics === undefined) {
				currentGraphics = [];
				graphicOffsetsMap.set(offset, currentGraphics);
			}

			graphicOffsetsMap.set(offset, [...currentGraphics as IGraphic[], graphic]);
		});

		return graphicOffsetsMap;
	};

	private syncUniterGraphics = (mapOffsetGraphics: Map<number, IGraphic[]>, groupGraphics: IGraphic[]) => {
		mapOffsetGraphics.forEach((graphics, key) => {
			const groupGraphic = groupGraphics[key];
			if (groupGraphic === undefined) {
				throw new ManipulatorError('uniter graphic is undefined');
			}
			this.syncUniterGraphic(groupGraphic, graphics);
		});
	};

	private syncUniterGraphic = (groupGraphic: IGraphic, graphics: IGraphic[]) => {
		const [leftGraphic, topGraphic, rightGraphic, bottomGraphic] = this.getExtremeGraphics(graphics);
		this.syncLeftExtreme(leftGraphic, groupGraphic, graphics);
		this.syncTopExtreme(topGraphic, groupGraphic, graphics);
		this.syncRightExtreme(rightGraphic, groupGraphic);
		this.syncBottomExtreme(bottomGraphic, groupGraphic);
	};

	private getExtremeGraphics = (graphics: IGraphic[]): IGraphic[] => {
		let leftGraphic = graphics[0];
		let topGraphic = graphics[0];
		let rightGraphic = graphics[0];
		let bottomGraphic = graphics[0];
		let leftConfiguration = graphics[0].getFrameConfiguration().x;
		let topConfiguration = graphics[0].getFrameConfiguration().y;
		let rightConfiguration = graphics[0].getFrameConfiguration().x + graphics[0].getFrameConfiguration().width;
		let bottomConfiguration = graphics[0].getFrameConfiguration().y + graphics[0].getFrameConfiguration().height;

		graphics.forEach((graphic) => {
			const {
				x, y, width, height,
			} = graphic.getFrameConfiguration();

			if (x < leftConfiguration) {
				leftGraphic = graphic;
				leftConfiguration = x;
			}
			if (y < topConfiguration) {
				topGraphic = graphic;
				topConfiguration = y;
			}
			if (x + width > rightConfiguration) {
				rightGraphic = graphic;
				rightConfiguration = x + width;
			}
			if (y + height > bottomConfiguration) {
				bottomGraphic = graphic;
				bottomConfiguration = y + height;
			}
		});

		return [leftGraphic, topGraphic, rightGraphic, bottomGraphic];
	};

	private syncLeftExtreme = (leftGraphic: IGraphic, groupGraphic: IGraphic, graphics: IGraphic[]) => {
		const configuration = leftGraphic.getFrameConfiguration();
		if (configuration.x === 0) {
			return;
		}

		const diff = configuration.x;
		groupGraphic.setFrameConfiguration(prev => ({
			...prev,
			x: prev.x + diff,
			width: prev.width - diff,
		}));
		leftGraphic.setFrameConfiguration(prev => ({
			...prev,
			x: 0,
		}));
		graphics.forEach((graphic) => {
			if (graphic === leftGraphic) {
				return;
			}
			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: prev.x - diff,
			}));
		});
	};

	private syncTopExtreme = (topGraphic: IGraphic, groupGraphic: IGraphic, graphics: IGraphic[]) => {
		const configuration = topGraphic.getFrameConfiguration();
		if (configuration.y === 0) {
			return;
		}

		const diff = configuration.y;
		groupGraphic.setFrameConfiguration(prev => ({
			...prev,
			y: prev.y + diff,
			height: prev.height - diff,
		}));
		topGraphic.setFrameConfiguration(prev => ({
			...prev,
			y: 0,
		}));
		graphics.forEach((graphic) => {
			if (graphic === topGraphic) {
				return;
			}
			graphic.setFrameConfiguration(prev => ({
				...prev,
				y: prev.y - diff,
			}));
		});
	};

	private syncRightExtreme = (rightGraphic: IGraphic, groupGraphic: IGraphic) => {
		const configuration = rightGraphic.getFrameConfiguration();
		const groupConfiguration = groupGraphic.getFrameConfiguration();
		if (configuration.x + configuration.width === groupConfiguration.width) {
			return;
		}

		const diff = configuration.x + configuration.width - groupConfiguration.width;
		groupGraphic.setFrameConfiguration(prev => ({
			...prev,
			width: prev.width + diff,
		}));
	};

	private syncBottomExtreme = (bottomGraphic: IGraphic, groupGraphic: IGraphic) => {
		const configuration = bottomGraphic.getFrameConfiguration();
		const groupConfiguration = groupGraphic.getFrameConfiguration();
		if (configuration.y + configuration.height === groupConfiguration.height) {
			return;
		}

		const diff = configuration.y + configuration.height - groupConfiguration.height;
		groupGraphic.setFrameConfiguration(prev => ({
			...prev,
			height: prev.height + diff,
		}));
	};
}

export default UniterComponent;
