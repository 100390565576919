import React from 'react';
import { useSelector } from 'react-redux';
import { Title } from '../../../../components/ui/title';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import { workshopModuleActions, workshopModuleSlice } from '../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../shared/libs/redux';
import css from './BindWorkshopModuleCategoryModal.module.scss';
import { CreateWorkshopCategoryInput } from '../create-category-input/CreateWorkshopCategoryInput';
import { SearchWorkshopModuleCategoryBar } from '../search-module-category-bar/SearchWorkshopModuleCategoryBar';
import { WorkshopModuleBindCategoryList } from '../category-list/WorkshopModuleBindCategoryList';
import { NewModal } from '../../../../components/ui/modals/new-modal';

export const BindWorkshopModuleCategoryModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopModuleSlice.selectors.getIsBindingCategoryModuleModalActive);
	const id = useSelector(workshopModuleSlice.selectors.getBindingModuleId);

	const setModalVisibility = (value: boolean) => {
		dispatch(workshopModuleActions.setIsBindingCategoryModuleModalActive(value));
	};

	const onClose = () => {
		setModalVisibility(false);
	};

	return (
		<NewModal styles={css.wrapper} active={isActive} closeModal={onClose}>
			<Title styles={css.title}>Изменение категорий модуля</Title>
			<SearchWorkshopModuleCategoryBar />
			<div className={css.categories_bar}>
				<CreateWorkshopCategoryInput moduleId={id} />
				<WorkshopModuleBindCategoryList moduleId={id} />
			</div>
			<MainButton btnStyle="bright" onClick={onClose}>Сохранить</MainButton>
		</NewModal>
	);
};
