import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../../shared/libs/classNames';
import { FilterModulesTagBar } from '../tags-bar/FilterModulesTagBar';
import { FilterModulesSearchGroups } from '../search-groups/FilterModulesSearchGroups';
import { workshopTagGroupApi } from '../../../../../entities/workshop-tag-group';
import { Slider } from '../../../../../widgets/slider';
import { ReactComponent as CleanIcon } from '../../../assets/close.svg';
import { FilterWorkshopModulesGroupCard } from '../group-card/FilterWorkshopModulesGroupCard';
import css from './FilterModulesByTags.module.scss';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { workshopModuleActions, workshopModuleSlice } from '../../../../../entities/workshop-module';
import { useDebounce } from '../../../../../shared/libs/useDebounce';

export interface IFilterModulesItem {
	id: number,
	name: string,
}

export const FilterWorkshopModulesByTags = () => {
	const dispatch = useAppDispatch();

	const selectedTags = useSelector(workshopModuleSlice.selectors.getTags);

	const [search, setSearch] = useState('');

	const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);

	const [showTags, setShowTags] = useState(false);
	const [showSearch, setShowSearch] = useState(false);

	const debouncedSearch = useDebounce(search.trim(), 200);

	const { data: groups } = workshopTagGroupApi.useGetWorkshopGroupsQuery({
		search: debouncedSearch,
		limit: 127,
		page: 1,
	});

	const onSelectGroup = (id: number) => {
		if (selectedGroup === id) {
			setSelectedGroup(undefined);
			setShowTags(false);
		} else {
			setSelectedGroup(id);
			setShowTags(true);
		}
	};

	const selectTags = (tags: number[]) => {
		dispatch(workshopModuleActions.setTags(tags));
	};

	const onCleanList = () => {
		setSelectedGroup(undefined);
		setShowTags(false);
		selectTags([]);
	};

	const cleanSearch = () => {
		setShowSearch(false);
		setSearch('');
	};

	const handleTagSelection = (tagId: number) => {
		if (selectedTags.includes(tagId)) {
			selectTags(selectedTags.filter(id => id !== tagId));
		} else {
			selectTags([...selectedTags, tagId]);
		}
	};

	const tags = useMemo(
		() => groups?.groupTags?.filter(group => group.id === selectedGroup)?.[0]?.tag
			|| [],
		[groups, selectedGroup],
	);

	return (
		<>
			<div className={css.wrapper}>
				<Slider buttonScrollDistance={400} height={30} controlSize="small">
					{
						groups?.groupTags?.map(group => {
							const selected = selectedGroup === group.id;

							return (
								<FilterWorkshopModulesGroupCard
									data={group}
									key={group.id}
									onSelect={onSelectGroup}
									selected={selected}
									isOpen={selected}
								/>
							);
						})
					}
				</Slider>
				<div className={css.reset_tags} role="presentation" onClick={onCleanList}>
					Сбросить теги
				</div>
				<CleanIcon
					onClick={cleanSearch}
					className={classNames('', {
						[css.close_icon]: !showSearch,
						[css.close_icon_margin]: showSearch,
					})}
				/>
				<FilterModulesSearchGroups
					value={search}
					setValue={setSearch}
					show={showSearch}
					setShow={setShowSearch}
				/>
			</div>
			<FilterModulesTagBar
				selectTag={handleTagSelection}
				selectedTagsIds={selectedTags}
				tags={tags}
				showed={showTags}
			/>
		</>
	);
};
