import React from 'react';
import css from './selected-tags.module.scss';
import { EditableTag } from '../../../../../shared/ui/editable-tag/editable-tag';
import { ITemplateTag } from '../../../../../entities/templates/tags/api/types';
import { useListOverflow } from '../../../../../libs/useListOverflow';
import { ITagData } from '../../../../../entities/modules/tag/api/types';

interface TemplateSearchChoicedTagsProps {
	search: string,
	selectedTags: ITemplateTag[] | ITagData[],
	handleTag: (tag: ITemplateTag | ITagData) => void,
}

const TOTAL_WIDTH = 190;
export const TemplateSearchSelectedTags = ({
	search,
	handleTag,
	selectedTags,
}: TemplateSearchChoicedTagsProps) => {
	const {
		visibleTags,
		hiddenTagsCount,
	} = useListOverflow(selectedTags, TOTAL_WIDTH);

	return (
		<div role="presentation" className={css.choiced_tag_list} onClick={e => e.stopPropagation()}>
			{
				visibleTags.map(tag => (
					<EditableTag
						tag={tag}
						key={tag.id + tag.name}
						closeAble
						styles={css.tag}
						onClick={() => null}
						state="active"
						onClose={() => handleTag(tag)}
					/>
				))
			}
			{
				!!hiddenTagsCount && (
					<EditableTag
						tag={{
							id: 1,
							name: `+${hiddenTagsCount}`,
						}}
						closeAble={false}
						styles={css.tag}
						onClick={() => null}
						state="active"
						onClose={() => null}
					/>
				)
			}
			{
				!!visibleTags.length && !!search.length && (
					<span className={css.plus}>+</span>
				)
			}
		</div>
	);
};
