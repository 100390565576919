import { classNames } from '../../../../../shared/libs/classNames';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { useUnpublishWorkshopModule } from '../../../model/services/unpublish-module/useUnpublishWorkshopModule';

interface UnpublishWorkshopModuleButtonProps {
	className?: string,
	moduleId?: string,
}

export const UnpublishWorkshopModuleButton = ({ className, moduleId }: UnpublishWorkshopModuleButtonProps) => {
	const { unpublish } = useUnpublishWorkshopModule();

	const onUnpublishWorkshopModule = async () => {
		await unpublish(moduleId);
	};

	return (
		<div className={classNames('', {}, [className])}>
			<MainButton btnStyle="light" onClick={onUnpublishWorkshopModule}>
				Снять с публикации
			</MainButton>
		</div>
	);
};
