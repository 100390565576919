import IColorexColorGroup from './IColorexColorGroup';
import ColorexColor from './ColorexColor';

const globalColorexColors: IColorexColorGroup[] = [
	{
		head: ColorexColor.Red600,
		colors: [
			ColorexColor.Red50,
			ColorexColor.Red100,
			ColorexColor.Red200,
			ColorexColor.Red300,
			ColorexColor.Red400,
			ColorexColor.Red500,
			ColorexColor.Red600,
			ColorexColor.Red700,
			ColorexColor.Red800,
			ColorexColor.Red900,
			// ColorexColor.RedA100,
			// ColorexColor.RedA200,
			// ColorexColor.RedA400,
			// ColorexColor.RedA700,
		],
	},
	{
		head: ColorexColor.Pink600,
		colors: [
			ColorexColor.Pink50,
			ColorexColor.Pink100,
			ColorexColor.Pink200,
			ColorexColor.Pink300,
			ColorexColor.Pink400,
			ColorexColor.Pink500,
			ColorexColor.Pink600,
			ColorexColor.Pink700,
			ColorexColor.Pink800,
			ColorexColor.Pink900,
			// ColorexColor.PinkA100,
			// ColorexColor.PinkA200,
			// ColorexColor.PinkA400,
			// ColorexColor.PinkA700,
		],
	},
	{
		head: ColorexColor.Purple600,
		colors: [
			ColorexColor.Purple50,
			ColorexColor.Purple100,
			ColorexColor.Purple200,
			ColorexColor.Purple300,
			ColorexColor.Purple400,
			ColorexColor.Purple500,
			ColorexColor.Purple600,
			ColorexColor.Purple700,
			ColorexColor.Purple800,
			ColorexColor.Purple900,
			// ColorexColor.PurpleA100,
			// ColorexColor.PurpleA200,
			// ColorexColor.PurpleA400,
			// ColorexColor.PurpleA700,
		],
	},
	{
		head: ColorexColor.DeepPurple600,
		colors: [
			ColorexColor.DeepPurple50,
			ColorexColor.DeepPurple100,
			ColorexColor.DeepPurple200,
			ColorexColor.DeepPurple300,
			ColorexColor.DeepPurple400,
			ColorexColor.DeepPurple500,
			ColorexColor.DeepPurple600,
			ColorexColor.DeepPurple700,
			ColorexColor.DeepPurple800,
			ColorexColor.DeepPurple900,
			// ColorexColor.DeepPurpleA100,
			// ColorexColor.DeepPurpleA200,
			// ColorexColor.DeepPurpleA400,
			// ColorexColor.DeepPurpleA700,
		],
	},
	{
		head: ColorexColor.Indigo600,
		colors: [
			ColorexColor.Indigo50,
			ColorexColor.Indigo100,
			ColorexColor.Indigo200,
			ColorexColor.Indigo300,
			ColorexColor.Indigo400,
			ColorexColor.Indigo500,
			ColorexColor.Indigo600,
			ColorexColor.Indigo700,
			ColorexColor.Indigo800,
			ColorexColor.Indigo900,
			// ColorexColor.IndigoA100,
			// ColorexColor.IndigoA200,
			// ColorexColor.IndigoA400,
			// ColorexColor.IndigoA700,
		],
	},
	{
		head: ColorexColor.Blue600,
		colors: [
			ColorexColor.Blue50,
			ColorexColor.Blue100,
			ColorexColor.Blue200,
			ColorexColor.Blue300,
			ColorexColor.Blue400,
			ColorexColor.Blue500,
			ColorexColor.Blue600,
			ColorexColor.Blue700,
			ColorexColor.Blue800,
			ColorexColor.Blue900,
			// ColorexColor.BlueA100,
			// ColorexColor.BlueA200,
			// ColorexColor.BlueA400,
			// ColorexColor.BlueA700,
		],
	},
	{
		head: ColorexColor.LightBlue600,
		colors: [
			ColorexColor.LightBlue50,
			ColorexColor.LightBlue100,
			ColorexColor.LightBlue200,
			ColorexColor.LightBlue300,
			ColorexColor.LightBlue400,
			ColorexColor.LightBlue500,
			ColorexColor.LightBlue600,
			ColorexColor.LightBlue700,
			ColorexColor.LightBlue800,
			ColorexColor.LightBlue900,
			// ColorexColor.LightBlueA100,
			// ColorexColor.LightBlueA200,
			// ColorexColor.LightBlueA400,
			// ColorexColor.LightBlueA700,
		],
	},
	{
		head: ColorexColor.Cyan600,
		colors: [
			ColorexColor.Cyan50,
			ColorexColor.Cyan100,
			ColorexColor.Cyan200,
			ColorexColor.Cyan300,
			ColorexColor.Cyan400,
			ColorexColor.Cyan500,
			ColorexColor.Cyan600,
			ColorexColor.Cyan700,
			ColorexColor.Cyan800,
			ColorexColor.Cyan900,
			// ColorexColor.CyanA100,
			// ColorexColor.CyanA200,
			// ColorexColor.CyanA400,
			// ColorexColor.CyanA700,
		],
	},
	{
		head: ColorexColor.Teal600,
		colors: [
			ColorexColor.Teal50,
			ColorexColor.Teal100,
			ColorexColor.Teal200,
			ColorexColor.Teal300,
			ColorexColor.Teal400,
			ColorexColor.Teal500,
			ColorexColor.Teal600,
			ColorexColor.Teal700,
			ColorexColor.Teal800,
			ColorexColor.Teal900,
			// ColorexColor.TealA100,
			// ColorexColor.TealA200,
			// ColorexColor.TealA400,
			// ColorexColor.TealA700,
		],
	},
	{
		head: ColorexColor.Green600,
		colors: [
			ColorexColor.Green50,
			ColorexColor.Green100,
			ColorexColor.Green200,
			ColorexColor.Green300,
			ColorexColor.Green400,
			ColorexColor.Green500,
			ColorexColor.Green600,
			ColorexColor.Green700,
			ColorexColor.Green800,
			ColorexColor.Green900,
			// ColorexColor.GreenA100,
			// ColorexColor.GreenA200,
			// ColorexColor.GreenA400,
			// ColorexColor.GreenA700,
		],
	},
	{
		head: ColorexColor.LightGreen600,
		colors: [
			ColorexColor.LightGreen50,
			ColorexColor.LightGreen100,
			ColorexColor.LightGreen200,
			ColorexColor.LightGreen300,
			ColorexColor.LightGreen400,
			ColorexColor.LightGreen500,
			ColorexColor.LightGreen600,
			ColorexColor.LightGreen700,
			ColorexColor.LightGreen800,
			ColorexColor.LightGreen900,
			// ColorexColor.LightGreenA100,
			// ColorexColor.LightGreenA200,
			// ColorexColor.LightGreenA400,
			// ColorexColor.LightGreenA700,
		],
	},
	{
		head: ColorexColor.Lime600,
		colors: [
			ColorexColor.Lime50,
			ColorexColor.Lime100,
			ColorexColor.Lime200,
			ColorexColor.Lime300,
			ColorexColor.Lime400,
			ColorexColor.Lime500,
			ColorexColor.Lime600,
			ColorexColor.Lime700,
			ColorexColor.Lime800,
			ColorexColor.Lime900,
			// ColorexColor.LimeA100,
			// ColorexColor.LimeA200,
			// ColorexColor.LimeA400,
			// ColorexColor.LimeA700,
		],
	},
	{
		head: ColorexColor.Red600,
		colors: [
			ColorexColor.Yellow50,
			ColorexColor.Yellow100,
			ColorexColor.Yellow200,
			ColorexColor.Yellow300,
			ColorexColor.Yellow400,
			ColorexColor.Yellow500,
			ColorexColor.Yellow600,
			ColorexColor.Yellow700,
			ColorexColor.Yellow800,
			ColorexColor.Yellow900,
			// ColorexColor.YellowA100,
			// ColorexColor.YellowA200,
			// ColorexColor.YellowA400,
			// ColorexColor.YellowA700,
		],
	},
	{
		head: ColorexColor.Amber600,
		colors: [
			ColorexColor.Amber50,
			ColorexColor.Amber100,
			ColorexColor.Amber200,
			ColorexColor.Amber300,
			ColorexColor.Amber400,
			ColorexColor.Amber500,
			ColorexColor.Amber600,
			ColorexColor.Amber700,
			ColorexColor.Amber800,
			ColorexColor.Amber900,
			// ColorexColor.AmberA100,
			// ColorexColor.AmberA200,
			// ColorexColor.AmberA400,
			// ColorexColor.AmberA700,
		],
	},
	{
		head: ColorexColor.Orange600,
		colors: [
			ColorexColor.Orange50,
			ColorexColor.Orange100,
			ColorexColor.Orange200,
			ColorexColor.Orange300,
			ColorexColor.Orange400,
			ColorexColor.Orange500,
			ColorexColor.Orange600,
			ColorexColor.Orange700,
			ColorexColor.Orange800,
			ColorexColor.Orange900,
			// ColorexColor.OrangeA100,
			// ColorexColor.OrangeA200,
			// ColorexColor.OrangeA400,
			// ColorexColor.OrangeA700,
		],
	},
	{
		head: ColorexColor.DeepOrange600,
		colors: [
			ColorexColor.DeepOrange50,
			ColorexColor.DeepOrange100,
			ColorexColor.DeepOrange200,
			ColorexColor.DeepOrange300,
			ColorexColor.DeepOrange400,
			ColorexColor.DeepOrange500,
			ColorexColor.DeepOrange600,
			ColorexColor.DeepOrange700,
			ColorexColor.DeepOrange800,
			ColorexColor.DeepOrange900,
			// ColorexColor.DeepOrangeA100,
			// ColorexColor.DeepOrangeA200,
			// ColorexColor.DeepOrangeA400,
			// ColorexColor.DeepOrangeA700,
		],
	},
	{
		head: ColorexColor.Brown600,
		colors: [
			ColorexColor.Brown50,
			ColorexColor.Brown100,
			ColorexColor.Brown200,
			ColorexColor.Brown300,
			ColorexColor.Brown400,
			ColorexColor.Brown500,
			ColorexColor.Brown600,
			ColorexColor.Brown700,
			ColorexColor.Brown800,
			ColorexColor.Brown900,
		],
	},
	{
		head: ColorexColor.Grey600,
		colors: [
			ColorexColor.Grey50,
			ColorexColor.Grey100,
			ColorexColor.Grey200,
			ColorexColor.Grey300,
			ColorexColor.Grey400,
			ColorexColor.Grey500,
			ColorexColor.Grey600,
			ColorexColor.Grey700,
			ColorexColor.Grey800,
			ColorexColor.Grey900,
		],
	},
	{
		head: ColorexColor.BlueGrey600,
		colors: [
			ColorexColor.BlueGrey50,
			ColorexColor.BlueGrey100,
			ColorexColor.BlueGrey200,
			ColorexColor.BlueGrey300,
			ColorexColor.BlueGrey400,
			ColorexColor.BlueGrey500,
			ColorexColor.BlueGrey600,
			ColorexColor.BlueGrey700,
			ColorexColor.BlueGrey800,
			ColorexColor.BlueGrey900,
		],
	},
];

export default globalColorexColors;
