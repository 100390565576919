/**
 * Список сторон.
 */
const enum DirectionVector {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
	MIDDLE_X = 'MIDDLE_X',
	MIDDLE_Y = 'MIDDLE_Y',
}

export default DirectionVector;
