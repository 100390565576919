import { moduleAPI } from '../../../../entities/modules/module/api/api';
import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { tagAPI } from '../../../../entities/modules/tag/api/api';

export const useDeleteModule = () => {
	const dispatch = useAppDispatch();

	const [deleteModule, { isLoading }] = moduleAPI.useDeleteMutation();

	const onDeleteModule = async (moduleId: string) => {
		try {
			await deleteModule({ id: moduleId })
				.unwrap();
			dispatch(tagAPI.util?.invalidateTags(['tag']));
			notificationSuccess('Модуль успешно удален!');
		} catch (error) {
			notificationError('Возникла ошибка при удалении модуля!');
		}
	};

	return {
		isLoading,
		onDeleteModule,
	};
};
