import React from 'react';
import { DeleteModalWrapper } from '../../../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useUnbindUserFromEntity } from '../../../lib/useUnbindUserFromEntity';

export const UnbindUserFromEntityModal = () => {
	const {
		unbindUser,
		isActive,
		setActive,
	} = useUnbindUserFromEntity();

	return (
		<DeleteModalWrapper
			description="Вы действительно хотите удалить пользователя без возможности восстановления?"
			onDelete={unbindUser}
			isActive={isActive}
			setIsActive={setActive}
		/>
	);
};
