import { useSelector } from 'react-redux';
import {
	workshopTagGroupActions,
	workshopTagGroupApi,
	workshopTagGroupSlice,
} from '../../../../entities/workshop-tag-group';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';

export const useBindWorkshopTagToGroup = () => {
	const dispatch = useAppDispatch();

	const [bind, requestData] = workshopTagGroupApi.useBindMutation();

	const tags = useSelector(workshopTagGroupSlice.selectors.getBindingTags);
	const groupId = useSelector(workshopTagGroupSlice.selectors.getBindingGroupId);

	const bindTags = async () => {
		try {
			if (tags.length && groupId) {
				await bind({ groupId, tags }).unwrap();

				dispatch(workshopTagGroupActions.setIsBindingGroupModalActive(false));
				dispatch(workshopTagGroupActions.setBindingTags([]));
			}
		} catch (e) {
			notificationError('Произошла ошибка при привязке тегов к группе!');
		}
	};

	return { requestData, bindTags };
};
