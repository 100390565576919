import { tariffAPI } from '../../../../entities/tariff/api/api';
import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { adminAPI } from '../../../../entities/admin/api/api';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { accountAPI } from '../../../../entities/user/account/api/api';

export const useReactivateTariff = (transactionId: number) => {
	const dispatch = useAppDispatch();

	const [reactivate, { isLoading }] = tariffAPI.useEnableTransactionMutation();

	const reactivateTariff = async () => {
		try {
			await reactivate({ transactionId })
				.unwrap();
			dispatch(accountAPI.util?.invalidateTags(['account']));
			dispatch(adminAPI.util?.invalidateTags(['admin']));
			notificationSuccess('Тариф успешно активирован!');
		} catch (error) {
			notificationError('Произошла ошибка при активации тарифа...');
		}
	};

	return {
		reactivateTariff,
		isLoading,
	};
};
