import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteTeamTemplateModal } from './modals/delete';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import useFilterData, { FromFilterData } from '../../../../../../../hooks/filter/useFilterData';
import { EmptyPlugList } from '../../../../../../../widgets/templates/empty-list';
import { PATH_AUTH_SUCCESS } from '../../../../../registration/authorization/Authorization';
import useGetTemplatesTeam from '../../../../../../../hooks/template/team/useGetTemplatesTeam';
import { TeamTemplateList } from './list/template-list';
import { MoveTeamTemplateModal } from './modals/move';
import { TemplateSearchBar } from '../../../../../../../widgets/templates/template-search-bar/template-search-bar';

export const TemplateTeamPage = () => {
	const navigate = useNavigate();

	const templates = useAppSelector(state => state.teamTemplate.teamTemplates);

	const {
		currentFilter,
		sortingData,
	} = useFilterData(FromFilterData.TEMPLATE);

	const {
		search,
		setSearch,
		isLoading,
	} = useGetTemplatesTeam(currentFilter.sortBy);

	return (
		<>
			<TemplateSearchBar
				search={search}
				setSearch={setSearch}
				sortingData={sortingData}
			/>
			{
				((templates && templates.length) || isLoading)
					? (
						<>
							<TeamTemplateList templates={templates} isDataLoading={isLoading} />
							<DeleteTeamTemplateModal />
							<MoveTeamTemplateModal />
						</>
					)
					: (
						<EmptyPlugList
							isCreateBtn={false}
							btnText="Перейти в «Мои шаблоны»"
							onClick={() => navigate(PATH_AUTH_SUCCESS)}
							title={
								<span>Работайте с&nbsp;командными шаблонами</span>
							}
							description={(
								<span>
									Переместите уже созданные Вами шаблоны и&nbsp;работайте вместе с&nbsp;командой
								</span>
							)}
						/>
					)
			}
		</>
	);
};
