import Dependent from '../../utils/dependent/Dependent';
import { AnyComponentStructure, LayerSequences } from '../../Types';
import ValidateFunction from './ValidateFunction';
import SketchComponentType from '../../components/SketchComponentType';
import ValidateErrors from './ValidateErrors';
import IGraphic from '../../graphic/IGraphic';
import IComponentTree from '../IComponentTree';
import ILayeredComponentTree from '../ILayeredComponentTree';

export interface IComponentTreeValidatorDependencies {
	componentTree: IComponentTree & ILayeredComponentTree,
}

/**
 * Валидатор структуры дерева компонентов. Проверяет, валидная ли структура храниться в дереве компонентов.
 */
class ComponentTreeValidator extends Dependent<IComponentTreeValidatorDependencies> {
	private readonly validateSteps: Map<ValidateFunction, ValidateErrors>;

	constructor() {
		super();
		this.validateSteps = new Map([
			[this.validatePageCount, ValidateErrors.pageCountError],
			[this.validateLayers, ValidateErrors.layerSequencesError],
			[this.validateLayerDuplicate, ValidateErrors.layerDuplicateError],
			[this.validateAbsenceGraphics, ValidateErrors.absenceGraphicsError],
			[this.validateRegistrationLayers, ValidateErrors.registrationLayersError],
		]);
	}

	/**
	 * Проверяет текущее состояние дерева на ошибки. Возвращает результат проверки.
	 */
	public validateCurrentStructure = (): ValidateErrors | null => {
		const structure = this.dependencies.componentTree.getStructure();
		const layers = this.dependencies.componentTree.getLayerSequences();
		return this.validate(structure, layers);
	};

	/**
	 * Проверяет переданную структуру на корректность.
	 * @param structure Структура на проверку.
	 * @param layerSequences Последовательность слоев к структуре.
	 */
	private validate = (structure: AnyComponentStructure, layerSequences: LayerSequences): ValidateErrors | null => {
		let validError: ValidateErrors | null = null;

		this.validateSteps.forEach((err, fn) => {
			if (validError !== null) {
				return;
			}
			if (!fn(structure, layerSequences)) {
				validError = err;
			}
		});

		return validError;
	};

	/**
	 * Проверяет на пропуски в последовательности слоев.
	 * @param _ Структура скетча.
	 * @param layerSequences Последовательность слоев к структуре.
	 */
	private validateLayers = (_: AnyComponentStructure, layerSequences: LayerSequences): boolean => {
		const layerCount: Map<string, number> = new Map();
		for (let i = 0; i < layerSequences.length; i++) {
			const sequence = layerSequences[i];
			for (let j = 0; j < sequence.length; j++) {
				if (sequence[j] === undefined) {
					return false;
				}
				const count = layerCount.get(sequence[j]);
				if (count !== undefined) {
					return false;
				}
			}
		}
		return true;
	};

	/**
	 * Проверяет на отсутствие графики у компонентов.
	 * @param structure Структура на проверку.
	 */
	private validateAbsenceGraphics = (structure: AnyComponentStructure): boolean => this
		.recursiveValidateAbsenceGraphics(structure);

	private recursiveValidateAbsenceGraphics = (component: AnyComponentStructure): boolean => {
		if (!this.isComponentUniter(component.type) && component.graphics !== null && component.graphics.length === 0) {
			return false;
		}

		if (component.components !== null) {
			for (let i = 0; i < component.components.length; i++) {
				const isValid = this.recursiveValidateAbsenceGraphics(component.components[i]);
				if (!isValid) {
					return false;
				}
			}
		}

		return true;
	};

	/**
	 * Проверяет на соответствие количества страниц и компонентов на их вместимость на текущее количество страниц.
	 * @param structure Структура на проверку.
	 */
	private validatePageCount = (structure: AnyComponentStructure): boolean => {
		if (structure.graphics === null) {
			return false;
		}
		const minPageCount = this.getMinPageCount(structure);
		const currentPageCount = structure.graphics.length;
		return currentPageCount >= minPageCount;
	};

	/**
	 * Возвращает минимально необходимое количество страниц для размещения всех компонентов.
	 * @param structure Структура на проверку.
	 */
	private getMinPageCount = (structure: AnyComponentStructure): number => {
		if (structure.components === null) {
			return 0;
		}

		const calculateBody = {
			currentPage: 0,
			maxPage: 0,
		};

		this.recursiveCalculateMaxPage(calculateBody, structure);

		return calculateBody.maxPage;
	};

	private recursiveCalculateMaxPage = (body: {
		currentPage: number,
		maxPage: number,
	}, structure: AnyComponentStructure) => {
		const currentOffset = structure.offset === null ? 0 : structure.offset;
		const currentGraphicCount = structure.graphics === null ? 0 : structure.graphics.length;
		const maxPage = currentOffset + currentGraphicCount;

		if (body.maxPage < maxPage) {
			body.maxPage = maxPage;
		}

		body.currentPage += currentOffset;

		structure.components?.forEach(component => {
			this.recursiveCalculateMaxPage(body, component);
		});
	};

	/**
	 * Проверяет, вся ли графика зарегистрирована в слоях.
	 * @param _ Структура на проверку.
	 * @param layerSequences Последовательность слоев.
	 */
	private validateRegistrationLayers = (_: AnyComponentStructure, layerSequences: LayerSequences) => {
		const layersCount = layerSequences.flat().length;
		const components = this.dependencies.componentTree.getComponents();
		const graphics: IGraphic[] = components.map(component => component.getGraphics()).flat();
		return graphics.length === layersCount;
	};

	/**
	 * Проверяет слои на дубликаты.
	 * @param _ Структура на проверку.
	 * @param layerSequences Последовательность слоев.
	 */
	private validateLayerDuplicate = (_: AnyComponentStructure, layerSequences: LayerSequences) => {
		const layers = layerSequences.flat();
		const layersSet = new Set(layers);
		return layers.length === layersSet.size;
	};

	private isComponentUniter = (type: SketchComponentType) => type === SketchComponentType.GROUP
		|| type === SketchComponentType.MODULE;
}

export default ComponentTreeValidator;
