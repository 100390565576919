class Queue<T> {
	private items: T[];

	constructor() {
		this.items = [];
	}

	// Добавление элемента в очередь
	public enqueue(item: T): void {
		this.items.push(item);
	}

	// Удаление и возврат первого элемента из очереди
	public dequeue(): T | undefined {
		if (this.isEmpty()) {
			return undefined;
		}
		return this.items.shift();
	}

	// Возврат первого элемента без удаления из очереди
	public peek(): T | undefined {
		if (this.isEmpty()) {
			return undefined;
		}
		return this.items[0];
	}

	// Проверка, пуста ли очередь
	public isEmpty(): boolean {
		return this.items.length === 0;
	}

	// Получение текущей длины очереди
	public size(): number {
		return this.items.length;
	}

	// Очистка очереди
	public clear(): void {
		this.items = [];
	}
}

export default Queue;
