import React, { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoPanel } from '../../../../info-panel';
import { teamTemplateAPI } from '../../../../../../../../entities/templates/team/api/api';
import { useUnbindTeamTemplateTag } from '../../utils/useUnbindTeamTemplateTag';
import { ChangeTemplateTagsModal } from '../../../../info-panel/ui/change-tags-modal/modal';
import { ContentLayout } from '../../../../../../layouts/content';
import { useEditTeamTemplateName } from '../../../../../../../../features/templates/edit-name';
import { useAppSelector } from '../../../../../../../../shared/libs/redux';
import { personalAccountSlice } from '../../../../../../../../redux/reducers/personalAccountReducer';

export const TeamTemplatePanel = () => {
	const { id } = useParams();

	const navigate = useNavigate();

	const [isChangeModalActive, setIsChangeModalActive] = useState(false);
	const isBasicTariff = useAppSelector(personalAccountSlice.selectors.isBasicTariff);

	const goBack = () => navigate(-1);

	const {
		data,
		error,
	} = teamTemplateAPI.useGetQuery({ id: id || '' }, { skip: !id });

	const { unbindTag } = useUnbindTeamTemplateTag(id || '');
	const { editName } = useEditTeamTemplateName(id || '');

	useLayoutEffect(() => {
		if (error && 'originalStatus' in error && error.originalStatus === 403) {
			navigate('/collection/forbidden');
		}
	}, [error]);

	return (
		<ContentLayout>
			{
				data && (
					<InfoPanel
						download={isBasicTariff}
						data={data}
						goBack={goBack}
						editName={editName}
						unbindTag={unbindTag}
						openTagsModal={() => setIsChangeModalActive(true)}
						isPDFAvailable={false}			
					/>
				)
			}
			<ChangeTemplateTagsModal
				template={data?.template}
				type="TEAM_TEMPLATE"
				unbindTag={unbindTag}
				tags={data?.template.tags}
				active={isChangeModalActive}
				setIsActive={setIsChangeModalActive}
			/>
		</ContentLayout>
	);
};
