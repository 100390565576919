import React from 'react';
import css from './tags.module.scss';
import { ReactComponent as PlusIcon } from '../../images/plus.svg';
import { setBindingModuleId, setIsBindingTagModuleModalActive } from '../../model/slice';
import { ITagData } from '../../../tag/api/types';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { useUnbindModuleTag } from '../../../../../features/module/tags/unbind/api/useUnbindModuleTag';
import { ModuleTag } from '../../../tag/ui/module-tag';

interface ModuleTagsProps {
	tags: ITagData[],
	moduleId: string,
	onBindTag?: () => void,
}

export const ModuleTags = ({
	tags,
	moduleId,
	onBindTag,
}: ModuleTagsProps) => {
	const { onUnbindTag } = useUnbindModuleTag(moduleId || '');

	return (
		<div className={css.tags_block}>
			{
				tags?.map(tag => (
					<ModuleTag
						key={tag.id}
						tag={tag}
						onUnbind={() => onUnbindTag(tag.id)}
						styles={css.tag}
					/>
				))
			}
			<PlusIcon
				className={css.plus}
				onClick={onBindTag}
			/>
		</div>
	);
};
