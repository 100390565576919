import cx from 'classnames';
import { ITransaction } from '../../../../entities/tariff/api/types';
import { tariffMapData } from '../../../../entities/tariff/model/data';
import css from './history-item.module.scss';
import { ReactivateTariffButton } from '../../../../features/tariff/reactivate';
import { DisableTariffButton } from '../../../../features/tariff/disable';
import { setTransactionHistoryDate } from '../../libs/utils/set-transaction-history-date';
import { setTransactionHistoryDuration } from '../../libs/utils/set-transaction-history-duration';

interface TransactionHistoryItemProps {
    transaction: ITransaction;
    styles?: string,
}

export const TransactionHistoryItem = ({
	transaction,
	styles,
}: TransactionHistoryItemProps) => {
	const {
		name,
		id,
		disabled,
		duration,
		startDate: startDateTime,
	} = transaction;

	return (
		<tr className={cx(css.wrapper, !disabled && css.active, styles)}>
			<td className={css.tariff_name}>{tariffMapData.get(name)}</td>
			<td className={cx(css.side_text_duration)}>{setTransactionHistoryDuration(duration)}</td>
			<td className={cx(css.side_text_start_date)}>{setTransactionHistoryDate(startDateTime)}</td>
			<td className={css.reactivate}>
				{
					disabled
						? <ReactivateTariffButton transactionId={id} />
						: <DisableTariffButton transactionId={id} />
				}
			</td>
		</tr>
	);
};
