import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button, { buttonsTypes } from '../../../../../ui/button/button';
import CreateInput from '../../createInput/CreateInput';
import css from './createTagWindow.module.scss';
import { setIsCreatingTagModalActive } from '../../../../../../redux/reducers/module/tagReducer';
import useCatchEnterClick from '../../../../../../hooks/useCatchEnterClick';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { tagModalsActive } from '../../../../../../redux/selectors/module/tag';
import { NewModal } from '../../../../../ui/modals/new-modal';
import { useCreateModuleTag } from '../../../../../../features/module/tags/create/api/useCreateModuleTag';
import { useAppDispatch } from '../../../../../../shared/libs/redux';

const CreateTagWindow = () => {
	const [inputValue, setInputValue] = useState('');

	const isCreatingTagModalActive = useSelector((state: RootState) => tagModalsActive(state).isCreatingTagModalActive);

	const dispatch = useAppDispatch();

	const setActive = (value: boolean) => dispatch(setIsCreatingTagModalActive(value));

	useEffect(() => {
		if (!isCreatingTagModalActive) {
			setInputValue('');
		}
	}, [isCreatingTagModalActive]);

	const {
		createTag,
	} = useCreateModuleTag();

	const onCreateTag = async () => {
		if (inputValue.length) {
			await createTag(inputValue);
			setInputValue('');
			setActive(false);
		}
	};

	useCatchEnterClick(onCreateTag, isCreatingTagModalActive);

	return (
		<NewModal
			active={isCreatingTagModalActive}
			closeModal={setActive}
		>
			<div className={css.wrapper}>
				<div className={css.title}>
					Создать тег
				</div>
				<CreateInput
					maxLength={20}
					text={inputValue}
					subTitle="Введите название"
					defaultValue="Название тега"
					onChange={setInputValue}
				/>
				{/* <CreateInputArrow */}
				{/*	text="Теги" */}
				{/*	choicedItemsId={choicedTagId} */}
				{/*	onAddChoicedItem={onAddChoicedTag} */}
				{/*	onRemoveChoicedItem={onRemoveChoicedTag} */}
				{/* /> */}
				<Button
					text="Создать"
					onClick={onCreateTag}
					buttonType={buttonsTypes.BRIGHT}
					cssClass={css.btn}
					inActive={!!inputValue.length}
				/>
			</div>
		</NewModal>
	);
};

export default CreateTagWindow;
