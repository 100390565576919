import CSSCursor from './CSSCursor';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import SpatialResizeArea from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialResizeArea';
import AreaResizeTrigger from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/AreaResizeTrigger';
import SpatialAreaType from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialAreaType';
import { AnySpatialArea } from '../Types';

/**
 * Сущность, управляющая внешним видом курсора в разных режимах конструктора.
 */
class CursorView {
	private readonly rootElement: HTMLElement;

	/** Флаги для фиксации вида курсора. */
	private isComponentMutateMode: boolean;
	private isComponentInjectMode: boolean;
	private isColumnMutatorMode: boolean;
	private isPictureMutatorMode: boolean;

	constructor(rootElement: HTMLElement) {
		this.rootElement = rootElement;
		this.isComponentMutateMode = false;
		this.isComponentInjectMode = false;
		this.isColumnMutatorMode = false;
		this.isPictureMutatorMode = false;
	}

	public enableComponentMutateMode = (cursor: CSSCursor) => {
		this.isComponentMutateMode = true;
		this.setCursor(cursor);
	};

	public disableComponentMutateMode = () => {
		if (this.isComponentInjectMode || this.isColumnMutatorMode || this.isPictureMutatorMode) {
			return;
		}
		this.setCursor(CSSCursor.AUTO);
	};

	public enableComponentInjectMode = () => {
		this.isComponentInjectMode = true;
	};

	public disableComponentInjectMode = () => {
		this.isComponentInjectMode = false;
		this.setCursor(CSSCursor.AUTO);
	};

	public enableColumnMutatorMode = () => {
		this.setCursor(CSSCursor.COLUMN_RESIZE);
		this.isColumnMutatorMode = true;
	};

	public disableColumnMutatorMode = () => {
		this.setCursor(CSSCursor.AUTO);
		this.isColumnMutatorMode = false;
	};

	public enablePictureMutateMode = () => {
		this.isPictureMutatorMode = true;
	};

	public disablePictureMutatorMode = () => {
		this.setCursor(CSSCursor.AUTO);
		this.isPictureMutatorMode = false;
	};

	public setCursor = (value: CSSCursor) => {
		this.rootElement.style.cursor = value;
	};

	public setActiveArea = (activeArea: AnySpatialArea | null) => {
		if (this.isEnableAnyMode()) {
			return;
		}

		if (activeArea === null) {
			this.setCursor(CSSCursor.AUTO);
			return;
		}

		if (activeArea.type === SpatialAreaType.TABLE_COLUMN_RESIZE) {
			this.setCursor(CSSCursor.COLUMN_RESIZE);
			return;
		}

		if (activeArea.type === SpatialAreaType.TABLE_ROW_RESIZE) {
			this.setCursor(CSSCursor.ROW_RESIZE);
			return;
		}

		if (!activeArea.isResizeType()) {
			this.setCursor(CSSCursor.AUTO);
			return;
		}

		const resizeArea = activeArea as SpatialResizeArea<any>;

		switch (resizeArea.getTrigger()) {
		case AreaResizeTrigger.LEFT: {
			this.setCursor(CSSCursor.LEFT);
			break;
		}
		case AreaResizeTrigger.TOP: {
			this.setCursor(CSSCursor.TOP);
			break;
		}
		case AreaResizeTrigger.RIGHT: {
			this.setCursor(CSSCursor.RIGHT);
			break;
		}
		case AreaResizeTrigger.BOTTOM: {
			this.setCursor(CSSCursor.BOTTOM);
			break;
		}
		case AreaResizeTrigger.LEFT_TOP: {
			this.setCursor(CSSCursor.LEFT_TOP);
			break;
		}
		case AreaResizeTrigger.RIGHT_TOP: {
			this.setCursor(CSSCursor.RIGHT_TOP);
			break;
		}
		case AreaResizeTrigger.RIGHT_BOTTOM: {
			this.setCursor(CSSCursor.RIGHT_BOTTOM);
			break;
		}
		case AreaResizeTrigger.LEFT_BOTTOM: {
			this.setCursor(CSSCursor.LEFT_BOTTOM);
			break;
		}
		default: throw new ManipulatorError(`resize trigger "${resizeArea.getTrigger()} not found"`);
		}
	};

	private isEnableAnyMode = () => this.isColumnMutatorMode || this.isComponentMutateMode || this.isComponentInjectMode
		|| this.isPictureMutatorMode;
}

export default CursorView;
