import React from 'react';
import cx from 'classnames';
import { ITagData } from '../../../../entities/modules/tag/api/types';
import { ReactComponent as SearchIcon } from '../images/search-icon.svg';
import { useOutSideClick } from '../../../../hooks/mouse/useNewOutSideClick';
import css from './advanced-template-search.module.scss';
import { TemplateSearchSelectedTags } from './selected-tags/selected-tags';
import { useInputFocus } from '../libs/useInputFocus';

interface AdvancedTemplateSearchProps {
	search: string,
	selectedTags: ITagData[],
	isDropdownVisible: boolean,
	children?: React.ReactNode,
	setSearch: (value: string) => void,
	handleTag: (tag: ITagData) => void,
	setIsDropdownVisible: (value: boolean) => void,
}

export const AdvancedTemplateSearch = ({
	search,
	children,
	setSearch,
	handleTag,
	selectedTags,
	isDropdownVisible,
	setIsDropdownVisible,
}: AdvancedTemplateSearchProps) => {
	const {
		inputRef,
		isFocused,
		focusInput,
		onBlurInput,
		onFocusInput,
	} = useInputFocus(search.length, setIsDropdownVisible);

	const handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		const text = e.target.value;

		if (!isDropdownVisible) setIsDropdownVisible(true);

		setSearch(text);
	};

	const wrapperRef = useOutSideClick(() => setIsDropdownVisible(false));

	return (
		<div role="presentation" onMouseDown={e => e.stopPropagation()} ref={wrapperRef} className={css.wrapper}>
			<div role="presentation" className={css.input_wrapper} onClick={focusInput}>
				<SearchIcon className={cx(css.icon, isFocused && css.active_icon)} />
				<TemplateSearchSelectedTags
					search={search}
					handleTag={handleTag}
					selectedTags={selectedTags}
				/>
				<input
					type="search"
					value={search}
					maxLength={35}
					ref={inputRef}
					onBlur={onBlurInput}
					onFocus={onFocusInput}
					onChange={handleSearchValue}
					placeholder={`${!selectedTags.length ? 'Фильтр' : ''} + поиск по шаблонам`}
				/>
			</div>
			{children}
		</div>
	);
};
