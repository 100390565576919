import React, { ReactNode } from 'react';
import { classNames, Mods } from '../../../../../shared/libs/classNames';
import { ICategoryData } from '../../api/types';
import css from './CategoryCard.module.scss';

interface CategoryCardProps {
	className?: string,
	actions?: ReactNode,
	druggingAction?: ReactNode,
	isActive?: boolean,
	category?: ICategoryData,
	onCategoryClick?: (id: number) => void,
}

export const CategoryCard = ({
	className, actions, onCategoryClick, isActive, category, druggingAction,
}: CategoryCardProps) => {
	if (!category) {
		return null;
	}

	const { id, name } = category;

	const handleCategoryClick = (e: React.MouseEvent) => {
		e.stopPropagation();

		onCategoryClick?.(id);
	};

	const mods: Mods = {
		[css.active]: isActive,
	};

	return (
		<div
			role="presentation"
			onClick={handleCategoryClick}
			className={classNames(css.wrapper, mods, [className])}
		>
			{druggingAction}
			<div className={css.text}>{name}</div>
			<div className={css.action_block}>
				{actions}
			</div>
		</div>
	);
};
