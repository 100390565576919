import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import IGraphic from '../../graphic/IGraphic';
import Dependent from '../../utils/dependent/Dependent';
import Utils from '../../utils/impl/Utils';
import IGraphicStructure from '../../graphic/IGraphicStructure';
import GraphicType from '../../graphic/GraphicType';
import GroupComponent from '../../components/group/GroupComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IDescartesPosition from '../../utils/IDescartesPosition';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import ComponentBuilder from '../../factories/ComponentBuilder';
import { AnyComponentStructure, AnyGraphicStructure, LayerSequences } from '../../Types';
import IPostGroupingLayers from './group/IPostGroupingLayers';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IMutationTools from '../../component-tree/IMutationTools';
import IComponentUniter from '../../components/IComponentUniter';
import IPostUngroupLayers from './ungroup/IPostUngroupLayers';
import IComponentGrouper from './IComponentGrouper';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';

export interface IComponentGrouperDependencies<ComponentTreeType>{
	componentBuilder: ComponentBuilder,
	componentTree: ComponentTreeType
}

/**
 * Сущность, объединяющая компоненты в группу и обратно.
 */
abstract class ComponentGrouper<Dependencies extends IComponentGrouperDependencies<
	IComponentTree & IComponentTreeMutator & ILayeredComponentTree>>
	extends Dependent<Dependencies>
	implements IComponentGrouper {
	abstract ungroupComponents(components: IComponent[]): void;

    abstract groupComponents(components: IComponent[]): GroupComponent;

	/**
	 * Создает компонент группы и помещает туда все отправленные компоненты.
	 * @param components Компоненты для объединения в группу.
	 * @return { IComponent } Сформированный GroupComponent.
	 */
	abstract groupSingleComponents(components: IComponent[]): GroupComponent;

	/**
	 * Возвращает последовательность слоев после объединения компонентов в группу.
	 * @param groupGraphics Графика группы.
	 * @param groupingGraphics Вся графика компонентов, которые должны попасть в группу.
	 * @param globalLayers Полная последовательность слоев на всех страницах скетча.
	 * @param groupingGraphicLayers Правильная последовательность слоев только той графики,
	 * которая будет объединена в группу
	 */
	abstract getPostGroupingLayers (
		groupGraphics: GroupGraphic[],
		groupingGraphics: IGraphic[],
		globalLayers: LayerSequences,
		groupingGraphicLayers: IGraphic[][],
	): IPostGroupingLayers

	/**
	 * Возвращает правильную последовательность слоев после разделения группы на отдельные компоненты.
	 * @param componentGraphics Графики компонентов внутри группы.
	 * @param globalLayers Полная последовательность слоев на каждой странице скетча.
	 * @param graphicInGroupLayers Последовательность слоев графики внутри разделяемой группы.
	 */
	abstract getPostUngroupingLayers (
		componentGraphics: IGraphic[],
		globalLayers: string[][],
		graphicInGroupLayers: IGraphic[][],
	): IPostUngroupLayers;

	/**
	 * Возвращает результаты проверки компонентов на отсутствие среди них групповых компонентов.
	 * @param components - проверяемые компоненты
	 */
	protected isAllSingleComponent = (components: IComponent[]): boolean => components
		.every(component => component.type !== SketchComponentType.GROUP);

	/**
	 * Разбивает компонент группы на самостоятельные компоненты.
	 * @param componentUniter Групповой компонент.
	 */
	protected ungroupGroup = (componentUniter: IComponentUniter): IComponent[] => {
		const components = componentUniter.getComponents();
		if (components === null) {
			throw new ManipulatorError('components not found');
		}
		const parentComponent = componentUniter.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('group parent component not found');
		}

		const componentGraphics: IGraphic[] = components.map(component => component.getGraphics()).flat();

		// Получить позицию для коррекции позиции графики после выхода из компонента группы
		const correctPositions = this.getPostUngroupCorrectPositions(componentGraphics);

		this.dependencies.componentTree.executeMutations(tools => {
			// Последовательность слоев до разделения
			const globalLayers = this.dependencies.componentTree.getLayerSequences();

			// Отражение для хранения последовательности слоев на каждой странице
			const pageSequence = this.getLayerSequenceMap(componentGraphics, globalLayers);

			// Удалить компоненты в группе из дерева
			components.forEach(component => tools.mutator.mutateByRemoveComponent(component));

			// Удалить группу из дерева
			tools.mutator.mutateByRemoveComponent(componentUniter);

			// Добавить компоненты в родительский компонент группы
			components.forEach(component => tools.mutator.mutateByAppendComponent(parentComponent, component));

			// Установить offset для элементов такой же как и у групповой графики
			const offset = componentUniter.getOffset();
			if (offset === null) {
				throw new ManipulatorError('offset is null');
			}
			components.forEach(component => tools.mutator.mutateByChangeOffset(component, offset));

			// Получить последовательность слоев для корректировки их после объединения
			const postGroupingLayers = this.getPostUngroupingLayers(
				componentGraphics,
				globalLayers,
				pageSequence,
			);

			// Синхронизировать номера слоев
			this.syncPostUngroupingLayers(postGroupingLayers, tools.mutator);

			// Скорректировать позицию компонентов
			correctPositions.forEach((position, graphic) => {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: prev.x + position.x,
					y: prev.y + position.y,
				}));
			});
		});

		return components;
	};

	/**
	 * Возвращает последовательность графики в контексте слоев на каждой странице, игнорируя графику,
	 * не переданную графику в `graphics`, учитывая все страницы.
	 * @param graphics Графика компонентов.
	 * @param globalLayerSequences Глобальная последовательность слоев.
	 * @return IGraphic[] Позиция - номер страницы, значение - правильная последовательность графики.
	 */
	protected getLayerSequenceMap = (
		graphics: IGraphic[],
		globalLayerSequences: LayerSequences,
	): IGraphic[][] => {
		const graphicIdentities = graphics.map(graphic => graphic.getID());
		const graphicSequences: IGraphic[][] = [];

		for (let i = 0; i < globalLayerSequences.length; i++) {
			let sequenceIndex = 0;
			for (let j = 0; j < globalLayerSequences[i].length; j++) {
				const graphicIndex = graphicIdentities.indexOf(globalLayerSequences[i][j]);
				if (graphicIndex === -1) {
					// eslint-disable-next-line no-continue
					continue;
				}

				if (graphicSequences[i] === undefined) {
					graphicSequences[i] = [];
				}

				graphicSequences[i][sequenceIndex] = graphics[graphicIndex];
				sequenceIndex++;
			}
		}

		for (let i = 0; i < graphicSequences.length; i++) {
			if (graphicSequences[i] !== undefined) {
				graphicSequences[i] = graphicSequences[i].filter(elem => elem !== undefined);
			}
		}

		return graphicSequences;
	};

	protected groupVariousComponents = (components: IComponent[]): GroupComponent => {
		// TODO
		throw new ManipulatorError('not implemented');
	};

	/**
	 * Синхронизирует последовательность слоев после объединения компонентов в группу.
	 * @param postGroupingLayers Последовательность слоев.
	 * @param mutator Мутатор дерева компонентов.
	 */
	protected syncPostGroupingLayers = (postGroupingLayers: IPostGroupingLayers, mutator: IComponentTreeMutator) => {
		postGroupingLayers.layersFromPages.forEach(pageSequence => {
			if (pageSequence.groupingGraphics.length === 0) {
				throw new ManipulatorError('grouping graphics not found', { postGroupingLayers });
			}

			mutator.mutateByChangeLayer(pageSequence.baseGraphic, pageSequence.groupGraphic);
			mutator.mutateByChangeLayer(pageSequence.groupGraphic, pageSequence.groupingGraphics[0]);

			for (let i = 0; i < pageSequence.groupingGraphics.length - 1; i++) {
				mutator.mutateByChangeLayer(pageSequence.groupingGraphics[i], pageSequence.groupingGraphics[i + 1]);
			}
		});
	};

	/**
	 * Устанавливает новые офсеты компонентам, которые были объединены в группу.
	 * @param components Компоненты в группе.
	 */
	protected correctPostGroupingComponentOffsets = (components: IComponent[]) => {
		const componentOffsets: Map<IComponent, number> = new Map();

		let minComponentOffset = Number.MAX_SAFE_INTEGER;
		components.forEach(component => {
			const { offset } = component.getStructure();
			if (offset === null) {
				throw new ManipulatorError('offset is null');
			}
			if (offset < minComponentOffset) {
				minComponentOffset = offset;
			}
			componentOffsets.set(component, offset);
		});

		components.forEach(component => {
			component.setStructure(prev => ({
				...prev,
				offset: prev.offset === null ? null : prev.offset - minComponentOffset,
			}));
		});
	};

	/**
	 * Возвращает стандартные структуры графики группы.
	 * @param groupGraphicCount Количество график группы.
	 */
	protected getDefaultGroupGraphicStructures = (groupGraphicCount: number): AnyGraphicStructure[] => {
		const structures: AnyGraphicStructure[] = [];

		for (let i = 0; i < groupGraphicCount; i++) {
			const graphicStructure: IGraphicStructure<null> = {
				id: Utils.Generate.UUID4(),
				type: GraphicType.GROUP,
				texture: null,
				offset: i,
				frame: {
					y: 0,
					x: 0,
					height: 0,
					width: 0,
					rotate: 0,
					layer: Number.MAX_SAFE_INTEGER,
				},
			};
			structures.push(graphicStructure);
		}

		return structures;
	};

	/**
	 * Возвращает стандартную структуру группового компонента.
	 * @param offset Сдвиг компонента группы.
	 */
	protected getDefaultGroupStructure = (offset: number): AnyComponentStructure => ({
		offset,
		graphics: [],
		texture: null,
		components: [],
		id: Utils.Generate.UUID4(),
		type: SketchComponentType.GROUP,
	});

	/**
	 * Возвращает графику группы.
	 * @param groupComponent Компонент группы.
	 * @param structures Структуры графики, на основе которой они будут собраны.
	 * @param tools Инструменты мутации дерева компонентов.
	 */
	protected getGroupGraphics = (
		groupComponent: GroupComponent,
		structures: AnyGraphicStructure[],
		tools: IMutationTools,
	): GroupGraphic[] => {
		const graphics: GroupGraphic[] = [];
		structures.forEach((graphicStructure, i) => {
			const groupGraphic = tools.graphicFactory.createGraphic<GroupGraphic>(
				GraphicType.GROUP,
				groupComponent,
			);
			groupGraphic.setStructure(() => graphicStructure);
			graphics.push(groupGraphic);
		});
		return graphics;
	};

	/**
	 * Возвращает сдвиги позиции компонентов внутри группы после их разделения.
	 * @param graphics Графики группы.
	 */
	protected getPostUngroupCorrectPositions = (graphics: IGraphic[]): Map<IGraphic, IDescartesPosition> => {
		const correctPositions = new Map();

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const { x, y } = parentGraphic.getFrameConfiguration();
			correctPositions.set(graphic, {
				x, y,
			});
		});

		return correctPositions;
	};

	/**
	 * Устанавливает правильную последовательность слоев после разделения группы.
	 * @param postUngroupingLayers Правильная последовательность слоев.
	 * @param mutator Мутатор для изменения дерева компонентов.
	 */
	protected syncPostUngroupingLayers = (postUngroupingLayers: IPostUngroupLayers, mutator: IComponentTreeMutator) => {
		postUngroupingLayers.layersFromPages.forEach(pageSequence => {
			if (pageSequence.groupingGraphics.length === 0) {
				throw new ManipulatorError('grouping graphics not found', { postUngroupingLayers });
			}

			mutator.mutateByChangeLayer(pageSequence.baseGraphic, pageSequence.groupingGraphics[0]);

			for (let i = 0; i < pageSequence.groupingGraphics.length - 1; i++) {
				mutator.mutateByChangeLayer(pageSequence.groupingGraphics[i], pageSequence.groupingGraphics[i + 1]);
			}
		});
	};
}

export default ComponentGrouper;
