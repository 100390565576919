import Orientation from '../../page/Orientation';
import IPageTexture from './IPageTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import Graphic from '../Graphic';
import IDescartesPosition from '../../utils/IDescartesPosition';
import PageBackgroundImageState from './PageBackgroundImageState';
import IFrameConfiguration from '../../frame/IFrameConfiguration';
import Frame from '../../frame/Frame';
import IImageTexture from '../foundation/image/IImageTexture';
import SpatialPictureArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialPictureArea';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import PageHeader from '../../interface/page-header/PageHeader';
import IRepeatingImageTexture from '../foundation/image/IRepeatingImageTexture';
import Utils from '../../utils/impl/Utils';
import { AnySpatialArea } from '../../Types';

class PageGraphic extends Graphic<IPageTexture> {
	public readonly type: GraphicType = GraphicType.PAGE;
	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-page';

	private readonly CONTAINER_GRAPHIC_CLASS_NAME = 'page-container_container';
	private readonly CSS_BACKGROUND_ATTRIBUTE = '--background';
	private readonly CSS_CLASS_NAME_LANDSCAPE = 'landscape';
	private readonly CSS_CLASS_NAME_PORTRAIT = 'portrait';

	private topPageHeader: PageHeader | null;
	private bottomPageHeader: PageHeader | null;
	private readonly backgroundImageState: PageBackgroundImageState;

	private backgroundColor: string | null;
	private paddingBottom: number;
	private paddingTop: number;
	private paddingLeft: number;
	private paddingRight: number;
	private orientation: Orientation;

	private repeatingImageTexture: IRepeatingImageTexture | null;

	constructor() {
		super();

		this.topPageHeader = null;
		this.bottomPageHeader = null;
		this.repeatingImageTexture = null;
		this.backgroundImageState = new PageBackgroundImageState();
		this.backgroundImageState.connectDependencies({
			graphic: this,
		});
		this.backgroundImageState.injectDependencies();

		const frameElement = this.getFrameElement();
		frameElement.className = this.CONTAINER_GRAPHIC_CLASS_NAME;

		this.graphicElement.classList.add(this.GRAPHIC_CLASS_NAME);

		this.backgroundColor = 'null';
		this.paddingBottom = 0;
		this.paddingTop = 0;
		this.paddingLeft = 0;
		this.paddingRight = 0;
	}

	public connectTopPageHeader = (pageHeader: PageHeader) => {
		this.topPageHeader = pageHeader;
	};

	public connectBottomPageHeader = (pageHeader: PageHeader) => {
		this.bottomPageHeader = pageHeader;
	};

	public setBackgroundColor = (background: string) => {
		this.graphicElement.style.setProperty(this.CSS_BACKGROUND_ATTRIBUTE, background);
		this.backgroundColor = background;
		this.backgroundImageState.clear();
		this.clearRepeatingImage();
	};

	public enableBackgroundPictureLoadingMode = () => {
		this.backgroundImageState.enableLoadingBlock();
	};

	public disableBackgroundPictureLoadingMode = () => {
		this.backgroundImageState.disableBlocks();
	};

	public setOrientation = (orientation: Orientation) => {
		this.resetOrientation();

		switch (orientation) {
		case Orientation.PORTRAIT: {
			this.graphicElement.classList.add(this.CSS_CLASS_NAME_PORTRAIT);
			break;
		}
		case Orientation.LANDSCAPE: {
			this.graphicElement.classList.add(this.CSS_CLASS_NAME_LANDSCAPE);
			break;
		}
		default: {
			throw new ManipulatorError(`orientation "${orientation}" not found`);
		}
		}
		this.orientation = orientation;
	};

	/**
	 * Возвращает доступное пространство для компонентов по оси y (высота страницы минус верхний и нижний отступ).
	 */
	public getAvailableSpaceY = (): number => {
		const texture = this.getTexture();
		const height = this.getRealHeight();
		const space = height - texture.paddingTop - texture.paddingBottom;
		if (space < 0) {
			throw new ManipulatorError('available y space < 0');
		}
		return space;
	};

	/**
	 * Возвращает доступное пространство для компонентов по оси x (ширина страницы минус левый и правый отступ).
	 */
	public getAvailableSpaceX = (): number => {
		const texture = this.getTexture();
		const width = this.getRealWidth();
		const space = width - texture.paddingLeft - texture.paddingRight;
		if (space < 0) {
			throw new ManipulatorError('available y space < 0');
		}
		return space;
	};

	public override appendFrame = (frame: Frame) => {
		const frameElement = frame.getFrameElement();
		this.graphicElement.append(frameElement);
	};

	public setStructure = (
		fn: (prev: IGraphicStructure<IPageTexture>) => IGraphicStructure<IPageTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const { texture, id, offset } = updated;
		if (texture === null) {
			throw new ManipulatorError('page graphic texture cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setTexture(_ => texture);
	};

	public getTexture = (): IPageTexture => ({
		backgroundColor: this.backgroundColor,
		orientation: this.orientation,
		paddingLeft: this.paddingLeft,
		paddingRight: this.paddingRight,
		paddingTop: this.paddingTop,
		paddingBottom: this.paddingBottom,
		backgroundImage: this.backgroundImageState.hasPicture()
			? this.backgroundImageState.getTexture()
			: null,
		repeatingImage: this.repeatingImageTexture !== null
			? { ...this.repeatingImageTexture }
			: null,
	});

	public setTexture = (fn: (prev: IPageTexture) => IPageTexture) => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);
		const {
			backgroundColor, orientation, paddingBottom,
			paddingTop, paddingRight, paddingLeft, backgroundImage,
			repeatingImage,
		} = updatedTexture;

		if (backgroundColor !== null && backgroundColor !== '') {
			this.setBackgroundColor(backgroundColor);
			this.backgroundImageState.clear();
			this.clearRepeatingImage();
		} else if (backgroundImage !== null) {
			this.backgroundImageState.enableTextureBlock(backgroundImage);
			this.clearRepeatingImage();
			this.backgroundColor = null;
		} else if (repeatingImage !== null) {
			this.backgroundImageState.clear();
			this.setRepeatingImage(repeatingImage);
			this.backgroundColor = null;
		} else {
			throw new ManipulatorError('invalid page texture');
		}

		this.setOrientation(orientation);
		this.paddingBottom = paddingBottom;
		this.paddingTop = paddingTop;
		this.paddingRight = paddingRight;
		this.paddingLeft = paddingLeft;
	};

	public syncBottomPageHeader = () => {
		if (this.bottomPageHeader === null) {
			throw new ManipulatorError('page headers is null');
		}
		this.bottomPageHeader.sync();
	};

	public syncTopPageHeader = () => {
		if (this.topPageHeader === null) {
			throw new ManipulatorError('page header is null');
		}
		this.topPageHeader.sync();
	};

	public showBottomPageHeader = () => {
		if (this.bottomPageHeader === null) {
			throw new ManipulatorError('page header is null');
		}
		this.bottomPageHeader.show();
	};

	public hideBottomPageHeader = () => {
		if (this.bottomPageHeader === null) {
			throw new ManipulatorError('page header is null');
		}
		this.bottomPageHeader.hide();
	};

	/**
	 * Устанавливает изображение в качестве повторяющегося фона.
	 * @param repeatingImage сигнатура изображения.
	 */
	public setRepeatingImage = (repeatingImage: IRepeatingImageTexture) => {
		this.repeatingImageTexture = {
			source: repeatingImage.source,
			size: repeatingImage.size,
		};
		this.backgroundImageState.clear();
		this.backgroundImageState.disableBlocks();
		this.backgroundColor = null;

		const imagePath = Utils.Backend.getImageURI(repeatingImage.source);

		this.graphicElement.style.background = `url(${imagePath})`;
		this.graphicElement.style.backgroundSize = `${repeatingImage.size}px`;
	};

	/**
	 * Устанавливает изображение в качестве фона, используется только при первой установке пользователем.
	 * @param pictureSource - идентификатор изображения
	 * @param postAdaptPictureListener - слушатель события адаптации изображения.
	 */
	public setBackgroundImage = (pictureSource: string, postAdaptPictureListener: VoidFunction) => {
		const currentTexture = this.backgroundImageState.getTexture();
		const texture: IImageTexture = {
			...currentTexture,
			source: pictureSource,
		};

		const frameAspectRatio = this.getFrameAspectRatio();
		const frameConfiguration = this.getFrameConfiguration();

		this.backgroundImageState
			.addPostLoadPictureListener(
				this.backgroundImageState.adaptPicture.bind(this, frameConfiguration, frameAspectRatio),
			);
		this.backgroundImageState.addPostLoadPictureListener(postAdaptPictureListener);
		this.backgroundImageState.addPostLoadPictureListener(this.backgroundImageState.enableEditMode);
		this.backgroundImageState.enableTextureBlock(texture);

		this.backgroundColor = null;
		this.clearRepeatingImage();
	};

	public clearRepeatingImage = () => {
		this.repeatingImageTexture = null;
		this.graphicElement.style.removeProperty('background');
		this.graphicElement.style.removeProperty('background-size');
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const areas: AnySpatialArea[] = [];

		const graphicArea = new SpatialGraphicArea(this);
		areas.push(graphicArea);

		if (this.backgroundImageState.hasPicture()) {
			const pictureArea = new SpatialPictureArea(this, this.backgroundImageState);
			const pictureResizeAreas = this.backgroundImageState.getResizeAreas();

			areas.push(pictureArea, ...pictureResizeAreas);
		}

		return areas;
	};

	public override getGlobalPosition = (): IDescartesPosition => {
		const bound = this.graphicElement.getBoundingClientRect();
		return {
			x: bound.x + window.scrollX,
			y: bound.y + window.scrollY,
		};
	};

	public getContainerPagePosition = (): IDescartesPosition => {
		const bound = this.frameElement.getBoundingClientRect();
		return {
			x: bound.x + window.scrollX,
			y: bound.y + window.scrollY,
		};
	};

	public removeBackgroundImage = () => {
		this.backgroundImageState.disableBlocks();
	};

	public hasBackgroundImage = () => {
		const texture = this.getTexture();
		return texture.backgroundImage !== null;
	};

	/**
	 * Растягивает фоновое изображение по высоте.
	 */
	public stretchBackgroundImageToHeight = () => {
		const frameAspectRatio = this.getFrameAspectRatio();
		const frameConfiguration = this.getFrameConfiguration();

		this.backgroundImageState.adaptPicture(frameConfiguration, frameAspectRatio);
	};

	public getBackgroundImageState = (): PageBackgroundImageState => this.backgroundImageState;

	public getUniqueTexture = (): IPageTexture => this.getTexture();

	/** Возвращает конфигурацию фрейма {x, y, width, height, rotate, layer} */
	override getFrameConfiguration = (): IFrameConfiguration => {
		const width = this.getRealWidth();
		const height = this.getRealHeight();
		const { x, y } = this.getGlobalPosition();

		return {
			x: Math.round(x),
			y: Math.round(y),
			width: Math.round(width),
			height: Math.round(height),
			layer: 0,
			rotate: 0,
		};
	};

	override getFrameAspectRatio = (): number => {
		const frameConfiguration = this.getFrameConfiguration();
		return frameConfiguration.width / frameConfiguration.height;
	};

	private resetOrientation = () => {
		this.graphicElement.classList.remove(this.CSS_CLASS_NAME_LANDSCAPE);
		this.graphicElement.classList.remove(this.CSS_CLASS_NAME_PORTRAIT);
	};

	protected startMutation = (): void => {
		this.backgroundImageState.enableEditMode();
	};

	protected finishMutation = (): void => {
		this.backgroundImageState.disableEditMode();
	};
}

export default PageGraphic;
