import React from 'react';
import { ChangeableTagList } from '../../../changeable-tag-bar/ui/tag-list/changeable-tag-list';
import { workshopModuleApi } from '../../../../entities/workshop-module';
import { useUnbindWorkshopModuleTag } from '../../model/services/useUnbindWorkshopModuleTag';

interface WorkshopModuleBindTagListProps {
	className?: string,
	moduleId?: string,
}

export const WorkshopModuleBindTagList = ({ className, moduleId }: WorkshopModuleBindTagListProps) => {
	const { data: moduleData } = workshopModuleApi.useGetWorkshopModuleQuery({
		moduleId: moduleId || '',
	});

	const { onUnbindTag } = useUnbindWorkshopModuleTag(moduleId);

	return (
		<ChangeableTagList
			deleteTag={onUnbindTag}
			activeTags={moduleData?.module?.tags || []}
		/>
	);
};
