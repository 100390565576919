import React, { useRef, useState } from 'react';
import { Emoji } from 'emoji-picker-react';
import { CSSTransition } from 'react-transition-group';
import css from './role.module.scss';
import Utils from '../../../../Sketch/utils/impl/Utils';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';
import { IRoleItem } from '../../../../../entities/role/api/types';

export interface RoleProps {
	text: string,
	bgColor: string,
	emoji?: string,
	id?: string,
	permission?: number,
	userEmail?: string,
	canDeleteRole?: boolean,
	isCursorPointed?: boolean,
	cssClass?: string,
	onClick?: (role: IRoleItem) => void,
	onDeleteRole?: (roleId: string, email?: string) => void,
}

const Role: React.FC<RoleProps> = ({
	text,
	bgColor = '#ffffff',
	id,
	emoji,
	permission,
	userEmail,
	canDeleteRole,
	isCursorPointed,
	cssClass,
	onClick,
	onDeleteRole,
}: RoleProps) => {
	Role.defaultProps = {
		emoji: undefined,
		id: undefined,
		permission: undefined,
		userEmail: undefined,
		canDeleteRole: undefined,
		isCursorPointed: undefined,
		cssClass: undefined,
		onClick: undefined,
		onDeleteRole: undefined,
	};

	const roleRef = useRef(null);

	const [isDeleteShow, setIsDeleteShow] = useState(false);

	// * При наведении на роль
	const onRoleMouseEnter = () => {
		if (canDeleteRole) {
			setIsDeleteShow(true);
		}
	};

	const onRoleMouseLeave = () => {
		if (canDeleteRole) {
			setIsDeleteShow(false);
		}
	};

	const onRoleDeleting = () => {
		if (onDeleteRole && userEmail && id) {
			onDeleteRole(id, userEmail);
		} else if (onDeleteRole && id) {
			onDeleteRole(id);
		}
	};

	const onRoleClick = () => {
		if (onClick && bgColor && emoji && id && permission !== undefined) {
			const role: IRoleItem = {
				background: bgColor,
				emoji,
				id,
				name: text,
				permission,
			};
			onClick(role);
		}
	};

	const roleStyles = {
		backgroundColor: bgColor,
		cursor: isCursorPointed ? 'pointer' : '',
	};

	const textStyles = { color: Utils.Color.getAccentColorFromHex(bgColor) };

	return (
		<div
			onMouseEnter={onRoleMouseEnter}
			onMouseLeave={onRoleMouseLeave}
			className={`${css.role} ${cssClass}`}
			style={roleStyles}
			onClick={onRoleClick}
			role="presentation"
		>
			{
				emoji && (
					<Emoji
						unified={emoji}
						size={20}
						lazyLoad
					/>
				)
			}
			<div className={css.text}>
				<span
					style={textStyles}
				>
					{text}
				</span>
			</div>
			<CSSTransition
				nodeRef={roleRef}
				in={isDeleteShow}
				timeout={200}
				classNames={popUpTransitionClasses}
				unmountOnExit
			>
				<div
					ref={roleRef}
					className={css.delete}
					onClick={onRoleDeleting}
					role="presentation"
				/>
			</CSSTransition>
		</div>
	);
};

export default Role;
