import ManipulatorCommand from '../ManipulatorCommand';
import IComponentRemoveBody from './IComponentRemoveBody';
import CommandType from '../CommandType';
import IComponent from '../../../components/IComponent';
import IComponentTreeMutator from '../../../component-tree/IComponentTreeMutator';

class ComponentRemoveCommand extends ManipulatorCommand<IComponentRemoveBody> {
	public type: CommandType = CommandType.REMOVE_COMPONENT;

	private readonly sketch: string;
	private readonly parent: IComponent;
	private readonly component: IComponent;
	private readonly componentTreeMutator: IComponentTreeMutator;

	constructor(
		componentTreeMutator: IComponentTreeMutator,
		component: IComponent,
		parent: IComponent,
		sketchID: string,
	) {
		super();
		this.parent = parent;
		this.sketch = sketchID;
		this.component = component;
		this.componentTreeMutator = componentTreeMutator;
	}

	public exec = (): void => {
		this.componentTreeMutator.executeMutations(tools => {
			tools.mutator.mutateByRemoveComponent(this.component);
		});
	};

	protected buildBody = (): IComponentRemoveBody => {
		const parentId = this.parent.getStructure().id;
		const componentId = this.component.getStructure().id;

		return {
			sketch: this.sketch,
			parent: parentId,
			component: componentId,
		};
	};
}

export default ComponentRemoveCommand;
