export type HandlerMouseEvent = (() => void) | ((ev: MouseEvent) => void);
export type HandlerKeyboardEvent = (() => any) | ((ev: KeyboardEvent) => void);
export type HandlerFn = (() => void);
export type ChangeSelectFn = ((event: Event) => void);

interface IGetDIV {
	className?: string
	id?: string
	fnClick?: HandlerMouseEvent
	fnMouseUp?: HandlerMouseEvent
	fnMouseDown?: HandlerMouseEvent
	fnDoubleClick?: HandlerMouseEvent
	fnMouseEnter?: HandlerMouseEvent
	fnMouseLeave?: HandlerMouseEvent
}

export interface ISelectOption {
	text : string
	value : string
}

interface IGetParagraph {
	className?: string
	text?: string
}

interface IGetLabel {
	text?: string
}

interface IGetCanvas {
	id?: string,
	width?: number,
	height?: number,
}

interface IGetInput {
	value?: string
	type: string
	maxlength?: number
	placeholder?: string
	className?: string
	fnKeyDown?: HandlerKeyboardEvent
	fnKeyUp?: HandlerKeyboardEvent
	fnOnInput?: HandlerFn
	fnOnChange?: HandlerFn
	HandlerKeyboardEvent?: HandlerKeyboardEvent
}

interface IGetSelect {
	options?: ISelectOption[],
	className?: string,
	fnChange?: ChangeSelectFn,
}

interface IGetSpan {
	text?: string
	className?: string
}

interface IGetBtn {
	text?: string
	className?: string
	fnClick?: HandlerMouseEvent
}

interface IGetImg {
	src?: string
	className?: string
}

interface IGetH1 {
	fnClick?: HandlerMouseEvent
	text?: string
	className?: string
	fnKeyUp?: HandlerKeyboardEvent
}

interface IGetH4 {
	fnClick?: HandlerMouseEvent
	text?: string
	className?: string
}

interface IHTMlElement {
	target?: string;
	tag?: string
	className?: string
	text?: string
	fnClick?: HandlerMouseEvent
	href?: string
}

interface IGetTableData {
	text?: string,
}

interface IGetTableCaption {
	text?: string,
}

class HTMLGeneratorClass {
	public getDiv = (props?: IGetDIV): HTMLElement => {
		const element = document.createElement('div');
		if (!props) {
			return element;
		}
		element.className = props.className ? props.className : '';
		// element.id = props.id ? props.id : '';
		if (props.fnClick) {
			element.addEventListener('click', props.fnClick);
		}
		if (props.fnMouseDown) {
			element.addEventListener('mousedown', props.fnMouseDown);
		}
		if (props.fnMouseUp) {
			element.addEventListener('mouseup', props.fnMouseUp);
		}
		if (props.fnDoubleClick) {
			element.addEventListener('dblclick', props.fnDoubleClick);
		}
		if (props.fnMouseEnter) {
			element.addEventListener('mouseenter', props.fnMouseEnter);
		}
		if (props.fnMouseLeave) {
			element.addEventListener('mouseleave', props.fnMouseLeave);
		}
		return element;
	};

	public getSpan = (props?: IGetSpan): HTMLElement => {
		const element = document.createElement('span');
		if (!props) {
			return element;
		}
		if (props.className) element.className = props.className;
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getH1 = (props: IGetH1): HTMLElement => {
		const element = document.createElement('h1');
		if (props.fnClick) {
			element.addEventListener('click', props.fnClick);
		}
		if (props.fnKeyUp) {
			element.addEventListener('keyup', props.fnKeyUp);
		}
		element.className = props.className ? props.className : '';
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getH4 = (props: IGetH4): HTMLElement => {
		const element = document.createElement('h4');
		if (props.fnClick) {
			element.addEventListener('click', props.fnClick);
		}
		element.className = props.className ? props.className : '';
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getImg = (props?: IGetImg): HTMLImageElement => {
		const element = document.createElement('img');
		if (!props) {
			return element;
		}
		if (props.src) {
			element.src = props.src;
		}
		element.className = props.className ? props.className : 'img';
		return element;
	};

	public getBtn = (props?: IGetBtn): HTMLElement => {
		const element = document.createElement('button');
		if (!props) {
			return element;
		}
		if (props.fnClick) {
			element.addEventListener('click', props.fnClick);
		}
		element.className = props.className ? props.className : '';
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getCanvas = (props?: IGetCanvas): HTMLCanvasElement => {
		const element = document.createElement('canvas');
		if (!props) {
			return element;
		}
		element.id = props.id ? props.id : '';
		element.width = props.width ? props.width : 150;
		element.height = props.height ? props.height : 200;
		return element;
	};

	public getParagraph = (props?: IGetParagraph): HTMLElement => {
		const element = document.createElement('p');
		if (!props) {
			return element;
		}
		element.className = props.className ? props.className : '';
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getLabel = (props?: IGetLabel): HTMLElement => {
		const element = document.createElement('label');
		if (!props) {
			return element;
		}
		if (props.text) {
			element.textContent = props.text;
		}
		return element;
	};

	public getInput = (props?: IGetInput): HTMLInputElement => {
		const element = document.createElement('input');
		if (!props) {
			return element;
		}
		if (props.maxlength) {
			element.maxLength = props.maxlength;
		}
		if (props.type.length !== 0) {
			element.type = props.type;
		}
		if (props.value) {
			element.value = props.value;
		}
		if (props.placeholder) {
			element.placeholder = props.placeholder;
		}
		if (props.fnKeyDown) {
			element.addEventListener('keydown', props.fnKeyDown);
		}
		if (props.fnKeyUp) {
			element.addEventListener('keyup', props.fnKeyUp);
		}
		if (props.fnOnInput) {
			element.addEventListener('input', props.fnOnInput);
		}
		if (props.fnOnChange) {
			element.addEventListener('change', props.fnOnChange);
		}
		element.className = props.className ? props.className : '';
		return element;
	};

	public getSelect = (props?: IGetSelect): HTMLSelectElement => {
		const element = document.createElement('select');
		if (!props) {
			return element;
		}
		if (props.fnChange) {
			element.addEventListener('change', props.fnChange);
		}
		if (props.options) {
			props.options.forEach((option) => {
				const newOption = new Option(option.value, option.text);
				element.append(newOption);
			});
		}
		element.className = props.className ? props.className : '';
		return element;
	};

	public getAside = (className?: string): HTMLElement => {
		const element = document.createElement('aside');
		if (!className) {
			return element;
		}
		if (className) {
			element.className = className;
		}
		return element;
	};

	public getHTMlElement<T extends HTMLElement>(props: IHTMlElement): T {
		let tag = 'div';
		if (props.tag) {
			tag = props.tag;
		}
		const element: T = document.createElement(tag) as T;

		element.className = props.className ? props.className : '';
		if (props.fnClick) {
			element.addEventListener('click', props.fnClick);
		}
		if (props.text) {
			element.textContent = props.text;
		}
		if (props.href && tag === 'a') {
			const anchorElement = element as unknown as HTMLAnchorElement;
			anchorElement.href = props.href;
		}
		if (props.target && tag === 'a') {
			const anchorElement = element as unknown as HTMLAnchorElement;
			anchorElement.target = props.target;
		}

		return element as T;
	}

	public getFragment = (): DocumentFragment => document.createDocumentFragment();

	public getTableData = (props?: IGetTableData): HTMLTableCellElement => {
		const element = document.createElement('td');
		if (!props) {
			return element;
		}
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getTableCaption = (props?: IGetTableCaption): HTMLTableCaptionElement => {
		const element = document.createElement('caption');
		if (!props) {
			return element;
		}
		element.textContent = props.text ? props.text : '';
		return element;
	};

	public getSelectOption = (option: ISelectOption): HTMLOptionElement => new Option(option.text, option.value);
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const HTMLGenerator = new HTMLGeneratorClass();

export default HTMLGenerator;
