import React from 'react';
import css from './tag.module.scss';
import Tag from './Tag';

export interface TagProps {
	name: string,
	choiced: boolean,
	onClick: () => void,
}

export interface TagMenuProps {
	tags: Array<TagProps>
	closeMenu: () => void,
}

const TagMenu: React.FC<TagMenuProps> = ({
	tags,
	closeMenu,
}: TagMenuProps) => (
	<div className={css.menuWrapper}>
		{
			tags.map(tag => {
				const { choiced, name, onClick } = tag;
				const onTagClick = () => {
					onClick();
					closeMenu();
				};
				return (
					<Tag key={name} name={name} choiced={choiced} onClick={onTagClick} />
				);
			})
		}
	</div>
);

export default TagMenu;
