import IContextMenu from './IContextMenu';
import ElementContainer from '../../../utils/ElementContainer';
import HTMLElementName from '../../../utils/HTMLElementName';
import IDescartesPosition from '../../../utils/IDescartesPosition';

/**
 * Контекстное меню манипулятора.
 */
class ContextMenu extends ElementContainer<HTMLDivElement> implements IContextMenu {
	private readonly ELEMENT_CLASS_NAME = 'context-menu';

	private currentPosition: IDescartesPosition;

	constructor() {
		super(HTMLElementName.DIV);
		this.currentPosition = { x: 0, y: 0 };
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
	}

	public remove = (): void => {
		this.rootElement.remove();
	};

	public append = (position: IDescartesPosition): void => {
		this.applyPosition(position);
		this.currentPosition = position;
		document.body.append(this.rootElement);
		this.adjustPosition(position);
	};

	public getCurrentPosition = (): IDescartesPosition => ({ ...this.currentPosition });

	private applyPosition = (position: IDescartesPosition) => {
		this.rootElement.style.top = `${position.y}px`;
		this.rootElement.style.left = `${position.x}px`;
	};

	/**
	 * Проверяет не выходит ли контекстное меню за пределы окна
	 */
	private adjustPosition(position: IDescartesPosition) {
		const { offsetWidth } = this.rootElement;
		const { offsetHeight } = this.rootElement;
		const windowBottom = window.scrollY + document.documentElement.clientHeight;
		const windowRight = window.scrollX + document.documentElement.clientWidth;
		if (position.y + offsetHeight > windowBottom) {
			const distance = position.y + offsetHeight - windowBottom;
			this.rootElement.style.top = `${(position.y - distance)}px`;
		}
		if (position.x + offsetWidth > windowRight) {
			const distance = position.x + offsetWidth - windowRight;
			this.rootElement.style.left = `${(position.x - distance)}px`;
		}
	}
}

export default ContextMenu;
