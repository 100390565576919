import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { buttonsTypes } from '../../../ui/button/button';
import css from './unRegisteredHeader.module.scss';
import { AUTHORIZATION_LINK, REGISTRATION_LINK } from '../authorization/Authorization';

const UnRegisteredHeader: React.FC = () => {
	const navigate = useNavigate();

	const onMainPage = () => {
		navigate('/');
	};

	return (
		<header className={css.header}>
			<div
				className={css.logo_box}
				onClick={onMainPage}
				role="presentation"
			>
				<svg fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M41.1564 4.91077C39.5997 3.73289 37.292 2.96582 
					34.5155 2.96582C33.6474 2.96582 32.7317 3.04216 31.7793 3.19849C29.5009 3.57657 27.5962 4.76172 
					25.5926 6.10683C23.5963 7.43376 21.5194 8.90247 18.8491 9.51685C17.9151 9.72771 16.9884 9.84768 
					16.0873 9.84768C13.0251 9.84404 10.2632 8.81885 8.24858 7.81911C7.87862 7.63734 7.53797 7.45193 
					7.21929 7.2738C8.0984 11.3527 11.6075 14.6646 15.4902 15.0718C11.135 15.8534 8.09474 13.3522 
					6.38781 11.0837C5.34753 14.2175 7.4061 17.7874 9.13501 19.2234C10.8566 20.6413 12.5635 22.1463 
					15.5195 22.5608C15.5195 22.5608 14.7649 26.098 4.81641 29.5662C5.82005 31.1803 9.64782 32.3946 
					11.6954 32.3946C12.5965 32.3982 13.2009 32.5982 15.9005 31.9729L16.0213 39.3164L24.7245 
					34.7758C25.8783 38.9638 27.5633 41.2032 28.3215 42.2829L32.1273 35.0157L30.9185 30.1661C30.6914 
					29.2245 30.8855 28.2429 31.4496 27.4686C32.0247 26.6833 32.9075 26.2035 33.9075 
					26.1235H33.9185H33.9294L34.8745 26.0435C35.4862 18.9762 37.1125 14.8137 39.0942 12.327C40.5081 
					10.5348 42.2663 10.124 43.6655 9.77861C43.6362 8.01542 42.6912 6.08501 41.1564 4.91077Z" 
						fill="#FFAE00"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M50.5227 14.727C48.9806 12.6948 47.0539 11.775 
					45.0246 11.7823C43.9404 11.8077 42.3287 11.9604 40.7134 13.9381C39.1932 15.8212 37.087 19.4421 
					36.501 26.1895C43.8232 28.3089 44.5668 34.6418 44.6657 35.0708C45.7243 34.4055 47.27 33.2931 
					48.6766 31.9007C51.0282 29.574 53.1417 26.2513 53.1307 22.5431C53.1344 19.6348 52.0575 16.7483 
					50.5227 14.727Z" 
						fill="#353535"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M56.6183 26.5746C55.8161 25.2876 54.3326 25.8402 
					53.2191 26.331C52.1055 26.8218 50.1312 31.6642 47.721 34.4235C45.3108 37.1827 44.754 36.8156 
					
					43.5819 32.5839C42.4097 28.3523 37.6516 27.9851 35.1791 27.4943C32.7103 27.0036 32.648 29.5156 
					32.9593 30.3118C33.267 31.1079 32.7139 31.7223 33.1461 35.2778C33.5784 38.8332 36.234 42.8795 
					37.1607 43.123C38.0875 43.3666 54.4571 41.4071 56.4974 41.0399C58.5376 40.6728 61.2555 39.8766 
					61.3764 38.0989C61.4973 36.3211 57.4204 27.8615 56.6183 26.5746Z" 
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M62.5777 37.0447L58.3103 26.7673C58.0283 26.0875 
					57.5191 25.4913 56.8781 25.0805C56.4203 24.7897 55.9074 24.6043 55.391 24.5315C55.391 24.5315 
					56.8122 19.3947 52.7646 13.5417C51.1676 11.2368 48.2885 9.64084 45.5889 9.37546C45.5633 6.55437 
					44.4315 4.67485 42.4351 3.17705C40.3693 1.62836 37.5598 0.755859 34.3657 0.755859C33.362 0.755859 
					32.3218 0.84311 31.2522 1.02125C28.3805 1.50476 26.1644 2.94439 24.1681 4.27859C22.1644 5.62733 
					20.3623 6.85611 18.3696 7.30326C17.648 7.46686 16.9264 7.53957 16.2085 7.53957C13.9265 7.5432 
					11.6958 6.75795 9.95221 5.96906C6.49805 4.4131 5.05486 2.52268 5.05486 2.52268C4.34058 3.86415 
					4.33326 6.20173 4.68856 8.39026C4.0622 10.1571 3.55305 11.8621 3.55305 13.3526C3.54206 16.9444 
					6.6519 21.7141 11.8826 23.4809C6.02187 27.1272 0 28.1379 0 28.1379C4.23069 35.9722 12.8862 34.9834 
					13.4723 34.9216L13.0767 44.3192L23.3512 37.9099C24.7578 42.476 28.798 46.6568 28.798 
					46.6568L32.6551 38.1135L33.4683 41.3854C33.6734 42.2433 34.1899 43.0032 34.9188 43.523C35.6697 
					44.0611 36.5781 44.2974 37.4755 44.1883L60.0649 41.5272H60.0795L60.0942 41.5235C61.0795 41.3963
					 61.9256 40.8401 62.4165 40.0003C62.933 39.1169 62.9879 38.0408 62.5777 37.0447ZM54.7536 
					 27.0145C54.8195 27.0109 54.8562 27.0073 54.8562 27.0073C55.0759 26.9891 55.314 27.0582 55.5155 
					 27.189C55.7206 27.3199 55.8891 27.5053 55.9807 27.7235L58.7902 34.4926C56.9111 33.7219 54.358 
					 32.6713 52.0394 31.7188C52.6218 30.9553 53.1932 30.1119 53.7353 29.1813C54.1346 28.5014 54.4752 
					 27.778 54.7536 27.0145ZM45.0212 11.9457C47.0211 11.9384 48.9222 12.8437 50.4423 14.8504C51.9551 
					 16.8426 53.0174 19.6892 53.0137 22.5539C53.021 26.2111 50.9405 29.4866 48.6219 31.7806C47.2373 
					 33.1548 45.7904 34.1763 44.7465 34.8307C44.6476 34.409 43.8381 28.2433 36.6184 26.1529C37.1935 
					 19.5038 39.274 15.9301 40.7722 14.0761C42.3619 12.1202 43.9516 11.9712 45.0212 11.9457ZM30.7394 
					 30.0574L31.9225 34.8343L28.2522 41.8762C27.5087 40.8146 26.0069 38.7243 24.8714 34.598L16.1352 
					 39.0333L16.018 31.8388C13.3587 32.4568 12.7617 32.2605 11.8752 32.2568C9.86063 32.2568 6.0878 
					 31.2607 5.09881 29.6684C14.9045 26.2511 15.9301 22.4048 15.9301 22.4048C13.0181 21.994 11.0547 
					 20.6707 9.35881 19.2747C7.65554 17.8605 5.58598 14.9813 6.6116 11.8948C8.29289 14.1342 12.2562 
					 16.8753 16.5968 15.0285C12.769 14.625 8.34418 11.524 7.47606 7.50321C7.78741 7.67771 8.1244 
					 7.85948 8.49069 8.03762C10.476 9.02282 13.1939 10.0335 16.2122 10.0371C17.1023 10.0371 18.0143 
					 9.92077 18.9337 9.70992C21.5637 9.10643 23.6076 7.65953 25.5783 6.35078C27.5526 5.02749 29.4317 
					 3.85688 31.6734 3.48606C32.6111 3.32974 33.5122 3.25703 34.3694 3.25703C37.1056 3.25703 39.3766 
					 4.00957 40.9114 5.1729C42.4242 6.32897 43.2373 7.80859 43.2703 9.54996C41.893 9.88805 40.2777 
					 10.7133 38.8821 12.4801C36.9298 14.9304 35.2448 19.0311 34.6441 25.9966L33.7137 
					 26.073H33.7027H33.6917C32.7064 26.153 31.8346 26.6256 31.2705 27.3999C30.7064 28.1597 30.5159 
					 29.1304 30.7394 30.0574ZM33.3108 29.4757C33.2558 29.2467 33.3547 29.0213 33.4243 28.8286C33.5269 
					 28.5451 33.9078 28.2906 34.3217 28.2615C36.2777 27.8471 38.1275 28.8541 38.9773 29.5084C39.8235 
					 30.1628 40.6403 31.1953 41.1495 32.1914C41.2593 32.4022 41.3582 32.6131 41.4571 32.824L35.3254 
					 38.4734L33.3108 29.4757ZM60.2407 38.7424C60.1491 38.9024 59.988 39.0115 59.7718 39.0405L37.2228 
					 41.6181C36.9444 41.6544 36.1898 41.8471 36.069 40.8655L42.1861 34.6998C42.5633 36.074 42.567 
					 37.7899 42.3912 39.1678C42.3912 39.1678 46.5743 37.5318 50.761 33.1984C54.4862 34.7289 58.3616 
					 36.4049 59.8231 37.0047L60.248 38.0008C60.3652 38.2807 60.3542 38.5498 60.2407 38.7424ZM33.384 
					 15.7156C33.384 13.3962 32.2815 11.8585 31.0141 11.9312C29.7357 12.0039 28.5563 13.6943 28.3438 
					 15.8683C28.1167 18.1586 29.0581 20.3435 30.5929 20.3435C32.1423 20.3435 33.384 18.0932 33.384 
					 15.7156Z"
						fill="#0E0E0E"
					/>
				</svg>
				<svg fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M22.6633 13.2542C22.3675 14.4191 22.0569 15.6083 21.7266 16.8169C21.3963 18.0303 21.061 
					19.2098 20.7159 20.365C20.3708 21.5153 20.0405 22.5734 19.725 23.5345C19.3602 22.4327 19.0003 
					21.292 18.6502 20.1126C18.2952 18.938 17.9699 17.7585 17.6642 16.5839C17.3585 15.4093 17.0726 
					14.2638 16.8063 13.1474C16.5401 12.031 16.2936 10.9826 16.0767 9.99728H12.6749C12.4531 10.9826 
					12.2065 12.031 11.9255 13.1474C11.6445 14.2638 11.3438 15.4093 11.0283 16.5839C10.7127 17.7585 
					10.3726 18.938 10.0225 20.1126C9.66755 21.2872 9.30764 22.4278 8.94281 23.5345C8.5977 22.5734 
					8.25753 21.5202 7.91242 20.365C7.56732 19.2146 7.237 18.0303 6.92147 16.8169C6.60101 15.6034 
					6.29535 14.4191 6.00447 13.2542C5.70866 12.0893 5.45229 11.002 5.23537 9.99728H0.551758C1.06449 
					11.8466 1.58708 13.657 2.12447 15.4335C2.66185 17.21 3.19923 18.904 3.73662 20.5106C4.274 22.1172 
					4.81138 23.6316 5.34876 25.0489C5.88615 26.4662 6.39888 27.7233 6.88696 28.83H10.5204C11.1515 
					27.0535 11.7924 25.1654 12.4383 23.1608C13.0841 21.1561 13.6856 19.0981 14.2476 16.9867C14.8097 
					19.0981 15.421 21.1561 16.0915 23.1608C16.762 25.1654 17.4128 27.0535 18.0438 28.83H21.6428C22.1555 
					27.7282 22.6781 26.4662 23.2155 25.0489C23.7529 23.6316 24.2903 22.122 24.8277 20.5106C25.365 
					18.904 25.9024 17.21 26.4398 15.4335C26.9772 13.657 27.4998 11.8466 28.0125 9.99728H23.4423C23.2106 
					11.002 22.9542 12.0893 22.6633 13.2542ZM42.8669 11.5796C42.2704 10.9292 41.4717 10.4293 40.4709 
					10.0652C39.4701 9.70605 38.2425 9.52646 36.7783 9.52646C35.5605 9.52646 34.4118 9.62354 33.342 
					9.81284C32.2672 10.007 31.4537 10.1963 30.8917 10.3904L31.4389 13.9191C31.9763 13.725 32.6715 13.56 
					33.5244 13.4143C34.3773 13.2687 35.314 13.1959 36.3395 13.1959C37.0938 13.1959 37.7248 13.293 
					38.2228 13.4823C38.7207 13.6765 39.125 13.9434 39.4307 14.2929C39.7363 14.6424 39.9483 15.0452 
					40.0716 15.4966C40.1948 15.9529 40.254 16.4334 40.254 16.9382V17.6566C39.6426 17.5109 39.0708 
					17.409 38.5334 17.3508C37.996 17.2925 37.4833 17.2586 36.9952 17.2586C35.9451 17.2586 34.9393 
					17.3605 33.9779 17.5643C33.0166 17.7682 32.1686 18.1031 31.4389 18.5739C30.7093 19.0399 30.1275 
					19.6466 29.7035 20.3941C29.2746 21.1367 29.0626 22.0492 29.0626 23.1316C29.0626 24.2577 29.25 
					25.2091 29.6296 25.976C30.0092 26.7429 30.5318 27.369 31.2023 27.8495C31.8728 28.33 32.6813 28.6795
					 33.6328 28.8931C34.5843 29.1115 35.6295 29.2183 36.7783 29.2183C38.5087 29.2183 40.0617 29.1164 
					 41.4421 28.9125C42.8176 28.7086 43.848 28.5339 44.5333 28.3883V16.977C44.5333 15.8752 44.4051 
					 14.8608 44.1488 13.9337C43.8924 13.0115 43.4635 12.2252 42.8669 11.5796ZM40.254 25.5488C39.549 
					 25.6944 38.5087 25.7672 37.148 25.7672C36.0289 25.7672 35.1365 25.5682 34.4808 25.1751C33.8202 
					 24.7771 33.4948 24.0636 33.4948 23.0346C33.4948 22.5055 33.618 22.0687 33.8596 21.7192C34.1012 
					 21.3697 34.4266 21.1028 34.8309 20.9086C35.2351 20.7144 35.6838 20.5834 36.1817 20.5106C36.6796 
					 20.4378 37.1727 20.4038 37.6607 20.4038C38.2228 20.4038 38.7355 20.4378 39.1989 20.5106C39.6624 
					 20.5834 40.0173 20.6562 40.2589 20.729V25.5488H40.254ZM62.2029 23.2772C61.4485 22.3162 60.6696 
					 21.4134 59.866 20.5591C59.0624 19.7097 58.2982 18.9816 57.5685 18.3798C58.9293 17.011 60.285 
					 15.6083 61.6359 14.1667C62.9867 12.7251 64.2686 11.3369 65.4863 9.99243H60.2555C59.866 10.4487 
					 59.3976 10.9826 58.8455 11.5942C58.2982 12.2058 57.7164 12.8416 57.11 13.5017C56.4987 14.1618 
					 55.8775 14.8219 55.2465 15.4821C54.6105 16.1422 54.0139 16.7586 53.4568 17.3362V0.886719L49.0296 
					 1.60508V28.8203H53.4519V20.1805C54.1125 20.6611 54.7781 21.2435 55.4634 21.9279C56.1437 22.6123 
					 56.8142 23.3403 57.4749 24.1072C58.1355 24.8741 58.7469 25.6653 59.3237 26.4856C59.8956 27.301 
					 60.4034 28.0825 60.8422 28.8251H65.9596C65.5208 28.0097 64.9736 27.1215 64.3179 26.1604C63.6622 
					 25.1994 62.9572 24.2383 62.2029 23.2772ZM80.7105 11.5796C80.114 10.9292 79.3153 10.4293 78.3145 
					 10.0652C77.3137 9.70605 76.0861 9.52646 74.6218 9.52646C73.4041 9.52646 72.2554 9.62354 71.1855 
					 9.81284C70.1108 10.007 69.2973 10.1963 68.7353 10.3904L69.2825 13.9191C69.8199 13.725 70.515 
					 13.56 71.3679 13.4143C72.2209 13.2687 73.1576 13.1959 74.183 13.1959C74.9373 13.1959 75.5684 
					 13.293 76.0663 13.4823C76.5643 13.6765 76.9686 13.9434 77.2742 14.2929C77.5799 14.6424 77.7919 
					 15.0452 77.9151 15.4966C78.0384 15.9529 78.0976 16.4334 78.0976 16.9382V17.6566C77.4862 17.5109 
					 76.9143 17.409 76.3769 17.3508C75.8396 17.2925 75.3268 17.2586 74.8387 17.2586C73.7886 17.2586 
					 72.7829 17.3605 71.8215 17.5643C70.8601 17.7682 70.0122 18.1031 69.2825 18.5739C68.5529 19.0399 
					 67.9711 19.6466 67.5471 20.3941C67.1182 21.1367 66.9062 22.0492 66.9062 23.1316C66.9062 24.2577 
					 67.0935 25.2091 67.4732 25.976C67.8528 26.7429 68.3754 27.369 69.0459 27.8495C69.7164 28.33 
					 70.5249 28.6795 71.4764 28.8931C72.4279 29.1115 73.4731 29.2183 74.6218 29.2183C76.3523 29.2183 
					 77.9053 29.1164 79.2857 28.9125C80.6612 28.7086 81.6916 28.5339 82.3769 28.3883V16.977C82.3769 
					 15.8752 82.2487 14.8608 81.9923 13.9337C81.736 13.0115 81.312 12.2252 80.7105 11.5796ZM78.0976 
					 25.5488C77.3926 25.6944 76.3523 25.7672 74.9916 25.7672C73.8724 25.7672 72.9801 25.5682 72.3244 
					 25.1751C71.6638 24.7771 71.3384 24.0636 71.3384 23.0346C71.3384 22.5055 71.4616 22.0687 71.7032 
					 21.7192C71.9448 21.3697 72.2702 21.1028 72.6744 20.9086C73.0787 20.7144 73.5273 20.5834 74.0253 
					 20.5106C74.5232 20.4378 75.0162 20.4038 75.5043 20.4038C76.0663 20.4038 76.5791 20.4378 77.0376 
					 20.5106C77.501 20.5834 77.856 20.6562 78.0976 20.729V25.5488ZM98.6118 10.6428C98.099 10.3807 
					 97.4926 10.138 96.7827 9.92447C96.0777 9.70605 95.2593 9.59927 94.3324 9.59927C92.9668 9.59927 
					 91.7589 9.83225 90.7137 10.3031C89.6636 10.769 88.7762 11.4389 88.0465 12.3028C87.3169 13.1668 
					 86.7598 14.2055 86.3851 15.419C86.0055 16.6324 85.8181 17.9818 85.8181 19.467C85.8181 20.9814 
					 86.0449 22.3405 86.4935 23.5539C86.9422 24.7674 87.593 25.7964 88.4311 26.6506C89.2741 27.5049 
					 90.2897 28.1553 91.4828 28.6116C92.6759 29.0678 94.0317 29.2959 95.5403 29.2959C97.0045 29.2959 
					 98.3998 29.1892 99.726 28.9707C101.052 28.7523 102.157 28.5145 103.034 28.2524V0.886719L98.6118 
					 1.60508V10.6428V10.6428ZM98.6118 25.2236C98.2962 25.2964 97.887 25.3644 97.3891 25.4226C96.8911 
					 25.4809 96.2847 25.5148 95.5797 25.5148C93.9479 25.5148 92.666 24.9712 91.7392 23.8791C90.8123 
					 22.787 90.3489 21.2823 90.3489 19.3602C90.3489 17.5109 90.7186 16.05 91.4631 14.9676C92.2075 
					 13.8852 93.3809 13.3464 94.9931 13.3464C95.7227 13.3464 96.4129 13.4629 97.0588 13.6862C97.7046 
					 13.9143 98.2223 14.1618 98.6118 14.4239V25.2236ZM122.488 12.2252C121.685 11.3612 120.713 10.6962 
					 119.58 10.2254C118.446 9.75945 117.208 9.52161 115.867 9.52161C114.526 9.52161 113.294 9.75459 
					 112.175 10.2254C111.055 10.6914 110.084 11.3612 109.266 12.2252C108.447 13.0891 107.816 14.1279 
					 107.363 15.3413C106.909 16.5548 106.687 17.9041 106.687 19.3894C106.687 20.8795 106.914 22.2337 
					 107.363 23.4568C107.811 24.68 108.442 25.7236 109.246 26.5875C110.05 27.4515 111.011 28.1213 
					 112.135 28.6019C113.254 29.0824 114.502 29.3202 115.867 29.3202C117.233 29.3202 118.48 29.0824 
					 119.614 28.6019C120.748 28.1213 121.714 27.4515 122.523 26.5875C123.326 25.7236 123.948 24.68 
					 124.386 23.4568C124.825 22.2337 125.047 20.8795 125.047 19.3894C125.047 17.8993 124.82 16.5499 
					 124.372 15.3413C123.918 14.1279 123.292 13.0891 122.488 12.2252ZM119.289 23.8888C118.47 24.9906 
					 117.331 25.544 115.872 25.544C114.408 25.544 113.269 24.9906 112.456 23.8888C111.637 22.787 
					 111.233 21.2872 111.233 19.3894C111.233 17.5158 111.642 16.0354 112.456 14.9433C113.274 13.8512 
					 114.413 13.3076 115.872 13.3076C117.336 13.3076 118.475 13.856 119.289 14.9433C120.107 
					 16.0354 120.511 17.5158 120.511 19.3894C120.516 21.2872 120.107 22.787 119.289 23.8888ZM145.325 
					 15.3413C144.871 14.1279 144.245 13.0891 143.441 12.2252C142.638 11.3612 141.666 10.6962 140.533 
					 10.2254C139.399 9.75459 138.161 9.52161 136.82 9.52161C135.479 9.52161 134.247 9.75459 133.127 
					 10.2254C132.008 10.6914 131.037 11.3612 130.219 12.2252C129.4 13.0891 128.769 14.1279 128.316 
					 15.3413C127.862 16.5548 127.64 17.9041 127.64 19.3894C127.64 20.8795 127.867 22.2337 128.316 
					 23.4568C128.764 24.68 129.395 25.7236 130.199 26.5875C131.003 27.4515 131.964 28.1213 133.088 
					 28.6019C134.207 29.0824 135.455 29.3202 136.82 29.3202C138.186 29.3202 139.433 29.0824 140.567 
					 28.6019C141.701 28.1213 142.667 27.4515 143.476 26.5875C144.279 25.7236 144.901 24.68 145.339 
					 23.4568C145.778 22.2337 146 20.8795 146 19.3894C146 17.9041 145.773 16.5548 145.325 
					 15.3413ZM140.242 23.8888C139.423 24.9906 138.284 25.544 136.825 25.544C135.366 25.544 134.222 
					 24.9906 133.409 23.8888C132.59 22.787 132.186 21.2872 132.186 19.3894C132.186 17.5158 132.595 
					 16.0354 133.409 14.9433C134.227 13.8512 135.366 13.3076 136.825 13.3076C138.284 13.3076 139.428 
					 13.856 140.242 14.9433C141.06 16.0354 141.464 17.5158 141.464 19.3894C141.464 21.2872 141.055 
					 22.787 140.242 23.8888Z" 
						fill="black"
					/>
				</svg>
			</div>
			<div className={css.wrapper}>
				<div>
					<Button
						cssClass={css.enter}
						buttonType={buttonsTypes.LIGHT}
						text="Вход"
						onClick={() => { navigate(AUTHORIZATION_LINK); }}
					/>
					<Button
						cssClass={css.reg}
						buttonType={buttonsTypes.LIGHT}
						text="Регистрация"
						onClick={() => { navigate(REGISTRATION_LINK); }}
					/>
				</div>
			</div>
		</header>
	);
};

export default UnRegisteredHeader;
