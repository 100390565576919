import React from 'react';
import { NavLink } from 'react-router-dom';
import css from './registration.module.scss';

interface AgreeWithPolicyProps {
	agreeWithPolicy: boolean,
	setAgreeWithPolicy: (value: boolean) => void,
}

const AgreeWithPolicy: React.FC<AgreeWithPolicyProps> = ({
	agreeWithPolicy,
	setAgreeWithPolicy,
}: AgreeWithPolicyProps) => {
	const onSetAgreeWithPolicy = () => {
		setAgreeWithPolicy(!agreeWithPolicy);
	};
	
	return (
		<div className={css.agreementBlock}>
			<div
				className={css.acceptedWrapper}
				onClick={onSetAgreeWithPolicy}
				role="presentation"
			>
				{
					agreeWithPolicy && (
						<svg
							width="13"
							height="13"
							viewBox="0 0 13 9"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M12 1L4.66667 8L1 4.5" stroke="#0075FF" />
						</svg>
					)
				}
			</div>
			<div className={css.agreementText}>
				Согласен с&nbsp;
				<NavLink
					to="/privacy"
					className={css.agreementLink}
				>
					политикой обработки персональных данных
				</NavLink>
			</div>
		</div>
	);
};

export default AgreeWithPolicy;
