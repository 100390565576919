import type { IParserOptions, Token } from '../parser';
import { ShortcutHandler } from './shortcuts';
import Editor from './index';

export type TextRange = [from: number, to: number];

export interface IBaseEditorOptions {
    /** Параметры для парсера текста */
    parse: Partial<IParserOptions>;

    /** Сбрасывать форматирование при вставке новой строки */
    resetFormatOnNewline: boolean;

    /** Заменять все пробельные символы на неразрывные пробелы */
    nowrap: boolean;
}

export interface IEditorOptions extends IBaseEditorOptions {
    /** Значение по умолчанию для редактора */
    value?: string;
    shortcuts?: Record<string, ShortcutHandler<Editor>>;

    /** Парсить HTML при вставке */
    html?: boolean;

    /** Размечать ссылки при вставке HTML */
    htmlLinks?: boolean;

    scroller?: HTMLElement;
}

export type EventName = 'editor-selectionchange'
    | 'editor-formatChange'
    | 'editor-fontFamilyChange'
    | 'editor-fontSizeChange'
    | 'editor-colorChange'
    | 'editor-textAlignChange'
    | 'editor-lineHeightChange'
    | 'editor-update';

export interface IEditorEventDetails {
    editor: Editor;
}

export interface IEditorEvent<T = IEditorEventDetails> extends CustomEvent<T> {}

/**
 * Перечисление с названиями событий
 */
export const enum DiffActionType {
    Insert = 'insert',
    Remove = 'remove',
    Replace = 'replace',
    Compose = 'compose'
}

export const enum DirtyState {
    None, Dirty, DirtyRetainNewline
}

export interface IPickLinkOptions {
    /**
     * Функция, которая на вход принимает текущую ссылку, если она есть, и должна
     * вернуть новую ссылку или Promise, который вернёт ссылку
     */
    url: (currentUrl: string) => string | Promise<string>,
    // url: (currentUrl: string) => string | null,

    /**
     * Диапазон, для которого нужно выставить ссылку. Если не указан,
     * берётся текущий диапазон
     */
    range?: TextRange;
}

export enum FontSize {
    Pt8 = '8pt',
    Pt9 = '9pt',
    Pt10 = '10pt',
    Pt11 = '11pt',
    Pt12 = '12pt',
    Pt14 = '14pt',
    Pt16 = '16pt',
    Pt18 = '18pt',
    Pt20 = '20pt',
    Pt22 = '22pt',
    Pt24 = '24pt',
    Pt26 = '26pt',
    Pt28 = '28pt',
    Pt36 = '36pt',
    Pt48 = '48pt',
    Pt56 = '56pt',
    Pt64 = '64pt',
    Pt72 = '72pt',
}

export const enum TextAlign {
    LEFT = 'start',
    CENTER = 'center',
    RIGHT = 'end',
    JUSTIFY = 'stretch',
}

export enum FontFamily {
    Default = 'Inter', // По договоренности Inter - дефолтный шрифт
    Merriweather = 'Merriweather',
    FiraSans = 'Fira Sans',
    Roboto = 'Roboto',
    Jost = 'Jost',
    Arial = 'Arial',
    Rubik = 'Rubik',
    Ubuntu = 'Ubuntu',
    Oswald = 'Oswald',
    RussoOne = 'Russo One',
    KosugiMaru = 'Kosugi Maru',
    Unbounded = 'Unbounded',
    DelaGothicOne = 'Dela Gothic One',
    MartinMono = 'Martin Mono',
    KiwiMaru = 'Kiwi Maru',
    ProstoOne = 'Prosto One',
    Tektur = 'Tektur',
    SeymourOne = 'Seymour One',
}

export type Model = {
    tokens: Token[],
    lineHeight: number,
    id: string,
};
