import css from './disable.module.scss';
import { useDisableTariff } from '../api/useDisableTariff';

interface ReactivateTariffButtonProps {
	transactionId: number,
}

export const DisableTariffButton = ({ transactionId }: ReactivateTariffButtonProps) => {
	const {
		isLoading,
		disableTariff,
	} = useDisableTariff(transactionId);

	return (
		<button type="submit" disabled={isLoading} className={css.wrapper} onClick={disableTariff}>
			Отключить
		</button>
	);
};
