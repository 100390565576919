import React, { useMemo } from 'react';
import CreateInputArrow from '../../../../../components/pages/features/moduleWindow/createInputArrow/CreateInputArrow';
import { legalEntityAPI } from '../../../../../entities/legal-entity/api/api';

interface SelectModuleEntitiesProps {
	selectedLegalEntityId: string[],
	selectLegalEntity: (id: string) => void,
	unselectLegalEntity: (id: string) => void,
}

export const SelectModuleEntities = ({
	selectLegalEntity,
	unselectLegalEntity,
	selectedLegalEntityId,
}: SelectModuleEntitiesProps) => {
	const { data: legalEntityData } = legalEntityAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	const selectData = useMemo(
		() => legalEntityData?.legalEntities?.map(tag => ({ ...tag, id: tag.id.toString() })),
		[legalEntityData],
	);

	return (
		<CreateInputArrow
			text="Юридические лица"
			inputData={selectData}
			choicedItemsId={selectedLegalEntityId}
			onAddChoicedItem={selectLegalEntity}
			onRemoveChoicedItem={unselectLegalEntity}
		/>
	);
};
