import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import EditTagWindow from './EditTagWindow';
import { setIsEditTagGroupModalActive } from '../../../../../../redux/reducers/module/tagGroupReducer';
import { tagsGroupModalsActive } from '../../../../../../redux/selectors/module/tagGroup';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { useAppSelector } from '../../../../../../shared/libs/redux';

const EditingTagGroupWrapper = () => {
	const dispatch = useDispatch();

	const editingTagGroupId = useAppSelector(state => state.tagGroup.editingTagGroupId);

	const isEditTagGroupModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isEditTagGroupModalActive);

	const [update] = tagGroupAPI.useUpdateMutation();

	const handleEditingTagGroupModal = (value: boolean) => {
		dispatch(setIsEditTagGroupModalActive(value));
	};

	const onEditTag = async (
		inputValue: string,
		setInputValue: (value: string) => void,
	) => {
		if (editingTagGroupId) {
			try {
				await update({
					id: editingTagGroupId,
					name: inputValue,
				})
					.unwrap();
				setInputValue('');
				handleEditingTagGroupModal(false);
				notificationSuccess('Группа тегов изменена успешно!');
			} catch (e) {
				notificationError('Возникла ошибка при изменении группы тегов!');
			}
		}
	};

	return (
		<EditTagWindow
			maxLength={20}
			onEditTag={onEditTag}
			inputTitle="Изменение группы тегов"
			isActive={isEditTagGroupModalActive}
			setActive={handleEditingTagGroupModal}
		/>
	);
};

export default EditingTagGroupWrapper;
