import React from 'react';
import { useAppSelector } from '../../../../../../../../shared/libs/redux';
import { DeleteModalWrapper } from '../../../../../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useDeleteTemplates } from '../../api/useDeleteTemplates';

interface DeletePrivateTemplateModalProps {
	choicedTemplateData: string[],
}

export const DeletePrivateTemplateModal = ({
	choicedTemplateData,
}: DeletePrivateTemplateModalProps) => {
	const isActive = useAppSelector(state => state.privateTemplate.isConfirmPrivateDeleteModalActive);

	const {
		isLoading,
		setActive,
		deleteTemplate,
	} = useDeleteTemplates();

	return (
		<DeleteModalWrapper
			onDelete={deleteTemplate}
			isActive={isActive}
			isLoading={isLoading}
			setIsActive={setActive}
			description={`Вы действительно хотите удалить ${
				choicedTemplateData?.length === 1 ? 'шаблон' : 'шаблоны'
			} без возможности восстановления?`}
		/>
	);
};
