class KeyScanner {
	public isCtrlShiftG = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.shiftKey && ev.code === 'KeyG';
	public isShiftT = (ev: KeyboardEvent): boolean => ev.shiftKey && ev.code === 'KeyT';
	public isShiftK = (ev: KeyboardEvent): boolean => ev.shiftKey && ev.code === 'KeyK';
	public isShiftF = (ev: KeyboardEvent): boolean => ev.shiftKey && ev.code === 'KeyF';
	public isShiftL = (ev: KeyboardEvent): boolean => ev.shiftKey && ev.code === 'KeyL';
	public isCtrlG = (ev: KeyboardEvent): boolean => ev.ctrlKey && !ev.shiftKey && ev.code === 'KeyG';
	public isDelete = (ev: KeyboardEvent): boolean => ev.code === 'Delete' || ev.code === 'Backspace';
	// public isDelete = (ev: KeyboardEvent): boolean => ev.code === 'Delete';
	public isEscape = (ev: KeyboardEvent): boolean => ev.code === 'Escape';
	public isCtrlZ = (ev: KeyboardEvent): boolean => ev.ctrlKey && !ev.shiftKey && ev.code === 'KeyZ';
	public isCtrlShiftZ = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.shiftKey && ev.code === 'KeyZ';
	public isCtrlX = (ev: KeyboardEvent): boolean => ev.ctrlKey && !ev.shiftKey && ev.code === 'KeyX';
	public isCtrlC = (ev: KeyboardEvent): boolean => ev.ctrlKey && !ev.shiftKey && ev.code === 'KeyC';
	public isCtrlV = (ev: KeyboardEvent): boolean => ev.ctrlKey && !ev.shiftKey && ev.code === 'KeyV';
	public isCtrl = (ev: KeyboardEvent): boolean => ev.code === 'ControlLeft' || ev.code === 'ControlRight';
	public isShift = (ev: KeyboardEvent): boolean => ev.code === 'ShiftRight' || ev.code === 'ShiftLeft';
	public isArrowUp = (ev: KeyboardEvent): boolean => ev.code === 'ArrowUp';
	public isArrowDown = (ev: KeyboardEvent): boolean => ev.code === 'ArrowDown';
	public isArrowLeft = (ev: KeyboardEvent): boolean => ev.code === 'ArrowLeft';
	public isArrowRight = (ev: KeyboardEvent): boolean => ev.code === 'ArrowRight';
	public isAlt = (ev: KeyboardEvent): boolean => (ev.altKey || ev.metaKey);
	// AI
	public isCtrlAltM = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.altKey && ev.code === 'KeyM';

	public isCtrlArrowUp = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.code === 'ArrowUp';
	public isCtrlArrowDown = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.code === 'ArrowDown';
	public isCtrlArrowLeft = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.code === 'ArrowLeft';
	public isCtrlArrowRight = (ev: KeyboardEvent): boolean => ev.ctrlKey && ev.code === 'ArrowRight';
}
export default KeyScanner;
