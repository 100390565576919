import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import classNames from 'classnames';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getIsTeamOpen, getTeamResponses, setIsAiCategoriesModalOpen,
	setTeamOpen, setTeamResponses,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtnIcon } from '../../../../../shared/images/ai.svg';
import { symbolsCountSelectOptions } from '../../../model/symbolsCountSelectOptions';
import { AiSelect, IOption } from '../../ai-select/AiSelect';
import { getSocket } from '../../../../websocket/model/slice';
import { messageQueue } from '../../../../websocket/model/webSocketMiddleware';

type FormData = {
	KeywordName: string,
	gender: string,
	symbolsCount: string,
};

export const TeamModal: FC = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState,
		formState: { errors, isSubmitSuccessful },
		reset,
	} = useForm<FormData>();

	// States
	const [symbolsCount, setSymbolsCount] = useState<IOption>({
		title: 'По умолчанию',
		value: '3000',
	});
	const [gender, setGender] = useState<IOption>({
		title: 'Муж',
		value: 'Мужчина',
	});
	const [isLoading, setIsLoading] = useState(false);

	const active = useAppSelector(getIsTeamOpen);
	const socket = useAppSelector(getSocket);
	const responses = useAppSelector(getTeamResponses);

	// Обнулить значения полей
	const onResetForm = () => {
		reset({ KeywordName: '' });
		setGender({
			title: 'По умолчанию',
			value: 'Нейтральный',
		});
		setSymbolsCount({
			title: 'По умолчанию',
			value: '3000',
		});
	};

	const onClose = () => {
		dispatch(setTeamOpen(false));
		onResetForm();
	};
	const onGoBack = () => {
		dispatch(setTeamOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
		onResetForm();
	};

	const onSubmit: SubmitHandler<FormData> = (data) => {
		setIsLoading(true);
		const wsData = JSON.stringify({
			type: 'ai.generator.text.team_member',
			body: JSON.stringify({
				...data,
				numCharacters: symbolsCount.value,

			}),
			id: uuidv4(),
		});

		if	(socket?.readyState === WebSocket.OPEN) {
			socket.send(wsData);
		} else {
			messageQueue.enqueue(wsData);
		}
	};

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const message = JSON.parse(event.data);
			if (message.type === 'ai.generator.text.team_member' && message.status === 'ok') {
				setIsLoading(false);
				dispatch(setTeamResponses(message.body));
				// onResetForm();
			} else if (message.type === 'ai.generator.text.team_member' && message.status === 'error') {
				setIsLoading(false);
			}
		};

		if (socket) {
			socket.addEventListener('message', handleMessage);
		}

		return () => {
			if (socket) {
				socket.removeEventListener('message', handleMessage);
			}
		};
	}, [formState, reset, responses]);

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Описание члена команды"
		>
			<form className="ai-form" onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="KeywordName" className={css.keyWordsLabel}>
					Ключевые слова
					<textarea
						id="KeywordName"
						{...register('KeywordName', { required: true })}
						placeholder="Старший менеджер Иванов"
					/>
					{errors.KeywordName && (
						<span className="error" role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiSelect
						title="Пол"
						options={[
							{ title: 'муж', value: 'муж' },
							{ title: 'жен', value: 'жен' },
						]}
						onSelect={setGender}
						option={gender}
					/>

					<AiSelect
						title="Количество символов"
						options={symbolsCountSelectOptions}
						onSelect={setSymbolsCount}
						option={symbolsCount}
					/>
				</div>

				<button className="aiSubmit" type="submit" disabled={isLoading}>
					<span className="aiSubmit_btn">Написать</span>
					<AiBtnIcon
						className={classNames('aiSubmit_icon', {
							'ai-modal-disabled': isLoading === true,
						})}
					/>
				</button>
			</form>

			{(isLoading && responses.length == 0) && (
				<Loader
					type="roller"
					color="rgba(57, 150, 218, 1)"
					size={100}
					className="alt-loader"
				/>
			)}

			{responses.length >= 1 && (
				<div className="responsesWrapper">
					{isLoading
						&& (
							<Loader
								type="roller"
								color="rgba(57, 150, 218, 1)"
								size={100}
								className="ai-modal-loader"
							/>
						)}
					<div>
						{responses.map((data) => (
							<div key={uuid()} className="ai-response">
								{data}
							</div>
						))}
					</div>
				</div>
			)}

		</ModalWrapper>
	);
};
