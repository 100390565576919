import { ReactComponent as VkIcon } from '../../images/vk.svg';
import { ReactComponent as YoutubeIcon } from '../../images/youtube.svg';
import { ReactComponent as TenchatIcon } from '../../images/tenchat.svg';
import css from './social-networks.module.scss';

const toVk = () => window.open('https://vk.com/wakadoo', '_blank');
const toYoutube = () => window.open('https://www.youtube.com/@WakadooServis', '_blank');
const toTenchat = () => window.open('https://tenchat.ru/wakadoo', '_blank');

export const SocialNetworks = () => (
	<div className={css.social_networks}>
		<VkIcon onClick={toVk} />
		<YoutubeIcon onClick={toYoutube} />
		<TenchatIcon onClick={toTenchat} />
	</div>
);
