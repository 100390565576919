import React from 'react';
import cx from 'classnames';
import { ReactComponent as LeftArrowIcon } from './images/left-arrow.svg';
import css from './go-back.module.scss';

interface GoBackButtonProps {
	onClick: () => void,
	styles?: string,
	children?: React.ReactNode,
}

export const GoBackButton = ({
	onClick,
	styles,
	children = 'Вернуться назад',
}: GoBackButtonProps) => (
	<button
		className={cx(css.wrapper, styles)}
		onClick={onClick}
		type="button"
	>
		<LeftArrowIcon />
		<span className={css.text}>{children}</span>
	</button>
);
