import React, { useEffect, useRef, useState } from 'react';
import PinInputBlock from './PinInputBlock';

interface PinInputBlockProps {
	digits: Array<string>,
	changeHandler: (value: Array<string>) => void,
	handleKeyPressSendCode: (e: React.KeyboardEvent<HTMLInputElement>) => void,
}

const PinInputBlockContainer: React.FC<PinInputBlockProps> = ({
	digits,
	changeHandler,
	handleKeyPressSendCode,
}: PinInputBlockProps) => {
	// здесь будут ссылки на input-элементы
	const inputRefs = useRef<HTMLInputElement[]>([]);

	const [nextNumber, setNextNumber] = useState(0);
	const [prevNumber, setPrevNumber] = useState(0);

	const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace') {
			e.preventDefault();
			if (digits[index].match(/^\d$/)) {
				// если элемент массива digits содержит цифру, то при нажатии клавиши
				// вызываем callback-функцию родителя, чтобы записать пустую строку
				const newDigits = [...digits]; // копия digits
				newDigits[index] = '';
				changeHandler(newDigits);
				inputRefs.current[index].focus();
				// элемент массива digits пустой, удалять нечего — так что надо сместить
				// фокус на предыдущее поле input — при условии, что это не первое поле
			}
			if (index > 0) {
				setPrevNumber(index - 1);
			}
		}
		handleKeyPressSendCode(e);
	};

	const handleChange = (index: number, newValue: string) => {
		const oldDigit = digits[index];
		// старую цифру в поле ввода убираем, оставляя только новую
		const newDigit = newValue.trim().replace(oldDigit, '');
		// если это не цифра, ничего не делаем, пока не будет цифры
		if (newDigit < '0' || newDigit > '9') return;
		// теперь вызываем callback родителя, чтобы обовить digits
		const newDigits = [...digits]; // копия digits
		newDigits[index] = newDigit;
		changeHandler(newDigits);
		// смещаем фокус на следующее поле для ввода следующей цифры
		if (index < digits.length - 1) {
			setNextNumber(index + 1);
		}
	};

	useEffect(() => {
		inputRefs.current[prevNumber].focus();
	}, [prevNumber]);

	useEffect(() => {
		if (nextNumber < digits.length) {
			inputRefs.current[nextNumber].focus();
		} else {
			inputRefs.current[nextNumber].blur();
		}
	}, [nextNumber]);

	// поля подсвечиваем зеленым
	useEffect(() => {
		let allValid = true;
		digits.forEach(value => {
			if (!value.match(/^\d$/)) {
				allValid = false;
			}
		});
		if (allValid) {
			digits.forEach((_, index) => {
				inputRefs.current[index].style.borderColor = '#249C5C';
			});
		}
	}, [digits]);

	useEffect(() => {
		inputRefs.current[0].focus();
	}, []);
	
	return (
		<PinInputBlock 
			digits={digits} 
			inputRefs={inputRefs}
			handleChange={handleChange}
			handleKeyDown={handleKeyDown}
		/>
	);
};

export default PinInputBlockContainer;
