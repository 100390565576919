import EmojiPicker, { Categories } from 'emoji-picker-react';
import React, { useEffect, useState } from 'react';
import Button, { buttonsTypes } from '../../../../ui/button/button';
import css from './createModalBody.module.scss';
import useCatchEnterClick from '../../../../../hooks/useCatchEnterClick';
import { setChoicedEmoji, setCreateRoleBgColor } from '../../../../../redux/reducers/usersReducer';
import CreateRoleInput from './createRoleInput/CreateRoleInput';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';

const categories = [
	{
		category: Categories.ANIMALS_NATURE,
		name: 'Природа',
	},
	{
		category: Categories.ACTIVITIES,
		name: 'Активности',
	},
	{
		category: Categories.FLAGS,
		name: 'Флаги',
	},
	{
		category: Categories.FOOD_DRINK,
		name: 'Еда/напитки',
	},
	{
		category: Categories.OBJECTS,
		name: 'Объекты',
	},
	{
		category: Categories.SMILEYS_PEOPLE,
		name: 'Смайлы',
	},
	{
		category: Categories.SUGGESTED,
		name: 'Недавние',
	},
	{
		category: Categories.SYMBOLS,
		name: 'Символы',
	},
	{
		category: Categories.TRAVEL_PLACES,
		name: 'Разное',
	},
];

interface CreateRoleProps {
	isActive: boolean,
	onCreating: () => void,
	createRoleName: string,
	isLoading: boolean,
	setCreateRoleName: (value: string) => void,
}

const CreateRole = ({
	onCreating,
	isLoading,
	isActive,
	createRoleName,
	setCreateRoleName,
}: CreateRoleProps) => {
	const dispatch = useAppDispatch();

	const choicedEmoji = useAppSelector(state => state.user.choicedEmoji);

	const handleChoicedEmoji = (emoji: string | null) => {
		dispatch(setChoicedEmoji(emoji));
	};

	const handleCreateRoleBgColor = (color: string) => {
		dispatch(setCreateRoleBgColor(color));
	};

	const [isChoiceEmojiWindowShow, setIsChoiceEmojiWindowShow] = useState(false);

	const isInputValid = (createRoleName.length > 0 && !!choicedEmoji) || false;
	useCatchEnterClick(onCreating, isInputValid);

	// * При закрытии модалки закрывает окно с эмоджи
	useEffect(() => {
		if (!isActive) {
			setIsChoiceEmojiWindowShow(false);
		}
	}, [isActive]);

	useEffect(() => {
		if (!isActive) {
			setCreateRoleName('');
			handleCreateRoleBgColor('#ffffff');
			handleChoicedEmoji(null);
			setIsChoiceEmojiWindowShow(false);
		}
	}, [isActive]);

	// * Закрытие/открытие окна с выбором эмоджи 
	const showEmojiWindow = () => {
		setIsChoiceEmojiWindowShow(!isChoiceEmojiWindowShow);
	};

	// * При добавлении эмоджи
	const onEmojiAdd = (unified: string, e: MouseEvent) => {
		e.stopPropagation();
		handleChoicedEmoji(unified);
		setIsChoiceEmojiWindowShow(!isChoiceEmojiWindowShow);
	};

	return (
		<div className={css.wrapper}>
			<div className={css.title}>
				Создать роль
			</div>
			<CreateRoleInput
				createRoleName={createRoleName}
				showEmojiWindow={showEmojiWindow}
				setCreateRoleName={setCreateRoleName}
			/>
			<Button
				onClick={onCreating}
				text="Создать роль"
				buttonType={buttonsTypes.BRIGHT}
				cssClass={css.roleCreateBtn}
				inActive={isInputValid}
				inProcess={isLoading}
			/>
			<div id="emojiPicker" className={css.createWrapper}>
				{
					isChoiceEmojiWindowShow && (
						<EmojiPicker
							onEmojiClick={(emoji, event) => onEmojiAdd(emoji.unified, event)}
							lazyLoadEmojis
							categories={categories}
							searchDisabled
						/>
					)
				}
			</div>
		</div>
	);
};

export default CreateRole;
