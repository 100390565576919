import { useParams } from 'react-router-dom';
import OfferSettingsContainer from '../../../offerSettings/OfferSettingsContainer';
import { customTemplateAPI } from '../../../../../../../../entities/templates/custom/api/api';

export const CustomTemplateOfferSettings = () => {
	const { id } = useParams();
	const { isLoading, data: template } = customTemplateAPI.useGetQuery({ id: id || '' }, { skip: !id });

	if	(isLoading) return <div />;

	return (
		<OfferSettingsContainer template={template} />
	);
};
