import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../../shared/api/api';
import {
	URI_WORKSHOP_INTEGRATE_TEMPLATE,
	URI_WORKSHOP_TEMPLATE,
	URI_WORKSHOP_TEMPLATE_DUPLICATE,
	URI_WORKSHOP_TEMPLATE_DUPLICATE_GALLERY,
	URI_WORKSHOP_TEMPLATE_GALLERY,
	URI_WORKSHOP_TEMPLATE_TAG,
} from './consts';
import { IGetPrivateTemplateResponse } from '../../../private/api/types';
import {
	IBindTagToWorkshopTemplateRequest,
	ICreateWorkshopTemplateRequest,
	IDuplicateWorkshopTemplateRequest, IGetLazyWorkshopTemplateListResponse,
	IGetWorkshopTemplateListRequest,
	IGetWorkshopTemplateListResponse,
	IIntegrateWorkshopTemplateRequest,
	IUpdateWorkshopTemplateRequest,
} from './types';
import { IWorkshopTemplateData } from '../model/types';

export const workshopTemplateAPI = createApi({
	reducerPath: 'workshopTemplateAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['templates'],
	endpoints: builder => ({
		create: builder.mutation<IGetPrivateTemplateResponse, ICreateWorkshopTemplateRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['templates'],
		}),
		bindTag: builder.mutation<void, IBindTagToWorkshopTemplateRequest>({
			query: ({
				tag,
				template,
			}) => ({
				url: URI_WORKSHOP_TEMPLATE_TAG(template, tag),
				method: 'POST',
			}),
			invalidatesTags: ['templates'],
		}),
		publishToGallery: builder.mutation<void, { template: string, tariffPermission: number }>({
			query: ({
				template,
				...data
			}) => ({
				url: URI_WORKSHOP_TEMPLATE_DUPLICATE_GALLERY(template),
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['templates'],
		}),
		integrate: builder.mutation<void, IIntegrateWorkshopTemplateRequest>({
			query: ({
				template,
				email,
			}) => ({
				url: URI_WORKSHOP_INTEGRATE_TEMPLATE(template, email),
				method: 'POST',
			}),
			invalidatesTags: ['templates'],
		}),
		update: builder.mutation<void, IUpdateWorkshopTemplateRequest>({
			query: ({
				template,
				...data
			}) => ({
				url: `${URI_WORKSHOP_TEMPLATE}/${template}`,
				method: 'PUT',
				body: { ...data },
			}),
			invalidatesTags: ['templates'],
		}),
		getAll: builder.query<IGetWorkshopTemplateListResponse, IGetWorkshopTemplateListRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['templates'],
		}),
		// getLazy: builder.query<IGetLazyWorkshopTemplateListResponse, IGetWorkshopTemplateListRequest>({
		// 	query: (data) => ({
		// 		url: URI_WORKSHOP_TEMPLATE,
		// 		method: 'GET',
		// 		params: data,
		// 	}),
		// 	providesTags: ['templates'],
		// }),
		get: builder.query<IWorkshopTemplateData, { template: string }>({
			query: ({ template }) => ({
				url: `${URI_WORKSHOP_TEMPLATE}/${template}`,
				method: 'GET',
			}),
			providesTags: ['templates'],
		}),
		deleteFromGallery: builder.mutation<void, { templates: string[] }>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE_GALLERY,
				method: 'DELETE',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['templates'],
		}),
		unbindTag: builder.mutation<void, IBindTagToWorkshopTemplateRequest>({
			query: ({
				tag,
				template,
			}) => ({
				url: URI_WORKSHOP_TEMPLATE_TAG(template, tag),
				method: 'DELETE',
			}),
			invalidatesTags: ['templates'],
		}),
		duplicate: builder.mutation<void, IDuplicateWorkshopTemplateRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE_DUPLICATE,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['templates'],
		}),
		deleteWorkshopTemplate: builder.mutation<void, { templates: string[] }>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE,
				method: 'DELETE',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['templates'],
		}),
		deleteIntegrationTemplate: builder.mutation<void, IIntegrateWorkshopTemplateRequest>({
			query: ({
				template,
				email,
			}) => ({
				url: URI_WORKSHOP_INTEGRATE_TEMPLATE(template, email),
				method: 'DELETE',
			}),
			invalidatesTags: ['templates'],
		}),
	}),
});
