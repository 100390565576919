import MagneticLines from '../MagneticLines';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IPagesComponentTree from '../../../component-tree/IPagesComponentTree';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import IPageTexture from '../../../graphic/page/IPageTexture';
import MagneticLine from '../magnetic-line/MagneticLine';
import MagneticPagePaddingLine from '../magnetic-line/MagneticPagePaddingLine';
import DirectionVector from '../DirectionVector';
import MagneticPageSideLine from '../magnetic-line/MagneticPageSideLine';
import IGraphic from '../../../graphic/IGraphic';
import MagneticGraphicSideLine from '../magnetic-line/MagneticGraphicSideLine';
import IFrameConfiguration from '../../../frame/IFrameConfiguration';
import IOnStartMutationListenerProps from '../../spatial-quadrants/area-mutators/events/IOnStartMutationListenerProps';
import GraphicType from '../../../graphic/GraphicType';

class MultiPageMagneticLines extends MagneticLines<IPagesComponentTree> {
	constructor(componentTree: IPagesComponentTree) {
		super(componentTree);
	}

	protected createMagneticLines = (): MagneticLine[] => {
		if (this.initiatorGraphic === null) {
			throw new ManipulatorError('initiator graphic not found');
		}

		const initiatorPageNumber = this.componentTree.getGraphicPageNumber(this.initiatorGraphic);
		const page = this.componentTree.getPageFromNumber(initiatorPageNumber);
		if (page === null) {
			throw new ManipulatorError('the initiator page from number not found');
		}
		const pageConfiguration = page.getFrameConfiguration();
		const pageTexture = page.getTexture();
		const pageElement = page.getGraphicElement();

		let graphics = this.componentTree.getPageEmbeddedGraphics(initiatorPageNumber);
		if (graphics !== null) {
			if (this.initiatorGraphic.isUniter) {
				graphics = graphics.filter(graphic => graphic !== this.initiatorGraphic
					&& graphic.getParentGraphic()!.type === GraphicType.PAGE);
			} else {
				graphics = graphics.filter(graphic => graphic !== this.initiatorGraphic
					&& graphic.type !== GraphicType.GROUP);
			}
		}

		return this.generateMagneticLines(pageConfiguration, pageTexture, graphics, pageElement);
	};

	protected calculateStartConfiguration = (): IFrameConfiguration => {
		if (this.initiatorGraphic === null) {
			throw new ManipulatorError('the initiator graphic not found');
		}
		return this.componentTree.getConfigurationFromPage(this.initiatorGraphic);
	};

	protected calculateInitiatorGraphic = (props: IOnStartMutationListenerProps): IGraphic => {
		const graphic = props.initiator.getData()?.graphic;
		if (graphic === undefined) {
			throw new ManipulatorError('graphic not found');
		}
		return graphic;
	};

	/**
	 * Создает магнитные линии на основе текущей страницы и графики.
	 * @param pageConfiguration - конфигурация страницы.
	 * @param pageTexture - текстура страницы.
	 * @param graphics - графика на текущей странице.
	 * @param pageElement - HTML элемент страницы, на которую будет добавлена линия.
	 */
	private generateMagneticLines = (
		pageConfiguration: IFrameArea,
		pageTexture: IPageTexture,
		graphics: IGraphic[] | null,
		pageElement: HTMLElement,
	): MagneticLine[] => [
		...this.generatePagePaddingLines(pageConfiguration, pageTexture, pageElement),
		...this.generatePageSideLines(pageConfiguration, pageElement),
		...(graphics === null ? [] : this.generateGraphicsLines(pageConfiguration, graphics, pageElement)),
	];

	/**
	 * Создает магнитные линии для внутренних отступов страницы.
	 * @param pageConfiguration - конфигурация страницы, для которой создаются магнитные линии.
	 * @param pageTexture - текстура страницы.
	 * @param pageElement - HTML элемент страницы, на которую будет добавлена линия.
	 */
	private generatePagePaddingLines = (
		pageConfiguration: IFrameArea,
		pageTexture: IPageTexture,
		pageElement: HTMLElement,
	): MagneticLine[] => [
		new MagneticPagePaddingLine({
			pageTexture,
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.LEFT,
		}),
		new MagneticPagePaddingLine({
			pageTexture,
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.TOP,
		}),
		new MagneticPagePaddingLine({
			pageTexture,
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.RIGHT,
		}),
		new MagneticPagePaddingLine({
			pageTexture,
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.BOTTOM,
		}),
	];

	/**
	 * Создает магнитные линии для внешних границ страницы.
	 * @param pageConfiguration - конфигурация страницы, для которой создаются магнитные линии.
	 * @param pageElement - HTML элемент страницы, на которую будет добавлена линия.
	 */
	private generatePageSideLines = (
		pageConfiguration: IFrameArea,
		pageElement: HTMLElement,
	): MagneticLine[] => [
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.LEFT,
		}),
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.TOP,
		}),
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.RIGHT,
		}),
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.BOTTOM,
		}),
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.MIDDLE_X,
		}),
		new MagneticPageSideLine({
			pageConfiguration,
			parentElement: pageElement,
			direction: DirectionVector.MIDDLE_Y,
		}),
	];

	/**
	 * Создает магнитные линии для каждой графики страницы.
	 * @param pageConfiguration - конфигурация страницы, на которой расположена графика.
	 * @param graphics - графика, расположенная на странице.
	 * @param pageElement - HTML элемент страницы, на которую будет добавлена линия.
	 */
	private generateGraphicsLines = (
		pageConfiguration: IFrameArea,
		graphics: IGraphic[],
		pageElement: HTMLElement,
	): MagneticLine[] => graphics
		.map(graphic => this.createGraphicLines(pageConfiguration, graphic, pageElement)).flat();

	/**
	 * Создает магнитные линии для графики страницы.
	 * @param pageConfiguration - конфигурация страницы, на которой расположена графика.
	 * @param graphic - графика, расположенная на странице.
	 * @param pageElement - HTML элемент страницы, на которую будет добавлена линия.
	 */
	private createGraphicLines = (
		pageConfiguration: IFrameArea,
		graphic: IGraphic,
		pageElement: HTMLElement,
	): MagneticLine[] => {
		const graphicConfiguration = this.componentTree.getConfigurationFromPage(graphic);
		if (graphicConfiguration === null) {
			throw new ManipulatorError('graphic configuration not found');
		}
		return [
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.LEFT,
			}),
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.TOP,
			}),
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.RIGHT,
			}),
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.BOTTOM,
			}),
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.MIDDLE_X,
			}),
			new MagneticGraphicSideLine({
				graphicConfiguration,
				pageConfiguration,
				parentElement: pageElement,
				direction: DirectionVector.MIDDLE_Y,
			}),
		];
	};
}

export default MultiPageMagneticLines;
