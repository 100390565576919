import React from 'react';
import css from './main.module.scss';
import HeaderContainer from '../../../../Header/HeaderContainer';

export const MainLayout: React.FC = ({ children }) => (
	<div className={css.wrapper}>
		<HeaderContainer />
		{children}
	</div>
);
