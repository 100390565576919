import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { onSetSketchStructure } from '../../../../../../redux/thunk-creators/sketch';
import OfferSettings from './OfferSettings';
import {
	setSketchDeadline,
	setSketchManagerId,
	setSketchName,
	setSketchPreview,
} from '../../../../../../redux/reducers/sketchReducer';
import CategoryBlock from './CategoryBlock';
import { useAppSelector } from '../../../../../../shared/libs/redux';
import { ISimpleTemplate } from '../../../../../../entities/templates/types';

interface OfferSettingsContainerProps {
	template?: ISimpleTemplate,
	setSketchName: (value: string) => void,
	setSketchManagerId: (value: number | undefined | null) => void,
	setSketchDeadline: (value: string | null | undefined) => void,
	setSketchPreview: (value: string | null) => void
}

/**
 * Компонент - страница настройки КП
 */
const OfferSettingsContainer = ({
	setSketchDeadline,
	setSketchManagerId,
	setSketchName,
	setSketchPreview,
	template,
}: OfferSettingsContainerProps) => {
	const { id } = useParams();

	const {
		name,
		preview,
		deadline,
		sketch_structure: structure,
	} = useAppSelector(state => state.sketch);

	const [isChangeConstructorOpen, setIsChangeConstructorOpen] = useState(false);

	const navigate = useNavigate();

	const controller = new AbortController();

	// * Вернуться на предыдущую страницу
	const onGoBack = () => {
		navigate(-1);
	};

	// * При открытии окна изменении шаблона
	const onOpenChangeTemplate = () => {
		setIsChangeConstructorOpen(true);
	};

	// * При закрытии окна изменения шаблона
	const onCloseChangeTemplate = () => setIsChangeConstructorOpen(false);

	// * При переходе к отправке
	const onSendTemplate = () => {
		setSketchName(name);
		setSketchDeadline(deadline);
		setSketchManagerId(null);
		// setSketchManagerId(choicedManager ? parseInt(choicedManager.id, 10) : undefined);
		setSketchPreview(preview);

		navigate(`/preview/${id}`);
	};

	// * Получает приватный шаблон
	useEffect(() => {
		if (template) {
			onSetSketchStructure({
				...template.template,
				root: template.rootComponent,
			});
		}
	}, [template]);

	useEffect(() => {
		setSketchDeadline('');
	}, []);

	// * Устанавливает title при подтягивании
	useEffect(() => {
		if (structure) {
			setSketchName(structure.name);
		}
	}, [setSketchName, structure]);

	useEffect(() => () => controller.abort(), [controller]);

	return (
		structure
			? (
				<OfferSettings
					sketchStructure={structure}
					isChangeTemplateOpen={isChangeConstructorOpen}
					onSendTemplate={onSendTemplate}
					onOpenChangeTemplate={onOpenChangeTemplate}
					title={name}
					setTitle={setSketchName}
					onGoBack={onGoBack}
					onCloseChangeTemplate={onCloseChangeTemplate}
				>
					<CategoryBlock />
				</OfferSettings>
			)
			: <div />
	);
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	setSketchName: (value: string) => dispatch(setSketchName(value)),
	setSketchManagerId: (value: number | undefined | null) => dispatch(setSketchManagerId(value)),
	setSketchDeadline: (value: string | null | undefined) => dispatch(setSketchDeadline(value)),
	setSketchPreview: (value: string | null) => dispatch(setSketchPreview(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferSettingsContainer);
