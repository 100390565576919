import React from 'react';
import css from '../../card.module.scss';
import { ReactComponent as OpenArrowIcon } from '../../../../../../image/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../../../../../image/close-arrow.svg';
import { ReactComponent as UsersIcon } from '../../../../../../image/users.svg';

interface InfoProps {
	isOpen: boolean,
	name: string,
	employeeCount: string,
}

export const Info = ({
	isOpen,
	name,
	employeeCount,
}: InfoProps) => (
	<div className={css.info}>
		<div className={css.arrow}>
			{
				isOpen
					? <OpenArrowIcon />
					: <CloseArrowIcon />
			}
		</div>
		<UsersIcon className={css.user_icon} />
		<span className={css.name}>{name}</span>
		<span className={css.employee_count}>{employeeCount}</span>
	</div>
);
