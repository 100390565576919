import { useEffect, useState } from 'react';
import InputArrowItem
	from '../../../../../components/pages/features/moduleWindow/createInputArrow/inputArrowItem/InputArrowItem';
import { tariffMapData } from '../../../../../entities/tariff/model/data';
import { tariffAPI } from '../../../../../entities/tariff/api/api';
import { IWorkshopTemplateGallery } from '../../../../../entities/templates/workshop/template/model/types';
import { useEditTariffAvailability } from '../api/useEditTariffAvailability';
import css from './edit-tariff-availability.module.scss';
import { ITariffBitmapItem } from '../../../../../entities/tariff/api/types';
import { checkBitPosition } from '../../../../../helpers/checkBitPosition';

interface EditWorkshopTemplateTariffAvailabilityProps {
	template: string;
	gallery: IWorkshopTemplateGallery | null | undefined;
}

export const EditWorkshopTemplateTariffAvailability = ({
	gallery,
	template,
}: EditWorkshopTemplateTariffAvailabilityProps) => {
	const { updateTariff } = useEditTariffAvailability(template);

	const { data: bitmap } = tariffAPI.useGetBitmapQuery();

	const [tariffPermission, setTariffPermission] = useState(gallery?.tariffPermission || 0);

	useEffect(() => {
		setTariffPermission(gallery?.tariffPermission || 0);
	}, [gallery?.tariffPermission]);

	const selectTariff = async (tariff: ITariffBitmapItem) => {
		const newPermission = tariffPermission | (1 << tariff.bitPosition);
		await updateTariff(newPermission);
		setTariffPermission(newPermission);
	};

	const removeTariff = async (tariff: ITariffBitmapItem) => {
		const newPermission = tariffPermission & ~(1 << tariff.bitPosition);
		await updateTariff(newPermission);
		setTariffPermission(newPermission);
	};

	return (
		<>
			<div className={css.title}>Доступность в тарифах</div>
			<div className={css.controls}>
				{
					bitmap?.tariffs.map(tariff => {
						const selected = !!checkBitPosition(tariffPermission, tariff.bitPosition);
						
						return (
							<InputArrowItem
								id={tariff.tariff}
								key={tariff.tariff}
								isChoiced={selected}
								onAddChoicedItem={() => selectTariff(tariff)}
								onRemoveChoicedItem={() => removeTariff(tariff)}
								name={tariffMapData.get(tariff.tariff) as string}
							/>
						);
					})
				}
			</div>
		</>
	);
};
