import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { setIsLegalEntityConfirmDeleteModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { legalEntityAPI } from '../../../../entities/legal-entity/api/api';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useDeleteLegalEntity = () => {
	const dispatch = useAppDispatch();

	const {
		deletingLegalEntityId,
		isLegalEntityConfirmDeleteModalActive: isActive,
	} = useAppSelector(state => state.legalEntity);

	const setActive = (value: boolean) => {
		dispatch(setIsLegalEntityConfirmDeleteModalActive(value));
	};

	const [del] = legalEntityAPI.useDeleteMutation();

	const deleteLegalEntity = async () => {
		if (deletingLegalEntityId) {
			try {
				await del({ id: deletingLegalEntityId })
					.unwrap();
				setActive(false);
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				notificationSuccess('Вы успешно удалили юр. лицо!');
			} catch (err) {
				notificationError('Произошла ошибка при удалении юр. лица...');
			}
		}
	};

	return {
		deleteLegalEntity,
		isActive,
		setActive,
	};
};
