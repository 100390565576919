import { AxiosResponse } from 'axios';
import APIGroup from '../APIGroup';
import setHeaders from '../generalFunc';

export interface IAPIEmojiResponse {
    list: [
        {
            emojis: [
                {
                    file: string,
                    id: number
                }
            ],
            name: string
        }
    ]
}

class Emoji extends APIGroup {
	private readonly URI_EMOJI_GET = '/emoji';

	public getEmojiList = (): Promise<IAPIEmojiResponse> => this.axios(this.URI_EMOJI_GET, {
		method: 'get',
		headers: setHeaders(true),
	})
		.then((response: AxiosResponse<IAPIEmojiResponse>) => response.data);
}

export default Emoji;
