import { useEffect, useMemo } from "react";
import useDynamicPagination from "../../useDynamicPagination";
import { ITEM_LIMIT_PER_PAGE } from "../../../components/pages/collection/section/template/SectionTemplate";
import { templatePrivateAPI } from "../../../entities/templates/private/api/api";
import { useAppDispatch, useAppSelector } from "../../../shared/libs/redux";
import { useDebounce } from "../../../shared/libs/useDebounce";
import { useCleanTemplateSelection } from "../../template-selection/useCleanTemplateSelection";
import { ITemplateData, TTemplateSortBy } from "../../../entities/templates/types";
import { privateTemplateSlice, setPrivateTemplates } from "../../../entities/templates/private/model/slice";

/** Кастомный хук для обработки шаблонов (загрузка, выделение и т.д.) */
const useGetTemplatesMy = (
	cleanSelectedList: () => void,
	sortBy?: TTemplateSortBy,
) => {
	const dispatch = useAppDispatch();

	const {
		search,
		tagFilter: privateTagFilter,
		privateTemplates: templates,
	} = useAppSelector(state => state.privateTemplate);

	const { cleanTemplateSelection } = useCleanTemplateSelection();

	const debounceSearch = useDebounce(search.trim(), 250);

	const setPrivateList = (list: ITemplateData[]) => dispatch(setPrivateTemplates(list));

	const tags = useMemo(() => privateTagFilter.map(tag => tag.id.toString()), [privateTagFilter]);

	const {
		data: initialTemplates,
		isLoading
	} = templatePrivateAPI.useGetAllQuery({
		page: 1,
		sortBy,
		limit: ITEM_LIMIT_PER_PAGE,
		search: debounceSearch,
		tags,
	});

	const [getTemplates] = templatePrivateAPI.useLazyGetAllQuery();

	const fetchData = async (page: number, perPage: number) => {
		if (templates) {
			return getTemplates({
				page,
				sortBy,
				limit: perPage,
				search: debounceSearch,
				tags,
			})
				.unwrap()
				.then(data => {
					setPrivateList([...templates, ...data.privateTemplates]);
				});
		}
		return undefined;
	};

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		ITEM_LIMIT_PER_PAGE,
		2,
		templates?.length || 0
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialTemplates) {
			setCurrentPage(2);
			setPrivateList(initialTemplates?.privateTemplates || []);

			setTotalCount(initialTemplates?.totalCount || 0);
		}
	}, [initialTemplates]);

	useEffect(() => {
		cleanSelectedList();
		cleanTemplateSelection();
	}, [search, sortBy]);

	return {
		isLoading,
	};
};

export default useGetTemplatesMy;
