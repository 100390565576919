import React from 'react';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { useUnpublishIntegrationWorkshopTemplate } from '../api/useUnpublishIntegrationWorkshopTemplate';

interface UnpublishWorkshopTemplateButtonProps {
	template: string;
	email: string,
}

export const UnpublishIntegrationWorkshopTemplateButton = ({
	template,
	email,
}: UnpublishWorkshopTemplateButtonProps) => {
	const {
		isLoading,
		onDeleteIntegration,
	} = useUnpublishIntegrationWorkshopTemplate();

	return (
		<MainButton
			onClick={() => onDeleteIntegration(template, email)}
			disabled={isLoading}
			btnStyle="transparent"
			type="submit"
		>
			Снять с публикации
		</MainButton>
	);
};
