import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITeamItem } from '../../entities/team/api/types';

interface IDeletingUserData {
	email: string,
	teamId: string,
}

export interface IModalsActive {
	isCreateOrgModalActive: boolean,
	isUsersModalListActive: boolean,
	isBindUserSuccessModalActive: boolean,
	isDeleteOrganizationSuccessActive: boolean,
	isDeleteUserSuccessActive: boolean,
}

interface IInitialState {
	teamsList: ITeamItem[] | undefined,

	isCreateTeamModalActive: boolean,
	isTeamUserSelectionModalActive: boolean,
	isTeamConfirmDeleteModalActive: boolean,
	deletingTeamId: string | null,
	isTeamUserConfirmDeleteModalActive: boolean,
	untieEmail: string | null,
	untieTeamId: string | null,

	modalsActive: IModalsActive,
	bindingTeamId: string | null,
	deletingUserData: IDeletingUserData | null,
}

const initialState: IInitialState = {
	teamsList: undefined,

	isCreateTeamModalActive: false,
	isTeamUserSelectionModalActive: false,
	bindingTeamId: null,
	isTeamConfirmDeleteModalActive: false,
	deletingTeamId: null,
	isTeamUserConfirmDeleteModalActive: false,
	untieEmail: null,
	untieTeamId: null,

	modalsActive: {
		isBindUserSuccessModalActive: false,
		isCreateOrgModalActive: false,
		isDeleteOrganizationSuccessActive: false,
		isDeleteUserSuccessActive: false,
		isUsersModalListActive: false,
	},
	deletingUserData: null,
};

export const teamSlice = createSlice({
	name: 'team',
	initialState,
	reducers: {
		setTeamsList: (state, action: PayloadAction<ITeamItem[]>) => {
			state.teamsList = action.payload;
		},

		setIsCreateTeamModalActive: (state, action: PayloadAction<boolean>) => {
			state.isCreateTeamModalActive = action.payload;
		},
		setIsTeamUserSelectionModalActive: (state, action: PayloadAction<boolean>) => {
			state.isTeamUserSelectionModalActive = action.payload;
		},
		setBindingTeamId: (state, action: PayloadAction<string>) => {
			state.bindingTeamId = action.payload;
		},
		setIsTeamConfirmDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isTeamConfirmDeleteModalActive = action.payload;
		},
		setDeletingTeamId: (state, action: PayloadAction<string>) => {
			state.deletingTeamId = action.payload;
		},
		setIsTeamUserConfirmDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isTeamUserConfirmDeleteModalActive = action.payload;
		},
		setTeamUntieEmail: (state, action: PayloadAction<string>) => {
			state.untieEmail = action.payload;
		},
		setTeamUntieId: (state, action: PayloadAction<string>) => {
			state.untieTeamId = action.payload;
		},

		setIsCreateOrgModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreateOrgModalActive = action.payload;
		},
		setIsUsersModalListActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isUsersModalListActive = action.payload;
		},
		setIsBindUserSuccessModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isBindUserSuccessModalActive = action.payload;
		},
		setIsDeleteOrganizationSuccessActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteOrganizationSuccessActive = action.payload;
		},
		setIsDeleteUserSuccessActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteUserSuccessActive = action.payload;
		},
		setDeletingUserData: (state, action: PayloadAction<IDeletingUserData>) => {
			state.deletingUserData = action.payload;
		},
	},
});

export const {
	setTeamsList,
	setBindingTeamId,
	setDeletingTeamId,
	setDeletingUserData,
	setIsUsersModalListActive,
	setIsCreateOrgModalActive,
	setIsDeleteUserSuccessActive,
	setIsBindUserSuccessModalActive,
	setIsDeleteOrganizationSuccessActive,

	setIsTeamConfirmDeleteModalActive,
	setIsTeamUserConfirmDeleteModalActive,
	setIsTeamUserSelectionModalActive,
	setIsCreateTeamModalActive,
	setTeamUntieEmail,
	setTeamUntieId,
} = teamSlice.actions;

export default teamSlice.reducer;
