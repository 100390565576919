import InterfacePanel from '../../../InterfacePanel';
import ZoomTool from './ZoomTool';

export class ZoomPanel extends InterfacePanel {
	public zoomTool: ZoomTool;

	constructor() {
		super();
		this.zoomTool = new ZoomTool();
		this.setRootElementClassName('panel-zoom');
		this.rootElement.append(this.zoomTool.getElement());
	}
}

export default ZoomPanel;
