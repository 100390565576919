import MagneticLine from './MagneticLine';
import DirectionVector from '../DirectionVector';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import IFrameConfiguration from '../../../frame/IFrameConfiguration';

interface IMagneticGraphicSideLineProps {
	parentElement: HTMLElement,
	direction: DirectionVector,
	pageConfiguration: IFrameArea,
	graphicConfiguration: IFrameConfiguration,
}

/**
 * Реализация магнитной линии основанных на графике.
 */
class MagneticGraphicSideLine extends MagneticLine {
	constructor(props: IMagneticGraphicSideLineProps) {
		super(props.direction, props.parentElement);

		switch (props.direction) {
		case DirectionVector.LEFT:
			this.setLocation(props.graphicConfiguration.x, 0, props.pageConfiguration.height);
			break;
		case DirectionVector.RIGHT:
			this.setLocation(
				props.graphicConfiguration.x + props.graphicConfiguration.width,
				0,
				props.pageConfiguration.height,
			);
			break;
		case DirectionVector.TOP:
			this.setLocation(0, props.graphicConfiguration.y, props.pageConfiguration.width);
			break;
		case DirectionVector.BOTTOM:
			this.setLocation(
				0,
				props.graphicConfiguration.y + props.graphicConfiguration.height,
				props.pageConfiguration.width,
			);
			break;
		case DirectionVector.MIDDLE_X:
			this.setLocation(
				0,
				props.graphicConfiguration.y + (props.graphicConfiguration.height / 2),
				props.pageConfiguration.width,
			);
			break;
		case DirectionVector.MIDDLE_Y:
			this.setLocation(
				props.graphicConfiguration.x + (props.graphicConfiguration.width / 2),
				0,
				props.pageConfiguration.height,
			);
			break;
		default: break;
		}
	}
}

export default MagneticGraphicSideLine;
