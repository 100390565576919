import React, { useMemo } from 'react';
import css from './EditWorkshopCategories.module.scss';
import { classNames } from '../../../../../shared/libs/classNames';
import { EmptyList } from '../../../../../components/ui/empty-list/empty-list';
import BlockHeader
	from '../../../../../components/pages/features/moduleWindow/editModuleWindow/blockHeader/BlockHeader';
import { EditingCategoryCard } from '../editing-category-card/EditingCategoryCard';
import { setIsCreatingCategoryModalActive } from '../../../../../redux/reducers/module/categoryReducer';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { workshopCategoryApi } from '../../../../../entities/workshop-category';

interface EditCategoriesProps {
	className?: string,
}

export const EditWorkshopCategories = ({ className }: EditCategoriesProps) => {
	const dispatch = useAppDispatch();

	const { data: categories } = workshopCategoryApi.useGetWorkshopCategoriesQuery({
		page: 1,
		limit: 127,
	});

	const sortedCategories = useMemo(() => (
		[...(categories?.moduleCategories || [])].sort((a, b) => a.sequenceNumber - b.sequenceNumber)
	), [categories]);

	const onCreateCategory = () => dispatch(setIsCreatingCategoryModalActive(true));

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<BlockHeader
				title="Категории"
				onBtnClick={onCreateCategory}
				btnText="Добавить категорию"
				cssClass={css.dropdown}
				btnClass={classNames('', { [css.btn]: !!categories?.moduleCategories })}
			>
				{
					categories?.moduleCategories
						? (
							sortedCategories
								.map(category => (
									<EditingCategoryCard key={category.id} category={category} />
								))
						)
						: (
							<EmptyList
								title="Создайте первую категорию"
								needBtn={false}
							/>
						)
				}
			</BlockHeader>
		</div>
	);
};
