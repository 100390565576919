import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Utils from '../../../components/Sketch/utils/impl/Utils';
import {
	IMessage, ISendListener, ISocketState,
} from './types/types';
import SocketMessageFactory from '../../../components/Sketch/replication/SocketMessageFactory';
import SocketQueue from '../../../components/Sketch/replication/SocketQueue';
import ManipulatorError from '../../../components/Sketch/utils/manipulator-error/ManipulatorError';
import { AnyManipulatorCommand } from '../../../components/Sketch/Types';
import { ServerWebSocketType } from './types/serverWebSocketType';
import { RootState } from '../../../redux/reducers/reducer';

const messageFactory = new SocketMessageFactory();
export const myURL = Utils.Backend.getSocketConnectionString();

export const sendAuthTokenAsync = createAsyncThunk(
	'webSocket/sendAuthToken',
	async (_, { getState }) => {
		const state = getState() as RootState;
		if (!state.webSocket.socket
			|| state.webSocket.socket.readyState !== 1) {
			throw new Error('WebSocket is not connected');
		}

		const authToken = localStorage.getItem('authorization') || sessionStorage.getItem('authorization');
		if (!authToken) throw new ManipulatorError('auth token is empty');

		const message = JSON.stringify({
			id: Utils.Generate.UUID4(),
			type: 'auth.login',
			body: JSON.stringify({ session: authToken }),
		});

		state.webSocket.socket?.send(message);
	},
);

const initialState: ISocketState = {
	isOpen: false,	// Нужен для обработки 1005 кода ws (для понимания что нами инициировано отключение)
	startSendListeners: [],
	sendErrorListeners: [],
	sendSuccessListeners: [],
	sketchID: null,
	pendingMessageID: null,
	queueMessages: new SocketQueue(),
	socket: null,
};

export const socketSlice = createSlice({
	name: 'webSocket',
	initialState,
	selectors: {
		getPendingMessageId: (state) => state.pendingMessageID,
		getSocket: (state) => state.socket,
	},
	reducers: {
		setSocket: (state, action: PayloadAction<WebSocket | null>) => {
			state.socket = action.payload;
		},
		connect: (state) => {
			// Говорим, что мы открываем соединение
			state.isOpen = true;
		},

		disconnect: (state) => {
			state.isOpen = false;
			if (!state.socket) return;
			state.socket.close(1000);
			state.socket = null;
		},
		/* Использовать при входе в конструктор для отправки сообщения, по которому сервер нас
		* проверит и запишет в разрешенный список */
		sendAuthToken: (state) => {
			// nothing
		},
		sendTemplateId: (state, action: PayloadAction<string>) => {
			// nothing
		},
		setPendingMessageId: (state, action: PayloadAction<string | null>) => {
			state.pendingMessageID = action.payload;
		},
		callSendSuccessListeners: (state) => {
			state.sendSuccessListeners.forEach(listener => listener());
		},
		callStartSendListeners: (state) => {
			state.startSendListeners.forEach(listener => listener());
		},
		callSendErrorListeners: (state) => {
			state.sendErrorListeners.forEach(listener => listener());
		},
		addStartSendListener: (state, action: PayloadAction<ISendListener>) => {
			state.startSendListeners.push(action.payload.listener);
		},
		addSendErrorListener: (state, action: PayloadAction<ISendListener>) => {
			state.sendErrorListeners.push(action.payload.listener);
		},
		addSendSuccessListener: (state, action: PayloadAction<ISendListener>) => {
			state.sendSuccessListeners.push(action.payload.listener);
		},
		send: (state, action: PayloadAction<IMessage>) => {
			if (!state.socket) return;
			state.startSendListeners.forEach(listener => listener());
			state.pendingMessageID = action.payload.message.getID();
			state.socket.send(action.payload.message.getJSON());
		},
		sendMessages: (state) => {
			if (state.pendingMessageID !== null) {
				return;
			}
			if (!state.queueMessages.isEmpty()) {
				const message = state.queueMessages.dequeue();
				if (message === undefined) {
					return;
				}

				if (!state.socket) return;
				state.startSendListeners.forEach(listener => listener());
				state.pendingMessageID = message.getID();
				state.socket.send(message.getJSON());
			}
		},
		/**
		 * Упаковывает и отправляет команды пользователя на изменения скетча по сокету.
		 */
		sendCommands: (
			state,
			action: PayloadAction<AnyManipulatorCommand[]>,
		) => {
			if (!state.socket) return;
			const socketMessage = messageFactory.getActionMessage(action.payload);
			state.queueMessages.enqueue(socketMessage);
			switch (state.socket.readyState) {
			case state.socket.OPEN: {
				if (!state.queueMessages.isEmpty()) {
					const message = state.queueMessages.dequeue();
					if (message === undefined) {
						return;
					}

					if (!state.socket) return;
					state.startSendListeners.forEach(listener => listener());
					state.pendingMessageID = message.getID();
					state.socket.send(message.getJSONMessage());
				}
				break;
			}
				// case state.socket.CLOSED:
				// 	state.queueMessages.
				// 	break;
			}
		},
		disconnectTemplate: (state, action: PayloadAction<string>) => {
			if (!state.socket) return;

			const message = JSON.stringify({
				id: Utils.Generate.UUID4(),
				type: ServerWebSocketType.templateDisconnect,
				body: JSON.stringify({
					sketch_id: action.payload,
				}),
			});
			state.socket.send(message);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(sendAuthTokenAsync.fulfilled, (state) => {
				// Действия после успешной отправки токена
			})
			.addCase(sendAuthTokenAsync.rejected, (state, action) => {
				console.error('Failed to send auth token:', action.error);
			});
	},
});

export const {
	connect,
	disconnect,
	callSendSuccessListeners,
	addStartSendListener,
	addSendErrorListener,
	addSendSuccessListener,
	send,
	sendCommands,
	setSocket,
	sendAuthToken,
	sendTemplateId,
	disconnectTemplate,
	callStartSendListeners,
} = socketSlice.actions;

export const {
	getSocket,
} = socketSlice.selectors;

export default socketSlice.reducer;
