import ru from 'date-fns/locale/ru';

export const useCustomRuLocale: Locale = {
	...ru,
	localize: {
		...ru.localize,
		month: (n: number) => {
			const months = [
				'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
				'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь',
			];
			return months[n];
		},
		day: (n: number) => {
			const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
			return days[n];
		},
		ordinalNumber: (n: number) => ru.localize?.ordinalNumber(n),
		era: (n: number) => ru.localize?.era(n),
		quarter: (n: number) => ru.localize?.quarter(n),
		dayPeriod: (n: string) => ru.localize?.dayPeriod(n),
	},
};
