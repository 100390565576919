import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { teamTemplateAPI } from '../../../../../../../entities/templates/team/api/api';
import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { setIsConfirmTeamMoveModalActive } from '../../../../../../../entities/templates/team/model/slice';

export const useMoveTeamTemplates = () => {
	const dispatch = useAppDispatch();

	const template = useAppSelector(state => state.teamTemplate.selectedTeamTemplate);
	const active = useAppSelector(state => state.teamTemplate.isConfirmTeamMoveModalActive);

	const [duplicateToPrivate] = teamTemplateAPI.useDuplicateToPrivateMutation();

	const setActive = (value: boolean) => {
		dispatch(setIsConfirmTeamMoveModalActive(value));
	};

	const moveTemplate = async () => {
		try {
			await duplicateToPrivate({ templates: { id: template.id } })
				.unwrap();

			setActive(false);
			notificationSuccess('Шаблон успешно перемещен!');
		} catch (e) {
			notificationError('Ошибка!', 'Произошла ошибка при перемещении шаблона...');
		}
	};

	return {
		active,
		setActive,
		moveTemplate,
	};
};
