// * Определяет пункты в меню
import {PossibleItem} from '../components/pages/collection/section/template/my/item/TemplateMyItem';
import {IActionsWithCardsItem} from '../components/ui/actionsWithCards/TransitionActionsWithCards';

const actionsDependsPossible = (isItPossibleTo: PossibleItem, ActionsWithCardsItems: IActionsWithCardsItem[]) => {
    const currentPossible = [];

    if (isItPossibleTo.move) {
        currentPossible.push(ActionsWithCardsItems[0]);
    }

    if (isItPossibleTo.copy) {
        currentPossible.push(ActionsWithCardsItems[1]);
    }

    if (isItPossibleTo.delete) {
        currentPossible.push(ActionsWithCardsItems[2]);
    }

    if (ActionsWithCardsItems.length === 4) currentPossible.push(ActionsWithCardsItems[3]);

    return currentPossible;
};

export default actionsDependsPossible;
