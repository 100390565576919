import React from 'react';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import OfferPresenting from './OfferPresenting';
import ISketchStructure from '../../Sketch/ISketchStructure';

export interface ComponentOfferPresentingProps {
	structure: ISketchStructure,
}

class ComponentOfferPresetting extends React.Component<ComponentOfferPresentingProps, object> {
	private manipulator: OfferPresenting | null = null;
	private ref : React.RefObject<HTMLDivElement>;

	constructor(props: ComponentOfferPresentingProps | Readonly<ComponentOfferPresentingProps>) {
		super(props);
		const { structure } = this.props;
		this.state = structure;
		this.ref = React.createRef();
	}

	override componentDidMount() {
		this.createManipulator();
	}

	override componentDidUpdate(
		prevProps: ComponentOfferPresentingProps | Readonly<ComponentOfferPresentingProps>,
	) {
		const { structure } = this.props;
		// Сравниваем предыдущие и текущие пропсы
		if (prevProps.structure.root.components !== structure.root.components) {
			this.updateRef();
			const container = this.ref.current;
			if (container === null || container === undefined) {
				throw new ManipulatorError('manipulator container not found');
			}

			this.manipulator = new OfferPresenting(container, structure);
		}
	}

	override componentWillUnmount() {
		if (this.manipulator === null) {
			return;
		}
		this.manipulator.destruct();
	}

	private createManipulator = () => {
		const { structure } = this.props;
		if (!this.ref) return;
		const container = this.ref.current;
		if (container === null || container === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		this.manipulator = new OfferPresenting(container, structure);
	};

	updateRef() {
		if (this.ref.current) {
			this.ref.current.innerHTML = '';
		}
	}

	override render() {
		return <div ref={this.ref} className="sketch-manipulator offer-presetting" />;
	}
}

export default ComponentOfferPresetting;
