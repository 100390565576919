import React from 'react';
import css from './authorization.module.scss';

interface RememberMeBlockProps {
	isRememberMe: boolean;
	setIsRememberMe: (value: boolean) => void;
}

const RememberMeBlock: React.FC<RememberMeBlockProps> = ({ setIsRememberMe, isRememberMe }: RememberMeBlockProps) => (
	<div
		className={css.rememberWrapper}
		onClick={() => { setIsRememberMe(!isRememberMe); }}
		role="presentation"
	>
		{
			isRememberMe
				? (
					<div>
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="0.5"
								y="0.5"
								width="20"
								height="20"
								rx="5.5"
								fill="white"
								stroke="#EEEEEE"
							/>
							<path
								d="M16 7L8.66667 14L5 10.5"
								stroke="#0075FF"
							/>
						</svg>
					</div>
				)
				: (
					<div>
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect
								x="0.5"
								y="0.5"
								width="20"
								height="20"
								rx="5.5"
								fill="white"
								stroke="#EEEEEE"
							/>
						</svg>
					</div>
				)
		}
		<span className={css.remember}>Запомнить меня</span>
	</div>
);

export default RememberMeBlock;
