import React from 'react';
import { useParams } from 'react-router-dom';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import OfferPrinter from './OfferPrinter';
import { LoadStructureFunctionType } from '../../../shared/api/structure/load-structure';

interface ComponentOfferPrinterWithRouterProps {
	loadStructure: LoadStructureFunctionType,
	isAutoPrint: boolean
}

export interface ComponentOfferPrinterProps {
	sketchID: string,
	isAutoPrint: boolean,
	loadStructure: LoadStructureFunctionType,
}

class ComponentOfferPrinter extends React.Component<ComponentOfferPrinterProps, object> {
	private refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();
	private manipulator: OfferPrinter | null = null;

	override componentDidMount() {
		const {
			sketchID,
			isAutoPrint,
			loadStructure,
		} = this.props;

		const container = this.refManipulatorContainer.current;
		if (container === null || container === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		// store.dispatch(offerAPI.endpoints?.presentation.initiate({ id: sketchID }))
		// 	.unwrap()
		// 	.then(res => {
		// 		this.manipulator = new OfferPrinter(container, res.rootComponent);
		// 	});

		loadStructure(sketchID)
			.then(res => {
				this.manipulator = new OfferPrinter(container, res.rootComponent);
				if (isAutoPrint) {
					setTimeout(window.print, 1000);
				}
			});
	}

	override componentWillUnmount() {
		if (this.manipulator === null) {
			return;
		}
		this.manipulator.destruct();
	}

	override render() {
		return <div ref={this.refManipulatorContainer} className="sketch-manipulator offer-printer" />;
	}
}

export const ComponentOfferPrinterWithRouter = ({
	loadStructure,
	isAutoPrint,
}: ComponentOfferPrinterWithRouterProps) => {
	const { id } = useParams();

	if (id === undefined) {
		return <p>error get sketch id</p>;
	}

	return <ComponentOfferPrinter sketchID={id} loadStructure={loadStructure} isAutoPrint={isAutoPrint} />;
};
