import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setCurrentTagsIds } from '../../../../entities/modules/module/model/slice';

export const useSelectedGroupTags = () => {
	const dispatch = useAppDispatch();

	const selectedTags = useAppSelector(state => state.moduleWindow.currentTagsIds);

	const onSetCurrentIds = (preparedIds: number[]) => dispatch(setCurrentTagsIds(preparedIds));

	const onSelectTag = (id: number) => {
		if (selectedTags.includes(id)) {
			const preparedIds = selectedTags.filter(tag => tag !== id);

			onSetCurrentIds(preparedIds);
		} else {
			const preparedIds = [...selectedTags, id];

			onSetCurrentIds(preparedIds);
		}
	};

	const onCleanSelectedTagList = () => onSetCurrentIds([]);

	return {
		selectedTags,
		onSelectTag,
		onCleanSelectedTagList,
	};
};
