import React from 'react';
import { useSelector } from 'react-redux';
import css from './EditingTemplateModules.module.scss';
import { classNames } from '../../../shared/libs/classNames';
import TagBlock from '../../../components/pages/features/moduleWindow/editModuleWindow/editBlocks/tagBlock/TagBlock';
import TagGroupBlock
	from '../../../components/pages/features/moduleWindow/editModuleWindow/editBlocks/tagGroupBlock/TagGroupBlock';
import { NewModal } from '../../../components/ui/modals/new-modal';
import { moduleWindow, moduleWindowActions } from '../../../entities/modules/module/model/slice';
import { useAppDispatch } from '../../../shared/libs/redux';
import { templateModuleActions, templateModuleSelectors } from '../../../entities/template-module';

interface EditingTemplateModulesProps {
	className?: string,
	modalInModalActive?: boolean,
}

export const EditingTemplateModules = ({ className, modalInModalActive }: EditingTemplateModulesProps) => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(templateModuleSelectors.getIsEditTemplateModuleActive);

	const setActive = (value: boolean) => {
		dispatch(templateModuleActions.setIsEditTemplateModuleActive(value));
	};

	return (
		<NewModal modalInModalActive={modalInModalActive} styles={css.modal} active={isActive} closeModal={setActive}>
			<div className={classNames(css.wrapper, {}, [className])}>
				<div className={css.header}>
					<div className={css.title_block}>
						<div className={css.title}>Редактирование модулей</div>
					</div>
				</div>
				<TagBlock />
				<TagGroupBlock />
			</div>
		</NewModal>
	);
};
