import { useParams } from 'react-router-dom';
import OfferSettingsContainer from '../../../offerSettings/OfferSettingsContainer';
import { templatePrivateAPI } from '../../../../../../../../entities/templates/private/api/api';

export const PrivateTemplateOfferSettings = () => {
	const { id } = useParams();

	const { isLoading, data: template } = templatePrivateAPI.useGetQuery({ id: id || '' }, {
		skip: !id,
		refetchOnMountOrArgChange: true,
	});

	if (isLoading) {
		return <div />;
	}
	return <OfferSettingsContainer template={template} />;
};
