// eslint-disable-next-line import/no-extraneous-dependencies
import EditableFrame from '../frame/editable/EditableFrame';
import IGraphicStructure from './IGraphicStructure';
import GraphicType from './GraphicType';
import IComponent from '../components/IComponent';
import HTMLGenerator from '../utils/HTMLGenerator';
import IDescartesPosition from '../utils/IDescartesPosition';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import IFrameArea from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import SpatialGraphicResizeArea
	from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicResizeArea';
import AreaResizeTrigger from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/AreaResizeTrigger';
import OnMutationEvent from '../mechanics/spatial-quadrants/area-mutators/events/OnMutationEvent';
import Utils from '../utils/impl/Utils';
import SpatialGraphicResizeAngularArea
	from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicResizeAngularArea';
import IGraphic from './IGraphic';
import { AnySpatialArea } from '../Types';
import GraphicBlobGenerator from '../utils/GraphicBlobGenerator';

/**
 * База для любой графики в манипуляторе.
 * @abstract
 */
abstract class Graphic<TextureType> extends EditableFrame implements IGraphic {
	private readonly postMutationEvents: OnMutationEvent[];
	private maintainAspectRatio = false;

	protected readonly graphicElement: HTMLElement;

	protected id: string;
	/* */
	protected offset: number;
	protected parentComponent: IComponent | null;

	protected abstract GRAPHIC_CLASS_NAME: string;
	protected graphicBlobGenerator: GraphicBlobGenerator;

	public readonly isUniter: boolean = false;
	public abstract readonly type: GraphicType;

	protected constructor() {
		super();
		const frameElement = this.getFrameElement();

		this.offset = 0;
		this.id = Utils.Generate.UUID4();
		this.parentComponent = null;
		this.graphicElement = HTMLGenerator.getDiv();
		frameElement.prepend(this.graphicElement);
		this.graphicBlobGenerator = new GraphicBlobGenerator();
		this.postMutationEvents = [];
	}

	/**
	 * Рекурсивно поднимается от переданной графики наверх и возвращает родительскую групповую графику. Если графика
	 * находится не в группе, то вернет null.
	 */
	public getParentGroupGraphic = (): IGraphic | null => {
		let parentGraphic: IGraphic | null = this.getParentGraphic();
		if (parentGraphic === null) throw new ManipulatorError('parentGraphic is null');

		while (parentGraphic.type !== GraphicType.PAGE) {
			if (parentGraphic.type === GraphicType.GROUP) return parentGraphic;
			parentGraphic = parentGraphic.getParentGraphic();
			if (parentGraphic === null) throw new ManipulatorError('parentGraphic is null');
		}
		return null;
	};

	public setParentComponent = (component: IComponent) => {
		this.parentComponent = component;
	};

	public getGlobalPosition = (): IDescartesPosition => {
		const bound = this.frameElement.getBoundingClientRect();
		return {
			x: bound.x + window.scrollX,
			y: bound.y + window.scrollY,
		};
	};

	public getRealWidth = (): number => {
		const { width } = this.graphicElement.getBoundingClientRect();
		return Math.round(width);
	};

	public getRealHeight = (): number => {
		const { height } = this.graphicElement.getBoundingClientRect();
		return Math.round(height);
	};

	public getStructure = (): IGraphicStructure<TextureType> => ({
		id: this.id,
		type: this.type,
		offset: this.offset,
		frame: this.getFrameConfiguration(),
		texture: this.getTexture(),
	});

	public getParentGraphic = (): IGraphic | null => {
		if (this.parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}
		const prevParentComponent = this.parentComponent.getParentComponent();
		if (prevParentComponent === null) {
			return null;
		}

		const parentGraphics = prevParentComponent.getGraphics();
		const componentStructure = this.parentComponent.getStructure();
		if (componentStructure.offset === null) {
			throw new ManipulatorError('component structure offset is null');
		}
		const parentGraphicIndex = this.offset + componentStructure.offset;
		const findGraphic = parentGraphics[parentGraphicIndex];
		if (findGraphic === undefined) {
			throw new ManipulatorError('find graphic not found');
		}

		return findGraphic;
	};

	public getUniqueStructure = (): IGraphicStructure<TextureType> => ({
		id: Utils.Generate.UUID4(),
		type: this.type,
		offset: this.offset,
		frame: this.getFrameConfiguration(),
		texture: this.getUniqueTexture(),
	});

	public mutateFrameArea = (fn: (prev: IFrameArea) => IFrameArea): void => {
		const currentFrameArea = this.getFrameConfiguration();
		const updatedFrameArea = fn(currentFrameArea);
		this.setFrameConfiguration(prev => ({
			...prev,
			...updatedFrameArea,
		}));
	};

	public addMutationListener = (event: OnMutationEvent): void => {
		this.postMutationEvents.push(event);
	};

	public getResizeAreas = (): AnySpatialArea[] => {
		const areas: AnySpatialArea[] = [];
		const permissions = this.getMutationPermission();

		if (permissions.LEFT) {
			areas.push(new SpatialGraphicResizeArea(this, AreaResizeTrigger.LEFT));
		}
		if (permissions.TOP) {
			areas.push(new SpatialGraphicResizeArea(this, AreaResizeTrigger.TOP));
		}
		if (permissions.RIGHT) {
			areas.push(new SpatialGraphicResizeArea(this, AreaResizeTrigger.RIGHT));
		}
		if (permissions.BOTTOM) {
			areas.push(new SpatialGraphicResizeArea(this, AreaResizeTrigger.BOTTOM));
		}
		if (permissions.LEFT_BOTTOM) {
			areas.push(new SpatialGraphicResizeAngularArea(this, AreaResizeTrigger.LEFT_BOTTOM));
		}
		if (permissions.LEFT_TOP) {
			areas.push(new SpatialGraphicResizeAngularArea(this, AreaResizeTrigger.LEFT_TOP));
		}
		if (permissions.RIGHT_TOP) {
			areas.push(new SpatialGraphicResizeAngularArea(this, AreaResizeTrigger.RIGHT_TOP));
		}
		if (permissions.RIGHT_BOTTOM) {
			areas.push(new SpatialGraphicResizeAngularArea(this, AreaResizeTrigger.RIGHT_BOTTOM));
		}

		return areas;
	};

	public setOffset = (value: number) => {
		this.offset = value;
	};

	/**
	 * Возвращает изображение графики в виде строки base64 и её размера.
	 * @param callback Функция, выполняемая по завершению генерации изображения.
	 */
	public toBase64 = (callback: (bytes: string, size: number) => void) => {
		const cloneGraphicElement = this.graphicElement.cloneNode(true) as HTMLElement;
		this.graphicBlobGenerator.generate(cloneGraphicElement, callback);
	};

	public isMaintainAspectRatio = (): boolean => this.maintainAspectRatio;
	public isConnected = (): boolean => this.frameElement.isConnected;
	public getID = () => this.id;
	public getOffset = (): number => this.offset;
	public getShortID = () => this.id.slice(0, 4);
	public getGraphicElement = (): HTMLElement => this.graphicElement;
	public getFrameArea = (): IFrameArea => this.getFrameConfiguration();
	public getParentComponent = (): IComponent | null => this.parentComponent;
	public getPostMutationEvents = (): OnMutationEvent[] => this.postMutationEvents;

	public enableMaintainAspectRatio = () => {
		this.maintainAspectRatio = true;
	};

	public disableMaintainAspectRatio = () => {
		this.maintainAspectRatio = false;
	};

	public abstract setStructure: (
		fn: (prev: IGraphicStructure<TextureType>) => IGraphicStructure<TextureType>,
	) => void;

	/** Возвращает текстуру с обновленными идентификаторами для избежания конфликтов дублирования на сервере. */
	public abstract getUniqueTexture: () => TextureType;
	public abstract setTexture: (fn: (prev: TextureType) => TextureType) => void;
	public abstract getTexture: () => TextureType;
	public abstract getSpatialAreas: () => AnySpatialArea[];
}

export default Graphic;
