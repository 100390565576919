import React from 'react';
import HeaderContainer from '../../../../../Header/HeaderContainer';
import ISketchStructure from '../../../../../Sketch/ISketchStructure';
import ComponentOfferPresenter from '../../../../../SketchManipulators/OfferPresenter/ComponentOfferPresenter';
import UnRegisteredHeader from '../../../../registration/unRegisteredHeader/UnRegisteredHeader';
import { useAppSelector } from '../../../../../../shared/libs/redux';
import { personalAccountSlice } from '../../../../../../redux/reducers/personalAccountReducer';

interface OfferPresentationProps {
	structure: ISketchStructure | undefined,
}

const Presentation = ({ structure }: OfferPresentationProps) => {
	const isAuthorized = useAppSelector(personalAccountSlice.selectors.authorized);

	return (
		<>
			{
				isAuthorized && <HeaderContainer />
			}
			{
				!isAuthorized && <UnRegisteredHeader />
			}
			{
				structure && <ComponentOfferPresenter structure={structure} />
			}
		</>
	);
};

export default Presentation;
