import cx from 'classnames';
import React from 'react';
import { ICollectionTab } from './model/types';
import css from './tab.module.scss';

interface CollectionTabProps {
	isActive: boolean,
	setTab: (value: number) => void,
	tab: ICollectionTab,
	index: number,
}

export const CollectionTab = ({
	tab,
	index,
	setTab,
	isActive,
}: CollectionTabProps) => {
	const {
		picture,
		name,
		disabled,
	} = tab;

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (disabled) {
			e.preventDefault();
			return;
		}
		setTab(index);
	};

	return (
		<div
			className={cx(css.wrapper, isActive && css.active, disabled && css.disabled)}
			onClick={handleClick}
			role="presentation"
		>
			<div className={css.active_sign} />
			<div className={css.content}>
				{picture}
				<span>{name}</span>
			</div>
		</div>
	);
};
