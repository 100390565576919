import { useSelector } from 'react-redux';
import { FormEventHandler } from 'react';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { useCreateWorkshopTag } from '../../services/create-tag/useCreateWorkshopTag';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import css from './CreateWorkshopTagModal.module.scss';
import CreateInput from '../../../../components/pages/features/moduleWindow/createInput/CreateInput';
import Button, { buttonsTypes } from '../../../../components/ui/button/button';

export const CreateWorkshopTagModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopTagSlice.selectors.getIsCreateModalActive);
	const value = useSelector(workshopTagSlice.selectors.getCreateTagName);

	const { onCreate, requestData } = useCreateWorkshopTag();

	const handleValue = (value: string) => {
		dispatch(workshopTagActions.setCreateTagName(value));
	};

	const handleModal = (value: boolean) => {
		if (!value) handleValue('');
		dispatch(workshopTagSlice.actions.setIsCreateModalActive(value));
	};

	const isBtnActive = !!value.length && !requestData.isLoading;

	const createTag: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (isBtnActive) {
			await onCreate();
		}
	};

	return (
		<NewModal active={isActive} closeModal={handleModal}>
			<form className={css.wrapper} onSubmit={createTag}>
				<div className={css.title}>
					Создать тег
				</div>
				<CreateInput
					maxLength={20}
					text={value}
					subTitle="Введите название"
					defaultValue="Название тега"
					onChange={handleValue}
				/>
				<Button
					text="Создать"
					type="submit"
					buttonType={buttonsTypes.BRIGHT}
					cssClass={css.btn}
					inActive={isBtnActive}
				/>
			</form>
		</NewModal>
	);
};
