enum SpatialAreaType {
	GRAPHIC = 'GRAPHIC',

	// боковые, верхняя и нижняя линии графики
	GRAPHIC_RESIZE = 'GRAPHIC_RESIZE',

	// угловые точки графики
	GRAPHIC_ANGULAR_RESIZE = 'GRAPHIC_ANGULAR_RESIZE',

	PICTURE = 'PICTURE',

	// боковые, верхняя и нижняя линии картинки
	PICTURE_RESIZE = 'PICTURE_RESIZE',

	PICTURE_ANGULAR_RESIZE = 'PICTURE_ANGULAR_RESIZE',

	TABLE_COLUMN_RESIZE = 'TABLE_COLUMN_RESIZE',

	TABLE_ROW_RESIZE = 'TABLE_ROW_RESIZE',

	TABLE_CELL = 'TABLE_CELL',
}

export default SpatialAreaType;
