import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { workshopCategoryApi } from '../../../../entities/workshop-category';

export const useDeleteCategory = (id: number | null, closeModal: () => void) => {
	const [deleteCategory, { isLoading }] = workshopCategoryApi.useDeleteMutation();

	const onDeleteCategory = async () => {
		if (id) {
			try {
				await deleteCategory({ categoryId: id })
					.unwrap();
				closeModal();
				notificationSuccess('Категория успешно удалена!');
			} catch (e) {
				notificationError('Возникла ошибка при удалении категории...');
			}
		}
	};

	return {
		onDeleteCategory,
		isLoading,
	};
};
