import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleTagApi, workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useCreateWorkshopTag = () => {
	const dispatch = useAppDispatch();

	const [createTag, requestData] = workshopModuleTagApi.useCreateMutation();

	const name = useSelector(workshopTagSlice.selectors.getCreateTagName);

	const onCreate = async () => {
		try {
			if (name) {
				await createTag({ name }).unwrap();

				dispatch(workshopTagActions.setCreateTagName(''));
				dispatch(workshopTagActions.setIsCreateModalActive(false));
			}
		} catch (e) {
			notificationError('Возникла ошибка при создании тега...');
		}
	};

	return {
		onCreate,
		requestData,
	};
};
