import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IPublishedFilter, ITariffPermissionFilter, IWorkshopTemplate, IWorkshopTemplateState,
} from './types';
import { ITagData } from '../../../../modules/tag/api/types';

const initialState: IWorkshopTemplateState = {
	search: '',
	templates: [],
	selectedTags: [],
	selectedPublishUser: '',
	tariffPermissionFilter: {
		filter: [],
		permission: 0,
	},
	isDeleteModalActive: false,
	publishedFilter: undefined,
	activeWorkshopTemplate: null,
	isDuplicateModalActive: false,
	isFilterUserModalActive: false,
	templateForRename: null,
	selectedTemplate: [],
	workshopTemplateSelectedList: [],
	isWorkshopSelectionAreaActive: false,
};

export const workshopTemplateSlice = createSlice({
	name: 'workshopTemplate',
	initialState,
	reducers: {
		setWorkshopTemplates(state, action: PayloadAction<IWorkshopTemplate[]>) {
			state.templates = action.payload;
		},
		setWorkshopTemplatesSearch(state, action: PayloadAction<string>) {
			state.search = action.payload;
		},
		setWorkshopSelectedTags(state, action: PayloadAction<ITagData[]>) {
			state.selectedTags = action.payload;
		},
		setWorkshopSelectedPublishUser(state, action: PayloadAction<string>) {
			state.selectedPublishUser = action.payload;
		},
		setWorkshopDeleteModalActive(state, action: PayloadAction<boolean>) {
			state.isDeleteModalActive = action.payload;
		},
		setWorkshopDuplicateModalActive(state, action: PayloadAction<boolean>) {
			state.isDuplicateModalActive = action.payload;
		},
		setActiveWorkshopTemplate(state, action: PayloadAction<string | null>) {
			state.activeWorkshopTemplate = action.payload;
		},
		setPublishedFilter(state, action: PayloadAction<IPublishedFilter | undefined>) {
			state.publishedFilter = action.payload;
		},
		setWorkshopTariffPermissionFilter(state, action: PayloadAction<ITariffPermissionFilter>) {
			state.tariffPermissionFilter = action.payload;
		},
		setIsWorkshopFilterUserModalActive(state, action: PayloadAction<boolean>) {
			state.isFilterUserModalActive = action.payload;
		},
		setTemplateForRenaming(state, action: PayloadAction<string | null>) {
			state.templateForRename = action.payload;
		},
		setSelectedTemplate(state, action: PayloadAction<IWorkshopTemplate[]>) {
			state.selectedTemplate = action.payload;
		},
		setWorkshopTemplateSelectedList: (state, action: PayloadAction<string[]>) => {
			state.workshopTemplateSelectedList = action.payload;
		},
		addWorkshopTemplateSelectedList: (state, action: PayloadAction<string>) => {
			if (!state.workshopTemplateSelectedList.includes(action.payload)) {
				state.workshopTemplateSelectedList = [...state.workshopTemplateSelectedList, action.payload];
			}
		},
		removeWorkshopTemplateSelectedList: (state, action: PayloadAction<string>) => {
			state.workshopTemplateSelectedList = state.workshopTemplateSelectedList.filter(
				elem => elem !== action.payload,
			);
		},
		setIsWorkshopSelectionAreaActive: (state, action: PayloadAction<boolean>) => {
			state.isWorkshopSelectionAreaActive = action.payload;
		},
	},
});

export const {
	setPublishedFilter,
	setWorkshopTemplates,
	setWorkshopSelectedTags,
	setActiveWorkshopTemplate,
	setWorkshopTemplatesSearch,
	setWorkshopDeleteModalActive,
	setWorkshopSelectedPublishUser,
	setWorkshopDuplicateModalActive,
	setWorkshopTariffPermissionFilter,
	setIsWorkshopFilterUserModalActive,
	setTemplateForRenaming,
	setSelectedTemplate,
	setWorkshopTemplateSelectedList,
	addWorkshopTemplateSelectedList,
	removeWorkshopTemplateSelectedList,
	setIsWorkshopSelectionAreaActive,
} = workshopTemplateSlice.actions;

export default workshopTemplateSlice.reducer;
