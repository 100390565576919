import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICollectionTab } from '../ui/tab/model/types';

export const useTabs = (data: ICollectionTab[]) => {
	const [currentTab, setCurrentTab] = useState<number | undefined>(undefined);

	const navigate = useNavigate();
	const { pathname } = useLocation();
	
	const onSetCurrentTab = (value: number) => {
		setCurrentTab(value);

		if (!data[value].link) return;

		navigate(`${data[value].link}*`);
	};

	useEffect(() => {
		for (let i = 0; i < data.length; i++) {
			const tab = data[i];

			if (pathname.includes(tab.link)) {
				setCurrentTab(i);
				break;
			} else {
				setCurrentTab(undefined);
			}
		}
	}, [pathname]);

	return {
		currentTab,
		onSetCurrentTab,
	};
};
