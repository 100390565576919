import React from 'react';
import css from './empty-list.module.scss';
import { ReactComponent as EmptyListIcon } from '../images/empty-list.svg';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import { CreateButton } from '../../../../shared/ui';

interface EmptyPrivateListProps {
	title: string | React.ReactNode,
	description: string | React.ReactNode,
	onClick: () => void,
	btnText: string,
	isCreateBtn: boolean,
	isNeedProcessing?: boolean
}

export const EmptyPlugList = ({
	title,
	description,
	isCreateBtn,
	isNeedProcessing = false,
	onClick,
	btnText,
}: EmptyPrivateListProps) => (
	<div className={css.empty_template_box}>
		<EmptyListIcon />
		<h4 className={css.title}>{title}</h4>
		<p className={css.description}>{description}</p>
		{
			isCreateBtn
				? (
					<CreateButton disabled={isNeedProcessing} onClick={onClick}>
						{btnText}
					</CreateButton>
				)
				: (
					<MainButton
						btnStyle="bright"
						type="button"
						onClick={onClick}
						disabled={isNeedProcessing}
					>
						{btnText}
					</MainButton>
				)
		}
	</div>
);
