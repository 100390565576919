export type TMeasureElemWidthStyles = { [key: string]: string }

export const measureElemWidth = (text: string, styles?: TMeasureElemWidthStyles, additionalDistance = 0) => {
	// * В этой функции можно измерить ширину каждого тега,
	// * Создав временный элемент и получив его ширину.

	// * Создать временный элемент для измерения ширины
	const tempElement = document.createElement("span");
	tempElement.textContent = text;

	tempElement.style.fontSize = "14px";
	tempElement.style.visibility = "hidden";
	Object.assign(tempElement.style, styles);

	document.body.appendChild(tempElement);

	// * Получить ширину элемента
	const width = tempElement.offsetWidth + additionalDistance;

	// * Удалить временный элемент
	document.body.removeChild(tempElement);

	return width;
};
