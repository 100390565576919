import React from 'react';
import css from './empty-list.module.scss';
import { ReactComponent as EmptyListIcon } from './images/empty-list.svg';
import { ReactComponent as PlusIcon } from './images/blue-plus.svg';

interface EmptyListProps {
	title: string;
	onBtnClick?: () => void;
	btnText?: string,
	needBtn: boolean,
}

export const EmptyList = ({
	onBtnClick,
	btnText,
	title,
	needBtn,
}: EmptyListProps) => (
	<div className={css.wrapper}>
		<div className={css.content}>
			<EmptyListIcon />
			<h4 className={css.title}>{title}</h4>
			{
				needBtn && (
					<button className={css.btn} type="button" onClick={onBtnClick}>
						<PlusIcon />
						<span>{btnText}</span>
					</button>
				)
			}
		</div>
	</div>
);
