import ElementContainer from '../../../utils/ElementContainer';
import DirectionVector from '../DirectionVector';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import HTMLElementName from '../../../utils/HTMLElementName';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';

/**
 * Базовый абстрактный класс для магнитных линий.
 */
abstract class MagneticLine extends ElementContainer<HTMLDivElement> {
	private readonly ELEMENT_CLASS_NAME = 'magnetic-line';

	private readonly direction: DirectionVector;
	private readonly position: IDescartesPosition;
	private readonly parentElement: HTMLElement;

	/**
	 * Создает новый экземпляр MagneticLine.
	 * @param direction - вектор направления линии.
	 * @param parentElement - HTML элемент, в который будет добавлена линия при её визуализации.
	 */
	protected constructor(direction: DirectionVector, parentElement: HTMLElement) {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.direction = direction;
		this.position = {
			x: 0,
			y: 0,
		};
		this.parentElement = parentElement;
		this.parentElement.append(this.rootElement);
	}

	/**
	 * Показывает магнитную линию.
	 */
	public show = () => {
		this.rootElement.classList.add('show');
	};

	/**
	 * Скрывает магнитную линию.
	 */
	public hide = () => {
		this.rootElement.classList.remove('show');
	};

	public remove = () => {
		this.rootElement.remove();
	};

	public getDirection = () => this.direction;
	public getPosition = (): IDescartesPosition => ({ ...this.position });

	/**
	 * Устанавливает расположение и размеры магнитной линии.
	 * @param x - координата X начальной точки линии.
	 * @param y - координата Y начальной точки линии.
	 * @param length - длина линии.
	 */
	protected setLocation = (x: number, y: number, length: number) => {
		this.setConfiguration({
			x,
			y,
			width:
				this.direction === DirectionVector.BOTTOM
				|| this.direction === DirectionVector.MIDDLE_X
				|| this.direction === DirectionVector.TOP
					? length
					: 1,
			height:
				this.direction === DirectionVector.LEFT
				|| this.direction === DirectionVector.MIDDLE_Y
				|| this.direction === DirectionVector.RIGHT
					? length
					: 1,
			rotate: 0,
		});
	};

	/**
	 * Устанавливает конфигурацию магнитной линии.
	 * @param configuration - применяемая конфигурация к линии.
	 */
	private setConfiguration = (configuration: IFrameArea) => {
		this.position.x = configuration.x;
		this.position.y = configuration.y;
		this.rootElement.style.left = `${configuration.x}px`;
		this.rootElement.style.top = `${configuration.y}px`;
		this.rootElement.style.width = `${configuration.width}px`;
		this.rootElement.style.height = `${configuration.height}px`;
	};
}

export default MagneticLine;
