import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAiScheme } from '../types/IAiScheme';
import { TextCategories } from '../types/types';

const initialState: IAiScheme = {
	categories: [
		{
			title:	TextCategories.GREETINGS,
			childs: null,
		},
		{
			title:	TextCategories.PRODUCT_DESCRIPTION,
			childs: null,
		},
		{
			title:	TextCategories.ADVANTAGES,
			childs: [
				{
					title:	TextCategories.ADVANTAGES_LIST,
					childs: null,
				},
				{
					title:	TextCategories.ADVANTAGES_DESCRIPTION,
					childs: null,
				},
			],
		},
		{
			title:	TextCategories.TEAM,
			childs: null,
		},
		{
			title:	TextCategories.HEADER,
			childs: [
				{
					title: TextCategories.SLOGAN,
					childs: null,
				},
			],
		},
		{
			title:	TextCategories.ABOUT_COMPANY,
			childs: null,
		},
		{
			title:	TextCategories.UNIQUE_PROPOSITION,
			childs: null,
		},
		{
			title:	TextCategories.WORK_STAGES,
			childs: null,
		},
	],
	modalsActive: {
		isAiCategoriesModalOpen: false, // Главная модалка для генерации текстовых промптов
		isGreetingsOpen: false, // Приветствие
		isProductDescriptionOpen: false, // Описание товара или услуги
		isAdvantagesListOpen: false, // Список преимуществ
		isAdvantagesDescriptionOpen: false, // Описание преимуществ
		isTeamOpen: false, // Команда
		isHeaderOpen: false, // Шапка
		isAboutCompanyOpen: false, // О компании
		isUniquePropositionOpen: false, // Уникальное торговое предложение
		isWorkStagesOpen: false, // Этапы работы
	},
	responses: {
		greetings: [],
		productDescription: [],
		advantagesList: [],
		advantagesDescription: [],
		team: [],
		slogan: [],
		aboutCompany: [],
		uniqueProposition: [],
		workStages: [],
	},
	onCloseAiModalFunc: null,
	aiMode: false,
	availableGenerationQty: null,
	generationLimitQty: null,
	resetTime: null,
};

export const aiSlice = createSlice({
	name: 'ai',
	initialState,
	selectors: {
		// Режим отображения модалок генерации
		getAiMode: (state) => state.aiMode,

		// Категории текстовой генерации
		getCategories: (state) => state.categories,
		// Модалка текстовых категорий
		getIsAiCategoriesModalActive: (state) => state.modalsActive.isAiCategoriesModalOpen,
		// Модалка Приветствие
		getIsGreetingsOpen: (state) => state.modalsActive.isGreetingsOpen,
		// Модалка Описание товара или услуги
		getIsProductDescriptionOpen: (state) => state.modalsActive.isProductDescriptionOpen,
		// Модалка Список преимуществ
		getIsAdvantagesListOpen: (state) => state.modalsActive.isAdvantagesListOpen,
		// Модалка Описание преимуществ
		getIsAdvantagesDescriptionOpen: (state) => state.modalsActive.isAdvantagesDescriptionOpen,
		// Модалка Команда
		getIsTeamOpen: (state) => state.modalsActive.isTeamOpen,
		// Модалка Шапка
		getSloganOpen: (state) => state.modalsActive.isHeaderOpen,
		// Модалка О компании
		getIsAboutCompanyOpen: (state) => state.modalsActive.isAboutCompanyOpen,
		// Модалка Уникальное торговое предложение
		getIsUniquePropositionOpen: (state) => state.modalsActive.isUniquePropositionOpen,
		// Модалка Этапы работы
		getIsWorkStagesOpen: (state) => state.modalsActive.isWorkStagesOpen,

		// Сгенерированные ответы
		getGreetingsResponses: (state) => state.responses.greetings,
		getProductDescriptionResponses: (state) => state.responses.productDescription,
		getAdvantagesListResponses: (state) => state.responses.advantagesList,
		getAdvantagesDescriptionResponses: (state) => state.responses.advantagesDescription,
		getTeamResponses: (state) => state.responses.team,
		getSloganResponses: (state) => state.responses.slogan,
		getAboutCompanyResponses: (state) => state.responses.aboutCompany,
		getUniquePropositionResponses: (state) => state.responses.uniqueProposition,
		getWorkStagesResponses: (state) => state.responses.workStages,

		getOnCloseAiModalFunc: (state) => state.onCloseAiModalFunc,
	},
	reducers: {
		setAiMode: (state, action: PayloadAction<boolean>) => {
			state.aiMode = action.payload;
		},
		setIsAiCategoriesModalOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAiCategoriesModalOpen = action.payload;
		},
		setIsGreetingsOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isGreetingsOpen = action.payload;
			state.aiMode = action.payload;
		},
		setIsProductDescriptionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isProductDescriptionOpen = action.payload;
			state.aiMode = action.payload;
		},
		setAdvantagesListOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAdvantagesListOpen = action.payload;
			state.aiMode = action.payload;
		},
		setAdvantagesDescriptionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAdvantagesDescriptionOpen = action.payload;
			state.aiMode = action.payload;
		},
		setTeamOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isTeamOpen = action.payload;
			state.aiMode = action.payload;
		},
		setSloganOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isHeaderOpen = action.payload;
			state.aiMode = action.payload;
		},
		setAboutCompanyOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isAboutCompanyOpen = action.payload;
			state.aiMode = action.payload;
		},
		setUniquePropositionOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isUniquePropositionOpen = action.payload;
			state.aiMode = action.payload;
		},
		setWorkStagesOpen(state, action: PayloadAction<boolean>) {
			state.modalsActive.isWorkStagesOpen = action.payload;
			state.aiMode = action.payload;
		},

		// 	Записать ответы нейросети
		setGreetingsResponses(state, action: PayloadAction<string>) {
			state.responses.greetings.unshift(action.payload);
		},
		setProductDescriptionResponses(state, action: PayloadAction<string>) {
			state.responses.productDescription.unshift(action.payload);
		},
		setAdvantagesListResponses(state, action: PayloadAction<string>) {
			state.responses.advantagesList.unshift(action.payload);
		},
		setAdvantagesDescriptionResponses(state, action: PayloadAction<string>) {
			state.responses.advantagesDescription.unshift(action.payload);
		},
		setTeamResponses(state, action: PayloadAction<string>) {
			state.responses.team.unshift(action.payload);
		},
		setSloganResponses(state, action: PayloadAction<string>) {
			state.responses.slogan.unshift(action.payload);
		},
		setAboutCompanyResponses(state, action: PayloadAction<string>) {
			state.responses.aboutCompany.unshift(action.payload);
		},
		setUniquePropositionResponses(state, action: PayloadAction<string>) {
			state.responses.uniqueProposition.unshift(action.payload);
		},
		setWorkStagesResponses(state, action: PayloadAction<string>) {
			state.responses.workStages.unshift(action.payload);
		},

		setCloseAiModalFunc: (state, action: PayloadAction<() => void>) => {
			state.onCloseAiModalFunc = action.payload;
		},

		// Обнулить все ответы
		resetAllResponses(state) {
			state.responses.greetings = [];
			state.responses.productDescription = [];
			state.responses.advantagesList = [];
			state.responses.advantagesDescription = [];
			state.responses.team = [];
			state.responses.slogan = [];
			state.responses.aboutCompany = [];
			state.responses.uniqueProposition = [];
			state.responses.workStages = [];
		},

		closeAllModals(state) {
			state.modalsActive.isAiCategoriesModalOpen = false;
			state.modalsActive.isGreetingsOpen = false;
			state.modalsActive.isProductDescriptionOpen = false;
			state.modalsActive.isAdvantagesListOpen = false;
			state.modalsActive.isAdvantagesDescriptionOpen = false;
			state.modalsActive.isTeamOpen = false;
			state.modalsActive.isHeaderOpen = false;
			state.modalsActive.isAboutCompanyOpen = false;
			state.modalsActive.isUniquePropositionOpen = false;
			state.modalsActive.isWorkStagesOpen = false;
		},
	},
});

export const {
	setIsAiCategoriesModalOpen,
	setIsGreetingsOpen,
	setIsProductDescriptionOpen,
	setAdvantagesListOpen,
	setAdvantagesDescriptionOpen,
	setTeamOpen,
	setSloganOpen,
	setAboutCompanyOpen,
	setUniquePropositionOpen,
	setWorkStagesOpen,
	setGreetingsResponses,
	setProductDescriptionResponses,
	setWorkStagesResponses,
	setAdvantagesDescriptionResponses,
	setAdvantagesListResponses,
	setAboutCompanyResponses,
	setSloganResponses,
	setUniquePropositionResponses,
	setTeamResponses,
	resetAllResponses,
	setCloseAiModalFunc,
	closeAllModals,
	setAiMode,
} = aiSlice.actions;
export const {
	getCategories,
	getIsGreetingsOpen,
	getIsProductDescriptionOpen,
	getIsAdvantagesListOpen,
	getIsAdvantagesDescriptionOpen,
	getIsTeamOpen,
	getSloganOpen,
	getIsAboutCompanyOpen,
	getIsUniquePropositionOpen,
	getIsWorkStagesOpen,
	getAdvantagesDescriptionResponses,
	getAdvantagesListResponses,
	getAboutCompanyResponses,
	getProductDescriptionResponses,
	getUniquePropositionResponses,
	getGreetingsResponses,
	getSloganResponses,
	getWorkStagesResponses,
	getTeamResponses,
	getOnCloseAiModalFunc,
	getAiMode,
} = aiSlice.selectors;

export const { actions: aiSliceActions, reducer } = aiSlice;

export default aiSlice.reducer;
