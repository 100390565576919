import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordRegistration from './PasswordRegistration';
import { useInput } from '../../../../hooks/useInput';
import { PATH_AUTH_SUCCESS } from '../authorization/Authorization';
import { notificationError } from '../../../Notifications/callNotifcation';
import RedirectPage from '../redirectPage/RedirectPage';
import { authAPI } from '../../../../entities/user/auth/api/api';
import { useAppDispatch } from '../../../../shared/libs/redux';
import {
	setAccount,
	setAccountAuthorized,
	setAccountSessionId,
} from '../../../../redux/reducers/personalAccountReducer';
import { ISignInRequest } from '../../../../entities/user/auth/api/types';

interface PasswordRegistrationContainerProps {
	email: string,
}

const PasswordRegistrationContainer = ({ email }: PasswordRegistrationContainerProps) => {
	const password = useInput('', {
		isEmpty: true,
		minLength: 15,
		maxLength: 25,
	});

	const [isPasswordHide, setIsPasswordHide] = useState(true);
	const [redirectCounter, setRedirectCounter] = useState(3);
	const [isRedirecting, setIsRedirecting] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const passwordData: ISignInRequest = {
		email,
		password: password.value,
	};

	const counterFunc = () => {
		setIsRedirecting(true);
		const timer = setInterval(
			() => setRedirectCounter(
				redirectCounter => redirectCounter - 1,
			),
			1000,
		);
		setTimeout(() => {
			clearInterval(timer);
			dispatch(setAccountAuthorized(true));
			navigate(PATH_AUTH_SUCCESS);
		}, 3000);
	};

	const [signIn, { isLoading }] = authAPI.useSignInMutation();

	const onSignIn = async () => {
		try {
			const res = await signIn(passwordData)
				.unwrap();

			const {
				account,
				session,
			} = res;
			
			dispatch(setAccount(account));
			dispatch(setAccountSessionId(session));

			counterFunc();

			sessionStorage.setItem('authorization', session);
			sessionStorage.setItem('isCookieAccepted', 'true');
		} catch (error) {
			notificationError('Ошибка!', 'Произошла ошибка при регистрации...');
		}
	};

	const handleKeyPressSendPassword = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter' && password.inputValid) {
			await onSignIn();
		}
	};

	const handlePasswordVisibility = () => {
		setIsPasswordHide(!isPasswordHide);
	};

	if (isRedirecting) return <RedirectPage redirectCounter={redirectCounter} />;

	return (
		<PasswordRegistration
			password={password}
			isPasswordHide={isPasswordHide}
			handlePasswordVisibility={handlePasswordVisibility}
			handleKeyPressSendPassword={handleKeyPressSendPassword}
			onSignIn={onSignIn}
			isDataLoading={isLoading}
		/>
	);
};

export default PasswordRegistrationContainer;
