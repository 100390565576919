import React from 'react';
import { PossibleItem } from '../../../../my/item/TemplateMyItem';
import TransitionActionsWithCards, {
	IActionsWithCardsItem,
} from '../../../../../../../../ui/actionsWithCards/TransitionActionsWithCards';
import { ReactComponent as MoveIcon } from '../../../../../../../../../shared/images/move.svg';
import css from '../../../../private/ui/private-template/context-menu/context-menu.module.scss';
import actionsDependsPossible from '../../../../../../../../../helpers/actionsDependsPossible';

const TEMPLATE_MY_POSSIBILITIES: PossibleItem = {
	move: true,
	copy: false,
	delete: false,
};

interface GalleryTemplateContextMenuProps {
	onMoveTemplate: () => void,
	isActionMenuOpened: boolean,
	menuPosition: { x: number; y: number },
	menuRef: React.RefObject<HTMLDivElement>,
	setIsActionMenuOpened: (value: boolean) => void,
}

export const GalleryTemplateContextMenu = ({
	menuRef,
	menuPosition,
	onMoveTemplate,
	isActionMenuOpened,
	setIsActionMenuOpened,
}: GalleryTemplateContextMenuProps) => {
	const onCloseMenu = () => setIsActionMenuOpened(false);

	const actionsWithCardsItems: IActionsWithCardsItem[] = [
		{
			text: 'Переместить в «Мои шаблоны»',
			smile: <MoveIcon />,
			onClick: onMoveTemplate,
		},
	];
	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				left: menuPosition.x,
				top: menuPosition.y,
				display: 'inline-block',
				zIndex: 100,
			}}
		>
			<TransitionActionsWithCards
				ref={menuRef}
				onClose={onCloseMenu}
				isActive={isActionMenuOpened}
				cssClassMain={css.cards_actions}
				items={actionsDependsPossible(TEMPLATE_MY_POSSIBILITIES, actionsWithCardsItems)}
			/>
		</div>
	);
};
