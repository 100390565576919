import ManipulatorCommand from '../ManipulatorCommand';
import CommandType from '../CommandType';
import IComponentAppendBody from './IComponentAppendBody';
import IComponent from '../../../components/IComponent';
import IComponentTreeMutator from '../../../component-tree/IComponentTreeMutator';

class ComponentAppendCommand extends ManipulatorCommand<IComponentAppendBody> {
	public type: CommandType = CommandType.APPEND_COMPONENT;

	private readonly sketchID: string;
	private readonly parent: IComponent;
	private readonly component: IComponent;
	private readonly componentTreeMutator: IComponentTreeMutator;

	constructor(
		componentTreeMutator: IComponentTreeMutator,
		component: IComponent,
		parent: IComponent,
		sketchID: string,
	) {
		super();
		this.parent = parent;
		this.sketchID = sketchID;
		this.component = component;
		this.componentTreeMutator = componentTreeMutator;
	}

	public exec = (): void => {
		this.componentTreeMutator.executeMutations(tools => {
			tools.mutator.mutateByAppendComponent(this.parent, this.component);
		});
	};

	protected buildBody = (): IComponentAppendBody => {
		const { id } = this.parent.getStructure();
		const structure = this.component.getStructure();

		return {
			sketch: this.sketchID,
			parent: id,
			structure,
		};
	};
}

export default ComponentAppendCommand;
