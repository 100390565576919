import React, { ChangeEventHandler, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as FindIcon } from '../../../assets/find-icon.svg';
import css from './FilterModulesSearchGroups.module.scss';
import { rightAppearanceTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';
import { classNames } from '../../../../../shared/libs/classNames';

interface FilterModulesSearchGroupsProps {
	show?: boolean,
	setShow?: (value: boolean) => void,
	value?: string,
	setValue?: (value: string) => void,
}

const TIMEOUT_MS = 200;

export const FilterModulesSearchGroups = ({
	show,
	setShow,
	setValue,
	value,
}: FilterModulesSearchGroupsProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const onFindIconClick = () => {
		if (!show) {
			setShow?.(true);
		}
	};

	const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
		const { value } = e.target;

		setValue?.(value);
	};

	useEffect(() => {
		if (show) {
			const focusing = () => inputRef.current?.focus();

			setTimeout(focusing, TIMEOUT_MS);
		}
	}, [show]);

	return (
		<div className={css.search_block}>
			<CSSTransition
				timeout={TIMEOUT_MS}
				unmountOnExit
				nodeRef={inputRef}
				in={show}
				classNames={rightAppearanceTransitionClasses}
			>
				<input
					ref={inputRef}
					value={value}
					className={css.input}
					onChange={handleSearch}
				/>
			</CSSTransition>
			<div
				role="presentation"
				onClick={onFindIconClick}
				className={classNames(css.find_icon, { [css.active_icon]: value || show })}
			>
				<FindIcon />
			</div>
		</div>
	);
};
