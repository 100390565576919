import { classNames } from '../../../../../shared/libs/classNames';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { workshopModuleActions } from '../../../../../entities/workshop-module';

interface PublishWorkshopModuleButtonProps {
	className?: string,
	moduleId?: string,
}

export const PublishWorkshopModuleButton = ({ className, moduleId }: PublishWorkshopModuleButtonProps) => {
	const dispatch = useAppDispatch();

	const showPublishModal = () => {
		dispatch(workshopModuleActions.setIsPublishingModuleModalActive(true));
		dispatch(workshopModuleActions.setPublishingModuleId(moduleId));
	};

	return (
		<div className={classNames('', {}, [className])}>
			<MainButton btnStyle="bright" onClick={showPublishModal}>
				Опубликовать в галерее
			</MainButton>
		</div>
	);
};
