import { renderCountWord } from '../../../../helpers/renderCountWord';

export const setTransactionHistoryDuration = (durationInMinutes: number) => {
	const days = Math.floor(durationInMinutes / (60 * 24));
	const remainingMinutes = durationInMinutes % (60 * 24);
	const hours = Math.floor(remainingMinutes / 60);
	const minutes = remainingMinutes % 60;

	const daysWord = renderCountWord(days, ['день', 'дня', 'дней']);
	const hoursWord = renderCountWord(hours, ['час', 'часа', 'часов']);
	const minutesWord = renderCountWord(minutes, ['минута', 'минуты', 'минут']);

	return `${daysWord} ${hoursWord} ${minutesWord}`;
};
