import AreaMutator from '../AreaMutator';
import IAreaColumnMutationBody from '../events/column/IAreaColumnMutationBody';
import AreaMutationEventType from '../events/AreaMutationEventType';
import { AnySpatialArea } from '../../../../Types';
import IFrameArea from '../../spatial-tree/spatial-area/IFrameArea';
import IMutableEntity from '../IMutableEntity';

class AreaTableColumnMutator extends AreaMutator<IAreaColumnMutationBody> {
	constructor() {
		super(AreaMutationEventType.COLUMN);
		this.disableBlindMode();
	}

	protected updateOffsets = (offsetX: number, offsetY: number): void => {
		if (this.absoluteAreaOffsets === null) {
			return;
		}

		this.absoluteAreaOffsets.x += offsetX;
	};

	public postStartEvent = (areas: AnySpatialArea[]): void => {
		// nothing
	};

	public postStopEvent = (): void => {
		// nothing
	};

	protected getMutationEventBody = (): IAreaColumnMutationBody => ({});

	protected correctAspectRatioValues = (
		currentValues: IFrameArea,
		entity: IMutableEntity,
	): IFrameArea => currentValues;
}

export default AreaTableColumnMutator;
