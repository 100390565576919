import React from 'react';
import Button, { buttonsTypes } from '../ui/button/button';
import css from './styles/notifications.module.scss';

interface NotificationsProps {
    title: string,
	filled?: boolean,
    btnText?: string,
    description: string,
    buttonTypes?: buttonsTypes,
    children?: React.ReactNode,
    btnOnClick?: () => null | void,
}

const Notifications: React.FC<NotificationsProps> = ({
	title,
	filled,
	btnText,
	children,
	description,
	btnOnClick = () => null,
	buttonTypes = buttonsTypes.LIGHT,
}: NotificationsProps) => {
	Notifications.defaultProps = {
		btnText: undefined,
		buttonTypes: undefined,
		btnOnClick: undefined,
		filled: false,
		children: null,
	};

	return (
		<div className={css.wrapper}>
			<div className={css.picture}>
				{children}
			</div>
			<div className={css.content}>
				{
					!!title && (
						<div
							className={css.title}
							style={filled ? { color: '#fff' } : { }}
						>
							{title}
						</div>
					)
				}
				{
					!!description && (
						<div
							className={css.description}
							style={filled ? { color: '#fff' } : { }}
						>
							{description}
						</div>
					)
				}
				<div>
					{
						btnText && (
							<Button
								text={btnText}
								buttonType={buttonTypes}
								onClick={btnOnClick}
							/>
						)
					}
				</div>
			</div>
		</div>
	);
};

export default Notifications;
