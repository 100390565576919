import { workshopModuleTagApi } from '../../../../entities/workshop-tag';

export const useCreateWorkshopModuleTag = () => {
	const [createTag] = workshopModuleTagApi.useCreateMutation();

	const onCreateTag = async (name: string) => {
		const res = await createTag({ name }).unwrap();

		return res.moduleTag;
	};

	return { onCreateTag };
};
