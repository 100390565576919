import React from 'react';
import { CreateUserModal } from '../../../../../../widgets/modals/create-user';
import { useAddManager } from '../../../lib/useAddManager';
import { useAppSelector } from '../../../../../../shared/libs/redux';

const CreateManagerModal = () => {
	const isCreateManagerModalActive = useAppSelector(state => state.legalEntity.isCreateManagerModalActive);

	const {
		createManagerPhone,
		createManagerName,
		handleCreateManagerModal,
		createManagerEmail,
		onAddManager,
		isLoading,
	} = useAddManager();

	return (
		<CreateUserModal
			name={createManagerName}
			email={createManagerEmail}
			phone={createManagerPhone}
			isActive={isCreateManagerModalActive}
			onCreating={onAddManager}
			setActive={handleCreateManagerModal}
			title="Создать менеджера"
			isDataLoading={isLoading}
		/>
	);
};

export default CreateManagerModal;
