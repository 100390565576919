import { IAccount } from '../../../../entities/user/account/api/types';
import {
	ScrollableFilterContent,
} from '../../../dropdown-filter/ui/scrollable-filter-content/scrollable-filter-content';
import { ISelectAbleCardData, SelectableFilterCard } from '../../../../shared/ui';

interface SelectableUserListProps {
	users: IAccount[],
	selectedUser: string,
	onSelect: (user: IAccount) => void,
}

export const SelectableUserList = ({
	users,
	onSelect,
	selectedUser,
}: SelectableUserListProps) => (
	<ScrollableFilterContent height={240} emptyData={users.length === 0}>
		{
			users?.map(user => {
				const {
					photo,
					email,
					name,
				} = user;
				const data: ISelectAbleCardData = {
					photo,
					id: email,
					text: name,
				};
				return (
					<SelectableFilterCard
						data={data}
						key={email}
						onSelect={() => onSelect(user)}
						selectedType="filled"
						selected={selectedUser === email}
					/>
				);
			})
		}
	</ScrollableFilterContent>
);
