import { useEffect, useState } from 'react';
import { measureElemWidth, TMeasureElemWidthStyles } from '../helpers/measure-elem-width';
import { ITagData } from '../entities/modules/tag/api/types';

const TEMP_ELEM_STYLES: TMeasureElemWidthStyles = {
	fontSize: '12px',
	padding: '4px 10px',
};
export const useListOverflow = (
	templateTags: ITagData[],
	width: number,
) => {
	const [visibleTags, setVisibleTags] = useState<ITagData[]>([]);
	const [hiddenTagsCount, setHiddenTagsCount] = useState(0);

	useEffect(() => {
		const updateVisibleTags = () => {
			// * totalWidth не равна 0, так как после тегов будет еще div
			// * в котором будет число скрытых тегов
			let totalWidth = 30;
			const visibleTagsArray: ITagData[] = [];
			let hiddenCount = 0;

			templateTags.forEach(tag => {
				const tagWidth = measureElemWidth(tag.name, TEMP_ELEM_STYLES, 8);
				totalWidth += tagWidth;
				if (width && totalWidth <= width) {
					visibleTagsArray.push(tag);
				} else {
					hiddenCount++;
				}
			});
			setVisibleTags(visibleTagsArray);
			setHiddenTagsCount(hiddenCount);
		};

		updateVisibleTags();
	}, [templateTags]);

	return {
		visibleTags,
		hiddenTagsCount,
	};
};
