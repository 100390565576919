import { createApi } from '@reduxjs/toolkit/query/react';
import { IGetTemplateModuleCategoriesRequest, IGetTemplateModuleCategoriesResponse } from '../model/types/types';
import { baseQuery } from '../../../shared/api/api';

const URI_TEMPLATE_MODULE_CATEGORY = '/template/module/category';

export const templateModuleCategoryApi = createApi({
	reducerPath: 'templateGalleryModule',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['templateGalleryModule'],
	endpoints: builder => ({
		getCategories: builder.query<
			IGetTemplateModuleCategoriesResponse,
			IGetTemplateModuleCategoriesRequest
		>({
			query: (data) => ({
				url: URI_TEMPLATE_MODULE_CATEGORY,
				method: 'GET',
				params: data,
			}),
			providesTags: ['templateGalleryModule'],
		}),
		createFavoriteCategory: builder.mutation<void, { categoryId: number }>({
			query: ({ categoryId }) => ({
				url: `${URI_TEMPLATE_MODULE_CATEGORY}/${categoryId}/favorite`,
				method: 'POST',
			}),
			async onQueryStarted(
				// 1 параметр: QueryArg - аргументы, которые приходят в query
				id,
				// 2 параметр: MutationLifecycleApi - dispatch, queryFulfilled, getState и пр.
				{ dispatch, queryFulfilled },
			) {
				const patchResult = dispatch(
					templateModuleCategoryApi.util.updateQueryData(
						// 1 параметр: endpointName, который мы
						// выполняем после удачного первого запроса (invalidatesTags)
						'getCategories',
						// 2 параметр: QueryArgFrom - параметры, которые приходят в endpoint выше
						{ limit: 127, page: 1 },
						// 3 параметр: Коллбек функция.
						// В данном блоке мы делаем логику, которая должна выполниться синхронно,
						// без необходимости дожидаться ответа от сервера.
						// Говоря проще пишем здесь логику, которую раньше писали в редьюсере,
						// чтобы изменять стейт
						(draft) => {
							const index = draft?.moduleCategories?.findIndex(
								(card) => card.id === id.categoryId,
							);
							if (index !== -1) draft?.moduleCategories?.splice(index, 1);
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
			invalidatesTags: ['templateGalleryModule'],
		}),
		deleteFavoriteCategory: builder.mutation<void, { categoryId: number }>({
			query: ({ categoryId }) => ({
				url: `${URI_TEMPLATE_MODULE_CATEGORY}/${categoryId}/favorite`,
				method: 'DELETE',
			}),
			async onQueryStarted(
				// 1 параметр: QueryArg - аргументы, которые приходят в query
				id,
				// 2 параметр: MutationLifecycleApi - dispatch, queryFulfilled, getState и пр.
				{ dispatch, queryFulfilled },
			) {
				const patchResult = dispatch(
					templateModuleCategoryApi.util.updateQueryData(
						// 1 параметр: endpointName, который мы
						// выполняем после удачного первого запроса (invalidatesTags)
						'getCategories',
						// 2 параметр: QueryArgFrom - параметры, которые приходят в endpoint выше
						{ limit: 127, page: 1 },
						// 3 параметр: Коллбек функция.
						// В данном блоке мы делаем логику, которая должна выполниться синхронно,
						// без необходимости дожидаться ответа от сервера.
						// Говоря проще пишем здесь логику, которую раньше писали в редьюсере,
						// чтобы изменять стейт
						(draft) => {
							const index = draft?.moduleCategories?.findIndex(
								(card) => card.id === id.categoryId,
							);
							if (index !== -1) draft?.moduleCategories?.splice(index, 1);
						},
					),
				);
				try {
					await queryFulfilled;
				} catch {
					patchResult.undo();
				}
			},
			invalidatesTags: ['templateGalleryModule'],
		}),
	}),
});
