import React, { useState } from 'react';
import { Title } from '../../../../ui/title';
import { AdminUsersSearchBar } from './search-bar/search-bar';
import { AdminUsersList } from './users-list/users-list';
import { adminUsersFilterData, IAdminUsersFilterItem } from '../models/filter-data';
import { IFilterItem } from '../../../../../widgets/filter/model/types';
import { useDebounce } from '../../../../../shared/libs/useDebounce';

export const AdminUsers = () => {
	const [search, setSearch] = useState('');
	const [filter, setFilter] = useState<IAdminUsersFilterItem>(adminUsersFilterData[0]);

	const debounceSearch = useDebounce(search.trim(), 200);

	const onSetFilter = (filter: IFilterItem) => setFilter(filter as IAdminUsersFilterItem);

	return (
		<div>
			<Title underlined>Пользователи</Title>
			<AdminUsersSearchBar search={search} filter={filter} setSearch={setSearch} setFilter={onSetFilter} />
			<AdminUsersList search={debounceSearch} filter={filter} />
		</div>
	);
};
