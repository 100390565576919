import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { templatePrivateAPI } from '../../../../../../../entities/templates/private/api/api';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { useCleanTemplateSelection } from '../../../../../../../hooks/template-selection/useCleanTemplateSelection';
import { setPrivateTemplateSelectedList } from '../../../../../../../entities/templates/private/model/slice';

export const useDuplicateTemplates = () => {
	const dispatch = useAppDispatch();

	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const [duplicate] = templatePrivateAPI.useDuplicateMutation();

	const cleanSelectedList = () => dispatch(setPrivateTemplateSelectedList([]));

	const { cleanTemplateSelection } = useCleanTemplateSelection();

	const duplicateTemplates = async () => {
		if (selectedList) {
			try {
				await duplicate({ templates: selectedList })
					.unwrap();
				if (selectedList.length === 1) {
					notificationSuccess('Шаблон успешно скопирован!');
				} else if (selectedList.length > 1) {
					notificationSuccess('Шаблоны успешно скопированы!');
				}
			} catch (e) {
				notificationError('Шаблон', 'Ошибка при копировании шаблона...');
			} finally {
				cleanTemplateSelection();
				cleanSelectedList();
			}
		}
	};

	return { duplicateTemplates };
};
