import React, { useState } from 'react';
import { classNames } from '../../../../../shared/libs/classNames';
import { templateModuleCategoryApi } from '../../../../../entities/template-module-category';
import { useDebounce } from '../../../../../shared/libs/useDebounce';
import SearchInput from '../../../../../components/ui/searchInput/SearchInput';
import { TemplateModuleCategoriesList } from '../list/TemplateModuleCategoriesList';
import css from './TemplateModuleCategoriesBar.module.scss';

interface TemplateModuleCategoriesBarProps {
	className?: string,
}

export const TemplateModuleCategoriesBar = ({ className }: TemplateModuleCategoriesBarProps) => {
	const [search, setSearch] = useState('');

	const debouncedSearch = useDebounce(search.trim(), 200);

	const { data: categoriesData } = templateModuleCategoryApi.useGetCategoriesQuery({
		page: 1,
		limit: 127,
		search: debouncedSearch,
	});

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<SearchInput
				styles={css.search}
				searchValue={search}
				onChange={setSearch}
				placeholder="Поиск по категориям"
			/>
			<TemplateModuleCategoriesList data={categoriesData?.moduleCategories} />
		</div>
	);
};
