import { FontFamily, FontSize } from '../editor/types';
import { type Token } from '../parser';

export interface IRenderOptions {
	/**
	 * Обработчик ссылок: принимает токен ссылки и должен вернуть значение для
	 * атрибута `href`. На вход может быть несколько типов токенов: Link, Hashtag
	 */
	link: (token: Token) => string,

	/**
	 * Нужно ли исправлять завершающий перевод строки.
	 * Используется для режима редактирования, когда для отображения
	 * последнего перевода строки нужно добавить ещё один
	 */
	fixTrailingLine: boolean;

	/** Заменяем все пробелы и переводы строк на неразрывный пробел */
	nowrap?: boolean;

	/** Отрисовать содержимое в контексте инлайн-блока
	 * (без переводов строк и блочных элементов) */
	inline?: boolean;
}

type TReconcileStateStack = [elem: HTMLElement, pos: number];

/**
 *
 */
class ReconcileState {
	/** Указатель на текущую позицию потомка внутри `container`. Потомки в данном случае - это DOM-узлы */
	public pos = 0;
	public container: HTMLElement;
	public options: IRenderOptions;
	private stack: TReconcileStateStack[] = [];

	// eslint-disable-next-line no-useless-constructor,no-empty-function
	constructor(container: HTMLElement, options: IRenderOptions) {
		this.container = container;
		this.options = options;
	}

	/**
	 * Ожидает текстовый узел в позиции `pos`. Если его нет, то автоматически создаст
	 * со значением `value`, а если есть, то обновит значение на `value`
	 */
	public text(value: string): Text {
		let node = this.container.childNodes[this.pos];
		if (node && node.nodeType === 3) {
			if (node.nodeValue !== value) {
				node.nodeValue = value;
			}
		} else {
			node = document.createTextNode(value);
			this.insertAt(this.container, node, this.pos);
		}
		this.pos++;
		return node as Text;
	}

	/**
	 * Ожидает элемент с именем `name` в текущей позиции. Если его нет, то создаст
	 * такой и вставит в DOM.
	 */
	public elem(name: string): HTMLElement {
		let node = this.container.childNodes[this.pos];
		if (!this.isElement(node) || node.localName !== name) {
			node = document.createElement(name);
			this.insertAt(this.container, node, this.pos);
		}
		this.pos++;
		return node as HTMLElement;
	}

	public isElement(node?: Node): node is HTMLElement {
		return node?.nodeType === 1;
	}

	/**
	 * Добавляет указанный узел `node` в позицию `pos` потомков `elem`
	 */
	public insertAt<T extends Node>(elem: HTMLElement, child: T, pos: number): T {
		const curChild = elem.childNodes[pos];
		if (curChild) {
			elem.insertBefore(child, curChild);
		} else {
			elem.appendChild(child);
		}

		return child;
	}

	/** Запишет в стек текущий контейнер и текущую позицию */
	public save(): void {
		this.stack.push([this.container, this.pos]);
	}

	/**
	 * Запишет в стек текущий контейнер и текущую позицию, после этого
	 * запишет переданный элемент как текущий контейнер и поставит позицию 0.
	 */
	public enter(elem: HTMLElement): void {
		this.save();
		this.prepare(elem);
	}

	public exit(): void {
		this.trim();
		this.restore();
	}

	/**
	 * Восстанавливает container и его позицию из стека.
	 */
	public restore(): void {
		const entry: TReconcileStateStack | undefined = this.stack.pop();
		if (entry) {
			// eslint-disable-next-line prefer-destructuring
			this.container = entry[0];
			// eslint-disable-next-line prefer-destructuring
			this.pos = entry[1];
		}
	}

	/**
	 * Удаляет все дочерние элементы контейнера, которые находятся правее точки `pos`
	 */
	public trim(): void {
		while (this.pos < this.container.childNodes.length) {
			this.remove(this.container.childNodes[this.pos]);
		}
	}

	/**
	 * Удаляет указанный DOM-узел
	 */
	public remove(node: ChildNode): void {
		node.remove();
	}

	/**
	 * Записывает переданный элемент как текущий контейнер и ставит позицию 0.
	 */
	public prepare(container: HTMLElement) {
		this.container = container;
		this.pos = 0;
	}

	/**
	 * Устанавливает размер текста у контейнера
	 */
	public setFontSize(fontSize: FontSize) {
		if (this.container) {
			this.container.style.fontSize = fontSize;
		}
	}

	/**
	 * Устанавливает размер текста у контейнера
	 */
	public setFontFamily(fontFamily: FontFamily) {
		if (this.container) {
			this.container.style.fontFamily = fontFamily;
		}
	}

	/**
	 * Устанавливает размер текста у контейнера
	 */
	public setLineHeight(lineHeight: number) {
		if (this.container) {
			this.container.style.lineHeight = lineHeight.toString();
		}
	}
}

export default ReconcileState;
