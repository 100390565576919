import React, { useEffect, useState } from 'react';
import CreateInput from '../../../../features/moduleWindow/createInput/CreateInput';
import { MAX_INPUT_VALUE_LENGTH } from '../../../../../modals/organization/createOrganization/CreateOrganization';
import { CreateModalWrapper } from '../../../../../ui/create-modal-wrapper/create-modal-wrapper';
import { useAppSelector } from '../../../../../../shared/libs/redux';

interface CreateTeamModalProps {
	isCreating: boolean,
	onCreate: (name: string) => Promise<void>,
	setIsActive: (value: boolean) => void,
}

export const CreateTeamModal = ({
	onCreate,
	isCreating,
	setIsActive,
}: CreateTeamModalProps) => {
	const isActive = useAppSelector(state => state.team.isCreateTeamModalActive);

	const [name, setName] = useState('');

	const createTeam = async () => {
		await onCreate(name);
		setName('');
	};

	useEffect(() => {
		if (!isActive) setName('');
	}, [isActive]);

	return (
		<CreateModalWrapper
			onCreate={createTeam}
			title="Создать команду"
			disabled={isCreating || !name.length}
			btnText="Создать"
			isActive={isActive}
			setIsActive={setIsActive}
		>
			<CreateInput
				maxLength={MAX_INPUT_VALUE_LENGTH}
				text={name}
				subTitle="Введите название"
				defaultValue="Название команды"
				onChange={setName}
			/>
		</CreateModalWrapper>
	);
};
