import React from 'react';
import cx from 'classnames';
import { ReactComponent as PlusIcon } from './images/blue-plus.svg';
import css from './addBtn.module.scss';

interface AddBtnProps {
	btnText: string,
	onBtnClick?: () => void,
	styles?: string,
}

const AddBtn = ({
	btnText,
	onBtnClick,
	styles,
}: AddBtnProps) => (
	<button
		type="button"
		className={cx(css.btn, styles)}
		onClick={onBtnClick}
	>
		<PlusIcon />
		<div className={css.text}>{btnText}</div>
	</button>
);

export default AddBtn;
