import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useUnpublishIntegrationWorkshopTemplate = () => {
	const [unpublish, { isLoading }] = workshopTemplateAPI.useDeleteIntegrationTemplateMutation();

	const onDeleteIntegration = async (template: string, email: string) => {
		try {
			await unpublish({
				template,
				email,
			})
				.unwrap();
			notificationSuccess('Интеграция шаблона на аккаунт пользователя успешно удалена!');
		} catch (e) {
			notificationError('Ошибка', 'Произошла ошибка при удалении интеграции шаблона на аккаунт пользователя...');
		}
	};

	return {
		isLoading,
		onDeleteIntegration,
	};
};
