import Graphic from './Graphic';
import { AnySpatialArea } from '../Types';
import SpatialGraphicArea from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';

abstract class UniterGraphic<TextureType> extends Graphic<TextureType> {
	public override readonly isUniter = true;

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		return [graphicArea];
	};
}

export default UniterGraphic;
