import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { URI_ORGANIZATION } from './consts';
import { IGetOrganizationResponse } from './types';

export const organizationAPI = createApi({
	reducerPath: 'organizationAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['organization'],
	endpoints: builder => ({
		getAll: builder.query<IGetOrganizationResponse, void>({
			query: () => ({
				url: URI_ORGANIZATION,
				method: 'GET',
			}),
			providesTags: ['organization'],
		}),
	}),
});
