interface ISubscriber {
	action: (offset: number) => void
}

class ScrollObserver {
	private readonly subscribers: ISubscriber[] = [];

	private currentOffset: number;

	constructor() {
		this.currentOffset = 0;
		document.body.addEventListener('scroll', this.onScroll);
	}

	public subscribe = (action: (offset: number) => void) => {
		const subscriber: ISubscriber = {
			action,
		};
		this.subscribers.push(subscriber);
	};

	public destruct = () => {
		document.body.removeEventListener('scroll', this.onScroll);
	};

	private onScroll = () => {
		this.reportSubscribers();
	};

	private reportSubscribers = () => {
		this.subscribers.forEach((subscriber) => {
			subscriber.action(document.body.scrollTop - this.currentOffset);
		});
		this.currentOffset = document.body.scrollTop;
	};
}

export default ScrollObserver;
