import { Token } from '../mext/parser/types';
import IDescartesPosition from '../../utils/IDescartesPosition';
import IComponentInjectionParams from '../../component-injector/IComponentInjectionParams';
import IGraphic from '../../graphic/IGraphic';
import Dependent from '../../utils/dependent/Dependent';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import ComponentFocusObserver from '../../utils/observers/ComponentUIObserver';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IComponentTree from '../../component-tree/IComponentTree';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import TextComponent from '../../components/text/TextComponent';
import TextGraphic from '../../graphic/text/TextGraphic';

export interface ITextImporterDependencies {
	sketchStabilizer: SketchStructureStabilizer,
	mousePositionObserver: MousePositionObserver;
	componentFocusObserver: ComponentFocusObserver,
	componentTree: IComponentTreeMutator & IComponentTree;
}

interface ITextComponentInjectionParams extends IComponentInjectionParams{
	textWidth: number,
}
/**
 * Сущность отвечает за импорт текста в компонент.
 * Он управляет процессом получения параметров для вставки текстового компонента
 * в зависимости от текущей позиции мыши, рассчитывает ширину текста,
 * а также создает необходимые структуры для размещения текст компонента на странице.
 */
class TextImporter extends Dependent<ITextImporterDependencies> {
	private readonly MIN_TEXT_WIDTH = 80;
	private readonly DEFAULT_HEIGHT = 28;

	private readonly prevInjectListeners: VoidFunction[];
	private readonly postInjectListeners: VoidFunction[];

	constructor() {
		super();

		this.prevInjectListeners = [];
		this.postInjectListeners = [];

		this.addPrevInjectListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});
		this.addPostInjectListener(() => {
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 0);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 0);
		});
	}

	public importText = (tokens: Token[]) => {
		this.callPrevInjectListeners();

		const parentComponent = this.dependencies.componentTree.getRootComponent();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const injectionParams = this.calculateTextComponentInjectionParams(mousePosition, tokens);

		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const structure = {
			id: componentId,
			type: SketchComponentType.TEXT,
			offset: injectionParams.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.TEXT,
					offset: 0,
					frame: {
						x: injectionParams.x,
						y: injectionParams.y,
						width: injectionParams.textWidth,
						height: this.DEFAULT_HEIGHT,
						rotate: 0,
						layer: 100,
					},
					texture: {
						content: {
							id: Utils.Generate.UUID4(),
							tokens,
							lineHeight: 1,
						},
					},
				},
			],
			texture: null,
			components: null,
		};

		let textComponent: TextComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			textComponent = tools.componentFactory.createComponent<TextComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<TextGraphic>(graphicStructure.type, textComponent);
				graphic.setStructure(() => graphicStructure);
				textComponent.appendGraphic(graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, textComponent);

			textComponent.enableFocus();
			textComponent.enableEditMode();

			setTimeout(textComponent.setCarriage.bind(this, 0), 0);
		});

		this.callPostInjectListeners();
	};

	/**
	 * Возвращает ширину текста в пикселях, используя Canvas.
	 * @param token Токен, содержащий текст, шрифт и размер шрифта.
	 */
	private getTokenWidth = (token: Token): number => {
		const canvas = document.createElement('canvas');
		const context = canvas.getContext('2d');
		const { fontSize, fontFamily, value } = token;
		if (context) {
			context.font = `${fontSize}px ${fontFamily}`;
			return context.measureText(value).width;
		}
		return 0;
	};

	/**
	 * Вычисляет параметры инжекции для текста компонента по позиции мыши.
	 * @param mousePosition Текущая позиция мыши.
	 * @param tokens Токены импортируемого текста.
	 */
	private calculateTextComponentInjectionParams = (
		mousePosition: IDescartesPosition,
		tokens: Token[],
	): ITextComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		const nearestPage = this.getNearestPage(mousePosition);

		const { width, height } = nearestPage.getFrameConfiguration();
		const { x, y } = nearestPage.getGlobalPosition();
		const {
			paddingLeft, paddingRight, paddingTop, paddingBottom,
		} = nearestPage.getTexture();
		const textWidth = tokens.reduce((width, token) => width + this.getTokenWidth(token), 0);

		/* Вычисляем параметры вставки текста:
		 * Определяем положение мыши относительно границ страницы и выполняем соответствующие действия.
		 * 1. Первый случай: мышь находится внутри границ страницы по обеим координатам x и y.
		 *		Рассчитываем смещение от позиции вставки относительно левого верхнего угла страницы.
		 * 2. Второй случай: координата x мыши лежит внутри страницы, но y — за пределами страницы (выше или ниже).
		 * 		В этом случае ограничиваем смещение по y в пределах paddingTop или height - paddingBottom.
		 * 3. Третий случай: координата y мыши лежит внутри страницы, но x — за пределами страницы (слева или справа).
		 * 		Здесь ограничиваем смещение по x в пределах paddingLeft или width - paddingRight.
		 * 4. Четвертый случай: ни x, ни y не попадают внутрь страницы.
		 * 		В этом случае размещаем текст в центре страницы.
 		*/
		let posX: number;
		let posY: number;
		// Первый случай
		if ((mousePosition.x >= x && mousePosition.x <= x + width)
			&& (mousePosition.y >= y && mousePosition.y <= y + height)) {
			posX = mousePosition.x - x;
			posY = mousePosition.y - y;
			// Второй случай
		} else if ((mousePosition.x >= x && mousePosition.x <= x + width)
			&& (mousePosition.y < y || mousePosition.y > y + height)) {
			posX = mousePosition.x - x;
			posY = mousePosition.y < y ? paddingTop : height - paddingBottom;
			// Третий случай
		} else if ((mousePosition.y >= y && mousePosition.y <= y + height)
			&& (mousePosition.x < x || mousePosition.x > x + width)) {
			posX = mousePosition.x < x ? paddingLeft
				: Math.max(paddingLeft, width - paddingRight - textWidth);
			posY = mousePosition.y - y;
			// Четвертый случай
		} else {
			posX = Math.max(paddingLeft, width / 2 - textWidth / 2);
			posY = height / 2;
		}

		const availableTextWidth = Math.min(width - paddingRight - posX, textWidth);
		const adjustedTextWidth = Math.max(this.MIN_TEXT_WIDTH, availableTextWidth);

		return {
			x: posX,
			y: posY,
			componentOffset: nearestPage.getOffset(),
			textWidth: adjustedTextWidth,
		};
	};

	private getNearestPage = (mousePosition: IDescartesPosition): IGraphic => {
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(mousePosition.y - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		return nearestPage;
	};

	private addPostInjectListener(listener: () => void) {
		this.postInjectListeners.push(listener);
	}

	private callPostInjectListeners = () => {
		this.postInjectListeners.forEach(listener => listener());
	};

	private addPrevInjectListener(listener: () => void) {
		this.prevInjectListeners.push(listener);
	}

	private callPrevInjectListeners = () => {
		this.prevInjectListeners.forEach(listener => listener());
	};
}

export default TextImporter;
