export interface IMouseOffsetObserverAction {
    (offsetX: number, offsetY: number, ev: MouseEvent): void
}

interface IMouseOffsetSubscriber {
    action: IMouseOffsetObserverAction
}

// MouseOffsetObserver уведомляет подписчиков о движении мыши с передачей сдвига
class MouseOffsetObserver {
	private readonly subscribers: IMouseOffsetSubscriber[];
	private lastX: number;
	private lastY: number;

	constructor() {
		this.subscribers = [];
		document.addEventListener('mousemove', this.action);
	}

	public subscribe = (action: IMouseOffsetObserverAction) => {
		this.subscribers.push({ action });
	};

	public destructor = (): void => {
		document.removeEventListener('mousemove', this.action);
	};

	private action = (ev: MouseEvent) => {
		if (!this.lastX || !this.lastY) {
			this.lastX = ev.pageX;
			this.lastY = ev.pageY;
		}

		const offsetX = ev.pageX - this.lastX;
		const offsetY = ev.pageY - this.lastY;

		this.subscribers.forEach((subscriber) => {
			subscriber.action(offsetX, offsetY, ev);
		});

		this.lastX = ev.pageX;
		this.lastY = ev.pageY;
	};
}

export default MouseOffsetObserver;
