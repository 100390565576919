import Queue from '../utils/Queue';
import SocketMessage from './SocketMessage';

class SocketQueue extends Queue<SocketMessage<any>> {
	public saveLocalState = () => {
		// TODO
	};

	public loadLocalState = () => {
		// TODO
	};
}

export default SocketQueue;
