import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import {
	workshopModuleActions,
	workshopModuleApi,
	workshopModuleSelectors,
} from '../../../../../entities/workshop-module';
import { notificationError } from '../../../../../components/Notifications/callNotifcation';

export const usePublishWorkshopModule = () => {
	const dispatch = useAppDispatch();

	const moduleId = useSelector(workshopModuleSelectors.getPublishingModuleId);

	const [onPublish, requestData] = workshopModuleApi.usePublishToGalleryMutation();

	const onPublishModule = async (tariffPermission: number) => {
		try {
			if (moduleId && tariffPermission) {
				await onPublish({ moduleId, tariffPermission }).unwrap();

				dispatch(workshopModuleActions.setIsPublishingModuleModalActive(false));
			}
		} catch (e) {
			notificationError('Произошла ошибка', 'Не удалось опубликовать модуль. Попробуйте позже.');
		}
	};

	return { requestData, onPublishModule };
};
