import TableCell from './TableCell';
import TableCellContext from './context/TableCellContext';

class ExtendTableCell extends TableCell {
	public isContextExtend = true;

	constructor(context: TableCellContext) {
		super(context);
	}
}

export default ExtendTableCell;
