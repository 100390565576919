import ImageGraphicBlock from '../../ImageGraphicBlock';
import SvgCollection from '../../../../../utils/SvgCollection';
import Utils from '../../../../../utils/impl/Utils';
import PictureGraphic from '../../../../picture/PictureGraphic';
import IPictureTexture from '../../../../picture/IPictureTexture';
import Graphic from '../../../../Graphic';
import IImageTexture from '../../IImageTexture';
import IGraphic from '../../../../IGraphic';

/**
 * Блок для отображения состояния изображения на этапе ожидания загрузки.
 */
class PendingImageBlock extends ImageGraphicBlock {
	private readonly ELEMENT_CLASS_NAME = 'picture-block__pending';

	private readonly plusElement: HTMLElement;
	private readonly pictureElement: HTMLElement;

	constructor(graphic: IGraphic) {
		super(graphic);

		this.setElementClassName(this.ELEMENT_CLASS_NAME);

		this.plusElement = Utils.DOM.stringToElement(SvgCollection.COMPONENT_PICTURE_PLUS);
		this.pictureElement = Utils.DOM.stringToElement(SvgCollection.COMPONENT_PICTURE_DEFAULT);
		this.element.append(this.pictureElement, this.plusElement);

		this.enablePicture();
	}

	public setPlusElementClick = (fn: VoidFunction) => {
		this.element.addEventListener('click', this.onElementClick.bind(this, fn));
	};

	private onElementClick = (fn: VoidFunction) => {
		const isEnabled = this.isBlockEnabled();
		if (!isEnabled) {
			return;
		}
		fn();
	};

	public enablePicture = () => {
		this.pictureElement.classList.add('show');
		this.plusElement.classList.remove('show');
	};

	public enablePlus = () => {
		this.plusElement.classList.add('show');
		this.pictureElement.classList.remove('show');
	};
}

export default PendingImageBlock;
