import { UserOrganizationInfo } from '../../../../../../widgets/user-organization-info';
import { ReactComponent as OrganizationIcon } from '../../../../../../shared/images/collection/team.svg';
import { ReactComponent as EntityIcon } from '../../../../../../shared/images/collection/entity.svg';
import css from './organizations-info.module.scss';
import { IAccount } from '../../../../../../entities/user/account/api/types';

interface AdminUserOrganizationsInfoProps {
	data: IAccount | undefined,
}

export const AdminUserOrganizationsInfo = ({ data }: AdminUserOrganizationsInfoProps) => (
	<div className={css.wrapper}>
		<UserOrganizationInfo
			name="Организация"
			data={data?.organization && [data?.organization]}
			icon={<OrganizationIcon />}
		/>
		<UserOrganizationInfo name="Юридические лица" data={data?.legalEntity} icon={<EntityIcon />} />
	</div>
);
