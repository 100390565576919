import SpatialResizeArea from '../SpatialResizeArea';
import AreaResizeTrigger from '../AreaResizeTrigger';
import IFrameArea from '../IFrameArea';
import SpatialAreaType from '../SpatialAreaType';
import ManipulatorError from '../../../../../utils/manipulator-error/ManipulatorError';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import IGraphic from '../../../../../graphic/IGraphic';

interface ISpatialGraphicResizeAngularAreaData extends ISpatialAreaBaseData<IGraphic> {}

/** Угловая область для изменения размеров графики компонента. */
class SpatialGraphicResizeAngularArea extends SpatialResizeArea<ISpatialGraphicResizeAngularAreaData> {
	public readonly type: SpatialAreaType = SpatialAreaType.GRAPHIC_ANGULAR_RESIZE;

	private readonly graphic: IGraphic;

	constructor(graphic: IGraphic, trigger: AreaResizeTrigger) {
		super(trigger);
		this.graphic = graphic;
	}

	public getData = (): ISpatialGraphicResizeAngularAreaData => ({
		graphic: this.graphic,
	});

	public getGlobalFrameArea = (): IFrameArea => {
		const position = this.graphic.getGlobalPosition();
		const configuration = this.graphic.getFrameConfiguration();
		return this.getTriggerArea(position.x, position.y, configuration.width, configuration.height);
	};

	/** Возвращает всю графику компонента, которая подвержена мутации. */
	public getRelatedFrames = (): IAreaSizeMutable[] => {
		const component = this.graphic.getParentComponent();
		if (component === null) {
			throw new ManipulatorError('component not found');
		}

		return component.getGraphics();
	};

	public isAllowMutatePosition = (): boolean => true;
}

export default SpatialGraphicResizeAngularArea;
