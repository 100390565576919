import ManipulatorArea, { IManipulatorAreaDependencies } from '../../mechanics/ManipulatorArea';
import ConstructorInjector from '../ConstructorInjector';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';

interface IPictureInjectAreaDependencies extends IManipulatorAreaDependencies {}

/**
 * Область, определяющая размеры создаваемой графики для компонента изображения.
 */
class PictureInjectArea extends ManipulatorArea<IPictureInjectAreaDependencies> {
	private readonly AREA_CLASS_NAME = 'pages-container__picture-area';

	constructor() {
		super();
		this.setAreaClassName(this.AREA_CLASS_NAME);
	}
}

export default PictureInjectArea;
