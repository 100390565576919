import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './filterBox.module.scss';
import TagMenu, { TagProps } from './tagMenu/TagMenu';
import { ReactComponent as ArrowCloseIcon } from '../../pages/collection/section/offer/images/arrow-close.svg';
import { popUpTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';

export interface FilterProps {
	currentName: string,
	title: string,
	tags: TagProps[],
}

const Filter = ({
	currentName,
	tags,
	title,
}: FilterProps) => {
	const [isFilterOpen, setIsFilterOpen] = useState(false);

	const onCloseFilter = () => {
		setIsFilterOpen(false);
	};

	const handleFilter = () => {
		setIsFilterOpen(!isFilterOpen);
	};

	const hiddenRef = useOutSideClick(onCloseFilter);

	return (
		<div
			className={css.filterBox}
			ref={hiddenRef}
		>
			<div
				className={css.visible}
			>
				<div className={css.text}>{title}</div>
				<div
					className={css.choice}
					onClick={handleFilter}
					role="presentation"
				>
					<div className={css.text}>{currentName}</div>
					<div className={`${css.arrow} ${isFilterOpen ? css.rotate : ''}`}>
						<ArrowCloseIcon />
					</div>
				</div>
			</div>
			<CSSTransition
				nodeRef={hiddenRef}
				in={isFilterOpen}
				timeout={200}
				unmountOnExit
				classNames={popUpTransitionClasses}
			>
				<div
					className={css.hidden}
					ref={hiddenRef}
				>
					<TagMenu tags={tags} closeMenu={onCloseFilter} />
				</div>
			</CSSTransition>
		</div>
	);
};

export default Filter;
