import React from 'react';
import css from '../authorization.module.scss';
import Logo from '../../logo/Logo';
import { CurrentAuthorizationPageData, MAIN_AUTHORIZATION_PAGE } from '../Authorization';
import { MainButton } from '../../../../ui/main-button/main-button';

interface FinallyResetPageProps {
	setCurrentPage: (value: CurrentAuthorizationPageData) => void,
}

const FinallyResetPage: React.FC<FinallyResetPageProps> = ({
	setCurrentPage,
}: FinallyResetPageProps) => (
	<div className={css.wrapper}>
		<Logo
			isPasswordSent={false}
		/>
		<h2 className={css.title}>
			Проверьте почту,
			<br />
			{' '}
			пароль уже там
		</h2>
		<MainButton
			type="button"
			onClick={() => setCurrentPage(MAIN_AUTHORIZATION_PAGE)}
			btnStyle="bright"
			styles={css.enter_button}
		>
			Войти
		</MainButton>
	</div>
);

export default FinallyResetPage;
