import ElementContainer from '../../../utils/ElementContainer';
import ColorInput from '../../../mechanics/ColorInput';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import HTMLElementName from '../../../utils/HTMLElementName';

interface INamedColorSelectorProps {
	containerClassName: string,
	text: string,
	textClassName: string,
	onSelectColorListener: (color: string) => void,
	isDisableTransparent: boolean,
}

/**
 * Элемент для выбора цвета с подписью.
 */
class NamedColorSelector extends ElementContainer<HTMLDivElement> {
	private readonly colorInput: ColorInput;

	constructor(props: INamedColorSelectorProps) {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(props.containerClassName);
		this.colorInput = new ColorInput(props.isDisableTransparent);
		this.colorInput.addSelectColorListener(props.onSelectColorListener);
		const textElement = HTMLGenerator.getH4({
			className: props.textClassName,
			text: props.text,
		});
		this.rootElement.append(
			textElement,
			this.colorInput.getElement(),
		);
	}

	public getColor = (): string => this.colorInput.getColor();
	public setColor = (color: string) => this.colorInput.setColor(color);
}

export default NamedColorSelector;
