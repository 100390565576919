import ManipulatorError from '../manipulator-error/ManipulatorError';
import IDependent from './IDependent';

/**
 * Вспомогательная сущность для внедрения зависимостей.
 * `Dependencies` содержит список зависимостей, требуемых для подключения.
 */
abstract class Dependent<Dependencies> implements IDependent<Dependencies> {
	private readonly postInjectDependenciesListeners: ((dependencies: Dependencies) => void)[];

	constructor() {
		this.postInjectDependenciesListeners = [];
	}

	protected private_dependencies: Dependencies | null = null;

	/**
	 * Внешние зависимости сущности.
	 * @protected
	 */
	protected get dependencies(): Dependencies {
		if (this.private_dependencies === null) {
			throw new ManipulatorError('the dependencies not injected');
		}
		return this.private_dependencies;
	}

	/**
	 * Добавляет слушателя события внедрения зависимостей.
	 * @param listener слушатель события.
	 */
	protected addPostInjectDependenciesListener = (listener: (dependencies: Dependencies) => void) => {
		this.postInjectDependenciesListeners.push(listener);
	};

	/**
	 * Подключает внешние зависимости.
	 * @param dependencies
	 */
	public connectDependencies = (dependencies: Dependencies) => {
		// eslint-disable-next-line no-restricted-syntax
		for (const dependenciesKey in dependencies) {
			if (dependencies[dependenciesKey] === undefined) {
				throw new ManipulatorError(`the dependency {${dependenciesKey}} is undefined`);
			}
		}
		this.private_dependencies = { ...dependencies };
	};

	/**
	 * Активирует внедренные зависимости.
	 */
	public injectDependencies = (): void => {
		if (this.private_dependencies === null) {
			throw new ManipulatorError('dependencies not connected');
		}
		this.postInjectDependenciesListeners.forEach(listener => listener({ ...this.private_dependencies! }));
	};
}

export default Dependent;
