import TextOptionsView from './TextOptionsView';
import ITextOption from './line-height/ITextOption';
import { FontFamily } from '../../../../mechanics/mext/editor/types';

class FontFamilySelector extends TextOptionsView<FontFamily> {
	private readonly DEFAULT_VALUE: FontFamily = FontFamily.Default;
	private readonly FONT_FAMILY_OPTIONS: ITextOption<FontFamily>[] = [
		{ text: 'Arial', value: FontFamily.Arial },
		{ text: 'Dela Gothic One', value: FontFamily.DelaGothicOne },
		{ text: 'Fira Sans', value: FontFamily.FiraSans },
		{ text: 'Inter', value: FontFamily.Default },
		{ text: 'Jost', value: FontFamily.Jost },
		{ text: 'Kiwi Maru', value: FontFamily.KiwiMaru },
		{ text: 'Kosugi Maru', value: FontFamily.KosugiMaru },
		{ text: 'Martin Mono', value: FontFamily.MartinMono },
		{ text: 'Merriweather', value: FontFamily.Merriweather },
		{ text: 'Oswald', value: FontFamily.Oswald },
		{ text: 'Prosto One', value: FontFamily.ProstoOne },
		{ text: 'Roboto', value: FontFamily.Roboto },
		{ text: 'Rubik', value: FontFamily.Rubik },
		{ text: 'Russo One', value: FontFamily.RussoOne },
		{ text: 'Seymour One', value: FontFamily.SeymourOne },
		{ text: 'Tektur', value: FontFamily.Tektur },
		{ text: 'Ubuntu', value: FontFamily.Ubuntu },
		{ text: 'Unbounded', value: FontFamily.Unbounded },
	];

	constructor() {
		super();
		this.setOptions(this.FONT_FAMILY_OPTIONS);
		this.setSelectedOption(this.DEFAULT_VALUE);
	}
}

export default FontFamilySelector;
