import InterfacePanel from '../../../InterfacePanel';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';
import IBaseSketchManipulator from '../../../../../SketchManipulators/IBaseSketchManipulator';

interface IPanelTitleProps {
	sketchManipulator: IBaseSketchManipulator,
}

/**
 * Панель с общими настройками шаблона.
 */
class SketchPanel extends InterfacePanel {
	private readonly ELEMENT_CLASS_NAME = 'top-bar__title template-name ';
	private readonly EDIT_BUTTON_CLASS_NAME = 'template-name__edit-btn';
	private readonly INPUT_CLASS_NAME = 'template-name__input';
	private readonly DEFAULT_TITLE_TEXT = 'Загрузка...';
	private readonly MAX_LEN = 60;

	private readonly title: HTMLElement;
	private readonly editButton: HTMLElement;
	private readonly inputTitle: HTMLElement;
	private readonly sketchManipulator: IBaseSketchManipulator;

	private isEditMode: boolean;

	constructor(props: IPanelTitleProps) {
		super();
		this.isEditMode = false;
		this.sketchManipulator = props.sketchManipulator;
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.onMouseDown();

		/* Элемент - название шаблона */
		this.inputTitle = HTMLGenerator.getH1({
			className: this.INPUT_CLASS_NAME,
			fnKeyUp: this.onInputKeyUp,
		});

		/* Обработка максимальной длины названия шаблона. */
		// TODO Нужно обработать случай когда в конце могут поставить пробел (за 1 или 2 символа до максимальной длины)
		//  в этом случае в элемент прилетает &nbs ""
		this.inputTitle.addEventListener('input', event => {
			if (this.inputTitle.innerHTML.length > this.MAX_LEN) {
				this.inputTitle.innerHTML = this.inputTitle.innerHTML.substring(0, this.MAX_LEN - 1);
				if (window.getSelection) {
					const savedRange = window.getSelection()?.getRangeAt(0);
					const s = window.getSelection();
					if (savedRange) s?.addRange(savedRange);
					this.setCaret(this.MAX_LEN - 1);
				}
			}
		});
		this.editButton = HTMLGenerator.getDiv({
			className: this.EDIT_BUTTON_CLASS_NAME,
		});
		Utils.DOM.injectSVG(this.editButton, SvgCollection.PENCIL_BUTTON);

		this.rootElement.append(this.inputTitle);
		this.rootElement.append(this.editButton);

		this.sketchManipulator.addPostChangeNameListener(this.postManipulatorChangeNameEvent);
	}

	public setCaret = (position: number) => {
		const range = document.createRange();
		const sel = window.getSelection();

		range.setStart(this.inputTitle.childNodes[0], position);
		range.collapse(true);

		if (sel) {
			sel.removeAllRanges();
			sel.addRange(range);
		}
	};

	public enableEditMode = () => {
		if (this.isEditMode) return;
		this.isEditMode = true;
		this.inputTitle.classList.add('editable');
		this.inputTitle.contentEditable = 'true';
		this.inputTitle.focus();
		this.editButton.classList.add('template-name__edit-btn_edit');
	};

	public disableEditMode = () => {
		this.isEditMode = false;
		this.inputTitle.contentEditable = 'false';
		this.inputTitle.classList.remove('editable');
		this.editButton.classList.remove('template-name__edit-btn_edit');
	};

	public setTitle = (sketchName: string) => {
		this.inputTitle.textContent = sketchName;
	};

	public hasEditMode = (): boolean => this.isEditMode;

	private onInputKeyUp = (ev: KeyboardEvent) => {
		const name = this.inputTitle.textContent || '';
		if (ev.code === 'Enter') {
			this.disableEditMode();
		}
		this.sketchManipulator.setName(name);
	};

	private postManipulatorChangeNameEvent = () => {
		const name = this.sketchManipulator.getName();
		this.setTitle(name);
	};

	private onMouseDown = (): void => {
		document.addEventListener('mousedown', (event) => {
			if (this.isEditMode) {
				if (event.target
					&& (event.target !== this.inputTitle)
					// && (event.target as HTMLElement).closest(`.${this.EDIT_BUTTON_CLASS_NAME}`)
				) {
					this.disableEditMode();
				}
			} else if (!this.isEditMode) {
				if (event.target
					&& ((event.target === this.inputTitle)
					|| event.target === this.editButton
					|| (event.target as HTMLElement).closest(`.${this.EDIT_BUTTON_CLASS_NAME}`))
				) {
					this.enableEditMode();
				}
			}
		});
	};
}

export default SketchPanel;
