import { ICollectionTab } from '../../../../../shared/ui/tab/model/types';
import { ReactComponent as TemplateIcon } from '../../../../../shared/images/collection/template.svg';
import { ReactComponent as ModuleIcon } from '../../../../../shared/images/collection/module.svg';
import { ReactComponent as UserIcon } from '../../../../../shared/images/collection/user.svg';

export const LINK_ADMIN_TEMPLATES = 'templates';
export const LINK_ADMIN_MODULES = 'modules';
export const LINK_ADMIN_USERS = 'users/';

export const adminTabsData: ICollectionTab[] = [
	{
		name: 'Шаблоны',
		link: LINK_ADMIN_TEMPLATES,
		picture: <TemplateIcon />,
		disabled: true,
	},
	{
		name: 'Модули',
		link: LINK_ADMIN_MODULES,
		picture: <ModuleIcon />,
		disabled: true,
	},
	{
		name: 'Пользователи',
		link: LINK_ADMIN_USERS,
		picture: <UserIcon />,
	},
];
