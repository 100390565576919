import { tariffAPI } from '../../../../../entities/tariff/api/api';
import { ICreateTransactionRequest } from '../../../../../entities/tariff/api/types';
import { notificationError, notificationSuccess } from '../../../../Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { adminAPI } from '../../../../../entities/admin/api/api';
import { accountAPI } from '../../../../../entities/user/account/api/api';

export const useCreateTransaction = (data: ICreateTransactionRequest) => {
	const dispatch = useAppDispatch();

	const [create, { isLoading }] = tariffAPI.useCreateTransactionMutation();

	const createTransaction = async () => {
		try {
			await create(data)
				.unwrap();

			dispatch(accountAPI.util?.invalidateTags(['account']));
			dispatch(adminAPI.util?.invalidateTags(['admin']));
			notificationSuccess('Транзация создана успешно!');
		} catch (err) {
			notificationError('Произошла ошибка при создании транзакции...');
		}
	};

	return {
		isLoading,
		createTransaction,
	};
};
