import ElementContainer from '../../../utils/ElementContainer';
import HTMLElementName from '../../../utils/HTMLElementName';
import HTMLGenerator from '../../../utils/HTMLGenerator';
import Utils from '../../../utils/impl/Utils';
import SvgCollection from '../../../utils/SvgCollection';

class TemplateMenu extends ElementContainer<HTMLElement> {
	private readonly CSS_CLASS = 'template-menu';

	constructor() {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.CSS_CLASS);
	}

	public appendMenuItem = (icon: SvgCollection, text: string, listener: VoidFunction) => {
		const container = HTMLGenerator.getDiv({ className: `${this.CSS_CLASS}__btn-wrap` });
		const button = HTMLGenerator.getBtn({
			text,
			fnClick: listener,
			className: `${this.CSS_CLASS}__btn`,
		});
		Utils.DOM.injectSVG(container, icon);
		container.append(button);
		this.rootElement.append(container);
	};

	public toggle = () => this.rootElement.classList.toggle('show');
	public show = () => this.rootElement.classList.add('show');
	public close = () => this.rootElement.classList.remove('show');
}

export default TemplateMenu;
