import IManipulatorAction from './actions/IManipulatorAction';

/**
 * Предоставляет методы для движения по временной линии действий пользователя.
 */
class ActionRepository {
	private readonly CURSOR_STEP = 1;
	private readonly CURSOR_DEVIATION = 0.5;
	private readonly CURSOR_INITIAL_VALUE = -0.5;

	private readonly actions: IManipulatorAction[];
	private readonly postMoveTimeLineCursorEvents: VoidFunction[];

	private cursor: number;

	constructor() {
		this.cursor = this.CURSOR_INITIAL_VALUE;
		this.actions = [];
		this.postMoveTimeLineCursorEvents = [];
	}

	/**
	 * Возвращает количество action доступных для восстановления.
	 */
	public getRedoActionCount = (): number => {
		if (this.actions.length === 0) {
			return 0;
		}

		const startIndex = this.cursor + this.CURSOR_DEVIATION;
		return this.actions.length - 1 < startIndex ? 0 : this.actions.length - startIndex;
	};

	/**
	 * Возвращает количество action доступных для отмены.
	 */
	public getUndoActionCount = (): number => {
		if (this.actions.length === 0) {
			return 0;
		}

		return this.cursor - this.CURSOR_INITIAL_VALUE;
	};

	public push = (action: IManipulatorAction) => {
		this.deleteFutureActions();
		this.actions.push(action);

		this.moveCursorForward();
	};

	/**
	 * Отменяет последнее действие.
	 */
	public undoAction = (fn: (action: IManipulatorAction) => boolean): void => {
		const actionIndex = this.cursor - this.CURSOR_DEVIATION;
		const action = this.actions[actionIndex];
		if (action === undefined) {
			return;
		}

		const isChangesValid = fn(action);
		if (!isChangesValid) {
			return;
		}

		if (this.cursor !== this.CURSOR_INITIAL_VALUE) {
			this.moveCursorBack();
		}
	};

	/**
	 * Выполняет следующее действие, стоящее впереди временной линии.
	 */
	public redoAction = (fn: (action: IManipulatorAction) => boolean): void => {
		const actionIndex = this.cursor + this.CURSOR_DEVIATION;
		const action = this.actions[actionIndex];
		if (action === undefined) {
			return;
		}

		const isChangesValid = fn(action);
		if (!isChangesValid) {
			return;
		}

		this.moveCursorForward();
	};

	/**
	 * Удаляет все действия, следующие после текущей позиции курсора.
	 */
	public deleteFutureActions = () => {
		const startIndex = this.cursor + this.CURSOR_DEVIATION;
		this.actions.splice(startIndex);
	};

	public getCursorPosition = (): number => this.cursor;
	public getActionCount = (): number => this.actions.length;

	public addPostMoveTimeLineCursorEvent = (event: VoidFunction) => {
		this.postMoveTimeLineCursorEvents.push(event);
	};

	private callPostMoveTimeLineCursorEvents = () => {
		this.postMoveTimeLineCursorEvents.forEach(event => event());
	};

	private moveCursorForward = () => {
		this.cursor += this.CURSOR_STEP;
		this.callPostMoveTimeLineCursorEvents();
	};

	private moveCursorBack = () => {
		this.cursor -= this.CURSOR_STEP;
		this.callPostMoveTimeLineCursorEvents();
	};
}

export default ActionRepository;
