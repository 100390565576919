import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, providesList } from '../../../../shared/api/api';
import {
	IBindTagGroupRequest,
	ICreateTagGroupRequest,
	IGetTagGroupListRequest,
	IGetTagGroupResponse,
	IGetTagsListRequest,
	IGetTagsResponse,
	IUnbindTagGroupRequest,
	IUpdateTagGroupRequest,
} from './types';
import { URI_TAG_GROUP } from './consts';

export const tagGroupAPI = createApi({
	reducerPath: 'tagGroupAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['tag_group'],
	endpoints: builder => ({
		create: builder.mutation<void, ICreateTagGroupRequest>({
			query: (data) => ({
				url: URI_TAG_GROUP,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['tag_group'],
		}),
		getAll: builder.query<IGetTagGroupResponse, IGetTagGroupListRequest>({
			query: (data) => ({
				url: URI_TAG_GROUP,
				method: 'GET',
				params: data,
			}),
			providesTags: (result) => providesList(result?.tagGroups, 'tag_group'),
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `${URI_TAG_GROUP}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['tag_group'],
		}),
		update: builder.mutation<void, IUpdateTagGroupRequest>({
			query: ({
				id,
				name,
			}) => ({
				url: `${URI_TAG_GROUP}/${id}`,
				method: 'PUT',
				body: {
					name,
				},
			}),
			invalidatesTags: ['tag_group'],
		}),
		bind: builder.mutation<void, IBindTagGroupRequest>({
			query: ({
				tags,
				group,
			}) => ({
				url: `${URI_TAG_GROUP}/${group}/tag`,
				method: 'POST',
				body: {
					tags,
				},
			}),
			invalidatesTags: ['tag_group'],
		}),
		unbind: builder.mutation<void, IUnbindTagGroupRequest>({
			query: ({
				group,
				tag,
			}) => ({
				url: `${URI_TAG_GROUP}/${group}/tag/${tag}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['tag_group'],
		}),
		tags: builder.query<IGetTagsResponse, IGetTagsListRequest>({
			query: ({
				group,
				...data
			}) => ({
				url: `${URI_TAG_GROUP}/${group}`,
				method: 'GET',
				params: {
					...data,
				},
			}),
			providesTags: ['tag_group'],
		}),
	}),
});
