import React, { useState } from 'react';
import css from './search-bar.module.scss';
import { CreateButton } from '../../../../../../shared/ui';
import FilterBox, { FilterBoxData } from '../../../../collection/section/template/my/FilterBox';
import { AdvancedTemplateSearch } from '../../../../../../widgets/templates/advanced-template-search';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/libs/redux';
import {
	setWorkshopSelectedTags,
	setWorkshopTemplatesSearch,
} from '../../../../../../entities/templates/workshop/template/model/slice';
import { useAdvancedSearchSelectedTags } from '../../../../../../shared/libs/templates/useAdvancedSearchSelectedTags';
import { ITagData } from '../../../../../../entities/modules/tag/api/types';
import {
	AdvancedSearchTagDropdown,
} from '../../../../../../widgets/templates/advanced-search-tag-dropdown/ui/advanced-search-tag-dropdown';
import { workshopTagAPI } from '../../../../../../entities/templates/workshop/tag/api/api';
import {
	useCreateWorkshopTemplateTag,
} from '../../../../../../features/workshop/tags/create/api/useCreateWorkshopTemplateTag';
import { WorkshopTemplatesPublishDropdownFilter } from './publish-dropdown-filter/publish-dropdown-filter';
import { WorkshopTemplatesTariffDropdownFilter } from './tariff-dropdown-filter/tariff-dropdown-filter';

interface WorkshopTemplateSearchBarProps {
	isLoading: boolean,
	sortingData: FilterBoxData,
	onTemplateCreate: () => void,
}

export const WorkshopTemplateSearchBar = ({
	isLoading,
	sortingData,
	onTemplateCreate,
}: WorkshopTemplateSearchBarProps) => {
	const dispatch = useAppDispatch();

	const [tagSearch, setTagSearch] = useState('');
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const {
		search,
		selectedTags,
	} = useAppSelector(state => state.workshopTemplate);

	const setSearch = (value: string) => dispatch(setWorkshopTemplatesSearch(value));
	const setSelectedTags = (tags: ITagData[]) => dispatch(setWorkshopSelectedTags(tags));

	const { handleTag } = useAdvancedSearchSelectedTags(selectedTags, setSelectedTags);
	const { onCreateTag } = useCreateWorkshopTemplateTag();

	const { data } = workshopTagAPI.useGetAllQuery({
		limit: 127,
		page: 1,
	});

	return (
		<>
			<div className={css.add_block}>
				<CreateButton disabled={isLoading} onClick={onTemplateCreate}>
					Создать шаблон
				</CreateButton>
				<AdvancedTemplateSearch
					search={search}
					selectedTags={selectedTags}
					isDropdownVisible={isDropdownVisible}
					setSearch={setSearch}
					setIsDropdownVisible={setIsDropdownVisible}
					handleTag={handleTag}
				>
					<AdvancedSearchTagDropdown
						isDropdownVisible={isDropdownVisible}
						tags={((data?.templateTags || []) as ITagData[])}
						setSearch={setTagSearch}
						search={tagSearch}
						selectedTags={selectedTags}
						createTag={onCreateTag}
						setSelectedTags={setSelectedTags}
					/>
				</AdvancedTemplateSearch>
			</div>
			<div className={css.filtration_bar}>
				<div className={css.filters}>
					<WorkshopTemplatesPublishDropdownFilter />
					<WorkshopTemplatesTariffDropdownFilter />
				</div>
				<FilterBox sortingData={sortingData} />
			</div>
		</>
	);
};
