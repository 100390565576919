import TextOptionsView from './TextOptionsView';
import ITextOption from './line-height/ITextOption';
import { FontSize } from '../../../../mechanics/mext/editor/types';

class FontSizeSelector extends TextOptionsView<FontSize> {
	private readonly DEFAULT_VALUE: FontSize = FontSize.Pt11;
	private readonly FONT_SIZE_OPTIONS: ITextOption<FontSize>[] = [
		{ text: '8', value: FontSize.Pt8 },
		{ text: '9', value: FontSize.Pt9 },
		{ text: '10', value: FontSize.Pt10 },
		{ text: '11', value: FontSize.Pt11 },
		{ text: '12', value: FontSize.Pt12 },
		{ text: '14', value: FontSize.Pt14 },
		{ text: '16', value: FontSize.Pt16 },
		{ text: '18', value: FontSize.Pt18 },
		{ text: '20', value: FontSize.Pt20 },
		{ text: '22', value: FontSize.Pt22 },
		{ text: '24', value: FontSize.Pt24 },
		{ text: '26', value: FontSize.Pt26 },
		{ text: '28', value: FontSize.Pt28 },
		{ text: '36', value: FontSize.Pt36 },
		{ text: '48', value: FontSize.Pt48 },
		{ text: '56', value: FontSize.Pt56 },
		{ text: '64', value: FontSize.Pt64 },
		{ text: '72', value: FontSize.Pt72 },
	];

	constructor() {
		super();
		this.setOptions(this.FONT_SIZE_OPTIONS);
		this.setSelectedOption(this.DEFAULT_VALUE);
	}
}

export default FontSizeSelector;
