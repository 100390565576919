import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';
import SquareButton from './SquareButton';
import ColorInput from '../../mechanics/ColorInput';
import NumberInputField from './NumberInputField';

/**
 * Контейнер для создания и размещения UI кнопок.
 */
class ButtonsContainer extends ElementContainer<HTMLUListElement> {
	private baseClassNameButton: string[];

	constructor(className: string) {
		super(HTMLElementName.UL);
		this.baseClassNameButton = [];
		this.setRootElementClassName(className);
	}

	/**
	 * Добавляет имя класса к каждой новой создаваемой кнопке. Вновь добавленные классы будут добавлены к дочерним
	 * кнопкам, которые будут созданы после добавления класса.
	 * @param className Название CSS класса.
	 */
	public appendBaseClassNameButton = (className: string) => {
		this.baseClassNameButton.push(className);
	};

	/**
	 * Добавляет квадратную кнопку в контейнер.
	 * @param fn Функция, принимающая созданную кнопку для её настройки за пределами метода.
	 */
	public appendSquareButton = (fn: (button: SquareButton) => void): SquareButton => {
		const button = new SquareButton();
		fn(button);
		this.rootElement.append(button.getElement());
		this.baseClassNameButton.forEach(className => button.appendClassName(className));
		return button;
	};

	/**
	 * Добавляет элемент выбора цвета.
	 * @param fn Функция, принимающая созданную кнопку для её настройки за пределами метода.
	 */
	public appendColorInput = (fn: (colorInput: ColorInput) => void) => {
		const colorInput = new ColorInput(false);
		this.baseClassNameButton.forEach(className => colorInput.appendClassName(className));
		fn(colorInput);
		this.rootElement.append(colorInput.getElement());
	};

	/**
	 * Добавляет поле для ввода числовых значений.
	 * @param fn Функция, принимающая созданную кнопку для её настройки за пределами метода.
	 */
	public appendNumberInput = (fn: (field: NumberInputField) => void): NumberInputField => {
		const numberInputField = new NumberInputField();
		fn(numberInputField);
		this.rootElement.append(numberInputField.getElement());
		return numberInputField;
	};

	public show = () => {
		this.rootElement.style.display = 'flex';
	};

	public hide = () => {
		this.rootElement.style.display = 'none';
	};
}

export default ButtonsContainer;
