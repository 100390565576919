import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { customTemplateAPI } from '../../../../../entities/templates/custom/api/api';

export const useIntegrateInAccount = (template: string) => {
	const dispatch = useAppDispatch();

	const [integrate, { isLoading }] = workshopTemplateAPI.useIntegrateMutation();

	const onIntegrate = async (email: string) => {
		try {
			await integrate({
				template,
				email,
			})
				.unwrap();
			dispatch(customTemplateAPI.util?.invalidateTags(['custom']));
			notificationSuccess('Шаблон успешно интегрирован в аккаунт пользователя!');
		} catch (e) {
			notificationError('Произошла ошибка при интеграции шаблона в аккаунт...');
		}
	};

	return {
		isLoading,
		onIntegrate,
	};
};
