import React from 'react';
import css from './offer_settings.module.scss';

// interface CategoryBlockProps {
// choicedLegalEntity: ChoiceMenuItem | undefined,
// legalEntityList: Array<ChoiceMenuItem> | undefined,
// setChoicedLegalEntity: (value: ChoiceMenuItem) => void,
// managerList: Array<ChoiceMenuItem> | undefined,
// choicedManager: ChoiceMenuItem | undefined,
// setChoicedManager: (value: ChoiceMenuItem | undefined) => void,
// deadLine: string | null | undefined,
// setSketchDeadline: (value: string | null | undefined) => void,
// }

const CategoryBlock = ({
	// choicedLegalEntity,
	// legalEntityList,
	// setChoicedLegalEntity,
	// managerList,
	// choicedManager,
	// setChoicedManager,
	// deadLine,
	// setSketchDeadline,
}) => {
	// const determinateLegalEntityText = (menuItems: Array<ChoiceMenuItem> | undefined) => {
	// 	if (!menuItems) {
	// 		return 'Юридическое лицо не создано';
	// 	}
	// 	if (choicedLegalEntity) {
	// 		return choicedLegalEntity.text;
	// 	}
	// 	return 'Выбрать юр. лицо';
	// };
	//
	// const determinateManagerText = (
	// 	menuItems: Array<ChoiceMenuItem> | undefined,
	// 	choicedLegalEntity: string | undefined,
	// ) => {
	// 	if (!menuItems && choicedLegalEntity) {
	// 		return `${choicedLegalEntity} не имеет закрепленных менеджеров`;
	// 	}
	// 	if (choicedManager
	// 		&& managerList) {
	// 		return choicedManager.text;
	// 	}
	// 	return 'Выбрать менеджера';
	// };

	// useEffect(() => {
	// 	setChoicedManager(undefined);
	// 	setSketchDeadline('');
	// }, [choicedLegalEntity]);
	//
	// useEffect(() => {
	// 	setSketchDeadline('');
	// }, [choicedManager]);
	const a = '';

	return (
		<div className={css.categoryBlock}>
			{/* <ChoiceMenu */}
			{/* 	text={determinateLegalEntityText(legalEntityList)} */}
			{/* 	isActive */}
			{/* 	data={legalEntityList} */}
			{/* 	cssClass={css.choiceMenu} */}
			{/* 	onItemClick={setChoicedLegalEntity} */}
			{/* /> */}
			{/* <ChoiceMenu */}
			{/* 	text={determinateManagerText(managerList, choicedLegalEntity?.text)} */}
			{/* 	isActive={!!(choicedLegalEntity && managerList)} */}
			{/* 	data={managerList} */}
			{/* 	cssClass={css.choiceMenu} */}
			{/* 	onItemClick={setChoicedManager} */}
			{/* /> */}
			{/* <ChoiceMenu */}
			{/* 	text="Время активности КП" */}
			{/* 	deadLine={deadLine} */}
			{/* 	isNeedDate */}
			{/* 	isActive={!!(choicedManager && managerList)} */}
			{/* 	setDate={setSketchDeadline} */}
			{/* /> */}
		</div>
	);
};

export default CategoryBlock;
