import { useState } from "react";
import { FilterBoxData } from "../../components/pages/collection/section/template/my/FilterBox";
import { TTemplateSortBy } from "../../entities/templates/types";

export enum SortingValues {
	SORTING_ALPHABET = "Алфавит",
	SORTING_DEFAULT = "Последнее измененное",
	SORTING_CREATE_DATE = "Дата создания",
	SORTING_DEADLINE = "Дата истечения",
}

export enum FromFilterData {
	TEMPLATE = "TEMPLATE",
	MODULE = "MODULE",
	OFFER = "OFFER",
}

interface CurrentFilterI {
	name: SortingValues,
	sortBy: TTemplateSortBy,
}

export type FromFilterType = FromFilterData.TEMPLATE | FromFilterData.MODULE | FromFilterData.OFFER;

const useFilterData = (from: FromFilterType) => {
	const [currentFilter, setCurrentFilter] = useState<CurrentFilterI>({
		name: SortingValues.SORTING_DEFAULT,
		sortBy: "update_date",
	});

	const templateTags = [
		{
			name: SortingValues.SORTING_DEFAULT,
			sortBy: "update_date" as TTemplateSortBy,
			choiced: currentFilter.sortBy === "update_date",
			onClick: () => setCurrentFilter({
				name: SortingValues.SORTING_DEFAULT,
				sortBy: "update_date",
			}),
		},
		{
			name: SortingValues.SORTING_ALPHABET,
			sortBy: "name",
			choiced: currentFilter.sortBy === "name",
			onClick: () => setCurrentFilter({
				name: SortingValues.SORTING_ALPHABET,
				sortBy: "name",
			}),
		},
		{
			name: SortingValues.SORTING_CREATE_DATE,
			sortBy: "create_date",
			choiced: currentFilter.sortBy === "create_date",
			onClick: () => setCurrentFilter({
				name: SortingValues.SORTING_CREATE_DATE,
				sortBy: "create_date",
			}),
		},
	];

	// const offerTags = [
	// 	{
	// 		name: SortingValues.SORTING_DEFAULT,
	// 		choiced: currentFilter === SortingValues.SORTING_DEFAULT,
	// 		onClick: () => setCurrentFilter(SortingValues.SORTING_DEFAULT),
	// 	},
	// 	{
	// 		name: SortingValues.SORTING_ALPHABET,
	// 		choiced: currentFilter === SortingValues.SORTING_ALPHABET,
	// 		onClick: () => setCurrentFilter(SortingValues.SORTING_ALPHABET),
	// 	},
	// 	{
	// 		name: SortingValues.SORTING_DEADLINE,
	// 		choiced: currentFilter === SortingValues.SORTING_DEADLINE,
	// 		onClick: () => setCurrentFilter(SortingValues.SORTING_DEADLINE),
	// 	},
	// ];

	const sortingData: FilterBoxData = {
		// tags: from === FromFilterData.TEMPLATE ? templateTags : offerTags,
		tags: templateTags,
		title: "Сортировка:",
		currentName: currentFilter.name,
		closeMenu: () => {
			// nothing
		},
	};

	return {
		currentFilter,
		sortingData
	};
};

export default useFilterData;
