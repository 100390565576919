import Graphic from '../Graphic';
import IGraphicStructure from '../IGraphicStructure';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import GraphicType from '../GraphicType';
import IFigureTexture from './IFigureTexture';
import BorderStyle from './BorderStyle';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import { AnySpatialArea } from '../../Types';

class FigureGraphic extends Graphic<IFigureTexture> {
	public readonly type: GraphicType = GraphicType.FIGURE;

	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-figure graphic-figure';
	private readonly CSS_PROPERTY_BACKGROUND = 'background-color';
	private readonly CSS_PROPERTY_BORDER_COLOR = 'border-color';
	private readonly CSS_PROPERTY_BORDER_WIDTH = 'border-width';
	private readonly CSS_PROPERTY_BORDER_STYLE = 'border-style';
	private readonly CSS_PROPERTY_BORDER_RADIUS = 'border-radius';

	protected texture: IFigureTexture;

	constructor() {
		super();
		this.graphicElement.className = this.GRAPHIC_CLASS_NAME;
		this.texture = {
			background: '',
			borderColor: '',
			borderWidth: 1,
			borderStyle: BorderStyle.SOLID,
			radius: {
				topLeft: 0,
				topRight: 0,
				bottomLeft: 0,
				bottomRight: 0,
			},
		};
	}

	public setStructure = (
		fn: (prev: IGraphicStructure<IFigureTexture>) => IGraphicStructure<IFigureTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		if (texture === null) {
			throw new ManipulatorError('figure graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('figure graphic frame cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public getTexture = (): IFigureTexture => ({ ...this.texture });

	public setTexture = (
		fn: (prevTexture: IFigureTexture) => IFigureTexture,
	): void => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);

		this.texture = updatedTexture;
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_BACKGROUND, `${updatedTexture.background}`);
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_BORDER_COLOR, `${updatedTexture.borderColor}`);
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_BORDER_WIDTH, `${updatedTexture.borderWidth}px`);
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_BORDER_STYLE, `${updatedTexture.borderStyle}`);

		this.graphicElement.style.setProperty(
			this.CSS_PROPERTY_BORDER_RADIUS,
			`${updatedTexture.radius.topLeft}px ${updatedTexture.radius.topRight}px 
			${updatedTexture.radius.bottomLeft}px ${updatedTexture.radius.bottomRight}px`,
		);
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		const resizeAreas = this.getResizeAreas();

		return [graphicArea, ...resizeAreas];
	};

	public getUniqueTexture = (): IFigureTexture => this.getTexture();

	protected startMutation = (): void => {
		if (this.type === GraphicType.FIGURE) return;
		this.graphicElement.contentEditable = 'true';
	};

	protected finishMutation = (): void => {
		this.graphicElement.contentEditable = 'false';
	};
}

export default FigureGraphic;
