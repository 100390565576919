import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopTagGroupActions, workshopTagGroupApi } from '../../../../entities/workshop-tag-group';
import BlockHeader from '../../../../components/pages/features/moduleWindow/editModuleWindow/blockHeader/BlockHeader';
import { Slider } from '../../../../widgets/slider';
import { WorkshopTagGroupCard } from '../../../../entities/workshop-tag-group/ui/group-card/WorkshopTagGroupCard';
import { EmptyList } from '../../../../components/ui/empty-list/empty-list';
import { CreateWorkshopTagGroupModal } from '../create-group-modal/CreateWorkshopTagGroupModal';
import { DeleteWorkshopTagGroupModal } from '../delete-group-modal/DeleteWorkshopTagGroupModal';
import { EditWorkshopTagGroupModal } from '../edit-group-modal/EditWorkshopTagGroupModal';
import { BindWorkshopTagGroupModal } from '../bind-group-tag-modal/BindWorkshopTagGroupModal';
import { UnbindWorkshopTagGroupModal } from '../unbind-group-tag-modal/UnbindWorkshopTagGroupModal';
import css from './EditWorkshopTagGroups.module.scss';
import { classNames } from '../../../../shared/libs/classNames';

interface EditWorkshopTagGroupsProps {
	className?: string,
}

export const EditWorkshopTagGroups = ({ className }: EditWorkshopTagGroupsProps) => {
	const dispatch = useAppDispatch();

	const { data: groups } = workshopTagGroupApi.useGetWorkshopGroupsQuery({
		page: 1,
		limit: 127,
		search: '',
	});

	const openCreateModal = () => {
		dispatch(workshopTagGroupActions.setIsCreateGroupModalActive(true));
	};

	const openDeleteModal = (groupId: number) => {
		dispatch(workshopTagGroupActions.setIsDeleteGroupModalActive(true));
		dispatch(workshopTagGroupActions.setDeletingGroupId(groupId));
	};

	const openEditModal = (groupId: number) => {
		dispatch(workshopTagGroupActions.setIsEditingGroupModalActive(true));
		dispatch(workshopTagGroupActions.setEditingGroupId(groupId));
	};

	const openBindModal = (groupId: number) => {
		dispatch(workshopTagGroupActions.setIsBindingGroupModalActive(true));
		dispatch(workshopTagGroupActions.setBindingGroupId(groupId));
	};

	const openUnbindModal = (groupId: number, tagId: number) => {
		dispatch(workshopTagGroupActions.setIsUnbindingGroupModalActive(true));
		dispatch(workshopTagGroupActions.setUnbindingGroupId(groupId));
		dispatch(workshopTagGroupActions.setUnbindingTags([tagId]));
	};

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<BlockHeader
				title="Группа тегов"
				onBtnClick={openCreateModal}
				btnText="Добавить группу тегов"
			>
				{
					groups?.groupTags
						? (
							<div className={css.slider}>
								<Slider buttonScrollDistance={600} height={308} controlSize="big">
									{
										groups?.groupTags?.map(group => (
											<WorkshopTagGroupCard
												key={group.id}
												data={group}
												onDeleteTagGroup={openDeleteModal}
												onEditTagGroup={openEditModal}
												onBindTag={openBindModal}
												onUnbindTag={openUnbindModal}
											/>
										))
									}
								</Slider>
							</div>
						)
						: (
							<EmptyList
								title="Создайте первую группу тегов"
								needBtn={false}
							/>
						)
				}
			</BlockHeader>
			<CreateWorkshopTagGroupModal />
			<DeleteWorkshopTagGroupModal />
			<EditWorkshopTagGroupModal />
			<BindWorkshopTagGroupModal />
			<UnbindWorkshopTagGroupModal />
		</div>
	);
};
