import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import {
	URI_PERMISSIONS, URI_PERMISSIONS_BITMAP, URI_PERMISSIONS_LEGAL_ENTITY, URI_PERMISSIONS_TEAM, 
} from './consts';
import {
	IGetPermissionsListResponse,
	IPermissionsBitmap,
	IPutPermissionsLegalEntityRequest,
	IPutPermissionsTeamRequest,
} from './types';
import { setBitmap, setPermissionList } from '../../../redux/reducers/permissionsReducer';

export const permissionAPI = createApi({
	reducerPath: 'permissionAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['permission'],
	endpoints: builder => ({
		getAll: builder.query<IGetPermissionsListResponse, { role: string }>({
			query: ({ role }) => ({
				url: `${URI_PERMISSIONS}/${role}`,
				method: 'GET',
			}),
			providesTags: ['permission'],
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const { data } = await queryFulfilled;

					dispatch(setPermissionList(data));
				} catch (e) {
					console.log('error');
				}
			},
		}),
		bitmap: builder.query<IPermissionsBitmap, void>({
			query: () => ({
				url: URI_PERMISSIONS_BITMAP,
				method: 'GET',
			}),
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const res = await queryFulfilled;
					dispatch(setBitmap(res.data));
				} catch (e) {
					console.log('Bitmap error');
				}
			},
		}),
		updateTeam: builder.mutation<void, IPutPermissionsTeamRequest>({
			query: (data) => ({
				url: URI_PERMISSIONS_TEAM,
				method: 'PUT',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['permission'],
		}),
		updateLegalEntity: builder.mutation<void, IPutPermissionsLegalEntityRequest>({
			query: (data) => ({
				url: URI_PERMISSIONS_LEGAL_ENTITY,
				method: 'PUT',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['permission'],
		}),
	}),
});
