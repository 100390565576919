import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { tagAPI } from '../../../../../entities/modules/tag/api/api';
import { useAppDispatch } from '../../../../../shared/libs/redux';

export const useCreateModuleTag = () => {
	const dispatch = useAppDispatch();

	const [create, { isLoading }] = tagAPI.useCreateMutation();

	const createTag = async (name: string) => {
		try {
			const res = await create({ name })
				.unwrap();

			dispatch(tagAPI.util?.invalidateTags(['tag']));
			notificationSuccess('Тег создан успешно!', '', true);

			return res.moduleTag;
		} catch (e) {
			notificationError('Возникла ошибка при создании тега...');
		}
		return null;
	};

	return {
		createTag,
		isLoading,
	};
};
