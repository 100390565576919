import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import DeletingTagWindow from './DeletingTagWindow';
import { setIsDeletingTagGroupModalActive } from '../../../../../../redux/reducers/module/tagGroupReducer';
import { tagsGroupModalsActive } from '../../../../../../redux/selectors/module/tagGroup';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { useAppSelector } from '../../../../../../shared/libs/redux';

const DeletingTagGroupWrapper = () => {
	const dispatch = useDispatch();

	const isDeletingTagGroupModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isDeletingTagGroupModalActive);

	const [deleteTagGroup] = tagGroupAPI.useDeleteMutation();

	const deletingTagGroupId = useAppSelector(state => state.tagGroup.deletingTagGroupId);

	const handleDeletingModal = (value: boolean) => dispatch(setIsDeletingTagGroupModalActive(value));

	const onDeleteGroup = async () => {
		if (deletingTagGroupId) {
			try {
				await deleteTagGroup({ id: deletingTagGroupId })
					.unwrap();
				handleDeletingModal(false);
				notificationSuccess('Групповой тег удален успешно!');
			} catch (e) {
				notificationError('Возникла ошибка при удалении группы тегов...');
			}
		}
	};

	return (
		<DeletingTagWindow
			onDelete={onDeleteGroup}
			handleDeletingModal={handleDeletingModal}
			isDeletingModalActive={isDeletingTagGroupModalActive}
			text="Вы действительно хотите удалить группу тегов без возможности восстановления?"
		/>
	);
};

export default DeletingTagGroupWrapper;
