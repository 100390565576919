import React from 'react';
import { useNavigate } from 'react-router-dom';
import css from './registration.module.scss';
import { AUTHORIZATION_LINK } from './authorization/Authorization';

const HaveAnAccountBlock: React.FC = () => {
	const navigate = useNavigate();
	return (
		<div className={css.haveAccountWrapper}>
			<div className={css.haveAccountBlock}>
				<span className={css.haveAccountDescr}>
					Уже есть учетная запись?
				</span>
				<span
					className={css.haveAccountLink}
					onClick={() => { navigate(AUTHORIZATION_LINK); }}
					role="presentation"
				>
					Войдите в систему
				</span>
			</div>
		</div>
	);
};

export default HaveAnAccountBlock;
