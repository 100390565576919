import React from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import css from '../../modal.module.scss';
import CloseModalBtn from '../../closeModalBtn/CloseModalBtn';
import { useModal } from '../util/useModal';

interface INewModalProps {
	active: boolean,
	closeModal: (active: boolean) => void,
	styles?: string,
	children: React.ReactNode,
	modalInModalActive?: boolean
}

export const portal = document.getElementById('portal');

/** Компонент - модальное окно */
export const NewModal = ({
	active,
	closeModal,
	styles,
	children,
	modalInModalActive,
}: INewModalProps) => {
	const {
		modalRef,
		modalContentRef,
	} = useModal(closeModal, active, modalInModalActive);

	const onClose = () => closeModal(false);

	return (
		<>
			{
				ReactDOM.createPortal(
					<CSSTransition
						in={active}
						timeout={700}
						nodeRef={modalRef}
						classNames={{
							enter: css.modal_enter,
							enterActive: css.modal_enter_active,
							exit: css.modal_exit,
							exitActive: css.modal_exit_active,
						}}
						unmountOnExit
					>
						<div
							className={css.modal}
							ref={modalRef}
							role="presentation"
						>
							<div
								className={cx(css.content_wrapper, styles)}
								ref={modalContentRef}
							>
								<CloseModalBtn onClose={onClose} />
								{children}
							</div>
						</div>
					</CSSTransition>,
					portal as Element,
				)
			}
		</>
	);
};
