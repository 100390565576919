import React from 'react';
import { useDispatch } from 'react-redux';
import css from './tagList.module.scss';
import {
	setIsUnbindingTagGroupModalActive,
	setUnbindingGroupId,
	setUnbindingTagId,
} from '../../../../../../../../../redux/reducers/module/tagGroupReducer';
import {
	setEditingTagId,
	setIsEditingTagModalActive,
} from '../../../../../../../../../redux/reducers/module/tagReducer';
import { ITagGroupData } from '../../../../../../../../../entities/modules/tag-group/api/types';
import { ModuleTag } from '../../../../../../../../../entities/modules/tag/ui/module-tag';

interface TagListProps {
	groupId: number,
	tagsData: ITagGroupData[] | undefined,
}

const TagList = ({
	groupId,
	tagsData,
}: TagListProps) => {
	const dispatch = useDispatch();

	const handleUnbindingModal = (value: boolean) => {
		dispatch(setIsUnbindingTagGroupModalActive(value));
	};

	const onUnbindingTag = (id: number) => {
		dispatch(setUnbindingTagId(id));
		dispatch(setUnbindingGroupId(groupId));
		handleUnbindingModal(true);
	};

	const onEditingTag = (id: number) => {
		dispatch(setEditingTagId(id));
		dispatch(setIsEditingTagModalActive(true));
	};

	return (
		<div className={css.tags}>
			{
				tagsData?.map(tag => {
					const { id } = tag;

					return (
						<ModuleTag
							key={id}
							tag={tag}
							onEdit={() => onEditingTag(id)}
							onDelete={() => onUnbindingTag(id)}
						/>
					);
				})
			}
		</div>
	);
};

export default TagList;
