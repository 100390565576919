import { v4 as uuid } from 'uuid';

const setHeaders = (isNeedAuthorization: boolean) => {
	if (isNeedAuthorization) {
		const authorization = localStorage.getItem('authorization')
			? localStorage.getItem('authorization')
			: sessionStorage.getItem('authorization');

		return {
			'Client-Request-Id': uuid(),
			Authorization: `${authorization}`,
		};
	} 
	return {
		'Client-Request-Id': uuid(),
		Authorization: '',
	};
};

export default setHeaders;
