export const formatDateForServer = (dateStr: string) => {
	if (!dateStr) return '';

	const [datePart, timePart] = dateStr.split(' ');
	if (!datePart || !timePart) return '';

	const [day, month, year] = datePart.split('-').map(Number);
	const [hours, minutes] = timePart.split(':').map(Number);

	if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hours) || isNaN(minutes)) return '';

	const date = new Date(year, month - 1, day, hours, minutes);
	return date.toISOString();
};
