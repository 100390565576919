import IComponent from '../../components/IComponent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IComponentAlignment from './IComponentAlignment';
import GraphicType from '../../graphic/GraphicType';
import PageGraphic from '../../graphic/page/PageGraphic';

/**
 * Сущность, предоставляющая методы для выравнивая внутри многостраничного компонента.
 */
class ComponentAlignment implements IComponentAlignment {
	public alignToWidth = (...components: IComponent[]) => {
		components.forEach(this.componentToWidthAlign);
	};

	public alignWithIndentation = (...components: IComponent[]) => {
		components.forEach(this.componentAlignWithIndentation);
	};

	public alignHorizontalCenter = (...components: IComponent[]) => {
		// TODO
	};

	public alignVerticalCenter = (...components: IComponent[]) => {
		// TODO
	};

	public alignLeft = (...components: IComponent[]) => {
		components.forEach(this.componentLeftAlign);
	};

	public alignExtremeLeft = (...components: IComponent[]) => {
		components.forEach(this.componentExtremeLeftAlign);
	};

	public alignTop = (...components: IComponent[]) => {
		components.forEach(this.componentTopAlign);
	};

	public alignRight = (...components: IComponent[]) => {
		components.forEach(this.componentRightAlign);
	};

	public alignBottom = (...components: IComponent[]) => {
		components.forEach(this.componentBottomAlign);
	};

	private componentToWidthAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: parentGraphic.getFrameConfiguration().x,
					width: parentGraphic.getFrameConfiguration().width,
				}));
				return;
			}
			const { width } = parentGraphic.getFrameConfiguration();

			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: 0,
				width,
			}));
		});
	};

	private componentAlignWithIndentation = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: parentGraphic.getFrameConfiguration().x,
					width: parentGraphic.getFrameConfiguration().width,
				}));
				return;
			}
			const { width } = parentGraphic.getFrameConfiguration();
			const { paddingLeft, paddingRight } = parentGraphic.getTexture();

			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: 0 + paddingLeft,
				width: width - paddingRight * 2,
			}));
		});
	};

	private componentLeftAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: 0,
				}));
				return;
			}
			const { paddingLeft } = parentGraphic.getTexture();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: paddingLeft,
			}));
		});
	};

	private componentExtremeLeftAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: 0,
			}));
		});
	};

	private componentTopAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				graphic.setFrameConfiguration(prev => ({
					...prev,
					y: 0,
				}));
				return;
			}
			const { paddingTop } = parentGraphic.getTexture();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				y: paddingTop,
			}));
		});
	};

	private componentRightAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				const parentGraphic = graphic.getParentGraphic();
				if (parentGraphic === null) {
					throw new ManipulatorError('parent graphic is null');
				}
				const parentGraphicConfiguration = parentGraphic.getFrameConfiguration();
				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: parentGraphicConfiguration.width - prev.width,
				}));
				return;
			}

			const { paddingRight } = parentGraphic.getTexture();
			const pageWidth = (parentGraphic as PageGraphic).getRealWidth();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: pageWidth - paddingRight - prev.width,
			}));
		});
	};

	private componentBottomAlign = (component: IComponent) => {
		const graphics = component.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		graphics.forEach(graphic => {
			const parentGraphic = graphic.getParentGraphic();
			if (parentGraphic === null) {
				throw new ManipulatorError('parent graphic not found');
			}
			const parentIsPage = parentGraphic.type === GraphicType.PAGE;
			if (!parentIsPage) {
				const parentGraphic = graphic.getParentGraphic();
				if (parentGraphic === null) {
					throw new ManipulatorError('parent graphic is null');
				}
				const parentGraphicConfiguration = parentGraphic.getFrameConfiguration();
				graphic.setFrameConfiguration(prev => ({
					...prev,
					y: parentGraphicConfiguration.height - prev.height,
				}));
				return;
			}

			const { paddingBottom } = parentGraphic.getTexture();
			const pageHeight = (parentGraphic as PageGraphic).getRealHeight();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				y: pageHeight - paddingBottom - prev.height,
			}));
		});
	};
}

export default ComponentAlignment;
