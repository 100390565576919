import ElementContainer from '../utils/ElementContainer';
import HTMLElementName from '../utils/HTMLElementName';

/**
 * Базовая сущность всех панелей в интерфейсе.
 */
abstract class InterfacePanel extends ElementContainer<HTMLDivElement> {
	// Вложенные панели
	private readonly internalPanels: this[];

	protected constructor() {
		super(HTMLElementName.DIV);
		this.internalPanels = [];
	}

	/** Показывает панель. */
	public show = () => {
		this.rootElement.classList.add('show');
	};

	/** Скрывает панель. */
	public hide = () => {
		this.rootElement.classList.remove('show');
	};

	/** Скрывает панель. */
	public toggle = () => {
		this.rootElement.classList.toggle('show');
	};

	/** Помещает все переданные панели внутрь главной панели. */
	public injectPanels = (...panels: InterfacePanel[]) => {
		this.internalPanels.push(...(panels as this[]));
	};

	/** Регистрирует переданные панели как внутренние. */
	public registerPanels = (...panels: InterfacePanel[]) => {
		panels.forEach(panel => {
			this.internalPanels.push(panel as this);
		});
	};

	public hidePanels = () => {
		this.internalPanels.forEach(panel => panel.hide());
	};

	public getInternalPanels = (): InterfacePanel[] => this.internalPanels;
}

export default InterfacePanel;
