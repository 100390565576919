import { classNames } from '../../../../../shared/libs/classNames';
import { SelectPublishModuleTariff } from '../select-tariff-modal/SelectPublishModuleTariff';
import { UnpublishWorkshopModuleButton } from '../unpublish-button/UnpublishWorkshopModuleButton';
import { PublishWorkshopModuleButton } from '../publish-button/PublishWorkshopModuleButton';

interface HandleModulePublicityProps {
	className?: string,
	gallery?: {
		tariffPermission?: number | null
	},
	currentModuleId?: string,
}

export const HandleModulePublicity = ({ className, gallery, currentModuleId }: HandleModulePublicityProps) => {
	const a = '';

	return (
		<div className={classNames('', {}, [className])}>
			{
				!gallery || gallery?.tariffPermission === null
					? <PublishWorkshopModuleButton moduleId={currentModuleId} />
					: <UnpublishWorkshopModuleButton moduleId={currentModuleId} />
			}
			<SelectPublishModuleTariff />
		</div>
	);
};
