import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import ICornerRadius from '../../../../../graphic/figure/ICornerRadius';
import DropdownPanel from '../../../../DropdownPanel';
import NumberInput from '../../../../buttons/NumberInput';
import NumberInputField from '../../../../buttons/NumberInputField';

interface IBorderRadiusSelectorProps {
	fnOnInput: (values: ICornerRadius) => void,
}

class BorderRadiusSelector extends DropdownPanel {
	private topLeftValue: number;
	private topRightValue: number;
	private bottomLeftValue: number;
	private bottomRightValue: number;

	private readonly topLeft: NumberInput;
	private readonly topRight: NumberInput;
	private readonly bottomLeft: NumberInput;
	private readonly bottomRight: NumberInput;
	private readonly HEADER_TEXT = 'Радиус углов';
	private readonly ELEMENT_CLASS_NAME = 'object-panel__options options object-options';

	constructor(props: IBorderRadiusSelectorProps) {
		super();
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.createHeaderElement();

		this.topLeftValue = 0;
		this.topRightValue = 0;
		this.bottomLeftValue = 0;
		this.bottomRightValue = 0;

		const inputsContainer = document.createElement('div');
		inputsContainer.className = 'inputs-container';

		this.topLeft = this.getNumberField(value => {
			this.topLeftValue = value;
			props.fnOnInput(this.getCurrentValues());
		});
		this.topRight = this.getNumberField(value => {
			this.topRightValue = value;
			props.fnOnInput(this.getCurrentValues());
		});
		this.bottomLeft = this.getNumberField(value => {
			this.bottomLeftValue = value;
			props.fnOnInput(this.getCurrentValues());
		});
		this.bottomRight = this.getNumberField(value => {
			this.bottomRightValue = value;
			props.fnOnInput(this.getCurrentValues());
		});

		inputsContainer.append(
			this.topLeft.getElement(),
			this.topRight.getElement(),
			this.bottomRight.getElement(),

			this.bottomLeft.getElement(),

		);

		this.rootElement.append(this.createHeaderElement());
		this.rootElement.append(inputsContainer);
	}

	// Устанавливает значения радиусов углов
	public setValue = (radius: ICornerRadius): void => {
		this.topLeftValue = radius.topLeft;
		this.topRightValue = radius.topRight;
		this.bottomLeftValue = radius.bottomLeft;
		this.bottomRightValue = radius.bottomRight;

		this.topLeft.setValue(radius.topLeft);
		this.topRight.setValue(radius.topRight);
		this.bottomLeft.setValue(radius.bottomLeft);
		this.bottomRight.setValue(radius.bottomRight);
	};

	private createHeaderElement = (): HTMLElement => HTMLGenerator.getH4({
		className: 'object-panel__title',
		text: this.HEADER_TEXT,
	});

	private getNumberField = (onChangeValueListener: (value: number) => void): NumberInput => {
		const field = new NumberInput();
		field.setMinValue(0);
		field.setMaxValue(400);
		field.addChangeValueListener(onChangeValueListener);
		field.setRootElementClassName('object-panel__corner-radius-input');
		return field;
	};

	private getCurrentValues = (): ICornerRadius => ({
		topLeft: this.topLeftValue,
		topRight: this.topRightValue,
		bottomLeft: this.bottomLeftValue,
		bottomRight: this.bottomRightValue,
	});
}

export default BorderRadiusSelector;
