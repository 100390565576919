import { useMemo } from 'react';
import { ITagData } from '../../../entities/modules/tag/api/types';

export const useAdvancedSearchSelectedTags = (
	selectedTags: ITagData[],
	setSelectedTags: (value: ITagData[]) => void,
) => {
	const selectedTagIds = useMemo(() => selectedTags.map(({ id }) => id), [selectedTags]);

	const handleTag = (tag: ITagData) => {
		if (selectedTagIds.includes(tag.id)) {
			setSelectedTags(selectedTags.filter(el => el.id !== tag.id));
		} else {
			setSelectedTags([...selectedTags, tag]);
		}
	};
	const resetTags = () => setSelectedTags([]);

	return {
		handleTag,
		resetTags,
		selectedTagIds,
	};
};
