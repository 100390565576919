import { MouseEventHandler } from 'react';
import { CategoryCard } from '../../../../entities/modules/category';
import { ITemplateModuleCategory } from '../../../../entities/template-module-category';
import { useLikeCategory } from '../../model/services/useLikeCategory';
import { useDislikeCategory } from '../../model/services/useDislikeCategory';
import { ReactComponent as LikedIcon } from '../../assets/filled-star.svg';
import { ReactComponent as DislikedIcon } from '../../assets/empty-star.svg';

interface TemplateModuleLikeableCardProps {
	className?: string,
	data?: ITemplateModuleCategory,
	isActive?: boolean,
	onCategoryClick?: (id: number) => void,
}

export const TemplateModuleLikeableCard = ({
	className, onCategoryClick, data, isActive,
}: TemplateModuleLikeableCardProps) => {
	const { onLikeCategory } = useLikeCategory();
	const { onDislikeCategory } = useDislikeCategory();

	const likeCategory: MouseEventHandler<SVGSVGElement> = async (e) => {
		e.stopPropagation();

		await onLikeCategory(data?.id);
	};
	const dislikeCategory: MouseEventHandler<SVGSVGElement> = async (e) => {
		e.stopPropagation();

		await onDislikeCategory(data?.id);
	};

	const Icon = data?.favorite
		? <LikedIcon onClick={dislikeCategory} />
		: <DislikedIcon onClick={likeCategory} />;

	return (
		<CategoryCard
			category={data}
			onCategoryClick={onCategoryClick}
			isActive={isActive}
			actions={Icon}
			className={className}
		/>
	);
};
