import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useUnbindTagToWorkshopTemplate = (template: string) => {
	const [unbindTag, { isLoading }] = workshopTemplateAPI.useUnbindTagMutation();

	const onUnbindTag = async (tag: number) => {
		try {
			await unbindTag({
				tag,
				template,
			})
				.unwrap();
			notificationSuccess('Тег успешно удален из шаблона!');
		} catch (e) {
			notificationError('Произошла ошибка при удалении тега из шаблона...');
		}
	};

	return {
		isLoading,
		onUnbindTag,
	};
};
