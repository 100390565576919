import React, { useState } from 'react';
import { employeeAPI } from '../../../../entities/employee/api/api';
import ChoiceBlock, { ITEMS_TEAMS } from '../../../filter-select-bar/ChoiceBlock';
import css from './find-block.module.scss';
import SearchInput from '../../../../components/ui/searchInput/SearchInput';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../components/Notifications/callNotifcation';
import { CreateButton } from '../../../../shared/ui';
import { IAccount } from '../../../../entities/user/account/api/types';

interface FindBlockProps {
	searchValue: string,
	registrationRight: boolean,
	choicedUser: IAccount | null,
	setChoicedUser: (value: IAccount | null) => void,
	setSearchValue: (value: string) => void,
	btnText: string,
	searchPlaceholder: string,
	openCreateModal: () => void
}

export const FindBlock = ({
	searchValue,
	searchPlaceholder,
	btnText,
	choicedUser,
	registrationRight,
	setChoicedUser,
	setSearchValue,
	openCreateModal,
}: FindBlockProps) => {
	const { data: users } = employeeAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	const [areBlockShow, setAreBlockShow] = useState(false);

	const onCreateTeam = () => {
		if (!registrationRight) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			openCreateModal();
		}
	};

	return (
		<div className={css.btnBlockWrapper}>
			<div className={css.btnBlock}>
				<ChoiceBlock
					isNeedChoiceBlock
					users={users?.accounts}
					setBlockShown={setAreBlockShow}
					items={ITEMS_TEAMS}
					isBlockShow={areBlockShow}
					choicedUser={choicedUser}
					onChoicedUser={setChoicedUser}
				/>
				<CreateButton onClick={onCreateTeam} styles={css.create_btn}>
					{btnText}
				</CreateButton>
			</div>
			<SearchInput
				onChange={setSearchValue}
				placeholder={searchPlaceholder}
				searchValue={searchValue}
				maxLength={30}
			/>
		</div>
	);
};
