import { useAppSelector } from "../../shared/libs/redux";
import { SELECTABLE_ITEM_CLASS, SELECTED_ITEM_CLASS } from "./useSelection";

export const useCleanTemplateSelection = () => {
	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const cleanTemplateSelection = () => {
		if (selectedList.length) {
			const templates = document.querySelectorAll("." + SELECTABLE_ITEM_CLASS);

			templates?.forEach(el => {
				const elem = el as HTMLElement;

				elem.classList.remove(SELECTED_ITEM_CLASS);
			});
		}
	};

	return { cleanTemplateSelection };
};
