import cx from 'classnames';
import { IContextMenuItem } from '../../../model/types';
import css from './context-menu-item.module.scss';

interface ContextMenuItemProps {
	data: IContextMenuItem,
}

export const ContextMenuItem = ({ data }: ContextMenuItemProps) => {
	const {
		text,
		icon,
		onClick,
		textStyles,
		iconStyles,
	} = data;

	return (
		<button type="button" onClick={onClick} className={css.wrapper}>
			<div className={cx(css.icon, iconStyles)}>{icon}</div>
			<div className={cx(css.text, textStyles)}>{text}</div>
		</button>
	);
};
