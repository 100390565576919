import KeyCombination from '../KeyCombination';
import { KeyEvent } from './KeyboardObserver';
import KeyScanner from '../KeyScanner';

class KeyboardSubscriber {
	private readonly keyScanner: KeyScanner;
	private readonly key: KeyCombination;
	private readonly event: KeyEvent;

	constructor(key: KeyCombination, event: KeyEvent) {
		this.keyScanner = new KeyScanner();
		this.key = key;
		this.event = event;
	}

	public isKey = (ev: KeyboardEvent): boolean => {
		switch (this.key) {
		// 	AI
		case KeyCombination.CTRL_ALT_M: {
			return this.keyScanner.isCtrlAltM(ev);
		}
		case KeyCombination.CTRL_SHIFT_G: {
			return this.keyScanner.isCtrlShiftG(ev);
		}
		case KeyCombination.CTRL_G: {
			return this.keyScanner.isCtrlG(ev);
		}
		case KeyCombination.SHIFT_T: {
			return this.keyScanner.isShiftT(ev);
		}
		case KeyCombination.SHIFT_F: {
			return this.keyScanner.isShiftF(ev);
		}
		case KeyCombination.SHIFT_L: {
			return this.keyScanner.isShiftL(ev);
		}
		case KeyCombination.DELETE: {
			return this.keyScanner.isDelete(ev);
		}
		case KeyCombination.SHIFT_K: {
			return this.keyScanner.isShiftK(ev);
		}
		case KeyCombination.ESCAPE: {
			return this.keyScanner.isEscape(ev);
		}
		case KeyCombination.CTRL_Z: {
			return this.keyScanner.isCtrlZ(ev);
		}
		case KeyCombination.CTRL_SHIFT_Z: {
			return this.keyScanner.isCtrlShiftZ(ev);
		}
		case KeyCombination.CTRL_X: {
			return this.keyScanner.isCtrlX(ev);
		}
		case KeyCombination.CTRL_C: {
			return this.keyScanner.isCtrlC(ev);
		}
		case KeyCombination.CTRL_V: {
			return this.keyScanner.isCtrlV(ev);
		}
		case KeyCombination.CTRL: {
			return this.keyScanner.isCtrl(ev);
		}
		case KeyCombination.SHIFT: {
			return this.keyScanner.isShift(ev);
		}
		case KeyCombination.ALT: {
			return this.keyScanner.isAlt(ev);
		}
		case KeyCombination.ARROW_UP: {
			return this.keyScanner.isArrowUp(ev);
		}
		case KeyCombination.ARROW_DOWN: {
			return this.keyScanner.isArrowDown(ev);
		}
		case KeyCombination.ARROW_LEFT: {
			return this.keyScanner.isArrowLeft(ev);
		}
		case KeyCombination.ARROW_RIGHT: {
			return this.keyScanner.isArrowRight(ev);
		}
		case KeyCombination.CTRL_ARROW_UP: {
			return this.keyScanner.isCtrlArrowUp(ev);
		}
		case KeyCombination.CTRL_ARROW_DOWN: {
			return this.keyScanner.isCtrlArrowDown(ev);
		}
		case KeyCombination.CTRL_ARROW_LEFT: {
			return this.keyScanner.isCtrlArrowLeft(ev);
		}
		case KeyCombination.CTRL_ARROW_RIGHT: {
			return this.keyScanner.isCtrlArrowRight(ev);
		}
		default: {
			return false;
		}
		}
	};

	public call = (ev: KeyboardEvent) => {
		this.event(ev);
	};
}

export default KeyboardSubscriber;
