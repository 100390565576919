import { ReactComponent as TariffIcon } from './images/tariff.svg';
import { useRedirectButton } from '../../libs/useRedirectButton';
import { CollectionTab } from '../tab/tab';
import { DEFAULT_TO_TARIFF_URL, toTariffData } from './model/data';

export const ToTariffButton = () => {
	const { onClick } = useRedirectButton(DEFAULT_TO_TARIFF_URL, toTariffData);

	return (
		<CollectionTab
			isActive={false}
			setTab={onClick}
			tab={{
				link: '',
				name: 'Тарифы',
				picture: <TariffIcon />,
			}}
			index={1}
		/>
	);
};
