import React from 'react';
import { ITagData } from '../../../../../../entities/modules/tag/api/types';
import { ModuleTag } from '../../../../../../entities/modules/tag/ui/module-tag';

interface TagsScrollSearchListProps {
	currentIds: number[],
	tags: ITagData[] | undefined,
	onSelectTag: (id: number) => void,
}

export const TagsScrollSearchList = ({
	tags,
	currentIds,
	onSelectTag,
}: TagsScrollSearchListProps) => (
	<div>
		{
			tags?.map(tag => {
				const { id } = tag;

				return (
					<ModuleTag
						tag={tag}
						key={id}
						onClick={() => onSelectTag(id)}
						isActive={currentIds?.includes(id)}
					/>
				);
			})
		}
	</div>
);
