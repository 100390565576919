import React from 'react';
import { classNames, Mods } from '../../../../../shared/libs/classNames';
import { IFilterModulesItem } from '../filter-modules-by-tags/FilterWorkshopModulesByTags';
import { ReactComponent as ArrowIcon } from '../../../assets/close-arrow.svg';
import css from './FilterWorkshopModulesGroupCard.module.scss';

interface FilterWorkshopModulesGroupCardProps {
	className?: string,
	selected?: boolean,
	isOpen?: boolean,
	onSelect?: (id: number) => void,
	data?: IFilterModulesItem,
}
export const FilterWorkshopModulesGroupCard = ({
	className, data, onSelect, selected, isOpen,
}: FilterWorkshopModulesGroupCardProps) => {
	if (!data) {
		return null;
	}

	const mods: Mods = {
		[css.open]: isOpen,
		[css.selected]: selected,
	};

	const { id, name } = data;

	const handleClick = () => onSelect?.(id);

	return (
		<div role="presentation" className={classNames(css.wrapper, mods, [className])} onClick={handleClick}>
			<div className={css.text}>{name}</div>
			<div className={css.icon}>
				<ArrowIcon />
			</div>
		</div>
	);
};
