import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { IFilterItem } from '../model/types';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';
import { popUpTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';
import { ReactComponent as ArrowIcon } from '../../../shared/images/arrow-close-black.svg';
import { FilterItem } from './item/item';
import css from './filter.module.scss';

interface FilterProps {
	title: string,
	data: IFilterItem[],
	selectedItem: IFilterItem,
	onSelect: (item: IFilterItem) => void,
}

export const Filter = ({
	data,
	title,
	onSelect,
	selectedItem,
}: FilterProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const close = () => setIsOpen(false);
	const handle = () => setIsOpen(prev => !prev);
	const select = (item: IFilterItem) => {
		onSelect(item);
		close();
	};

	const ref = useOutSideClick(close);

	return (
		<div className={css.wrapper} ref={ref}>
			<div role="presentation" className={css.main} onClick={handle}>
				<div className={css.title}>
					{title}
					:
				</div>
				<div className={css.selected}>
					<span>{selectedItem.name}</span>
					<ArrowIcon className={cx(isOpen && css.active)} />
				</div>
			</div>
			<CSSTransition
				nodeRef={ref}
				in={isOpen}
				timeout={200}
				unmountOnExit
				classNames={popUpTransitionClasses}
			>
				<div className={css.select_area} ref={ref}>
					{data?.map(el => (
						<FilterItem
							key={`${el.id}1`}
							selected={el === selectedItem}
							data={el}
							onSelect={select}
						/>
					))}
				</div>
			</CSSTransition>
		</div>
	);
};
