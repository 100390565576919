import React from 'react';
import { SelectableFilterCard } from '../../../../../../../shared/ui';
import { DropdownFilter } from '../../../../../../../widgets/dropdown-filter';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import {
	setIsWorkshopFilterUserModalActive,
	setPublishedFilter,
} from '../../../../../../../entities/templates/workshop/template/model/slice';
import {
	defaultWorkshopPublishFilterData,
	galleryWorkshopPublishFilterData,
	userWorkshopPublishFilterData,
} from '../../../model/data';
import { IPublishedFilter } from '../../../../../../../entities/templates/workshop/template/model/types';

const { id: galleryId } = galleryWorkshopPublishFilterData;
const { text: defaultText } = defaultWorkshopPublishFilterData;

export const WorkshopTemplatesPublishDropdownFilter = () => {
	const dispatch = useAppDispatch();

	const filter = useAppSelector(state => state.workshopTemplate.publishedFilter);

	const handleFilter = (filter: IPublishedFilter | undefined) => dispatch(setPublishedFilter(filter));
	const openUsersModal = () => dispatch(setIsWorkshopFilterUserModalActive(true));

	const selectedPublished = !filter ? defaultText : filter.text;

	return (
		<DropdownFilter scrollContentHeight={102} selected={selectedPublished}>
			<SelectableFilterCard
				selected={!filter}
				data={defaultWorkshopPublishFilterData}
				onSelect={() => handleFilter(undefined)}
			/>
			<SelectableFilterCard
				selectedType="filled"
				selected={filter?.id === galleryId}
				data={galleryWorkshopPublishFilterData}
				onSelect={() => handleFilter(galleryWorkshopPublishFilterData)}
			/>
			<SelectableFilterCard
				selectedType="filled"
				onSelect={openUsersModal}
				data={userWorkshopPublishFilterData}
				selected={filter && filter?.id !== galleryId}
			/>
		</DropdownFilter>
	);
};
