import MousePositionObserver from '../../../../utils/observers/MousePositionObserver';
import { AnySpatialArea } from '../../../../Types';
import SpatialAreaType from '../spatial-area/SpatialAreaType';
import IGraphic from '../../../../graphic/IGraphic';
import GraphicType from '../../../../graphic/GraphicType';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import BaseSpatialAreaTree from '../BaseSpatialAreaTree';

class WorkshopTemplateSpatialAreaTree extends BaseSpatialAreaTree {
	constructor(
		private readonly mouseObserver: MousePositionObserver,
	) {
		super();
		this.setUpdateActiveAreaListener(this.detectActiveArea);
		this.addComponentFilterFunction(this.filterUntilComponents);
	}

	public forceUpdateAreaFromMousePosition = () => {
		const mousePosition = this.mouseObserver.getCurrentPosition();
		this.forceUpdateActiveArea(mousePosition);
	};

	/**
	 * Определяет область на которую наведена мышь с учетом бизнес логики.
	 * При определении области, если областью является SpatialAreaType.GRAPHIC, важно учитывать состояние родительских
	 * и вложенных компонентов. Возможные варианты target-области (target-область - область графики под курсором мыши)
	 * и того, что должна вернуть на выходе:
	 *	1 пустое (от вложенного компонента) место в uniter-графике в режиме редактирования: uniter-графика,
	 *	2 пустое (от вложенного компонента) место в uniter-графике не в режиме редактирования: uniter-графика,
	 *	3 графика дочернего компонента у Uniter-компонента в режиме редактирования: графика дочернего компонента,
	 *	4 графика дочернего компонента у Uniter-компонента не в режиме редактирования: uniter-графика,
	 * @param areas Все пересеченные области в порядке от пользователя по оси Z в текущей позиции курсора.
	 */
	private detectActiveArea = (areas: AnySpatialArea[]): AnySpatialArea | null => {
		for (let i = 0; i < areas.length; ++i) {
			const area = areas[i];
			const { graphic } = area.getData();

			switch (area.type) {
			case SpatialAreaType.GRAPHIC_ANGULAR_RESIZE: {
				return area;
			}
			case SpatialAreaType.GRAPHIC_RESIZE: {
				return area;
			}
			case SpatialAreaType.GRAPHIC: {
				if (graphic.isEnableEditMode()
					&& (graphic as IGraphic).isUniter
				) {
					/* Нужно проверить есть ли под курсором еще область, то есть отделить пустую (от вложенного
					компонента) область от области графики дочернего компонента у Uniter-компонента */
					if (i === areas.length - 1) {
						return areas[i];
					}
					const area = areas[i + 1];
					const nextGraphic = area.getData().graphic;
					if (
						nextGraphic.type === SpatialAreaType.GRAPHIC
						&& (nextGraphic as IGraphic).getStructure().type === GraphicType.PAGE
					) {
						return area;
					}
					break;
				}

				if (graphic.isEnableEditMode()
					&& !(graphic as IGraphic).isUniter
				) {
					return area;
				}

				if (!graphic.isEnableEditMode() && (graphic as IGraphic).getStructure().type !== GraphicType.PAGE) {
					return area;
				}
				break;
			}
			case SpatialAreaType.PICTURE_ANGULAR_RESIZE: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			case SpatialAreaType.PICTURE_RESIZE: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			case SpatialAreaType.PICTURE: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			case SpatialAreaType.TABLE_COLUMN_RESIZE: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			case SpatialAreaType.TABLE_ROW_RESIZE: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			case SpatialAreaType.TABLE_CELL: {
				if (graphic.isEnableEditMode()) {
					return area;
				}
				break;
			}
			default: throw new ManipulatorError('not implemented all spatial area type');
			}
		}

		return null;
	};
}

export default WorkshopTemplateSpatialAreaTree;
