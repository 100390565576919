import HoveringFrame from '../hovering/HoveringFrame';
import IMutationPermission from '../../mechanics/IMutationPermission';
import IFocusableFrame from './IFocusableFrame';
import FocusType from '../FocusType';

/**
 * Фрейм, поддерживающий фокусирование.
 */
class FocusableFrame extends HoveringFrame implements IFocusableFrame {
	private readonly FOCUS_CLASS_NAME = 'focus';
	private readonly FOCUS_LINE_CLASS_NAME = 'focus-line';

	private readonly postEnableFocusListeners: VoidFunction[];
	private readonly postDisableFocusListeners: VoidFunction[];
	private readonly postFocusChangeEvents: VoidFunction[];

	private mutationPermissions: IMutationPermission;
	private isFocus: boolean;

	constructor() {
		super();
		this.isFocus = false;
		this.mutationPermissions = {
			TOP: true,
			LEFT: true,
			RIGHT: true,
			BOTTOM: true,
			LEFT_TOP: true,
			RIGHT_TOP: true,
			LEFT_BOTTOM: true,
			RIGHT_BOTTOM: true,
		};
		this.postEnableFocusListeners = [];
		this.postDisableFocusListeners = [];
		this.postFocusChangeEvents = [];
	}

	public enableFocus = (type: FocusType) => {
		if (this.isFocus) {
			return;
		}
		this.isFocus = true;
		this.applyVisualChange(type);
		this.callPostFocusChangeEvents();
		this.runPostEnableFocusModeListeners();
	};

	public disableFocus = (type: FocusType) => {
		if (!this.isFocus) {
			return;
		}
		this.isFocus = false;
		this.applyVisualChange(type);
		this.callPostFocusChangeEvents();
		this.runPostDisableFocusModeListeners();
	};

	public addPostEnableFocusListener = (listener: VoidFunction) => {
		this.postEnableFocusListeners.push(listener);
	};

	public addPostDisableFocusListener = (listener: VoidFunction) => {
		this.postDisableFocusListeners.push(listener);
	};

	private runPostDisableFocusModeListeners = () => {
		this.postDisableFocusListeners.forEach(listener => listener());
	};

	private runPostEnableFocusModeListeners = () => {
		this.postEnableFocusListeners.forEach(listener => listener());
	};

	public setMutationPermissions = (permission: IMutationPermission) => {
		this.mutationPermissions = { ...permission };
	};

	public addPostChangeFocusEvent = (event: VoidFunction) => {
		this.postFocusChangeEvents.push(event);
	};

	public isEnableFocus = (): boolean => this.isFocus;

	public getMutationPermission = (): IMutationPermission => ({ ...this.mutationPermissions });

	// Функции визуального преображения компонента в режиме фокуса, могут быть переопределены
	protected applyFocusOnVisual = (type: FocusType) => {
		switch (type) {
		case FocusType.BORDER: {
			this.enableColorBorder();
			break;
		}
		case FocusType.LINE: {
			this.enableColorLine();
		}
		}
	};

	protected applyFocusOffVisual = (type: FocusType) => {
		switch (type) {
		case FocusType.BORDER: {
			this.disableColorBorder();
			break;
		}
		case FocusType.LINE: {
			this.disableColorLine();
		}
		}
	};

	private applyVisualChange = (type: FocusType) => {
		if (this.isFocus) {
			this.applyFocusOnVisual(type);
			return;
		}
		this.applyFocusOffVisual(type);
	};

	private callPostFocusChangeEvents = () => {
		this.postFocusChangeEvents.forEach(ev => ev());
	};

	private enableColorBorder = () => {
		this.frameElement.classList.add(this.FOCUS_CLASS_NAME);
	};

	private enableColorLine = () => {
		this.frameElement.classList.add(this.FOCUS_LINE_CLASS_NAME);
	};

	private disableColorBorder = () => {
		this.frameElement.classList.remove(this.FOCUS_CLASS_NAME);
	};

	private disableColorLine = () => {
		this.frameElement.classList.remove(this.FOCUS_LINE_CLASS_NAME);
	};
}

export default FocusableFrame;
