import IPageTexture from '../../../../graphic/page/IPageTexture';
import Graphic from '../../../../graphic/Graphic';
import Orientation from '../../../../page/Orientation';
import PagePropertiesView from './PagePropertiesView';
import IComponent from '../../../../components/IComponent';
import IMultiPageUseCases from '../../../../use-cases/IMultiPageUseCases';

class PagePropertiesPanel extends PagePropertiesView {
	private isEditablePage = false;

	constructor(
		private readonly useCases: IMultiPageUseCases,
		private readonly pagesContainer: IComponent,
	) {
		super();

		this.addPortraitClickEvent(this.onPortraitClick);
		this.addLandscapeClickEvent(this.onLandscapeClick);
		this.addSelectImageEvent(this.onSetBackgroundImageClick);
		this.addSelectColorEvent(this.onSelectBackgroundColor);
		this.addEditPageEvent(this.onEditPageClick);
		this.addSelectRepeatingImage(this.onSelectRepeatingImage);
		this.addChangeBackgroundImageSizeListener(this.onChangeBackgroundImageSizeListener);
	}

	public sync = (graphic: Graphic<IPageTexture>) => {
		const { orientation, backgroundColor, repeatingImage } = graphic.getTexture();

		if (orientation === Orientation.PORTRAIT) {
			this.enablePortrait();
		}
		if (orientation === Orientation.LANDSCAPE) {
			this.enableLandscape();
		}
		if (backgroundColor !== null) {
			this.setColor(backgroundColor);
		}
		if (repeatingImage !== null) {
			this.setRepeatingImageSize(repeatingImage.size);
		} else {
			this.hideRepeatingImageSizeBlock();
		}
	};

	private onSetBackgroundImageClick = () => {
		this.useCases.setPageBackgroundImage();
	};

	private onPortraitClick = () => {
		this.enablePortrait();
		this.useCases.setPageOrientation(Orientation.PORTRAIT);
	};

	private onLandscapeClick = () => {
		this.enableLandscape();
		this.useCases.setPageOrientation(Orientation.LANDSCAPE);
	};

	private onEditPageClick = () => {
		if (this.isEditablePage) {
			this.disableEditPage();
			this.isEditablePage = false;
			this.pagesContainer.disableEditMode();
			return;
		}
		this.isEditablePage = true;
		this.enableEditPage();
		this.pagesContainer.enableEditMode();
	};

	private onSelectBackgroundColor = (color: string) => {
		this.useCases.setPageBackgroundColor(color);
	};

	private onSelectRepeatingImage = () => {
		this.useCases.setPageRepeatingImage();
	};

	private onChangeBackgroundImageSizeListener = (value: number) => {
		this.useCases.setRepeatingImageSize(value);
	};
}

export default PagePropertiesPanel;
