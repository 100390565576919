import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ICustomCalendarProps } from '../model/types';
import { useCustomRuLocale } from '../libs/useCustomRuLocale';
import 'react-datepicker/dist/react-datepicker.css';
import './datetime-picker.css';

export const CustomDateTimePicker = (props: ICustomCalendarProps) => {
	const {
		value, maxDate, setValue, minDate, setOpen,
	} = props;

	const [selectedDate, setSelectedDate] = useState<Date | null>(value || null);
	const [hasTimeBeenSelected, setHasTimeBeenSelected] = useState<boolean>(false);

	const handleDateChange = (date: Date | null) => {
		if (date) {
			setSelectedDate(date);
			setValue?.(date);

			if (hasTimeBeenSelected) {
				setOpen(false);
			} else {
				setHasTimeBeenSelected(true);
			}
		}
	};

	useEffect(() => {
		registerLocale('customRu', useCustomRuLocale);
	}, []);

	return (
		<div className="date-time-picker_container">
			<DatePicker
				selected={selectedDate}
				maxDate={maxDate || undefined}
				onChange={handleDateChange}
				minDate={minDate || undefined}
				className="date-time-picker"
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={60}
				timeCaption="Время"
				dateFormat="dd.MM.yyyy HH:mm"
				locale="customRu"
				inline
			/>
		</div>
	);
};
