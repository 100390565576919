import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import TextComponent from '../../components/text/TextComponent';
import IComponentStructure from '../../components/IComponentStructure';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import ITextTexture from '../../graphic/text/ITextTexture';
import CSSCursor from '../../cursor/CSSCursor';
import { Token, TokenFormat, TokenType } from '../../mechanics/mext/parser';
import {
	FontFamily, FontSize, Model, TextAlign,
} from '../../mechanics/mext/editor/types';
import TextGraphic from '../../graphic/text/TextGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TextInjectArea from './TextInjectArea';

interface ITextDefaultStructureProps {
	y: number
	x: number,
	width: number,
	height: number,
	componentOffset: number,
}

interface IGetLoadedTokenProps {
	loadedToken: string | Token[];
}

export interface ITextInjectorDependencies extends IComponentInjectorDependencies {}

/**
 * Инжектор для добавления текстового компонента.
 */
class TextInjector extends ConstructorInjector<ITextInjectorDependencies> {
	private readonly DEFAULT_WIDTH = 50;
	private readonly DEFAULT_HEIGHT = 28;

	private loadedToken: string | Token[] | null;

	private readonly textInjectArea: TextInjectArea;

	constructor() {
		super();
		this.loadedToken = null;

		this.textInjectArea = new TextInjectArea();

		this.addPostInjectDependenciesListener(dependencies => {
			const rootElement = dependencies.componentTree.getWorkAreaElement();
			this.textInjectArea.connectDependencies({
				rootElement,
				mousePositionObserver: this.dependencies.mousePositionObserver,
			});
			this.textInjectArea.injectDependencies();
		});
	}

	/**
	 * Запускает процесс инжекции текстового компонента.
	 */
	public run = (): void => {
		this.dependencies.cursorView.enableComponentInjectMode();
		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		this.isProcess = true;
		this.setNotReadyInject();
	};

	/**
	 * Загружает токен текста.
	 * @param token - токен текста.
	 */
	public loadToken = (token: string | Token[]): void => {
		this.loadedToken = token;
	};

	/**
	 * Инжектирует текстовый компонент.
	 */
	public inject = () => {
		const isReady = this.isReadyInject();
		if (!isReady) {
			throw new ManipulatorError('injector not ready inject component');
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();

		// Получаем область вставки текста
		const area = this.textInjectArea.getArea();
		const injectParams = this.calculateAreaComponentInjectionParams(area);
		const isDefaultSize = area.height === 0 || area.width === 0;
		let width: number;
		let height: number;
		let isEnableAutoWidth = false;
		if (isDefaultSize) {
			width = this.DEFAULT_WIDTH;
			height = this.DEFAULT_HEIGHT;
			isEnableAutoWidth = true;
		} else {
			const heightSmallerThenMin = area.height < this.DEFAULT_HEIGHT;
			const widthSmallerThenMin = area.width < this.DEFAULT_WIDTH;

			if (heightSmallerThenMin && widthSmallerThenMin) {
				height = this.DEFAULT_HEIGHT;
				width = this.DEFAULT_WIDTH;
				isEnableAutoWidth = true;
			} else if (widthSmallerThenMin) {
				height = area.height;
				width = this.DEFAULT_WIDTH;
				isEnableAutoWidth = true;
			} else if (heightSmallerThenMin) {
				height = this.DEFAULT_HEIGHT;
				width = area.width;
			} else {
				height = area.height;
				width = area.width;
			}
		}

		const x = area.x - injectParams.x;
		const y = area.y - injectParams.y;

		this.loadedToken = [{
			type: TokenType.Text,
			// value: '\t',
			value: '',
			format: TokenFormat.None,
			textAlign: TextAlign.LEFT,
			color: '#000',
			fontFamily: FontFamily.Default,
			fontSize: FontSize.Pt14,
			lineHeight: 1,
			sticky: true,
		}];

		const structure = this.getLoadedStructure(
			{
				x,
				y,
				height,
				width,
				componentOffset: injectParams.componentOffset,
			},
			{ loadedToken: this.loadedToken },
		);

		let textComponent: TextComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			textComponent = tools.componentFactory.createComponent<TextComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<TextGraphic>(graphicStructure.type, textComponent);
				graphic.setStructure(() => graphicStructure);
				textComponent.appendGraphic(graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, textComponent);

			textComponent.enableFocus();
			textComponent.enableEditMode();
			if (isEnableAutoWidth) {
				textComponent.enableAutoWidth();
			}

			setTimeout(textComponent.setCarriage.bind(this, 0), 0);

			this.callPostInjectListeners(textComponent);
		});

		this.stop();
		this.callSingleUseInjectListeners(textComponent!);

		this.loadedToken = null;
	};

	/**
	 * Останавливает процесс инжекции текстового компонента.
	 */
	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.isProcess = false;
		this.textInjectArea.clearArea();
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 */
	public override onMouseDown = () => {
		const position = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.textInjectArea.start(position);
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 */
	public override onMouseUp = () => {
		this.setReadyInject();
		this.inject();
		this.textInjectArea.stop();
	};

	/**
	 * Возвращает структуру текстового компонента по умолчанию.
	 */
	protected getDefaultStructure = (props: ITextDefaultStructureProps): IComponentStructure<null> => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		return {
			id: componentId,
			type: SketchComponentType.TEXT,
			offset: props.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.TEXT,
					offset: 0,
					frame: {
						x: props.x,
						y: props.y,
						width: props.width,
						height: props.height,
						rotate: 0,
						layer: 100,
					},
					texture: {
						content: this.getDefaultTextModel(),
					} as ITextTexture,
				},
			],
			texture: null,
			components: null,
		};
	};

	/**
	 * Возвращает структуру текстового компонента с загруженными данными.
	 */
	protected getLoadedStructure = (
		props: ITextDefaultStructureProps,
		token: IGetLoadedTokenProps,
	): IComponentStructure<null> => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		return {
			id: componentId,
			type: SketchComponentType.TEXT,
			offset: props.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.TEXT,
					offset: 0,
					frame: {
						x: props.x,
						y: props.y,
						width: props.width,
						height: props.height,
						rotate: 0,
						layer: 100,
					},
					texture: {
						content: this.getLoadedTextModel(token.loadedToken),
					} as ITextTexture,
				},
			],
			texture: null,
			components: null,
		};
	};

	private getLoadedTextModel = (token: string | Token[]): Model => ({
		id: Utils.Generate.UUID4(),
		tokens: token as Token[],
		lineHeight: 1,
	});

	/**
	 * Возвращает модель текста по умолчанию.
	 */
	private getDefaultTextModel = (): Model => ({
		id: Utils.Generate.UUID4(),
		tokens: [{
			type: TokenType.Text,
			value: '',
			fontSize: FontSize.Pt14,
			fontFamily: FontFamily.Default,
			color: '#000000',
			format: 0,
			textAlign: TextAlign.LEFT,
			lineHeight: 1,
			sticky: true,
		}],
		lineHeight: 1,
	});
}

export default TextInjector;
