import ConfirmModal from '../../../../../components/modals/common/confirmModal/ConfirmModal';
import { NewModal } from '../../../../../components/ui/modals/new-modal';
import { useAppSelector } from '../../../../../shared/libs/redux';
import { useDeleteWorkshopTemplate } from '../api/useDeleteWorkshopTemplate';

interface DeleteWorkshopTemplateModalProps {
	choicedTemplateData: string[],
}

export const DeleteWorkshopTemplateModal = ({
	choicedTemplateData,
}: DeleteWorkshopTemplateModalProps) => {
	const isActive = useAppSelector(state => state.workshopTemplate.isDeleteModalActive);

	const {
		isLoading,
		setActive,
		onDeleteTemplate,
	} = useDeleteWorkshopTemplate();

	return (
		<NewModal
			active={isActive}
			closeModal={setActive}
		>
			<ConfirmModal
				disabled={isLoading}
				isModalActive={isActive}
				onDelete={onDeleteTemplate}
				onCloseModal={() => setActive(false)}
				text={`Вы действительно хотите удалить ${
					choicedTemplateData?.length === 1 ? 'шаблон' : 'шаблоны'
				} без возможности восстановления?`}
			/>
		</NewModal>
	);
};
