import ManipulatorArea, { IManipulatorAreaDependencies } from '../../mechanics/ManipulatorArea';

interface ILineInjectAreaDependencies extends IManipulatorAreaDependencies {}

/**
 * Область, определяющая размеры создаваемой линии.
 */
class LineInjectArea extends ManipulatorArea<ILineInjectAreaDependencies> {
	private readonly AREA_CLASS_NAME = 'pages-container__figure-area';

	constructor() {
		super();
		this.setAreaClassName(this.AREA_CLASS_NAME);
	}
}

export default LineInjectArea;
