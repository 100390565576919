import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import MagneticLineEntity from '../MagneticLineEntity';
import IGraphic from '../../../graphic/IGraphic';
import IFrameConfiguration from '../../../frame/IFrameConfiguration';
import { AnyAreaMutator } from '../../../Types';
import ILinesState from '../magnetic-line/ILinesState';
import IMagneticLines from '../IMagneticLines';

abstract class MagneticCorrector<MutatorType extends AnyAreaMutator> {
	protected readonly magneticLines: IMagneticLines;
	protected readonly mutator: MutatorType;
	protected readonly linesState: ILinesState;
	protected startConfiguration: IFrameConfiguration | null;
	protected initiatorGraphic: IGraphic | null;

	protected constructor(magneticLines: IMagneticLines, areaPositionMutator: MutatorType) {
		this.magneticLines = magneticLines;
		this.mutator = areaPositionMutator;
		this.linesState = {
			enabledLineX: null,
			enabledLineY: null,
			disabledLines: [],
		};
	}

	/**
	 * Обрабатывает движение объектов графики и их притяжение к магнитным линиям во время изменения положения.
	 */
	public move = () => {
		const magneticLineEntities = this.magneticLines.getMagneticLines();
		this.startConfiguration = this.magneticLines.getStartConfiguration();
		this.initiatorGraphic = this.magneticLines.getInitiatorGraphic();

		if (magneticLineEntities.length === 0) {
			return;
		}

		if (this.startConfiguration === null) {
			throw new ManipulatorError('required entities not found');
		}

		const dryConfiguration = this.mutator.getDryConfiguration(this.startConfiguration);
		const correctValues: IFrameArea = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
		};

		this.linesState.disabledLines.length = 0;

		this.calculateCorrectValues(magneticLineEntities, dryConfiguration, correctValues);

		if (this.linesState.enabledLineX !== null) {
			this.linesState.enabledLineX.show();
		}
		if (this.linesState.enabledLineY !== null) {
			this.linesState.enabledLineY.show();
		}

		for (let i = 0; i < this.linesState.disabledLines.length; i++) {
			this.linesState.disabledLines[i].hide();
		}
	};

	protected abstract calculateCorrectValues: (
		magneticLineEntities: MagneticLineEntity[],
		dryConfiguration: IFrameArea,
		correctValues: IFrameArea,
	) => void;
}

export default MagneticCorrector;
