import { useState } from 'react';
import { SelectUserModal } from '../../../../../../widgets/select-user-modal';
import { useAppDispatch, useAppSelector } from '../../../../../../shared/libs/redux';
import {
	setIsWorkshopFilterUserModalActive,
	setPublishedFilter,
} from '../../../../../../entities/templates/workshop/template/model/slice';
import { IAccount } from '../../../../../../entities/user/account/api/types';

export const SelectWorkshopUserFilterModal = () => {
	const dispatch = useAppDispatch();

	const [selectedUser, setSelectedUser] = useState<IAccount | undefined>(undefined);

	const {
		isFilterUserModalActive: isActive,
	} = useAppSelector(state => state.workshopTemplate);

	const setActive = (value: boolean) => dispatch(setIsWorkshopFilterUserModalActive(value));

	const onSelect = (user: IAccount) => {
		if (selectedUser?.email === user.email) {
			setSelectedUser(undefined);
		} else {
			setSelectedUser(user);
		}
	};

	const save = () => {
		if (selectedUser) {
			dispatch(setPublishedFilter({
				id: selectedUser.email,
				text: selectedUser.name,
			}));
			setActive(false);
		}
	};

	return (
		<SelectUserModal
			onSave={save}
			isLoading={false}
			onSelect={onSelect}
			isActive={isActive}
			setActive={setActive}
			selectedUser={selectedUser?.email || ''}
		/>
	);
};
