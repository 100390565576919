import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setIsCreateManagerModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { cleanInputValues, useInput } from '../../../../hooks/useInput';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { managerAPI } from '../../../../entities/manager/api/api';

export const useAddManager = () => {
	const dispatch = useAppDispatch();

	const handleCreateManagerModal = (value: boolean) => {
		dispatch(setIsCreateManagerModalActive(value));
	};

	const createManagerName = useInput('', {
		isEmpty: true,
		minLength: 3,
		maxLength: 255,
	});
	const createManagerEmail = useInput('', {
		isEmpty: true,
		minLength: 5,
		isEmail: true,
		maxLength: 255,
	});
	const createManagerPhone = useInput('', {
		isEmpty: true,
		minLength: 15,
		isPhone: true,
		maxLength: 16,
	});

	const addingManagerLegalEntityId = useAppSelector(state => state.legalEntity.addingManagerLegalEntityId);

	const [create, { isLoading }] = managerAPI.useCreateMutation();

	// * Создание менеджеров
	const onAddManager = async () => {
		if (
			addingManagerLegalEntityId
			&& createManagerEmail.value
			&& createManagerName.value
			&& createManagerPhone.value
		) {
			try {
				await create({
					email: createManagerEmail.value,
					legalEntity: addingManagerLegalEntityId,
					name: createManagerName.value,
					phone: createManagerPhone.value.split(' ')
						.join(''),
				})
					.unwrap();
				handleCreateManagerModal(false);
				cleanInputValues(
					[createManagerEmail, createManagerName, createManagerPhone],
				);
				notificationSuccess('Менджер добавлен успешно!');
			} catch (err) {
				notificationError('Произошла ошибка при создании менджера...');
			}
		}
	};

	return {
		createManagerEmail,
		createManagerPhone,
		createManagerName,
		onAddManager,
		isLoading,
		handleCreateManagerModal,
	};
};
