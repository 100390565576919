import React from 'react';
import { CategoryCard } from '../../../../../entities/modules/category';
import { ICategoryData } from '../../../../../entities/modules/category/api/types';
import { ReactComponent as EditCategoryIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteCategoryIcon } from '../../assets/delete.svg';
import css from './EditingCategoryCard.module.scss';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import {
	setDeletingCategoryId,
	setEditingCategoryId, setIsDeletingCategoryModalActive,
	setIsEditCategoryModalActive,
} from '../../../../../redux/reducers/module/categoryReducer';

interface EditingCategoryCardProps {
	category: ICategoryData,
}

export const EditingCategoryCard = ({ category }: EditingCategoryCardProps) => {
	const {
		id,
	} = category;

	const dispatch = useAppDispatch();

	const onEditCategory = () => {
		dispatch(setEditingCategoryId(id));
		dispatch(setIsEditCategoryModalActive(true));
	};

	const onDeleteCategory = () => {
		dispatch(setDeletingCategoryId(id));
		dispatch(setIsDeletingCategoryModalActive(true));
	};

	return (
		<CategoryCard
			category={category}
			actions={(
				<div className={css.actions}>
					<div
						onClick={onEditCategory}
						role="presentation"
					>
						<EditCategoryIcon />
					</div>
					<div
						onClick={onDeleteCategory}
						role="presentation"
					>
						<DeleteCategoryIcon />
					</div>
				</div>
			)}
		/>
	);
};
