import ReactDOM from 'react-dom';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { aiSlice, getCategories, getOnCloseAiModalFunc } from '../../model/slice/aiSlice';
import css from './ai-category-modal.module.scss';
import CloseModalBtn from '../../../../components/ui/modals/closeModalBtn/CloseModalBtn';
import { portal } from '../../../../components/ui/modals/new-modal/ui/modal';
import { AiCategory } from '../ai-category/AiCategory';
import { GreetingModal } from '../text-generation-modals/greeting-modal/GreetingModal';
import { ProductDescriptionModal } from '../text-generation-modals/product-description-modal/ProductDescriptionModal';
import { AboutCompanyModal } from '../text-generation-modals/about-company-modal/AboutCompanyModal';
import {
	AdvantagesDescriptionModal,
} from '../text-generation-modals/advantages-description-modal/AdvantagesDescriptionModal';
import { AdvantagesListModal } from '../text-generation-modals/advantages-list-modal/AdvantagesListModal';
import { TeamModal } from '../text-generation-modals/team-modal/TeamModal';
import { SloganModal } from '../text-generation-modals/slogan-modal/SloganModal';
import { UniquePropositionalModal } from '../text-generation-modals/unique-proposition-modal/UniquePropositionModal';
import { WorkStagesModal } from '../text-generation-modals/work-stages-modal/WorkStagesModal';
import { useModal } from '../../../../hooks/ai/useAiModal';
import useOutsideClick from '../../../../hooks/ai/useClickOutside';

export const AiCategoryModal = () => {
	const dispatch = useAppDispatch();
	const isActive = useSelector(aiSlice.selectors.getIsAiCategoriesModalActive);
	const onCloseModal = useAppSelector(state => getOnCloseAiModalFunc(state));
	const closeModalRef = useRef<HTMLDivElement>(null);

	const categories = useAppSelector((state) => getCategories(state));

	const closeModal = () => {
		dispatch(aiSlice.actions.setIsAiCategoriesModalOpen(false));
		if (onCloseModal !== null) onCloseModal();
		dispatch(aiSlice.actions.setAiMode(false));
	};

	const {
		modalRef,
	} = useModal(closeModal, isActive);

	useOutsideClick(closeModalRef, closeModal);

	return (
		<>
			{
				ReactDOM.createPortal(
					<CSSTransition
						in={isActive}
						timeout={100}
						nodeRef={modalRef}
						classNames={{
							enter: css.modal_enter,
							enterActive: css.modal_enter_active,
							exit: css.modal_exit,
							exitActive: css.modal_exit_active,
						}}
						unmountOnExit
					>
						<div
							className={css.modal}
							ref={modalRef}
							role="presentation"
						>
							<div
								className={cx(css.content_wrapper)}
								ref={closeModalRef}
							>
								<div className={css.header}>
									<h3 className={css.title}>
										Интеллект
									</h3>
									<CloseModalBtn onClose={closeModal} className={css.closeBtn} />
								</div>
								<div className={css.tabs}>
									Текст
								</div>

								{categories && categories.map((cat) => (
									<AiCategory
										key={cat.title}
										title={cat.title}
										childs={cat.childs}
									/>
								))}

							</div>
						</div>
					</CSSTransition>,
					portal as Element,
				)
			}
			<GreetingModal />
			<ProductDescriptionModal />
			<AboutCompanyModal />
			<AdvantagesDescriptionModal />
			<AdvantagesListModal />
			<TeamModal />
			<SloganModal />
			<UniquePropositionalModal />
			<WorkStagesModal />
		</>
	);
};
