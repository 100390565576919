import store from '../../../redux/store/store';
import { offerAPI } from '../../../entities/offer/api/api';
import { templatePrivateAPI } from '../../../entities/templates/private/api/api';
import { IOfferPresentation } from '../../../entities/offer/api/types';

export type LoadStructureFunctionType = (id: string) => Promise<IOfferPresentation>;

export const loadOfferStructure: LoadStructureFunctionType = async (
	id: string,
) => store.dispatch(offerAPI.endpoints?.presentation.initiate({ id }))
	.unwrap();

export const loadTemplateStructure: LoadStructureFunctionType = async (
	id: string,
) => store.dispatch(templatePrivateAPI.endpoints?.getWithoutRegistration.initiate({ id }))
	.unwrap()
	.then(res => ({
		id,
		name: res.template.name,
		rootComponent: res.rootComponent,
	}));
