import React from 'react';
import { AdminUserMainInfoItem } from './item/item';
import css from './main-info.module.scss';
import { AdminUserActiveStatus } from './active-status/active-status';
import { ProfilePicture } from '../../../../../../shared/ui';
import { IAccount } from '../../../../../../entities/user/account/api/types';

interface UserMainInfoProps {
	data: IAccount | null | undefined,
}

export const AdminUserMainInfo = ({ data }: UserMainInfoProps) => (
	<div className={css.wrapper}>
		{data?.photo && <ProfilePicture src={data.photo} />}
		<div className={css.info}>
			<AdminUserActiveStatus tariff={data?.tariff} />
			<div className={css.title}>Контактная информация</div>
			<div className={css.contact_info}>
				<AdminUserMainInfoItem value={data?.name} description="ФИО" />
				<AdminUserMainInfoItem value={data?.phone} description="Телефон" />
				<AdminUserMainInfoItem value={data?.email} description="E-mail" />
			</div>
		</div>
	</div>
);
