enum KeyCombination {
	CTRL_G,
	CTRL_SHIFT_G,
	SHIFT_T,
	SHIFT_K,
	SHIFT_F,
	SHIFT_L,
	DELETE,
	ESCAPE,
	CTRL_Z,
	CTRL_SHIFT_Z,
	CTRL_C,
	CTRL_V,
	CTRL_X,
	CTRL,
	ARROW_UP,
	ARROW_DOWN,
	ARROW_LEFT,
	ARROW_RIGHT,
	SHIFT,
	ALT,
	// 	AI
	CTRL_ALT_M,
	CTRL_ARROW_UP,
	CTRL_ARROW_DOWN,
	CTRL_ARROW_LEFT,
	CTRL_ARROW_RIGHT,
}

export default KeyCombination;
