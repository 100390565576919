import React from 'react';
import css from './group.module.scss';
import { ReactComponent as ArrowIcon } from '../../images/arrow-icon.svg';
import { ITagData } from '../../../../../entities/modules/tag/api/types';
import { classNames, Mods } from '../../../../../shared/libs/classNames';

interface TagScrollSearchGroupProps {
	isOpen: boolean, // Для стилей открытой группы тегов
	data: ITagData,
	onClick: (value: number) => void,
	selected?: boolean,
}

export const TagScrollSearchGroup = ({
	data,
	isOpen,
	onClick,
	selected = false,
}: TagScrollSearchGroupProps) => {
	const mods: Mods = {
		[css.open]: isOpen,
		[css.selected]: selected,
	};

	return (
		<div
			role="presentation"
			onClick={() => onClick(data.id)}
			className={classNames(css.wrapper, mods)}
		>
			<div className={css.text}>{data.name}</div>
			<div className={css.icon}>
				<ArrowIcon />
			</div>
		</div>
	);
};
