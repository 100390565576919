import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { offerAPI } from '../../../../../../entities/offer/api/api';
import Presentation from './Presentation';
import { templatePrivateAPI } from '../../../../../../entities/templates/private/api/api';

const PresentationWrapper = () => {
	const { id } = useParams();

	const { data: offerPresentation } = offerAPI.usePresentationQuery({ id: id || '' }, { skip: !id });
	const { data: templatePresentation } = templatePrivateAPI.useGetQuery({ id: id || '' }, { skip: !id });

	return (
		(
			<Routes>
				{offerPresentation && (
					<Route
						path="offer/:id"
						element={(
							<Presentation
								structure={{
									...offerPresentation,
									root: offerPresentation.rootComponent,
								}}
							/>
						)}
					/>
				)}
				{templatePresentation && (
					<Route
						path="template/:id"
						element={(
							<Presentation
								structure={{
									...templatePresentation.template,
									root: templatePresentation.rootComponent,
								}}
							/>
						)}
					/>
				)}
			</Routes>
		)
	);
};

export default PresentationWrapper;
