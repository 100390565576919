import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, providesList } from '../../../shared/api/api';
import { notificationError } from '../../../components/Notifications/callNotifcation';
import { URI_LEGAL_ENTITY } from './consts';
import {
	IBindLegalEntityListRequest,
	IGetLegalEntityListRequest,
	IGetLegalEntityResponse,
	IUntieLegalEntityListRequest,
} from './types';
import { setLegalEntityList as setPermissionsLegalEntities } from '../../../redux/reducers/permissionsReducer';
import { setLegalEntityList } from '../../../redux/reducers/legalEntityReducer';
import { IGetEmployeeListResponse } from '../../employee/api/types';

export const legalEntityAPI = createApi({
	reducerPath: 'legalEntityAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['legal_entity'],
	endpoints: builder => ({
		getAll: builder.query<IGetLegalEntityResponse, IGetLegalEntityListRequest>({
			query: (data) => ({
				url: URI_LEGAL_ENTITY,
				method: 'GET',
				params: data,
			}),
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const { data } = await queryFulfilled;

					dispatch(setLegalEntityList(data.legalEntities));
					dispatch(setPermissionsLegalEntities(data));
				} catch (error) {
					notificationError('Возникла ошибка при загрузке юр. лиц....');
				}
			},
			providesTags: (result) => providesList(result?.legalEntities, 'legal_entity'),
		}),
		getAvailableAccounts: builder.query<IGetEmployeeListResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_LEGAL_ENTITY}/${id}/accounts`,
				method: 'GET',
			}),
			providesTags: ['legal_entity'],
		}),
		create: builder.mutation<{ id: string }, { name: string }>({
			query: ({ name }) => ({
				url: URI_LEGAL_ENTITY,
				method: 'POST',
				body: {
					name,
				},
			}),
			invalidatesTags: ['legal_entity'],
		}),
		bind: builder.mutation<void, IBindLegalEntityListRequest>({
			query: ({
				emails,
				legalEntity,
			}) => ({
				url: `${URI_LEGAL_ENTITY}/${legalEntity}/bind`,
				method: 'POST',
				body: {
					emails,
				},
			}),
			invalidatesTags: ['legal_entity'],
		}),
		unbind: builder.mutation<void, IUntieLegalEntityListRequest>({
			query: ({
				email,
				legalEntity,
			}) => ({
				url: `${URI_LEGAL_ENTITY}/${legalEntity}/unbind`,
				method: 'DELETE',
				body: {
					email,
				},
			}),
			invalidatesTags: ['legal_entity'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_LEGAL_ENTITY}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['legal_entity'],
		}),
	}),
});
