import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import css from './offer_settings.module.scss';
import Button, { buttonsTypes } from '../../../../../ui/button/button';
import { ReactComponent as SettingsBtnGoBackIcon } from '../images/settings-btn-go-back.svg';
import { ReactComponent as SettingsChangeIcon } from '../images/settings-change.svg';
import useCatchEnterClick from '../../../../../../hooks/useCatchEnterClick';

const MAX_INPUT_LENGTH = 60;

interface OfferSettingsHeaderProps {
	title: string,
	setTitle: (title: string) => void,
	onGoBack: () => void,
	isChangeTemplateOpen: boolean,
	onCloseChangeTemplate: () => void,
}

const Header = ({
	isChangeTemplateOpen,
	title,
	setTitle,
	onGoBack,
	onCloseChangeTemplate,
}: OfferSettingsHeaderProps) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [isInputFocused, setIsInputFocused] = useState(false);
	const [inputWidthState, setInputWidthState] = useState('');

	// * Обработчик клика на клавишу вернуться назад
	const btnClickHandler = () => {
		if (isChangeTemplateOpen) {
			onCloseChangeTemplate();
		} else {
			onGoBack();
		}
	};

	// * Включает editMode
	const enableEditMode = () => {
		setIsEditMode(true);
		setIsInputFocused(true);
	};

	// * Выключает editMode
	const disableEditMode = () => {
		setIsEditMode(false);
		setIsInputFocused(false);
	};

	// * Функция для изменения текста
	const setInputWidth = () => {
		if (title) {
			const inputWidth = (title.length + 5) * 10;
			setInputWidthState(`${inputWidth}px`);
		}
	};

	// * При клике на title
	const onTitleClick = () => {
		enableEditMode();
		setInputWidth();
	};

	// * При клике на кнопку "изменить"
	const onEditButtonClick = () => {
		if (isEditMode) {
			disableEditMode();
			return;
		}
		enableEditMode();
	};

	useCatchEnterClick(disableEditMode, true);

	// * Функции для изменения templateTitle

	// * При изменении значения inputFocused устанавливает focus на инпут
	useEffect(() => {
		if (!isInputFocused) return;

		const currentInput = document.getElementById('templateTitle');
		currentInput?.focus();
		setIsInputFocused(false);
	}, [isInputFocused]);

	// * Обновляет размер инпута при изменении текста
	useEffect(() => {
		setInputWidth();
	}, [setInputWidth, title]);

	const inputStyles = {
		width: inputWidthState,
		display: `${isEditMode ? 'block' : 'none'}`,
	};

	const titleStyles = { display: `${isEditMode ? 'none' : 'block'}` };

	return (
		<div className={css.header}>
			<Button
				onClick={btnClickHandler}
				text="Вернуться назад"
				buttonType={buttonsTypes.LIGHT}
				cssClass={css.btn}
				inActive
			>
				<SettingsBtnGoBackIcon />
			</Button>
			<div className={css.title}>
				<input
					id="templateTitle"
					className="template-name__input"
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					style={inputStyles}
					maxLength={MAX_INPUT_LENGTH}
				/>
				<div
					className="template-name__text"
					style={titleStyles}
					onClick={onTitleClick}
					role="presentation"
				>
					{title}
				</div>
				<div
					className={cx(css.svgBlock, isEditMode && css.activeSvg)}
					onClick={onEditButtonClick}
					role="presentation"
				>
					<SettingsChangeIcon />
				</div>
			</div>
		</div>
	);
};

export default Header;
