import React, { useEffect } from 'react';
import { cleanInputValues, useInputValue } from '../../../../../hooks/useInput';
import Button, { buttonsTypes } from '../../../../ui/button/button';
import InputField from '../../../registration/InputField/InputField';
import css from './createModalBody.module.scss';

interface CreateUserProps {
	name: useInputValue,
	email: useInputValue,
	phone: useInputValue,
	isActive: boolean,
	title?: string,
	onCreating: () => void,
	isDataLoading: boolean,
}

const CreateUser = ({
	email,
	name,
	phone,
	isDataLoading,
	title = 'Создать пользователя',
	isActive,
	onCreating,
}: CreateUserProps) => {
	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter'
			&& name.inputValid
			&& email.inputValid
			&& phone.inputValid
			&& !isDataLoading
		) {
			onCreating();
		}
	};

	useEffect(() => {
		if (!isActive) {
			cleanInputValues([name, email, phone]);
		}
	}, [isActive]);

	return (
		<div className={css.wrapper}>
			<div className={css.title}>{title}</div>
			<InputField
				placeholder="ФИО"
				type="text"
				value={name.value}
				onChange={e => name.onChange(e, 'name')}
				onBlur={name.onBlur}
				status={
					name.isEmpty || name.minLengthError || name.maxLengthError
						? 'error'
						: 'success'
				}
				keyDown={e => {
					handleKeyPress(e);
				}}
			/>
			<InputField
				placeholder="Телефон"
				type="tel"
				status={
					(phone.isEmpty || phone.phoneError)
						? 'error'
						: 'success'
				}
				value={phone.value}
				onChange={e => phone.onChange(e, 'phone')}
				onBlur={phone.onBlur}
				keyDown={e => {
					handleKeyPress(e);
				}}
			/>
			<InputField
				placeholder="E-mail"
				type="email"
				status={
					(email.emailError || email.isEmpty || email.minLengthError || email.maxLengthError)
						? 'error'
						: 'success'
				}
				value={email.value}
				onChange={e => email.onChange(e, 'email')}
				onBlur={email.onBlur}
				keyDown={e => {
					handleKeyPress(e);
				}}
			/>
			<Button
				onClick={onCreating}
				text={title}
				buttonType={buttonsTypes.BRIGHT}
				cssClass={css.userCreateBtn}
				inActive={
					name.inputValid
					&& phone.inputValid
					&& email.inputValid
				}
				inProcess={isDataLoading}
			/>
		</div>
	);
};

export default CreateUser;
