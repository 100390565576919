import ColorexColor from './colors/ColorexColor';
import ColorexPalette from './ColorexPalette';

export class ColorexCore {
	private readonly DEFAULT_MAX_FREQUENTLY_COLORS_COUNT = 7;
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex-container';
	private readonly paletteContainerElement: HTMLElement;
	private readonly palettes: ColorexPalette[];

	private maxFrequentlyColorCount: number;
	private frequentlyColors: ColorexColor[];

	constructor() {
		this.maxFrequentlyColorCount = this.DEFAULT_MAX_FREQUENTLY_COLORS_COUNT;
		this.paletteContainerElement = document.createElement('div');
		this.paletteContainerElement.className = this.CSS_ELEMENT_CLASS_NAME;
		document.body.append(this.paletteContainerElement);
		document.addEventListener('mouseup', this.onDocumentClick);

		this.palettes = [];
		this.frequentlyColors = [];
	}

	public setMaxFrequentlyColorCount = (count: number) => {
		this.maxFrequentlyColorCount = count;
	};

	public getFrequentlyColors = (): ColorexColor[] => [...this.frequentlyColors];

	public pushFrequentlyColor = (color: ColorexColor) => {
		const isFound = this.frequentlyColors.find(c => c === color);
		if (isFound) {
			const colorIndex = this.frequentlyColors.indexOf(color);
			this.frequentlyColors = [
				...this.frequentlyColors.slice(0, colorIndex),
				...this.frequentlyColors.slice(colorIndex + 1),
				color,
			];
		} else {
			if (this.frequentlyColors.length === this.maxFrequentlyColorCount) {
				this.frequentlyColors = [
					...this.frequentlyColors.slice(1),
					color,
				];
				return;
			}
			this.frequentlyColors.push(color);
		}
	};

	public connectPalette = (palette: ColorexPalette) => {
		this.palettes.push(palette);
	};

	public getContainer = () => this.paletteContainerElement;

	private onDocumentClick = (ev: MouseEvent) => {
		if (ev.target instanceof HTMLElement) {
			for (let i = 0; i < this.palettes.length; i++) {
				const palette = this.palettes[i];
				const paletteClassName = palette.getClassName();
				const isPaletteClick = ev.target.closest(`.${paletteClassName}`);
				const targetElement = palette.getTargetElement();
				if (targetElement instanceof HTMLElement) {
					const isTargetClick = targetElement.contains(ev.target);
					if (!isTargetClick && isPaletteClick === null) {
						const isShow = palette.isShow();
						if (isShow) {
							palette.hidden();
						}
					}
				}
			}
		}
	};
}

/** Ядро цветовых палитр, для синхронизации цветов среди всех запущенных экземпляров манипуляторов пользователем */
export const manipulatorColorexCore = new ColorexCore();
