import HTMLGenerator from '../../../../utils/HTMLGenerator';

/**
 * Панель изменения масштаба. Добавлен css-класс hidden - так как функционал пока не готов.
 */
export class ZoomTool {
	private readonly ELEMENT_CLASSNAME = 'panel-zoom__zoom hidden';
	private readonly ZOOM_STEP = 100;
	private readonly element: HTMLElement;
	private readonly increaseBtn: HTMLElement;
	private readonly currentSize: HTMLElement;
	private readonly decreaseBtn: HTMLElement;

	constructor() {
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASSNAME,
		});
		// Создаём и записываем в переменную элемент управления "уменьшить размер"
		this.decreaseBtn = HTMLGenerator.getDiv({
			className: 'panel-zoom__zoom-size-decrease',
			fnClick: () => {
				this.decreaseSize();
			},
		});
		this.decreaseBtn.insertAdjacentHTML('beforeend', `
			<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
			<path d="M0 8C0 3.58172 3.58172 0 8 0H36V36H8C3.58172 36 0 32.4183 0 28V8Z" fill="white"/>
			<path d="M12.167 18H23.8337" stroke="#272727" stroke-width="1.66667" stroke-linecap="round" 
			stroke-linejoin="round"/>
			</svg>
		`);
		// Создаём и записываем в переменную элемент управления "увеличить размер"
		const increase = HTMLGenerator.getDiv({
			className: 'panel-zoom__zoom-size-increase',
			fnClick: () => { this.increaseSize(); },
		});
		this.increaseBtn = increase;
		this.increaseBtn.insertAdjacentHTML('beforeend', `
			<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
			<path d="M0 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H0V0Z" fill="white"/>
			<path d="M18.0003 12.167V23.8337M12.167 18.0003H23.8337" stroke="#272727" stroke-width="1.66667" 
			stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
		`);

		this.currentSize = HTMLGenerator.getDiv({
			className: 'panel-zoom__zoom-size-increase',
		});
		this.currentSize.insertAdjacentHTML('beforeend', `<div class="panel-zoom__zoom-size">100%</div>
		`);

		this.element.append(this.decreaseBtn);
		this.element.append(this.currentSize);
		this.element.append(this.increaseBtn);
	}

	increaseSize = () => {
		const pagesElements = document.getElementsByClassName('page-frame__graphic-page');

		// Все страницы у нас одинакового размера, поэтому можно ориентироваться например по первой
		const currentWidth = pagesElements[0].clientWidth;
		const newWidth = currentWidth + this.ZOOM_STEP;
		for (let i = 0; i < pagesElements.length; i++) {
			(pagesElements[i] as HTMLElement).style.width = `${newWidth}px`;
			(pagesElements[i] as HTMLElement).style.height = `${newWidth * 1.4}px`;
		}
	};

	decreaseSize = () => {
		const pagesElements = document.getElementsByClassName('page-frame__graphic-page');

		// Все страницы у нас одинакового размера, поэтому можно ориентироваться например по первой
		const currentWidth = pagesElements[0].clientWidth;
		const newWidth = currentWidth - this.ZOOM_STEP;

		for (let i = 0; i < pagesElements.length; i++) {
			(pagesElements[i] as HTMLElement).style.width = `${newWidth}px`;
			(pagesElements[i] as HTMLElement).style.height = `${newWidth * 1.4}px`;
		}
	};

	public getElement = (): HTMLElement => this.element;
}

export default ZoomTool;
