import { useState } from 'react';
import { workshopModuleActions, WorkshopModuleCard } from '../../../../entities/workshop-module';
import { ISimpleWorkshopModule } from '../../../../entities/workshop-module/model/types/types';
import { workshopTagActions } from '../../../../entities/workshop-tag';
import { useAppDispatch } from '../../../../shared/libs/redux';

interface WorkshopModuleModalCardProps {
	data?: ISimpleWorkshopModule,
	onModuleClick?: ((id: string) => void) | null,
}

export const WorkshopModuleModalCard = ({ data, onModuleClick }: WorkshopModuleModalCardProps) => {
	const dispatch = useAppDispatch();

	const [isGlobalLoading, setIsGlobalLoading] = useState(false);

	const onDeleteModule = (moduleId: string) => {
		dispatch(workshopModuleActions.setIsDeletingModuleModalActive(true));
		dispatch(workshopModuleActions.setDeletingModuleId(moduleId));
	};

	const onBindTag = (moduleId: string) => {
		dispatch(workshopModuleActions.setIsBindingTagModuleModalActive(true));
		dispatch(workshopModuleActions.setBindingModuleId(moduleId));
	};

	const onUnbindTag = (moduleId: string, tagId: number) => {
		dispatch(workshopTagActions.setIsUnbindingTagModalActive(true));
		dispatch(workshopTagActions.setUnbindModuleId(moduleId));
		dispatch(workshopTagActions.setUnbindTagsIds(tagId));
	};

	const setGlobalLoadingState = (moduleId: string | null) => {
		setIsGlobalLoading(!!moduleId);
	};

	return (
		<WorkshopModuleCard
			key={data?.id}
			data={data}
			onModuleClick={onModuleClick}
			onDeleteModule={onDeleteModule}
			onBindTag={onBindTag}
			onUnbindTag={onUnbindTag}
			isGlobalLoading={isGlobalLoading}
			setGlobalLoading={setGlobalLoadingState}
		/>
	);
};
