import MagneticLine from './MagneticLine';
import DirectionVector from '../DirectionVector';
import PageGraphic from '../../../graphic/page/PageGraphic';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import IPageTexture from '../../../graphic/page/IPageTexture';

interface IMagneticPagePaddingLineProps {
	direction: DirectionVector,
	pageConfiguration: IFrameArea,
	pageTexture: IPageTexture,
	parentElement: HTMLElement,
}

/**
 * Реализация магнитной линии основанных на внутренних отступах страницы.
 */
class MagneticPagePaddingLine extends MagneticLine {
	constructor(props: IMagneticPagePaddingLineProps) {
		super(props.direction, props.parentElement);

		const { width, height } = props.pageConfiguration;

		const {
			paddingLeft, paddingRight, paddingTop, paddingBottom,
		} = props.pageTexture;

		switch (props.direction) {
		case DirectionVector.LEFT:
			this.setLocation(paddingLeft, 0, height);
			break;
		case DirectionVector.RIGHT:
			this.setLocation(width - paddingRight, 0, height);
			break;
		case DirectionVector.TOP:
			this.setLocation(0, paddingTop, width);
			break;
		case DirectionVector.BOTTOM:
			this.setLocation(0, height - paddingBottom, width);
			break;
		case DirectionVector.MIDDLE_X:
			this.setLocation(0, height / 2, width);
			break;
		case DirectionVector.MIDDLE_Y:
			this.setLocation(width / 2, 0, height);
			break;
		default: break;
		}
	}
}

export default MagneticPagePaddingLine;
