import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeletingTagWindow from './DeletingTagWindow';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import { setIsUnbindingTagGroupModalActive } from '../../../../../../redux/reducers/module/tagGroupReducer';
import { tagsGroupModalsActive } from '../../../../../../redux/selectors/module/tagGroup';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { useAppSelector } from '../../../../../../shared/libs/redux';

const UnbindTagWrapper = () => {
	const dispatch = useDispatch();

	const isUnbindingTagGroupModalActive = useSelector((
		state: RootState,
	) => tagsGroupModalsActive(state).isUnbindingTagGroupModalActive);

	const {
		unbindingGroupId,
		unbindingTagId,
	} = useAppSelector(state => state.tagGroup);

	const [unbind] = tagGroupAPI.useUnbindMutation();

	const handleUnbindingModal = (value: boolean) => {
		dispatch(setIsUnbindingTagGroupModalActive(value));
	};

	const onUnbindTag = async () => {
		try {
			if (unbindingGroupId !== null && unbindingTagId !== null) {
				await unbind({
					tag: unbindingTagId,
					group: unbindingGroupId,
				})
					.unwrap();
				handleUnbindingModal(false);
				notificationSuccess('Тег успешно удален из группы!');
			}
		} catch (e) {
			notificationError('Возникла ошибка при удалении тега из группы...');
		}
	};

	return (
		<DeletingTagWindow
			onDelete={onUnbindTag}
			handleDeletingModal={handleUnbindingModal}
			isDeletingModalActive={isUnbindingTagGroupModalActive}
			text="Вы действительно хотите удалить тег из группы тегов без возможности восстановления?"
		/>
	);
};

export default UnbindTagWrapper;
