import React, { FormEventHandler } from 'react';
import { useSelector } from 'react-redux';
import css from './EditWorkshopTagModal.module.scss';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { useEditWorkshopTag } from '../../services/edit-tag/useEditWorkshopTag';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import CreateInput from '../../../../components/pages/features/moduleWindow/createInput/CreateInput';
import { MainButton } from '../../../../components/ui/main-button/main-button';

export const EditWorkshopTagModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopTagSlice.selectors.getIsEditingModalActive);
	const value = useSelector(workshopTagSlice.selectors.getEditTagName);

	const { onEditTag, requestData } = useEditWorkshopTag();

	const handleValue = (value: string) => {
		dispatch(workshopTagActions.setEditTagName(value));
	};

	const handleModal = (value: boolean) => {
		if (!value) handleValue('');
		dispatch(workshopTagActions.setIsEditingTagModalActive(value));
	};

	const disabled = !value.length || requestData.isLoading;

	const editTag: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (!disabled) {
			await onEditTag();
		}
	};

	return (
		<NewModal active={isActive} closeModal={handleModal}>
			<form className={css.wrapper} onSubmit={editTag}>
				<div className={css.title}>
					Изменение тега
				</div>
				<CreateInput
					maxLength={20}
					text={value}
					defaultValue="Введите новое название"
					subTitle="Введите название"
					onChange={handleValue}
				/>
				<MainButton
					type="button"
					styles={css.btn}
					btnStyle="bright"
					disabled={disabled}
				>
					Изменить
				</MainButton>
			</form>
		</NewModal>
	);
};
