import { notificationError, notificationSuccess, notificationWarning } from '../../Notifications/callNotifcation';

export const notifyDownloadStarted = () => {
	notificationWarning('Запущена генерация PDF!');
};

export const notifyDownloadSuccess = () => {
	notificationSuccess('Загрузка PDF началась!');
};

export const notifyDownloadError = () => {
	notificationError('Ошибка при сохранении PDF', 'Возникла ошибка при создании PDF!');
};

export const notifyStructureError = () => {
	notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры!');
};
