import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICustomTemplateData, ICustomTemplateState } from './types';

const initialState: ICustomTemplateState = {
	search: '',
	customTemplates: undefined,
	isConfirmCustomMoveModalActive: false,
	selectedCustomTemplate: {} as ICustomTemplateData,
};

export const customTemplate = createSlice({
	name: 'customTemplate',
	initialState,
	reducers: {
		setCustomTemplateSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setCustomTemplates: (state, action: PayloadAction<ICustomTemplateData[]>) => {
			state.customTemplates = action.payload;
		},

		setSelectedCustomTemplate: (state, action: PayloadAction<ICustomTemplateData>) => {
			state.selectedCustomTemplate = action.payload;
		},

		setIsConfirmCustomMoveModalActive: (state, action: PayloadAction<boolean>) => {
			state.isConfirmCustomMoveModalActive = action.payload;
		},
	},
});

export const {
	setCustomTemplates,
	setCustomTemplateSearch,

	setSelectedCustomTemplate,

	setIsConfirmCustomMoveModalActive,
} = customTemplate.actions;

export default customTemplate.reducer;
