import ITextOption from './line-height/ITextOption';
import TextOptionView from './TextOptionView';
import InterfacePanel from '../../../InterfacePanel';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';

class TextOptionsView<OptionValueType> extends InterfacePanel {
	private readonly ELEMENT_CLASS_NAME = 'text-panel__options options text-options';

	private optionViews: TextOptionView<OptionValueType>[];
	private textOptions: ITextOption<OptionValueType>[];
	private selectedElement: HTMLElement;
	private readonly span: HTMLElement;

	constructor() {
		super();
		this.optionViews = [];
		this.textOptions = [];
		this.rootElement.style.display = 'none';
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.addClickOutsideHandler();
		this.span = HTMLGenerator.getSpan({

		});
	}

	/** Метод для проверки, является ли элемент самим окошком. */
	private isChildOfPanel = (element: HTMLElement | null): boolean => {
		while (element) {
			if (element === this.rootElement) {
				return true;
			}
			element = element.parentElement;
		}
		return false;
	};

	private addClickOutsideHandler() {
		document.addEventListener('mousedown', (event: MouseEvent) => {
			if (!this.isChildOfPanel(event.target as HTMLElement)) {
				this.closeWindow();
			}
		});
	}

	protected setOptions = (options: ITextOption<OptionValueType>[]) => {
		this.clearOptions();

		this.textOptions = options;
		options.forEach(option => {
			const textOptionView = new TextOptionView<OptionValueType>();
			textOptionView.setOption(option);
			const optionViewElement = textOptionView.getElement();
			this.optionViews.push(textOptionView);
			this.rootElement.append(optionViewElement);
		});
	};

	public addOptionSelectListener = (listener: (option: ITextOption<OptionValueType>) => void) => {
		this.optionViews.forEach(option => {
			option.setEvent(listener.bind(this, option.getData()));
			option.setEvent(this.currentValueElement.bind(this, option));
		});
	};

	public setSelectedOption = (value: OptionValueType) => {
		const targetOption = this.optionViews.find(option => option.getData().value === value);
		if (targetOption === undefined) {
			throw new ManipulatorError('target line height option not found');
		}
		this.currentValueElement(targetOption);
		this.disableOptions();
		targetOption.enable();
		this.showTextOption();
	};

	private currentValueElement = (targetOption: TextOptionView<OptionValueType>) => {
		this.span.textContent = targetOption?.getData().text;
		this.selectedElement = this.span;
	};

	public getCurrentValueElement = (): HTMLElement => this.selectedElement;

	private clearOptions = () => {
		this.rootElement.innerHTML = '';
		this.optionViews.length = 0;
		this.textOptions.length = 0;
	};

	public disableOptions = () => {
		this.optionViews.forEach(option => {
			option.disable();
		});
	};

	public openWindowAtPosition = () => {
		// Переключение видимости окна выбора шрифта
		if (this.rootElement.style.display === 'none') {
			this.rootElement.style.display = 'block';
		} else {
			this.rootElement.style.display = 'none';
		}
		this.setCoords();
	};

	private setCoords = () => {
		this.rootElement.style.left = `${0}px`;
		this.rootElement.style.top = `${0}px`;

		// Расчет координат для отображения панели
		const rect = this.rootElement.getBoundingClientRect();
		if (rect.right > window.innerWidth) {
			this.rootElement.style.left = `${window.innerWidth - rect.width - rect.height}px`;
		}
		if (rect.bottom > window.innerHeight) {
			this.rootElement.style.top = `${window.innerHeight - rect.height - rect.top}px`;
		}
	};

	public closeWindow = () => {
		this.rootElement.style.display = 'none';
	};

	public hideTextOption = () => {
		this.span.style.display = 'none';
	};

	public showTextOption = () => {
		this.span.style.display = 'block';
	};
}

export default TextOptionsView;
