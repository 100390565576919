import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { URI_ACCOUNT_AVAILABLE_ROLES } from './consts';
import { IGetAccountAvailableRoles } from './types';

export const accountRolesAPI = createApi({
	reducerPath: 'accountRolesAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['roles'],
	endpoints: builder => ({
		availableRoles: builder.query<IGetAccountAvailableRoles, { email: string }>({
			query: ({ email }) => ({
				url: `${URI_ACCOUNT_AVAILABLE_ROLES}/${email}`,
				method: 'GET',
			}),
			providesTags: ['roles'],
		}),
	}),
});
