import Frame from '../Frame';
import Utils from '../../utils/impl/Utils';

/**
 * Фрейм, поддерживающий механизм подсветки при наведении.
 */
class HoveringFrame extends Frame {
	private readonly CSS_PROPERTY_COLOR = '--hover-color';
	private readonly HOVER_CLASS_NAME = 'hover';
	private readonly DEFAULT_COLOR = '#BEBEBE';

	private readonly postDisableHover: VoidFunction[];
	private readonly postEnableHover: VoidFunction[];
	private isHover: boolean;

	constructor() {
		super();
		this.isHover = false;
		this.setColor(this.DEFAULT_COLOR);
		this.postEnableHover = [];
		this.postDisableHover = [];
	}

	public enableHover = () => {
		this.isHover = true;
		this.frameElement.classList.add(this.HOVER_CLASS_NAME);
		this.runPostEnableHoverListeners();
	};

	public disableHover = () => {
		this.isHover = false;
		this.frameElement.classList.remove(this.HOVER_CLASS_NAME);
		this.runPostDisableHoverListeners();
	};

	public addPostEnableHoverListener = (listener: VoidFunction) => {
		this.postEnableHover.push(listener);
	};

	public addPostDisableHoverListener = (listener: VoidFunction) => {
		this.postDisableHover.push(listener);
	};

	private runPostEnableHoverListeners = () => {
		this.postEnableHover.forEach(listener => listener());
	};

	private runPostDisableHoverListeners = () => {
		this.postDisableHover.forEach(listener => listener());
	};

	/**
	 * Обновляет цвет обводки на основании цвета фона.
	 * @param color - цвет фона.
	 */
	public setParentBackground = (color: string) => {
		const updatedColor = Utils.Color.getAccentColorFromHex(color);
		this.setColor(updatedColor);
	};

	public isEnableHover = (): boolean => this.isHover;

	private setColor = (color: string) => {
		this.frameElement.style.setProperty(this.CSS_PROPERTY_COLOR, color);
	};
}

export default HoveringFrame;
