import Graphic from '../Graphic';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import IPictureTexture from './IPictureTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import PictureTextureState from './PictureTextureState';
import Utils from '../../utils/impl/Utils';
import API from '../../../../api/API';
import PictureComponent from '../../components/picture/PictureComponent';
import IFrameArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import SpatialPictureArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialPictureArea';
import IImageTexture from '../foundation/image/IImageTexture';
import IAreaMutationEvent from '../../mechanics/spatial-quadrants/area-mutators/events/IAreaMutationEvent';
import AreaMutationEventType from '../../mechanics/spatial-quadrants/area-mutators/events/AreaMutationEventType';
import AreaMutationEventStage from '../../mechanics/spatial-quadrants/area-mutators/events/AreaMutationEventStage';
import IDescartesPosition from '../../utils/IDescartesPosition';
import { AnySpatialArea } from '../../Types';
import PictureActionLayer from '../../mechanics/replace-picture/PictureActionLayer';

class PictureGraphic extends Graphic<IPictureTexture> {
	public readonly type: GraphicType = GraphicType.PICTURE;

	private readonly DIFF_RANGE_BIND_PICTURE_FRAME_SIZE = 20;
	private readonly UPPER_POSITION_RANGE_LIMIT = this.DIFF_RANGE_BIND_PICTURE_FRAME_SIZE / 2;
	private readonly LOWER_POSITION_RANGE_LIMIT = -this.DIFF_RANGE_BIND_PICTURE_FRAME_SIZE / 2;

	private readonly textureState: PictureTextureState;
	private pictureActionLayer: PictureActionLayer | null;
	private startMutatePicturePosition: IDescartesPosition;

	private isInitialPictureState: boolean;
	private isBindChangeSize: boolean;

	protected readonly GRAPHIC_CLASS_NAME = 'frame__graphic-picture';

	constructor() {
		super();

		this.textureState = new PictureTextureState(this);
		this.textureState.setLoadPictureEvent(this.runLoadPictureInEditMode);
		this.textureState.enablePendingPicture();

		this.pictureActionLayer = null;
		this.addMutationListener(this.handlerMutationEvent);

		this.isBindChangeSize = false;
		this.isInitialPictureState = true;
		this.startMutatePicturePosition = { x: 0, y: 0 };
	}

	public connectPictureActionLayer = (pictureActionLayer: PictureActionLayer) => {
		this.pictureActionLayer = pictureActionLayer;
	};

	public enablePictureActionLayer = () => {
		if (this.pictureActionLayer === null) {
			return;
		}
		this.pictureActionLayer.enable();
	};

	public disablePictureActionLayer = () => {
		if (this.pictureActionLayer === null) {
			return;
		}
		this.pictureActionLayer.disable();
	};

	public getPictureAction = (): PictureActionLayer | null => this.pictureActionLayer;

	public getTexture = (): IPictureTexture => this.textureState.getTexture();

	public setStructure = (
		fn: (prev: IGraphicStructure<IPictureTexture>) => IGraphicStructure<IPictureTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		this.id = id;
		this.offset = offset;
		if (texture === null) {
			throw new ManipulatorError('picture graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('picture graphic frame cannot be null');
		}

		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public setTexture = (fn: (prev: IPictureTexture) => IPictureTexture): void => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);

		if (updatedTexture.source === null) {
			this.textureState.enablePendingPicture();
			return;
		}

		this.textureState.enableTextureBlock({
			...updatedTexture,
			source: updatedTexture.source as string,
		});
	};

	/**
	 * Запускает процесс загрузки нового изображения.
	 */
	public runLoadPicture = () => {
		if (this.parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}
		Utils.File.getPictureBase64FromFile(this.setPicture);
	};

	/**
	 * Возвращает размеры изображения в исходное состояние.
	 */
	public resetPictureSize = () => {
		const frameAspectRatio = this.getFrameAspectRatio();
		const frameConfiguration = this.getFrameConfiguration();
		this.textureState.adaptPicture(frameConfiguration, frameAspectRatio);
	};

	/**
	 * Возвращает глобальную область, которую занимает изображение.
	 */
	public getPictureArea = (): IFrameArea => {
		const globalPosition = this.getGlobalPosition();
		const texture = this.getTexture();
		return {
			x: globalPosition.x + texture.x,
			y: globalPosition.y + texture.y,
			width: texture.width,
			height: texture.height,
			rotate: 0,
		};
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		const resizeAreas = this.getResizeAreas();

		const areas = [graphicArea, ...resizeAreas];
		if (this.textureState.hasPicture()) {
			if (this.isEnableEditMode()) {
				const pictureArea = new SpatialPictureArea(this, this.textureState);
				areas.push(pictureArea);

				const pictureResizeAreas = this.textureState.getResizeAreas();
				areas.push(...pictureResizeAreas);
			}
		}

		return areas;
	};

	public resetInitialPictureState = () => {
		this.isInitialPictureState = false;
	};

	/**
	 * Устанавливает новое изображение.
	 * @param pictureID - id нового изображения.
	 */
	public setNewPicture = (pictureID: string) => {
		this.setTextureWithAdaptPicture(pictureID);
	};

	public enableCompactActionLayerMode = () => {
		this.pictureActionLayer && this.pictureActionLayer.enableCompactMode();
	};

	public disableCompactActionLayerMode = () => {
		this.pictureActionLayer && this.pictureActionLayer.disableCompactMode();
	};

	public getUniqueTexture = (): IPictureTexture => this.getTexture();

	protected startMutation = (): void => {
		this.textureState.enableEditMode();
	};

	protected finishMutation = (): void => {
		this.textureState.disableEditMode();
	};

	private handlerMutationEvent = (ev: IAreaMutationEvent<any>, frameChanges: IFrameArea) => {
		if (ev.type !== AreaMutationEventType.SIZE) {
			return;
		}

		const isEditMode = this.isEnableEditMode();

		if (ev.stage === AreaMutationEventStage.START) {
			const frameConfiguration = this.getFrameConfiguration();
			const pictureConfiguration = this.textureState.getTexture();
			const widthDiff = Math.abs(frameConfiguration.width - pictureConfiguration.width);
			const heightDiff = Math.abs(frameConfiguration.height - pictureConfiguration.height);
			const widthDiffInRange = widthDiff < this.DIFF_RANGE_BIND_PICTURE_FRAME_SIZE;
			const heightDiffInRange = heightDiff < this.DIFF_RANGE_BIND_PICTURE_FRAME_SIZE;
			const xIsRange = this.isInRange(
				pictureConfiguration.x,
				this.LOWER_POSITION_RANGE_LIMIT,
				this.UPPER_POSITION_RANGE_LIMIT,
			);
			const yInRange = this.isInRange(
				pictureConfiguration.y,
				this.LOWER_POSITION_RANGE_LIMIT,
				this.UPPER_POSITION_RANGE_LIMIT,
			);

			this.startMutatePicturePosition = pictureConfiguration;

			if ((widthDiffInRange || heightDiffInRange) && (xIsRange || yInRange)) {
				if (isEditMode) {
					return;
				}
				this.isBindChangeSize = true;
			}
		} else if (ev.stage === AreaMutationEventStage.PROCESS) {
			if (isEditMode) {
				this.correctPictureWithEditMode(frameChanges);
				return;
			}
			this.correctPictureWithoutEditMode(frameChanges);
		} else if (ev.stage === AreaMutationEventStage.STOP) {
			this.isBindChangeSize = false;
		} else {
			throw new ManipulatorError('area mutation event not found');
		}
	};

	private setPicture = (filename: string, base64: string, size: number) => {
		this.textureState.enableLoadingBlock();

		// Сохраняем текстуру в качестве резервного копирования
		const texture = this.getTexture();
		
		API.file.create({
			name: filename,
			bytes: base64,
		}, size)
			.then(res => {
				const isAdaptPicture = (this.parentComponent as PictureComponent).hasAdaptPictureAfterSetup();
				if (isAdaptPicture) {
					this.setTextureWithAdaptPicture(res.id);
				} else {
					const texture = this.textureState.getTexture();
					texture.source = res.id;
					this.setTextureWithAdaptFrame(res.id);
				}
				if (this.parentComponent === null) {
					return;
				}
				this.textureState.addPostLoadPictureListener(this.parentComponent.enableEditMode);
			})
			.catch(() => {
				this.textureState.enablePendingPicture();
				this.setTexture((prev: IPictureTexture) => ({
					...prev,
					texture,
				}));
			});
	};

	/**
	 * Устанавливает изображение, подгоняя его под размеры фрейма
	 * @param pictureSource - имя файла с изображением без URI
	 */
	private setTextureWithAdaptPicture = (pictureSource: string) => {
		const currentTexture = this.textureState.getTexture();
		const texture: IImageTexture = {
			...currentTexture,
			source: pictureSource,
		};

		const frameAspectRatio = this.getFrameAspectRatio();
		const frameConfiguration = this.getFrameConfiguration();

		this.textureState
			.addPostLoadPictureListener(
				this.textureState.adaptPicture.bind(
					this,
					frameConfiguration,
					frameAspectRatio,
				),
			);
		this.textureState.addPostLoadPictureListener(this.textureState.enableEditMode);
		this.textureState.enableTextureBlock(texture);
	};

	/**
	 * Устанавливает изображение, подгоняя под него размеры фрейма.
	 * @param pictureSource - имя файла с изображением без URI
	 */
	private setTextureWithAdaptFrame = (pictureSource: string) => {
		if (this.parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}

		const currentTexture = this.textureState.getTexture();

		const texture: IImageTexture = {
			...currentTexture,
			source: pictureSource,
		};

		this.textureState
			.addPostLoadPictureListener(this.textureState.adaptFrame.bind(
				this,
				this,
				this.parentComponent,
			));
		this.textureState.addPostLoadPictureListener(this.textureState.enableEditMode);
		this.textureState.enableTextureBlock(texture);
	};

	/**
	 * Корректирует характеристику изображения при изменении размеров фрейма в режиме редактирования.
	 * @param frameChanges - изменения фрейма
	 */
	private correctPictureWithEditMode = (frameChanges: IFrameArea) => {
		this.textureState.mutateFrameArea(prev => ({
			...prev,
			x: this.startMutatePicturePosition.x - frameChanges.x,
			y: this.startMutatePicturePosition.y - frameChanges.y,
		}));
	};

	/**
	 * Корректирует характеристику изображения при изменении размеров фрейма без режима редактирования.
	 */
	private correctPictureWithoutEditMode = (frameChanges: IFrameArea) => {
		if (!this.isInitialPictureState || !this.isBindChangeSize) {
			this.textureState.mutateFrameArea(prev => ({
				...prev,
				x: this.startMutatePicturePosition.x - frameChanges.x,
				y: this.startMutatePicturePosition.y - frameChanges.y,
			}));
			return;
		}

		const frameConfiguration = this.getFrameConfiguration();
		const frameAspectRatio = this.getFrameAspectRatio();
		this.textureState.adaptPicture(frameConfiguration, frameAspectRatio);
	};

	private runLoadPictureInEditMode = () => {
		if (this.isEnableEditMode()) {
			this.runLoadPicture();
		}
	};

	private isInRange = (x: number, a: number, b: number): boolean => x > a && x < b;
}

export default PictureGraphic;
