import React from 'react';
import css from '../authorization.module.scss';
import InputField from '../../InputField/InputField';
import Logo from '../../logo/Logo';
import { useInputValue } from '../../../../../hooks/useInput';
import { CurrentAuthorizationPageData, MAIN_AUTHORIZATION_PAGE } from '../Authorization';
import { MainButton } from '../../../../ui/main-button/main-button';

interface ResetPasswordPageProps {
	isEmailNotValid: boolean,
	email: useInputValue,
	isDataLoading: boolean,
	isEmailCorrect: boolean | undefined,
	setIsEmailCorrect: (value: boolean | undefined) => void,
	onResetPassword: () => void,
	handleKeyPressGetPassword: (e: React.KeyboardEvent<HTMLInputElement>) => void,
	setCurrentPage: (value: CurrentAuthorizationPageData) => void,
}

const ResetPassword = ({
	email,
	isEmailCorrect,
	isDataLoading,
	isEmailNotValid,
	setIsEmailCorrect,
	handleKeyPressGetPassword,
	onResetPassword,
	setCurrentPage,
}: ResetPasswordPageProps) => {
	const changeEmailHandler = (e: any) => {
		setIsEmailCorrect(undefined);
		email.onChange(e, 'email');
	};

	return (
		<div className={css.wrapper}>
			<Logo isPasswordSent={false} />
			<h2 className={css.title}>Забыли пароль?</h2>
			<div className={css.descr}>
				Введите почту ниже и мы вышлем
				<br />
				{' '}
				вам новый пароль
			</div>
			<div className={css.block}>
				<InputField
					placeholder="Электронная почта"
					type="email"
					keyDown={e => handleKeyPressGetPassword(e)}
					status={
						isEmailNotValid
							? 'error'
							: 'success'
					}
					value={email.value}
					onChange={e => changeEmailHandler(e)}
					onBlur={email.onBlur}
					disabled={false}
				/>
				{
					isEmailCorrect !== undefined && !isEmailCorrect && !isEmailNotValid && (
						<div className={css.error}>
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.0026 5.75407L6.0026 9.75407M6.0026 5.75407L10.0026
				9.75407M14.6693
							7.75407C14.6693 11.436 11.6845 14.4207 8.0026 14.4207C4.32071 14.4207 1.33594
				11.436
							1.33594 7.75407C1.33594 4.07217 4.32071 1.0874 8.0026 1.0874C11.6845 1.0874
				14.6693
							4.07217 14.6693 7.75407Z"
									stroke="#FF3636"
									strokeWidth="1.33"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<div>E-mail не найден. Попробуйте ещё раз</div>
						</div>
					)
				}
				<MainButton
					type="button"
					onClick={onResetPassword}
					btnStyle="bright"
					styles={css.button}
					disabled={isDataLoading || !email.inputValid}
				>
					Получить пароль
				</MainButton>
				<div
					className={css.rememberPassword}
					onClick={() => setCurrentPage(MAIN_AUTHORIZATION_PAGE)}
					role="presentation"
				>
					Вспомнили пароль
				</div>
			</div>
		</div>
	);
};
export default ResetPassword;
