import ToolBarTool from '../ToolBarTool';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';

/**
 * Элемент интерфейса для создания нового компонента изображения.
 */
class InjectPictureTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Изображение / Shift + K';

	constructor() {
		super('panel-components__img');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.imgTool);
	}
}

export default InjectPictureTool;
