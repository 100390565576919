import cx from 'classnames';
import React from 'react';
import { ACCOUNT_STATUS } from '../../../entities/user/account/api/types';
import { ReactComponent as ArrowIcon } from '../../images/arrow-close-black.svg';
import css from './user-status.module.scss';

interface UserStatusProps {
	type: ACCOUNT_STATUS,
	openable?: boolean,
	isOpen?: boolean,
	styles?: string,
	onClick?: (status: ACCOUNT_STATUS) => void,
}

const statusData = new Map<ACCOUNT_STATUS, string>([
	[ACCOUNT_STATUS.EMPLOYEE, 'Сотрудник'],
	[ACCOUNT_STATUS.ADMIN, 'Администратор'],
	[ACCOUNT_STATUS.SUPER_ADMIN, 'Супер администратор'],
	[ACCOUNT_STATUS.SERVICE_ADMIN, 'Сервис администратор'],
	[ACCOUNT_STATUS.INTEGRATOR, 'Интегратор'],
]);

export const UserStatus = ({
	type,
	isOpen,
	styles,
	openable,
	onClick,
}: UserStatusProps) => {
	const onStatusClick = () => onClick && onClick(type);

	return (
		<div
			role="presentation"
			onClick={onStatusClick}
			className={cx(css.wrapper, css[type], isOpen && css.active, openable && css.openable, styles)}
		>
			{statusData.get(type)}
			{openable && <ArrowIcon />}
		</div>
	);
};
