import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PrivateTemplateConstructor from './PrivateTemplateConstructor';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import { notificationError } from '../../Notifications/callNotifcation';
import store from '../../../redux/store/store';
import { templatePrivateAPI } from '../../../entities/templates/private/api/api';
import savePDFWithNotifications from '../SketchPDFGenerator/savePDFWithNotifications';
import { useAppDispatch } from '../../../shared/libs/redux';
import { closeAllModals } from '../../../features/ai/model/slice/aiSlice';

export interface PrivateTemplateConstructorProps {
	sketchID: string;
}

const ComponentPrivateTemplateConstructor = (props: PrivateTemplateConstructorProps) => {
	const { sketchID } = props;
	const navigate = useNavigate();
	const refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();
	const dispatch = useAppDispatch();

	const onExitConstructor = () => {
		navigate('/collection/template/storage');
		dispatch(closeAllModals());
	};

	const onSendOffer = () => {
		navigate(`/private-template-present/${sketchID}`);
		dispatch(closeAllModals());
	};

	const onDownloadPDF = async () => {
		store.dispatch(templatePrivateAPI.endpoints?.get.initiate({ id: sketchID }, { forceRefetch: true }))
			.unwrap()
			.then(res => {
				const { template, rootComponent } = res;
				savePDFWithNotifications(rootComponent, template.name);
				dispatch(closeAllModals());
			})
			.catch(() => {
				notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
			});
	};

	useEffect(() => {
		if (refManipulatorContainer.current === null || refManipulatorContainer.current === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		let manipulator: PrivateTemplateConstructor | null = null;

		store.dispatch(templatePrivateAPI.endpoints?.get.initiate({ id: sketchID }))
			.unwrap()
			.then(res => {
				const { template, rootComponent } = res;
				manipulator = new PrivateTemplateConstructor({
					onSendOffer,
					onDownloadPDF,
					templateData: template,
					structure: rootComponent,
					onBackPage: onExitConstructor,
					manipulatorContainer: refManipulatorContainer.current as HTMLDivElement,
				});
			})
			.catch(() => {
				notificationError('Загрузка структуры', 'Возникла непредвиденная ошибка загрузки структуры.');
			});

		return () => {
			manipulator && manipulator.destruct();
		};
	}, []);

	return (
		<div ref={refManipulatorContainer} className="sketch-manipulator private-template-constructor" />
	);
};

// Получает id скетча из параметров
export const TemplateConstructorWithRouter = () => {
	const { id } = useParams();
	if (id === undefined) {
		return <p>error get sketch id</p>;
	}

	return <ComponentPrivateTemplateConstructor sketchID={id} />;
};
