import ModulesAction from './ModulesAction';
import {
	onCloseModuleWindow,
	onOpenTemplateModuleWindow,
} from '../../../../../../redux/thunk-creators/moduleWindow';
import PrivateTemplateUseCases from '../../../../use-cases/impl/PrivateTemplateUseCases';

class PrivateTemplateModulesAction extends ModulesAction {
	constructor(useCases: PrivateTemplateUseCases) {
		super();

		this.addRootElementClickListener(onOpenTemplateModuleWindow.bind(
			this,
			useCases.injectModule,
			useCases.injectGalleryModule,
			() => {
				this.modulesTool.disable();
				onCloseModuleWindow();
			},
		));

		this.rootElement.append(this.modulesTool.getElement());
	}
}

export default PrivateTemplateModulesAction;
