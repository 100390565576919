import React from 'react';
import css from './table-header.module.scss';

interface TableHeaderProps {
	data: string[],
	title: string,
}

export const TableHeader = ({ data, title }: TableHeaderProps) => (
	<thead className={css.head}>
		<tr>
			<th className={css.main_title}>{title}</th>
			{/* eslint-disable-next-line react/no-array-index-key */}
			{data.map((elem, i) => <th key={i} className={css.title}>{elem}</th>)}
		</tr>
	</thead>
);
