import type {
	IEmoji, Token, ITokenLink, ITokenText,
} from '../parser';
import { TokenFormat, TokenType } from '../parser';
import { objectMerge } from '../utils/objectMerge';
import { FontFamily, FontSize, TextAlign } from '../editor/types';

/**
 * Индекс токена и позиция в нем для положения каретки в модели.
 */
export interface ICarriagePositionData {
    /** Индекс найденного токена (будет -1, если такой токен не найден) */
    index: number;

    /** Текстовое смещение позиции относительно начала токена */
    offset: number;
}

export type LocationType = 'start' | 'end';

/** Определяет индекс токена из списка `tokens`, который соответствует указанной
 * позиции текста (offset) и смещение позиции относительно начала токена
 * @param solid Если указан, индекс позиции токена будет обновлён таким образом,
 * чтобы учитывать «сплошные» (неразрывные) токены, то есть токены, которые нельзя
 * разрывать в середине. В основном это используется для форматирования, чтобы
 * не делить токен и не заниматься репарсингом. Значение может быть `false` (начало)
 * или `true` (конец).
 * @param tokens
 * @param offset
 * @param solid
 * @param locType - Токен, который мы ищем в диапазоне (либо start либо end),
 */
export function tokenForPos(
	tokens: Token[],
	offset: number,
	solid?: boolean,
	locType: LocationType = 'end',
): ICarriagePositionData {
	if (offset < 0) {
		return { index: -1, offset: -1 };
	}

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line array-callback-return
	const index = tokens.findIndex((token, i) => {
		const len = token.value.length;

		if (offset < len) {
			return true;
		}

		if (len === offset) {
			// Попали точно на границу токенов. Проверим, если следующий является
			// sticky-токеном, то работать нужно с ним, иначе с текущим

			if (tokens.length - 1 === i) {
				// Это последний токен
				return true;
			}

			const nextToken = tokens[i + 1]!;
			if (!isSticky(nextToken) && locType === 'end') {
				return true;
			}
		}

		offset -= len;
	});

	const pos: ICarriagePositionData = { offset, index };

	if (index !== -1) {
		const token = tokens[index];
		if (solid && isSolidToken(token)) {
			pos.offset = locType === 'end' ? token.value.length : 0;
		}
	}

	return pos;
}

/**
 * Возвращает позиции в токенах для указанного диапазона
 */
export function tokenRange(
	tokens: Token[],
	from: number,
	to: number,
	solid = false,
): [ICarriagePositionData, ICarriagePositionData] {
	const start = tokenForPos(tokens, from, solid, 'start');
	const end = tokenForPos(tokens, to, solid, 'end');
	// Из-за особенностей определения позиций может случиться, что концевой токен
	// будет левее начального. В этом случае отдаём предпочтение концевому
	if (end.index < start.index && from === to) {
		return [end, end];
	}

	return end.index < start.index && from === to
		? [end, end]
		: [start, end];
}

/**
 * Делит токен на две части в указанной позиции
 */
export function splitToken(token: Token, pos: number): [Token, Token] {
	pos = clamp(pos, 0, token.value.length);

	// Разбор пограничных случаев: позиция попадает на начало или конец токена
	if (pos === 0) {
		return [createToken(''), token];
	}

	if (pos === token.value.length) {
		return [token, createToken('')];
	}

	let right = sliceToken(token, pos);

	// Так как у нас фактически все токены зависят от префикса, деление
	// токена всегда должно превратить правую часть в обычный текст, только если
	// это не произвольная ссылка
	if (!isCustomLink(right) && pos > 0) {
		right = toText(right);
	}

	return [
		sliceToken(token, 0, pos),
		right,
	];
}

/**
 * Возвращает фрагмент указанного токена
 */
export function sliceToken(token: Token, start: number, end = token.value.length): Token {
	const { value } = token;
	const result = objectMerge(token, {
		value: value.slice(start, end),
	});

	if (result.type === TokenType.Link) {
		// Если достаём фрагмент автоссылки, то убираем это признак
		result.auto = false;
	}

	return result;
}

/**
 * Проверяет, является ли указанный токен сплошным, то есть его разделение на части
 * для форматирования является не желательным
 */
export function isSolidToken(token: Token): boolean {
	return token.type === TokenType.Command
        || token.type === TokenType.HashTag
        || token.type === TokenType.UserSticker
        || token.type === TokenType.Mention
        || (token.type === TokenType.Link && !isCustomLink(token));
}

/**
 * Проверяет, что указанный токен является пользовательской ссылкой, то есть
 * ссылка отличается от содержимого токена
 */
export function isCustomLink(token: Token): token is ITokenLink {
	return token.type === TokenType.Link && !token.auto;
}

/**
 * Проверяет, что указанный токен — это автоссылка, то есть автоматически
 * распарсилась из текста
 */
export function isAutoLink(token: Token): token is ITokenLink {
	return token.type === TokenType.Link && token.auto;
}

export function clamp(value: number, min: number, max: number): number {
	return Math.min(Math.max(value, min), max);
}

/**
 * Конвертирует указанный токен в текстовый токен.
 */
export function toText(token: Token, sticky?: boolean): ITokenText {
	if (sticky === undefined) {
		sticky = 'sticky' in token ? token.sticky : false;
	}
	return {
		type: TokenType.Text,
		format: token.format,
		value: token.value,
		textAlign: token.textAlign,
		color: token.color,
		fontFamily: token.fontFamily,
		fontSize: token.fontSize,
		lineHeight: 1,
		sticky,
	};
}

/**
 * Конвертирует указанный токен в ссылку
 */
export function toLink(token: Token, link: string, sticky?: boolean): ITokenLink {
	if (sticky === undefined) {
		sticky = 'sticky' in token ? token.sticky : false;
	}

	return {
		type: TokenType.Link,
		format: token.format,
		value: token.value,
		link,
		auto: false,
		textAlign: token.textAlign,
		color: token.color,
		fontFamily: token.fontFamily,
		fontSize: token.fontSize,
		lineHeight: 1,
		sticky,
	};
}

/**
 * Фабрика объекта-токена
 */
export function createToken(
	text: string,
	emoji?: IEmoji[],
	format: TokenFormat = 0,
	fontFamily: FontFamily = FontFamily.Default,
	fontSize: FontSize = FontSize.Pt14,
	color = '#000000',
	sticky = false,
): Token {
	return {
		type: TokenType.Text,
		format,
		value: text,
		sticky,
		textAlign: TextAlign.LEFT,
		color: '#000000',
		fontFamily,
		lineHeight: 1,
		fontSize: FontSize.Pt14,
	};
}

export function isSticky(token: Token): boolean {
	return 'sticky' in token && token.sticky;
}
