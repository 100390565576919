import React from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './dropdown.module.scss';
import { TagsScrollSearchList } from './list/tags-scroll-search-list';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';
import { workshopTagGroupApi } from '../../../../../entities/workshop-tag-group';
import { IWorkshopGroupTag } from '../../../../../entities/workshop-tag-group/model/types/types';

interface TagsScrollSearchDropdownProps {
	groupId: string | undefined,
	currentIds: number[],
	onSelectTag: (id: number) => void,
}

export const WorkshopTagsScrollSearchDropdown = React.forwardRef<HTMLDivElement, TagsScrollSearchDropdownProps>(({
	groupId,
	currentIds,
	onSelectTag,
}, ref) => {
	const { isLoading, data: tagGroupsData } = workshopTagGroupApi.useGetWorkshopGroupsQuery({
		page: 1,
		limit: 127,
		search: '',
	});
	let tags: IWorkshopGroupTag[] = [];
	if (groupId) {
		const group = tagGroupsData?.groupTags.find((g) => g.id === +groupId);
		if	(group) {
			tags = group?.tag;
		}
	}

	return (
		<div>
			<CSSTransition
				timeout={200}
				unmountOnExit
				nodeRef={ref}
				in={!!groupId}
				classNames={popUpTransitionClasses}
			>
				<div className={css.tags_wrapper} ref={ref}>
					{
						// !tagsData?.tags?.length
						// 	? <div className={css.not_found}>Тегов не найдено...</div>
						// 	: (
						// 		<TagsScrollSearchList
						// 			tags={tagsData?.tags}
						// 			onSelectTag={onSelectTag}
						// 			currentIds={currentIds}
						// 		/>
						// 	)
						// !isLoading !tagGroupsData?.groupTags.length
						isLoading || !tagGroupsData
							? <div className={css.not_found}>Тегов не найдено...</div>
							: (
								<TagsScrollSearchList
									tags={tags}
									onSelectTag={onSelectTag}
									currentIds={currentIds}
								/>
							)
					}
				</div>
			</CSSTransition>
		</div>
	);
});
