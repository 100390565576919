import CollectionTabBlock from './CollectionTabBlock';
import { CollectionTabs } from '../../../widgets/tabs/collection-tabs';
import { MainLayout } from '../layouts/main/ui/main';
import css from './collection.module.scss';
import { APP_VERSION } from '../../../index';

const Collection = () => (
	<MainLayout>
		<div className={css.collection}>
			<div className={css.left_side_bar}>
				<CollectionTabs />
				<span className={css.version_text}>{`v${APP_VERSION}`}</span>
			</div>
			<CollectionTabBlock />
		</div>
	</MainLayout>
);

export default Collection;
