import React, { RefObject, useEffect } from 'react';
import ColorInput from '../../components/Sketch/mechanics/ColorInput';
import ColorexColor from './colors/ColorexColor';

interface ColorexComponentProps {
	defaultColor: ColorexColor,
	onSelectColor: (selectedColor: string) => void,
	// eslint-disable-next-line react/require-default-props
	containerClassName?: string,
}

const ColorexComponent = (props: ColorexComponentProps) => {
	const { onSelectColor, defaultColor, containerClassName } = props;
	const refContainer: RefObject<any> = React.createRef();
	const colorCore = new ColorInput(false);

	if (defaultColor !== undefined) {
		colorCore.setColor(defaultColor.toString());
	}
	if (onSelectColor !== undefined) {
		colorCore.addSelectColorListener(onSelectColor);
	}

	useEffect(() => {
		refContainer.current.append(colorCore.getElement());
	}, []);

	return (
		<div className={containerClassName || ''} ref={refContainer} />
	);
};

export default ColorexComponent;
