import React from 'react';
import { useNavigate } from 'react-router-dom';
import css from './authorization.module.scss';
import { REGISTRATION_LINK } from './Authorization';

const RegisterBlock: React.FC = () => {
	const navigate = useNavigate();
	return (
		<div className={css.underButton}>
			<span className={css.noAccount}>Нет учётной записи?</span>
			<span
				className={css.registration}
				onClick={() => { navigate(REGISTRATION_LINK); }}
				role="presentation"
			>
				Зарегистрируйтесь
			</span>
		</div>
	);
};

export default RegisterBlock;
