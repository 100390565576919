import React from 'react';
import { NewModal } from '../../../components/ui/modals/new-modal';
import { ModuleSearchTagBar } from './module-search-tag-bar/module-search-tag-bar';
import { Title } from '../../../components/ui/title';
import { ChangeableTagBar } from '../../changeable-tag-bar';
import { MainButton } from '../../../components/ui/main-button/main-button';
import { useAppDispatch, useAppSelector } from '../../../shared/libs/redux';
import { moduleAPI } from '../../../entities/modules/module/api/api';
import { useCreateModuleTag } from '../../../features/module/tags/create/api/useCreateModuleTag';
import css from './module-tag-bind.module.scss';
import { useBindModuleTag } from '../../../features/module/tags/bind/api/useBindModuleTag';
import { useUnbindModuleTag } from '../../../features/module/tags/unbind/api/useUnbindModuleTag';
import { setBindingModuleId, setIsBindingTagModuleModalActive } from '../../../entities/modules/module/model/slice';

export const ModuleTagBindModal = () => {
	const dispatch = useAppDispatch();

	const moduleId = useAppSelector(state => state.moduleWindow.bindingModuleId);
	const isActive = useAppSelector(state => state.moduleWindow.modalsActive.isBindingTagModuleModalActive);

	const setActive = (value: boolean) => {
		dispatch(setIsBindingTagModuleModalActive(value));

		if (!value) dispatch(setBindingModuleId(null));
	};

	const { data } = moduleAPI.useGetModuleQuery({ id: moduleId || '' }, { skip: !moduleId });

	const { createTag } = useCreateModuleTag();
	const { onBindTag } = useBindModuleTag(moduleId || '');
	const { onUnbindTag } = useUnbindModuleTag(moduleId || '');

	const bindCreatedTag = async (name: string) => {
		const res = await createTag(name);
		if (res) await onBindTag(res);
	};

	return (
		<NewModal styles={css.wrapper} active={isActive} closeModal={setActive}>
			<Title styles={css.title}>Изменение тегов модуля</Title>
			<ModuleSearchTagBar module={data?.module} />
			<ChangeableTagBar
				unbindTag={onUnbindTag}
				onCreateTag={bindCreatedTag}
				activeTags={data?.module?.tags || []}
			/>
			<MainButton btnStyle="bright">Сохранить</MainButton>
		</NewModal>
	);
};
