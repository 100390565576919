export enum Tariff {
	TRIAL = 'trial',
	BASIC = 'basic',
}

export type TTariffName = Tariff.BASIC | Tariff.TRIAL;

export const tariffMapData = new Map<TTariffName, string>([
	[Tariff.TRIAL, 'Пробный'],
	[Tariff.BASIC, 'Базовый'],
]);
