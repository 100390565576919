import CurrentTableSize from './CurrentTableSize';
import GridTableSize from './GridTableSize';
import ExcelLoader from './ExcelLoader';
import InterfacePanel from '../../../../InterfacePanel';
import TableSizeSetterButton from './TableSizeSetterButton';
import InjectTableTool from './InjectTableTool';
import { onOpenCreateTableWindow } from '../../../../../../../redux/thunk-creators/moduleWindow';
import IBaseUseCases from '../../../../../use-cases/base/IBaseUseCases';

/**
 * Pop-up для настройки создаваемой таблицы,
 * который открывается при активации инструмента для добавления таблицы.
 */
class TableConstructorPanel extends InterfacePanel {
	private readonly ELEMENT_CLASS_NAME = 'table-constructor';

	private readonly tableSize: CurrentTableSize;
	private readonly tableGrid: GridTableSize;
	private readonly setTableValues: TableSizeSetterButton;
	private readonly excelLoader: ExcelLoader;

	private readonly useCases: IBaseUseCases;

	constructor(useCases: IBaseUseCases, injectTableTool: InjectTableTool) {
		super();
		this.useCases = useCases;
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.tableSize = new CurrentTableSize();
		this.tableGrid = new GridTableSize({
			currentTableSize: this.tableSize,
			useCases,
			injectTableTool,
		});
		this.setTableValues = new TableSizeSetterButton();
		this.excelLoader = new ExcelLoader(useCases);

		this.setTableValues.addAction(onOpenCreateTableWindow.bind(this, this.createTable, this.hide));

		this.rootElement.append(
			this.tableSize.getElement(),
			this.tableGrid.getElement(),
			this.setTableValues.getElement(),
			this.excelLoader.getElement(),
		);
	}

	private createTable = (columnCount: number, rowCount: number) => {
		this.useCases.createEmptyTable(rowCount, columnCount);
	};
}

export default TableConstructorPanel;
