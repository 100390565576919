export const URI_PRIVATE_TEMPLATE = 'private/template';
export const URI_PRIVATE_TEMPLATE_BIND_TAG = (
	template: string,
	tag: string,
) => `${URI_PRIVATE_TEMPLATE}/${template}/tag/${tag}`;
export const URI_PRIVATE_TEMPLATE_DUPLICATE = `${URI_PRIVATE_TEMPLATE}/duplicate`;
export const URI_PRIVATE_TEMPLATE_DUPLICATE_TEAM = `${URI_PRIVATE_TEMPLATE_DUPLICATE}/team`;
export const URI_PRESENTATION_TEMPLATE = 'presentation/template';
export const URI_GET_PRIVATE_TEMPLATE = 'private/template/';
export const URI_COPY_PRIVATE_TEMPLATE = `${URI_PRIVATE_TEMPLATE}/copy/team`;
export const URI_PRIVATE_TEMPLATE_WITHOUT_REGISTRATION = 'private/template-for-pdf';
