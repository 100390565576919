import React from 'react';
import { IRoleItem } from '../../../../../../entities/role/api/types';
import Role from '../../../../personalAccount/personalData/role/Role';
import { ReactComponent as AddRoleIcon } from '../../images/add-role.svg';
import { useAppDispatch } from '../../../../../../shared/libs/redux';
import { setIsAddRoleModalActive, setUntiengUserEmail } from '../../../../../../redux/reducers/usersReducer';
import css from './roles.module.scss';
import { checkUsersRightsInterface } from '../../../../../../helpers/usersRights';
import { useUnbindRole } from '../../../api/useUnbindRole';

interface UserCardRolesProps {
	roles: IRoleItem[];
	email: string,
	statusRights: checkUsersRightsInterface,
}

export const UserCardRoles = ({
	roles,
	email,
	statusRights,
}: UserCardRolesProps) => {
	const dispatch = useAppDispatch();

	const { onUnbindRole } = useUnbindRole(statusRights);

	// * При клике на добавление роли
	const onAddRole = () => {
		dispatch(setUntiengUserEmail(email));
		dispatch(setIsAddRoleModalActive(true));
	};
	return (
		<div className={css.wrapper}>
			{
				roles?.map(role => {
					const {
						id,
						name,
						emoji,
						background,
					} = role;

					return (
						<Role
							cssClass={css.role}
							text={name}
							emoji={emoji}
							bgColor={background}
							key={id}
							id={id}
							userEmail={email}
							onDeleteRole={onUnbindRole}
							canDeleteRole
							isCursorPointed
						/>
					);
				})
			}
			<div className={css.plus} role="presentation" onClick={onAddRole}>
				<AddRoleIcon />
			</div>
		</div>
	);
};
