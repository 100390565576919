import { setUsers } from "../../redux/reducers/usersReducer";
import useDynamicPagination from "../useDynamicPagination";
import { employeeAPI } from "../../entities/employee/api/api";
import { useAppDispatch, useAppSelector } from "../../shared/libs/redux";
import { useDebounce } from "../../shared/libs/useDebounce";
import { useEffect } from "react";
import { IAccount } from "../../entities/user/account/api/types";

const LEGAL_ENTITY_LIMIT_PER_PAGE = 6;

const useGetUsers = () => {
	const dispatch = useAppDispatch();

	const {
		users,
		usersSearchValue: search,
	} = useAppSelector(state => state.user);

	const debounceSearch = useDebounce(search.trim(), 200);

	const setList = (list: IAccount[]) => dispatch(setUsers(list));

	const {
		data: initialLegalEntities,
		isLoading
	} = employeeAPI.useGetAllQuery({
		page: 1,
		limit: LEGAL_ENTITY_LIMIT_PER_PAGE,
		search: debounceSearch,
	}, { refetchOnMountOrArgChange: true });

	const [getUsers, { data: fetchedUsers }] = employeeAPI.useLazyGetAllQuery();

	const fetchData = async (page: number, perPage: number) => {
		if (users) {
			return getUsers({
				page,
				limit: perPage,
				search: debounceSearch,
			})
				.unwrap()
				.then(data => {
					setList([...users, ...data.accounts]);
				});
		}
		return undefined;
	};

	useEffect(() => {
		if (fetchedUsers) {
			const { accounts } = fetchedUsers;

			const newIds = accounts?.map(acc => acc.email) || [];
			const unrepeatedUsers = users?.filter(user => !newIds.includes(user.email)) || [];

			if (accounts) {
				setList([...unrepeatedUsers, ...accounts]);
			} else {
				setList([...unrepeatedUsers]);
			}
		}
	}, [fetchedUsers]);

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		LEGAL_ENTITY_LIMIT_PER_PAGE,
		2,
		users?.length || 0,
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialLegalEntities) {
			setCurrentPage(2);
			setList(initialLegalEntities?.accounts || []);

			setTotalCount(initialLegalEntities?.totalCount || 0);
		}
	}, [initialLegalEntities]);

	return { isLoading };
};

export default useGetUsers;
