import HTMLGenerator from '../utils/HTMLGenerator';
import ColorexPalette from '../../../libs/colorex/ColorexPalette';
import { manipulatorColorexCore } from '../../../libs/colorex/ColorexCore';

/** Компонент для выбора цвета. */
class ColorInput {
	private readonly CSS_CONTAINER_CLASS_NAME = 'color-input_container';
	private readonly CSS_COLOR_CLASS_NAME = 'color-input_color';

	private readonly containerElement: HTMLElement;
	private readonly colorElement: HTMLElement;
	private readonly hintElement: HTMLElement;
	private readonly colorex: ColorexPalette;

	private selectedColor: string;

	constructor(isDisableTransparent: boolean) {
		this.containerElement = HTMLGenerator.getDiv({
			className: this.CSS_CONTAINER_CLASS_NAME,
		});
		this.colorElement = HTMLGenerator.getDiv({
			className: this.CSS_COLOR_CLASS_NAME,
		});
		this.colorex = new ColorexPalette(manipulatorColorexCore, isDisableTransparent);
		this.containerElement.append(this.colorElement);

		this.colorex.setTargetElement(this.containerElement);
		this.hintElement = HTMLGenerator.getDiv({ className: 'hint top-right' });
	}

	/** Добавить класс к корневому HTMLElement. */
	public appendClassName = (className: string) => {
		this.containerElement.classList.add(className);
	};

	/** Добавить обработчик на события выбора цвета. */
	public addSelectColorListener = (ev: (color: string) => void) => {
		this.colorex.addSelectColorEvent(color => {
			this.setColor(color);
			ev(color);
		});
	};

	/** Установить цвет для отображения. */
	public setColor = (color: string) => {
		this.selectedColor = color;
		this.colorElement.style.background = color;
	};

	/**
	 * Установить подсказку для пользователя при наведении.
	 * @param text Содержание подсказки.
	 */
	public setHint = (text: string) => {
		this.hintElement.textContent = text;
		this.containerElement.addEventListener('mouseenter', this.showHint);
		this.containerElement.addEventListener('mouseleave', this.hideHint);
	};

	/** Получить текущий выбранный цвет. */
	public getColor = () => this.selectedColor;
	/** Получить HTMLElement для вставки в DOM. */
	public getElement = (): HTMLElement => this.containerElement;

	protected showHint = () => {
		this.containerElement.append(this.hintElement);
	};

	protected hideHint = () => {
		this.hintElement.remove();
	};
}

export default ColorInput;
