import Graphic from '../Graphic';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { AnySpatialArea } from '../../Types';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import ILineTexture from './ILineTexture';
import LineStyle from './LineStyle';

class LineGraphic extends Graphic<ILineTexture> {
	public readonly type: GraphicType = GraphicType.LINE;

	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-line graphic-line';
	private readonly CSS_PROPERTY_THICKNESS = 'border-top-width';
	private readonly CSS_PROPERTY_STYLE = 'border-top-style';
	private readonly CSS_PROPERTY_BACKGROUND = 'border-top-color';

	protected texture: ILineTexture;

	constructor() {
		super();
		this.graphicElement.className = this.GRAPHIC_CLASS_NAME;
		this.texture = {
			background: '',
			thickness: 1,
			style: LineStyle.SOLID,
		};
	}

	public setStructure = (
		fn: (prev: IGraphicStructure<ILineTexture>) => IGraphicStructure<ILineTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		if (texture === null) {
			throw new ManipulatorError('figure graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('figure graphic frame cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public getTexture = (): ILineTexture => ({ ...this.texture });

	public setTexture = (
		fn: (prevTexture: ILineTexture) => ILineTexture,
	): void => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);

		this.texture = updatedTexture;
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_THICKNESS, `${updatedTexture.thickness}px`);
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_STYLE, `${updatedTexture.style}`);
		this.graphicElement.style.setProperty(this.CSS_PROPERTY_BACKGROUND, `${updatedTexture.background}`);
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		this.setMutationPermissions({
			TOP: false,
			RIGHT: true,
			BOTTOM: false,
			LEFT: true,
			LEFT_TOP: false,
			RIGHT_TOP: false,
			RIGHT_BOTTOM: false,
			LEFT_BOTTOM: false,
		});
		const resizeAreas = this.getResizeAreas();

		return [graphicArea, ...resizeAreas];
	};

	public getUniqueTexture = (): ILineTexture => this.getTexture();

	protected startMutation = (): void => {
		if (this.type === GraphicType.LINE) return;
		this.graphicElement.contentEditable = 'true';
	};

	protected finishMutation = (): void => {
		this.graphicElement.contentEditable = 'false';
	};
}

export default LineGraphic;
