import React, { useMemo } from 'react';
import css from './tags.module.scss';
import { TemplateTag } from '../template-tag/tag';
import { useListOverflow } from '../../../../../libs/useListOverflow';
import { ITemplateTags } from '../../../../../entities/templates/types';

interface TemplateTagsProps {
	tags: ITemplateTags,
}

export const TemplateTags = ({ tags }: TemplateTagsProps) => {
	const {
		fromTemplates,
	} = tags;
	const templateTags = useMemo(() => fromTemplates || [], [tags]);

	const {
		visibleTags,
		hiddenTagsCount,
	} = useListOverflow(templateTags, 216);

	return (
		<div className={css.wrapper}>
			{
				visibleTags.map(tag => (
					<TemplateTag key={tag.id + tag.name} tag={tag} />
				))
			}
			{
				!!hiddenTagsCount && (
					<TemplateTag tag={{
						id: 0,
						name: `+${hiddenTagsCount}`,
						organization: '',
					}}
					/>
				)
			}
		</div>
	);
};
