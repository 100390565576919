import HTMLGenerator from '../../utils/HTMLGenerator';
import InterfacePanel from '../InterfacePanel';
import Utils from '../../utils/impl/Utils';
import SvgCollection from '../../utils/SvgCollection';

// PageHeaderPanel - бездушное отображением панели без функционала с возможностью его привязки
class PageHeaderPanel extends InterfacePanel {
	private readonly PAGE_HEADER_CLASS_NAME = 'page-header';
	private readonly NAVIGATION_CLASS_NAME = 'page-header__nav';
	private readonly SCROLL_UP_CLASS_NAME = 'page-header__btn_prev page-header__btn';
	private readonly SCROLL_DOWN_CLASS_NAME = 'page-header__btn';
	private readonly CURRENT_PAGE_NUMBER_CLASS_NAME = 'page-header__numbers-current';
	private readonly NUMBER_SEPARATOR_CLASS_NAME = 'page-header__numbers-separator';
	private readonly NUMBER_CONTAINER_CLASS_NAME = 'page-header__numbers';
	private readonly ALL_PAGE_COUNT_CLASS_NAME = 'page-header__numbers-quantity';
	private readonly DELETE_PAGE_CLASS_NAME = 'page-header__actions-delete page-header__btn';
	private readonly APPEND_PAGE_CLASS_NAME = 'page-header__actions-add page-header__btn';
	private readonly ACTION_BLOCK_CLASS_NAME = 'page-header__actions';

	private readonly PAGE_HEADER_LOCATION_BOTTOM = 'bottom';

	private readonly DEFAULT_CURRENT_PAGE = '1';
	private readonly DEFAULT_ALL_PAGE_COUNT = '1';
	private readonly DEFAULT_NUMBER_SEPARATOR = ' / ';

	private readonly scrollUpElement: HTMLElement;
	private readonly scrollDownElement: HTMLElement;
	private readonly deletePageElement: HTMLElement;
	private readonly appendPageElement: HTMLElement;
	private readonly currentPageNumberElement: HTMLElement;
	private readonly allPageCount: HTMLElement;

	constructor() {
		super();
		this.setRootElementClassName(this.PAGE_HEADER_CLASS_NAME);

		this.scrollUpElement = HTMLGenerator.getDiv({
			className: this.SCROLL_UP_CLASS_NAME,
		});
		Utils.DOM.injectSVG(this.scrollUpElement, SvgCollection.PAGE_PANEL_TOP_ARROW);

		const navigationElement = HTMLGenerator.getDiv({
			className: this.NAVIGATION_CLASS_NAME,
		});
		navigationElement.append(this.scrollUpElement);

		const numberContainerElement = HTMLGenerator.getDiv({
			className: this.NUMBER_CONTAINER_CLASS_NAME,
		});
		this.currentPageNumberElement = HTMLGenerator.getSpan({
			className: this.CURRENT_PAGE_NUMBER_CLASS_NAME,
			text: this.DEFAULT_CURRENT_PAGE,
		});
		const separatorElement = HTMLGenerator.getSpan({
			className: this.NUMBER_SEPARATOR_CLASS_NAME,
			text: this.DEFAULT_NUMBER_SEPARATOR,
		});
		numberContainerElement.append(this.currentPageNumberElement, separatorElement);

		this.allPageCount = HTMLGenerator.getSpan({
			className: this.ALL_PAGE_COUNT_CLASS_NAME,
			text: this.DEFAULT_ALL_PAGE_COUNT,
		});
		numberContainerElement.append(this.allPageCount);
		navigationElement.append(numberContainerElement);

		this.scrollDownElement = HTMLGenerator.getDiv({
			className: this.SCROLL_DOWN_CLASS_NAME,
		});
		Utils.DOM.injectSVG(this.scrollDownElement, SvgCollection.PAGE_PANEL_BOTTOM_ARROW);
		navigationElement.append(this.scrollDownElement);

		this.rootElement.append(navigationElement);

		const actionBlock = HTMLGenerator.getDiv({
			className: this.ACTION_BLOCK_CLASS_NAME,
		});

		this.deletePageElement = HTMLGenerator.getDiv({
			className: this.DELETE_PAGE_CLASS_NAME,
		});
		Utils.DOM.injectSVG(this.deletePageElement, SvgCollection.PAGE_PANEL_CART);
		actionBlock.append(this.deletePageElement);

		this.appendPageElement = HTMLGenerator.getDiv({
			className: this.APPEND_PAGE_CLASS_NAME,
		});
		Utils.DOM.injectSVG(this.appendPageElement, SvgCollection.PAGE_PANEL_PLUS);
		actionBlock.append(this.appendPageElement);

		this.rootElement.append(actionBlock);
	}

	public addScrollUpEvent = (event: () => void) => this.scrollUpElement.addEventListener('click', event);
	public addScrollDownEvent = (event: () => void) => this.scrollDownElement.addEventListener('click', event);
	public addDeleteEvent = (event: () => void) => this.deletePageElement.addEventListener('click', event);
	public addAppendEvent = (event: () => void) => this.appendPageElement.addEventListener('click', event);
	public setAllPageCount = (value: number) => {
		this.allPageCount.textContent = value.toString();
	};

	public setCurrentNumber = (value: number) => {
		this.currentPageNumberElement.textContent = value.toString();
	};

	public enableLocationBottom = () => {
		this.rootElement.classList.add(this.PAGE_HEADER_LOCATION_BOTTOM);
	};

	public disableLocationBottom = () => {
		this.rootElement.classList.remove(this.PAGE_HEADER_LOCATION_BOTTOM);
	};
}

export default PageHeaderPanel;
