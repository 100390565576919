import HTMLElementName from './HTMLElementName';

class ElementContainer<ElementType extends HTMLElement> {
	protected readonly rootElement: ElementType;

	constructor(elementName: HTMLElementName) {
		this.rootElement = document.createElement(elementName) as unknown as ElementType;
	}

	public setRootElementClassName = (className: string) => {
		this.rootElement.className = className;
	};

	protected addRootElementClickListener = (onClick: (VoidFunction | ((ev: MouseEvent) => void))) => {
		this.rootElement.addEventListener('click', onClick);
	};

	protected addRootElementMouseDownListener = (listener: VoidFunction) => {
		this.rootElement.addEventListener('mousedown', listener);
	};

	protected addRootElementMouseUpListener = (listener: VoidFunction) => {
		this.rootElement.addEventListener('mouseup', listener);
	};

	protected addRootElementMouseEnterListener = (listener: VoidFunction) => {
		this.rootElement.addEventListener('mouseenter', listener);
	};

	protected addRootElementMouseLeaveListener = (listener: VoidFunction) => {
		this.rootElement.addEventListener('mouseleave', listener);
	};

	public getElement = (): ElementType => this.rootElement;
}

export default ElementContainer;
