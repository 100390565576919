import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import PictureComponent from '../../components/picture/PictureComponent';
import IComponentStructure from '../../components/IComponentStructure';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import IPictureTexture from '../../graphic/picture/IPictureTexture';
import PictureInjectArea from './PictureInjectArea';
import CSSCursor from '../../cursor/CSSCursor';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import PictureGraphic from '../../graphic/picture/PictureGraphic';

interface IPictureDefaultStructureProps {
	x: number,
	y: number,
	width: number,
	height: number,
	componentOffset: number,
}

export interface IPictureInjectorDependencies extends IComponentInjectorDependencies {}

/**
 * Инжектор для добавления изображений в структуру.
 */
class PictureInjector extends ConstructorInjector<IPictureInjectorDependencies> {
	private readonly DEFAULT_WIDTH = 200;
	private readonly DEFAULT_HEIGHT = 200;
	private readonly MIN_CUSTOM_WIDTH = 6;
	private readonly MIN_CUSTOM_HEIGHT = 6;

	private readonly pictureInjectArea: PictureInjectArea;

	constructor() {
		super();
		this.pictureInjectArea = new PictureInjectArea();

		this.addPostInjectDependenciesListener(dependencies => {
			const rootElement = dependencies.componentTree.getWorkAreaElement();
			this.pictureInjectArea.connectDependencies({
				rootElement,
				mousePositionObserver: dependencies.mousePositionObserver,
			});
			this.pictureInjectArea.injectDependencies();
		});
	}

	/**
	 * Запускает инжекцию изображения.
	 */
	public run = (): void => {
		this.dependencies.cursorView.enableComponentInjectMode();
		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		this.isProcess = true;
		this.setNotReadyInject();
	};

	/**
	 * Инжектирует изображение в структуру.
	 */
	public inject = (): void => {
		const isReady = this.isReadyInject();
		if (!isReady) {
			throw new ManipulatorError('injector not ready inject component');
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();
		// Получаем область вставки картинки
		const area = this.pictureInjectArea.getArea();
		const injectParams = this.calculateAreaComponentInjectionParams(area);

		const isDefaultSize = area.width < this.MIN_CUSTOM_WIDTH || area.height < this.MIN_CUSTOM_HEIGHT;
		let width;
		let height;
		if (isDefaultSize) {
			width = this.DEFAULT_WIDTH;
			height = this.DEFAULT_HEIGHT;
		} else {
			width = area.width;
			height = area.height;
		}
		const x = area.x - injectParams.x;
		const y = area.y - injectParams.y;

		const structure = this.getDefaultStructure({
			x,
			y,
			width,
			height,
			componentOffset: injectParams.componentOffset,
		});
		let pictureComponent: PictureComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			pictureComponent = tools.componentFactory.createComponent<PictureComponent>(structure);

			structure.graphics?.forEach(graphicStructure => {
				const graphic = tools
					.graphicFactory.createGraphic<PictureGraphic>(GraphicType.PICTURE, pictureComponent);
				graphic.setStructure(() => graphicStructure);
				pictureComponent.appendGraphic(graphic);
			});

			if (isDefaultSize) {
				pictureComponent.disableAdaptPictureAfterSetup();
			} else {
				pictureComponent.enableAdaptPictureAfterSetup();
			}

			tools.mutator.mutateByAppendComponent(parentComponent, pictureComponent);

			this.callPostInjectListeners(pictureComponent);
		});

		this.stop();
		this.callSingleUseInjectListeners(pictureComponent!);
	};

	/**
	 * Останавливает инжекцию изображения.
	 */
	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.isProcess = false;
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 */
	public override onMouseDown = () => {
		const position = this.dependencies.mousePositionObserver.getCurrentPosition();
		this.pictureInjectArea.start(position);
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 */
	public override onMouseUp = () => {
		this.pictureInjectArea.stop();
		this.setReadyInject();
		this.inject();
	};

	/**
	 * Возвращает структуру изображения по умолчанию.
	 */
	protected getDefaultStructure = (props: IPictureDefaultStructureProps): IComponentStructure<null> => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		return {
			id: componentId,
			type: SketchComponentType.PICTURE,
			offset: props.componentOffset,
			graphics: [
				{
					id: graphicId,
					type: GraphicType.PICTURE,
					frame: {
						x: props.x,
						y: props.y,
						width: props.width,
						height: props.height,
						rotate: 0,
						layer: 100,
					},
					offset: 0,
					texture: {
						x: 0,
						y: 0,
						width: 0,
						height: 0,
						source: null,
					} as IPictureTexture,
				},
			],
			texture: null,
			components: null,
		};
	};
}

export default PictureInjector;
