import ModulesAction from './ModulesAction';
import IModuleUseCases from '../../../../use-cases/IModuleUseCases';
import {
	onCloseWorkshopModuleWindow,
	onOpenWorkshopModuleWindow,
} from '../../../../../../redux/thunk-creators/moduleWindow';
import store from '../../../../../../redux/store/store';
import { workshopModuleActions } from '../../../../../../entities/workshop-module';
import { templateModuleActions } from '../../../../../../entities/template-module';

class WorkshopModulesAction extends ModulesAction {
	constructor(useCases: IModuleUseCases) {
		super();

		this.addRootElementClickListener(onOpenWorkshopModuleWindow.bind(this, (moduleId: string) => {
			useCases.injectModule(moduleId);
			this.modulesTool.disable();
			onCloseWorkshopModuleWindow();
		}, () => {
			this.modulesTool.disable();
			onCloseWorkshopModuleWindow();
		}));

		this.rootElement.append(this.modulesTool.getElement());

		store.dispatch(workshopModuleActions.setModuleFunc(
			(moduleId) => {
				useCases.injectModule(moduleId);
				this.modulesTool.disable();
				onCloseWorkshopModuleWindow();
			},
		));
		store.dispatch(templateModuleActions.setCloseModuleModalFunc(() => {
			this.modulesTool.disable();
			onCloseWorkshopModuleWindow();
		}));
	}
}

export default WorkshopModulesAction;
