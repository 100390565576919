import { Dispatch } from 'redux';
import ISketchStructure from '../../components/Sketch/ISketchStructure';
import store from '../store/store';
import { setSketchStructure } from '../reducers/sketchReducer';

export const onThunkSetSketchStructure = (structure: ISketchStructure) => (dispatch: Dispatch) => {
	dispatch(setSketchStructure(structure));
};

export const onSetSketchStructure = (structure: ISketchStructure) => {
	store.dispatch(onThunkSetSketchStructure(structure) as any);
};
