import { customTemplateAPI } from '../../../../../entities/templates/custom/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { IId } from '../../../../../entities/templates/types';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { templatePrivateAPI } from '../../../../../entities/templates/private/api/api';

export const useMoveCustomTemplate = () => {
	const dispatch = useAppDispatch();

	const [move, { isLoading }] = customTemplateAPI.useDuplicateMutation();

	const onMoveTemplate = async (templates: IId[]) => {
		try {
			await move({ templates })
				.unwrap();
			dispatch(templatePrivateAPI.util?.invalidateTags(['templates']));
			notificationSuccess('Заказной шаблон успешно дублирован в «Мои шаблоны»');
		} catch (e) {
			notificationError('Произошла ошибка при дублировании шаблона');
		}
	};

	return {
		onMoveTemplate,
		isLoading,
	};
};
