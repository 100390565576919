import { Tariff, tariffMapData } from '../../../../../entities/tariff/model/data';

export const adminUserChangeTariffData = [
	{
		id: Tariff.TRIAL,
		name: tariffMapData.get(Tariff.TRIAL) || '',
	},
	{
		id: Tariff.BASIC,
		name: tariffMapData.get(Tariff.BASIC) || '',
	},
];

export const adminUserChangeTariffDurationData = [
	{
		id: '1',
		name: '1 месяц',
	},
	{
		id: '2',
		name: '3 месяца',
	},
	{
		id: '3',
		name: '6 месяцев',
	},
	{
		id: '4',
		name: '12 месяцев',
	},
	{
		id: '5',
		name: 'Выбрать',
	},
];
