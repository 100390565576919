import InterfacePanel from '../../InterfacePanel';
import ManipulatorInterfaceBar from '../ManipulatorInterfaceBar';

class ToolBar extends ManipulatorInterfaceBar {
	constructor(...panels: InterfacePanel[]) {
		super();
		this.setRootElementClassName('toolbar show');
		this.rootElement.append(...panels.map(panel => panel.getElement()));
		this.injectPanels(...panels);
	}
}

export default ToolBar;
