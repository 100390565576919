import { ACCOUNT_STATUS } from '../../../../entities/user/account/api/types';

interface IChangeStatusAbility {
	[ACCOUNT_STATUS.SERVICE_ADMIN]: boolean,
	[ACCOUNT_STATUS.SUPER_ADMIN]: boolean,
	[ACCOUNT_STATUS.ADMIN]: boolean,
	[ACCOUNT_STATUS.EMPLOYEE]: boolean,
	[ACCOUNT_STATUS.INTEGRATOR]: boolean,
}

export const changeStatusUserAbility = new Map<ACCOUNT_STATUS, IChangeStatusAbility>([
	[ACCOUNT_STATUS.EMPLOYEE, {
		[ACCOUNT_STATUS.EMPLOYEE]: false,
		[ACCOUNT_STATUS.ADMIN]: false,
		[ACCOUNT_STATUS.SUPER_ADMIN]: false,
		[ACCOUNT_STATUS.SERVICE_ADMIN]: false,
		[ACCOUNT_STATUS.INTEGRATOR]: false,
	}],
	[ACCOUNT_STATUS.ADMIN, {
		[ACCOUNT_STATUS.EMPLOYEE]: true,
		[ACCOUNT_STATUS.ADMIN]: true,
		[ACCOUNT_STATUS.SUPER_ADMIN]: false,
		[ACCOUNT_STATUS.SERVICE_ADMIN]: false,
		[ACCOUNT_STATUS.INTEGRATOR]: false,
	}],
	[ACCOUNT_STATUS.SUPER_ADMIN, {
		[ACCOUNT_STATUS.EMPLOYEE]: true,
		[ACCOUNT_STATUS.ADMIN]: true,
		[ACCOUNT_STATUS.SUPER_ADMIN]: true,
		[ACCOUNT_STATUS.SERVICE_ADMIN]: false,
		[ACCOUNT_STATUS.INTEGRATOR]: false,
	}],
	[ACCOUNT_STATUS.SERVICE_ADMIN, {
		[ACCOUNT_STATUS.EMPLOYEE]: true,
		[ACCOUNT_STATUS.ADMIN]: true,
		[ACCOUNT_STATUS.SUPER_ADMIN]: true,
		[ACCOUNT_STATUS.SERVICE_ADMIN]: true,
		[ACCOUNT_STATUS.INTEGRATOR]: true,
	}],
	[ACCOUNT_STATUS.INTEGRATOR, {
		[ACCOUNT_STATUS.EMPLOYEE]: false,
		[ACCOUNT_STATUS.ADMIN]: false,
		[ACCOUNT_STATUS.SUPER_ADMIN]: false,
		[ACCOUNT_STATUS.SERVICE_ADMIN]: false,
		[ACCOUNT_STATUS.INTEGRATOR]: false,
	}],
]);
