import IComponent from '../../components/IComponent';
import IComponentFactory from './IComponentFactory';
import { AnyComponentStructure } from '../../Types';
import ComponentFactory from './ComponentFactory';

/**
 * Реализация паттерна фабрики, собирает и настраивает компоненты.
 */
class StaticComponentFactory extends ComponentFactory<null> implements IComponentFactory {
	// eslint-disable-next-line no-useless-constructor
	constructor() {
		super();
	}

	public createComponent = <ComponentType extends IComponent>(
		structure: AnyComponentStructure,
	): ComponentType => {
		const component = this.getClearComponent(structure.type);
		component.setStructure(() => structure);
		return component as ComponentType;
	};
}

export default StaticComponentFactory;
