import SketchPDFGenerator from './SketchPDFGenerator';
import { AnyComponentStructure } from '../../Sketch/Types';

const savePDF = (
	structure: AnyComponentStructure,
	name: string,
): Promise<void> => new Promise<void>((resolve, reject) => {
	const tempContainer = document.createElement('div');
	document.body.append(tempContainer);

	const generator = new SketchPDFGenerator(tempContainer, structure, name);

	setTimeout(async () => {
		try {
			await generator.savePDF();
			tempContainer.remove();
			resolve();
		} catch (error) {
			tempContainer.remove();
			reject(error);
		}
	}, 0);
});

export default savePDF;
