import React, { useState } from 'react';
import css from '../WorkshopCategoriesBar.module.scss';
import { classNames } from '../../../../../shared/libs/classNames';
import { WorkshopCategoriesDnDList } from '../list/WorkshopCategoriesDnDList';
import { workshopCategoryApi } from '../../../../../entities/workshop-category';
import { useDebounce } from '../../../../../shared/libs/useDebounce';
import SearchInput from '../../../../../components/ui/searchInput/SearchInput';

interface WorkshopModulesCategoriesBarProps {
	className?: string,
}

export const WorkshopModulesCategoriesBar = ({ className }: WorkshopModulesCategoriesBarProps) => {
	const [search, setSearch] = useState('');

	const debouncedSearch = useDebounce(search.trim(), 200);

	const { data: categoriesData } = workshopCategoryApi.useGetWorkshopCategoriesQuery({
		page: 1,
		limit: 127,
		search: debouncedSearch,
	});

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<SearchInput
				styles={css.search}
				searchValue={search}
				onChange={setSearch}
				placeholder="Поиск по категориям"
			/>
			<WorkshopCategoriesDnDList data={categoriesData?.moduleCategories} />
		</div>
	);
};
