import React from 'react';
import { collectionTabsData } from '../model/tabs-config';
import { ManualButton } from '../../../../components/ui/manual-button/manual-button';
import { useTabs } from '../../../../shared/libs/useTabs';
import css from './collection-tabs.module.scss';
import { CollectionTab } from '../../../../shared/ui';
import { useAppSelector } from '../../../../shared/libs/redux';
import { SocialNetworks } from './social-networks/social-networks';
import { ToTariffButton } from '../../../../shared/ui/to-tariff-button/to-tariff-button';

export const CollectionTabs = () => {
	const status = useAppSelector(state => state.personalAccount.account.status);

	const {
		currentTab,
		onSetCurrentTab,
	} = useTabs(collectionTabsData);

	return (
		<div className={css.wrapper}>
			{
				collectionTabsData.map((tab, i) => {
					const isActive = currentTab === i;

					if (tab.accessible && !tab.accessible.includes(status)) return;

					return (
						<CollectionTab
							index={i}
							tab={tab}
							key={tab.name}
							isActive={isActive}
							setTab={onSetCurrentTab}
						/>
					);
				})
			}
			<ManualButton />
			<ToTariffButton />
			<SocialNetworks />
		</div>
	);
};
