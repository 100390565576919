import { templatePrivateAPI } from '../../../../../../../entities/templates/private/api/api';
import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { useCleanTemplateSelection } from '../../../../../../../hooks/template-selection/useCleanTemplateSelection';
import { teamTemplateAPI } from '../../../../../../../entities/templates/team/api/api';
import {
	setIsChoicePlaceToMoveModalActive,
	setPrivateTemplateSelectedList,
} from '../../../../../../../entities/templates/private/model/slice';

export const useMoveTemplates = () => {
	const dispatch = useAppDispatch();

	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const [duplicateToTeam, { isLoading }] = templatePrivateAPI.useDuplicateToTeamMutation();

	const { cleanTemplateSelection } = useCleanTemplateSelection();

	const setActive = (value: boolean) => {
		dispatch(setIsChoicePlaceToMoveModalActive(value));
	};
	const moveTemplate = async (selectedTeamsId: string[]) => {
		if (selectedList.length && selectedTeamsId.length) {
			const templates = selectedList.map(el => ({ id: el }));
			const teams = selectedTeamsId.map(el => ({ id: el }));

			try {
				await duplicateToTeam({
					templates,
					teams,
				})
					.unwrap();

				dispatch(teamTemplateAPI.util?.invalidateTags(['team']));

				if (templates.length === 1) {
					notificationSuccess('Шаблон успешно перемещен!');
				} else if (templates.length > 1) {
					notificationSuccess('Шаблоны успешно перенесены!');
				}
			} catch (e) {
				if (templates.length === 1) {
					notificationError('Ошибка при перемещении шаблона!');
				} else if (templates.length > 1) {
					notificationError('Ошибка при перемещении шаблонов!');
				}
			} finally {
				setActive(false);

				cleanTemplateSelection();
				dispatch(setPrivateTemplateSelectedList([]));
			}
		}
	};

	return {
		isLoading,
		setActive,
		moveTemplate,
	};
};
