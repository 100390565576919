import ToolBarTool from '../ToolBarTool';

/**
 * Базовый элемент для элементов навигации по истории действий пользователя.
 */
abstract class ActionTool extends ToolBarTool {
	private readonly className: string;

	private isHighlightEnable: boolean;

	protected constructor(className: string) {
		super(className);
		this.isHighlightEnable = false;
		this.className = `${className}_wake-up`;
	}

	public enableHighlight = () => {
		this.rootElement.classList.add(this.className);
		this.isHighlightEnable = true;
	};

	public disableHighlight = () => {
		this.rootElement.classList.remove(this.className);
		this.isHighlightEnable = false;
	};
}

export default ActionTool;
