import { useCallback, useLayoutEffect, useState } from "react";
import { useHandleSelectedList } from "./useHandleSelectedList";
import { useAppSelector } from "../../shared/libs/redux";
import { throttle } from "lodash";
import logo from '../../components/pages/registration/logo/Logo';

export const SELECTABLE_ITEM_CLASS = "selectable_template";
export const SELECTED_ITEM_CLASS = "selected";

interface ICoordinates {
	x: number,
	y: number,
}

export const useSelection = () => {
	const privateTemplateList = useAppSelector(state => state.privateTemplate.privateTemplates);

	const [templates, setTemplates] = useState<NodeList>();

	const [coordinates, setCoordinates] = useState<ICoordinates>({
		x: 0,
		y: 0,
	});

	const {
		setList,
	} = useHandleSelectedList();

	const [target, setTarget] = useState<null | HTMLDivElement>(null);

	// * При динамической пагинации добавляются элементы в стейт
	// * Приходится обновлять список dom-элементов (шаблонов)
	useLayoutEffect(() => {
		if (privateTemplateList) {
			const templates = document.querySelectorAll("." + SELECTABLE_ITEM_CLASS);
			setTemplates(templates);
		}
	}, [privateTemplateList]);

	useLayoutEffect(() => {
		const {
			x,
			y
		} = coordinates;
		const onMouseDown = (e: MouseEvent) => {
			if (
				!document.contains(target)
				&& e.button <= 0 && document.body.style.overflowY !== "hidden"
				&& (e.target && !document.getElementById("toast-container")
					?.contains(e.target as Node))
			) {
				setCoordinates({
					x: e.pageX,
					y: e.pageY,
				});
				const newTarget = document.createElement("div");

				newTarget.style.border = "1px solid #0075FF";
				newTarget.id = "line";
				newTarget.style.zIndex = "10";
				newTarget.style.position = "absolute";
				newTarget.style.backgroundColor = "rgba(0, 117, 255, 0.2)";

				setTarget(newTarget);
			}
		};

		const onMouseUp = () => {
			if (target && document.body.contains(target)) {
				document.body.removeChild(target);

				target.style.width = "0px";
				target.style.height = "0px";

				setCoordinates({
					x: 0,
					y: 0,
				});
			}
			setTarget(null);
		};

		const onMouseMove = (e: MouseEvent) => {
			if (!x || !y || !target) return;

			if (!document.body.contains(target)) document.body.appendChild(target);

			if (target) {
				target.style.width = `${Math.abs(e.pageX - x)}px`;
				target.style.height = `${Math.abs(e.pageY - y)}px`;
				target.style.left = `${e.pageX < x ? e.pageX : x}px`;
				target.style.top = `${e.pageY < y ? e.pageY : y}px`;

				selectBlock();
			}
		};

		document.body.addEventListener("mousedown", onMouseDown);
		document.body.addEventListener("mousemove", onMouseMove);
		document.body.addEventListener("mouseup", onMouseUp);

		return () => {
			document.body.removeEventListener("mousedown", onMouseDown);
			document.body.removeEventListener("mousemove", onMouseMove);
			document.body.removeEventListener("mouseup", onMouseUp);
		};
	}, [target, coordinates, templates]);

	let elems: string[] = [];

	const selectBlock = throttle(async () => {
		if (!target) return;
		templates?.forEach(el => {
			const elem = el as HTMLElement;

			const {
				classList,
				id
			} = elem;

			const data = coordinate(elem)
				.concat(coordinate(target as HTMLElement));

			const isCollision = collisionDetection(
				...(data as [number, number, number, number, number, number, number, number])
			);

			if (classList.contains(SELECTED_ITEM_CLASS) && !isCollision) {
				classList.remove(SELECTED_ITEM_CLASS);
				elems = elems.filter(el => el !== id);
			} else if (!classList.contains(SELECTED_ITEM_CLASS) && isCollision) {
				classList.add(SELECTED_ITEM_CLASS);
				if (!elems.includes(id)) elems = [...elems, id];
			}
		});
		setList(elems);
	}, 20);

	const coordinate = useCallback((el: HTMLElement): [number, number, number, number] => {
		const pos = el.getBoundingClientRect();
		const top = pos.top;
		const left = pos.left;
		const right = pos.right;
		const bottom = pos.bottom;
		const height = bottom - top;
		const width = right - left;
		return [left, top, width, height];
	}, []);

	const collisionDetection = useCallback(
		(
			x1: number,
			y1: number,
			w1: number,
			h1: number,
			x2: number,
			y2: number,
			w2: number,
			h2: number
		): boolean => {
			return x1 < x2 + w2 && y1 < y2 + h2 && x1 + w1 > x2 && y1 + h1 > y2;
		},
		[]
	);

	return target;
};
