import SvgCollection from '../../../../../utils/SvgCollection';
import ActionTool from '../ActionTool';
import Utils from '../../../../../utils/impl/Utils';

/**
 * Инструмент возврата действия пользователя вперед.
 */
class ActionBackTool extends ActionTool {
	private readonly HINT_TEXT = 'Предыдущее действие / Ctrl+Z';

	constructor() {
		super('panel-action__back');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.backTool);
	}
}

export default ActionBackTool;
