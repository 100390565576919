import { getLength, Token, TokenFormat } from '../parser';
import {
	ITokenFormatUpdate,
	updateTokens,
	removeText as plainRemoveText,
	FormatFontSize, setFontFam,
	setFormat as plainSetFormat,
	setLink,
	sliceTokens,
	FormatFontColor,
} from '../formatted-string';
import { isCustomLink, tokenForPos } from '../formatted-string/utils';
import type {
	IBaseEditorOptions, IEditorOptions, Model, TextRange,
} from './types';
import { FontFamily, FontSize } from './types';
import { getInputText, isNotHaveSelection, startsWith } from './utils';
import Editor from './index';

/** типы инпутов, которые не обрабатываем и возвращаем сразу модель */
const skipInputTypes = new Set<string>([
	'insertOrderedList',	// Создаёт пронумерованный список из выбранного или на месте курсора.
	'insertUnorderedList',	// Создаёт список из выбранного или на месте курсора.
	'deleteOrderedList',
	'deleteUnorderedList',
]);

/** Вставляет указанный текст в модель (в указанную позицию) */
export function insertText(
	editor: Editor,
	pos: number,
	text: string,
	options: IBaseEditorOptions,
): Model {
	let updated: Token[];

	updated = updateTokens(editor, text, pos, pos, options.parse);

	if (options.resetFormatOnNewline && /^[\n\r]+$/.test(text)) {
		updated = plainSetFormat(updated, TokenFormat.None, pos, undefined, text.length);
	}

	return {
		...editor.model,
		tokens: updated,
	};
}

export function removeText(editor: Editor, from: number, to: number, options: IBaseEditorOptions): Model {
	// eslint-disable-next-line no-return-assign
	return {
		...editor.model,
		tokens: plainRemoveText(editor, editor.model.tokens, from, to - from, options.parse = {}),
	};
}

/**
 * Заменяет текст в выбранном диапазоне с учетом переданных опций.
 * @param editor - текущий редактор текста, нужен для использования внутренних полей.
 * @param {Model} model
 * @param {Token[] | string} text - Текст, которым заменим текст в выбранном диапазоне.
 * @param {number} from - Начало диапазона.
 * @param {number} to - Конец диапазона.
 * @param {IBaseEditorOptions} options - Опции для замены.
 */
export function replaceText(
	editor: Editor,
	text: Token[] | string,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Model {
	// Приводим переданный текст к строке (в случае когда передан массив токенов).
	const value = typeof text === 'string' ? text : getText(text);
	// Заменяем текст в ИСТИННОЙ модели в зависимости от переданных опций
	const newTokens = updateTokens(
		editor,
		value,
		from,
		to,
		options.parse,
	);
	const nextModel = {
		...editor.model,
		tokens: newTokens,
	};
	editor.model = nextModel;

	// Применяем форматирование из фрагмента
	// if (Array.isArray(text)) {
	// 	editor.model = applyFormatFromFragment(editor.model, newTokens, options, from);
	// 	editor.model = applySettingsFromFragment(editor.model, newTokens, options, from);
	// }

	return editor.model;
}

/**
 * Применяет новый формат к указанному диапазону и возвращает новый набор токенов
 */
export function setFormat(
	tokens: Token[],
	format: TokenFormat | ITokenFormatUpdate,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Token[] {
	const len = to - from;

	return plainSetFormat(tokens, format, from, undefined, len);
}

/**
 * Применяет новый font-family к указанному диапазону и возвращает новый набор токенов
 */
export function setFontFamily(
	tokens: Token[],
	fontFamily: FontFamily,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Token[] {
	const len = to - from;
	return setFontFam(tokens, fontFamily, from, undefined, len);
}

/**
 * Применяет новый font-size к указанному диапазону и возвращает новый набор токенов */
export function setFontSize(
	tokens: Token[],
	fontSize: FontSize,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Token[] {
	const len = to - from;
	return FormatFontSize(tokens, fontSize, from, undefined, len);
}

/**
 * Применяет новый font-size к указанному диапазону и возвращает новый набор токенов */
export function setFontColor(
	tokens: Token[],
	fontColor: string,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Token[] {
	const len = to - from;

	return FormatFontColor(tokens, fontColor, from, undefined, len);
}

export function toggleFormat(
	model: Model,
	format: TokenFormat,
	from: number,
	to: number,
	options: IBaseEditorOptions,
): Model {
	let source: Token | undefined;
	if (from !== to) {
		const fragment = sliceTokens(model.tokens, from, to);
		// Исходный токен
		[source] = fragment;
	} else {
		const pos = tokenForPos(model.tokens, from, undefined, 'start');
		if (pos.index !== -1) {
			source = model.tokens[pos.index];
		}
	}
	if (source) {
		const update: ITokenFormatUpdate = source.format & format
			// Если переданы одинаковые форматы
			? { remove: format }
			// Если переданы разные форматы
			: { add: format };

		return {
			...model,
			tokens: setFormat(model.tokens, update, from, to, options),
		};
	}

	if (!model.tokens.length && format) {
		return {
			...model,
			tokens: setFormat(model.tokens, { add: format }, 0, 0, options),
		};
	}

	return model;
}

export function applyFormatFromFragment(
	model: Model,
	fragment: Token[],
	options: IBaseEditorOptions,
	offset = 0,
): Model {
	fragment.forEach(token => {
		const len = token.value.length;
		if ('sticky' in token && token.sticky) {
			model.tokens = setFormat(model.tokens, token.format, offset, offset + len, options);
		} else if (token.format) {
			model.tokens = setFormat(model.tokens, { add: token.format }, offset, offset + len, options);
		}

		if (isCustomLink(token)) {
			model.tokens = setLink(model.tokens, token.link, offset, len);
		}

		offset += len;
	});

	return model;
}

export function applySettingsFromFragment(
	model: Model,
	fragment: Token[],
	options: IBaseEditorOptions,
	offset = 0,
): Model {
	fragment.forEach(token => {
		const len = token.value.length;
		model.tokens = setFontFamily(model.tokens, token.fontFamily, offset, offset + len, options);
		model.tokens = setFontSize(model.tokens, token.fontSize, offset, offset + len, options);
		model.tokens = setFontColor(model.tokens, token.color, offset, offset + len, options);

		offset += len;
	});

	return model;
}

/**
 * Типы форматирования текста (жирный, курсив, подчеркнутый, strike)
 */
const inputToFormat: Record<string, TokenFormat> = {
	formatBold: TokenFormat.Bold,
	formatItalic: TokenFormat.Italic,
	formatUnderline: TokenFormat.Underline,
	formatStrikeThrough: TokenFormat.Strike,
};

/**
 * Если тип переданного события есть в списке skipInputTypes, то отменяем действие браузера
 * по умолчанию и возвращаем модель.  */
function handleSkipEvent(evt: InputEvent, model: Model): Model | undefined {
	if (skipInputTypes.has(evt.inputType)) {
		evt.preventDefault();
		return model;
	}
	return undefined;
}

/**
 * handleFormatEvent обрабатывает модель если событие event.inputType связано с
 * форматированием (имя события начинается с "format").
 */
function handleFormatEvent(
	evt: InputEvent,
	model: Model,
	range: TextRange,
	options: IBaseEditorOptions,
): Model | undefined {
	const { inputType } = evt;
	/* Тут определяем события связанные с форматированием, то есть если у evt тип события
	 начинается с format (например "formatBold", "formatJustifyLeft" и
	 тп). */
	if (inputType && startsWith(inputType, 'format')) {
		const [from, to] = range;
		// Применяем форматирование: скорее всего это Safari с тачбаром
		if (inputType === 'formatFontColor') {
			const update: ITokenFormatUpdate = !(
				/^rgb\(0,\s*0,\s*0\)/.test(evt.data || '')
				|| evt.data === 'transparent'
			) ? { add: TokenFormat.Marked } : { remove: TokenFormat.Marked };

			return {
				...model,
				tokens: plainSetFormat(model.tokens, update, from, undefined, to - from),
			};
		}

		if (inputType === 'formatRemove') {
			return {
				...model,
				tokens: plainSetFormat(model.tokens, TokenFormat.None, from, undefined, to - from),
			};
		}

		if (inputType in inputToFormat) {
			return toggleFormat(model, inputToFormat[inputType], from, to, options);
		}

		return model;
	}
	return undefined;
}

/**
 * Обрабатывает модель если событие event.inputType связано с
 * вставкой (имя события начинается с "insert").
 * - Истинная модель редактора
 */
function handleInsertEvent(
	evt: InputEvent,
	editor: Editor,
	range: TextRange,
	options: IBaseEditorOptions,
): Model | undefined {
	const { inputType } = evt;
	if (inputType && startsWith(inputType, 'insert')) {
		const text = getInputEventText(evt);
		const replace = replaceText(editor, text, range[0], range[1], options);
		return replace;
	}
	return undefined;
}

/**
 * Выполняет обновление для старых браузеров, которые ещё не поддерживают
 * полноценный `InputEvent`
 */
export function updateFromOldEvent(
	text: string,
	editor: Editor,
	range: TextRange,
	prevRange: TextRange,
	options: IEditorOptions,
) {
	let [from, to] = prevRange;
	let update = '';
	if (isNotHaveSelection(prevRange)) {
		const len = getLength(editor.model.tokens);
		if (len > text.length) {
			// Удалили контент
			from = Math.min(from, range[0]);
			to = Math.max(to, range[1]);
			if (from === to) {
				// Удаление справа от курсора
				to += len - text.length;
			}
		} else {
			// Добавили контент
			update = text.slice(from, Math.max(range[0], range[1]));
		}
	} else {
		// Было выделение: либо заменили контент, либо удалили
		update = text.slice(Math.min(from, range[0]), range[1]);
	}

	return replaceText(editor, update, from, to, options);
}

// eslint-disable-next-line max-len
export function updateFromInputEventFallback(
	evt: InputEvent,
	editor: Editor,
	range: TextRange,
	prevRange: TextRange,
	options: IEditorOptions,
): Model {
	const updated = handleSkipEvent(evt, editor.model)
        || handleFormatEvent(evt, editor.model, range, options)
        || handleInsertEvent(evt, editor, prevRange, options);

	if (updated) {
		return updated;
	}

	const { inputType } = evt;
	if (inputType && startsWith(inputType, 'delete')) {
		const [from, to] = range;
		const [prevFrom, prevTo] = prevRange;
		const boundFrom = Math.min(from, prevFrom);
		let boundTo = Math.max(to, prevTo);

		if (boundFrom === boundTo && evt.inputType.includes('Forward')) {
			const curLen = getInputText(evt.currentTarget as Element).length;
			const prevLen = getLength(editor.model.tokens);
			if (prevLen > curLen) {
				boundTo += prevLen - curLen;
			}
		}

		return removeText(editor, boundFrom, boundTo, options);
	}

	return editor.model;
}

/**
 *
 * @param evt
 * @param editor - редактор
 * @param range
 * @param options
 */
export function updateFromInputEvent(
	evt: InputEvent,
	editor: Editor,
	range: TextRange,
	options:Required<IBaseEditorOptions>,
): Model {
	const updated = handleSkipEvent(evt, editor.model)
        || handleFormatEvent(evt, editor.model, range, options)
        || handleInsertEvent(evt, editor, range, options);
	if (updated) {
		return updated;
	}

	const { inputType } = evt;
	if (inputType && inputType.startsWith('delete')) {
		return removeText(editor, range[0], range[1], options);
	}
	return editor.model;
}

/**
 * Возвращает текстовое содержимое указанных токенов
 */
export function getText(tokens: Token[]): string {
	return tokens.map(t => t.value).join('');
}

/**
 * Обрабатывает ввод пользователя.
 */
export function getInputEventText(evt: InputEvent): string {
	if (evt.inputType === 'insertParagraph' || evt.inputType === 'insertLineBreak') {
		return '\n';
	}
	/* Если есть веденные данные - возвращаем их. */
	if (evt.data != null) {
		return evt.data;
	}

	// Расширение для Safari, используется. Например, для подстановки
	// нового значения на длинное нажатие клавиши (е → ё)
	if (evt.dataTransfer) {
		return evt.dataTransfer.getData('text/plain');
	}

	return '';
}
