enum AreaResizeTrigger {
	LEFT,
	TOP,
	RIGHT,
	BOTTOM,
	LEFT_TOP,
	RIGHT_TOP,
	RIGHT_BOTTOM,
	LEFT_BOTTOM,
}

export default AreaResizeTrigger;
