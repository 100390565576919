import React from 'react';
import TransitionActionsWithCards, {
	IActionsWithCardsItem,
} from '../../../../../../../../../ui/actionsWithCards/TransitionActionsWithCards';
import css from '../../../../../private/ui/private-template/context-menu/context-menu.module.scss';
import actionsDependsPossible from '../../../../../../../../../../helpers/actionsDependsPossible';
import { PossibleItem } from '../../../../../my/item/TemplateMyItem';
import { ReactComponent as TrashIcon } from '../../../../../../../../../../shared/images/red-trash.svg';
import { ReactComponent as MoveIcon } from '../../../../../../../../../../shared/images/move.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../../../../../../../shared/images/copy-link.svg';
import { ReactComponent as SendKpIcon } from '../../../../../../../../../../shared/images/green-send-kp.svg';

interface TeamTemplateContextMenuProps {
	onDelete: () => void,
	onCopyLink: () => void,
	onSendOffer: () => void,
	onMoveTemplate: () => void,
	isActionMenuOpened: boolean,
	menuPosition: { x: number; y: number },
	menuRef: React.RefObject<HTMLDivElement>,
	setIsActionMenuOpened: (value: boolean) => void,
}

const TEMPLATE_MY_POSSIBILITIES: PossibleItem = {
	move: true,
	copy: true,
	delete: true,
};

export const TeamTemplateContextMenu = ({
	menuRef,
	onDelete,
	onCopyLink,
	onSendOffer,
	menuPosition,
	onMoveTemplate,
	isActionMenuOpened,
	setIsActionMenuOpened,
}: TeamTemplateContextMenuProps) => {
	// * При закрытии меню
	const onCloseMenu = () => setIsActionMenuOpened(false);

	const actionsWithCardsItems: IActionsWithCardsItem[] = [
		{
			text: 'Отправить КП',
			smile: <SendKpIcon />,
			onClick: onSendOffer,
			cssClassSmile: css.sendOfferIcon,
			cssClassText: css.sendOfferText,
		},
		{
			text: 'Переместить в «Мои шаблоны»',
			smile: <MoveIcon />,
			onClick: onMoveTemplate,
		},
		{
			text: 'Скопировать ссылку',
			smile: <CopyLinkIcon />,
			onClick: onCopyLink,
		},
		{
			text: 'Удалить',
			smile: <TrashIcon />,
			onClick: onDelete,
			cssClassSmile: css.deleteOfferIcon,
			cssClassText: css.deleteOfferText,
		},
	];

	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				left: menuPosition.x,
				top: menuPosition.y,
				display: 'inline-block',
				zIndex: 100,
			}}
		>
			<TransitionActionsWithCards
				ref={menuRef}
				onClose={onCloseMenu}
				isActive={isActionMenuOpened}
				cssClassMain={css.cards_actions}
				items={actionsDependsPossible(TEMPLATE_MY_POSSIBILITIES, actionsWithCardsItems)}
			/>
		</div>
	);
};
