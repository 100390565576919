import { useState } from 'react';
import { SearchTagBar } from '../../../../../../../../widgets/search-tag-bar';
import { useDebounce } from '../../../../../../../../shared/libs/useDebounce';
import { templateTagAPI } from '../../../../../../../../entities/templates/tags/api/api';
import { useBindTemplateTag } from '../../../../../../../../features/templates/tag/bind/api/useBindTemplateTag';
import { ITemplateTag } from '../../../../../../../../entities/templates/tags/api/types';
import { ITagData } from '../../../../../../../../entities/modules/tag/api/types';
import { ITemplateData } from '../../../../../../../../entities/templates/types';

interface TemplateSearchTagBarProps {
	template: ITemplateData | undefined,
	type: 'TEAM_TEMPLATE' | 'PRIVATE_TEMPLATE',
	onCreateTag: (name: string) => Promise<ITemplateTag | null>,
}

export const TemplateSearchTagBar = ({
	type,
	template,
	onCreateTag,
}: TemplateSearchTagBarProps) => {
	const [search, setSearch] = useState('');

	const debounceSearch = useDebounce(search.trim(), 200);

	const { data } = templateTagAPI.useGetAllQuery({
		search: debounceSearch,
		page: 1,
		limit: 127,
	});

	const bind = useBindTemplateTag(type, template?.id || '');

	return (
		<SearchTagBar
			tags={data?.templateTags}
			search={search}
			bindTag={bind}
			setSearch={setSearch}
			createTag={onCreateTag}
			templateTags={(template?.tags.fromTemplates || []) as ITagData[]}
		/>
	);
};
