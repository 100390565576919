import React from 'react';
import { MultipleUserSelection } from '../../../../../../widgets/entity/multiple-selection';
import { useMultipleLegalEntityManagers } from '../../../lib/useMultipleLegalEntityManagers';

const MultipleManagerSelectionModal = () => {
	const {
		isManagerSelectionActive,
		handleManagersModal,
		users,
		onAddingManager,
	} = useMultipleLegalEntityManagers();

	return (
		<MultipleUserSelection
			onBind={onAddingManager}
			selection="single"
			isBinding={false}
			availableUsers={users}
			btnText="Добавить менеджера"
			isEntityUserSelectionModalActive={isManagerSelectionActive}
			handleIsEntityUserSelectionModalActive={handleManagersModal}
		/>
	);
};

export default MultipleManagerSelectionModal;
