import React from 'react';
import css from '../registration.module.scss';
import Logo from '../logo/Logo';
import AgreeWithPolicy from '../AgreeWithPolicy';
import HaveAnAccountBlock from '../HaveAnAccountBlock';
import { MainButton } from '../../../ui/main-button/main-button';

interface MainRegistrationProps {
	children: React.ReactNode,
	isButtonActive: boolean,
	onSignUp: () => void,
	isDataLoading: boolean | undefined,
	agreeWithPolicy: boolean,
	setAgreeWithPolicy: (value: boolean) => void,
}

const MainRegistration: React.FC<MainRegistrationProps> = ({
	children,
	agreeWithPolicy,
	setAgreeWithPolicy,
	isDataLoading,
	onSignUp,
	isButtonActive,
}: MainRegistrationProps) => (
	<div className={css.wrapper}>
		<Logo isPasswordSent={false} />
		<h2 className={css.title}>Добро пожаловать!</h2>
		<div className={css.descr}>Расскажите нам немного о себе</div>
		<div className={css.block}>
			{children}
			<AgreeWithPolicy
				agreeWithPolicy={agreeWithPolicy}
				setAgreeWithPolicy={setAgreeWithPolicy}
			/>
		</div>
		<MainButton
			type="button"
			btnStyle="bright"
			onClick={onSignUp}
			disabled={!isButtonActive || isDataLoading}
			styles={css.registration_btn}
		>
			Далее
		</MainButton>
		<HaveAnAccountBlock />
	</div>
);

export default MainRegistration;
