export type Fn = (value: { [ket: string]: any }) => string

export interface INode<T> {
	value: T
	next: INode<T> | null
	previous: INode<T> | null
	toString(fn?: Fn): string
}

export class DoublyLinkedListNode<T> implements INode<T> {
	next: INode<T> | null;
	previous: INode<T> | null;
	value: T;
	constructor(value: T, next?: INode<T> | null, previous?: INode<T> | null) {
		this.value = value;
		if (next) {
			this.next = next;
		} else {
			this.next = null;
		}
		if (previous) {
			this.previous = previous;
		} else {
			this.previous = null;
		}
	}

	public toString(callback?: Fn): string {
		if (callback) {
			return callback(this.value as { [ket: string]: any });
		}
		return `${this.value}`;
	}
}
