import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITagGroupData } from '../../../entities/modules/tag-group/api/types';

interface IModalsActive {
	isTagGroupMenuModalActive: boolean,
	isEditTagGroupModalActive: boolean,
	isDeletingTagGroupModalActive: boolean,
	isCreatingTagGroupModalActive: boolean,
	isUnbindingTagGroupModalActive: boolean,
	isBindingTagGroupModalActive: boolean,
}

interface IInitialState {
	totalCount: number,
	searchValue: string,
	tagsGroupData: ITagGroupData[] | null,
	editingTagGroupId: number | null,
	deletingTagGroupId: number | null,
	modalsActive: IModalsActive,
	bindingGroupId: number | null,
	unbindingGroupId: number | null,
	unbindingTagId: number | null,
}

const initialState: IInitialState = {
	totalCount: 0,
	searchValue: '',
	tagsGroupData: null,
	deletingTagGroupId: null,
	editingTagGroupId: null,
	bindingGroupId: null,
	unbindingTagId: null,
	unbindingGroupId: null,
	modalsActive: {
		isEditTagGroupModalActive: false,
		isTagGroupMenuModalActive: false,
		isCreatingTagGroupModalActive: false,
		isDeletingTagGroupModalActive: false,
		isUnbindingTagGroupModalActive: false,
		isBindingTagGroupModalActive: false,
	},
};

export const tagGroupReducer = createSlice({
	name: 'tagGroup',
	initialState,
	reducers: {
		setTagGroupData: (state, action: PayloadAction<ITagGroupData[]>) => {
			state.tagsGroupData = action.payload;
		},
		setTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		setBindingGroupId: (state, action: PayloadAction<number | null>) => {
			state.bindingGroupId = action.payload;
		},
		setUnbindingTagId: (state, action: PayloadAction<number>) => {
			state.unbindingTagId = action.payload;
		},
		setUnbindingGroupId: (state, action: PayloadAction<number>) => {
			state.unbindingGroupId = action.payload;
		},
		setEditingTagGroupId: (state, action: PayloadAction<number>) => {
			state.editingTagGroupId = action.payload;
		},
		setIsEditTagGroupModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isEditTagGroupModalActive = action.payload;
		},
		setDeletingTagGroupId: (state, action: PayloadAction<number>) => {
			state.deletingTagGroupId = action.payload;
		},
		setIsDeletingTagGroupModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeletingTagGroupModalActive = action.payload;
		},
		setIsCreatingTagGroupModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreatingTagGroupModalActive = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
		setIsTagGroupMenuModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isTagGroupMenuModalActive = action.payload;
		},
		setIsUnbindingTagGroupModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isUnbindingTagGroupModalActive = action.payload;
		},
		setIsBindingTagGroupModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isBindingTagGroupModalActive = action.payload;
		},
	},
});

export const {
	setTotalCount,
	setSearchValue,
	setIsCreatingTagGroupModalActive,
	setIsTagGroupMenuModalActive,
	setIsDeletingTagGroupModalActive,
	setIsUnbindingTagGroupModalActive,
	setIsBindingTagGroupModalActive,
	setIsEditTagGroupModalActive,
	setDeletingTagGroupId,
	setEditingTagGroupId,
	setBindingGroupId,
	setTagGroupData,
	setUnbindingTagId,
	setUnbindingGroupId,
} = tagGroupReducer.actions;

export default tagGroupReducer.reducer;
