import { DoublyLinkedList } from '../structures/DoublyLinkedList';
import IGraphic from './IGraphic';
import { DoublyLinkedListNode } from '../structures/DoublyLinkedListNode';

/**
 * Двунаправленная коллекция графики.
 */
class GraphicList<GraphicType extends IGraphic> extends DoublyLinkedList<GraphicType> {
	constructor() {
		super();
		this.addPostDeleteListener((node: DoublyLinkedListNode<GraphicType>) => {
			node.value.removeFrame();
			this.syncGraphicsOffset();
		});
		this.addPostAppendListener(() => {
			this.syncGraphicsOffset();
		});
	}

	/**
	 * Возвращает результат проверки на содержание графики в коллекции.
	 * @param graphic проверяемая графика.
	 */
	public isOwnGraphic = (graphic: GraphicType): boolean => {
		const index = this.getIndex(graphic);
		return index !== -1;
	};

	public syncGraphicsOffset = () => {
		const graphics = this.getElements();
		graphics.forEach((graphic, index) => {
			graphic.setOffset(index);
		});
	};
}

export default GraphicList;
