import React, { useState } from 'react';
import css from './createInputArrow.module.scss';
import InputArrowItem from './inputArrowItem/InputArrowItem';

interface CreateInputArrowProps {
	text: string,
	inputData?: {
		id: string,
		name: string
	}[],
	choicedItemsId: string[],
	onAddChoicedItem: (value: string) => void,
	onRemoveChoicedItem: (value: string) => void
}

const CreateInputArrow: React.FC<CreateInputArrowProps> = ({
	text,
	inputData,
	choicedItemsId,
	onAddChoicedItem,
	onRemoveChoicedItem,
}: CreateInputArrowProps) => {
	const [areHiddenShowed, setAreHiddenShow] = useState(false);

	CreateInputArrow.defaultProps = {
		inputData: undefined,
	};

	// const onAddChoicedItemLoc = (id: string) => {
	//     setChoicedId(choicedId => [...choicedId, id])
	// }

	// const onRemoveChoicedItemLoc = (id: string) => {
	//     setChoicedId(choicedId => choicedId.filter(item => item !== id))
	// }

	const isItemSelected = (id: string) => choicedItemsId.includes(id);

	const findName = () => {
		const result = inputData?.map(item => {
			if (choicedItemsId?.includes(item.id)) {
				return item.name;
			}
			return undefined;
		})
			.filter(item => item !== undefined);
		return result?.join(', ');
	};

	const onShowHidden = () => {
		if (inputData) {
			setAreHiddenShow(!areHiddenShowed);
		}
	};

	return (
		<div className={css.wrapper}>
			<div
				className={css.inp}
				onClick={onShowHidden}
				role="presentation"
			>
				<div className={css.text}>
					{(choicedItemsId ? choicedItemsId.length > 0 : false) ? findName() : text}
				</div>
				<div>
					{
						areHiddenShowed
							? (
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 7L7 1L13 7"
										stroke="black"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)
							: (
								<svg
									width="14"
									height="8"
									viewBox="0 0 14 8"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1 1L7 7L13 1"
										stroke="#272727"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							)
					}
				</div>
			</div>
			{
				areHiddenShowed && inputData && (
					<div className={css.hiddenBlock}>
						{
							inputData.map(item => (
								<InputArrowItem
									name={item.name}
									id={item.id}
									isChoiced={isItemSelected(item.id)}
									onAddChoicedItem={onAddChoicedItem}
									onRemoveChoicedItem={onRemoveChoicedItem}
									key={item.id}
								/>
							))
						}
					</div>
				)
			}
		</div>
	);
};

export default CreateInputArrow;
