import Dependent from '../utils/dependent/Dependent';
import IManipulatorInterface from './IManipulatorInterface';

abstract class ManipulatorInterface<Dependencies> extends Dependent<Dependencies> implements IManipulatorInterface {
	private isVisible: boolean;

	protected constructor() {
		super();
		this.isVisible = true;
	}

	public show = () => {
		if (this.isVisible) {
			return;
		}
		this.onShow();
		this.isVisible = true;
	};

	public hide = () => {
		if (!this.isVisible) {
			return;
		}
		this.onHide();
		this.isVisible = false;
	};

	public isShowed = () => this.isVisible;

	/**
	 * Возвращает результат поиска активного элемента среди элементов интерфейса.
	 */
	public haveActiveElement = (): boolean => document.activeElement?.getAttribute('content-editable') === 'true'
			|| document.activeElement?.tagName.toLowerCase() === 'input';
	// const panelObjectInputs = this.interface.getObjectPanelInputsArr();
	// const panelObjectInputsElements = panelObjectInputs.map((i) => i.getElement());
	// let inputInFocus = false;
	// panelObjectInputsElements.forEach((input) => {
	// 	if (document.activeElement === input) {
	// 		inputInFocus = true;
	// 	}
	// });
	// if (inputInFocus) return;

	public abstract sync: VoidFunction;
	public abstract getElements(): DocumentFragment;
	public abstract disableInjectingElements: VoidFunction;

	protected abstract onHide: VoidFunction;
	protected abstract onShow: VoidFunction;
}

export default ManipulatorInterface;
