import UniterGraphic from '../UniterGraphic';
import GraphicType from '../GraphicType';
import { AnyGraphicStructure } from '../../Types';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';

class ModuleGraphic extends UniterGraphic<null> {
	public readonly type: GraphicType = GraphicType.MODULE;
	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-module';

	constructor() {
		super();
		this.graphicElement.classList.add(this.GRAPHIC_CLASS_NAME);
	}

	public getTexture = (): null => null;

	public setStructure = (fn: (prev: AnyGraphicStructure) => AnyGraphicStructure): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const { frame, id, offset } = updated;
		if (frame === null) {
			throw new ManipulatorError('group graphic frame cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setFrameConfiguration(_ => frame);
	};

	public setTexture = (fn: (prev: null) => null): void => {
		// nothing
	};

	public getUniqueTexture = (): null => null;

	protected startMutation = (): void => {
		// TODO
	};

	protected finishMutation = (): void => {
		// TODO
	};
}

export default ModuleGraphic;
