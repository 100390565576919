import { teamAPI } from '../../../../entities/team/api/api';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setIsTeamUserConfirmDeleteModalActive } from '../../../../redux/reducers/teamReducer';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useUntieUser = () => {
	const dispatch = useAppDispatch();

	const {
		untieTeamId,
		untieEmail,
		isTeamUserConfirmDeleteModalActive,
	} = useAppSelector(state => state.team);

	const [unbind] = teamAPI.useUnbindMutation();

	// * Удаление пользователя из команды
	const untieUser = async () => {
		if (untieEmail && untieTeamId) {
			try {
				await unbind({
					email: untieEmail,
					team: untieTeamId,
				})
					.unwrap();
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(setIsTeamUserConfirmDeleteModalActive(false));
				notificationSuccess('Вы успешно удалили пользователя!');
			} catch (e) {
				notificationError('Произошла ошибка при удалении пользователя...');
			}
		}
	};

	const handleIsTeamUserConfirmDeleteModalActive = (value: boolean) => {
		dispatch(setIsTeamUserConfirmDeleteModalActive(value));
	};

	return {
		untieUser,
		isTeamUserConfirmDeleteModalActive,
		handleIsTeamUserConfirmDeleteModalActive,
	};
};
