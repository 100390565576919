import React from 'react';
import css from './choiceBlock.module.scss';
import { IAccount } from '../../entities/user/account/api/types';
import { ISelectAbleCardData, SelectableFilterCard } from '../../shared/ui';

interface TeamsListProps {
	users: IAccount[],
	setBlockShown?: (value: boolean) => void,
	onChoicedUser?: (user: IAccount | null) => void
}

const initialData: ISelectAbleCardData = {
	id: '1',
	text: 'Все пользователи',
};

const TeamsList = ({
	onChoicedUser,
	users,
	setBlockShown,
}: TeamsListProps) => {
	const onTeamClick = (user: IAccount | null) => {
		if (onChoicedUser && setBlockShown) {
			setBlockShown(false);
			onChoicedUser(user);
		}
	};

	return (
		<>
			<SelectableFilterCard
				onSelect={() => onTeamClick(null)}
				data={initialData}
				styles={css.teamWrapper}
			/>
			{
				users.map(user => {
					const {
						name,
						email,
						photo,
					} = user;

					const data: ISelectAbleCardData = {
						id: email,
						text: name,
						photo,
					};

					return (
						<SelectableFilterCard
							data={data}
							key={email}
							styles={css.teamWrapper}
							onSelect={() => onTeamClick(user)}
						/>
					);
				})
			}
		</>
	);
};

export default TeamsList;
