import React, { useEffect } from 'react';
import css from './TemplateModulesModalTabs.module.scss';
import { classNames } from '../../../../shared/libs/classNames';
import { TemplateModulesModalTabsEnum } from '../modal/TemplateModulesModal';

interface TemplateModulesModalTabsProps {
	className?: string,
	currentTab?: TemplateModulesModalTabsEnum,
	setCurrentTab?: (tab: TemplateModulesModalTabsEnum) => void,
}

export const TemplateModulesModalTabs = ({ className, currentTab, setCurrentTab }: TemplateModulesModalTabsProps) => {
	const showGalleryModules = () => {
		setCurrentTab?.(TemplateModulesModalTabsEnum.GALLERY);
	};

	const showTemplatesModules = () => {
		setCurrentTab?.(TemplateModulesModalTabsEnum.ENTITY);
	};

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<div
				role="presentation"
				onClick={showTemplatesModules}
				className={classNames(
					css.tab_item,
					{ [css.active_tab]: currentTab === TemplateModulesModalTabsEnum.ENTITY },
				)}
			>
				Модули организации
			</div>
			<div
				role="presentation"
				onClick={showGalleryModules}
				className={classNames(
					css.tab_item,
					{ [css.active_tab]: currentTab === TemplateModulesModalTabsEnum.GALLERY },
				)}
			>
				Галерея
			</div>
		</div>
	);
};
