import React from 'react';
import cx from 'classnames';
import css from './skeleton.module.scss';

interface SkeletonTeamsProps {
	count: number;
}

const SkeletonTeams = ({
	count,
}: SkeletonTeamsProps) => (
	<>
		{
			Array.from({ length: count }, (_, index) => (
				<div className={css.skeleton_loading_card} key={index}>
					<div className={css.main}>
						<div className={cx(css.picture, css.skeleton)} />
						<div className={cx(css.name, css.skeleton)} />
						<div className={cx(css.count, css.skeleton)} />
					</div>
					<div className={cx(css.picture, css.skeleton)} />
				</div>
			))
		}
	</>
);

export default SkeletonTeams;
