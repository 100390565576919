import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOffer } from '../../entities/offer/api/types';

interface IInitialState {
    activeSearch: string,
    completeSearch: string,
    activeOfferList: IOffer[] | undefined,
    completedOfferList: IOffer[] | undefined
}

const initialState: IInitialState = {
	activeSearch: '',
	completeSearch: '',
	activeOfferList: undefined,
	completedOfferList: undefined,
};

export const offerSlice = createSlice({
	name: 'offer',
	initialState,
	reducers: {
		setActiveOfferList: (state, action: PayloadAction<IOffer[]>) => {
			state.activeOfferList = action.payload;
		},
		setCompletedOfferList: (state, action: PayloadAction<IOffer[]>) => {
			state.completedOfferList = action.payload;
		},
		setActiveOfferSearch: (state, action: PayloadAction<string>) => {
			state.activeSearch = action.payload;
		},
		setCompleteOfferSearch: (state, action: PayloadAction<string>) => {
			state.completeSearch = action.payload;
		},
	},
});

export const {
	setActiveOfferSearch,
	setActiveOfferList,
	setCompleteOfferSearch,
	setCompletedOfferList,
} = offerSlice.actions;

export default offerSlice.reducer;
