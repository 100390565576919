import { AnyManipulatorCommand } from '../../Types';
import Dependent from '../../utils/dependent/Dependent';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ComponentTreeBackupService from '../../component-tree/backup-service/ComponentTreeBackupService';
import ComponentTreeValidator from '../../component-tree/validator/ComponentTreeValidator';
import ActionStore from '../../mutations/ActionStore';
import CommandType from '../../mutations/commands/CommandType';
import IActionSender from '../../mutations/IActionSender';
import SketchState from '../../mutations/SketchState';
import SpatialAreaTree from '../spatial-quadrants/spatial-tree/SpatialAreaTree';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';
import { notificationError } from '../../../Notifications/callNotifcation';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import store from '../../../../redux/store/store';
import { getSocket, sendCommands } from '../../../../features/websocket/model/slice';

export interface ISketchStructureStabilizerDependencies {
	state: SketchState,
	actionStore: ActionStore,
	areaTree: SpatialAreaTree,
	focusObserver: ComponentUIObserver,
	componentTree: IComponentTree & ILayeredComponentTree,
}

/**
 * Стабилизатор структуры скетча. Сохраняет структуру валидной, даже если действия пользователя
 * или кода её повредили.
 */
class SketchStructureStabilizer extends Dependent<ISketchStructureStabilizerDependencies> {
	private readonly validator: ComponentTreeValidator;
	private readonly backupService: ComponentTreeBackupService;

	// Происходит ли в данный момент выполнение действия пользователя.
	// Выполнение больше одного действия в момент времени запрещен.
	private actionProcess: boolean;

	constructor() {
		super();
		this.actionProcess = false;
		this.validator = new ComponentTreeValidator();
		this.backupService = new ComponentTreeBackupService();

		this.addPostInjectDependenciesListener(dependencies => {
			this.validator.connectDependencies({
				componentTree: dependencies.componentTree,
			});
			this.backupService.connectDependencies({
				componentTree: dependencies.componentTree,
			});

			this.validator.injectDependencies();
			this.backupService.injectDependencies();

			this.backupService.addPostRestoreListener(dependencies.areaTree.sync);
			this.backupService.addPostRestoreListener(dependencies.focusObserver.sync);
			this.backupService.addPostRestoreListener(dependencies.componentTree.disableComponentFocus);
			this.backupService.addPostRestoreListener(dependencies.componentTree.disableComponentsEditMode);
		});
	}

	/**
	 * Инициализирующие действия после первой успешной загрузки конструктора.
	 */
	public init = () => {
		this.backupService.save();
	};

	/**
	 * Выполнить действие пользователя в безопасном режиме, когда в случае ошибки
	 * структура скетча вернется в последнее сохраненное корректное состояние.
	 * @param userAction Действие пользователя.
	 */
	public executeUserAction = (userAction: VoidFunction) => {
		this.startUserAction();
		const err = this.runAction(userAction);
		if (err !== null) {
			this.forceStopAction(err);
			return;
		}
		this.stopUserAction();
	};

	/**
	 * Запускает выполнение действия по изменение структуры, инициировавшее системой,
	 * а не пользовательскими действиями.
	 * @param systemAction Действие системы.
	 */
	public executeSystemAction = (systemAction: VoidFunction) => {
		this.startSystemAction();
		const err = this.runAction(systemAction);
		if (err !== null) {
			this.forceStopAction(err);
			return;
		}
		this.stopSystemAction();
	};

	/**
	 * Включает режим выполнения действия пользователя.
	 */
	public startUserAction = () => {
		if (this.actionProcess) {
			throw new ManipulatorError('action is active');
		}
		this.backupService.save();
		this.actionProcess = true;
	};

	/**
	 * Выключает режим выполнения действия пользователя, фиксирует и проверяет изменения на корректность.
	 */
	public stopUserAction = () => {
		if (!this.actionProcess) {
			throw new ManipulatorError('action is inactive');
		}

		this.performAction();

		this.actionProcess = false;
	};

	/**
	 * Включает режим выполнения действия системой.
	 */
	public startSystemAction = () => {
		if (this.actionProcess) {
			throw new ManipulatorError('action is active');
		}
		this.backupService.save();
		this.actionProcess = true;
	};

	/**
	 * Выключает режим выполнения действия пользователя и проверяет изменения на корректность.
	 */
	public stopSystemAction = (): boolean => {
		if (!this.actionProcess) {
			throw new ManipulatorError('action is inactive');
		}

		const validateError = this.validator.validateCurrentStructure();
		if (validateError !== null) {
			this.restoreBackup(new ManipulatorError(validateError));
		}

		this.actionProcess = false;

		return validateError === null;
	};

	public callActionError = (e: Error) => {
		if (this.actionProcess) {
			console.log('error in action time');
		}
		console.error(e);
	};

	/**
	 * Принудительная остановка действия пользователя.
	 */
	public forceStopUserAction = () => {
		this.performAction();
		this.actionProcess = false;
	};

	/**
	 * Возвращает результат выполнения действия пользователя.
	 * @param userAction Действие пользователя.
	 */
	private runAction = (userAction: VoidFunction): ManipulatorError | null => {
		let err: ManipulatorError | null = null;

		try {
			userAction();
		} catch (e) {
			if (e instanceof ManipulatorError) {
				err = e;
			} else {
				err = new ManipulatorError((e as Error).message);
				err.stack = (e as Error).stack;
				console.log(err.getBody());
			}
		}

		return err;
	};

	/**
	 * Восстанавливает последнюю стабильную структуру скетча.
	 */
	private forceStopAction = (err: ManipulatorError) => {
		this.restoreBackup(err);
		this.actionProcess = false;
	};

	/**
	 * Отправляет команды в удаленное хранилище.
	 * @param commands Команды на изменение структуры скетча.
	 */
	public sendCommands = (commands: AnyManipulatorCommand[]) => {
		// Команды на изменение последовательности отправлять не нужно, так как они нужны только внутри
		// манипулятора. Изменения номера слоя графики на сервере сохраняется через команду на изменение структуры.
		const commandForSend = commands.filter(command => command.type !== CommandType.LAYER_SEQUENCES);

		// TODO логика на отсутствие подключения
		// this.dependencies.sender.sendCommands(commandForSend);
		store.dispatch(sendCommands(commandForSend));
	};

	/**
	 * Формирует и проверяет действие пользователя на валидность, на основании чего
	 * принимает решение о восстановлении структуры или сохранении изменений.
	 */
	private performAction = () => {
		const action = this.dependencies.state.buildAction();

		if (action !== null) {
			const validateError = this.validator.validateCurrentStructure();
			if (validateError !== null) {
				this.restoreBackup(new ManipulatorError(validateError));
			} else if (!action.isEmpty()) {
				const redoCommands = action.getRedoCommands();
				this.dependencies.actionStore.push(action);
				this.sendCommands(redoCommands);
			}
		}
	};

	/**
	 * Восстанавливает последний корректный backup и сообщает об этом пользователю.
	 */
	private restoreBackup = (err: ManipulatorError) => {
		this.backupService.restore();
		notificationError(
			'Непредвиденная ошибка',
			'Восстановлено последнее корректное состояние структуры ввиду возникновения непредвиденной ошибки.',
		);
		console.log(
			err.getBody(),
		);
	};
}

export default SketchStructureStabilizer;
