import IComponentTree from '../../component-tree/IComponentTree';
import Dependent from '../dependent/Dependent';
import SizePanel from '../../frame/SizePanel';
import GraphicType from '../../graphic/GraphicType';
import PictureActionLayer from '../../mechanics/replace-picture/PictureActionLayer';
import PictureGraphic from '../../graphic/picture/PictureGraphic';
import IBaseUseCases from '../../use-cases/base/IBaseUseCases';
import SketchComponentType from '../../components/SketchComponentType';
import PictureComponent from '../../components/picture/PictureComponent';

interface ISizePanelDependencies {
	componentTree: IComponentTree,
}

class ComponentUIObserver extends Dependent<ISizePanelDependencies> {
	private sizePanels: SizePanel[];
	private pictureActions: PictureActionLayer[];

	private readonly baseUseCases: IBaseUseCases;

	constructor(private manipulatorContainer: HTMLElement, useCases: IBaseUseCases) {
		super();
		this.sizePanels = [];
		this.pictureActions = [];
		this.baseUseCases = useCases;
	}

	public sync = () => {
		const pictureComponents = this.dependencies
			.componentTree.getUniformComponents<PictureComponent>(SketchComponentType.PICTURE);

		const pictureActionsIsGraphic: PictureActionLayer[] = [];

		if (pictureComponents !== null) {
			for (let i = 0; i < pictureComponents.length; i++) {
				const graphics = pictureComponents[i].getGraphics();

				for (let j = 0; j < graphics.length; j++) {
					const pictureGraphic = graphics[j] as PictureGraphic;
					if (pictureGraphic.getPictureAction() === null) {
						const pictureAction = new PictureActionLayer(
							this.manipulatorContainer, 
							this.baseUseCases, 
							pictureGraphic,
						);
						
						pictureGraphic.connectPictureActionLayer(pictureAction);

						pictureGraphic.addPostEnableHoverListener(() => {
							if (pictureGraphic.isEnableHover()) pictureGraphic.enablePictureActionLayer();
						});
						pictureGraphic.addPostEnableFocusListener(() => {
							if (pictureGraphic.isEnableFocus()) pictureGraphic.enablePictureActionLayer();
						});

						pictureGraphic.addPostDisableHoverListener(() => {
							if (!pictureGraphic.isEnableFocus()) pictureGraphic.disablePictureActionLayer();
						});
						pictureGraphic.addPostDisableFocusListener(() => {
							pictureGraphic.disablePictureActionLayer();
						});

						this.pictureActions.push(pictureAction);
					}
					const pictureAction = pictureGraphic.getPictureAction();
					if (pictureAction !== null) {
						pictureActionsIsGraphic.push(pictureAction);
					}
				}
			}
		}
		this.pictureActions = this.syncPictureLayer(pictureActionsIsGraphic, this.pictureActions);

		this.disableSizePanels();
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		if (focusComponents === null) return;
		for (let i = 0; i < focusComponents.length; i++) {
			const graphics = focusComponents[i].getGraphics();
			if (!focusComponents[i].isEnableEditMode()) {
				for (let j = 0; j < graphics.length; j++) {
					const position = graphics[j].getGlobalPosition();
					const width = graphics[j].getRealWidth();
					let height = graphics[j].getRealHeight();
					if (graphics[j].type === GraphicType.GROUP) {
						height = graphics[j].getFrameConfiguration().height;
					}
					const sizePanel = new SizePanel();
					sizePanel.setSize(width, height);
					const { topTriggersElement, bottomTriggersElement, sizeElement } = sizePanel.getElements();
					topTriggersElement.style.left = `${position.x}px`;
					topTriggersElement.style.width = `${width}px`;
					topTriggersElement.style.top = `${position.y}px`;
					topTriggersElement.style.zIndex = '1000';
					topTriggersElement.style.position = 'absolute';

					bottomTriggersElement.style.left = `${position.x}px`;
					bottomTriggersElement.style.width = `${width}px`;
					bottomTriggersElement.style.top = `${position.y + height}px`;
					bottomTriggersElement.style.zIndex = '1000';
					bottomTriggersElement.style.position = 'absolute';

					if (graphics[j].type === GraphicType.LINE) {
						height = graphics[j].getFrameConfiguration().height;
						topTriggersElement.style.top = `${position.y + height / 2}px`;
						bottomTriggersElement.style.top = `${position.y + height / 2}px`;
					}

					sizeElement.style.left = `${position.x + width / 2}px`;
					sizeElement.style.top = `${position.y + height + 5}px`;
					sizeElement.style.position = 'absolute';
					sizeElement.style.zIndex = '1000';
					this.manipulatorContainer.append(topTriggersElement);
					this.manipulatorContainer.append(bottomTriggersElement);
					this.manipulatorContainer.append(sizeElement);
					this.sizePanels.push(sizePanel);
				}
			}
		}
	};
	
	private syncPictureLayer = (
		mainPictureActions: PictureActionLayer[],
		comparePictureActions: PictureActionLayer[],
	): PictureActionLayer[] => {
		const removePictures = comparePictureActions.filter(item => !mainPictureActions.includes(item));
		removePictures.forEach(picture => {
			picture.removeElement();
		});

		return comparePictureActions.filter(pictureAction => mainPictureActions.includes(pictureAction));
	};

	private disableSizePanels = () => {
		if (!this.sizePanels.length) return;
		for (let i = 0; i < this.sizePanels.length; i++) {
			this.sizePanels[i].disable();
		}
		this.sizePanels = [];
	};
}

export default ComponentUIObserver;
