import React from 'react';
import css from './changeable-tag-list.module.scss';
import { EditableTag } from '../../../../shared/ui/editable-tag/editable-tag';
import { ITemplateTag } from '../../../../entities/templates/tags/api/types';
import { ITagData } from '../../../../entities/modules/tag/api/types';

interface ChangeableTagListProps {
	activeTags: ITemplateTag[] | ITagData[],
	disabledTags?: ITemplateTag[] | ITagData[],
	deleteTag?: (id: number) => void,
	disableTagHint?: string,
	isNeedHint?: boolean,
}

export const ChangeableTagList = ({
	deleteTag,
	activeTags,
	disabledTags,
	isNeedHint = true,
	disableTagHint = 'Теги модуля не редактируются',
}: ChangeableTagListProps) => {
	const onDeleteTag = (id: number) => {
		if (deleteTag) deleteTag(id);
	};

	return (
		<div className={css.wrapper}>
			{
				activeTags?.map(tag => (
					<EditableTag
						key={tag.id}
						tag={tag}
						state="active"
						closeAble={!!deleteTag}
						onClose={onDeleteTag}
						styles={css.tag}
					/>
				))
			}
			{
				disabledTags?.map(tag => (
					<EditableTag
						key={tag.id}
						tag={tag}
						state="disabled"
						closeAble={false}
						styles={css.tag}
						hint={isNeedHint ? disableTagHint : undefined}
					/>
				))
			}
		</div>
	);
};
