import { RootState } from '../../reducers/reducer';

const all = (state: RootState) => state.moduleWindow;

const moduleModalsActive = (state: RootState) => all(state).modalsActive;
const modules = (state: RootState) => all(state).modules;

export {
	all,
	modules,
	moduleModalsActive,
};
