import React, { useMemo } from 'react';
import CreateInputArrow from '../../../../../components/pages/features/moduleWindow/createInputArrow/CreateInputArrow';
import { workshopModuleTagApi } from '../../../../../entities/workshop-tag';

interface SelectModuleTagsProps {
	selectTag: (id: string) => void,
	unselectTag: (id: string) => void,
	selectedTags: string[],
}

export const SelectModuleTags = ({ unselectTag, selectedTags, selectTag }: SelectModuleTagsProps) => {
	const { data: tags } = workshopModuleTagApi.useGetWorkshopTagsQuery({
		page: 1,
		limit: 127,
	});

	const selectData = useMemo(
		() => tags?.moduleTags?.map(tag => ({ ...tag, id: tag.id.toString() })),
		[tags],
	);

	return (
		<CreateInputArrow
			text="Теги"
			inputData={selectData}
			choicedItemsId={selectedTags}
			onAddChoicedItem={selectTag}
			onRemoveChoicedItem={unselectTag}
		/>
	);
};
