import { workshopModuleTagApi } from '../../../../entities/workshop-tag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleApi } from '../../../../entities/workshop-module';

export const useUnbindWorkshopModuleTag = (moduleId?: string) => {
	const dispatch = useAppDispatch();

	const [unbindTag] = workshopModuleTagApi.useUnbindMutation();

	const onUnbindTag = async (tagId: number) => {
		try {
			if (moduleId && tagId) {
				await unbindTag({ moduleId, tags: [tagId] }).unwrap();

				dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));
			}
		} catch (e) {
			notificationError('Произошла ошибка при отвязке тега', 'Не удалось отвязать тег');
		}
	};

	return { onUnbindTag };
};
