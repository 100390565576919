import { IRoleItem } from '../../../role/api/types';
import { ITeamItem } from '../../../team/api/types';
import { ILegalEntityItem } from '../../../legal-entity/api/types';
import { ITariff, ITransaction } from '../../../tariff/api/types';

export enum ACCOUNT_STATUS {
	SUPER_ADMIN = 'SUPER_ADMIN',
	ADMIN = 'ADMIN',
	EMPLOYEE = 'EMPLOYEE',
	SERVICE_ADMIN = 'SERVICE_ADMIN',
	INTEGRATOR = 'INTEGRATOR'
}

export interface IAccountOrganization {
	id: string,
	name: string,
}

export interface IAccount {
	email: string,
	name: string,
	phone: string,
	birthday: string,
	status: ACCOUNT_STATUS,
	organization: IAccountOrganization,
	photo: string,
	roles: IRoleItem[],
	teams: ITeamItem[],
	legalEntity: ILegalEntityItem[],
	tariff: ITariff | null,
	tariffTransactions: ITransaction[] | undefined,
}

export interface IUpdateAccountRequest {
	photo?: string;
	name?: string;
	birthday?: string;
	phone?: string;
}
