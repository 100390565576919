import OrganizerMutation from './OrganizerMutation';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IComponent from '../../../components/IComponent';

/**
 * Мутатор сдвига компонента.
 * Изменяет офсет компонента и обновляет его координаты для отсутствия визуального сдвига.
 */
class OrganizerOffsetMutation extends OrganizerMutation<IComponent> {
	public inspectPostMovePlace = (): void => {
		this.inspectAvailableGraphicForMove();
	};

	public run = () => {
		// Вычислить новые координаты
		const parentGraphicIndex = this.componentTree.getIndexByParentGraphic(this.graphic);
		const parentComponent = this.graphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component is null');
		}

		const postMoveParentGraphicIndex = parentGraphicIndex + this.moveOffset;
		const updatedPosition = this.getPostMovePosition(this.graphic, postMoveParentGraphicIndex);

		// Вычислить новый офсет
		const currentOffset = this.component.getOffset();
		if (currentOffset === null) {
			throw new ManipulatorError('offset is null');
		}

		this.componentTree.mutateByChangeOffset(this.component, currentOffset + this.moveOffset);

		this.graphic.setFrameConfiguration(prev => ({
			...prev,
			...updatedPosition,
		}));
	};
}

export default OrganizerOffsetMutation;
