import IComponent from '../components/IComponent';

/**
 * Контекст режима редактирования
 */
class EditModeContext {
	private currentEditContext: IComponent | null;

	constructor() {
		this.currentEditContext = null;
	}

	public getCurrentEditContext = (): IComponent | null => this.currentEditContext;

	public setCurrentEditContext = (context: IComponent | null) => {
		this.currentEditContext = context;
	};
}

export default EditModeContext;
