import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import Dependent from '../../utils/dependent/Dependent';
import IComponentTree from '../IComponentTree';
import IGraphic from '../../graphic/IGraphic';
import { AnyComponentStructure } from '../../Types';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IComponentUniter from '../../components/IComponentUniter';

/**
 * Основа для дерева компонентов, которое содержит набор методов для работы со всем деревом компонентов
 * в текущем скетче.
 */
abstract class ComponentTree<Dependencies> extends Dependent<Dependencies> implements IComponentTree {
	protected abstract readonly rootComponent: IComponent;

	protected readonly manipulatorElement: HTMLElement;

	protected constructor(manipulatorElement: HTMLElement) {
		super();
		this.manipulatorElement = manipulatorElement;
	}

	/**
	 * Снимает фокус (следовательно обводку и режим редактирования) со всех компонентов, кроме компонентов,
	 * указанных в `targetComponents`.
	 * @param targetComponents Компоненты, которые не подлежать сбросу фокуса.
	 */
	public disableFocusExcept = (...targetComponents: IComponent[]) => {
		const components = this.getComponents();
		components.forEach((component) => {
			const isTargetComponent = targetComponents.includes(component);
			if (isTargetComponent) {
				return;
			}
			component.disableEditMode();
			component.disableFocus();
			component.disableHover();
		});
	};

	/**
	 * Возвращает компоненты в фокусе.
	 * @return IComponent[] При нахождении подобных.
	 * @return null При отсутствии компонентов в фокусе.
	 */
	public getFocusComponents = (): IComponent[] | null => {
		const focusComponents = this.getComponents().filter(component => component.isEnableFocus()
			&& component.type !== SketchComponentType.PAGES_CONTAINER);
		return focusComponents.length === 0 ? null : focusComponents;
	};

	/**
	 * Возвращает коллекцию графики, находящейся в фокусе.
	 * @return IGraphic[] - Коллекция графики.
	 * @return null - В случае отсутствия графики в фокусе.
	 */
	public getFocusGraphics = (): IGraphic[] | null => {
		const focusComponents = this.getFocusComponents();
		if (focusComponents === null) {
			return null;
		}

		return focusComponents.map(component => component.getGraphics()).flat();
	};

	/**
	 * Включает фокус только у одного элемента, в то же время отключая его у других.
	 * @param component - Компонент, у которого необходимо включить фокус.
	 */
	public enableFocusOnly = (component: IComponent) => {
		component.enableFocus();
		this.disableFocusExcept(component);
	};

	/**
	 * Возвращает все компоненты типа `UniformType`, в случае отсутствия таковых - null.
	 * `UniformType` и `componentType` должны соответствовать друг другу.
	 * @param componentType - тип компонентов для поиска.
	 */
	public getUniformComponents = <UniformType extends IComponent>(
		componentType: SketchComponentType,
	): UniformType[] | null => {
		const components = this.getComponents();
		const uniformComponents = components
			.filter(component => component.type === componentType);
		return uniformComponents.length === 0 ? null : uniformComponents as UniformType[];
	};

	/**
	 * Возвращает все компоненты в фокусе типа `UniformType`, в случае отсутствия таковых - null.
	 * `UniformType` и `componentType` должны соответствовать друг другу.
	 * @param componentType - тип компонентов для поиска.
	 */
	public getUniformFocusComponents = <UniformType extends IComponent>(
		componentType: SketchComponentType,
	): UniformType[] | null => {
		const focusComponents = this.getFocusComponents();
		if (focusComponents === null) {
			return null;
		}
		const uniformComponents = focusComponents
			.filter(component => component.type === componentType);
		return uniformComponents.length === 0 ? null : uniformComponents as UniformType[];
	};

	/**
	 * Возвращает все существующие компоненты-объединители из дерева компонентов.
	 */
	public getUniterComponents = (): IComponentUniter[] | null => {
		const components = this.getComponents();
		const uniterComponents = components.filter(component => component.isUniter) as IComponentUniter[];
		return uniterComponents.length === 0 ? null : uniterComponents;
	};

	/**
	 * Возвращает все компоненты в режиме редактирования, кроме корневого компонента.
	 */
	public getEditableComponents = (): IComponent[] | null => {
		const components = this.getComponentsWithoutRoot();
		if (components === null) {
			return null;
		}
		const editableComponents = components.filter(component => component.isEnableEditMode());
		return editableComponents.length === 0 ? null : editableComponents;
	};

	/**
	 * Возвращает все компоненты в режиме редактирования типа `UniformType`, в случае отсутствия таковых - null.
	 * `UniformType` и `componentType` должны соответствовать друг другу.
	 * @param componentType Тип компонентов для поиска.
	 */
	public getEditableUniformComponents = <UniformType extends IComponent>(
		componentType: SketchComponentType,
	): UniformType[] | null => {
		const components = this.getEditableComponents();
		if (components === null) {
			return null;
		}

		const uniformComponents = components.filter(component => component.type === componentType);
		return uniformComponents.length === 0 ? null : uniformComponents as UniformType[];
	};

	/**
	 * Находится ли хотя бы один компонент в режиме фокуса или редактирования.
	 * */
	public hasEditableOrFocusComponent = (): boolean => {
		const components = this.getComponents();
		return components.filter(component => {
			if (component.type === SketchComponentType.PAGES_CONTAINER) {
				return component.isEnableEditMode();
			}
			return component.isEnableEditMode() || component.isEnableFocus();
		}).length !== 0;
	};

	/** Содержит ли дерево хотя бы один компонент в режиме редактирования. */
	public hasEditableComponent = (): boolean => this.rootComponent.hasEditableComponent();

	/**
	 * Возвращает структуру всех компонентов текущего скетча.
	 */
	public getStructure = () => this.rootComponent.getStructure();

	/**
	 * Возвращает абсолютно все компоненты дерева.
	 */
	public getComponents = (): IComponent[] => [this.rootComponent, ...this.rootComponent.getComponentAll()];

	public getRootComponent = (): IComponent => this.rootComponent;

	public getRootGraphics = (): IGraphic[] => this.rootComponent.getGraphics();

	/**
	 * Возвращает все компоненты кроме корневого.
	 */
	public getComponentsWithoutRoot = (): IComponent[] | null => {
		const components = this.rootComponent.getComponentAll();
		return components.length === 0 ? null : components;
	};

	/**
	 * Отключает фокус у всех компонентов.
	 */
	public disableComponentFocus = (): void => {
		const components = this.getComponents();
		components.forEach(component => component.disableFocus());
	};

	/**
	 * Отключает режим редактирования у всех компонентов.
	 */
	public disableComponentsEditMode = (): void => {
		const components = this.getComponents();
		components.forEach(component => component.disableEditMode());
	};

	/**
	 * Возвращает всю графику на всей глубине, которую в себе содержит переданная графика.
	 * @param targetGraphic Графика, в которой будет запущен поиск.
	 */
	public getInternalGraphics = (targetGraphic: IGraphic): IGraphic[] | null => {
		const targetComponent = targetGraphic.getParentComponent();
		if (targetComponent === null) {
			throw new ManipulatorError('target component not found');
		}

		const components = targetComponent.getComponentAll();
		const graphics = new Set<IGraphic>([...components.map(component => component.getGraphics()).flat()]);
		const result: IGraphic[] = [];

		let requiredParentGraphics: IGraphic[] = [targetGraphic];
		const nextRequiredParentGraphics: IGraphic[] = [];

		while (true) {
			graphics.forEach(graphic => {
				const parentGraphic = graphic.getParentGraphic();
				if (parentGraphic === null) {
					return;
				}
				if (requiredParentGraphics.includes(parentGraphic)) {
					nextRequiredParentGraphics.push(graphic);
					result.push(graphic);
				}
			});

			if (nextRequiredParentGraphics.length === 0) {
				break;
			}

			requiredParentGraphics = [...nextRequiredParentGraphics];
			nextRequiredParentGraphics.length = 0;
		}

		return result.length === 0 ? null : result;
	};

	/**
	 * Возвращает позицию графики относительно коллекции графики компонента,
	 * дочерним компонентов которого является родительский компонент текущей графики.
	 */
	public getIndexByParentGraphic = (graphic: IGraphic): number => {
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}
		const parentComponent = parentGraphic.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		const parentGraphics = parentComponent.getGraphics();
		const parentGraphicIndex = parentGraphics.indexOf(parentGraphic);
		if (parentGraphicIndex === -1) {
			throw new ManipulatorError('parent graphic index not found');
		}

		return parentGraphicIndex;
	};

	/**
	 * Синхронизирует размеры контейнерных компонентов начиная от компонента,
	 * отправленного в метод, вверх по дереву компонентов в сторону корня.
	 */
	public syncUniterComponentsSizeFromComponent = (component: IComponent) => {
		this.recursiveSyncContainerComponent(component);
	};

	/**
	 * Синхронизирует размеры всех компонентов объединителей.
	 */
	public syncUniterComponentsSize = () => {
		const uniters = this.getUniterComponents();
		if (uniters === null) {
			return;
		}
		for (let i = 0; i < uniters.length; i++) {
			uniters[i].syncSizeFrames();
		}
	};

	public abstract reset: () => void;
	public abstract load: (structure: AnyComponentStructure) => void;

	/**
	 * Возвращает корневой HTML элемент для встраивания всей структуры компонентов в родительский контейнер.
	 */
	public abstract getElementForEmbedding: () => HTMLElement;

	/**
	 * Рекурсивно запускает синхронизацию размеров у компонента и его родителя.
	 * @param component Синхронизируемый компонент.
	 */
	private recursiveSyncContainerComponent = (component: IComponent) => {
		this.syncSizeContainerComponent(component);

		const parentComponent = component.getParentComponent();
		if (parentComponent === null) {
			return;
		}

		this.recursiveSyncContainerComponent(parentComponent);
	};

	/**
	 * Запускает синхронизацию размеров только компонентов, которые объединяют другие компоненты.
	 * @param component
	 */
	private syncSizeContainerComponent = (component: IComponent) => {
		if (!component.isUniter) {
			return;
		}

		(component as IComponentUniter).syncSizeFrames();
	};

	/**
	 * Проверяет DOM на наличие всей необходимой графики и при
	 * отсутствии добавляет её к родительскому элементу рекурсивно.
	 */
	public validateDOMStructure = () => {
		// Инициализация массива с верной последовательностью id графики
		const idPageGraphics: string[][] = this.getIdPageGraphics();

		// Получение компонентов и корневого компонента
		const components = this.getComponents();
		const rootComponent = this.getRootComponent();

		// Получение фактической графики компонентов
		const factualGraphics = components.map(component => component.getGraphics()).flat();
		const rootGraphics = rootComponent.getGraphics();

		// Проверка наличия корневой графики
		if (rootGraphics === null) {
			throw new ManipulatorError('pages not found');
		}

		// Перебор каждого массива id графики страницы
		idPageGraphics.forEach((ids, index) => {
			const rootGraphic = rootGraphics[index];
			// HTML элемент страницы
			const rootFrameElement = rootGraphic.getFrameElement();

			// Перебираем id графики страницы
			ids.forEach(id => {
				// Поиск соответствующей графики по id
				const graphic = factualGraphics.find(graphic => graphic.getID() === id);
				if (graphic) {
					const graphicFrameElement = graphic.getFrameElement();
					// Проверяем есть ли соответствующая графика на странице
					if (!rootFrameElement.contains(graphicFrameElement)) {
						// Добавление графики к родительскому элементу рекурсивно
						this.recursiveAddGraphicToParent(graphic, graphicFrameElement);
					}
				}
			});
		});
	};

	/**
	 * Рекурсивная функция для добавления графики к родительскому элементу.
	 * @param graphic Графика, которую нужно добавить.
	 * @param graphicFrameElement HTML элемент графики.
	 */
	private recursiveAddGraphicToParent = (
		graphic: IGraphic,
		graphicFrameElement: HTMLElement,
	) => {
		// Получаем родительскую графику
		const parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic not found');
		}
		// Получаем HTML родительской графики
		const parentGraphicElement = parentGraphic.getGraphicElement();
		parentGraphicElement.append(graphicFrameElement);

		// Проверяем есть ли у родительской графики родитель, есть ли есть передаем его в рекурсию
		if (parentGraphic.getParentGraphic() !== null) {
			this.recursiveAddGraphicToParent(parentGraphic, parentGraphicElement);
		}
	};

	/**
	 * Возвращает верную последовательность id графики для каждой страницы
	 * на основе структур компонентов в дереве.
	 * @returns Массив с массивами верной последовательности id графики страницы.
	 */
	private getIdPageGraphics = (): string[][] => {
		const idPageGraphics: string[][] = [];

		// Получение структуры
		const structure = this.getStructure();
		const { components, graphics } = structure;

		// Проверка наличия графики и компонентов
		if (graphics === null) {
			throw new ManipulatorError('structure not found');
		}

		if (components === null) {
			throw new ManipulatorError('components not found');
		}

		// Инициализируем массивы для каждого offset страницы
		graphics.forEach(graphicPage => {
			const graphicPageOffset = graphicPage.offset;
			idPageGraphics[graphicPageOffset] = [graphicPage.id];
		});

		// Заполнение массивов id графики для каждой страницы
		graphics.forEach(graphic => {
			components.forEach(component => {
				if (component.offset === graphic.offset) {
					this.recursiveFillIdGraphic(idPageGraphics, component, component.offset);
				}
			});
		});
		return idPageGraphics;
	};

	/**
	 * Рекурсивная функция для заполнения массивов id графики.
	 * @param idPageGraphics Массив массивов id графики страницы.
	 * @param component Компонент структуры.
	 * @param offset Отступ компонента.
	 * @returns Массив массивов id графики страницы.
	 */
	private recursiveFillIdGraphic = (
		idPageGraphics: string[][],
		component: AnyComponentStructure,
		offset: number,
	): string[][] => {
		if (component === null) {
			throw new ManipulatorError('component not found');
		}

		const componentGraphics = component.graphics;

		// Проверка наличия графики у компонента
		if (componentGraphics === null) {
			throw new ManipulatorError('graphic of component not found');
		}

		// Добавление id графики к соответствующему массиву
		componentGraphics.forEach(componentGraphic => {
			idPageGraphics[offset + componentGraphic.offset]
				.push(componentGraphic.id);
		});

		const { components } = component;
		if (components === null) {
			throw new ManipulatorError('component of components not found');
		}

		// Рекурсивный вызов для вложенных компонентов
		if (components.length > 0) {
			components.forEach(component => {
				idPageGraphics = this.recursiveFillIdGraphic(idPageGraphics, component, offset);
			});
		}
		return idPageGraphics;
	};

	abstract getWorkAreaElement(): HTMLElement;
}
export default ComponentTree;
