import React, { useEffect, useState } from 'react';
import css from './preLoader.module.scss';

const PreLoader: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean | null>(false);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsLoading(!isLoading);
			clearTimeout(timeout);
		}, 2000);
	}, [isLoading]);

	useEffect(() => {
		setIsLoading(true);
		return () => {
			setIsLoading(null);
		};
	}, []);

	return (
		<div>
			<div className={css.wrapper}>
				<svg width="114" height="86" viewBox="0 0 114 86" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M74.1553 7.82874C71.3503 5.60939 67.1924 4.16406 
					62.1897 4.16406C60.6255 4.16406 58.9755 4.30791 57.2595 4.60245C53.1544 5.31484 49.7225 7.5479 
					46.1123 10.0824C42.5154 12.5826 38.7732 15.3499 33.9619 16.5075C32.2789 16.9048 30.6092 17.1309 
					28.9856 17.1309C23.4681 17.124 18.4918 15.1924 14.8618 13.3086C14.1952 12.9661 13.5815 12.6168 
					13.0073 12.2812C14.5912 19.9667 20.9139 26.207 27.9098 26.9741C20.0625 28.4469 14.5846 23.7341 
					11.5091 19.4598C9.63472 25.3644 13.3439 32.091 16.459 34.7967C19.561 37.4681 22.6365 40.304 27.9626 
					41.0849C27.9626 41.0849 26.603 47.7498 8.67773 54.2846C10.4861 57.3259 17.383 59.6138 21.0723 
					59.6138C22.6959 59.6206 23.7849 59.9974 28.649 58.8192L28.8668 72.6559L44.5481 64.1004C46.6271 
					71.9915 49.6631 76.211 51.0292 78.2454L57.8865 64.5525L55.7086 55.4148C55.2994 53.6407 55.6492 
					51.7912 56.6655 50.3322C57.7017 48.8526 59.2923 47.9484 61.0941 47.7977H61.1139H61.1337L62.8364 
					47.6471C63.9386 34.3309 66.869 26.4878 70.4395 21.8025C72.9871 18.4255 76.155 17.6515 78.6762 
					17.0007C78.6234 13.6785 76.9206 10.0413 74.1553 7.82874Z"
						fill="#FFAE00"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M91.032 26.3219C88.2534 22.4928 84.7819 20.7598 
					81.1255 20.7735C79.172 20.8215 76.268 21.1092 73.3575 24.8355C70.6185 28.3837 66.8236 35.2062 
					65.7676 47.9196C78.9608 51.9131 80.3006 63.8455 80.4788 64.6538C82.3861 63.4003 85.1713 61.3042 
					87.7057 58.6807C91.9428 54.2968 95.7509 48.036 95.7311 41.0492C95.7377 35.5693 93.7974 30.1304 
					91.032 26.3219Z"
						fill="#353535"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M102.016 48.6471C100.571 46.2223 97.8981 47.2634 
					95.8917 48.1882C93.8853 49.1129 90.328 58.2369 85.9853 63.436C81.6425 68.635 80.6393 67.9432 
					78.5274 59.97C76.4154 51.9967 67.8422 51.3049 63.3872 50.3801C58.9389 49.4554 58.8267 54.1887 
					59.3877 55.6888C59.9421 57.1889 58.9455 58.3465 59.7243 65.0457C60.5031 71.7449 65.288 79.3688 
					66.9578 79.8277C68.6275 80.2867 98.1225 76.5946 101.799 75.9028C105.475 75.2109 110.372 73.7108 
					110.59 70.3612C110.807 67.0116 103.462 51.072 102.016 48.6471Z"
						fill="white"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M112.753 68.3755L105.064 49.0109C104.555 47.7299 
					103.638 46.6065 102.483 45.8325C101.658 45.2845 100.734 44.9352 99.8036 44.7982C99.8036 44.7982 
					102.364 35.1193 95.0714 24.091C92.1939 19.7482 87.0064 16.7411 82.1423 16.2411C82.0961 10.9256 
					80.0567 7.38416 76.4597 4.56202C72.7374 1.64397 67.6753 0 61.9202 0C60.1118 0 58.2374 0.164397 
					56.3103 0.500041C51.136 1.41107 47.143 4.12362 43.5461 6.63753C39.9359 9.17883 36.6888 11.4941 
					33.0985 12.3366C31.7983 12.6449 30.4981 12.7819 29.2045 12.7819C25.0928 12.7887 21.0735 11.3091 
					17.9319 9.82272C11.7082 6.89097 9.10785 3.32904 9.10785 3.32904C7.82087 5.85664 7.80767 10.2611 
					8.44786 14.3847C7.31928 17.7138 6.4019 20.9264 6.4019 23.7348C6.3821 30.5025 11.9854 39.4895 
					21.41 42.8186C10.8502 49.689 0 51.5933 0 51.5933C7.62288 66.3547 23.2184 64.4916 24.2744 
					64.3751L23.5616 82.082L42.0743 70.0057C44.6087 78.6092 51.8884 86.4865 51.8884 86.4865L58.838 
					70.3893L60.3032 76.5542C60.6728 78.1708 61.6034 79.6024 62.9168 80.5819C64.2697 81.5957 65.9065 
					82.0409 67.5235 81.8354L108.225 76.8213H108.251L108.278 76.8145C110.053 76.5748 111.578 75.5267 
					112.462 73.9444C113.393 72.2799 113.492 70.2523 112.753 68.3755ZM98.6552 49.4766C98.774 49.4698 
					98.84 49.4629 98.84 49.4629C99.236 49.4287 99.665 49.5588 100.028 49.8054C100.398 50.052 100.701 
					50.4014 100.866 50.8124L105.928 63.5668C102.543 62.1147 97.9424 60.135 93.7647 58.3404C94.814 
					56.9019 95.8436 55.3127 96.8204 53.5592C97.5398 52.2782 98.1536 50.9151 98.6552 49.4766ZM81.1193 
					21.0839C84.7228 21.0702 88.1482 22.7758 90.8871 26.557C93.6129 30.3107 95.5268 35.6741 95.5202 
					41.0718C95.5334 47.9628 91.7847 54.1346 87.607 58.4568C85.1122 61.0461 82.5052 62.9709 80.6243 
					64.2039C80.4461 63.4093 78.9875 51.7919 65.9791 47.8532C67.0153 35.3248 70.764 28.5914 73.4634 
					25.0979C76.3278 21.4127 79.1921 21.1319 81.1193 21.0839ZM55.3863 55.21L57.5181 64.2107L50.905 
					77.4789C49.5652 75.4788 46.8592 71.5401 44.8133 63.7655L29.0725 72.1223L28.8613 58.5664C24.0698 
					59.7309 22.994 59.361 21.3969 59.3542C17.7669 59.3542 10.969 57.4773 9.18705 54.4771C26.855 
					48.0382 28.7029 40.791 28.7029 40.791C23.456 40.017 19.9185 37.5236 16.8627 34.8933C13.7938 32.2287 
					10.0648 26.8036 11.9128 20.988C14.9422 25.2075 22.0832 30.3723 29.9041 26.8926C23.0072 26.1323 
					15.0346 20.2893 13.4704 12.7134C14.0314 13.0422 14.6386 13.3847 15.2985 13.7203C18.8757 15.5766 
					23.7728 17.4809 29.2111 17.4877C30.8149 17.4877 32.4583 17.2685 34.1148 16.8712C38.8536 15.7342 
					42.5363 13.0079 46.087 10.542C49.6444 8.0486 53.0301 5.84294 57.0693 5.14426C58.7588 4.84971 
					60.3824 4.71271 61.9268 4.71271C66.8569 4.71271 70.9488 6.13064 73.7142 8.3226C76.4399 10.5009 
					77.9051 13.2888 77.9645 16.5698C75.483 17.2069 72.5724 18.7618 70.0579 22.0908C66.5401 26.7077 
					63.5042 34.4343 62.4218 47.5587L60.7454 47.7025H60.7256H60.7058C58.9304 47.8532 57.3597 48.7437 
					56.3433 50.2027C55.3269 51.6344 54.9837 53.4633 55.3863 55.21ZM60.0194 54.114C59.9204 53.6825 
					60.0986 53.2578 60.224 52.8947C60.4088 52.3604 61.0952 51.8809 61.841 51.8261C65.3653 51.0453 
					68.6983 52.9427 70.2294 54.1757C71.754 55.4086 73.2258 57.354 74.1432 59.2309C74.3412 59.6282 
					74.5194 60.0254 74.6976 60.4227L63.6494 71.0675L60.0194 54.114ZM108.542 71.5743C108.377 71.8757 
					108.086 72.0812 107.697 72.136L67.0681 76.9926C66.5665 77.0611 65.2069 77.4241 64.9891 
					75.5747L76.011 63.9573C76.6907 66.5465 76.6973 69.7797 76.3806 72.3758C76.3806 72.3758 83.9176 
					69.2933 91.4613 61.1283C98.1734 64.0121 105.156 67.1699 107.789 68.3001L108.555 70.177C108.766 
					70.7044 108.746 71.2113 108.542 71.5743ZM60.1514 28.1872C60.1514 23.817 58.1648 20.9195 55.8813 
					21.0565C53.5779 21.1935 51.4528 24.3787 51.07 28.4749C50.6608 32.7904 52.3569 36.9071 55.1223 
					36.9071C57.9141 36.9071 60.1514 32.6671 60.1514 28.1872Z"
						fill="#0E0E0E"
					/>
				</svg>
				<div className={css.textContainer}>
					<div className={css.lower}>wakadoo</div>
					<div className={`${css.higher} ${isLoading ? '' : css.animate}`}>wakadoo</div>
				</div>
			</div>
		</div>
	);
};

export default PreLoader;
