import React from 'react';
import css from './list.module.scss';
import { checkUsersRightsInterface } from '../../../../../../helpers/usersRights';
import { ITeamItem } from '../../../../../../entities/team/api/types';
import SkeletonTeams from '../../../../../../widgets/entity/skeleton/skeleton';
import { EmptyList } from '../../../../../ui/empty-list/empty-list';
import { useAppDispatch } from '../../../../../../shared/libs/redux';
import { setIsCreateTeamModalActive } from '../../../../../../redux/reducers/teamReducer';
import { TeamCard } from './card';

interface TeamsListProps {
	isLoading: boolean,
	teams: ITeamItem[] | undefined,
	statusRights: checkUsersRightsInterface,
}

export const TeamsList = ({
	teams,
	isLoading,
	statusRights,
}: TeamsListProps) => {
	const dispatch = useAppDispatch();

	const handleCreateModal = () => dispatch(setIsCreateTeamModalActive(true));

	if (isLoading) {
		return (
			<div className={css.skeleton_wrapper}>
				<SkeletonTeams count={7} />
			</div>
		);
	}
	if (!teams) {
		return (
			<div className={css.empty_wrapper}>
				<EmptyList
					needBtn
					btnText="Создать команду"
					title="Создайте первую команду"
					onBtnClick={handleCreateModal}
				/>
			</div>
		);
	}

	return (
		<div className={css.wrapper}>
			{
				teams.map(team => (
					<TeamCard
						key={team.id}
						team={team}
						statusRights={statusRights}
					/>
				))
			}
		</div>
	);
};
