import React from 'react';
import { AdminTabs } from './tabs/tabs';
import { AdminTabBlock } from './tab-block/tab-block';
import { MainLayout } from '../../../layouts/main/ui/main';
import css from './admin.module.scss';
import { ACCOUNT_STATUS } from '../../../../../entities/user/account/api/types';
import { StatusAccess } from '../../../../../app/providers/status-access';

export const Admin = () => (
	<StatusAccess accessStatus={[ACCOUNT_STATUS.SERVICE_ADMIN]}>
		<MainLayout>
			<div className={css.wrapper}>
				<div className={css.left_side_bar}>
					<AdminTabs />
				</div>
				<AdminTabBlock />
			</div>
		</MainLayout>
	</StatusAccess>
);
