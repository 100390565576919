import React from 'react';
import ComponentSketchStructureVisualizer
	from '../../../../../SketchManipulators/SketchStructureVisualizer/ComponentSketchStructureVisualizer';
import Button, { buttonsTypes } from '../../../../../ui/button/button';
import css from './preview.module.scss';
import { ReactComponent as SettingsBtnGoBackIcon } from '../images/settings-btn-go-back.svg';
import { SendOfferButton } from '../../../../../../features/offer/send';
import { useAppSelector } from '../../../../../../shared/libs/redux';

interface PreviewProps {
	// newStructure: ISketchStructure,
	// onSendKP: () => void,
	onGoBack: () => void,
}

const Preview: React.FC<PreviewProps> = ({
	// newStructure,
	onGoBack,
	// onSendKP,
}: PreviewProps) => {
	const {
		name,
		sketch_structure: structure,
	} = useAppSelector(state => state.sketch);

	return (
		<div className={css.wrapper}>
			<div className={css.btnWrapper}>
				<Button
					onClick={onGoBack}
					text="Вернуться назад"
					buttonType={buttonsTypes.LIGHT}
					cssClass={css.btn}
				>
					<SettingsBtnGoBackIcon />
				</Button>
				{/* <Button */}
				{/* 	onClick={onSendKP} */}
				{/* 	text="Отправить КП" */}
				{/* 	buttonType={buttonsTypes.BRIGHT} */}
				{/* /> */}
				<SendOfferButton structure={structure} name={name} />
			</div>
			{
				structure && <ComponentSketchStructureVisualizer structure={structure} />
			}
		</div>
	);
};

export default Preview;
