import SpatialArea from '../SpatialArea';
import TableGraphic from '../../../../../graphic/table/TableGraphic';
import IFrameArea from '../IFrameArea';
import SpatialAreaType from '../SpatialAreaType';
import ManipulatorError from '../../../../../utils/manipulator-error/ManipulatorError';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import TableComponent from '../../../../../components/table/TableComponent';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import TableColumnMutator from '../../../../../graphic/table/mutators/TableColumnMutator';

export interface ISpatialTableColumnResizeAreaData extends ISpatialAreaBaseData<TableGraphic> {
	multiplierIndex: number,
	component: TableComponent,
}

/** Область для изменения ширины колонки внутри таблицы. */
class SpatialTableColumnResizeArea extends SpatialArea<ISpatialTableColumnResizeAreaData> {
	public readonly type: SpatialAreaType = SpatialAreaType.TABLE_COLUMN_RESIZE;

	private readonly COLUMN_WIDTH = 10;

	constructor(
		private readonly component: TableComponent,
		private readonly graphic: TableGraphic,
		private readonly multiplierIndex: number,
	) {
		super();
	}

	public getGlobalFrameArea = (): IFrameArea => {
		const columnMultipliers = this.graphic.getColumnMultipliers();
		const graphicPosition = this.graphic.getGlobalPosition();
		const graphicConfiguration = this.graphic.getFrameConfiguration();
		const defaultWidth = graphicConfiguration.width / columnMultipliers.length;

		let { x } = graphicPosition;
		for (let i = 0; i < this.multiplierIndex + 1; i++) {
			const multiplier = columnMultipliers[i];
			if (multiplier === undefined) {
				throw new ManipulatorError('multiplier not found');
			}
			x += defaultWidth * multiplier;
		}

		const targetMultiplier = columnMultipliers[this.multiplierIndex];
		if (targetMultiplier === undefined) {
			throw new ManipulatorError('multiplier not found');
		}

		return {
			x: x - (this.COLUMN_WIDTH / 2),
			y: graphicPosition.y,
			width: this.COLUMN_WIDTH,
			height: graphicConfiguration.height,
			rotate: 0,
		};
	};

	public getData = (): ISpatialTableColumnResizeAreaData => ({
		graphic: this.graphic,
		component: this.component,
		multiplierIndex: this.multiplierIndex,
	});

	public isResizeType = (): boolean => false;

	public getRelatedFrames = (): IAreaSizeMutable[] => {
		const columnMutator = new TableColumnMutator(this);
		return [columnMutator];
	};

	public isAllowMutatePosition = (): boolean => true;
}

export default SpatialTableColumnResizeArea;
