import { ITabData } from '../../../../../widgets/tabs/page-tabs/model/types';
import { LINK_WORKSHOP_MODULES, LINK_WORKSHOP_TEMPLATES } from './consts';

export const workshopTabs: ITabData[] = [
	{
		name: 'Шаблоны',
		link: LINK_WORKSHOP_TEMPLATES,
	},
	{
		name: 'Модули',
		link: LINK_WORKSHOP_MODULES,
		// disabled: true,
	},
];
