import { useAppDispatch, useAppSelector } from "../../shared/libs/redux";
import { useEffect } from "react";
import {
	addWorkshopTemplateSelectedList,
	removeWorkshopTemplateSelectedList,
	setIsWorkshopSelectionAreaActive,
	setWorkshopTemplateSelectedList
} from '../../entities/templates/workshop/template/model/slice';

export const useHandleWorkshopSelectedList = () => {
	const dispatch = useAppDispatch();

	const selectedList = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);

	const removeElem = (id: string) => {
		dispatch(removeWorkshopTemplateSelectedList(id));
	};
	const addElem = (id: string) => {
		dispatch(addWorkshopTemplateSelectedList(id));
	};
	const setList = (ids: string[]) => {
		dispatch(setWorkshopTemplateSelectedList(ids));
	};
	useEffect(() => {
		if (selectedList.length) {
			dispatch(setIsWorkshopSelectionAreaActive(true));
		} else {
			dispatch(setIsWorkshopSelectionAreaActive(false));
		}
	}, [selectedList]);

	return {
		setList,
		selectedList,
		removeElem,
		addElem,
	};
};
