import {
	Navigate, Route, Routes, useLocation, 
} from 'react-router-dom';
import React from 'react';
import { ContentLayout } from '../../../../layouts/content';
import { LINK_ADMIN_MODULES, LINK_ADMIN_TEMPLATES, LINK_ADMIN_USERS } from '../../model/tabs-config';
import { AdminUsers } from '../../../users';
import { AdminUser } from '../../../user';

export const AdminTabBlock = () => {
	const { pathname } = useLocation();

	return (
		<ContentLayout>
			{
				pathname.includes('/templates*')
					? <Navigate to={LINK_ADMIN_TEMPLATES} />
					: null
			}
			{
				pathname.includes('/modules*')
					? <Navigate to={LINK_ADMIN_MODULES} />
					: null
			}
			{
				pathname.includes('/users/*')
					? <Navigate to={LINK_ADMIN_USERS} />
					: null
			}
			<Routes>
				<Route path={LINK_ADMIN_TEMPLATES} element={<div />} />
				<Route path={LINK_ADMIN_MODULES} element={<div />} />
				<Route path={LINK_ADMIN_USERS} element={<AdminUsers />} />
				<Route path={`${LINK_ADMIN_USERS}:id`} element={<AdminUser />} />
			</Routes>
		</ContentLayout>
	);
};
