import HTMLGenerator from '../../../utils/HTMLGenerator';
import Utils from '../../../utils/impl/Utils';
import IFrameArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import TableCellContext from './context/TableCellContext';

/**
 * Ячейка таблицы.
 */
abstract class TableCell {
	private readonly ELEMENT_CLASS_NAME = 'graphic-table_cell';
	private readonly ELEMENT_FOCUS_CLASS_NAME = 'focus';

	private readonly id: string;
	private readonly element: HTMLElement;

	private row: number;
	private column: number;
	private rowSpan: number;
	private startRow: number;
	private columnSpan: number;

	abstract readonly isContextExtend: boolean;

	protected constructor(
		private readonly context: TableCellContext,
	) {
		this.id = Utils.Generate.UUID4();
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
		});
		this.row = 1;
		this.column = 1;
		this.rowSpan = 1;
		this.columnSpan = 1;
		this.setGridId(this.id);
	}

	public getID = (): string => this.id;

	public setBackground = (color: string): this => {
		this.element.style.background = color;
		return this;
	};

	public setRowSpan = (span: number): this => {
		this.rowSpan = span;
		this.syncGridPosition();
		return this;
	};

	public setColumnSpan = (span: number): this => {
		this.columnSpan = span;
		this.syncGridPosition();
		return this;
	};

	public setColumn = (column: number): this => {
		this.column = column;
		this.syncGridPosition();
		return this;
	};

	public setRow = (row: number): this => {
		this.row = row;
		this.syncGridPosition();
		return this;
	};

	public getRow = () => this.row;
	public getColumn = () => this.column;
	public getRowSpan = () => this.rowSpan;
	public getColumnSpan = () => this.columnSpan;
	public getContext = (): TableCellContext => this.context;

	/**
	 * Устанавливает значения строки, с которой начинается перенос в родительской графике
	 * @param row - номер строки.
	 */
	public setStartRow = (row: number) => {
		this.startRow = row;
		this.syncGridPosition();
	};

	public getHeight = (): number => {
		const { height } = this.element.getBoundingClientRect();
		return height;
	};

	public enableFocus = () => {
		this.element.classList.add(this.ELEMENT_FOCUS_CLASS_NAME);
	};

	public disableFocus = () => {
		this.element.classList.remove(this.ELEMENT_FOCUS_CLASS_NAME);
	};

	public getFrameArea = (): IFrameArea => {
		const {
			height, width, x, y,
		} = this.element.getBoundingClientRect();
		return {
			x: x + window.scrollX,
			y: y + window.scrollY,
			height,
			width,
			rotate: 0,
		};
	};

	public getElement = (): HTMLElement => this.element;

	/**
	 * Устанавливает CSS-свойство grid-area (имя для последующего построения макета) для HTML-элемента ячейки.
	 */
	private setGridId = (id: string) => {
		this.element.style.gridArea = id;
	};

	private syncGridPosition = () => {
		this.element.style.gridRowStart = (this.row - this.startRow + 1).toString();
		this.element.style.gridColumnStart = (this.column + 1).toString();

		this.element.style.gridRowEnd = (this.row - this.startRow + this.rowSpan + 1).toString();
		this.element.style.gridColumnEnd = (this.column + this.columnSpan + 1).toString();
	};
}

export default TableCell;
