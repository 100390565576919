enum GraphicType {
	GROUP = 'GRAPHIC_GROUP',
	PICTURE = 'GRAPHIC_PICTURE',
	TABLE = 'GRAPHIC_TABLE',
	TEXT = 'GRAPHIC_TEXT',
	FIGURE = 'GRAPHIC_FIGURE',
	PAGE = 'GRAPHIC_PAGE',
	MODULE = 'GRAPHIC_MODULE',
	LINE = 'GRAPHIC_LINE',
}

export default GraphicType;
