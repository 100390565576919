import { useAppDispatch } from '../../../../../shared/libs/redux';
import { tagAPI } from '../../../../../entities/modules/tag/api/api';
import { moduleAPI } from '../../../../../entities/modules/module/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useUnbindModuleTag = (moduleId: string) => {
	const dispatch = useAppDispatch();

	const [unbind, { isLoading }] = tagAPI.useUnbindMutation();

	const onUnbindTag = async (tag: number) => {
		try {
			await unbind({
				id: moduleId,
				tags: [tag],
			})
				.unwrap();

			dispatch(moduleAPI.util?.invalidateTags(['modules']));
			dispatch(tagAPI.util?.invalidateTags(['tag']));
			notificationSuccess('Тег успешно отвязан от модуля!');
		} catch (err) {
			notificationError(
				'Что-то пошло не так...',
				'Мы уже устраняем эту проблему!',
			);
		}
	};

	return {
		onUnbindTag,
		isLoading,
	};
};
