import React from 'react';
import css from './roles.module.scss';

const skeletonRoles = (count = 4) => {
	const roles = [];

	for (let i = 0; i < count; i++) {
		roles.push({
			jsx: <div className={`${css.role} ${css.skeleton}`} />,
			id: i,
		});
	}
	return roles;
};

interface SkeletonRolesProps {
	count: number;
}

const SkeletonRoles: React.FC<SkeletonRolesProps> = ({ count }: SkeletonRolesProps) => (
	<div className={css.skeleton_loading_card}>
		{
			skeletonRoles(count).map(item => <div key={item.id}>{item.jsx}</div>)
		}
	</div>
);

export default SkeletonRoles;
