import React from 'react';
import Logo from '../logo/Logo';
import css from '../registration.module.scss';
import PinInputBlockContainer from './pinInputBlock/PinInputBlockContainer';
import { MainButton } from '../../../ui/main-button/main-button';

interface CodeRegistrationProps {
	email: string,
	digits: Array<string>,
	isCodeValid: boolean,
	sendCode: () => void,
	isDataLoading: boolean,
	changeHandler: (value: Array<string>) => void,
	handleKeyPressSendCode: (e: React.KeyboardEvent<HTMLInputElement>) => void,
}

const CodeRegistration: React.FC<CodeRegistrationProps> = ({
	email,
	digits,
	changeHandler,
	isDataLoading,
	sendCode,
	isCodeValid,
	handleKeyPressSendCode,
}: CodeRegistrationProps) => (
	<div className={css.wrapper}>
		<Logo isPasswordSent={false} />
		<h2 className={css.title}>
			Введите 6-значный
			<br />
			{' '}
			код из письма
		</h2>
		<div className={css.descr}>
			На почту
			<span className={css.black}> 
				{' '}
				{email}
			</span>
			{' '}
			выслан 6-значный
			<br />
			{' '}
			код для подтверждения адреса
		</div>
		<div className={css.block}>
			<PinInputBlockContainer
				digits={digits}
				changeHandler={changeHandler}
				handleKeyPressSendCode={handleKeyPressSendCode}
			/>
			<MainButton
				type="button"
				styles={css.button}
				btnStyle="bright"
				onClick={sendCode}
				disabled={isDataLoading || !isCodeValid}
			>
				Далее
			</MainButton>
		</div>
	</div>
);

export default CodeRegistration;
