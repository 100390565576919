export const domainName: string = `${window.location.protocol}${window.location.host}/`
export const base64ImagePrefix: string = "data:image/"
export const formDataFilesKey: string = "files"

interface RealPixelsSizeElement {
    width: number
    height: number
    topOffset: number
}

export const setCursorToEnd = (element: HTMLElement) => {
    if (!element) {
        console.error("selectElementTextContent: element is undefined")
        return
    }

    const range = document.createRange()
    range.selectNodeContents(element)
    range.collapse(false)
    const sel = window.getSelection()
    if (sel) {
        sel.removeAllRanges()
        sel.addRange(range)
    }
}
export const copyBuffer = (str: string) => {
    let input = document.createElement("input")
    input.style.cssText = `
            position: absolute;
            left: -5000px;
        `
    window.document.body.append(input)
    input.value = str
    input.select()

    try {
        document.execCommand('cut')
    } catch(err) {
        console.error('Oops, unable to cut')
    }
    input.remove()
}
export const uploadImageToBase64 = (callback: (base64: string) => void) => {
    let input = document.createElement("input")
    input.type = "file"
    input.accept = "image/*"
    input.className = "invisible-input"
    document.body.append(input)
    input.onchange = () => {
        if (!input.files) return
        let file = input.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            if (!reader.result) return
            callback.call(this, reader.result.toString())

        }
        reader.readAsDataURL(file)
    }
    input.click()
    input.remove()
}

export const getRealPixelsSizeElement = (elem: HTMLElement): RealPixelsSizeElement => {
    return {
        height: elem.offsetHeight,
        width: elem.offsetWidth,
        topOffset: elem.offsetTop
    }
}

export const isValueContainsOnlyNumber = (value: string) => {
    const result = value.match(/\D/)
    return !result
}

export const getOnlyNumberStringFor = (value: string) => {
    return value.replace(/[^+\d]/g, '')
}

export const isBase64String = (value: string): boolean => {
    return value.includes(base64ImagePrefix)
}

export const selectElementContent = (elem: HTMLElement) => {
    let range = document.createRange()
    range.selectNodeContents(elem)
    let sel = window.getSelection()
    if (!sel) return
    sel.removeAllRanges()
    sel.addRange(range)
}

export const isElementNotWasClicked = (ev: MouseEvent, element: HTMLElement) => {
    const id = "u87887se8trh78e"
    element.setAttribute("id", id)
    const result = !(ev.target as HTMLElement).closest(`#${id}`)
    element.removeAttribute("id")
    return result
}

export const getBase64FromFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = error => reject(error)
    })
}

// DEPRECATED
export const base64ImageWriteFormData = (formData: FormData, base64: string): string => {
    const extension = base64.substring("data:image/".length, base64.indexOf(";base64"))
    // DEPRECATED const fileName = keccak256().toString("hex")
    const fileName = ""
    const fileFullName = `${fileName}.${extension}`
    const imageBlob = dataURIToBlob(base64)

    formData.append(formDataFilesKey, imageBlob, fileFullName);

    return fileFullName
}

const dataURIToBlob = (uri: string): Blob => {
    const splitDataURI = uri.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}
