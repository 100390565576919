import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './createRoleInput.module.scss';
import { popUpTransitionClasses } from '../../../../../../styles/transitionStyles/transitionStyles';

interface ToolTipBlockProps {
	isRoleToolTipActive: boolean,
}

const ToolTipBlock: React.FC<ToolTipBlockProps> = ({
	isRoleToolTipActive,
}: ToolTipBlockProps) => {
	const choiceRef = useRef(null);

	return (
		<CSSTransition
			nodeRef={choiceRef}
			in={isRoleToolTipActive}
			timeout={300}
			classNames={popUpTransitionClasses}
			unmountOnExit
		>
			<div
				className={`${css.tooltip} ${css.roleTooltip}`}
				ref={choiceRef}
			>
				Выберите роль
			</div>
		</CSSTransition>
	);
};

export default ToolTipBlock;
