import React from 'react';
import css from './tag.module.scss';
import { ITemplateTag } from '../../../../../entities/templates/tags/api/types';
import { ITagData } from '../../../../../entities/modules/tag/api/types';

interface TemplateTagProps {
	tag: ITemplateTag | ITagData;
}

export const TemplateTag = ({ tag }: TemplateTagProps) => (
	<div className={css.wrapper}>{tag.name}</div>
);
