import { useState } from 'react';
import {
	useMoveGalleryTemplate,
} from '../../../../../../../features/templates/gallery/move/api/useMoveGalleryTemplate';

export const useGalleryContextMenuActions = (id: string) => {
	const [isActionMenuOpened, setIsActionMenuOpened] = useState(false);

	const { moveTemplate } = useMoveGalleryTemplate();

	const onMoveTemplate = async () => {
		const data = await moveTemplate(id);
		setIsActionMenuOpened(false);

		return data;
	};

	return {
		isActionMenuOpened,
		onMoveTemplate,
		setIsActionMenuOpened,
	};
};
