class ObjectUtils {
	/**
	 * Сравнивает объекты по значению, возвращает true в случае совпадения, в ином false.
	 */
	public deepEqual = (obj1: object, obj2: object): boolean => {
		if (obj1 === obj2) {
			return true;
		}

		if (obj1 === null || obj2 === null) {
			return false;
		}

		if (typeof obj1 === 'undefined' || typeof obj2 === 'undefined') {
			return false;
		}

		if (obj1 instanceof Array && obj2 instanceof Array) {
			if (obj1.length !== obj2.length) {
				return false;
			}
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const key in obj1) {
				if (!this.deepEqual(obj1[key], obj2[key])) {
					return false;
				}
			}
			return true;
		}

		if (
			typeof obj1 === 'object'
			&& typeof obj2 === 'object'
			&& !(obj1 instanceof Array)
			&& !(obj2 instanceof Array)
		) {
			if (Object.keys(obj1).length !== Object.keys(obj2).length) {
				return false;
			}
			// eslint-disable-next-line guard-for-in,no-restricted-syntax
			for (const key in obj1) {
				if (typeof obj1[key as keyof object] !== 'object' && typeof obj2[key as keyof object] !== 'object') {
					if (obj1[key as keyof object] !== obj2[key as keyof object]) {
						return false;
					}
				}

				if (!this.deepEqual(obj1[key as keyof object], obj2[key as keyof object])) {
					return false;
				}
			}
			return true;
		}

		return false;
	};

	/**
	 * Возвращает результат совпадений значений полей объектов. Если все значения одного поля одинаковы,
	 * то в значении этого поля с этим именем будет `true`.
	 * @param objects - объекты для сравнения.
	 */
	public checkFieldsEquality = <T extends object>(...objects: T[]): Record<keyof T, T[keyof T] | null> => {
		let i = 0;
		let firstObject = null;

		while (i < objects.length) {
			if (objects[i] !== null) {
				firstObject = objects[i];
				break;
			}
			i++;
		}

		if (!firstObject) {
			throw new Error('Array is empty');
		}

		const fieldsEquality: Partial<Record<keyof T, T[keyof T] | null>> = {};

		// eslint-disable-next-line no-restricted-syntax
		for (const field in firstObject) {
			if (Object.prototype.hasOwnProperty.call(firstObject, field)) {
				const fieldValue = firstObject[field];
				let areAllEqual = true;

				for (let i = 1; i < objects.length; i++) {
					// if (objects[i][field] !== fieldValue) {
					if (!this.deepEqual(objects[i][field] as any, fieldValue as any)) {
						areAllEqual = false;
						break;
					}
				}

				fieldsEquality[field] = areAllEqual ? fieldValue : null;
			}
		}

		return fieldsEquality as Record<keyof T, T[keyof T] | null>;
	};

	/**
	 * Возвращает отсортированный массив объектов на основании переданных функций.
	 * @param objects Сортируемые объекты.
	 * @param lambdaFunctions Функция сортировки.
	 */
	public sortByLambdaFunctions = <T>(objects: T[], ...lambdaFunctions: ((obj: T) => boolean)[]): T[] => objects
		.sort((a, b) => {
			for (let i = 0; i < lambdaFunctions.length; i++) {
				const lambda = lambdaFunctions[i];
				const resultA = lambda(a);
				const resultB = lambda(b);

				if (resultA && !resultB) {
					return -1;
				} if (!resultA && resultB) {
					return 1;
				}
			}

			return 0;
		});
}

export default ObjectUtils;
