import React, { useLayoutEffect, useRef, useState, } from "react";
import { useOutSideClick } from "./useNewOutSideClick";

interface UseContextMenuProps {
	setMenuVisible: (value: boolean) => void,
	exception_id?: string,
}

const useContextMenu = ({
	setMenuVisible,
	exception_id
}: UseContextMenuProps) => {
	const [menuPosition, setMenuPosition] = useState({
		x: 0,
		y: 0
	});
	const [absolutePosition, setAbsolutePosition] = useState({
		x: 0,
		y: 0
	});

	const onCloseMenu = () => {
		if (document.body.style.overflowY === "hidden") return;
		setMenuVisible(false);
	};

	const menuRef = useOutSideClick(onCloseMenu, false, exception_id);
	const wrapperRef = useRef<HTMLDivElement>(null);

	// * Если элемент выходит за границы экрана
	useLayoutEffect(() => {
		const menuEl = menuRef.current;
		if (!menuEl) return;

		const {
			innerWidth,
			innerHeight
		} = window;
		const {
			x,
			y
		} = absolutePosition;

		const xOverflow = x + menuEl.offsetWidth + 20 > innerWidth;
		const yOverflow = y + menuEl.offsetHeight + 20 > innerHeight;

		setMenuPosition(menuPosition => ({
			x: xOverflow ? menuPosition.x - menuEl.offsetWidth : menuPosition.x,
			y: yOverflow ? menuPosition.y - menuEl.offsetHeight : menuPosition.y,
		}));
	}, [absolutePosition, menuRef]);

	const handleItemClick = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		const { currentTarget } = e;
		const {
			x,
			y
		} = currentTarget.getBoundingClientRect();

		setAbsolutePosition({
			x: e.clientX,
			y: e.clientY,
		});

		setMenuPosition(
			{
				x: e.clientX - x,
				y: e.clientY - y,
			},
		);
		setMenuVisible(true);
	};

	return {
		menuRef,
		menuPosition,
		handleItemClick,
		wrapperRef,
	};
};

export default useContextMenu;
