import { workshopCategoryApi } from '../../../entities/workshop-category';

export const useCreateWorkshopModuleCategory = () => {
	const [createCategory] = workshopCategoryApi.useCreateMutation();

	const onCreateCategory = async (name: string) => {
		const res = await createCategory({ name }).unwrap();

		return res.category;
	};

	return { onCreateCategory };
};
