import { useEffect, useState } from 'react';

export interface validation {
    isEmpty: boolean,
    minLength: number,
    maxLength: number,
    isEmail?: boolean,
    isPhone?: boolean,
    isCodeRight?: boolean,
    isDate?: boolean
}

export const useValidation = (value: string, validations?: validation) => {
	const [isEmpty, setEmpty] = useState(true);
	const [minLengthError, setMinLengthError] = useState(false);
	const [maxLengthError, setMaxLengthError] = useState(false);
	const [emailError, setEmailError] = useState(false);
	const [phoneError, setPhoneError] = useState(false);
	const [isCodeRight, setCodeRight] = useState(false);
	const [inputValid, setInputValid] = useState(false);

	useEffect(() => {
		for (const validation in validations) {
			switch (validation) {
			case 'minLength':
				value.length < validations.minLength ? setMinLengthError(true) : setMinLengthError(false);
				break;

			case 'maxLength':
				value.length > validations.maxLength ? setMaxLengthError(true) : setMaxLengthError(false);
				break;

			case 'isEmpty':
				value.trim() === '' ? setEmpty(true) : setEmpty(false);
				break;

			case 'isEmail':
				const mail = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/;

				mail.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true);
				break;

			case 'isPhone':
				const reg = /[\d+][\d ()\-]{14,}\d/g;
				reg.test(String(value).toLowerCase()) ? setPhoneError(false) : setPhoneError(true);
				break;

			case 'isCodeRight':
				const number = /^\d+$/;
				number.test(String(value).toLowerCase()) ? setCodeRight(true) : setCodeRight(false);
				break;
			default:
				break;
			}
		}
	}, [value]);

	useEffect(() => {
		if (isEmpty || minLengthError || emailError || phoneError || maxLengthError) {
			setInputValid(false);
		} else {
			setInputValid(true);
		}
	}, [isEmpty, minLengthError, emailError, phoneError, maxLengthError, isCodeRight, value]);

	return {
		isEmpty,
		minLengthError,
		maxLengthError,
		emailError,
		phoneError,
		isCodeRight,
		inputValid,
	};
};
