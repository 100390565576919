import React from 'react';
import ReactDOM from 'react-dom';
import UserList from './user-list';
import UserListModalWrapper from './UserListModalWrapper';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import useGetUsers from '../../../../../hooks/users/useGetUsers';
import { useAppSelector } from '../../../../../shared/libs/redux';
import { CreateUserModalWrapper } from '../modals/create-user';
import { UserCard } from '../user-card/user-card';

interface UsersListContainerProps {
	statusRights: checkUsersRightsInterface;
}

const portal = document.getElementById('portal');

const UsersListContainer = ({ statusRights }: UsersListContainerProps) => {
	const users = useAppSelector(state => state.user.users);

	const { isLoading } = useGetUsers();

	return (
		<>
			{
				ReactDOM.createPortal(
					<UserListModalWrapper statusRights={statusRights} />,
					portal as Element,
				)
			}
			<UserList
				statusRights={statusRights}
				isDataLoading={isLoading}
			>
				{
					users?.map(user => (
						<UserCard key={user.email} statusRights={statusRights} user={user} />
					))
				}
			</UserList>
			<CreateUserModalWrapper statusRights={statusRights} />
		</>
	);
};

export default UsersListContainer;
