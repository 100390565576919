import React from 'react';
import classNames from 'classnames';
import { ReactComponent as EditIcon } from '../../images/edit-icon.svg';
import css from './edit-title.module.scss';

interface TitleWithEditBtnProps {
	title: string;
	onChange: () => void,
}

export const TitleWithEditBtn = ({
	onChange,
	title,
}: TitleWithEditBtnProps) => (
	<div className={classNames(css.title_block)}>
		<h3 className={css.title}>{title}</h3>
		<div className={css.icon} onClick={onChange} role="presentation">
			<EditIcon />
		</div>
	</div>
);
