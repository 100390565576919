import Dependent from '../dependent/Dependent';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';
import IGraphic from '../../graphic/IGraphic';
import { AnyGraphicStructure } from '../../Types';
import Utils from '../impl/Utils';

interface IPageStructureObserverDependencies {
	componentTree: IPagesComponentTree,
}

class PageStructureObserver extends Dependent<IPageStructureObserverDependencies> {
	private readonly subscribers: VoidFunction[];

	private snapshot: AnyGraphicStructure[] | null;

	constructor() {
		super();
		this.snapshot = null;
		this.subscribers = [];
	}

	public subscribe = (subscriber: VoidFunction) => {
		this.subscribers.push(subscriber);
	};

	public sync = () => {
		const pages = this.dependencies.componentTree.getPages();
		const firstPage = pages[0];
		if (pages === undefined) {
			console.warn('page preview observer: first page not found');
			return;
		}

		const embeddedGraphics = this.dependencies.componentTree.getPageEmbeddedGraphics(0);
		const targetGraphics: IGraphic[] = embeddedGraphics === null ? [firstPage] : [firstPage, ...embeddedGraphics];
		const structures: AnyGraphicStructure[] = targetGraphics.map(graphic => graphic.getStructure());

		if (this.snapshot === null) {
			this.snapshot = structures;
			return;
		}

		if (!Utils.Object.deepEqual(this.snapshot, structures)) {
			this.callSubscribes();
		}

		this.snapshot = structures;
	};

	private callSubscribes = () => {
		for (let i = 0; i < this.subscribers.length; i++) {
			this.subscribers[i]();
		}
	};
}

export default PageStructureObserver;
