import React, { useEffect, useMemo } from 'react';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { ModalTitle } from '../../../../shared/ui/modal-title/modal-title';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import InputField from '../../../../components/pages/registration/InputField/InputField';
import { cleanInputValues, useInputValue } from '../../../../hooks/useInput';
import css from './create-user.module.scss';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';

interface CreateUserModalProps {
	name: useInputValue,
	email: useInputValue,
	phone: useInputValue,
	isActive: boolean,
	title?: string,
	onCreating: () => void,
	isDataLoading: boolean,
	setActive: (value: boolean) => void,
}

export const CreateUserModal = ({
	name,
	phone,
	isDataLoading,
	title,
	onCreating,
	isActive,
	setActive,
	email,
}: CreateUserModalProps) => {
	const isFormFilled = useMemo(
		() => name.inputValid && email.inputValid && phone.inputValid && !isDataLoading,
		[name, email, phone, isDataLoading],
	);

	useCatchEnterClick(onCreating, isFormFilled);

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onCreating();
	};

	useEffect(() => {
		if (!isActive) cleanInputValues([name, email, phone]);
	}, [isActive]);

	return (
		<NewModal styles={css.wrapper} active={isActive} closeModal={setActive}>
			<ModalTitle>{title}</ModalTitle>
			<form onSubmit={handleFormSubmit}>
				<InputField
					placeholder="ФИО"
					type="text"
					value={name.value}
					onChange={e => name.onChange(e, 'name')}
					onBlur={name.onBlur}
					status={
						name.isEmpty || name.minLengthError || name.maxLengthError
							? 'error'
							: 'success'
					}
				/>
				<InputField
					placeholder="Телефон"
					type="tel"
					status={
						(phone.isEmpty || phone.phoneError)
							? 'error'
							: 'success'
					}
					value={phone.value}
					onChange={e => phone.onChange(e, 'phone')}
					onBlur={phone.onBlur}
				/>
				<InputField
					placeholder="E-mail"
					type="email"
					status={
						(email.emailError || email.isEmpty || email.minLengthError || email.maxLengthError)
							? 'error'
							: 'success'
					}
					value={email.value}
					onChange={e => email.onChange(e, 'email')}
					onBlur={email.onBlur}
				/>
				<MainButton disabled={!isFormFilled} type="submit" btnStyle="bright">
					{title}
				</MainButton>
			</form>
		</NewModal>
	);
};
