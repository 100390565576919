import { tariffAPI } from '../../../../entities/tariff/api/api';
import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { adminAPI } from '../../../../entities/admin/api/api';
import { accountAPI } from '../../../../entities/user/account/api/api';

export const useDisableTariff = (tariffId: number) => {
	const dispatch = useAppDispatch();

	const [disable, { isLoading }] = tariffAPI.useDisableTransactionMutation();

	const disableTariff = async () => {
		try {
			await disable({ transactionId: tariffId })
				.unwrap();
			dispatch(accountAPI.util?.invalidateTags(['account']));
			dispatch(adminAPI.util?.invalidateTags(['admin']));
			notificationSuccess('Тариф успешно отключен!');
		} catch (error) {
			notificationError('Произошла ошибка при отключении тарифа...');
		}
	};

	return {
		isLoading,
		disableTariff,
	};
};
