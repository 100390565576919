import { Dispatch } from 'redux';
import store from '../store/store';
import { AnyComponentStructure } from '../../components/Sketch/Types';
import {
	setCloseTableModalFunc,
	setCreateModuleWindowClose,
	setCreateModuleWindowOpen,
	setCreateTableFunc,
	setCreateTemplateWindowPreview,
	setCreateTemplateWindowStructure,
	setIsCreateTableModalOpen,
} from '../../entities/modules/module/model/slice';
import {
	createWorkshopModuleActions,
} from '../../features/manage-workshop-module/model/create-module/slice/createWorkshopModuleSlice';
import { workshopModuleActions } from '../../entities/workshop-module';
import { templateModuleActions } from '../../entities/template-module';
import {
	setAiMode,
	setCloseAiModalFunc,
	setIsAiCategoriesModalOpen,
} from '../../features/ai/model/slice/aiSlice';

// * Вызов модалки AI
export const onOpenAiModal = (
	onCloseAiModal: () => void,
) => {
	store.dispatch(setCloseAiModalFunc(onCloseAiModal));
	store.dispatch(setIsAiCategoriesModalOpen(true));
	store.dispatch(setAiMode(true));
};

export const onCloseModuleWindow = () => {
	// store.dispatch(setWindowModuleClose());
	store.dispatch(templateModuleActions.setIsTemplateModuleActive(false));
};

// * Вызов модалки модулей шаблонов
export const onOpenTemplateModuleWindow = (
	onEntityModuleClick: (moduleId: string) => void,
	onGalleryModuleClick: (moduleId: string) => void,
	onCloseModuleWindow: () => void,
) => {
	store.dispatch(templateModuleActions.setOnEntityModuleClick(onEntityModuleClick));
	store.dispatch(templateModuleActions.setOnGalleryModuleClick(onGalleryModuleClick));

	store.dispatch(templateModuleActions.setCloseModuleModalFunc(onCloseModuleWindow));

	store.dispatch(templateModuleActions.setIsTemplateModuleActive(true));

	// store.dispatch(setModuleFunc(onModuleClick));
	// store.dispatch(setWindowModuleOpen());
	// store.dispatch(setCloseModuleModalFunc(onCloseModuleWindow));
};

export const onCloseWorkshopModuleWindow = () => {
	store.dispatch(workshopModuleActions.setIsModulesModalActive(false));
};

// * Вызов модалки модулей мастерской
export const onOpenWorkshopModuleWindow = (
	onModuleClick: (moduleId: string) => void,
	onCloseModuleWindow: () => void,
) => {
	store.dispatch(workshopModuleActions.setModuleFunc(onModuleClick));

	store.dispatch(workshopModuleActions.setCloseModuleModalFunc(onCloseModuleWindow));

	store.dispatch(workshopModuleActions.setIsModulesModalActive(true));
};

export const onThunkCloseCreateModuleWindow = () => (dispatch: Dispatch) => {
	dispatch(setCreateModuleWindowClose());
};

// * Вызов модалки создания модулей
export const onOpenCreateTemplateWindow = (
	fn: (showPreview: (src: string) => void) => void,
	moduleStructure: AnyComponentStructure,
) => {
	const showPreview = (preview: string) => {
		store.dispatch(setCreateTemplateWindowPreview(preview));
	};

	// TODO Сделать лоадер загрузки превью
	store.dispatch(setCreateTemplateWindowPreview('Загрузка...'));

	store.dispatch(setCreateModuleWindowOpen());
	store.dispatch(setCreateTemplateWindowStructure(moduleStructure));

	fn(showPreview);
};

// * Вызов модалки создания модулей в мастерской
export const onOpenCreateWorkshopModule = (
	fn: (showPreview: (src: string) => void) => void,
	moduleStructure: AnyComponentStructure,
) => {
	const showPreview = (preview: string) => {
		store.dispatch(createWorkshopModuleActions.setPreview(preview));
	};

	// TODO Сделать лоадер загрузки превью
	store.dispatch(createWorkshopModuleActions.setPreview('Загрузка...'));

	store.dispatch(createWorkshopModuleActions.setIsWindowOpen(true));
	store.dispatch(createWorkshopModuleActions.setStructure(moduleStructure));

	fn(showPreview);
};

// * Вызов модалки создания таблицы
export const onOpenCreateTableWindow = (
	onCreateTable: (columnCount: number, rowCount: number) => void,
	onCloseTableModal: () => void,
) => {
	store.dispatch(setIsCreateTableModalOpen(true));
	store.dispatch(setCreateTableFunc(onCreateTable));
	store.dispatch(setCloseTableModalFunc(onCloseTableModal));
};
