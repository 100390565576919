import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { URI_OFFER } from './consts';
import {
	ICreateOfferRequest,
	ICreateOfferResponse,
	IGetOfferResponse,
	IGetOffersRequest,
	IGetOffersResponse,
	IOfferPresentation, IUpdateOfferPreviewRequest,
} from './types';

export const offerAPI = createApi({
	reducerPath: 'offerAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['offer'],
	endpoints: builder => ({
		getActive: builder.query<IGetOffersResponse, IGetOffersRequest>({
			query: (data) => ({
				url: `${URI_OFFER}/active`,
				method: 'GET',
				params: data,
			}),
			providesTags: ['offer'],
		}),
		getCompleted: builder.query<IGetOffersResponse, IGetOffersRequest>({
			query: (data) => ({
				url: `${URI_OFFER}/completed`,
				method: 'GET',
				params: data,
			}),
			providesTags: ['offer'],
		}),
		get: builder.query<IGetOfferResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_OFFER}/${id}`,
				method: 'GET',
			}),
			providesTags: ['offer'],
		}),
		presentation: builder.query<IOfferPresentation, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_OFFER}/${id}/presentation`,
				method: 'GET',
			}),
			providesTags: ['offer'],
		}),
		complete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_OFFER}/${id}/complete`,
				method: 'PUT',
			}),
			invalidatesTags: ['offer'],
		}),
		create: builder.mutation<ICreateOfferResponse, ICreateOfferRequest>({
			query: (data) => ({
				url: URI_OFFER,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['offer'],
		}),
		reuse: builder.mutation<{ sketch: string }, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_OFFER}/${id}/reuse`,
				method: 'POST',
			}),
			invalidatesTags: ['offer'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_OFFER}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['offer'],
		}),
		update: builder.mutation<void, { templateId: string, name: string }>({
			query: ({
				templateId,
				name,
			}) => ({
				url: `${URI_OFFER}/${templateId}/name`,
				method: 'PUT',
				body: { name },
			}),
			invalidatesTags: ['offer'],
		}),
		updatePreview: builder.mutation<void, IUpdateOfferPreviewRequest>({
			query: ({
				sketchId,
				preview,
			}) => ({
				url: `${URI_OFFER}/${sketchId}/preview`,
				method: 'POST',
				body: {
					preview,
				},
			}),
		}),
	}),
});
