import _ from 'lodash';
import TableComponent from '../../components/table/TableComponent';
import { notificationError } from '../../../Notifications/callNotifcation';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import Dependent from '../../utils/dependent/Dependent';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import Utils from '../../utils/impl/Utils';
import IGraphicStructure from '../../graphic/IGraphicStructure';
import ITableGraphicTexture from '../../graphic/table/ITableGraphicTexture';
import GraphicType from '../../graphic/GraphicType';
import SketchComponentType from '../../components/SketchComponentType';
import IComponentStructure from '../../components/IComponentStructure';
import ITableComponentTexture from '../../components/table/ITableComponentTexture';
import IGraphic from '../../graphic/IGraphic';
import TableGraphic from '../../graphic/table/TableGraphic';
import SpatialTableCellArea from '../spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';

export interface ITableSplitterDependencies {
	componentTree: IComponentTreeMutator & IComponentTree & ILayeredComponentTree,
}
/**
 * Отвечает за разделение таблицы на две части по указанной ячейке.
 * Он обрабатывает исходную таблицу, делит её на две, а затем вставляет вторую таблицу
 * под первую, корректируя индексы строк и ячеек.
 */
class TableSplitter<Dependencies extends ITableSplitterDependencies> extends Dependent<Dependencies> {
	// Отступ от первой таблицы при вставке
	private readonly PADDING_BOTTOM = 20;

	private readonly prevSplitListeners: ((tableComponent: TableComponent) => void)[];
	private readonly postSplitListeners: VoidFunction[];

	protected constructor() {
		super();
		this.prevSplitListeners = [];
		this.postSplitListeners = [];
	}

	/**
	 * Разделяет таблицу на две по активной ячейке.
	 * Если ячейка выходит за пределы строки, по которой нужно разделить таблицу, она делится на две части.
	 * Первая таблица заменяет исходную, вторая вставляется ниже первой.
	 *
	 * @param table Таблица, которую необходимо разделить
	 * @param activeCellArea Область, содержащая активную ячейку, по которой будет происходить разделение
	 */
	public splitTable = (table: TableComponent, activeCellArea: SpatialTableCellArea) => {
		// Проверка на существование таблицы
		if (table === undefined) {
			throw new ManipulatorError('table is undefined');
		}

		const activeCell = activeCellArea.getData().areaCell;
		if (activeCell === undefined) {
			notificationError('Разделение таблицы', 'Не найдена ячейка в фокусе.');
			return;
		}

		// Индекс строки, по которой нужно делить
		const targetIndex = activeCell.getRow() + activeCell.getRowSpan() - 1;
		// Проверяем, можно ли разделить таблицу
		if (targetIndex === table.getRowCount() - 1) {
			notificationError('Разделение таблицы', 'Невозможно разделить таблицу по последней строке.');
			return;
		}

		const textures = table.getTexture().cells;
		const updatedTextures = _.cloneDeep(textures);

		const firstTable: ITableCellTexture[] = [];
		const secondTable: ITableCellTexture[] = [];

		for (let i = 0; i < textures.length; i++) {
			const cell = updatedTextures[i];
			const {
				row, rowSpan, column, columnSpan, background,
			} = cell;

			const isInFirstTable = row <= targetIndex;

			// Проверяем, нужно ли разделять ячейку по границе targetIndex
			if (row <= targetIndex && targetIndex < row + rowSpan - 1) {
				// Количество rowSpan, которое должно быть отделено и перемещено во вторую таблицу.
				const diffSpan = rowSpan - 1 + row - targetIndex;
				
				cell.rowSpan -= diffSpan;

				const newCell = this
					.getSingleCellTexture((row + rowSpan - 1) - targetIndex - diffSpan, column, background);
				newCell.rowSpan = diffSpan;
				newCell.columnSpan = columnSpan;
				secondTable.push(newCell);
			}

			// Если это вторая таблица, корректируем индексы строк
			if (!isInFirstTable) {
				cell.row -= targetIndex + 1;
				secondTable.push(cell);
			} else {
				firstTable.push(cell);
			}
		}

		// Вставляем вторую таблицу
		this.injectTableUnder(table, secondTable, activeCellArea);
		
		// Формируем первую таблицу
		this.replaceTable(table, firstTable);

		this.callPostSplitListeners();
	};

	/**
	 * Вставляет вторую таблицу под первой с отступом.
	 * Метод рассчитывает координаты вставки новой таблицы и добавляет ее в дерево компонентов.
	 *
	 * @param table Первая таблица, под которой нужно вставить вторую
	 * @param cells Набор ячеек для второй таблицы
	 * @param activeCellArea Область, содержащая активную ячейку, по которой происходит разделение
	 */
	private injectTableUnder = (
		table: TableComponent,
		cells: ITableCellTexture[],
		activeCellArea: SpatialTableCellArea,
	) => {
		const { graphic, areaCell } = activeCellArea.getData();
		const cellGlobalFrame = activeCellArea.getGlobalFrameArea();
		const graphicFrame = graphic.getFrameArea();
		const cellFrame = areaCell.getFrameArea();

		// Рассчитываем координаты для новой таблицы: ниже последней ячейки первой таблицы
		const yDiff = cellGlobalFrame.y - graphic.getGlobalPosition().y;
		const newY = graphicFrame.y + yDiff + cellFrame.height + this.PADDING_BOTTOM;

		const parentComponent = table.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		// Вычисляем смещение для вставки новой таблицы ниже последней ячейки
		const offset = this.calculateOffsetAtPosition(cellGlobalFrame.y + cellGlobalFrame.height + this.PADDING_BOTTOM);

		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const { borderColor, columnMultipliers } = table.getTexture();
		const rowCount = this.calculateRowCount(cells);

		const newGraphic: IGraphicStructure<ITableGraphicTexture> = {
			id: graphicId,
			type: GraphicType.TABLE,
			frame: {
				width: graphicFrame.width,
				height: 0,
				layer: 0,
				x: graphicFrame.x,
				y: newY,
				rotate: 0,
			},
			offset: 0,
			texture: {
				rowCount,
			},
		};

		const tableStructure: IComponentStructure<ITableComponentTexture> = {
			id: componentId,
			type: SketchComponentType.TABLE,
			offset,
			graphics: [newGraphic],
			texture: {
				startRows: [0],
				cells,
				borderColor,
				columnMultipliers,
				rowMultipliers: new Array(rowCount).fill(1),
			},
			components: null,
		};

		let component: TableComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			component = tools.componentFactory.createComponent<TableComponent>(tableStructure);
			const graphicSequence = new Map<IGraphic, TableGraphic>();

			tableStructure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<TableGraphic>(GraphicType.TABLE, component);
				graphic.setStructure(() => graphicStructure);

				component.appendGraphic(graphic);

				if (tableStructure.offset === null) {
					throw new ManipulatorError('the table structure does not have an offset');
				}

				const pageNumber = tableStructure.offset + graphicStructure.offset;
				const lastPageLayerGraphic = tools.componentLayers.getLastLayerGraphicFromPage(pageNumber);

				graphicSequence.set(lastPageLayerGraphic, graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, component);

			graphicSequence.forEach((tableGraphic, prevGraphic) => {
				tools.mutator.mutateByChangeLayer(prevGraphic, tableGraphic);
			});

			this.callPrevSplitListeners(component);
		});
	};

	/**
	 * Заменяет текущие текстуры ячеек таблицы на новые.
	 *
	 * @param table Таблица, в которой нужно заменить текстуру ячеек
	 * @param cells Набор новых текстур ячеек для таблицы
	 */
	private replaceTable = (table: TableComponent, cells: ITableCellTexture[]) => {
		const rowCount = this.calculateRowCount(cells);
		const rowMultipliers = Array(rowCount).fill(1);

		table.mutateRowMultipliers(rowMultipliers);
		table.mutateCellTextures(cells);
		table.applyMutations();

		this.callPrevSplitListeners(table);
	};

	private calculateRowCount = (cells: ITableCellTexture[]) => {
		let rowCount = 0;
		for (let i = 0; i < cells.length; i++) {
			const cell = cells[i];
			const { row, rowSpan } = cell;

			rowCount = Math.max(rowCount, row + rowSpan);
		}

		return rowCount;
	};

	private calculateOffsetAtPosition = (newY: number) => {
		// Переменная для хранения ближайшей страницы
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;
		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(newY - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		return nearestPage.getOffset();
	};

	private getSingleCellTexture = (row: number, column: number, background: string): ITableCellTexture => ({
		id: Utils.Generate.UUID4(),
		column,
		row,
		background,
		rowSpan: 1,
		content: Utils.Component.getDefaultTextModel(),
		columnSpan: 1,
	});

	protected addPostSplitListeners(listener: () => void) {
		this.postSplitListeners.push(listener);
	}

	protected addPrevSplitListeners(listener: (tableComponent: TableComponent) => void) {
		this.prevSplitListeners.push(listener);
	}

	private callPostSplitListeners = () => {
		this.postSplitListeners.forEach(listener => listener());
	};

	private callPrevSplitListeners = (tableComponent: TableComponent) => {
		this.prevSplitListeners.forEach(listener => listener(tableComponent));
	};
}

export default TableSplitter;
