import css from './reactivate.module.scss';
import { useReactivateTariff } from '../api/useReactivateTariff';

interface ReactivateTariffButtonProps {
	transactionId: number,
}

export const ReactivateTariffButton = ({ transactionId }: ReactivateTariffButtonProps) => {
	const {
		isLoading,
		reactivateTariff,
	} = useReactivateTariff(transactionId);

	return (
		<button type="submit" disabled={isLoading} className={css.wrapper} onClick={reactivateTariff}>
			Активировать
		</button>
	);
};
