import { useSelector } from 'react-redux';
import ConfirmModal from '../../../../components/modals/common/confirmModal/ConfirmModal';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import { workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { useUnbindWorkshopTag } from '../../services/unbind-tag/useUnbindWorkshopTag';

export const UnbindWorkshopTagModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopTagSlice.selectors.getIsUnbindingModalActive);

	const { onUnbindTag } = useUnbindWorkshopTag();

	const handleModal = (value: boolean) => {
		dispatch(workshopTagActions.setIsUnbindingTagModalActive(value));
	};

	const closeModal = () => handleModal(false);

	return (
		<NewModal active={isActive} closeModal={handleModal}>
			<ConfirmModal
				onDelete={onUnbindTag}
				title="Вы уверены?"
				closeBtnText="Отменить"
				actionBtnText="Удалить"
				isModalActive={isActive}
				text="Удаление тега - необратимое действие"
				onCloseModal={closeModal}
			/>
		</NewModal>
	);
};
