import HTMLGenerator from '../utils/HTMLGenerator';

class LoadingPage {
	private readonly PAGE_COUNT = 3;

	private readonly rootElement: HTMLElement;
	private readonly pageElements: HTMLElement[];

	constructor(rootElement: HTMLElement) {
		this.pageElements = [];
		this.rootElement = rootElement;
		for (let i = 0; i < this.PAGE_COUNT; i++) {
			const container = HTMLGenerator.getDiv({
				className: 'page-loader_container',
			});
			const page = HTMLGenerator.getDiv({
				className: 'page-loader_page',
			});
			container.append(page);
			this.pageElements.push(container);
		}
	}

	public show = () => {
		this.pageElements.forEach(element => this.rootElement.append(element));
	};

	public hide = () => {
		this.pageElements.forEach(element => element.remove());
	};
}

export default LoadingPage;
