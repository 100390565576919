import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import RedirectPage from './RedirectPage';
import { PATH_AUTH_SUCCESS } from '../authorization/Authorization';
import { authAPI } from '../../../../entities/user/auth/api/api';
import { setAccount, setAccountSessionId } from '../../../../redux/reducers/personalAccountReducer';
import { useAppDispatch } from '../../../../shared/libs/redux';

const RedirectPageContainer = () => {
	const { pass = '' } = useParams();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [redirectCounter, setRedirectCounter] = useState(3);

	const counterFunc = () => {
		const timer = setInterval(() => setRedirectCounter(redirectCounter => redirectCounter - 1), 1000);
		setTimeout(() => {
			clearInterval(timer);
			navigate(PATH_AUTH_SUCCESS);
			notificationSuccess('Вы успешно подтвердили свой аккаунт!', 'Данные для входа отправлены на вашу почту!');
		}, 3000);
	};

	const [confirmEmployee] = authAPI.useConfirmEmployeeMutation();

	const onConfirmEmployee = async (code: string) => {
		try {
			const res = await confirmEmployee({ code })
				.unwrap();

			dispatch(setAccount(res.account));
			dispatch(setAccountSessionId(res.session));
		} catch (e) {
			notificationError('Ошибка!', 'Произошла ошибка...');
		} finally {
			counterFunc();
		}
	};

	useEffect(() => {
		if (pass) onConfirmEmployee(pass);
	}, []);

	return (
		<RedirectPage redirectCounter={redirectCounter} />
	);
};

export default RedirectPageContainer;
