import React from 'react';
import css from './loading.module.scss';

interface LoadingProps{
    color?: string
}

const Loading: React.FC<LoadingProps> = ({ color }: LoadingProps) => {
	Loading.defaultProps = {
		color: undefined,
	};
	return (
		<div style={{ borderColor: color }} className={css.loading} />
	);
};

export default Loading;
