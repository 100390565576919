import React, { useState } from 'react';
import { ArrowInput, DropdownItem, IDropdownItem } from '../../../shared/ui';
import css from './dropdown-arrow-input.module.scss';

interface DropdownArrowInputProps {
	data: IDropdownItem[],
	selectedData: string[],
	children: React.ReactNode,
	onItemClick: (id: string) => void,
	onLastItemClick?: (id: string) => void,
}

export const DropdownArrowInput = ({
	data,
	children,
	onItemClick,
	selectedData,
	onLastItemClick,
}: DropdownArrowInputProps) => {
	const [isVisible, setVisible] = useState(false);

	const handleDropdown = () => setVisible(!isVisible);

	return (
		<div>
			<ArrowInput onClick={handleDropdown}>{children}</ArrowInput>
			{
				isVisible && (
					<div className={css.dropdown}>
						{
							data.map((el, i) => {
								const onClick = i === data.length - 1
								&& onLastItemClick ? onLastItemClick : onItemClick;

								return (
									<DropdownItem
										isActive={selectedData.includes(el.id)}
										key={el.id}
										data={el}
										onClick={onClick}
									/>
								);
							})
						}
					</div>
				)
			}
		</div>
	);
};
