import React, { useMemo } from 'react';
import { ISelectAbleCardData, SelectableFilterCard } from '../../../../../../../shared/ui';
import { defaultWorkshopTariffFilterData } from '../../../model/data';
import { DropdownFilter } from '../../../../../../../widgets/dropdown-filter';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import {
	ITariffFilter,
	ITariffPermissionFilter,
} from '../../../../../../../entities/templates/workshop/template/model/types';
import { tariffAPI } from '../../../../../../../entities/tariff/api/api';
import { tariffMapData } from '../../../../../../../entities/tariff/model/data';
import {
	setWorkshopTariffPermissionFilter,
} from '../../../../../../../entities/templates/workshop/template/model/slice';
import css from './tariff-dropdown-filter.module.scss';

export const WorkshopTemplatesTariffDropdownFilter = () => {
	const dispatch = useAppDispatch();

	const { data: bitmap } = tariffAPI.useGetBitmapQuery();

	const {
		filter: tariffFilter,
		permission,
	} = useAppSelector(state => state.workshopTemplate.tariffPermissionFilter);

	const setTariffPermissionFilter = (
		value: ITariffPermissionFilter,
	) => dispatch(setWorkshopTariffPermissionFilter(value));

	const tariffs = useMemo(() => tariffFilter?.map(el => el.tariff), [tariffFilter]);
	const selectedTariffs = useMemo(() => tariffFilter?.map(el => el.text)
		.join(', '), [tariffFilter]);

	const handleFilter = (filter: ITariffFilter | undefined) => {
		if (filter && tariffs?.includes(filter.tariff)) {
			const newPermission = permission & ~(1 << filter.tariff);

			setTariffPermissionFilter({
				permission: newPermission,
				filter: [...tariffFilter.filter(el => el.text !== filter.text)],
			});

			return;
		}
		if (!filter) {
			setTariffPermissionFilter({
				permission: 0,
				filter: [],
			});

			return;
		}
		const newPermission = permission | (1 << filter.tariff);
		setTariffPermissionFilter({
			permission: newPermission,
			filter: [...tariffFilter, filter],
		});
	};

	return (
		<DropdownFilter
			styles={css.wrapper}
			scrollContentHeight={102}
			selected={!tariffFilter.length ? defaultWorkshopTariffFilterData.text : selectedTariffs}
		>
			<SelectableFilterCard
				selected={!tariffFilter.length}
				data={defaultWorkshopTariffFilterData}
				onSelect={() => handleFilter(undefined)}
			/>
			{
				bitmap?.tariffs.map(({
					tariff,
					bitPosition,
				}) => {
					const tariffFilter: ITariffFilter = {
						tariff: bitPosition,
						text: tariffMapData.get(tariff) as string,
					};

					const cardData: ISelectAbleCardData = {
						id: bitPosition.toString(),
						text: tariffMapData.get(tariff) as string,
					};
					return (
						<SelectableFilterCard
							key={tariff}
							data={cardData}
							selectedType="filled"
							selected={tariffs.includes(tariffFilter.tariff)}
							onSelect={() => handleFilter(tariffFilter)}
						/>
					);
				})
			}
		</DropdownFilter>
	);
};
