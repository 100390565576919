import { useState } from 'react';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';

export const useSearchCategoryDropdownVisibility = () => {
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const showDropDown = () => setIsDropdownVisible(true);
	const hideDropDown = () => setIsDropdownVisible(false);

	const searchBlockRef = useOutSideClick(hideDropDown);

	return {
		isDropdownVisible,
		showDropDown,
		hideDropDown,
		searchBlockRef,
	};
};
