import { setIsEditCategoryModalActive } from '../../../../redux/reducers/module/categoryReducer';
import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopCategoryApi } from '../../../../entities/workshop-category';

export const useEditCategory = (id: number | null) => {
	const dispatch = useAppDispatch();

	const [update, { isLoading }] = workshopCategoryApi.useUpdateMutation();

	const handleModal = (value: boolean) => {
		dispatch(setIsEditCategoryModalActive(value));
	};

	// * Изменение категории
	const editCategory = async (
		inputValue: string,
		setInputValue: (value: string) => void,
	) => {
		if (inputValue !== '' && id) {
			try {
				await update({
					moduleCategories: [{
						id,
						name: inputValue,
						sequenceNumber: 0,
					}],
				})
					.unwrap();
				setInputValue('');
				handleModal(false);
				notificationSuccess('Категория изменена успешно!');
			} catch (e) {
				notificationError('При изменении категории возникла ошибка...');
			}
		}
	};

	return {
		editCategory,
		isLoading,
	};
};
