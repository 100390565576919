import ToolBarTool from '../ToolBarTool';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';

/**
 * Элемент интерфейса для создания нового текстового компонента.
 */
class InjectTextTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Текст / Shift + T';

	constructor() {
		super('panel-components__text');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.textTool);
	}
}

export default InjectTextTool;
