import React from 'react';
import { DeleteModalWrapper } from '../../../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useDeleteLegalEntity } from '../../../lib/useDeleteLegalEntity';

export const DeleteEntityModal = () => {
	const {
		deleteLegalEntity,
		isActive,
		setActive,
	} = useDeleteLegalEntity();
	
	return (
		<DeleteModalWrapper
			description="Вы действительно хотите удалить юридическое лицо без возможности восстановления?"
			onDelete={deleteLegalEntity}
			isActive={isActive}
			setIsActive={setActive}
		/>
	);
};
