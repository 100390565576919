import useDynamicPagination from "../../useDynamicPagination";
import { ITEM_LIMIT_PER_PAGE } from "../../../components/pages/collection/section/template/SectionTemplate";
import { TTemplateSortBy } from "../../../entities/templates/types";
import { useAppDispatch, useAppSelector } from "../../../shared/libs/redux";
import { useDebounce } from "../../../shared/libs/useDebounce";
import { teamTemplateAPI } from "../../../entities/templates/team/api/api";
import { useEffect, useMemo } from "react";
import { ITeamTemplateData } from "../../../entities/templates/team/api/types";
import { setTeamTemplates, setTeamTemplateSearch } from "../../../entities/templates/team/model/slice";

const useGetTemplatesTeam = (
	sortBy?: TTemplateSortBy,
) => {
	const dispatch = useAppDispatch();

	const {
		search,
		teamTemplates,
	} = useAppSelector(state => state.teamTemplate);
	const teams = useAppSelector(state => state.personalAccount.account.teams);

	const setSearch = (value: string) => dispatch(setTeamTemplateSearch(value));
	const setList = (list: ITeamTemplateData[]) => dispatch(setTeamTemplates(list));

	const debounceSearch = useDebounce(search.trim(), 250);

	const teamsIds = useMemo(() => teams?.map(({ id }) => id), [teams]);

	const {
		data: initialTemplates,
		isLoading
	} = teamTemplateAPI.useGetAllQuery({
		page: 1,
		sortBy,
		teams: teamsIds || [],
		limit: ITEM_LIMIT_PER_PAGE,
		search: debounceSearch,
	});

	const [getTemplates] = teamTemplateAPI.useLazyGetAllQuery();

	const fetchData = (page: number, perPage: number) => {
		if (teamTemplates && teams) {
			return getTemplates({
				page,
				sortBy,
				limit: perPage,
				search: debounceSearch,
				teams: teamsIds || [],
			})
				.unwrap()
				.then(data => setList([...teamTemplates, ...data?.teamTemplates]));
		}
		return undefined;
	};

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		ITEM_LIMIT_PER_PAGE,
		2,
		teamTemplates?.length,
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialTemplates) {
			setCurrentPage(2);
			setList(initialTemplates?.teamTemplates || []);

			setTotalCount(initialTemplates?.totalCount || 0);
		}
	}, [initialTemplates]);

	return {
		search,
		setSearch,
		isLoading,
	};
};

export default useGetTemplatesTeam;
