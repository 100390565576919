import { useSelector } from 'react-redux';
import { FormEventHandler, useMemo } from 'react';
import { workshopTagGroupActions, workshopTagGroupSlice } from '../../../../entities/workshop-tag-group';
import { workshopModuleTagApi } from '../../../../entities/workshop-tag';
import { useCreateWorkshopTagGroup } from '../../services/create-group/useCreateWorkshopTagGroup';
import { useActionCreators } from '../../../../shared';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import CreateInput from '../../../../components/pages/features/moduleWindow/createInput/CreateInput';
import CreateInputArrow from '../../../../components/pages/features/moduleWindow/createInputArrow/CreateInputArrow';
import Button, { buttonsTypes } from '../../../../components/ui/button/button';
import css from './CreateWorkshopTagGroupModal.module.scss';

export const CreateWorkshopTagGroupModal = () => {
	const isActive = useSelector(workshopTagGroupSlice.selectors.getIsCreateGroupModalActive);
	const value = useSelector(workshopTagGroupSlice.selectors.getCreateGroupName);
	const selectedTags = useSelector(workshopTagGroupSlice.selectors.getCreatedGroupTags);

	const { data: tagsData } = workshopModuleTagApi.useGetWorkshopTagsQuery({
		page: 1,
		limit: 127,
		search: '',
	});

	const { onCreate, requestData } = useCreateWorkshopTagGroup();

	const actions = useActionCreators(workshopTagGroupActions);

	const handleModal = () => {
		actions.setIsCreateGroupModalActive(false);
	};

	const handleValue = (value: string) => {
		actions.setCreateGroupName(value);
	};

	const addCreatedGroupTags = (tag: string) => {
		actions.addCreatedGroupTag(Number(tag));
	};

	const removeCreatedGroupTags = (tag: string) => {
		actions.removeCreatedGroupTag(Number(tag));
	};

	const isBtnActive = !!value.length && !requestData.isLoading;

	const createGroup: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (isBtnActive) {
			await onCreate();
		}
	};

	const tagDataForSelect = useMemo(
		() => tagsData?.moduleTags?.map(
			tag => ({ ...tag, id: String(tag.id) }),
		)
		|| [],
		[tagsData],
	);

	const selectedTagsIds = useMemo(
		() => selectedTags.map(
			tag => String(tag),
		),
		[selectedTags],
	);

	return (
		<NewModal active={isActive} closeModal={handleModal}>
			<form className={css.wrapper} onSubmit={createGroup}>
				<div className={css.title}>
					Создать группу тегов
				</div>
				<CreateInput
					maxLength={20}
					text={value}
					subTitle="Введите название"
					defaultValue="Название группы тегов"
					onChange={handleValue}
				/>
				<CreateInputArrow
					text="Теги"
					inputData={tagDataForSelect}
					choicedItemsId={selectedTagsIds}
					onAddChoicedItem={addCreatedGroupTags}
					onRemoveChoicedItem={removeCreatedGroupTags}
				/>
				<Button
					text="Создать"
					type="submit"
					buttonType={buttonsTypes.BRIGHT}
					cssClass={css.btn}
					inActive={isBtnActive}
				/>
			</form>
		</NewModal>
	);
};
