import React from 'react';
import { classNames } from '../../../../shared/libs/classNames';
import css from './WorkshopTagGroupCard.module.scss';
import { IWorkshopTagsGroup } from '../../model/types/types';
import { ReactComponent as EditCategoryIcon } from '../../assets/edit.svg';
import { ReactComponent as DeleteCategoryIcon } from '../../assets/delete.svg';
import AddBtn from '../../../../components/ui/addBtn/AddBtn';
import { ModuleTag } from '../../../modules/tag/ui/module-tag';

interface WorkshopTagGroupCardProps {
	className?: string,
	data?: IWorkshopTagsGroup,
	onBindTag?: (groupId: number) => void,
	onUnbindTag?: (groupId: number, tagId: number) => void,
	onDeleteTagGroup?: (groupId: number) => void,
	onEditTagGroup?: (groupId: number) => void,
}

export const WorkshopTagGroupCard = ({
	className,
	data,
	onDeleteTagGroup,
	onEditTagGroup,
	onUnbindTag,
	onBindTag,
}: WorkshopTagGroupCardProps) => {
	if (!data) {
		return null;
	}

	const { tag, name, id } = data;

	const onDelete = () => {
		onDeleteTagGroup?.(id);
	};

	const onEdit = () => {
		onEditTagGroup?.(id);
	};

	const onUnbind = (tagId: number) => {
		onUnbindTag?.(id, tagId);
	};

	const onBind = () => {
		onBindTag?.(id);
	};

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			<div className={css.main}>
				<div className={css.title}>
					<div className={css.text}>{name}</div>
					<div
						role="presentation"
						className={css.btn}
						onClick={onEdit}
					>
						<EditCategoryIcon />
					</div>
					<div
						role="presentation"
						className={css.btn}
						onClick={onDelete}
					>
						<DeleteCategoryIcon />
					</div>
				</div>
				<div className={css.tags}>
					{
						tag?.map((tag) => {
							const unbindTag = () => onUnbind?.(tag.id);

							return (
								<ModuleTag onUnbind={unbindTag} tag={tag} key={tag.id} />
							);
						})
					}
				</div>
			</div>
			<AddBtn btnText="Добавить тег в группу" onBtnClick={onBind} />
		</div>
	);
};
