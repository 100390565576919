import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { ITariff, ITransaction } from '../../../entities/tariff/api/types';
import css from './user-tariff.module.scss';
import { tariffMapData } from '../../../entities/tariff/model/data';
import { calculateTariffExpiration } from '../libs/calculate-tariff-expiration';
import { calculateDuration } from '../../../shared/libs/utils/calculate-duration';

interface UserTariffProps {
	tariff: ITariff | null | undefined,
	tariffTransactions: ITransaction[] | undefined,
	children: React.ReactNode,
}

export const UserTariff = ({
	tariff,
	children,
	tariffTransactions,
}: UserTariffProps) => {
	const [remainingDaysStr, setRemainingDaysStr] = useState('0 дней 0 часов 0 минут');

	useEffect(() => {
		const transactions = tariffTransactions ? [...tariffTransactions] : [];

		// * Сортирует транзакций при изменении списка транзакций
		transactions.sort((a, b) => {
			const today = new Date();
			return calculateDuration(today, new Date(a?.startDate))
				- calculateDuration(today, new Date(b?.startDate));
		});

		// * Обновляет оставшееся время
		const updateRemainingTime = () => {
			if (tariff) {
				setRemainingDaysStr(calculateTariffExpiration(tariff.startDate, tariff.duration));
			} else if (transactions.length > 0) {
				const today = new Date();
				setRemainingDaysStr(calculateDuration(new Date(transactions[0].startDate), today).toString());
			}
		};

		// * Обновляет сразу при монтировании
		updateRemainingTime();

		// * Обновление каждую минуту
		const interval = setInterval(updateRemainingTime, 60000);

		// * Очистка интервала
		return () => clearInterval(interval);
	}, [tariff, tariffTransactions]);

	return (
		<div className={css.wrapper}>
			<div>
				<div className={css.description}>Тарифный план</div>
				<div className={css.tariff_name}>
					{tariff?.name ? tariffMapData.get(tariff.name) : 'Отсутствует'}
				</div>
			</div>
			<div>
				{tariff?.name && (
					<div className={cx(css.description, css.expiration)}>
						{remainingDaysStr}
						{' '}
						до истечения тарифа
					</div>
				)}
				{children}
			</div>
		</div>
	);
};
