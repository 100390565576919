/* Опции для выбора LineHeight */
enum LineHeight {
	LH1 = 1,
	LH1_2 = 1.2,
	LH1_HALF = 1.5,
	LH1_7 = 1.7,
	LH2 = 2,
	LH2_2 = 2.2,
	LH2_HALF = 2.5,
	LH2_7 =2.7,
	LH3 = 3,
}

export default LineHeight;
