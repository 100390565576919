import cx from 'classnames';
import { ITariff } from '../../../../../../../entities/tariff/api/types';
import css from './active-status.module.scss';

interface AdminUserActiveStatusProps {
	tariff: ITariff | null | undefined;
}

export const AdminUserActiveStatus = ({ tariff }: AdminUserActiveStatusProps) => {
	const isActive = !!tariff;

	return (
		<div className={cx(css.wrapper, isActive ? css.active : css.disabled)}>
			{isActive ? 'Активный' : 'Заблокирован'}
		</div>
	);
};
