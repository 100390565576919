import React, { useMemo } from 'react';
import css from './search-category-bar.module.scss';
import SearchInput from '../../../components/ui/searchInput/SearchInput';
import { TagDropdownWrapper } from '../../tag-dropdown-wrapper';
import useCatchEnterClick from '../../../hooks/useCatchEnterClick';
import { useSearchCategoryDropdownVisibility } from '../libs/useSearchCategoryDropdownVisibility';
import { notificationError } from '../../../components/Notifications/callNotifcation';
import { ICategoryData } from '../../../entities/modules/category/api/types';
import { CreateCategoryDropdown } from '../../create-category-dropdown';

interface SearchCategoryBarProps {
	search: string,
	templateCategories: ICategoryData[],
	categories: ICategoryData[] | undefined,
	setSearch: (value: string) => void,
	bindCategory: (category: ICategoryData) => Promise<void>,
	createCategory: (value: string) => Promise<ICategoryData | null>,
}

export const SearchCategoryBar = ({
	categories,
	search,
	bindCategory,
	createCategory,
	setSearch,
	templateCategories,
}: SearchCategoryBarProps) => {
	const selectedCategories = useMemo(() => [...templateCategories.map(el => el.id)], [templateCategories]);

	const {
		hideDropDown,
		showDropDown,
		searchBlockRef,
		isDropdownVisible,
	} = useSearchCategoryDropdownVisibility();

	const handleSearch = (text: string) => {
		if (!isDropdownVisible) showDropDown();

		setSearch(text);
	};

	const onCreateCategory = async () => {
		try {
			const res = await createCategory(search);
			if (res) {
				await bindCategory(res);
			}

			setSearch('');
			hideDropDown();
		} catch (e) {
			notificationError('Произошла ошибка при создании категории', 'Не удалось создать категорию');
		}
	};

	const onCategoryClick = async (category: ICategoryData | undefined) => {
		if (category && !selectedCategories.includes(category.id)) {
			await bindCategory(category);
			hideDropDown();
		}
	};

	useCatchEnterClick(onCreateCategory, isDropdownVisible && !!search && !categories?.length);
	useCatchEnterClick(
		() => onCategoryClick(categories && categories[0]),
		isDropdownVisible && !!search && categories?.length === 1,
	);

	return (
		<div ref={searchBlockRef} className={css.dropdown_wrapper}>
			<SearchInput
				placeholder="Поиск"
				searchValue={search}
				onClick={showDropDown}
				onChange={handleSearch}
			/>
			<TagDropdownWrapper isDropdownVisible={isDropdownVisible}>
				<CreateCategoryDropdown
					data={categories}
					createCategory={onCreateCategory}
					onCategoryClick={onCategoryClick}
					creatingCategoryName={search}
					selectedCategories={selectedCategories}
				/>
			</TagDropdownWrapper>
		</div>
	);
};
