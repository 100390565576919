import React from 'react';
import { useDispatch } from 'react-redux';
import css from './group.module.scss';
import {
	setBindingGroupId,
	setDeletingTagGroupId,
	setEditingTagGroupId,
	setIsBindingTagGroupModalActive,
	setIsDeletingTagGroupModalActive,
	setIsEditTagGroupModalActive,
} from '../../../../../../../../redux/reducers/module/tagGroupReducer';
import TagList from './tagList/TagList';
import EditBtns from './editBtns/EditBtns';
import AddBtn from '../../../../../../../ui/addBtn/AddBtn';
import { tagGroupAPI } from '../../../../../../../../entities/modules/tag-group/api/api';
import { ITagData } from '../../../../../../../../entities/modules/tag/api/types';

interface GroupProps {
	group: ITagData,
}

const Group = ({
	group,
}: GroupProps) => {
	const dispatch = useDispatch();

	const {
		name,
		id,
	} = group;

	const { data: tagsData } = tagGroupAPI.useTagsQuery({
		group: id.toString(),
		page: 1,
		limit: 127,
	});

	const openEditingTagGroupModal = () => {
		dispatch(setEditingTagGroupId(id));
		dispatch(setIsEditTagGroupModalActive(true));
	};

	const openDeletingTagGroupModal = () => {
		dispatch(setDeletingTagGroupId(id));
		dispatch(setIsDeletingTagGroupModalActive(true));
	};

	const onBindingTag = () => {
		dispatch(setBindingGroupId(id));
		dispatch(setIsBindingTagGroupModalActive(true));
	};

	return (
		<div className={css.wrapper}>
			<div className={css.main}>
				<div className={css.title}>
					<div className={css.text}>{name}</div>
					<EditBtns
						openEditingTagGroupModal={openEditingTagGroupModal}
						openDeletingTagGroupModal={openDeletingTagGroupModal}
					/>
				</div>
				<TagList
					groupId={Number(id)}
					tagsData={tagsData?.tags}
				/>
			</div>
			<AddBtn btnText="Добавить тег в группу" onBtnClick={onBindingTag} />
		</div>
	);
};

export default Group;
