import { teamAPI } from '../../../../entities/team/api/api';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setIsTeamUserSelectionModalActive } from '../../../../redux/reducers/teamReducer';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useMultipleTeamUsers = () => {
	const dispatch = useAppDispatch();

	const {
		bindingTeamId,
		isTeamUserSelectionModalActive,
	} = useAppSelector(state => state.team);

	const [bind, { isLoading: isBinding }] = teamAPI.useBindMutation();

	const { data: availableUsers } = teamAPI.useGetAvailableAccountsQuery({
		id: bindingTeamId || '',
	}, {
		skip: !bindingTeamId,
	});

	const handleIsTeamUserSelectionModalActive = (value: boolean) => {
		dispatch(setIsTeamUserSelectionModalActive(value));
	};

	const onBind = async (emails: string[]) => {
		if (bindingTeamId && emails) {
			try {
				await bind({
					team: bindingTeamId,
					emails,
				})
					.unwrap();

				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				if (emails.length > 1) {
					notificationSuccess('Вы успешно добавили пользователей!');
				} else {
					notificationSuccess('Вы успешно добавили пользователя!');
				}
				handleIsTeamUserSelectionModalActive(false);
			} catch (e) {
				if (emails.length > 1) {
					notificationError('Возникла ошибка при добавлении пользователей...');
				} else {
					notificationError('Возникла ошибка при добавлении пользователя!');
				}
			}
		}
	};

	return {
		isTeamUserSelectionModalActive,
		availableUsers,
		onBind,
		handleIsTeamUserSelectionModalActive,
		isBinding,
	};
};
