import InterfacePanel from '../../../InterfacePanel';
import NamedColorSelector from '../NamedColorSelector';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import LineSelect from './LineSelect';
import LineStyle from '../../../../graphic/line/LineStyle';
import LineComponent from '../../../../components/line/LineComponent';
import Utils from '../../../../utils/impl/Utils';
import ILineTexture from '../../../../graphic/line/ILineTexture';

/**
 * Панель для управления характеристиками фигур.
 */
class LinePanel extends InterfacePanel {
	private readonly CSS_ELEMENT_CLASS_NAME = 'propertyBar__figure figure-panel';
	private readonly HEADER_TEXT = 'Линия';

	private readonly fillInput: NamedColorSelector;
	private readonly borderWidthInput: HTMLInputElement;
	private readonly lineTypeSelect: LineSelect;

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.setRootElementClassName(this.CSS_ELEMENT_CLASS_NAME);
		this.setHeader(this.HEADER_TEXT);

		this.fillInput = new NamedColorSelector({
			containerClassName: 'figure-panel__fill',
			textClassName: '',
			onSelectColorListener: this.onBackgroundChange,
			text: '',
			isDisableTransparent: false,
		});

		this.borderWidthInput = HTMLGenerator.getInput({
			className: 'figure-panel__border-width-input',
			type: 'number',
			placeholder: '1',
			fnOnInput: this.onLineWidthChange,
		});

		this.lineTypeSelect = new LineSelect(this);
		this.lineTypeSelect.setValue(this.lineTypeSelect.solid, LineStyle.SOLID);

		const borderColorElement = this.fillInput.getElement();
		borderColorElement.append(
			this.borderWidthInput,
			this.lineTypeSelect.getElement(),
		);

		this.rootElement.append(borderColorElement);
	}

	private onBackgroundChange = () => {
		const selectedColor = this.fillInput.getColor();
		this.useCases.setFocusLineBackground(selectedColor);
	};

	protected onLineWidthChange = () => {
		let width = +this.borderWidthInput.value;
		if (width < 0) {
			width = 1;
		}
		this.useCases.setFocusLineWidth(width);
	};

	public onBorderStyleChange = () => {
		const style = this.lineTypeSelect.getValue();
		this.useCases.setFocusLineStyle(style);
	};

	public sync = (components: LineComponent[]) => {
		const textures = components
			.map(component => component.getGraphics()
				.map(graphic => graphic.getTexture())
				.flat())
			.flat();
		const sameProperties = Utils.Object.checkFieldsEquality<ILineTexture>(...textures);

		if (sameProperties.style !== null) {
			this.lineTypeSelect.activeValue = sameProperties.style as LineStyle;
		}
		if (sameProperties.thickness !== null) {
			this.borderWidthInput.value = sameProperties.thickness.toString();
		}
		if (sameProperties.background !== null) {
			this.fillInput.setColor(sameProperties.background as string);
		}
	};

	private setHeader = (text: string) => {
		const headerElement = HTMLGenerator.getH4({
			className: 'figure-panel__title',
			text,
		});
		this.rootElement.append(headerElement);
	};
}

export default LinePanel;
