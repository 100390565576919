import React from 'react';
import css from './pinInputBlock.module.scss';

interface PinInputBlockProps {
	digits: Array<string>,
	handleChange: (index: number, enewValue: string) => void,
	handleKeyDown: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void,
	inputRefs: React.MutableRefObject<HTMLInputElement[] | null[]>
}

const PinInputBlock: React.FC<PinInputBlockProps> = ({
	digits,
	handleChange,
	handleKeyDown,
	inputRefs,
}: PinInputBlockProps) => {
	const setRef = (elem: HTMLInputElement | null, index: number) => {
		inputRefs.current[index] = elem;
	};

	return (
		<div className={css.wrapper}>
			{
				digits.map((digit, index) => (
					<input
						key={(index + 1).toString()}
						value={digit}
						id={(index + 1).toString()}
						onChange={e => handleChange(index, e.target.value)}
						onKeyDown={e => handleKeyDown(index, e)}
						ref={el => setRef(el, index)}
						className={css.pin_item}
					/>
				))
			}
		</div>
	);
};

export default PinInputBlock;
