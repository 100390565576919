import InterfacePanel from '../../../InterfacePanel';
import ModulesTool from './ModulesTool';

abstract class ModulesAction extends InterfacePanel {
	public modulesTool: ModulesTool;

	protected constructor() {
		super();
		this.modulesTool = new ModulesTool();
		this.setRootElementClassName('panel-modules');
		this.rootElement.append(this.modulesTool.getElement());
	}
}

export default ModulesAction;
