import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { URI_ADMIN_ACCOUNT } from './consts';
import { IAdminAccount, IGetAdminAccountsRequest, IGetAdminAccountsResponse } from './types';

export const adminAPI = createApi({
	reducerPath: 'adminAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['admin'],
	endpoints: builder => ({
		getAccounts: builder.query<IGetAdminAccountsResponse, IGetAdminAccountsRequest>({
			query: (data) => ({
				url: URI_ADMIN_ACCOUNT,
				method: 'GET',
				params: data,
			}),
			providesTags: ['admin'],
		}),
		getAccount: builder.query<IAdminAccount, { email: string }>({
			query: ({ email }) => ({
				url: `${URI_ADMIN_ACCOUNT}/${email}`,
				method: 'GET',
			}),
			providesTags: ['admin'],
		}),
	}),
});
