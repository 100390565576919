import React from 'react';
import { ReactComponent as MenuIcon } from '../../images/more.svg';
import { ReactComponent as TrashIcon } from '../../../../../../shared/images/black-trash.svg';
import css from './menu.module.scss';
import AutoCloseActionsCards from '../../../../../ui/autoclose-actions-cards/autoclose-actions-cards';
import { setDeletingUserEmail, setIsDeleteUserModalActive } from '../../../../../../redux/reducers/usersReducer';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../../Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../../shared/libs/redux';
import { checkUsersRightsInterface } from '../../../../../../helpers/usersRights';

interface UserCardMenuProps {
	setActive: (value: boolean) => void,
	isActive: boolean,
	email: string,
	statusRights: checkUsersRightsInterface,
}

export const UserCardMenu = ({
	setActive,
	isActive,
	email,
	statusRights,
}: UserCardMenuProps) => {
	const dispatch = useAppDispatch();

	const deleteUser = () => {
		if (statusRights.delete) {
			dispatch(setDeletingUserEmail(email));
			dispatch(setIsDeleteUserModalActive(true));
		} else {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		}
	};

	const data = [
		{
			text: 'Удалить пользователя',
			smile: <TrashIcon />,
			onClick: deleteUser,
		},
	];

	return (
		<div>
			<div role="presentation" className={css.icon} onClick={() => setActive(true)}>
				<MenuIcon />
			</div>
			<AutoCloseActionsCards
				onClose={() => setActive(false)}
				isActive={isActive}
				cssClassMain={css.menu}
				items={data}
			/>
		</div>
	);
};
