import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, providesList } from '../../../shared/api/api';
import { URI_MANAGER } from './consts';
import { ICreateManagerRequest, IGetManagersListResponse } from './types';

export const managerAPI = createApi({
	reducerPath: 'managerAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['managers'],
	endpoints: builder => ({
		create: builder.mutation<void, ICreateManagerRequest>({
			query: ({
				legalEntity,
				...data
			}) => ({
				url: URI_MANAGER(legalEntity),
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['managers'],
		}),
		getAll: builder.query<IGetManagersListResponse, { legalEntity: string }>({
			query: ({ legalEntity }) => ({
				url: URI_MANAGER(legalEntity),
				method: 'GET',
			}),
			providesTags: (result) => providesList(result?.managers, 'managers'),
		}),
	}),
});
