import SketchManipulator from './SketchManipulator';
import IGraphicFactory from '../Sketch/factories/graphic/IGraphicFactory';
import IPagesComponentTree from '../Sketch/component-tree/IPagesComponentTree';
import IComponentFactory from '../Sketch/factories/component/IComponentFactory';

/**
 * Реализация конструктора скетчей с дополнительными возможностями работы с многостраничными структурами.
 */
abstract class MultiPageManipulator<
	ComponentTreeType extends IPagesComponentTree,
	GraphicFactoryType extends IGraphicFactory,
	ComponentFactoryType extends IComponentFactory,
	UseCasesType,
	SpatialAreaTreeType,
>
	extends SketchManipulator<
		ComponentTreeType,
		GraphicFactoryType,
		ComponentFactoryType,
		UseCasesType,
		SpatialAreaTreeType
	> {
	protected constructor(manipulatorContainer: HTMLDivElement) {
		super(manipulatorContainer);
	}
}

export default MultiPageManipulator;
