import React from 'react';
import { ReactComponent as AttentionIcon } from './images/attention.svg';
import { useRedirectButton } from '../../../shared/libs/useRedirectButton';
import { DEFAULT_MANUAL_REDIRECT_URL, manualRedirectData } from './model/data';
import { CollectionTab } from '../../../shared/ui';

export const ManualButton = () => {
	const { onClick } = useRedirectButton(DEFAULT_MANUAL_REDIRECT_URL, manualRedirectData);

	return (
		<CollectionTab
			isActive={false}
			setTab={onClick}
			tab={{
				link: '',
				name: 'Инструкции',
				picture: <AttentionIcon />,
			}}
			index={1}
		/>
	);
};
