import React from 'react';
import { NewModal } from '../modals/new-modal';
import { ModalTitle } from '../../../shared/ui/modal-title/modal-title';
import css from './delete-modal-wrapper.module.scss';
import { MainButton } from '../main-button/main-button';
import useCatchEnterClick from '../../../hooks/useCatchEnterClick';

interface DeleteModalProps {
	description: string,
	isActive: boolean,
	setIsActive: (value: boolean) => void,
	onDelete: () => void,
	isLoading?: boolean,
	title?: string,
}

export const DeleteModalWrapper = ({
	title,
	isActive,
	onDelete,
	isLoading,
	setIsActive,
	description,
}: DeleteModalProps) => {
	const closeModal = () => setIsActive(false);

	useCatchEnterClick(onDelete, isActive);

	return (
		<NewModal styles={css.wrapper} active={isActive} closeModal={setIsActive}>
			<ModalTitle>
				{title || 'Подтверждение удаления'}
			</ModalTitle>
			<span className={css.description}>
				{description}
			</span>
			<div className={css.btn_block}>
				<MainButton
					btnStyle="bright"
					type="button"
					onClick={closeModal}
				>
					Нет, не хочу
				</MainButton>
				<MainButton
					disabled={isLoading || false}
					btnStyle="bright"
					type="button"
					onClick={onDelete}
				>
					Да, хочу
				</MainButton>
			</div>
		</NewModal>
	);
};
