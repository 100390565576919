import React, { useCallback, useState } from 'react';
import CreateRole from '../createModalBody/CreateRole';
import {
	setChoicedEmoji,
	setCreateRoleBgColor,
	setIsRoleCreateModalActive,
} from '../../../../../redux/reducers/usersReducer';
import { ERROR_MESSAGE_LIST, notificationError, notificationSuccess } from '../../../../Notifications/callNotifcation';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { roleAPI } from '../../../../../entities/role/api/api';
import { NewModal } from '../../../../ui/modals/new-modal';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { accountRolesAPI } from '../../../../../entities/user/role/api/api';

interface RoleCreateModalProps {
	statusRights: checkUsersRightsInterface,
}

const RoleCreateModal = ({
	statusRights,
}: RoleCreateModalProps) => {
	const dispatch = useAppDispatch();

	const choicedEmoji = useAppSelector(state => state.user.choicedEmoji);
	const isActive = useAppSelector(state => state.user.modalsActive.isRoleCreateModalActive);
	const createRoleBgColor = useAppSelector(state => state.user.createRoleBgColor);

	const [createRoleName, setCreateRoleName] = useState('');

	const handleCreateRoleBgColor = (color: string) => {
		dispatch(setCreateRoleBgColor(color));
	};

	const resetBgColor = () => {
		handleCreateRoleBgColor('#ffffff');
	};

	const handleChoicedEmoji = (emoji: string | null) => {
		dispatch(setChoicedEmoji(emoji));
	};

	const handleCreateRoleModal = (value: boolean) => {
		dispatch(setIsRoleCreateModalActive(value));
	};

	const [create, { isLoading }] = roleAPI.useCreateMutation();

	// * При создании роли
	const onCreateRole = useCallback(async () => {
		if (statusRights.registration && choicedEmoji) {
			try {
				await create({
					name: createRoleName,
					emoji: choicedEmoji,
					background: createRoleBgColor,
				})
					.unwrap();
				dispatch(accountRolesAPI.util?.invalidateTags(['roles']));
				notificationSuccess('Роль создана успешно!');
				handleCreateRoleModal(false);
			} catch (err) {
				notificationError('Произошла ошибка при создании роли...');
			} finally {
				setCreateRoleName('');
				resetBgColor();
				handleChoicedEmoji(null);
			}
		} else {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'У вас недостаточно прав для совершения этого действия!');
			setCreateRoleName('');
			resetBgColor();
			handleChoicedEmoji(null);
		}
	}, [statusRights, choicedEmoji, createRoleName, createRoleBgColor]);

	return (
		<NewModal active={isActive} closeModal={handleCreateRoleModal}>
			<CreateRole
				isLoading={isLoading}
				onCreating={onCreateRole}
				createRoleName={createRoleName}
				isActive={isActive}
				setCreateRoleName={setCreateRoleName}
			/>
		</NewModal>
	);
};

export default RoleCreateModal;
