import { useRef } from "react";

export interface HandleInputSizeI {
	initialInputWidth: number;
	fontSize: number;
}

export const useHandleInputSize = (setValue: (value: string) => void, inputData: HandleInputSizeI) => {
	const {
		initialInputWidth,
		fontSize
	} = inputData;

	const inputRef = useRef<HTMLInputElement | null>(null);

	const handleInputChange = (text: string) => {
		if (inputRef.current) {
			if (text.length === 0) {
				inputRef.current.style.width = initialInputWidth + "px";
			} else {
				const tempElement = document.createElement("span"); // * Создаем временный элемент
				// * Задаем ему размер текста, как у инпута
				tempElement.style.fontSize = fontSize + "px";
				tempElement.style.fontFamily = "500";
				tempElement.innerText = text; // * Вставляем в него текст
				document.body.appendChild(tempElement); // * Добавляем в DOM
				const inputWidth = tempElement.offsetWidth; // * Получаем ширину
				document.body.removeChild(tempElement); // * Удаляем временный элемент
				inputRef.current.style.width = `${inputWidth + 23}px`; // * Устанавливаем ширину инпута
			}
			setValue(text);
		}
	};

	return {
		inputRef,
		handleInputChange,
	};
};
