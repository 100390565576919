import IDescartesPosition from '../../../utils/IDescartesPosition';
import IFrameArea from './spatial-area/IFrameArea';
import { AnySpatialArea } from '../../../Types';

class SpatialQuadrantsNode {
	public readonly objects: AnySpatialArea[];

	public leftTopQuadrant: this | null;
	public rightTopQuadrant: this | null;
	public rightBottomQuadrant: this | null;
	public leftBottomQuadrant: this | null;

	private visualElement: HTMLElement | null;

	constructor(
		private readonly x: number,
		private readonly y: number,
		private width: number,
		private height: number,
	) {
		this.objects = [];
		this.visualElement = null;
		this.leftTopQuadrant = null;
		this.rightTopQuadrant = null;
		this.rightBottomQuadrant = null;
		this.leftBottomQuadrant = null;
	}

	public isCrossPoint = (position: IDescartesPosition): boolean => {
		if (this.x === null || this.y === null || this.width === null || this.height === null) {
			throw new Error('node not initialized');
		}
		if (position.x < this.x || position.x > this.x + this.width) {
			return false;
		}
		return !(position.y < this.y || position.y > this.y + this.height);
	};

	public getArea = (): IFrameArea => ({
		x: this.x,
		y: this.y,
		width: this.width,
		height: this.height,
		rotate: 0,
	});

	public setWidth = (width: number) => {
		this.width = width;
	};

	public setHeight = (height: number) => {
		this.height = height;
	};

	/** Возвращает HTMLElement для визуализации узла. */
	public getVisualizeElement = (): HTMLElement => {
		if (this.visualElement !== null) {
			this.visualElement.style.width = `${this.width}px`;
			this.visualElement.style.height = `${this.height}px`;
			return this.visualElement;
		}

		this.visualElement = document.createElement('div');
		this.visualElement.style.position = 'absolute';
		this.visualElement.style.pointerEvents = 'none';
		this.visualElement.style.left = `${this.x}px`;
		this.visualElement.style.top = `${this.y}px`;
		this.visualElement.style.width = `${this.width}px`;
		this.visualElement.style.height = `${this.height}px`;
		if (this.objects.length === 0) {
			this.visualElement.style.border = '1px solid #000000';
		} else {
			this.visualElement.style.border = '1px solid red';
		}
		return this.visualElement;
	};

	public addObject = (obj: AnySpatialArea) => {
		this.objects.push(obj);
	};

	public clear = () => {
		this.objects.length = 0;
		this.leftTopQuadrant = null;
		this.rightTopQuadrant = null;
		this.rightBottomQuadrant = null;
		this.leftBottomQuadrant = null;
	};

	/** Разбивает текущую область на квадранты. Не может разбить меньше минимального значения переданной стороны. */
	public split = (minSide: number): boolean => {
		const width = this.width / 2;
		const height = this.height / 2;

		if (width < minSide || height < minSide) {
			return false;
		}

		if (this.leftTopQuadrant === null) {
			this.leftTopQuadrant = new SpatialQuadrantsNode(this.x, this.y, width, height) as this;
		}
		if (this.rightTopQuadrant === null) {
			this.rightTopQuadrant = new SpatialQuadrantsNode(this.x + width, this.y, width, height) as this;
		}
		if (this.rightBottomQuadrant === null) {
			this.rightBottomQuadrant = new SpatialQuadrantsNode(this.x, this.y + height, width, height) as this;
		}
		if (this.leftBottomQuadrant === null) {
			this.leftBottomQuadrant = new SpatialQuadrantsNode(this.x + width, this.y + height, width, height) as this;
		}

		return true;
	};
}

export default SpatialQuadrantsNode;
