import css from './changeable-tag-bar.module.scss';
import { ITemplateTag } from '../../../entities/templates/tags/api/types';
import { CreateTagInput } from './create-tag-input/input';
import { ChangeableTagList } from './tag-list/changeable-tag-list';
import { ITagData } from '../../../entities/modules/tag/api/types';

interface ChangeableTagBarProps {
	activeTags?: ITemplateTag[] | ITagData[],
	disabledTags?: ITemplateTag[] | ITagData[],
	unbindTag: (tag: number) => void,
	onCreateTag: (name: string) => void,
}

export const ChangeableTagBar = ({
	onCreateTag,
	unbindTag,
	disabledTags,
	activeTags,
}: ChangeableTagBarProps) => (
	<div className={css.wrapper}>
		<CreateTagInput onCreateTag={onCreateTag} />
		<ChangeableTagList
			deleteTag={unbindTag}
			activeTags={activeTags || []}
			disabledTags={disabledTags || []}
		/>
	</div>
);
