export const renderCountWord = (count: number, words: string[]) => {
	if (count === undefined) {
		return `0 ${words[1]}`;
	}

	const lastDigit = count % 10;
	const lastTwoDigits = count % 100;

	if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
		return `${count} ${words[1]}`;
	}

	if (lastDigit === 1) {
		return `${count} ${words[0]}`;
	}

	if (lastDigit >= 2 && lastDigit <= 4) {
		return `${count} ${words[2]}`;
	}

	return `${count} ${words[1]}`;
};
