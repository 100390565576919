import ManipulatorArea, { IManipulatorAreaDependencies } from './ManipulatorArea';
import SpatialAreaTree from './spatial-quadrants/spatial-tree/SpatialAreaTree';
import IComponentTree from '../component-tree/IComponentTree';

interface IHighlightAreaDependencies extends IManipulatorAreaDependencies {
	areaTree: SpatialAreaTree,
	componentTree: IComponentTree,
}

/**
 * Область выделения компонентов курсором пользователя.
 */
class HighlightArea extends ManipulatorArea<IHighlightAreaDependencies> {
	private readonly AREA_CLASS_NAME = 'pages-container__highlight-area';
	private readonly DELAY_HIGHLIGHT_PX = 4;

	constructor() {
		super();
		this.setAreaClassName(this.AREA_CLASS_NAME);
		this.setDelayCreateAreaPx(this.DELAY_HIGHLIGHT_PX);
		this.addPostUpdateAreaEvent(this.registerComponentCross);
	}

	private registerComponentCross = () => {
		const components = this.dependencies.componentTree.getComponents();
		const highlightArea = this.getArea();
		const crossComponents = this.dependencies.areaTree.getCrossComponentsFromArea(highlightArea);

		if (crossComponents === null) {
			components.forEach(component => {
				component.disableFocus();
			});
			return;
		}

		components.forEach(component => {
			if (crossComponents.includes(component)) {
				component.enableFocus();
			} else {
				component.disableFocus();
			}
		});
	};
}

export default HighlightArea;
