import { templateTagAPI } from '../../../../../entities/templates/tags/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useCreateTemplateTag = () => {
	const [createTag, { isLoading }] = templateTagAPI.useCreateMutation();

	const onCreateTag = async (name: string) => {
		try {
			const res = await createTag({ name })
				.unwrap();
			notificationSuccess('Тег создан успешно!');
			return res.tag;
		} catch (e) {
			notificationError('Произошла ошибка при создании тега...');

			return null;
		}
	};

	return {
		isLoading,
		onCreateTag,
	};
};
