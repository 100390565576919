import React, { useState } from 'react';
import css from './user-card.module.scss';
import { ReactComponent as MoreIcon } from '../../../card/images/more.svg';
import { copyToClipboard } from '../../../../../helpers/copyText';
import { ReactComponent as TrashIcon } from '../../../../../shared/images/black-trash.svg';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../../components/Notifications/callNotifcation';
import AutoCloseActionsCards from '../../../../../components/ui/autoclose-actions-cards/autoclose-actions-cards';
import { IAccount } from '../../../../../entities/user/account/api/types';

interface HiddenUserCardProps {
	untieUser: (email: string) => void,
	data: IAccount,
	deleteRight: boolean,
}

export const HiddenUserCard = ({
	data,
	untieUser,
	deleteRight,
}: HiddenUserCardProps) => {
	const {
		name,
		photo,
		phone,
		email,
	} = data;

	const [isShowUntieWindow, setIsShowUntieWindow] = useState(false);

	// * При увольнении пользователя
	const onUntieUser = () => {
		if (deleteRight) {
			untieUser(email);
			setIsShowUntieWindow(false);
		} else {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'У вас недостаточно прав для совершения этого действия!');
		}
	};

	// * При закрытии вспылвающего окна
	const onCloseUntieWindow = () => {
		setIsShowUntieWindow(false);
	};

	const actionsWithCardsItems = [
		{
			text: 'Удалить пользователя',
			smile: <TrashIcon />,
			onClick: onUntieUser,
		},
	];

	return (
		<div className={css.wrapper}>
			<div className={css.block}>
				<div className={css.shortInfo}>
					<img
						src={`/api/file/user/${photo}`}
						className={css.picture}
						alt="user"
					/>
					<div className={css.name}>{name}</div>
				</div>
				<div className={css.mainInfo}>
					<div className={css.phone}>{phone}</div>
					<div
						className={css.email}
						onClick={() => copyToClipboard(email)}
						role="presentation"
					>
						{email}
					</div>
				</div>
			</div>
			{
				deleteRight && (
					<div
						className={css.more}
						onClick={() => setIsShowUntieWindow(!isShowUntieWindow)}
						role="presentation"
					>
						<MoreIcon />
						<AutoCloseActionsCards
							onClose={onCloseUntieWindow}
							isActive={isShowUntieWindow}
							items={actionsWithCardsItems}
						/>
					</div>
				)
			}
		</div>
	);
};
