import MouseOffsetObserver from '../utils/observers/MouseOffsetObserver';
import CursorView from './CursorView';
import MousePositionObserver from '../utils/observers/MousePositionObserver';
import AreaSizeMutator from '../mechanics/spatial-quadrants/area-mutators/mutators/AreaSizeMutator';
import AreaPositionMutator from '../mechanics/spatial-quadrants/area-mutators/mutators/AreaPositionMutator';
import AreaTableColumnMutator from '../mechanics/spatial-quadrants/area-mutators/mutators/AreaTableColumnMutator';
import CellSelectionHandler from '../graphic/table/CellSelectionHandler';
import Dependent from '../utils/dependent/Dependent';
import IComponentTree from '../component-tree/IComponentTree';
import IDestructible from '../mechanics/IDestructible';
import IContextMenuHandler from '../mechanics/context-menu/upgrade/IContextMenuHandler';
import AreaPositionWithCloneMutator
	from '../mechanics/spatial-quadrants/area-mutators/mutators/AreaPositionWithCloneMutator';
import EditModeContext from '../mechanics/EditModeContext';
import PageTextOverflowObserver from '../utils/observers/PageTextOverflowObserver';

export interface ICursorHandlerDependencies {
	cursorView: CursorView,
	componentTree: IComponentTree,
	editModeContext: EditModeContext,
	mouseOffsetObserver: MouseOffsetObserver,
	mousePositionObserver: MousePositionObserver,
}

/**
 * Сущность для обработки движений и событий курсора.
 */
abstract class CursorHandler<Dependencies extends ICursorHandlerDependencies>
	extends Dependent<Dependencies>
	implements IDestructible {
	protected readonly areaSizeMutator: AreaSizeMutator;
	protected readonly tableColumnMutator: AreaTableColumnMutator;
	protected readonly cellSelectionHandler: CellSelectionHandler;
	private workAreaElement: HTMLElement;

	protected constructor() {
		super();

		this.areaSizeMutator = new AreaSizeMutator();
		this.cellSelectionHandler = new CellSelectionHandler();
		this.tableColumnMutator = new AreaTableColumnMutator();

		this.addPostInjectDependenciesListener(dependencies => {
			this.workAreaElement = dependencies.componentTree.getWorkAreaElement();
			this.workAreaElement.addEventListener('mousedown', this.onMouseDown);
			this.workAreaElement.addEventListener('dblclick', this.onDoubleClick);
			this.workAreaElement.addEventListener('contextmenu', this.onMouseContextMenu);

			this.cellSelectionHandler.connectDependencies({
				mousePositionObserver: dependencies.mousePositionObserver,
			});

			dependencies.mouseOffsetObserver.subscribe(this.onMouseMove);

			window.addEventListener('mouseup', this.onMouseUp);

			this.cellSelectionHandler.injectDependencies();
		});
	}

	public destruct = () => {
		window.removeEventListener('mouseup', this.onMouseUp);
		this.workAreaElement.removeEventListener('mousedown', this.onMouseDown);
		this.workAreaElement.removeEventListener('dblclick', this.onDoubleClick);
		this.workAreaElement.removeEventListener('contextmenu', this.onMouseContextMenu);
	};

	/**
	 * Обработчик события передвижения мыши.
	 * @param offsetX Сдвиг в px по горизонтали.
	 * @param offsetY Сдвиг в px по вертикали.
	 * @param event Событие мыши.
	 */
	protected abstract onMouseMove(offsetX: number, offsetY: number, event: MouseEvent): void;
	protected abstract onMouseContextMenu(ev: MouseEvent): void
	protected abstract onDoubleClick(ev: MouseEvent): void
	protected abstract onMouseDown(ev: MouseEvent): void
	protected abstract onMouseUp(ev: MouseEvent): void
}

export default CursorHandler;
