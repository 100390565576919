import React, { useCallback } from 'react';
import { setIsDeleteRoleModalActive } from '../../../../../redux/reducers/usersReducer';
import { notificationError, notificationSuccess } from '../../../../Notifications/callNotifcation';
import { roleAPI } from '../../../../../entities/role/api/api';
import { DeleteModalWrapper } from '../../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { accountAPI } from '../../../../../entities/user/account/api/api';
import { employeeAPI } from '../../../../../entities/employee/api/api';
import { adminAPI } from '../../../../../entities/admin/api/api';
import { accountRolesAPI } from '../../../../../entities/user/role/api/api';

const RoleDeleteConfirmModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useAppSelector(state => state.user.modalsActive.isDeleteRoleModalActive);
	const deletingRoleId = useAppSelector(state => state.user.deletingRoleId);

	const handleDeleteRoleModal = (value: boolean) => {
		dispatch(setIsDeleteRoleModalActive(value));
	};

	const [deleteRole] = roleAPI.useDeleteMutation();

	// * Удалить роль
	const onDeleteRole = useCallback(async () => {
		if (deletingRoleId) {
			try {
				await deleteRole({ id: deletingRoleId })
					.unwrap();
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(employeeAPI.util?.invalidateTags(['employee']));
				dispatch(accountRolesAPI.util?.invalidateTags(['roles']));
				handleDeleteRoleModal(false);
				notificationSuccess('Роль удалена успешно!');
			} catch (e) {
				notificationError('Произошла ошибка при удалении роли...');
			}
		}
	}, [deletingRoleId]);

	return (
		<DeleteModalWrapper
			description="Вы действительно хотите удалить роль без возможности восстановления?"
			isActive={isActive}
			setIsActive={handleDeleteRoleModal}
			onDelete={onDeleteRole}
		/>
	);
};

export default RoleDeleteConfirmModal;
