import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../../utils/SvgCollection';
import Utils from '../../../../../utils/impl/Utils';
import ElementContainer from '../../../../../utils/ElementContainer';
import HTMLElementName from '../../../../../utils/HTMLElementName';

/**
 * Модуль интерфейса для отображения текущего размера выбранных ячеек размера таблицы при её создании.
 */
class CurrentTableSize extends ElementContainer<HTMLDivElement> {
	private readonly ELEMENT_CLASS_NAME = 'table-constructor__values';
	private readonly rowElement: HTMLElement;
	private readonly colElement: HTMLElement;

	private currentRowCount: number;
	private currentColumnCount: number;

	constructor() {
		super(HTMLElementName.DIV);
		this.currentRowCount = 0;
		this.currentColumnCount = 0;

		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
		this.rowElement = HTMLGenerator.getSpan({ text: '1' });
		this.colElement = HTMLGenerator.getSpan({ text: '1' });

		this.rootElement.append(this.rowElement);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.CROSS);
		this.rootElement.append(this.colElement);
	}

	public setValues = (rowCount: number, columnCount: number) => {
		this.currentRowCount = rowCount;
		this.currentColumnCount = columnCount;
		this.setElementValues(rowCount, columnCount);
	};

	public getRowCount = (): number => this.currentRowCount;
	public getColumnCount = (): number => this.currentColumnCount;

	private setElementValues = (row: number, col: number): void => {
		this.rowElement.textContent = row.toString();
		this.colElement.textContent = col.toString();
	};
}

export default CurrentTableSize;
