import React from 'react';
import { CSSTransition } from 'react-transition-group';
import css from './dropdown.module.scss';
import { TagsScrollSearchList } from './list/tags-scroll-search-list';
import { tagGroupAPI } from '../../../../../entities/modules/tag-group/api/api';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';

interface TagsScrollSearchDropdownProps {
	groupId: string | undefined,
	currentIds: number[],
	onSelectTag: (id: number) => void,
}

export const TagsScrollSearchDropdown = React.forwardRef<HTMLDivElement, TagsScrollSearchDropdownProps>(({
	groupId,
	currentIds,
	onSelectTag,
}, ref) => {
	const { data: tagsData } = tagGroupAPI.useTagsQuery({
		page: 1,
		limit: 127,
		group: groupId?.toString() || '',
	});

	return (
		<div>
			<CSSTransition
				timeout={200}
				unmountOnExit
				nodeRef={ref}
				in={!!groupId}
				classNames={popUpTransitionClasses}
			>
				<div className={css.tags_wrapper} ref={ref}>
					{
						tagsData && tagsData.tags
							? (
								<TagsScrollSearchList
									tags={tagsData.tags}
									onSelectTag={onSelectTag}
									currentIds={currentIds}
								/>
							)
							: <div className={css.not_found}>Тегов не найдено...</div>
					}
				</div>
			</CSSTransition>
		</div>
	);
});
