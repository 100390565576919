import MagneticLine from './MagneticLine';
import DirectionVector from '../DirectionVector';
import PageGraphic from '../../../graphic/page/PageGraphic';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';

interface IMagneticPageSideLineProps {
	direction: DirectionVector,
	pageConfiguration: IFrameArea,
	parentElement: HTMLElement,
}

/**
 * Реализация магнитной линии основанных на границах страницы.
 */
class MagneticPageSideLine extends MagneticLine {
	constructor(props: IMagneticPageSideLineProps) {
		super(props.direction, props.parentElement);

		const { width, height } = props.pageConfiguration;
		switch (props.direction) {
		case DirectionVector.LEFT:
			this.setLocation(0, 0, height);
			break;
		case DirectionVector.RIGHT:
			this.setLocation(width, 0, height);
			break;
		case DirectionVector.TOP:
			this.setLocation(0, 0, width);
			break;
		case DirectionVector.BOTTOM:
			this.setLocation(0, height, width);
			break;
		case DirectionVector.MIDDLE_X:
			this.setLocation(0, height / 2, width);
			break;
		case DirectionVector.MIDDLE_Y:
			this.setLocation(width / 2, 0, height);
			break;
		default: break;
		}
	}
}

export default MagneticPageSideLine;
