import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { ILegalEntityItem } from '../../../../entities/legal-entity/api/types';
import { setIsCreateManagerModalActive, setIsManagersModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { IAccount } from '../../../../entities/user/account/api/types';

export const useMultipleLegalEntityManagers = () => {
	const dispatch = useAppDispatch();

	const legalEntityId = useAppSelector(state => state.legalEntity.addingManagerLegalEntityId);

	const isManagerSelectionActive = useAppSelector(state => state.legalEntity.isManagersModalListActive);

	const [users, setUsers] = useState<IAccount[]>();

	const legalEntityList = useAppSelector(state => state.legalEntity.legalEntityList);

	const handleCreateManagerModal = (value: boolean) => {
		dispatch(setIsCreateManagerModalActive(value));
	};
	const handleManagersModal = (value: boolean) => {
		dispatch(setIsManagersModalActive(value));
	};

	// * При клике на кнопку добавить менеджера
	const onAddingManager = () => {
		handleManagersModal(false);
		handleCreateManagerModal(true);
	};

	// * Сортировка данных
	const findUsersInCurrentLegalEntity = (data: ILegalEntityItem[]) => {
		if (legalEntityId) {
			const users = data?.filter(item => item.id === legalEntityId)[0].accounts;

			setUsers(users);
		}
	};

	useEffect(() => {
		if (legalEntityList) {
			findUsersInCurrentLegalEntity(legalEntityList);
		}
	}, [legalEntityId, legalEntityList]);

	return {
		isManagerSelectionActive,
		handleManagersModal,
		users,
		onAddingManager,
	};
};
