import {
	IEmoji, Token, TokenFormat, ITokenMarkdown, TokenType, IParserOptions, ITokenText,
} from './types';
import {
	isDelimiter, last, codePointAt, globalCodes,
} from './utils';
import { FontFamily, FontSize, TextAlign } from '../editor/types';

type MatchFn = (ch: number) => boolean;
export type Bracket = 'curly' | 'square' | 'round';

export const enum Quote {
    None = 0,
    Single = 1 << 0,
    Double = 1 << 1,
}

export default class ParserState {
	/** Опции, с которыми парсим текст */
	public options: IParserOptions;
	public startToken: Token;
	/** Текущая позиция парсера */
	public pos: number;

	/** Текстовая строка, которую нужно парсить */
	public string: string;

	/** Текущий аккумулированный формат  */
	public format: TokenFormat = 0;

	/** Список распаршенных токенов */
	public tokens: Token[] = [];

	/** Стэк открытых токенов форматирования */
	public formatStack: ITokenMarkdown[] = [];

	/** Позиция начала накапливаемого текстового фрагмента */
	public textStart = -1;

	/** Позиция конца накапливаемого текстового фрагмента */
	public textEnd = -1;

	/** Список эмоджи для текущего текстового токена */
	public emoji: IEmoji[] = [];

	/** Счётчик скобок */
	public brackets: Record<Bracket, number> = {
		round: 0,
		square: 0,
		curly: 0,
	};

	public quote: Quote = 0;

	/**
	 * @param str
	 * @param options
	 * @param pos Позиция, с которой нужно начинать парсинг
	 * @param startToken
	 */
	constructor(
		str: string,
		options: IParserOptions,
		pos = 0,
		startToken: Token = {
			format: TokenFormat.None,
			type: TokenType.Text,
			fontFamily: FontFamily.Default,
			fontSize: FontSize.Pt14,
			color: '#000000',
			textAlign: TextAlign.LEFT,
			sticky: true,
			lineHeight: 1.5,
			value: '',
		},
	) {
		this.string = str;
		this.options = options;
		this.pos = pos;
		this.startToken = startToken;
	}

	/**
     * Возвращает *code point* текущего символа парсера без смещения указателя
     */
	peek(): number {
		return codePointAt(this.string, this.pos);
	}

	/**
     * Возвращает *code point* текущего символа парсера и смещает указатель
     */
	next(): number {
		return this.hasNext() ? this.inc(this.peek()) : NaN;
	}

	/**
     * Возвращает код предыдущего символа без смещения указателя
     */
	peekPrev(): number {
		// XXX в идеале надо учитывать code points, но пока для текущих требований
		// парсера это не надо
		return this.string.charCodeAt(this.pos - 1);
	}

	/**
     * Вернёт `true` если позиция парсера не находится в конце потока и можно ещё
     * с него считывать данные
     */
	hasNext(): boolean {
		return this.pos < this.string.length;
	}

	/**
     * Проверяет, есть ли аккумулированный текст в состоянии
     */
	hasPendingText(): boolean {
		return this.textStart !== this.textEnd;
	}

	/**
     * Поглощает символ в текущей позиции парсера, если он соответствует `match`.
     * `match` может быть как кодом символа, так и функцией, которая принимает текущий
     * символ и должна вернуть `true` или `false`
     * Вернёт `true` если символ был поглощён
     */
	consume(match: number | MatchFn): boolean {
		const ch = this.peek();
		const ok = typeof match === 'function' ? match(ch) : ch === match;

		if (ok) {
			this.inc(ch);
		}

		return ok;
	}

	/**
     * Вызывает функцию `consume` до тех пор, пока текущий символ соответствует
     * условию `match`.
     * Вернёт `true` если было поглощение
     */
	consumeWhile(match: number | MatchFn): boolean {
		const start = this.pos;
		while (this.hasNext() && this.consume(match)) { /* */ }
		return this.pos !== start;
	}

	/**
     * Возвращает подстроку по указанным индексам
     */
	substring(from: number, to = this.pos): string {
		return this.string.substring(from, to);
	}

	/**
     * Добавляет указанный токен в вывод
     */
	push(token: Token): void {
		this.flushText(this.startToken);
		this.tokens.push(token);
	}

	/**
     * Проверяет, есть ли указанный формат в текущем состоянии
     */
	hasFormat(format: TokenFormat): boolean {
		return (this.format & format) === format;
	}

	/**
     * Добавляет указанный тип форматирования в состояние
     */
	addFormat(format: TokenFormat): void {
		this.format |= format;
	}

	/**
     * Удаляет указанный тип форматирования из состояния
     */
	removeFormat(format: TokenFormat): void {
		this.format ^= this.format & format;
	}

	/**
     * Поглощает текущий символ как накапливаемый текст
     */
	consumeText(): void {
		if (this.textStart === -1) {
			this.textStart = this.pos;
			this.textEnd = this.pos;
		}

		const ch = this.next();
		if (ch === globalCodes.SingleQuote) {
			this.quote ^= Quote.Single;
		} else if (ch === globalCodes.DoubleQuote) {
			this.quote ^= Quote.Double;
		}

		this.textEnd = this.pos;
	}

	/**
     * Записывает накопленный текст в токен и добавляет в массив токенов
     */
	public flushText(startToken: Token = {
		format: TokenFormat.None,
		type: TokenType.Text,
		fontFamily: FontFamily.Default,
		fontSize: FontSize.Pt14,
		color: '#000000',
		textAlign: TextAlign.LEFT,
		sticky: true,
		value: '',
		lineHeight: 1,

	}): void {
		if (this.hasPendingText()) {
			const token: Token = {
				...startToken,
				format: this.options.useFormat ? this.format : TokenFormat.None,
				value: this.substring(this.textStart, this.textEnd),
			};
			if (startToken.type === TokenType.Newline
				|| startToken.type === TokenType.Paragraph
				|| startToken.type === TokenType.RedLine
			) {
				token.type = TokenType.Text;
				(token as ITokenText).sticky = true;
			}

			this.tokens.push(token);
			this.textStart = -1;
			this.textEnd = -1;
		}
	}

	/**
     * Проверяет, находимся ли мы сейчас на границе слов
     */
	atWordBound(): boolean {
		// Для указанной позиции нам нужно проверить, что предыдущий символ или токен
		// является границей слов
		const { pos } = this;

		if (this.hasPendingText()) {
			return isDelimiter(this.peekPrev());
		}

		const lastToken = last(this.tokens);
		if (lastToken) {
			return lastToken.type === TokenType.Markdown || lastToken.type === TokenType.Newline;
		}

		return false;
	}

	markPending(textStart: number): void {
		if (!this.hasPendingText()) {
			this.textStart = textStart;
		}
		this.textEnd = this.pos;
	}

	/**
     * Смещает указатель на размер указанного кода символ вправо.
     */
	private inc(code: number): number {
		this.pos += code > 0xFFFF ? 2 : 1;
		return code;
	}
}

export function getQuoteType(ch: number): Quote {
	return ch === globalCodes.SingleQuote ? Quote.Single : Quote.Double;
}
