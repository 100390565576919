import { useAppDispatch } from '../../../../shared/libs/redux';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { teamTemplateAPI } from '../../../../entities/templates/team/api/api';

export const useEditTeamTemplateName = (template: string) => {
	const dispatch = useAppDispatch();

	const [edit, { isLoading }] = teamTemplateAPI.useUpdateMutation();

	const editName = async (name: string) => {
		try {
			await edit({
				name,
				template,
			})
				.unwrap();
			dispatch(teamTemplateAPI.util?.invalidateTags(['team']));
		} catch (e) {
			notificationError('Произошла ошибка при изменении названия шаблона...');
		}
	};

	return {
		editName,
		isLoading,
	};
};
