import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWorkshopTagScheme } from '../types/WorkshopTagScheme';

const initialState: IWorkshopTagScheme = {
	isDeleteModalActive: false,
	deletingTagId: undefined,

	createTagName: undefined,
	isCreateModalActive: false,

	editTagName: undefined,
	editingTagId: undefined,
	isEditingModalActive: false,

	unbindTagId: undefined,
	unbindModuleId: undefined,
	isUnbindingModalActive: false,
};

export const workshopTagSlice = createSlice({
	name: 'workshopTag',
	initialState,
	selectors: {
		getIsCreateModalActive: (state) => state.isCreateModalActive || false,
		getIsDeleteModalActive: (state) => state.isDeleteModalActive || false,
		getDeletingTagId: (state) => state.deletingTagId,
		getIsEditingModalActive: (state) => state.isEditingModalActive || false,
		getEditingTagId: (state) => state.editingTagId,
		getCreateTagName: (state) => state.createTagName || '',
		getEditTagName: (state) => state.editTagName || '',
		getIsUnbindingModalActive: (state) => state.isUnbindingModalActive || false,
		getUnbindTagId: (state) => state.unbindTagId || [],
		getUnbindModuleId: (state) => state.unbindModuleId,
	},
	reducers: {
		setIsCreateModalActive(state, action: PayloadAction<boolean>) {
			state.isCreateModalActive = action.payload;
		},
		setIsDeleteModalActive(state, action: PayloadAction<boolean>) {
			state.isDeleteModalActive = action.payload;
		},
		setDeletingTagId(state, action: PayloadAction<number | undefined>) {
			state.deletingTagId = action.payload;
		},
		setIsEditingTagModalActive(state, action: PayloadAction<boolean>) {
			state.isEditingModalActive = action.payload;
		},
		setEditingTagId(state, action: PayloadAction<number | undefined>) {
			state.editingTagId = action.payload;
		},
		setCreateTagName(state, action: PayloadAction<string>) {
			state.createTagName = action.payload;
		},
		setEditTagName(state, action: PayloadAction<string>) {
			state.editTagName = action.payload;
		},
		setIsUnbindingTagModalActive(state, action: PayloadAction<boolean>) {
			state.isUnbindingModalActive = action.payload;
		},
		setUnbindTagsIds(state, action: PayloadAction<number | undefined>) {
			state.unbindTagId = action.payload;
		},
		setUnbindModuleId(state, action: PayloadAction<string | undefined>) {
			state.unbindModuleId = action.payload;
		},
	},
});

export const { actions: workshopTagActions, reducer: workshopTagReducer } = workshopTagSlice;
