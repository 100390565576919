/**
 * Вернет элемент с блоком уведомления о пробном тарифе
 * @constructor
 */
export function notificationTrialBlock(notificationText: string, linkText: string, linkURL: string) {
	const baseClass = 'trialNotification';
	const rootElement = document.createElement('div');
	rootElement.classList.add(baseClass);

	const notificationElement = document.createElement('div');
	notificationElement.textContent = notificationText;
	notificationElement.classList.add(`${baseClass}__text`);

	const linkElement = document.createElement('a');
	linkElement.href = linkURL;
	linkElement.setAttribute('target', '_blank');
	linkElement.textContent = linkText;
	linkElement.classList.add(`${baseClass}__link`);

	rootElement.append(notificationElement, linkElement);
	return rootElement;
}
