import React from 'react';
import css from './user-list.module.scss';

const skeletonUsers = (count = 4) => {
	const users = [];

	for (let i = 0; i < count; i++) {
		users.push(
			{
				jsx: (
					<div className={css.skeleton_loading_card}>
						<div className={css.upper}>
							<div className={`${css.status} ${css.skeleton}`} />
							<div className={`${css.more} ${css.skeleton}`} />
						</div>
						<div className={css.main}>
							<div className={`${css.picture} ${css.skeleton}`} />
							<div className={`${css.name} ${css.skeleton}`} />
							<div className={`${css.info} ${css.skeleton}`} />
						</div>
						<div className={css.roles}>
							<div className={`${css.role} ${css.skeleton}`} />
							<div className={`${css.role} ${css.skeleton}`} />
							<div className={`${css.role} ${css.skeleton}`} />
						</div>
					</div>
				),
				id: i,
			},
		);
	}
	return users;
};

export default skeletonUsers;
