import React from 'react';
import { EntityCard } from '../../../../../../../../widgets/entity/card';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../../../../Notifications/callNotifcation';
import {
	setBindingTeamId,
	setDeletingTeamId,
	setIsTeamConfirmDeleteModalActive,
	setIsTeamUserConfirmDeleteModalActive,
	setIsTeamUserSelectionModalActive,
	setTeamUntieEmail,
	setTeamUntieId,
} from '../../../../../../../../redux/reducers/teamReducer';
import { useAppDispatch } from '../../../../../../../../shared/libs/redux';
import { checkUsersRightsInterface } from '../../../../../../../../helpers/usersRights';
import { ITeamItem } from '../../../../../../../../entities/team/api/types';

interface TeamCardProps {
	team: ITeamItem,
	statusRights: checkUsersRightsInterface,
}

export const TeamCard = ({
	statusRights,
	team,
}: TeamCardProps) => {
	const dispatch = useAppDispatch();

	const { id } = team;

	const onDeleteTeam = (id: string) => {
		if (!statusRights.delete) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setDeletingTeamId(id));
			dispatch(setIsTeamConfirmDeleteModalActive(true));
		}
	};

	const onUntieUser = (email: string, team: string) => {
		if (!statusRights.delete) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setTeamUntieId(team));
			dispatch(setTeamUntieEmail(email));
			dispatch(setIsTeamUserConfirmDeleteModalActive(true));
		}
	};

	const onBindingTeam = () => {
		if (!statusRights.registration) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setBindingTeamId(id));
			dispatch(setIsTeamUserSelectionModalActive(true));
		}
	};

	return (
		<EntityCard
			entity={team}
			onUntieUser={onUntieUser}
			statusRights={statusRights}
			deleteText="Удалить команду"
			onDeleteEntity={onDeleteTeam}
			onBindingEntity={onBindingTeam}
		/>
	);
};
