import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import FigureGraphic from '../../graphic/figure/FigureGraphic';

/**
 * Компонент, реализующий геометрические фигуры.
 */
class FigureComponent extends GraphicComponent<null, FigureGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.FIGURE;

	constructor() {
		super();
		this.disableEditModeMechanic();
	}

	public getTexture = () => null;
	public getUniqueTexture = () => null;
	public setTexture = (fn: (prev: null) => null) => {
		// nothing
	};
}

export default FigureComponent;
