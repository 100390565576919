import React, { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoPanel } from '../../../../info-panel';
import { templatePrivateAPI } from '../../../../../../../../entities/templates/private/api/api';
import { useUnbindPrivateTemplateTag } from '../../api/useUnbindPrivateTemplateTag';
import { ChangeTemplateTagsModal } from '../../../../info-panel/ui/change-tags-modal/modal';
import { ContentLayout } from '../../../../../../layouts/content';
import { useEditPrivateTemplateName } from '../../../../../../../../features/templates/edit-name';
import { useAppSelector } from '../../../../../../../../shared/libs/redux';
import { personalAccountSlice } from '../../../../../../../../redux/reducers/personalAccountReducer';

export const PrivateTemplatePanel = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isChangeModalActive, setIsChangeModalActive] = useState(false);

	const isBasicTariff = useAppSelector(personalAccountSlice.selectors.isBasicTariff);

	const goBack = () => navigate(-1);
	const { unbindTag } = useUnbindPrivateTemplateTag(id || '');
	const { editName } = useEditPrivateTemplateName(id || '');

	const { data, error } = templatePrivateAPI.useGetQuery({ id: id || '' }, { skip: !id });

	useLayoutEffect(() => {
		if (error && 'originalStatus' in error && error.originalStatus === 403) {
			navigate('/collection/forbidden');
		}
	}, [error]);

	return (
		<ContentLayout>
			{data && (
				<InfoPanel
					download={isBasicTariff}
					data={data}
					goBack={goBack}
					editName={editName}
					unbindTag={unbindTag}
					openTagsModal={() => setIsChangeModalActive(true)}
					isPDFAvailable={isBasicTariff}
				/>
			)}
			<ChangeTemplateTagsModal
				tags={data?.template.tags}
				template={data?.template}
				unbindTag={unbindTag}
				type="PRIVATE_TEMPLATE"
				active={isChangeModalActive}
				setIsActive={setIsChangeModalActive}
			/>
		</ContentLayout>
	);
};
