import SketchComponentType from '../SketchComponentType';
import UniterComponent from '../UniterComponent';
import ModuleGraphic from '../../graphic/module/ModuleGraphic';
import IModuleComponentTexture from './IModuleComponentTexture';

/**
 * ModuleComponent - модуль это компонент системы, который представляет собой компонент или группу компонентов с
 * какими-то заранее сохраненными настройками. Пример: есть некоторая таблица с данными и перед ней заголовок, их можно
 * объединить в группу и через вызов контекстного меню создать из них модуль.
 */
class ModuleComponent extends UniterComponent<IModuleComponentTexture, ModuleGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.MODULE;

	private baseModuleID: string | null;
	private layerSequence: string[];

	constructor() {
		super();
		this.baseModuleID = null;
		this.layerSequence = [];
	}

	public getTexture = (): IModuleComponentTexture => ({
		baseModule: this.baseModuleID,
		layerSequence: this.layerSequence,
	});

	public getUniqueTexture = (): IModuleComponentTexture => this.getTexture();

	public setTexture = (fn: (prev: IModuleComponentTexture) => IModuleComponentTexture) => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);
		if (updatedTexture === null) {
			return;
		}

		this.baseModuleID = updatedTexture.baseModule;
		this.layerSequence = updatedTexture.layerSequence;
	};
}

export default ModuleComponent;
