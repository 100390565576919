import React from 'react';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from './images/close.svg';
import { Hint } from '../index';
import { useHint } from '../../libs/useHint';
import css from './editable-category.module.scss';
import { ICategoryData } from '../../../entities/modules/category/api/types';

type CategoryState = 'active' | 'disabled';

interface EditableCategoryProps {
	category: ICategoryData,
	state: CategoryState,
	closeAble: boolean,
	onClick?: (id: number) => void,
	onClose?: (id: number) => void,
	styles?: string,
	hint?: string,
}

export const EditableCategory = ({
	category,
	hint,
	state,
	styles,
	onClose,
	onClick,
	closeAble,
}: EditableCategoryProps) => {
	const {
		id,
		name,
	} = category;

	const onCategoryClick = () => onClick && onClick(id);
	const onCategoryClose = () => onClose && onClose(id);

	const {
		isHintVisible,
		onMouseEnter,
		onMouseLeave,
	} = useHint();

	return (
		<div>
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				role="presentation"
				className={cx(css.wrapper, styles, css[state])}
				onClick={onCategoryClick}
			>
				{name}
				{
					closeAble && (
						<div role="presentation" className={css.close_icon} onClick={onCategoryClose}>
							<CloseIcon />
						</div>
					)
				}
			</div>
			<div className={css.hint_wrapper}>
				{hint && <Hint show={isHintVisible} text={hint} />}
			</div>
		</div>
	);
};
