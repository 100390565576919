import React, { useEffect, useState } from 'react';
import { CreateModalWrapper } from '../../../../../ui/create-modal-wrapper/create-modal-wrapper';
import { MAX_INPUT_VALUE_LENGTH } from '../../../../../modals/organization/createOrganization/CreateOrganization';
import CreateInput from '../../../../features/moduleWindow/createInput/CreateInput';
import { useCreateLegalEntity } from '../../../lib/useCreateLegalEntity';

const CreateLegalEntityModal = () => {
	const {
		isCreating,
		createLegalEntity,
		isActive,
		setActive,
	} = useCreateLegalEntity();

	const [name, setName] = useState('');

	const onCreateLegalEntity = async () => {
		await createLegalEntity(name);
		setName('');
	};

	useEffect(() => {
		if (!isActive) setName('');
	}, [isActive]);

	return (
		<CreateModalWrapper
			isActive={isActive}
			disabled={isCreating || !name.length}
			title="Создать юридическое лицо"
			btnText="Создать"
			onCreate={onCreateLegalEntity}
			setIsActive={setActive}
		>
			<CreateInput
				maxLength={MAX_INPUT_VALUE_LENGTH}
				text={name}
				subTitle="Введите название"
				defaultValue="Название юр. лица"
				onChange={setName}
			/>
		</CreateModalWrapper>
	);
};

export default CreateLegalEntityModal;
