import { CreateTagInput } from '../../../changeable-tag-bar/ui/create-tag-input/input';
import { classNames } from '../../../../shared/libs/classNames';
import { useCreateWorkshopModuleTag } from '../../model/services/useCreateWorkshopModuleTag';
import { useBindWorkshopModuleTag } from '../../model/services/useBindWorkshopModuleTag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

interface CreateWorkshopTagInputProps {
	className?: string,
	moduleId?: string,
}

export const CreateWorkshopTagInput = ({ className, moduleId }: CreateWorkshopTagInputProps) => {
	const { onCreateTag } = useCreateWorkshopModuleTag();
	const { onBindTag } = useBindWorkshopModuleTag(moduleId);

	const createTag = async (name: string) => {
		try {
			const res = await onCreateTag(name);
			await onBindTag(res);
		} catch (e) {
			notificationError('Произошла ошибка при создании тега', 'Не удалось создать тег');
		}
	};

	return (
		<div className={classNames('', {}, [className])}>
			<CreateTagInput onCreateTag={createTag} />
		</div>
	);
};
