interface IStack<T> {
	push(item: T): void;
	pop(): T | undefined;
	peek(): T | undefined;
	size(): number;
}

class Stack<T> implements IStack<T> {
	private storage: T[] = [];

	// eslint-disable-next-line no-empty-function
	constructor(private capacity: number = Infinity) {}

	public push = (item: T): void => {
		if (this.size() === this.capacity) {
			throw Error('Stack has reached max capacity, you cannot add more items');
		}
		this.storage.push(item);
	};

	public pop = (): T | undefined => this.storage.pop();

	public peek = (): T | undefined => this.storage[this.size() - 1];

	public size = (): number => this.storage.length;
}

export default Stack;
