import {
	Navigate, Route, Routes, useLocation,
} from 'react-router-dom';
import React, { Suspense } from 'react';
import { ContentLayout } from '../../../../layouts/content';
import {
	LINK_INTEGRATOR_GALLERY,
	LINK_INTEGRATOR_START_KITS,
	LINK_INTEGRATOR_USERS,
	LINK_INTEGRATOR_WORKSHOP,
} from '../../model/tabs-config';
import { IntegratorWorkshop } from '../../../workshop';
import { IntegratorTemplate } from '../../../template';
import { IntegratorModule } from '../../../../../../pages/integrator/module';

export const IntegratorTabBlock = () => {
	const { pathname } = useLocation();

	return (
		<ContentLayout>
			{
				pathname.includes('/workshop*')
					? <Navigate to={`${LINK_INTEGRATOR_WORKSHOP}/*`} />
					: null
			}
			{
				pathname.includes('/users/*')
					? <Navigate to={LINK_INTEGRATOR_USERS} />
					: null
			}
			{
				pathname.includes('/gallery*')
					? <Navigate to={LINK_INTEGRATOR_GALLERY} />
					: null
			}
			{
				pathname.includes('/start-kits*')
					? <Navigate to={LINK_INTEGRATOR_START_KITS} />
					: null
			}
			<Suspense fallback="">
				<Routes>
					<Route path="workshop/modules/:id" element={<IntegratorModule />} />
					<Route path="workshop/templates/:id" element={<IntegratorTemplate />} />
					<Route path={`${LINK_INTEGRATOR_WORKSHOP}/*`} element={<IntegratorWorkshop />} />
					<Route path={LINK_INTEGRATOR_USERS} element={<div />} />
					<Route path={LINK_INTEGRATOR_GALLERY} element={<div />} />
					<Route path={LINK_INTEGRATOR_START_KITS} element={<div />} />
					<Route path={`${LINK_INTEGRATOR_USERS}:id`} element={<div />} />
				</Routes>
			</Suspense>
		</ContentLayout>
	);
};
