import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import TextGraphic from '../../graphic/text/TextGraphic';
import IComponentContainingText from '../IComponentContainingText';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Editor from '../../mechanics/mext/editor';

/**
 * Компонент для отображения текста.
 */
class TextComponent extends GraphicComponent<null, TextGraphic> implements IComponentContainingText {
	public readonly type: SketchComponentType = SketchComponentType.TEXT;

	private isAutoWidth: boolean;

	constructor() {
		super();
		this.isAutoWidth = false;
	}

	/**
	 * Синхронизирует размер фрейма с фактическим размером содержимого текста.
	 */
	public syncFrameSize = () => {
		this.syncWidth();
		this.syncHeight();
	};

	public getEditors = (): Editor[] => {
		const graphics = this.getGraphics() as TextGraphic[];
		const editors: Editor[] = [];

		graphics.forEach(graphic => {
			const editor = graphic.getEditor();
			editors.push(editor);
		});

		return editors;
	};

	/**
	 * Включает режим автоматической ширины, когда ширина фрейма автоматически
	 * принимает ширину текста.
	 */
	public enableAutoWidth = () => {
		const graphics = this.getGraphics() as TextGraphic[];
		graphics.forEach((graphic) => graphic.enableAutoWidth());
		this.isAutoWidth = true;
	};

	/**
	 * Отключает режим автоматической ширины, когда ширина фрейма автоматически
	 * принимает ширину текста.
	 */
	public disableAutoWidth = () => {
		const graphics = this.getGraphics() as TextGraphic[];
		graphics.forEach((graphic) => graphic.disableAutoWidth());
		this.isAutoWidth = false;
	};

	/**
	 * Устанавливает каретку в начало текста.
	 */
	public setCarriage = (position = 0) => {
		const editors = this.getEditors();
		if (editors.length === 0) {
			throw new ManipulatorError('mext editors not found');
		}
		const editor = editors[0];

		editor.setSelection(position);
	};

	private syncWidth = () => {
		const graphics = this.getGraphics();

		graphics.forEach((graphic) => {
			if (this.isAutoWidth) {
				const graphicWidth = graphic.getRealWidth();
				graphic.setFrameConfiguration(prev => ({
					...prev,
					width: graphicWidth + 2,
				}));
			}
		});
	};

	private syncHeight = () => {
		const graphics = this.getGraphics();
		graphics.forEach((graphic) => {
			const graphicHeight = graphic.getRealHeight();
			graphic.setFrameConfiguration(prev => ({
				...prev,
				height: graphicHeight,
			}));
		});
	};

	public getTexture = (): null => null;

	public getUniqueTexture = (): null => null;

	public setTexture = (fn: (prev: null) => null): void => {
		// nothing
	};
}

export default TextComponent;
