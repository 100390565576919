import IDescartesPosition from '../IDescartesPosition';

interface ISubscriber {
    action: (position: IDescartesPosition) => void
}

// MousePositionObserver уведомляет подписчиков о движении мыши с передачей новых координат (x, y)
class MousePositionObserver {
	private readonly subscribers: ISubscriber[] = [];
	private currentClientPosition: IDescartesPosition;
	private currentPosition: IDescartesPosition;

	constructor() {
		this.currentClientPosition = {
			x: 0,
			y: 0,
		};
		this.currentPosition = {
			x: 0,
			y: 0,
		};

		document.addEventListener('mousemove', this.onMouseMove);
		document.body.addEventListener('scroll', this.onScroll);
	}

	public subscribe = (action: (_: IDescartesPosition) => void) => {
		const subscriber: ISubscriber = {
			action,
		};
		this.subscribers.push(subscriber);
	};

	public getCurrentPosition = (): IDescartesPosition => ({ ...this.currentPosition });

	private onMouseMove = (ev: MouseEvent) => {
		this.updateClientPosition(ev);
		this.updatePosition();
	};

	private onScroll = () => {
		this.updatePosition();
	};

	private updatePosition = () => {
		this.currentPosition = {
			x: this.currentClientPosition.x + window.scrollX,
			y: this.currentClientPosition.y + window.scrollY,
		};
		this.reportSubscribers({ ...this.currentPosition });
	};

	private updateClientPosition = (ev: MouseEvent) => {
		this.currentClientPosition.x = ev.clientX;
		this.currentClientPosition.y = ev.clientY;
	};

	private reportSubscribers = (position: IDescartesPosition) => {
		this.subscribers.forEach((subscriber) => {
			subscriber.action(position);
		});
	};
}

export default MousePositionObserver;
