import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { accountAPI } from '../entities/user/account/api/api';
import { ComponentOfferPrinterWithRouter } from '../components/SketchManipulators/SketchPrinter/ComponentOfferPrinter';
import PresentationWrapper from '../components/pages/collection/section/offer/offerPresentation/PresentationWrapper';
import PrivacyPolicy from '../components/pages/privacyPolicy/PrivacyPolicy';
import { AuthorizedRoutes } from './router/authorized-routes';
import ModalWindowWrapper from '../components/pages/features/moduleWindow/ModalWindowWrapper';
import { ReportButton } from '../components/ui/report-button/report-button';
import { useAppDispatch, useAppSelector } from '../shared/libs/redux';
import { UnauthorizedRoutes } from './router/un-authorized-routes';
import PageScaleListener from '../components/Sketch/interface/bars/tool-bar/panel-zoom/PageScaleListener';
import '../styles/mext.css';
import '../styles/color-input.scss';
import '../styles/colorex.scss';
import '../styles/core.scss';
import '../styles/ui.scss';
import '../styles/main.scss';
import '../styles/pages/collection.scss';
import css from './app.module.scss';
import PreLoader from '../components/ui/preLoader/PreLoader';
import { loadOfferStructure, loadTemplateStructure } from '../shared/api/structure/load-structure';
import {
	ConstructorInstructionButton,
} from '../shared/ui/constructor-instruction-button/constructor-instruction-button';
import { BasicTariffOnly } from './providers/basic-tariff-only/BasicTariffOnly';
import { disconnect } from '../features/websocket/model/slice';
import WebSocketConnector from '../features/websocket/WebSocketConnector';

export const App = () => {
	const authorized = useAppSelector(state => state.personalAccount.authorized);
	const appDispatch = useAppDispatch();

	useEffect(() => {
		if (!authorized) {
			appDispatch(disconnect());
		}
	}, [authorized]);

	PageScaleListener.init();

	const { isLoading } = accountAPI.useGetQuery();

	if (isLoading) {
		return (
			<div className={css.loader}>
				<PreLoader />
			</div>
		);
	}

	const MainRoutes = authorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />;

	return (
		<div className={css.wrapper}>
			<Routes>
				<Route
					path="/presentation-for-pdf/offer/:id"
					element={(
						<BasicTariffOnly>
							<ComponentOfferPrinterWithRouter loadStructure={loadOfferStructure} isAutoPrint />
						</BasicTariffOnly>
					)}
				/>
				<Route
					path="/presentation-for-pdf/private-template/:id"
					element={(
						<BasicTariffOnly>
							<ComponentOfferPrinterWithRouter loadStructure={loadTemplateStructure} isAutoPrint />
						</BasicTariffOnly>
					)}
				/>
				<Route path="/presentation/*" element={<PresentationWrapper />} />
				<Route path="/privacy" element={<PrivacyPolicy />} />
				<Route path="*" element={MainRoutes} />
			</Routes>
			{authorized && <ModalWindowWrapper />}
			{authorized && <WebSocketConnector /> }

			<div className={css.info_box}>
				<ConstructorInstructionButton />
				<ReportButton />
			</div>
			<ToastContainer containerId="toast-container" newestOnTop />
		</div>
	);
};
