import InterfacePanel from '../../InterfacePanel';

class PageTextOverflowPanel extends InterfacePanel {
	private readonly ROOT_CLASS_NAME = 'page-text-overflow-panel';
	private readonly WARNING_TEXT = 'Текст вышел за пределы страницы';

	constructor() {
		super();
		this.rootElement.textContent = this.WARNING_TEXT;
		this.setRootElementClassName(this.ROOT_CLASS_NAME);
	}
}

export default PageTextOverflowPanel;
