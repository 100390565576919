import Utils from "../../components/Sketch/utils/impl/Utils";
import { ICreateSketchRequest } from "../../entities/sketch/api/types";
import SketchComponentType from "../../components/Sketch/components/SketchComponentType";
import GraphicType from "../../components/Sketch/graphic/GraphicType";
import Orientation from "../../components/Sketch/page/Orientation";
import IPageTexture from "../../components/Sketch/graphic/page/IPageTexture";
import IGraphicStructure from "../../components/Sketch/graphic/IGraphicStructure";
import IComponentStructure from "../../components/Sketch/components/IComponentStructure";

export const generateEmptyTemplateStructure = (): ICreateSketchRequest => {
	const sketchId = Utils.Generate.UUID4();
	return {
		id: sketchId,
		name: "nothing",
		root: {
			id: Utils.Generate.UUID4(),
			type: SketchComponentType.PAGES_CONTAINER,
			offset: null,
			texture: null,
			graphics: [{
				id: Utils.Generate.UUID4(),
				type: GraphicType.PAGE,
				frame: null,
				texture: {
					index: 0,
					backgroundColor: "#FFFFFF",
					orientation: Orientation.PORTRAIT,
					paddingBottom: 40,
					paddingLeft: 40,
					paddingRight: 40,
					paddingTop: 40,
					backgroundImage: null,
					repeatingImage: null,
				} as IPageTexture,
			}] as IGraphicStructure<IPageTexture>[],
			components: null,
		} as IComponentStructure<null>,
		preview: null,
	};
};
