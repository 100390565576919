import Backend from './Backend';
import Color from './Color';
import Component from './Component';
import DOM from './DOM';
import Text from './Text';
import File from './File';
import Generate from './Generate';
import Geometry from './Geometry';
import ObjectUtils from './ObjectUtils';

class Utils {
	public static DOM: DOM = new DOM();
	public static Text: Text = new Text();
	public static File: File = new File();
	public static Color: Color = new Color();
	public static Backend: Backend = new Backend();
	public static Generate: Generate = new Generate();
	public static Geometry: Geometry = new Geometry();
	public static Component: Component = new Component();
	public static Object: ObjectUtils = new ObjectUtils();
}

export default Utils;
