import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';

class PageScaleListener {
	private static isStarted: boolean;

	public static init = () => {
		PageScaleListener.isStarted = false;

		/* Изменение поведения при изменении масштаба через ctrl + scroll */
		document.addEventListener('wheel', this.onChangePageScaleWithWheel.bind(this), { passive: false });

		/* На изменение масштаба выкинем предупреждение */
		// window.addEventListener('resize', this.onChangePageScaleFromBrowser.bind(this));
	};

	public static start = () => {
		if (this.isStarted) {
			return;
		}

		// window.devicePixelRatio !== 1 && notificationError(
		// 	'Определение масштаба окна пользователя',
		// 	`Ваш масштаб страницы отличается от стандартного - некоторые компоненты могут
		// 		отображаться некорректно!`,
		// );

		this.isStarted = true;
	};

	public static stop = () => {
		if (!this.isStarted) {
			return;
		}
		this.isStarted = false;
	};

	public static isListen = () => this.isStarted;

	/**
	 * Обработка изменения масштаба страницы через колесо мыши и Ctrl
	 * @param ev
	 * @private
	 */
	private static onChangePageScaleWithWheel = (ev: WheelEvent): boolean => {
		if (!this.isStarted) {
			return true;
		}
		if (ev.ctrlKey) {
			ev.preventDefault();
			return false;
		}
		return true;
	};

	/**
	 * Обработка программного изменения масштаба страницы (через настройки браузера)
	 * @private
	 */
	private static onChangePageScaleFromBrowser = () => {
		if (!this.isStarted) {
			return;
		}

		if (window.devicePixelRatio !== 1) {
			notificationError(
				'Определение масштаба',
				`Если масштаб страницы отличается от стандартного, некоторые компоненты могут
									отображаться не корректно! Сейчас масштаб ${window.devicePixelRatio.toFixed(2)}`,
				true,
				4000,
			);
		} else if (window.devicePixelRatio === 1) {
			notificationSuccess(
				'Масштаб страницы восстановлен до стандартного значения.',
				'',
				true,
				2000,
			);
		}
	};
}

export default PageScaleListener;
