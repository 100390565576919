import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { workshopTemplateAPI } from '../../../../../../../entities/templates/workshop/template/api/api';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import {
	setWorkshopTemplateSelectedList,
} from '../../../../../../../entities/templates/workshop/template/model/slice';
import {
	useCleanWorkshopTemplateSelection,
} from '../../../../../../../hooks/workshop-template-selection/useCleanWorkshopTemplateSelection';

export const useDuplicateWorkshopTemplates = () => {
	const dispatch = useAppDispatch();

	const [duplicate] = workshopTemplateAPI.useDuplicateMutation();

	const selectedList = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);

	const clearSelectedList = () => dispatch(setWorkshopTemplateSelectedList([]));

	const { cleanTemplateSelection } = useCleanWorkshopTemplateSelection();

	const duplicateTemplates = async () => {
		if (selectedList) {
			try {
				await duplicate({ templates: selectedList })
					.unwrap();
				if (selectedList.length === 1) {
					notificationSuccess('Шаблон успешно скопирован!');
				} else if (selectedList.length > 1) {
					notificationSuccess('Шаблоны успешно скопированы!');
				}
			} catch (e) {
				notificationError('Шаблон', 'Ошибка при копировании шаблона...');
			} finally {
				cleanTemplateSelection();
				clearSelectedList();
			}
		}
	};

	return { duplicateTemplates };
};
