export const fixCalendarDate = (dateStr: string | undefined) => {
	// Исходная дата
	const originalDate = new Date(dateStr || '');

	// Функция для добавления ведущего нуля к числам, если они меньше 10
	const padZero = (num: number) => (num < 10 ? `0${num}` : num);

	// Получаем нужные значения дня, месяца и года
	const day = padZero(originalDate.getDate());
	const month = padZero(originalDate.getMonth() + 1); // Месяцы в JavaScript начинаются с 0
	const year = originalDate.getFullYear();

	const hours = padZero(originalDate.getHours());
	const minutes = padZero(originalDate.getMinutes());

	// Формируем новую строку с датой в нужном формате
	return `${day}-${month}-${year} ${hours}:${minutes}`;
};
