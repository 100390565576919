import React from 'react';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import { classNames } from '../../../libs/classNames';
import css from './SettingsButton.module.scss';

interface SettingsButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string,
	onClick?: () => void,
}

export const SettingsButton = ({ className, onClick, ...props }: SettingsButtonProps) => (
	<button
		type="button"
		className={classNames(css.wrapper, {}, [className])}
		onClick={onClick}
		{...props}
	>
		<SettingsIcon />
		<div className={css.text}>Редактировать модули</div>
	</button>
);
