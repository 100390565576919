const waitForRerender = () => new Promise(resolve => {
	requestAnimationFrame(resolve);
});

export default function* renderStep() {
	let action: VoidFunction = yield;
	while (true) {
		waitForRerender().then(action);
		action = yield;
	}
}
