import {
	LINK_COLLECTION_TEMPLATE_CUSTOM,
	LINK_COLLECTION_TEMPLATE_GALLERY,
	LINK_COLLECTION_TEMPLATE_STORAGE,
	LINK_COLLECTION_TEMPLATE_TEAM,
} from './consts';
import { ITabData } from '../../../../../../widgets/tabs/page-tabs/model/types';

export const templateTabs: ITabData[] = [
	{
		name: 'Мои шаблоны',
		link: LINK_COLLECTION_TEMPLATE_STORAGE,
	},
	{
		name: 'Галерея',
		link: LINK_COLLECTION_TEMPLATE_GALLERY,
	},
	{
		name: 'Шаблоны команды',
		link: LINK_COLLECTION_TEMPLATE_TEAM,
	},
	{
		name: 'Заказные',
		link: LINK_COLLECTION_TEMPLATE_CUSTOM,
	},
];
