import type ParserState from './state';
import { TokenType } from './types';
import { globalCodes } from './utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function parseParagraph(state: ParserState): boolean {
	const { pos } = state;
	if (consumeParagraph(state)) {
		const value = state.substring(pos);
		state.push({
			type: TokenType.Paragraph,
			format: state.startToken.format,
			value,
			textAlign: state.startToken.textAlign,
			color: state.startToken.color,
			fontFamily: state.startToken.fontFamily,
			fontSize: state.startToken.fontSize,
			lineHeight: state.startToken.lineHeight,

		});
		return true;
	}
}

export function consumeParagraph(state: ParserState): boolean {
	if (state.consume(globalCodes.Paragraph)) {
		return true;
	}
	return false;
}
