import React from 'react';
import css from './create-category-dropdown.module.scss';
import { DropdownItem } from '../../../shared/ui';
import { ICategoryData } from '../../../entities/modules/category/api/types';

interface CreateTageDropdownProps {
	creatingCategoryName: string,
	data: ICategoryData[] | undefined,
	createCategory: () => void,
	selectedCategories?: number[],
	onCategoryClick: (category: ICategoryData) => void,
}

export const CreateCategoryDropdown = ({
	createCategory,
	onCategoryClick,
	creatingCategoryName,
	selectedCategories,
	data,
}: CreateTageDropdownProps) => {
	const a = '';

	return (
		<div className={css.category_list}>
			{
				!data?.length && creatingCategoryName && (
					<DropdownItem
						data={{
							id: 'add-tag',
							name: `Добавить категорию: ${creatingCategoryName}`,
						}}
						onClick={createCategory}
						isActive
					/>
				)
			}
			{
				data?.map(item => {
					const preparedData = {
						...item,
						id: item.id.toString(),
					};

					const isActive = selectedCategories?.includes(item.id);

					return (
						<DropdownItem
							key={item.id}
							data={preparedData}
							isActive={isActive}
							onClick={() => onCategoryClick(item)}
						/>
					);
				})
			}
		</div>
	);
};
