class PictureImporter {
	private readonly prevInjectListeners: VoidFunction[];
	private readonly postInjectListeners: VoidFunction[];

	constructor() {
		// super();

		this.prevInjectListeners = [];
		this.postInjectListeners = [];
	}

	public importPicture = () => {
		console.log('import picture');
	};
}

export default PictureImporter;
