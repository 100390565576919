import { useNavigate, useParams } from 'react-router-dom';
import React, { useLayoutEffect, useState } from 'react';
import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import css from './template.module.scss';
import {
	setActiveWorkshopTemplate,
	setWorkshopDuplicateModalActive,
} from '../../../../../entities/templates/workshop/template/model/slice';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { CopyLinkButton, TitleWithEditBtn, GoBackButton } from '../../../../../shared/ui';
import { ChangeWorkshopTemplateModal } from './modal/change-workshop-templates-modal/change-workshop-templates-modal';
import ComponentSketchStructureVisualizer
	from '../../../../SketchManipulators/SketchStructureVisualizer/ComponentSketchStructureVisualizer';
import {
	PublishWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/publish-to-gallery/ui/publish-workshop-template-modal';
import { ChangeableTagList } from '../../../../../widgets/changeable-tag-bar/ui/tag-list/changeable-tag-list';
import {
	useUnbindTagToWorkshopTemplate,
} from '../../../../../features/workshop/templates/unbind-tag/api/useUnbindTagToWorkshopTemplate';
import { EditTemplateName, useEditWorkshopTemplateName } from '../../../../../features/templates/edit-name';
import {
	IntegrateWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/integrate-in-account/ui/integrate-workshop-template-modal';
import { IntegratorPanelTemplateControls } from './controls/controls';

export const IntegratorTemplate = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { id = '' } = useParams();

	const [isChangeModalActive, setIsChangeModalActive] = useState(false);
	const [isSelectUserModalActive, setIsSelectUserModalActive] = useState(false);

	const {
		data: template,
		error,
	} = workshopTemplateAPI.useGetQuery({ template: id }, { skip: !id.length });
	const { onUnbindTag } = useUnbindTagToWorkshopTemplate(template?.template.id || '');
	const { editName } = useEditWorkshopTemplateName(template?.template.id || '');

	const onDuplicate = () => {
		dispatch(setActiveWorkshopTemplate(id));
		dispatch(setWorkshopDuplicateModalActive(true));
	};

	useLayoutEffect(() => {
		if (error && 'originalStatus' in error && error.originalStatus === 403) {
			navigate('/collection/forbidden');
		}
	}, [error, navigate]);

	return (
		<>
			<div className={css.wrapper}>
				<GoBackButton onClick={() => navigate('/integrator/workshop/templates')} />
				<div className={css.header}>
					<EditTemplateName
						editName={editName}
						name={template?.template.name || ''}
					/>
					<CopyLinkButton />
				</div>
				<TitleWithEditBtn title="Категории" onChange={() => setIsChangeModalActive(true)} />
				<ChangeableTagList
					deleteTag={onUnbindTag}
					activeTags={template?.template.tags.fromTemplates || []}
					disabledTags={template?.template.tags.fromModules || []}
				/>
				<IntegratorPanelTemplateControls
					template={template}
					onDuplicate={onDuplicate}
					openPublishModal={() => setIsSelectUserModalActive(true)}
				/>
				{
					template && (
						<div className={css.preview}>
							<ComponentSketchStructureVisualizer structure={{
								...template.template,
								root: template.rootComponent,
							}}
							/>
						</div>
					)
				}
			</div>
			<IntegrateWorkshopTemplateModal
				isActive={isSelectUserModalActive}
				setActive={setIsSelectUserModalActive}
				template={template?.template.id || ''}
			/>
			<PublishWorkshopTemplateModal />
			<ChangeWorkshopTemplateModal
				template={template}
				isActive={isChangeModalActive}
				setActive={setIsChangeModalActive}
			/>
		</>
	);
};
