import { IContextMenuData } from '../../model/types';
import { ContextMenuItem } from './item/context-menu-item';
import css from './context-menu-content.module.scss';

interface ContextMenuContentProps {
	data: IContextMenuData;
}

export const ContextMenuContent = ({ data }: ContextMenuContentProps) => (
	<div className={css.wrapper}>
		{
			data?.map(item => (
				<ContextMenuItem key={item.text} data={item} />
			))
		}
	</div>
);
