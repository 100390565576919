import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { URI_TEMPLATE_GALLERY, URI_TEMPLATE_GALLERY_DUPLICATE_PRIVATE } from './consts';
import {
	ICreateGalleryTemplateRequest,
	IGetGalleryTemplateResponse,
	IGetGalleryTemplatesRequest,
	IGetGalleryTemplatesResponse,
} from './types';
import { ITemplateData, ITemplateIds } from '../../types';

export const galleryAPI = createApi({
	reducerPath: 'galleryAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['gallery'],
	endpoints: builder => ({
		create: builder.mutation<{ templateId: string }, ICreateGalleryTemplateRequest>({
			query: ({ rootComponent }) => ({
				url: URI_TEMPLATE_GALLERY,
				method: 'POST',
				body: { rootComponent },
			}),
			invalidatesTags: ['gallery'],
		}),
		getAll: builder.query<IGetGalleryTemplatesResponse, IGetGalleryTemplatesRequest>({
			query: (data) => ({
				url: URI_TEMPLATE_GALLERY,
				method: 'GET',
				params: data,
			}),
			async onQueryStarted(arg, {
				queryFulfilled,
			}) {
				try {
					await queryFulfilled;
				} catch (error) {
					console.log();
				}
			},
			providesTags: ['gallery'],
		}),
		get: builder.query<IGetGalleryTemplateResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_TEMPLATE_GALLERY}/${id}`,
				method: 'GET',
			}),
			providesTags: ['gallery'],
		}),
		duplicateToPrivate: builder.mutation<{ templates: ITemplateData[] }, ITemplateIds>({
			query: (data) => ({
				url: URI_TEMPLATE_GALLERY_DUPLICATE_PRIVATE,
				method: 'POST',
				body: { ...data },
			}),
		}),
	}),
});
