import React from 'react';
import CreateInput from '../../../../../components/pages/features/moduleWindow/createInput/CreateInput';

interface SetModuleNameProps {
	name: string,
	handleName: (name: string) => void,
}

export const SetModuleName = ({ handleName, name }: SetModuleNameProps) => (
	<CreateInput
		text={name}
		subTitle="Название модуля"
		onChange={handleName}
		defaultValue="Название модуля"
		maxLength={128}
	/>
);
