import React from 'react';
import InputField from '../InputField/InputField';
import { useInputValue } from '../../../../hooks/useInput';

interface InputBlockProps {
	inputItems: {
		name: useInputValue,
		phone: useInputValue,
		birthday: useInputValue,
		organization: useInputValue,
		email: useInputValue,
	}
	isDataLoading: boolean | undefined,
	handleKeyPressReg: (e: React.KeyboardEvent<HTMLInputElement>) => void,
}

const InputBlock: React.FC<InputBlockProps> = ({
	handleKeyPressReg,
	isDataLoading,
	inputItems,
}: InputBlockProps) => {
	const {
		name,
		phone,
		birthday,
		organization,
		email,
	} = inputItems;

	const isOrganizationValid = organization.isEmpty || organization.minLengthError;
	const isNameValid = name.isEmpty || name.minLengthError || name.maxLengthError;
	const isEmailValid = email.emailError
		|| email.isEmpty
		|| email.minLengthError
		|| email.maxLengthError;
	const isPhoneValid = phone.isEmpty || phone.phoneError;

	return (
		<>
			{/* <InputField */}
			{/* 	placeholder="Организация" */}
			{/* 	type="text" */}
			{/* 	value={organization.value} */}
			{/* 	onChange={e => organization.onChange(e, 'organization')} */}
			{/* 	onBlur={organization.onBlur} */}
			{/* 	status={ */}
			{/* 		isOrganizationValid */}
			{/* 			? 'error' */}
			{/* 			: 'success' */}
			{/* 	} */}
			{/* 	keyDown={e => { */}
			{/* 		handleKeyPressReg(e); */}
			{/* 	}} */}
			{/* 	disabled={isDataLoading} */}
			{/* /> */}

			<InputField
				placeholder="ФИО"
				type="text"
				value={name.value}
				onChange={e => name.onChange(e, 'name')}
				onBlur={name.onBlur}
				status={
					isNameValid
						? 'error'
						: 'success'
				}
				keyDown={e => {
					handleKeyPressReg(e);
				}}
				disabled={isDataLoading}
			/>
			{/* <Calendar */}
			{/* 	onBlur={birthday.onBlur} */}
			{/* 	onChange={e => birthday.onChange(e, 'birthday')} */}
			{/* 	isRegistratedInProgress={isDataLoading} */}
			{/* 	maxLengthError={birthday.maxLengthError} */}
			{/* 	minLengthError={birthday.minLengthError} */}
			{/* 	isEmpty={birthday.isEmpty} */}
			{/* 	value={birthday.value} */}
			{/* /> */}

			<InputField
				placeholder="E-mail"
				type="email"
				status={
					isEmailValid
						? 'error'
						: 'success'
				}
				keyDown={e => {
					handleKeyPressReg(e);
				}}
				value={email.value}
				onChange={e => email.onChange(e, 'email')}
				onBlur={email.onBlur}
				disabled={isDataLoading}
			/>
			<InputField
				placeholder="+7 900 000 00 00"
				type="tel"
				status={
					isPhoneValid
						? 'error'
						: 'success'
				}
				keyDown={e => {
					handleKeyPressReg(e);
				}}
				value={phone.value}
				onChange={e => phone.onChange(e, 'phone')}
				onBlur={phone.onBlur}
				disabled={isDataLoading}
			/>
		</>
	);
};

export default InputBlock;
