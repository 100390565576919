import React, { useMemo, useState } from 'react';
import cx from 'classnames';
import { ITagData } from '../api/types';
import { cutText } from '../../../../helpers/cutText';
import css from './module-tag.module.scss';
import { ModuleTagControls } from './controls/controls';

interface ModuleTagProps {
	tag: ITagData,
	isActive?: boolean,
	styles?: string,
	onClick?: (id: number) => void,
	onDelete?: () => void,
	onUnbind?: () => void,
	onEdit?: () => void,
}

export const ModuleTag = ({
	tag,
	onClick,
	isActive,
	onDelete,
	onUnbind,
	onEdit,
	styles,
}: ModuleTagProps) => {
	const {
		id,
		name,
	} = tag;

	const clickHandler = () => {
		if (onClick) {
			onClick(id);
		}
	};

	const tagName = useMemo(() => cutText(name, 8), [name]);

	const [isIconShow, setIsIconShow] = useState(false);

	// * При наведении на тег
	const onRoleMouseEnter = () => {
		if (onUnbind) {
			setIsIconShow(true);
		}
	};

	const onRoleMouseLeave = () => {
		if (onUnbind) {
			setIsIconShow(false);
		}
	};

	return (
		<div
			onMouseEnter={onRoleMouseEnter}
			onMouseLeave={onRoleMouseLeave}
			role="presentation"
			onClick={clickHandler}
			className={cx(css.wrapper, isActive && css.active, styles)}
		>
			<span className={css.text}>
				{tagName}
			</span>
			<ModuleTagControls isIconShow={isIconShow} onEdit={onEdit} onDelete={onDelete} onUnbind={onUnbind} />
		</div>
	);
};
