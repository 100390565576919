import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInput } from '../../../../../hooks/useInput';
import { CurrentAuthorizationPageData, RESET_PASSWORD_AUTHORIZATION_PAGE } from '../Authorization';
import { notificationError } from '../../../../Notifications/callNotifcation';
import MainAuthorization from './MainAuthorization';
import RememberMeBlock from '../RememberMeBlock';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { authAPI } from '../../../../../entities/user/auth/api/api';
import {
	setAccount,
	setAccountAuthorized,
	setAccountSessionId,
} from '../../../../../redux/reducers/personalAccountReducer';
import { ISignInRequest } from '../../../../../entities/user/auth/api/types';

interface MainAuthorizationContainerProps {
	setCurrentPage: (value: CurrentAuthorizationPageData) => void,
}

const MainAuthorizationContainer = ({ setCurrentPage }: MainAuthorizationContainerProps) => {
	const email = useInput('', {
		isEmpty: true,
		minLength: 5,
		isEmail: true,
		maxLength: 255,
	});
	const password = useInput('', {
		isEmpty: true,
		minLength: 8,
		maxLength: 255,
	});

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [isRememberMe, setIsRememberMe] = useState(true);

	const [isPasswordHide, setIsPasswordHide] = useState(true);

	const handlePasswordVisibility = () => setIsPasswordHide(!isPasswordHide);

	const onSetCurrentPage = () => setCurrentPage(RESET_PASSWORD_AUTHORIZATION_PAGE);

	const isDataValid = email.inputValid && password.inputValid;
	const isEmailValid = email.emailError
		|| email.isEmpty
		|| email.minLengthError
		|| email.maxLengthError;

	const authorizationData: ISignInRequest = {
		email: email.value,
		password: password.value,
	};

	const [signIn, { isLoading }] = authAPI.useSignInMutation();

	const onSignIn = async () => {
		try {
			const res = await signIn(authorizationData)
				.unwrap();

			const {
				session,
				account,
			} = res;

			if (isRememberMe) {
				localStorage.setItem('authorization', session);
				dispatch(setAccountSessionId(session));
			} else {
				sessionStorage.setItem('authorization', session);
				dispatch(setAccountSessionId(session));
			}

			dispatch(setAccount(account));
			dispatch(setAccountAuthorized(true));

			navigate('/');
		} catch (error: any) {
			if (error.status === 401) {
				notificationError(
					'Ошибка авторизации',
					'Пароль введен неверно. Пожалуйста, убедитесь в правильности ввода и повторите попытку.',
				);
			} else if (error.status === 404) {
				notificationError(
					'Ошибка авторизации',
					'Аккаунт не найден. Проверьте данные или зарегистрируйтесь.',
				);
			} else {
				notificationError(
					'Ошибка!',
					'Произошла ошибка при авторизации. Пожалуйста, попробуйте еще раз позже.',
				);
			}
		}
	};

	const handleKeyPressAuth = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter' && isDataValid) {
			await onSignIn();
		}
	};

	return (
		<MainAuthorization
			onAuthorize={onSignIn}
			handleKeyPressAuth={handleKeyPressAuth}
			isDataValid={isDataValid}
			handlePasswordVisibility={handlePasswordVisibility}
			email={email}
			isEmailValid={isEmailValid}
			isPasswordHide={isPasswordHide}
			password={password}
			isDataLoading={isLoading}
			onSetCurrentPage={onSetCurrentPage}
		>
			<RememberMeBlock
				isRememberMe={isRememberMe}
				setIsRememberMe={setIsRememberMe}
			/>
		</MainAuthorization>
	);
};

export default MainAuthorizationContainer;
