import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalsActive } from './teamReducer';
import { ILegalEntityItem } from '../../entities/legal-entity/api/types';

interface IDeletingUserData {
	email: string,
	legalEntityId: string,
}

interface IInitialState {
	modalsActive: IModalsActive,
	legalEntityList: ILegalEntityItem[] | undefined,
	isCreateManagerModalActive: boolean,
	isManagersModalListActive: boolean,
	addingManagerLegalEntityId: string | null,
	deletingUserData: IDeletingUserData | null,
	bindingLegalEntityId: string | null,

	isCreateLegalEntityModalActive: boolean,
	isLegalEntityUserSelectionModalActive: boolean,
	isLegalEntityConfirmDeleteModalActive: boolean,
	deletingLegalEntityId: string | null,
	isLegalEntityUserConfirmDeleteModalActive: boolean,
	untieEmail: string | null,
	untieLegalEntityId: string | null,
}

const initialState: IInitialState = {
	legalEntityList: undefined,

	isCreateLegalEntityModalActive: false,
	isLegalEntityUserSelectionModalActive: false,
	isLegalEntityConfirmDeleteModalActive: false,
	deletingLegalEntityId: null,
	isLegalEntityUserConfirmDeleteModalActive: false,
	untieEmail: null,
	untieLegalEntityId: null,
	bindingLegalEntityId: null,

	addingManagerLegalEntityId: null,
	isCreateManagerModalActive: false,
	isManagersModalListActive: false,
	deletingUserData: null,

	modalsActive: {
		isBindUserSuccessModalActive: false,
		isCreateOrgModalActive: false,
		isDeleteOrganizationSuccessActive: false,
		isDeleteUserSuccessActive: false,
		isUsersModalListActive: false,
	},
};

export const legalEntitySlice = createSlice({
	name: 'legalEntity',
	initialState,
	reducers: {
		setLegalEntityList: (state, action: PayloadAction<ILegalEntityItem[]>) => {
			state.legalEntityList = action.payload;
		},

		setIsCreateLegalEntityModalActive: (state, action: PayloadAction<boolean>) => {
			state.isCreateLegalEntityModalActive = action.payload;
		},
		setIsLegalEntityUserSelectionModalActive: (state, action: PayloadAction<boolean>) => {
			state.isLegalEntityUserSelectionModalActive = action.payload;
		},
		setBindingLegalEntityId: (state, action: PayloadAction<string>) => {
			state.bindingLegalEntityId = action.payload;
		},
		setIsLegalEntityConfirmDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isLegalEntityConfirmDeleteModalActive = action.payload;
		},
		setDeletingLegalEntityId: (state, action: PayloadAction<string>) => {
			state.deletingLegalEntityId = action.payload;
		},
		setIsLegalEntityUserConfirmDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isLegalEntityUserConfirmDeleteModalActive = action.payload;
		},
		setLegalEntityUntieEmail: (state, action: PayloadAction<string>) => {
			state.untieEmail = action.payload;
		},
		setLegalEntityUntieId: (state, action: PayloadAction<string>) => {
			state.untieLegalEntityId = action.payload;
		},
		setIsManagersModalActive: (state, action: PayloadAction<boolean>) => {
			state.isManagersModalListActive = action.payload;
		},
		setAddingManagerLegalEntityId: (state, action: PayloadAction<string>) => {
			state.addingManagerLegalEntityId = action.payload;
		},

		setIsCreateOrgModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreateOrgModalActive = action.payload;
		},
		setIsUsersModalListActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isUsersModalListActive = action.payload;
		},
		setIsBindUserSuccessModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isBindUserSuccessModalActive = action.payload;
		},
		setIsDeleteOrganizationSuccessActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteOrganizationSuccessActive = action.payload;
		},
		setIsDeleteUserSuccessActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteUserSuccessActive = action.payload;
		},
		setIsCreateManagerModalActive: (state, action: PayloadAction<boolean>) => {
			state.isCreateManagerModalActive = action.payload;
		},
		setDeletingUserData: (state, action: PayloadAction<IDeletingUserData>) => {
			state.deletingUserData = action.payload;
		},
	},
});

export const {
	setLegalEntityList,
	setDeletingUserData,
	setBindingLegalEntityId,
	setDeletingLegalEntityId,
	setIsCreateOrgModalActive,
	setIsUsersModalListActive,
	setIsDeleteUserSuccessActive,
	setIsCreateManagerModalActive,
	setIsBindUserSuccessModalActive,
	setIsDeleteOrganizationSuccessActive,

	setIsLegalEntityConfirmDeleteModalActive,
	setIsLegalEntityUserConfirmDeleteModalActive,
	setIsLegalEntityUserSelectionModalActive,
	setLegalEntityUntieEmail,
	setIsCreateLegalEntityModalActive,
	setLegalEntityUntieId,
	setIsManagersModalActive,
	setAddingManagerLegalEntityId,
} = legalEntitySlice.actions;

export default legalEntitySlice.reducer;
