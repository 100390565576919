import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CategoryCard, CategoryCardProps } from '../../../../category-card/ui/category-card';

export const WorkshopDndCategoryCard = (props: CategoryCardProps) => {
	const { category } = props;

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: category.id });

	const style = {
		transition,
		zIndex: 1,
		transform: CSS.Transform.toString(transform),
	};

	return (
		<div
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			style={style}
			role="presentation"
		>
			<CategoryCard
				draggable
				{...props}
				dragging={isDragging}
			/>
		</div>
	);
};
