import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import {
	workshopTagGroupActions,
	workshopTagGroupApi,
	workshopTagGroupSlice,
} from '../../../../entities/workshop-tag-group';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useDeleteWorkshopTagGroup = () => {
	const dispatch = useAppDispatch();

	const id = useSelector(workshopTagGroupSlice.selectors.getDeletingGroupId);

	const [deleteGroup, requestData] = workshopTagGroupApi.useDeleteMutation();

	const onDeleteTag = async () => {
		try {
			const numberedId = Number(id);

			if (!Number.isNaN(numberedId)) {
				await deleteGroup({ id: numberedId }).unwrap();

				dispatch(workshopTagGroupActions.setIsDeleteGroupModalActive(false));
			}
		} catch (e) {
			notificationError('При удалении группы тегов произошла ошибка...');
		}
	};

	return {
		onDeleteTag,
		requestData,
	};
};
