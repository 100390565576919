import IManipulatorAction from './IManipulatorAction';
import { AnyManipulatorCommand } from '../../Types';

/**
 * Сущность, содержащая изменения шаблона одним действием пользователя.
 */
class ConstructorAction implements IManipulatorAction {
	private readonly redoCommands: AnyManipulatorCommand[];
	private readonly undoCommands: AnyManipulatorCommand[];

	constructor() {
		this.redoCommands = [];
		this.undoCommands = [];
	}

	/**
	 * Возвращает команды для восстановления действия.
	 */
	public getRedoCommands = (): AnyManipulatorCommand[] => [...this.redoCommands];

	/**
	 * Возвращает команды для отмены действия.
	 */
	public getUndoCommands = (): AnyManipulatorCommand[] => [...this.undoCommands];

	/**
	 * Добавляет команды для восстановления действия.
	 * @param commands Добавляемые команды.
	 */
	public pushRedoCommands = (...commands: AnyManipulatorCommand[]): void => {
		this.redoCommands.push(...commands);
	};

	/**
	 * Добавляет команды для отмены действия.
	 * @param commands Добавляемые команды.
	 */
	public pushUndoCommands = (...commands: AnyManipulatorCommand[]): void => {
		this.undoCommands.push(...commands);
	};

	/**
	 * Восстановить действие.
	 */
	public redo = (): void => {
		this.redoCommands.forEach(command => {
			command.exec();
		});
	};

	/**
	 * Отменить действие.
	 */
	public undo = (): void => {
		this.undoCommands.forEach(command => {
			command.exec();
		});
	};

	/**
	 * Имеет ли действие наличие команд.
	 */
	public isEmpty = (): boolean => this.redoCommands.length === 0 || this.undoCommands.length === 0;
}

export default ConstructorAction;
