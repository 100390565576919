import { useAppDispatch } from '../../../../../../../shared/libs/redux';
import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { teamTemplateAPI } from '../../../../../../../entities/templates/team/api/api';

export const useUnbindTeamTemplateTag = (template: string) => {
	const dispatch = useAppDispatch();

	const [unbind] = teamTemplateAPI.useUnbindMutation();

	const unbindTag = async (tag: number) => {
		try {
			await unbind({
				tagId: tag.toString(),
				templateId: template,
			})
				.unwrap();

			dispatch(teamTemplateAPI.util?.invalidateTags(['team']));

			notificationSuccess('Тег успешно отвязан от шаблона!');
		} catch (e) {
			notificationError('Произошла ошибка при отвязывании тега...');
		}
	};

	return { unbindTag };
};
