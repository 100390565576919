import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleTagApi, workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useEditWorkshopTag = () => {
	const dispatch = useAppDispatch();

	const name = useSelector(workshopTagSlice.selectors.getEditTagName);
	const id = useSelector(workshopTagSlice.selectors.getEditingTagId);

	const [editTag, requestData] = workshopModuleTagApi.useUpdateMutation();

	const onEditTag = async () => {
		try {
			if (id) {
				await editTag({ id, name }).unwrap();

				dispatch(workshopTagActions.setEditTagName(''));
				dispatch(workshopTagActions.setEditingTagId(undefined));
				dispatch(workshopTagActions.setIsEditingTagModalActive(false));
			}
		} catch (e) {
			notificationError('Возникла ошибка при изменении тега...');
		}
	};

	return {
		onEditTag,
		requestData,
	};
};
