import { generateEmptyTemplateStructure } from "./generateEmptyTemplateStructure";
import store from "../../redux/store/store";
import { notificationError } from "../../components/Notifications/callNotifcation";
import { workshopTemplateAPI } from "../../entities/templates/workshop/template/api/api";

const getConstructorLink = (id: string) => `/integrator/workshop/template-constructor/${id}`;

// * Создание шаблона мастерской

export const onWorkshopTemplateCreate = async (navigate: any, setIsDataLoading: (value: boolean) => void) => {
	setIsDataLoading(true);

	const requestBody = generateEmptyTemplateStructure();

	try {
		const res = await store.dispatch(workshopTemplateAPI.endpoints?.create.initiate({ rootComponent: requestBody.root }))
			.unwrap();

		const link = getConstructorLink(res?.template.id);
		navigate(link);
	} catch (err) {
		notificationError("Шаблон", "Ошибка при переходе в конструктор...");
	} finally {
		setIsDataLoading(false);
	}
};
