import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { GoBackButton } from '../../../../../shared/ui';
import { Title } from '../../../../ui/title';
import { adminAPI } from '../../../../../entities/admin/api/api';
import { AdminUserMainInfo } from './main-info/main-info';
import css from './user.module.scss';
import { AdminUserOrganizationsInfo } from './organizations-info/organizations-info';
import { AdminUserStatus } from './status/status';
import { UserTariff } from '../../../../../widgets/user-tariff';
import { TransactionHistory } from '../../../../../widgets/transaction-history';
import { ChangeTariffModal } from './modals/change-tariff/change-tariff';
import { MainButton } from '../../../../ui/main-button/main-button';

export const AdminUser = () => {
	const { id } = useParams();

	const navigate = useNavigate();

	const [isActive, setActive] = useState(false);

	const toUsersPage = () => navigate('/admin/users/*');

	const { data } = adminAPI.useGetAccountQuery({ email: id || '' }, { skip: !id || id === '*' });

	return (
		<div className={css.wrapper}>
			<GoBackButton onClick={toUsersPage} />
			<Title underlined>Личные данные</Title>
			<div className={css.info_wrapper}>
				<div className={css.info}>
					<AdminUserMainInfo data={data?.account} />
					<div className={css.side_info}>
						<AdminUserOrganizationsInfo data={data?.account} />
						<AdminUserStatus status={data?.account.status} />
					</div>
				</div>
				<UserTariff tariffTransactions={data?.account.tariffTransactions} tariff={data?.account.tariff}>
					<MainButton
						type="button"
						btnStyle="bright"
						onClick={() => setActive(true)}
					>
						Сменить тариф
					</MainButton>
				</UserTariff>
			</div>
			{
				data?.account.tariffTransactions && (
					<TransactionHistory transactions={data?.account.tariffTransactions} />
				)
			}
			<ChangeTariffModal
				organizationId={data?.account?.organization?.id || ''}
				isActive={isActive}
				setActive={setActive}
			/>
		</div>
	);
};
