import { useLocation } from 'react-router-dom';
import { ReactComponent as InstructionIcon } from './images/instruction-icon.svg';
import css from './constructor-instruction-button.module.scss';

export const ConstructorInstructionButton = () => {
	const { pathname } = useLocation();

	const onClick = () => window.open('https://wakadoo.pro/instrykcii', '_blank');

	if (!pathname.includes('constructor')) return null;

	return (
		<button type="button" className={css.wrapper} onClick={onClick}>
			<InstructionIcon />
		</button>
	);
};
