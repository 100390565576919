import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { IEmployeeRegistrationRequest, IGetEmployeeListRequest, IGetEmployeeListResponse } from './types';
import { URI_EMPLOYEE } from './consts';
import { IAccount } from '../../user/account/api/types';

export const employeeAPI = createApi({
	reducerPath: 'employeeAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['employee'],
	endpoints: builder => ({
		getAll: builder.query<IGetEmployeeListResponse, IGetEmployeeListRequest>({
			query: (data) => ({
				url: URI_EMPLOYEE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['employee'],
		}),
		create: builder.mutation<IAccount, IEmployeeRegistrationRequest>({
			query: (data) => ({
				url: URI_EMPLOYEE,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['employee'],
		}),
		delete: builder.mutation<void, { email: string }>({
			query: ({ email }) => ({
				url: `${URI_EMPLOYEE}/${email}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['employee'],
		}),
	}),
});
