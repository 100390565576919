enum CSSCursor {
	AUTO = 'auto',
	DEFAULT = 'default',
	TOP = 'n-resize',
	RIGHT = 'e-resize',
	BOTTOM = 's-resize',
	LEFT = 'w-resize',
	LEFT_TOP = 'nw-resize',
	RIGHT_TOP = 'ne-resize',
	RIGHT_BOTTOM = 'se-resize',
	LEFT_BOTTOM = 'sw-resize',
	TEXT = 'text',
	CROSSHAIR = 'crosshair',
	COLUMN_RESIZE = 'col-resize',
	ROW_RESIZE = 'row-resize',
	GRAB = 'grab',
	GRABBING = 'grabbing',
}

export default CSSCursor;
