import { teamAPI } from '../../../../entities/team/api/api';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { setIsCreateTeamModalActive } from '../../../../redux/reducers/teamReducer';

export const useCreateTeam = () => {
	const dispatch = useAppDispatch();

	const handleCreateTeamModal = (value: boolean) => {
		dispatch(setIsCreateTeamModalActive(value));
	};

	const [create, { isLoading: isCreating }] = teamAPI.useCreateMutation();

	// * При создании команды
	const createTeam = async (name: string) => {
		try {
			await create({ name })
				.unwrap();
			notificationSuccess('Вы успешно создали команду!');
			handleCreateTeamModal(false);
		} catch (e) {
			notificationError('Произошла ошибка при создании команды...');
		}
	};

	return {
		createTeam,
		isCreating,
		handleCreateTeamModal,
	};
};
