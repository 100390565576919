import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFilterData, { FromFilterData } from '../../../../../hooks/filter/useFilterData';
import { WorkshopTemplatesList } from './list/WorkshopTemplatesList';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { EmptyPlugList } from '../../../../../widgets/templates/empty-list';
import { WorkshopTemplateSearchBar } from './search-bar/search-bar';
import { onWorkshopTemplateCreate } from '../../../../../helpers/create-template/onWorkshopTemplateCreate';
import {
	useGetWorkshopTemplates,
} from '../../../../../hooks/template/workshop/useGetWorkshopTemplates';
import {
	DeleteWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/delete/ui/delete-workshop-template-modal';
import {
	PublishWorkshopTemplateModal,
} from '../../../../../features/workshop/templates/publish-to-gallery/ui/publish-workshop-template-modal';
import { SelectWorkshopUserFilterModal } from './select-workshop-user-filter-modal/select-workshop-user-filter-modal';
import { setWorkshopTemplateSelectedList } from '../../../../../entities/templates/workshop/template/model/slice';
import { templatePrivateAPI } from '../../../../../entities/templates/private/api/api';
import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { setPrivateTemplateSelectedList } from '../../../../../entities/templates/private/model/slice';
import { useContextMenu } from '../../../../../widgets/context-menu/libs/useContextMenu';

/** Содержимое вкладки "Шаблоны" в мастерской */
export const WorkshopTemplatesPage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [updateTemplate] = workshopTemplateAPI.useUpdateMutation();

	// Для переименования шаблона
	const [editableTemplate, setEditableTemplate] = useState<string | null>(null);
	const onFinishEditTemplateTitle = (id: string, name: string) => {
		setEditableTemplate(null);
		updateTemplate({ template: id, name });
	};

	const selectedList = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);
	const workshopTemplateList = useAppSelector(state => state.workshopTemplate.templates);

	const onContextMenuRename = () => {
		setEditableTemplate(selectedList[0]);
	};

	const [isTemplateCreating, setIsTemplateCreating] = useState(false);

	const {
		sortingData,
		currentFilter,
	} = useFilterData(FromFilterData.TEMPLATE);

	const onTemplateCreate = () => onWorkshopTemplateCreate(navigate, setIsTemplateCreating);

	const cleanSelectedList = () => dispatch(setWorkshopTemplateSelectedList([]));

	const { isLoading } = useGetWorkshopTemplates(currentFilter.sortBy, cleanSelectedList);

	const {
		menuRef,
		show,
		position,
		onShowContextMenu,
		closeContextMenu,
	} = useContextMenu(cleanSelectedList);

	return (
		<>
			<WorkshopTemplateSearchBar
				sortingData={sortingData}
				isLoading={isTemplateCreating}
				onTemplateCreate={onTemplateCreate}
			/>
			{
				// ((workshopTemplateList && workshopTemplateList.length) || isLoading)
				(!workshopTemplateList || workshopTemplateList.length)
					? (
						<>
							<WorkshopTemplatesList
								isDataLoading={isLoading}
								templates={workshopTemplateList}
								sortBy={currentFilter.sortBy}
							/>
							<DeleteWorkshopTemplateModal choicedTemplateData={selectedList} />
							<PublishWorkshopTemplateModal />
							<SelectWorkshopUserFilterModal />
						</>

					)
					: (
						<EmptyPlugList
							isCreateBtn
							isNeedProcessing={isTemplateCreating}
							btnText="Создать шаблон"
							onClick={onTemplateCreate}
							title="Создайте первый шаблон"
							description="Используйте конструктор для создания коммерческого предложения"
						/>
					)
			}

		</>
	);
};
