import React from 'react';
import { useInputValue } from '../../../../../hooks/useInput';
import css from '../authorization.module.scss';
import Logo from '../../logo/Logo';
import InputField from '../../InputField/InputField';
import RegisterBlock from '../RegisterBlock';
import { MainButton } from '../../../../ui/main-button/main-button';

interface MainAuthorizaionProps {
	isEmailValid: boolean,
	isDataValid: boolean,
	onAuthorize: () => void,
	isDataLoading: boolean,
	email: useInputValue,
	password: useInputValue,
	isPasswordHide: boolean,
	handlePasswordVisibility: () => void,
	children: React.ReactNode,
	onSetCurrentPage: () => void,
	handleKeyPressAuth: (e: React.KeyboardEvent<HTMLInputElement>) => void,
}

const MainAuthorization: React.FC<MainAuthorizaionProps> = ({
	password,
	isDataValid,
	isEmailValid,
	email,
	onSetCurrentPage,
	isDataLoading,
	onAuthorize,
	isPasswordHide,
	handlePasswordVisibility,
	handleKeyPressAuth,
	children,
}: MainAuthorizaionProps) => (
	<div className={css.wrapper}>
		<Logo
			isPasswordSent={false}
		/>
		<h2 className={css.title}>Вход</h2>
		<div className={css.block}>
			<InputField
				placeholder="E-mail"
				type="email"
				status={
					isEmailValid
						? 'error'
						: 'success'
				}
				keyDown={(e) => {
					handleKeyPressAuth(e);
				}}
				value={email.value}
				onChange={e => email.onChange(e, 'email')}
				onBlur={email.onBlur}
				disabled={false}
			/>
			<InputField
				placeholder="Пароль"
				type={isPasswordHide ? 'hidePassword' : 'visiblePassword'}
				keyDown={(e) => {
					handleKeyPressAuth(e);
				}}
				status="clear"
				value={password.value}
				onChange={e => password.onChange(e, 'password')}
				onBlur={password.onBlur}
				disabled={false}
				signClick={handlePasswordVisibility}
			/>
			<div className={css.underInput}>
				{children}
				<span
					className={css.forgetPassword}
					onClick={onSetCurrentPage}
					role="presentation"
				>
					Забыли пароль?
				</span>
			</div>
			<MainButton
				onClick={onAuthorize}
				btnStyle="bright"
				styles={css.button}
				disabled={isDataLoading || !isDataValid}
			>
				Войти
			</MainButton>
			<RegisterBlock />
		</div>
	</div>
);

export default MainAuthorization;
