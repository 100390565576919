import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setIsCreateLegalEntityModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { legalEntityAPI } from '../../../../entities/legal-entity/api/api';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';

export const useCreateLegalEntity = () => {
	const dispatch = useAppDispatch();

	const isActive = useAppSelector(state => state.legalEntity.isCreateLegalEntityModalActive);

	const setActive = (value: boolean) => dispatch(setIsCreateLegalEntityModalActive(value));

	const [create, { isLoading: isCreating }] = legalEntityAPI.useCreateMutation();

	const createLegalEntity = async (name: string) => {
		try {
			await create({ name })
				.unwrap();
			notificationSuccess('Вы успешно создали юридическое лицо!');
			setActive(false);
		} catch (e) {
			notificationError('Произошла ошибка при создании юр. лица...');
		}
	};

	return {
		isCreating,
		createLegalEntity,
		setActive,
		isActive,
	};
};
