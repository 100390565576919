import { useSelector } from 'react-redux';
import { FormEventHandler } from 'react';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopTagGroupSlice } from '../../../../entities/workshop-tag-group';
import { useEditWorkshopTagGroup } from '../../services/edit-group/useEditWorkshopTagGroup';
import { NewModal } from '../../../../components/ui/modals/new-modal';
import CreateInput from '../../../../components/pages/features/moduleWindow/createInput/CreateInput';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import css from './EditWorkshopTagGroupModal.module.scss';

export const EditWorkshopTagGroupModal = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopTagGroupSlice.selectors.getIsEditingGroupModalActive);
	const value = useSelector(workshopTagGroupSlice.selectors.getEditGroupName);

	const { onEditTagGroup, requestData } = useEditWorkshopTagGroup();

	const handleValue = (value: string) => {
		dispatch(workshopTagGroupSlice.actions.setEditGroupName(value));
	};
	const handleModal = (value: boolean) => {
		if (!value) handleValue('');

		dispatch(workshopTagGroupSlice.actions.setIsEditingGroupModalActive(value));
	};

	const disabled = !value.length || requestData.isLoading;

	const editGroup: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();

		if (!disabled) {
			await onEditTagGroup();
		}
	};

	return (
		<NewModal active={isActive} closeModal={handleModal}>
			<form className={css.wrapper} onSubmit={editGroup}>
				<div className={css.title}>
					Изменение группы тегов
				</div>
				<CreateInput
					maxLength={20}
					text={value}
					defaultValue="Введите новое название"
					subTitle="Введите название"
					onChange={handleValue}
				/>
				<MainButton
					type="button"
					styles={css.btn}
					btnStyle="bright"
					disabled={disabled}
				>
					Изменить
				</MainButton>
			</form>
		</NewModal>
	);
};
