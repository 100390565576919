import React from 'react';
import { ITeamTemplateData } from '../../../../../../../../entities/templates/team/api/types';
import { TeamTemplateCard } from '../team-template/ui/team-template';
import { TemplateListWrapper } from '../../../../../../../../widgets/templates/list-wrapper';

interface TeamTemplateListProps {
    isDataLoading: boolean,
    templates: ITeamTemplateData[] | undefined,
}

export const TeamTemplateList = ({
	templates,
	isDataLoading,
}: TeamTemplateListProps) => (
	<TemplateListWrapper isDataLoading={isDataLoading}>
		{
			templates?.map(template => (
				<TeamTemplateCard
					key={template.id}
					template={template}
				/>
			))
		}
	</TemplateListWrapper>
);
