import React, { lazy, Suspense } from 'react';
import {
	Navigate, Route, Routes, useLocation, 
} from 'react-router-dom';
import PreLoader from '../../ui/preLoader/PreLoader';
import css from './collection.module.scss';
import {
	LINK_COMMERCIAL_PANEL,
	LINK_CUSTOM_TEMPLATE_PANEL,
	LINK_GALLERY_TEMPLATE_PANEL,
	LINK_PERSONAL_ACCOUNT,
	LINK_PRIVATE_TEMPLATE_PANEL,
	LINK_SECTION_LEGAL_ENTITIES,
	LINK_SECTION_OFFER,
	LINK_SECTION_PERMISSIONS,
	LINK_SECTION_TEAMS,
	LINK_SECTION_TEMPLATE,
	LINK_SECTION_USERS,
	LINK_TEAM_TEMPLATE_PANEL,
} from '../../../widgets/tabs/collection-tabs/model/tabs-config';
import { PrivateTemplatePanel } from './section/template/private/ui/panel/private-template-panel';
import { TeamTemplatePanel } from './section/template/teams/ui/panel/team-template-panel';
import { Teams } from '../teams';
import { Users } from '../users';
import { LegalEntity } from '../legal-entity';
import { GalleryTemplatePanel } from './section/template/galleries/ui/panel/gallery-template-panel';
import { Forbidden } from '../forbidden';
import { CustomTemplatePanel } from './section/template/custom/ui/panel/custom-template-panel';

const CommercialPanelContainer = lazy(() => import('./section/commercialPanel/CommercialPanelContainer'));
const SectionTemplate = lazy(() => import('./section/template/SectionTemplate'));
const SectionOffer = lazy(() => import('./section/offer/OffersPage'));
const PersonalDataContainer = lazy(() => import('../personalAccount/personalData/PersonalDataContainer'));
const PermissionsContainer = lazy(() => import('../permissions/permissions'));

const CollectionTabBlock = () => {
	const { pathname } = useLocation();

	return (
		<>
			{
				pathname.includes('account/*')
					? <Navigate to="account/data" />
					: null
			}
			{
				pathname.includes('template/*')
					? <Navigate to="template/gallery" />
					: null
			}
			{
				pathname.includes('offer/*')
					? <Navigate to="offer/active" />
					: null
			}
			{
				pathname.includes('permissions/*')
					? <Navigate to="permissions/template" />
					: null
			}
			{
				pathname.includes('users/*')
					? <Navigate to="users/users_list" />
					: null
			}
			{
				pathname.includes('teams/*')
					? <Navigate to="teams/" />
					: null
			}
			{
				pathname.includes('legal-entities/*')
					? <Navigate to="legal-entities/" />
					: null
			}
			<Suspense fallback={(
				<div className={css.loader}>
					<PreLoader />
				</div>
			)}
			>
				<Routes>
					<Route path={`${LINK_SECTION_OFFER}*`} element={<SectionOffer />} />
					<Route path={`${LINK_SECTION_TEMPLATE}*`} element={<SectionTemplate />} />
					<Route path={`${LINK_SECTION_TEAMS}*`} element={<Teams />} />
					<Route path={`${LINK_SECTION_USERS}*`} element={<Users />} />
					<Route path={`${LINK_SECTION_PERMISSIONS}*`} element={<PermissionsContainer />} />
					<Route path={`${LINK_SECTION_LEGAL_ENTITIES}*`} element={<LegalEntity />} />

					<Route path={LINK_PRIVATE_TEMPLATE_PANEL} element={<PrivateTemplatePanel />} />
					<Route path={LINK_TEAM_TEMPLATE_PANEL} element={<TeamTemplatePanel />} />
					<Route path={LINK_GALLERY_TEMPLATE_PANEL} element={<GalleryTemplatePanel />} />
					<Route path={LINK_CUSTOM_TEMPLATE_PANEL} element={<CustomTemplatePanel />} />
					<Route path={LINK_COMMERCIAL_PANEL} element={<CommercialPanelContainer />} />

					<Route path={`${LINK_PERSONAL_ACCOUNT}*`} element={<PersonalDataContainer />} />

					<Route path="forbidden" element={<Forbidden />} />

					<Route path="*" element={<Navigate to="template/gallery" />} />
				</Routes>
			</Suspense>
		</>
	);
};

export default CollectionTabBlock;
