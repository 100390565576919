import IContextMenuSection from './IContextMenuSection';
import ElementContainer from '../../../../utils/ElementContainer';
import HTMLElementName from '../../../../utils/HTMLElementName';

class ContextMenuSection extends ElementContainer<HTMLDivElement> implements IContextMenuSection {
	private readonly ELEMENT_CLASS_NAME = 'context-menu__section';

	constructor() {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
	}
}

export default ContextMenuSection;
