import DesktopInterface from '../DesktopInterface';
import SvgCollection from '../../utils/SvgCollection';
import SketchPanel from '../bars/top-bar/sketch-panel/SketchPanel';
import FigurePanel from '../bars/top-bar/figure-panel/FigurePanel';
import PicturePanel from '../bars/top-bar/picture-panel/PicturePanel';
import TablePanel from '../bars/properties-bar/table-panel/TablePanel';
import TopBar from '../bars/top-bar/TopBar';
import InjectComponentPanel from '../bars/tool-bar/inject-component-panel/InjectComponentPanel';
import ActionPanel from '../bars/tool-bar/action-panel/ActionPanel';
import ToolBar from '../bars/tool-bar/ToolBar';
import PagePropertiesPanel from '../bars/properties-bar/panel-page-properties/PagePropertiesPanel';
import FramePanel from '../bars/properties-bar/panel-object/frame/FramePanel';
import TextPanel from '../bars/properties-bar/panel-text/TextPanel';
import LayerPanel from '../bars/properties-bar/panel-object/LayerPanel';
import PropertiesBar from '../bars/properties-bar/PropertiesBar';
import IComponentContainingText from '../../components/IComponentContainingText';
import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import TableComponent from '../../components/table/TableComponent';
import FigureComponent from '../../components/figure/FigureComponent';
import ActionStore from '../../mutations/ActionStore';
import ComponentInjector from '../../component-injector/ComponentInjector';
import IBaseSketchManipulator from '../../../SketchManipulators/IBaseSketchManipulator';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';
import PrivateTemplateUseCases from '../../use-cases/impl/PrivateTemplateUseCases';
import PageTextOverflowObserver from '../../utils/observers/PageTextOverflowObserver';
import AIPanel from '../bars/tool-bar/ai-panel/AIPanel';
import PrivateTemplateModulesAction from '../bars/tool-bar/panel-modules/PrivateTemplateModulesAction';
import ZoomPanel from '../bars/tool-bar/panel-zoom/ZoomPanel';
import ListsPanel from '../bars/tool-bar/panel-lists/ListsPanel';
import LineComponent from '../../components/line/LineComponent';
import LinePanel from '../bars/top-bar/line-panel/LinePanel';

interface ITemplateConstructorInterfaceDependencies {
	onSendOffer: VoidFunction,
	onDownloadPDF: VoidFunction,
	onBackPage: VoidFunction,
	actionStore: ActionStore,
	componentTree: IPagesComponentTree,
	componentInjector: ComponentInjector,
	sketchManipulator: IBaseSketchManipulator,
	useCases: PrivateTemplateUseCases,
	pageTextOverflowObserver: PageTextOverflowObserver,
	enableAIModalMode:VoidFunction,
	disableAIModalMode: VoidFunction,
}

class PrivateTemplateConstructorInterface extends DesktopInterface<ITemplateConstructorInterfaceDependencies> {
	private readonly sendOfferText = 'Отправить КП';
	private readonly downloadPDF = 'Скачать PDF';

	constructor() {
		super();
		this.addPostInjectDependenciesListener(dependencies => {
			this.sketchPanel = new SketchPanel({
				sketchManipulator: dependencies.sketchManipulator,
			});
			this.figurePanel = new FigurePanel(dependencies.useCases);
			this.linePanel = new LinePanel(dependencies.useCases);
			this.picturePanel = new PicturePanel(dependencies.useCases);
			this.tablePanel = new TablePanel(dependencies.useCases);

			this.aiPanel = new AIPanel(
				this.dependencies.enableAIModalMode,
				this.dependencies.disableAIModalMode,
				this.addDestructListener,
			);

			this.topBar = new TopBar(
				this.addDestructListener,
				dependencies.onBackPage,
				this.sketchPanel,
				this.picturePanel,
				this.pageTextOverflowPanel,
			);

			this.injectComponentPanel = new InjectComponentPanel(
				dependencies.useCases,
				dependencies.componentInjector,
			);
			this.actionPanel = new ActionPanel(dependencies.useCases);
			this.modulesPanel = new PrivateTemplateModulesAction(dependencies.useCases);
			this.zoomPanel = new ZoomPanel();
			this.listsPanel = new ListsPanel();

			this.toolBar = new ToolBar(
				this.aiPanel,
				this.injectComponentPanel,
				this.actionPanel,
				this.modulesPanel,
				this.listsPanel,
			);

			const rootComponent = dependencies.componentTree.getRootComponent();
			this.pagePropertiesPanel = new PagePropertiesPanel(dependencies.useCases, rootComponent);
			this.framePanel = new FramePanel(dependencies.useCases);
			this.textPanel = new TextPanel(dependencies.useCases);
			this.layerPanel = new LayerPanel(dependencies.useCases);

			this.propertiesBar = new PropertiesBar(
				this.pagePropertiesPanel,
				this.framePanel,
				this.textPanel,
				this.figurePanel,
				this.linePanel,
				this.tablePanel,
				this.layerPanel,
			);

			this.bars.push(
				this.topBar,
				this.toolBar,
				this.propertiesBar,
			);

			this.topBar
				.appendMenuItem(SvgCollection.SEND_KP, this.sendOfferText, dependencies.onSendOffer)
				.appendMenuItem(SvgCollection.PDF, this.downloadPDF, dependencies.onDownloadPDF);
		});
	}

	public sync = () => {
		const focusComponents = this.dependencies.componentTree.getFocusComponents();
		const undoCommandCount = this.dependencies.actionStore.getUndoCommandCount();
		const redoCommandCount = this.dependencies.actionStore.getRedoCommandCount();
		const focusPageGraphic = this.dependencies.componentTree.getFocusPageGraphic();
		const isEnableEditMode = this.dependencies.componentTree.getRootComponent().isEnableEditMode();

		if (isEnableEditMode) {
			this.pagePropertiesPanel.enableEditPage();
		} else {
			this.pagePropertiesPanel.disableEditPage();
		}

		this.topBar.hidePanels();
		this.pagePropertiesPanel.hide();

		const textOverflowGraphics = this.dependencies.pageTextOverflowObserver.getOverflowGraphics();
		if (textOverflowGraphics !== null) {
			this.pageTextOverflowPanel.show();
		}

		this.actionPanel.sync(undoCommandCount, redoCommandCount);

		if (focusComponents === null) {
			this.propertiesBar.hidePanels();

			this.sketchPanel.show();
			this.pagePropertiesPanel.sync(focusPageGraphic);
			this.pagePropertiesPanel.show();
			return;
		}

		this.framePanel.show();
		this.framePanel.sync(focusComponents);

		const firstComponent = focusComponents[0];
		const isSameComponentType = focusComponents.every(component => component.type === firstComponent.type);

		const isContainingTextComponents = this.isContainingTextComponents(focusComponents);

		if (isContainingTextComponents) {
			this.textPanel.sync(focusComponents as IComponentContainingText[]);
			this.textPanel.show();
		}

		if (isSameComponentType) {
			this.syncForSameComponentType(focusComponents, firstComponent.type);
		}
	};

	/**
	 * Синхронизировать интерфейс для компонентов одного типа.
	 * @param components - компоненты для сканирования.
	 * @param type - тип компонентов.
	 * @private
	 */
	private syncForSameComponentType(components: IComponent[], type: SketchComponentType) {
		this.hideAllPanels();

		switch (type) {
		case SketchComponentType.PICTURE:
			this.picturePanel.show();
			break;
		case SketchComponentType.TABLE:
			this.tablePanel.sync(components as TableComponent[]);
			this.tablePanel.show();
			this.textPanel.show();
			break;
		case SketchComponentType.TEXT:
			this.textPanel.sync(components as TableComponent[]);
			this.textPanel.show();
			break;
		case SketchComponentType.FIGURE:
			this.figurePanel.sync(components as FigureComponent[]);
			this.figurePanel.show();
			break;
		case SketchComponentType.LINE:
			this.linePanel.sync(components as LineComponent[]);
			this.linePanel.show();
			break;
		case SketchComponentType.GROUP:
			break;
		case SketchComponentType.PAGES_CONTAINER:
			break;
		case SketchComponentType.MODULE:
			break;
		default: break;
		}
	}
}

export default PrivateTemplateConstructorInterface;
