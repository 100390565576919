import { useEffect } from 'react';

const useOutsideClick = <T extends HTMLElement>(ref: React.RefObject<T>, handler: (() => void) | null) => {
	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			const aiBtn = document.getElementById('ai-panel');
			if (ref.current
				&& !ref.current.contains(event.target as Node)
				&& aiBtn
				&& (!aiBtn.contains(event.target as Node))
			) {
				if (handler) handler();
			}
		};

		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, [ref, handler]);
};

export default useOutsideClick;
