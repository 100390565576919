import ITextOption from './ITextOption';
import LineHeight from './LineHeight';
import TextOptionsView from '../TextOptionsView';

/**
 * Отвечает за логику работы с панелью выбора значения line-height.
 */
class LineHeightSelector extends TextOptionsView<LineHeight> {
	private readonly DEFAULT_VALUE: LineHeight = LineHeight.LH1;
	private readonly LINE_HEIGHT_OPTIONS: ITextOption<LineHeight>[] = [
		{ text: '1', value: LineHeight.LH1 },
		{ text: '1.2', value: LineHeight.LH1_2 },
		{ text: '1.5', value: LineHeight.LH1_HALF },
		{ text: '1.7', value: LineHeight.LH1_7 },
		{ text: '2', value: LineHeight.LH2 },
		{ text: '2.2', value: LineHeight.LH2_2 },
		{ text: '2.5', value: LineHeight.LH2_HALF },
		{ text: '2.7', value: LineHeight.LH2_7 },
		{ text: '3', value: LineHeight.LH3 },
	];

	constructor() {
		super();
		this.setOptions(this.LINE_HEIGHT_OPTIONS);
		this.setSelectedOption(this.DEFAULT_VALUE);
	}
}

export default LineHeightSelector;
