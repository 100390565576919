import React, { useState } from 'react';
import { useAppSelector } from '../../../../../../../../shared/libs/redux';
import { useSelection } from '../../../../../../../../hooks/template-selection/useSelection';
import { ITemplateData } from '../../../../../../../../entities/templates/types';
import { TemplateListWrapper } from '../../../../../../../../widgets/templates/list-wrapper';
import { PrivateTemplate } from '../private-template/private-template';
import { useIsMounted } from '../../../../../../../../hooks/lifecycle/useIsMounted';

interface PrivateTemplateListProps {
	isDataLoading: boolean,
	templates: ITemplateData[] | undefined,
	onShowContextMenu: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
	editableTemplate: string | null,
	onFinishEditTemplateTitle: (id: string, name: string) => void,
}

export const PrivateTemplateList = ({
	templates,
	isDataLoading,
	onShowContextMenu,
	editableTemplate,
	onFinishEditTemplateTitle,
}: PrivateTemplateListProps) => {
	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const isMounted = useIsMounted();

	const setGlobalLoadingState = (id: string | null) => {
		if (isMounted.current) {
			setIsGlobalLoading(!!id);
		}
	};

	useSelection();

	return (
		<TemplateListWrapper isDataLoading={isDataLoading}>
			{
				templates?.map(template => {
					let isEditTitle = false;
					if (editableTemplate === template.id) isEditTitle = true;
					return (
						<PrivateTemplate
							key={template.id}
							template={template}
							onShowContextMenu={onShowContextMenu}
							selected={selectedList.includes(template.id)}
							isEditableTitle={isEditTitle}
							onFinishEditTemplateTitle={onFinishEditTemplateTitle}
							isGlobalLoading={isGlobalLoading}
							setGlobalLoading={setGlobalLoadingState}
						/>
					);
				})
			}
		</TemplateListWrapper>
	);
};
