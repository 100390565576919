import React from 'react';
import { ReactComponent as OfferIcon } from '../../../../shared/images/collection/offer.svg';
import { ReactComponent as TemplateIcon } from '../../../../shared/images/collection/template.svg';
import { ReactComponent as TeamIcon } from '../../../../shared/images/collection/team.svg';
import { ReactComponent as UserIcon } from '../../../../shared/images/collection/user.svg';
import { ReactComponent as PermissionIcon } from '../../../../shared/images/collection/permission.svg';
import { ReactComponent as EntityIcon } from '../../../../shared/images/collection/entity.svg';
import { ReactComponent as SettingsIcon } from '../../../../shared/images/collection/settings.svg';
import { ReactComponent as IntegratorIcon } from '../../../../shared/images/collection/integrator.svg';
import { ICollectionTab } from '../../../../shared/ui/tab/model/types';
import { ACCOUNT_STATUS } from '../../../../entities/user/account/api/types';
import { DEFAULT_TO_TARIFF_URL } from '../../../../shared/ui/to-tariff-button/model/data';

export const LINK_SECTION_OFFER = 'offer/';
export const LINK_SECTION_TEMPLATE = 'template/';
export const LINK_SECTION_TEAMS = 'teams/';
export const LINK_SECTION_USERS = 'users/';
export const LINK_SECTION_PERMISSIONS = 'permissions/';
export const LINK_SECTION_LEGAL_ENTITIES = 'legal-entities/';
export const LINK_SECTION_TARIFFS = DEFAULT_TO_TARIFF_URL;

export const LINK_PERSONAL_ACCOUNT = 'account/';

export const LINK_COMMERCIAL_PANEL = 'commercial-panel/:id';
export const LINK_TEAM_TEMPLATE_PANEL = 'template-team-panel/:id';
export const LINK_PRIVATE_TEMPLATE_PANEL = 'template-private-panel/:id';
export const LINK_GALLERY_TEMPLATE_PANEL = 'template-gallery-panel/:id';
export const LINK_CUSTOM_TEMPLATE_PANEL = 'template-custom-panel/:id';

export const collectionTabsData: ICollectionTab[] = [
	{
		name: 'Предложения',
		link: LINK_SECTION_OFFER,
		picture: <OfferIcon />,
	},
	{
		name: 'Шаблоны',
		link: LINK_SECTION_TEMPLATE,
		picture: <TemplateIcon />,
	},
	{
		name: 'Команды',
		link: LINK_SECTION_TEAMS,
		picture: <TeamIcon />,
	},
	{
		name: 'Пользователи',
		link: LINK_SECTION_USERS,
		picture: <UserIcon />,
	},
	{
		name: 'Разрешения',
		link: LINK_SECTION_PERMISSIONS,
		picture: <PermissionIcon />,
	},
	{
		name: 'Юр. лица',
		link: LINK_SECTION_LEGAL_ENTITIES,
		picture: <EntityIcon />,
	},
	{
		name: 'Админ-панель',
		link: '/admin/users/',
		picture: <SettingsIcon />,
		accessible: [ACCOUNT_STATUS.SERVICE_ADMIN],
	},
	{
		name: 'Интегратор',
		link: '/integrator/workshop/',
		picture: <IntegratorIcon />,
		accessible: [ACCOUNT_STATUS.INTEGRATOR, ACCOUNT_STATUS.SERVICE_ADMIN],
	},
];
