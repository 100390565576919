import { workshopModuleApi } from '../../../../../entities/workshop-module';
import { notificationError } from '../../../../../components/Notifications/callNotifcation';

export const useUnpublishWorkshopModule = () => {
	const [unpublishWorkshopModule, requestData] = workshopModuleApi.useUnpublishFromGalleryMutation();

	const unpublish = async (moduleId?: string) => {
		try {
			if (moduleId) {
				await unpublishWorkshopModule({ modules: [moduleId] }).unwrap();
			}
		} catch (e) {
			notificationError('Не удалось снять модуль с публикации');
		}
	};

	return { unpublish, requestData };
};
