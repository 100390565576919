import React, { useEffect, useState } from 'react';
import CodeRegistration from './CodeRegistration';
import { CurrentRegistrationPageData, PASSWORD_REGISTRATION_PAGE } from '../Registration';
import { notificationError } from '../../../Notifications/callNotifcation';
import { authAPI } from '../../../../entities/user/auth/api/api';

interface CodeRegistrationContainerProps {
	email: string,
	setCurrentPage: (value: CurrentRegistrationPageData) => void,
}

const CodeRegistrationContainer = ({
	email,
	setCurrentPage,
}: CodeRegistrationContainerProps) => {
	const initDigits = ['', '', '', '', '', ''];
	const [digits, setDigits] = useState(initDigits);
	const [sentAuto, setSentAuto] = useState<boolean | undefined>(false);
	const currentCode = digits.join('');
	const isCodeValid = currentCode.length === 6;

	const onSentAuto = () => setSentAuto(true);

	const [confirmAccount, { isLoading }] = authAPI.useConfirmAccountMutation();

	const onConfirmAccount = async () => {
		try {
			await confirmAccount({
				email,
				code: currentCode,
			})
				.unwrap();

			setCurrentPage(PASSWORD_REGISTRATION_PAGE);
		} catch (e) {
			notificationError('Ошибка!', 'Произошла ошибка при отправке кода...');
		} finally {
			onSentAuto();
		}
	};

	// const sendCode = () => {
	// 	if (isCodeValid) {
	// 		setIsDataLoading(true);
	// 		API.auth.confirmAccount(confirmData)
	// 			.then(() => {
	// 				onSentAuto();
	// 				setIsDataLoading(false);
	// 				setCurrentPage(PASSWORD_REGISTRATION_PAGE);
	// 			})
	// 			.catch(error => {
	// 				onSentAuto();
	// 				setIsDataLoading(false);
	// 				notificationHandler(error.response.data.code);
	// 			});
	// 	}
	// };

	const handleKeyPressSendCode = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter' && isCodeValid) {
			await onConfirmAccount();
		}
	};

	useEffect(() => {
		if ((sentAuto === false) && isCodeValid) {
			onConfirmAccount();
		}
	}, [sentAuto, digits]);

	return (
		<CodeRegistration
			email={email}
			digits={digits}
			sendCode={onConfirmAccount}
			changeHandler={setDigits}
			isCodeValid={isCodeValid}
			isDataLoading={isLoading}
			handleKeyPressSendCode={handleKeyPressSendCode}
		/>
	);
};

export default CodeRegistrationContainer;
