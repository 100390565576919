import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryFile } from '../../../shared/api/api';
import { URI_FILE } from './consts';
import { IFileSaveRequest, IPreviewSaveRequest } from './types';

export const fileAPI = createApi({
	reducerPath: 'fileAPI',
	refetchOnReconnect: true,
	baseQuery: baseQueryFile,
	endpoints: builder => ({
		getPreview: builder.query<string, { fileID: string }>({
			query: ({ fileID }) => ({
				url: `${URI_FILE}/${fileID}`,
				method: 'GET',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		}),
		getUserFile: builder.query<string, { fileID: string }>({
			query: ({ fileID }) => ({
				url: `${URI_FILE}/${fileID}`,
				method: 'GET',
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}),
		}),
		savePreview: builder.mutation<{ id: string }, IPreviewSaveRequest>({
			query: (data) => ({
				url: `${URI_FILE}/preview`,
				method: 'POST',
				body: { ...data },
			}),
		}),
		saveUserFile: builder.mutation<{ id: string }, IFileSaveRequest>({
			query: (data) => ({
				url: `${URI_FILE}/user`,
				method: 'POST',
				body: { ...data },
			}),
		}),
	}),
});
