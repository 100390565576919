import ColorexColor from './colors/ColorexColor';
import ColorexColorCircle from './ColorexColorCircle';

class ColorexFrequentlyUsed {
	private readonly CSS_ELEMENT_CLASS_NAME = 'colorex-frequently-block';
	private readonly BASE_COLORS = [ColorexColor.Black, ColorexColor.White, ColorexColor.Transparent];

	private readonly element: HTMLElement;

	private colorCircles: ColorexColorCircle[];
	private selectColorEvent: ((color: ColorexColor) => void) | null;
	private beforeSelectColorEvent: ((color: ColorexColor) => void) | null;

	constructor(isDisableTransparent: boolean) {
		this.element = document.createElement('div');
		this.element.className = this.CSS_ELEMENT_CLASS_NAME;
		this.colorCircles = [];
		this.selectColorEvent = null;
		this.beforeSelectColorEvent = null;
		this.renderBaseColors(isDisableTransparent);
	}

	public setColors = (colors: ColorexColor[]) => {
		this.render(colors);
	};

	public addSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.selectColorEvent = ev;
	};

	public addBeforeSelectColorEvent = (ev: (color: ColorexColor) => void) => {
		this.beforeSelectColorEvent = ev;
	};

	public getElement = (): HTMLElement => this.element;

	private render = (colors: ColorexColor[]) => {
		this.colorCircles.forEach(circle => circle.remove());
		this.colorCircles = [];

		colors.forEach(color => {
			const circle = this.appendCircle(color);
			this.colorCircles.push(circle);
		});
	};

	private onSelectColorClick = (color: ColorexColor) => {
		if (this.beforeSelectColorEvent !== null) {
			this.beforeSelectColorEvent(color);
		}
		if (this.selectColorEvent !== null) {
			this.selectColorEvent(color);
		}
	};

	private appendCircle = (color: ColorexColor): ColorexColorCircle => {
		const createdCircle = new ColorexColorCircle();
		const circleElement = createdCircle.getElement();

		createdCircle.setColor(color);
		createdCircle.addClickEvent(this.onSelectColorClick.bind(this, color));
		this.element.append(circleElement);

		return createdCircle;
	};

	private renderBaseColors = (isDisableTransparent: boolean) => {
		this.BASE_COLORS.forEach(color => {
			if (isDisableTransparent) {
				if (color === 'transparent') {
					return;
				}
			}
			this.appendCircle(color);
		});
	};
}

export default ColorexFrequentlyUsed;
