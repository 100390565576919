import React, { ChangeEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../../shared/libs/classNames';
import { createWorkshopModuleSlice } from '../../../model/create-module/slice/createWorkshopModuleSlice';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import css from './SetModuleDescription.module.scss';

interface SetModuleDescriptionProps {
	className?: string,
}

export const SetModuleDescription = ({ className }: SetModuleDescriptionProps) => {
	const dispatch = useAppDispatch();

	const description = useSelector(createWorkshopModuleSlice.selectors.getDescription);
	const descriptionCount = useSelector(createWorkshopModuleSlice.selectors.getDescriptionCount);

	const handleDescription: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
		dispatch(createWorkshopModuleSlice.actions.setDescription(e.target.value));
	};

	return (
		<div className={classNames('', {}, [className])}>
			<div className={css.area}>
				<textarea
					maxLength={100}
					data-limit-row-len="true"
					rows={8}
					value={description}
					onChange={handleDescription}
				/>
				<div className={css.placeholder}>Описание</div>
			</div>
			<div className={css.charCounter}>
				{descriptionCount}
				/100
			</div>
		</div>
	);
};
