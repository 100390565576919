import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'react-ts-loaders';
import { MainButton } from '../../../../../ui/main-button/main-button';
import { IWorkshopTemplateData } from '../../../../../../entities/templates/workshop/template/model/types';
import {
	UnpublishGalleryWorkshopTemplateButton,
} from '../../../../../../features/workshop/templates/unpublish-gallery-workshop-template';
import {
	EditWorkshopTemplateTariffAvailability,
} from '../../../../../../features/workshop/templates/edit-tariff-availability';
import { WorkshopTemplateIntegratedUser } from '../integrated-user/integrated-user';
import {
	UnpublishIntegrationWorkshopTemplateButton,
} from '../../../../../../features/workshop/templates/unpublish-integration-workshop-template';
import css from './controls.module.scss';
import { useIsMounted } from '../../../../../../hooks/lifecycle/useIsMounted';

interface IntegratorPanelTemplateControlsProps {
	onDuplicate: () => void,
	openPublishModal: () => void,
	template: IWorkshopTemplateData | undefined
}

/**
 * Панель настройки шаблона мастерской
 */
export const IntegratorPanelTemplateControls = ({
	template,
	onDuplicate,
	openPublishModal,
}: IntegratorPanelTemplateControlsProps) => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const isMounted = useIsMounted();

	const editInConstructor = () => {
		setIsLoading(true);
		try {
			navigate(`/integrator/workshop/template-constructor/${template?.template.id}`);
		} catch (error) {
			console.log(`Ошибка: ${error}`);
		} finally {
			if (isMounted.current) {
				setIsLoading(false);
			}
		}
	};

	return (
		<div className={css.wrapper}>
			<div className={css.controls}>
				<MainButton
					btnStyle="bright"
					type="submit"
					onClick={editInConstructor}
					disabled={isLoading}
				>
					{
						isLoading ? (
							<div className={css.buttonContent}>
								Редактировать в конструкторе
								<Loader className={css.loader} type="ring" color="#d8d7d7" size={18} />
							</div>
						) : (
							'Редактировать в конструкторе'
						)
					}
				</MainButton>
				{
					template?.template.gallery && (
						<UnpublishGalleryWorkshopTemplateButton
							templates={[template?.template.id]}
						/>
					)
				}
				{
					template?.template.integration && (
						<UnpublishIntegrationWorkshopTemplateButton
							template={template?.template.id || ''}
							email={template?.template.integration?.email || ''}
						/>
					)
				}
				{
					(
						(!template?.template.gallery && !template?.template.integration) && (
							<>
								<MainButton
									btnStyle="transparent"
									type="submit"
									onClick={onDuplicate}
								>
									Опубликовать в галерее
								</MainButton>
								<MainButton
									btnStyle="transparent"
									type="submit"
									onClick={openPublishModal}
								>
									Опубликовать у пользователя
								</MainButton>
							</>
						)
					)
				}
			</div>
			{
				!template?.template.integration && template?.template.gallery && (
					<EditWorkshopTemplateTariffAvailability
						template={template?.template.id || ''}
						gallery={template?.template.gallery}
					/>
				)
			}
			{
				template?.template.integration && !template?.template.gallery && (
					<WorkshopTemplateIntegratedUser user={template.template.integration} />
				)
			}
		</div>
	);
};
