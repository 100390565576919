import { RootState } from '../../reducers/reducer';

const all = (state: RootState) => state.tag;

const tagModalsActive = (state: RootState) => all(state).modalsActive;

const tags = (state: RootState) => all(state).tagsData;

export {
	all,
	tags,
	tagModalsActive,
};
