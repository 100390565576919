import { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classNames } from '../../../../../shared/libs/classNames';
import { ModuleTag } from '../../../../../entities/modules/tag/ui/module-tag';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';
import css from './FilterModulesTagBar.module.scss';
import { IFilterModulesItem } from '../filter-modules-by-tags/FilterWorkshopModulesByTags';

interface FilterModulesTagBarProps {
	className?: string,
	tags?: IFilterModulesItem[],
	selectedTagsIds?: number[],
	showed?: boolean,
	selectTag?: (id: number) => void,
}

export const FilterModulesTagBar = ({
	className, tags, selectTag, selectedTagsIds, showed,
}: FilterModulesTagBarProps) => {
	const ref = useRef<HTMLDivElement>(null);

	return (
		<div className={classNames('', {}, [className])}>
			<CSSTransition
				timeout={200}
				unmountOnExit
				nodeRef={ref}
				in={showed}
				classNames={popUpTransitionClasses}
			>
				<div className={css.tags_wrapper} ref={ref}>
					{
						tags?.map(tag => {
							const { id } = tag;

							const onSelectTag = () => selectTag?.(id);

							const isActive = selectedTagsIds?.includes(id);

							return (
								<ModuleTag
									tag={tag}
									key={id}
									onClick={onSelectTag}
									isActive={isActive}
								/>
							);
						})
					}
				</div>
			</CSSTransition>
		</div>
	);
};
