import React, { useEffect, useState } from 'react';
import { Title } from '../../../ui/title';
import { FindBlock } from '../../../../widgets/entity/find-block';
import { TeamsList } from './list';
import { checkUsersRights, checkUsersRightsInterface } from '../../../../helpers/usersRights';
import { CreateTeamModal } from './modals/create/create';
import { useCreateTeam } from '../lib/useCreateTeam';
import { useDeleteTeam } from '../lib/useDeleteTeam';
import { teamAPI } from '../../../../entities/team/api/api';
import { useUntieUser } from '../lib/useUntieUser';
import { DeleteModalWrapper } from '../../../ui/delete-modal-wrapper/delete-modal-wrapper';
import EntityWrapper from '../../../ui/entity/entity-wrapper/entity-wrapper';
import TeamMultipleSelection from './modals/multiple-selection/multiple-selection';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import { useAppSelector } from '../../../../shared/libs/redux';
import { IAccount } from '../../../../entities/user/account/api/types';
import { ContentLayout } from '../../layouts/content';

export const Teams = () => {
	const status = useAppSelector(state => state.personalAccount.account.status);

	const [searchValue, setSearchValue] = useState('');

	const debounceValue = useDebounce(searchValue, 250);

	const [choicedUser, setChoicedUser] = useState<IAccount | null>(null);

	const [statusRights, setStatusRights] = useState<checkUsersRightsInterface>(checkUsersRights(status));

	const {
		data: teams,
		isLoading,
	} = teamAPI.useGetAllQuery({
		page: 1,
		limit: 127,
		search: debounceValue,
		employee: choicedUser?.email,
	});

	const {
		untieUser,
		isTeamUserConfirmDeleteModalActive,
		handleIsTeamUserConfirmDeleteModalActive,
	} = useUntieUser();

	const {
		isCreating,
		createTeam,
		handleCreateTeamModal,
	} = useCreateTeam();

	const {
		deleteTeam,
		isTeamConfirmDeleteModalActive,
		handleIsTeamConfirmDeleteModalActive,
	} = useDeleteTeam();

	// * Обновляет права аккаунта при подтягивании статуса из state
	useEffect(() => {
		setStatusRights(checkUsersRights(status));
	}, [status]);

	return (
		<ContentLayout>
			<EntityWrapper>
				<Title underlined>Команды</Title>
				<FindBlock
					openCreateModal={() => handleCreateTeamModal(true)}
					btnText="Создать команду"
					searchPlaceholder="Поиск по командам"
					searchValue={searchValue}
					choicedUser={choicedUser}
					setChoicedUser={setChoicedUser}
					setSearchValue={setSearchValue}
					registrationRight={statusRights.registration}
				/>
				<TeamsList
					teams={teams?.teams}
					isLoading={isLoading}
					statusRights={statusRights}
				/>
				<CreateTeamModal
					onCreate={createTeam}
					isCreating={isCreating}
					setIsActive={handleCreateTeamModal}
				/>
				<DeleteModalWrapper
					description="Вы действительно хотите удалить команду без возможности восстановления?"
					onDelete={deleteTeam}
					isActive={isTeamConfirmDeleteModalActive}
					setIsActive={handleIsTeamConfirmDeleteModalActive}
				/>
				<DeleteModalWrapper
					description="Вы действительно хотите удалить пользователя без возможности восстановления?"
					onDelete={untieUser}
					isActive={isTeamUserConfirmDeleteModalActive}
					setIsActive={handleIsTeamUserConfirmDeleteModalActive}
				/>
				<TeamMultipleSelection />
			</EntityWrapper>
		</ContentLayout>
	);
};
