import React from 'react';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { useUnpublishGalleryWorkshopTemplate } from '../api/useUnpublishGalleryWorkshopTemplate';

interface UnpublishWorkshopTemplateButtonProps {
	templates: string[];
}

export const UnpublishGalleryWorkshopTemplateButton = ({ templates }: UnpublishWorkshopTemplateButtonProps) => {
	const {
		isLoading,
		onUnpublish,
	} = useUnpublishGalleryWorkshopTemplate();

	return (
		<MainButton onClick={() => onUnpublish(templates)} disabled={isLoading} btnStyle="transparent" type="submit">
			Снять с публикации
		</MainButton>
	);
};
