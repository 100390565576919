import { useNavigate, useParams } from 'react-router-dom';
import { ContentLayout } from '../../../../../../layouts/content';
import { CopyLinkButton, GoBackButton } from '../../../../../../../../shared/ui';
import css from './custom-template-panel.module.scss';
import { customTemplateAPI } from '../../../../../../../../entities/templates/custom/api/api';
import { MainButton } from '../../../../../../../ui/main-button/main-button';
import { ChangeableTagList } from '../../../../../../../../widgets/changeable-tag-bar/ui/tag-list/changeable-tag-list';
import ComponentSketchStructureVisualizer 
	from '../../../../../../../SketchManipulators/SketchStructureVisualizer/ComponentSketchStructureVisualizer';
import { useMoveCustomTemplate } 
	from '../../../../../../../../features/templates/custom/move/api/useMoveCustomTemplate';

export const CustomTemplatePanel = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { data } = customTemplateAPI.useGetQuery({ id: id || '' }, { skip: !id });
	const { isLoading, onMoveTemplate } = useMoveCustomTemplate();

	const moveTemplate = async () => onMoveTemplate([{ id: id || '' }]);

	const tags = data?.template?.tags?.fromTemplates?.concat(data?.template?.tags?.fromModules || []) || [];
	return (
		<ContentLayout>
			<div className={css.wrapper}>
				<GoBackButton onClick={() => navigate(-1)} />
				<div className={css.title_wrapper}>
					<div className={css.title}>{data?.template.name}</div>
					<CopyLinkButton />
				</div>
				{
					(data?.template?.tags?.fromTemplates || data?.template?.tags?.fromModules) && (
						<div className={css.tags}>
							<h3>Теги</h3>
							<ChangeableTagList
								isNeedHint={false}
								disabledTags={tags}
								activeTags={[]}
							/>
						</div>
					)
				}
				<MainButton onClick={moveTemplate} disabled={isLoading} btnStyle="bright" type="button">
					Перенести в «Мои шаблоны»
				</MainButton>
				<div className={css.description}>
					Чтобы редактировать шаблон в конструкторе, сохраните его в раздел «Мои
					шаблоны»
				</div>
			</div>
			{
				data && (
					<ComponentSketchStructureVisualizer structure={{
						...data.template,
						root: data.rootComponent,
					}}
					/>
				)
			}
		</ContentLayout>
	);
};
