import React, { useState } from 'react';
import FilterBox, { FilterBoxData } from '../../../my/FilterBox';
import css from './search-bar.module.scss';
import { CreateButton } from '../../../../../../../../shared/ui';
import { AdvancedTemplateSearch } from '../../../../../../../../widgets/templates/advanced-template-search';
import {
	AdvancedSearchTagDropdown,
} from '../../../../../../../../widgets/templates/advanced-search-tag-dropdown/ui/advanced-search-tag-dropdown';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/libs/redux';
import {
	useAdvancedSearchSelectedTags,
} from '../../../../../../../../shared/libs/templates/useAdvancedSearchSelectedTags';
import { ITagData } from '../../../../../../../../entities/modules/tag/api/types';
import { templateTagAPI } from '../../../../../../../../entities/templates/tags/api/api';
import { useDebounce } from '../../../../../../../../shared/libs/useDebounce';
import { useCreateTemplateTag } from '../../../../../../../../features/templates/tag/create/api/useCreateTemplateTag';
import {
	setPrivateTagFilter,
	setPrivateTemplateSearch,
} from '../../../../../../../../entities/templates/private/model/slice';

interface PrivateTemplateSearchBarProps {
	isLoading: boolean,
	sortingData: FilterBoxData,
	onTemplateCreate: () => void,
}

export const PrivateTemplateSearchBar = ({
	isLoading,
	sortingData,
	onTemplateCreate,
}: PrivateTemplateSearchBarProps) => {
	const dispatch = useAppDispatch();

	const [tagSearch, setTagSearch] = useState('');
	const [isDropdownVisible, setIsDropdownVisible] = useState(false);

	const search = useAppSelector(state => state.privateTemplate.search);
	const selectedTags = useAppSelector(state => state.privateTemplate.tagFilter);

	const setSelectedTags = (tags: ITagData[]) => dispatch(setPrivateTagFilter(tags));
	const setSearch = (value: string) => dispatch(setPrivateTemplateSearch(value));

	const { handleTag } = useAdvancedSearchSelectedTags(selectedTags, setSelectedTags);
	const { onCreateTag } = useCreateTemplateTag();

	const debounceSearch = useDebounce(tagSearch.trim(), 200);

	const { data } = templateTagAPI.useGetAllQuery({
		limit: 127,
		page: 1,
		search: debounceSearch,
	});

	return (
		<>
			<div className={css.add_block}>
				<CreateButton disabled={isLoading} onClick={onTemplateCreate}>
					Создать шаблон
				</CreateButton>
				<AdvancedTemplateSearch
					search={search}
					selectedTags={selectedTags}
					isDropdownVisible={isDropdownVisible}
					setSearch={setSearch}
					setIsDropdownVisible={setIsDropdownVisible}
					handleTag={handleTag}
				>
					<AdvancedSearchTagDropdown
						isDropdownVisible={isDropdownVisible}
						tags={data?.templateTags || []}
						setSearch={setTagSearch}
						search={tagSearch}
						selectedTags={selectedTags}
						createTag={onCreateTag}
						setSelectedTags={setSelectedTags}
					/>
				</AdvancedTemplateSearch>
			</div>
			<div className={css.filter}>
				<FilterBox sortingData={sortingData} />
			</div>
		</>
	);
};
