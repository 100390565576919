import React from 'react';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import { ReactComponent as PdfIcon } from '../../../images/pdf.svg';
import css from './download.module.scss';

interface DownloadButtonProps {
	download: () => void,
	disabled?: boolean,
	children?: React.ReactNode,
}

export const DownloadButton = ({
	download,
	disabled,
	children,
}: DownloadButtonProps) => (
	<MainButton
		onClick={download}
		btnStyle="light"
		type="button"
		disabled={disabled}
	>
		<div className={css.wrapper}>
			<PdfIcon />
			{children}
		</div>
	</MainButton>
);
