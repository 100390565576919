import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../shared/libs/redux';
import { ITEM_LIMIT_PER_PAGE } from '../../SectionTemplate';
import useDynamicPagination from '../../../../../../../hooks/useDynamicPagination';
import { customTemplateAPI } from '../../../../../../../entities/templates/custom/api/api';
import { setCustomTemplates } from '../../../../../../../entities/templates/custom/model/slice';
import { ICustomTemplateData } from '../../../../../../../entities/templates/custom/model/types';

const useGetCustomTemplates = () => {
	const dispatch = useAppDispatch();

	const templates = useAppSelector(state => state.customTemplate.customTemplates);

	const setList = (list: ICustomTemplateData[]) => dispatch(setCustomTemplates(list));

	const {
		data: initialTemplates,
		isLoading,
	} = customTemplateAPI.useGetAllQuery({
		page: 1,
		limit: ITEM_LIMIT_PER_PAGE,
	});

	const [getTemplates] = customTemplateAPI.useLazyGetAllQuery();

	const fetchData = (page: number, perPage: number) => {
		if (templates) {
			return getTemplates({
				page,
				limit: perPage,
			})
				.unwrap()
				.then(data => setList([...templates, ...data.customTemplates]));
		}
		return undefined;
	};

	const {
		setCurrentPage,
		setTotalCount,
	} = useDynamicPagination(
		fetchData,
		ITEM_LIMIT_PER_PAGE,
		2,
		templates?.length,
	);

	// * При изменении поискового значения, вмонтировании компонента
	useEffect(() => {
		if (initialTemplates) {
			setCurrentPage(2);
			setList(initialTemplates?.customTemplates || []);

			setTotalCount(initialTemplates?.totalCount || 0);
		}
	}, [initialTemplates]);

	return { isLoading };
};

export default useGetCustomTemplates;
