import IImportTableStructure from '../import-table/IImportTableStructure';
import { notificationError } from '../../../Notifications/callNotifcation';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import { TableGridMap } from '../../graphic/table/TableGridMap';
import { Token, TokenFormat, TokenType } from '../mext/parser';
import {
	FontFamily, FontSize, Model, TextAlign, 
} from '../mext/editor/types';
import Utils from '../../utils/impl/Utils';
import IGraphic from '../../graphic/IGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import IComponentTree from '../../component-tree/IComponentTree';
import ILayeredComponentTree from '../../component-tree/ILayeredComponentTree';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import Dependent from '../../utils/dependent/Dependent';
import SketchComponentType from '../../components/SketchComponentType';
import GraphicType from '../../graphic/GraphicType';
import IGraphicStructure from '../../graphic/IGraphicStructure';
import ITableGraphicTexture from '../../graphic/table/ITableGraphicTexture';
import ITableComponentTexture from '../../components/table/ITableComponentTexture';
import IComponentStructure from '../../components/IComponentStructure';
import TableComponent from '../../components/table/TableComponent';
import TableGraphic from '../../graphic/table/TableGraphic';
import IDescartesPosition from '../../utils/IDescartesPosition';
import IComponentInjectionParams from '../../component-injector/IComponentInjectionParams';
import ComponentOrganizer from '../component-organizer/ComponentOrganizer';
import SketchStructureStabilizer from '../mutation-observer/SketchStructureStabilizer';
import ComponentUIObserver from '../../utils/observers/ComponentUIObserver';

export interface IHTMLTableImporterDependencies {
	componentOrganizer: ComponentOrganizer,
	sketchStabilizer: SketchStructureStabilizer,
	mousePositionObserver: MousePositionObserver;
	componentFocusObserver: ComponentUIObserver,
	componentTree: IComponentTreeMutator & IComponentTree & ILayeredComponentTree;
}

interface IStylesHTML { background: string; tokens: Token[] }

class HTMLTableImporter extends Dependent<IHTMLTableImporterDependencies> {
	private readonly EMPTY_VALUE = '';

	private readonly prevInjectListeners: VoidFunction[];
	private readonly postInjectListeners: VoidFunction[];

	constructor() {
		super();
		this.prevInjectListeners = [];
		this.postInjectListeners = [];

		this.addPrevInjectListener(() => {
			this.dependencies.sketchStabilizer.startUserAction();
		});
		this.addPostInjectListener(() => {
			setTimeout(this.dependencies.componentOrganizer.sync, 0);
			setTimeout(this.dependencies.componentOrganizer.sync, 100);
			setTimeout(this.dependencies.componentOrganizer.sync, 200);
			setTimeout(this.dependencies.componentFocusObserver.sync.bind(this), 100);
			setTimeout(this.dependencies.sketchStabilizer.stopUserAction, 200);
		});
	}

	/**
	 * Вставляет таблицы в приложение на основе переданной HTML-строки.
	 * Метод парсит входящую строку, извлекает таблицы и преобразует их в
	 * структуру, которую можно использовать в приложении. Если таблицы не найдены,
	 * выводится уведомление об ошибке.
	 * @param htmlString HTML строка таблицы.
	 */
	public importTable = (htmlString: string) => {
		const tableStructures = this.getStuctures(htmlString);
		
		if (tableStructures.length === 0) {
			notificationError('Вставка таблицы.', 'В буффере нет таблиц.');
		}

		this.callPrevInjectListeners();

		// TODO сделать вставку больше чем одной таблицы
		this.injectTable(tableStructures[0]);
	};

	/**
	 * Возвращает структуры таблиц, найденных в переданном HTML.
	 * @param htmlString Строка HTML, содержащая таблицы.
	 */
	public getStuctures = (htmlString: string): IImportTableStructure[] => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlString, 'text/html');

		// Извлекаем таблицы из переданного HTML
		const tables = doc.querySelectorAll('table');
		if (tables.length === 0) {
			return [];
		}

		const tableStructures: IImportTableStructure[] = [];
		tables.forEach((tableElement) => {
			const tableStructure = this.convertHTMLTableToStructure(tableElement);
			tableStructures.push(tableStructure);
		});
		return tableStructures;
	};

	/**
	 * Конвертирует HTML таблицу в структуру таблицы.
	 * @param table HTML элемент таблицы.
	 */
	private convertHTMLTableToStructure = (table: HTMLTableElement): IImportTableStructure => {
		// Создаем временный невидимый контейнер и добавляем его на страницу
		const container = this.getTemporaryContainer();
		// Вставляем HTML таблицу в контейнер
		container.appendChild(table);
		
		const { rows } = table;

		const columnCount = Math.max(...Array.from(rows).map((row) => row.cells.length));
		const rowCount = rows.length;

		const tableStructure: IImportTableStructure = {
			cells: [],
			columnCount,
			rowCount,
		};

		const cells: ITableCellTexture[][] = [];
		const cellsGrid: TableGridMap = [];

		let currentRowsIndex = 0;
		for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
			const row = rows[currentRowsIndex];
			if (cells[rowIndex] === undefined) {
				cells[rowIndex] = [];
			}
			let currentCellsIndex = 0;
			for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
				const cellElement: HTMLTableCellElement = row.cells[currentCellsIndex];
				if (cellElement) {
					// Пропускаем уже занятые ячейки
					while (cellsGrid[rowIndex] && cellsGrid[rowIndex][columnIndex]) {
						columnIndex++;
					}

					const cellTexture = this.getDefaultCellTexture(this.EMPTY_VALUE, rowIndex, columnIndex);
					cellTexture.rowSpan = cellElement.rowSpan;
					cellTexture.columnSpan = cellElement.colSpan;

					const { background, tokens } = this.getStyledContentFromHTML(cellTexture, cellElement);

					cellTexture.background = background;
					cellTexture.content.tokens = [...tokens];

					cells[rowIndex][columnIndex] = cellTexture;

					// Построение TableGridMap (см. в описании типа).
					for (let currentRow = rowIndex; currentRow < rowIndex + cellElement.rowSpan; currentRow++) {
						if (!cellsGrid[currentRow]) cellsGrid[currentRow] = [];
						for (let currentColumn = columnIndex; currentColumn
						< columnIndex + cellElement.colSpan; currentColumn++) {
							if (!cellsGrid[currentRow][currentColumn]) {
								cellsGrid[currentRow][currentColumn] = cellTexture.id;
							}
						}
					}
				}
				currentCellsIndex++;
			}
			currentRowsIndex++;
		}

		this.convertEmptyToEmptyCells(cells, columnCount, cellsGrid);
		tableStructure.cells = this.flatCells(cells);

		// Удаляем контейнер
		this.removeTemporaryContainer(container);

		return tableStructure;
	};

	/**
	 * Возвращает вычисленные стили из HTML-элемента ячейки таблицы.
	 * @param cell Текстура ячейки таблицы содержащяя информацию о стилях ячейки.
	 * @param cellElement HTML-элемент ячейки таблицы, из которого будут извлечены стили.
	 */
	private getStyledContentFromHTML = (cell: ITableCellTexture, cellElement: HTMLTableCellElement): IStylesHTML => {
		const styledCell = { ...cell };

		// Проверяем цвет заднего фона
		if (cellElement.style.backgroundColor) {
			styledCell.background = cellElement.style.backgroundColor;
		}

		const tokens = this.getTokensFromElement(cellElement);
		return { background: styledCell.background, tokens };
	};

	private injectTable = (importTableStructure: IImportTableStructure) => {
		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const { rowCount, columnCount, cells } = importTableStructure;

		if (cells.length < 1) {
			notificationError('Вставка таблицы.', 'Вставляемая таблица не имеет ячеек с текстом.');
			return;
		}

		const parentComponent = this.dependencies.componentTree.getRootComponent();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const injectionParams = this.calculateComponentInjectionParams(mousePosition);
		const injectionPageGraphic = parentComponent.getGraphics()[injectionParams.componentOffset];
		const pageGlobalPosition = injectionPageGraphic.getGlobalPosition();

		const { paddingLeft, paddingRight } = injectionPageGraphic.getTexture();
		const { width } = injectionPageGraphic.getFrameConfiguration();

		const graphic: IGraphicStructure<ITableGraphicTexture> = {
			id: graphicId,
			type: GraphicType.TABLE,
			frame: {
				width: width - paddingRight - paddingLeft,
				height: 0,
				layer: 0,
				x: paddingLeft,
				y: mousePosition.y - pageGlobalPosition.y,
				rotate: 0,
			},
			offset: 0,
			texture: {
				rowCount,
			},
		};

		const tableStructure: IComponentStructure<ITableComponentTexture> = {
			id: componentId,
			type: SketchComponentType.TABLE,
			offset: injectionParams.componentOffset,
			graphics: [graphic],
			texture: {
				startRows: [0],
				cells,
				borderColor: 'black',
				columnMultipliers: new Array(columnCount).fill(1),
				rowMultipliers: new Array(rowCount).fill(1),
			},
			components: null,
		};

		let component: TableComponent;
		this.dependencies.componentTree.executeMutations((tools) => {
			component = tools.componentFactory.createComponent<TableComponent>(tableStructure);
			const graphicSequence = new Map<IGraphic, TableGraphic>();

			tableStructure.graphics?.forEach((graphicStructure) => {
				const graphic = tools.graphicFactory.createGraphic<TableGraphic>(
					GraphicType.TABLE,
					component,
				);
				graphic.setStructure(() => graphicStructure);

				component.appendGraphic(graphic);

				if (tableStructure.offset === null) {
					throw new ManipulatorError('the table structure does not have an offset');
				}

				const pageNumber = tableStructure.offset + graphicStructure.offset;
				const lastPageLayerGraphic = tools.componentLayers.getLastLayerGraphicFromPage(pageNumber);

				graphicSequence.set(lastPageLayerGraphic, graphic);
			});

			tools.mutator.mutateByAppendComponent(parentComponent, component);

			graphicSequence.forEach((tableGraphic, prevGraphic) => {
				tools.mutator.mutateByChangeLayer(prevGraphic, tableGraphic);
			});

			this.callPostInjectListeners();
		});
	};

	private addPostInjectListener(listener: () => void) {
		this.postInjectListeners.push(listener);
	}

	private callPostInjectListeners = () => {
		this.postInjectListeners.forEach(listener => listener());
	};

	private addPrevInjectListener(listener: () => void) {
		this.prevInjectListeners.push(listener);
	}

	private callPrevInjectListeners = () => {
		this.prevInjectListeners.forEach(listener => listener());
	};

	/**
	 * Возвращает стилизованный токен текста из HTML элемента.
	 * @param element HTML-элемент, из которого будут извлечены токены.
	 */
	private getTokensFromElement = (element: HTMLElement): Token[] => {
		const tokens: Token[] = [];
		element.childNodes.forEach(child => this.recursiveTokenCollecting(child as HTMLElement, tokens));

		if (tokens.length === 0) {
			tokens.push(this.getDefaultTextToken(this.EMPTY_VALUE));
		}

		return tokens;
	};

	private recursiveTokenCollecting = (element: HTMLElement, tokens: Token[]) => {
		if (element.nodeType === Node.ELEMENT_NODE) {
			const isParagraphWithText = ((element.tagName === 'P'
					&& (element.textContent
						&& element.textContent.trim().length > 1)
					&& (tokens.length > 0
						&& tokens[tokens.length - 1].value !== '\n'))
				|| element.tagName === 'BR');

			const isWhitespaceSpan = (element.tagName === 'SPAN' && element.textContent === ' ');
			
			if (isParagraphWithText) {
				const computedStyle = window.getComputedStyle(element);
				const token = this.getDefaultTextToken('\n');
				this.applyTextStyles(token, computedStyle);
				tokens.push(token);
			}

			// Проверка вложенного <span> с пробелом
			if (isWhitespaceSpan) {
				const computedStyle = window.getComputedStyle(element);
				const token = this.getDefaultTextToken(' ');
				this.applyTextStyles(token, computedStyle);
				tokens.push(token);
			}
		}
		if (element.nodeType === Node.TEXT_NODE) {
			const { textContent } = element;
			if (textContent && textContent.trim().length > 0) {
				const parentElement = element.parentElement as HTMLElement;
				const computedStyle = window.getComputedStyle(parentElement);
				const cleanText = textContent
					.replace(/[\n\r\u2028\u2029]/g, '')
					.replace(/\s{2,}/g, ' ');
				const token = this.getDefaultTextToken(cleanText);
				this.applyTextStyles(token, computedStyle);
				tokens.push(token);
			}
		} else {
			element.childNodes.forEach(child => this.recursiveTokenCollecting(child as HTMLElement, tokens));
		}
	};

	/**
	 * Применяет форматирование к токену в соответствии с вычисленными стилями текста.
 	 * @param token - Токен, к которому будут применены стили.
 	 * @param styles - Вычисленные CSS-стили, которые будут применены к токену.
 	 */ 
	private applyTextStyles = (token: Token, styles: CSSStyleDeclaration) => {
		const {
			color, fontWeight, fontStyle, fontSize, fontFamily, textAlign, 
		} = styles;

		// Проверяем цвет текста
		if (color) {
			token.color = color;
		}
		// Проверяем жирность текста
		if (fontWeight >= '700') {
			token.format = TokenFormat.Bold;
		}

		// Проверяем курсив
		if (fontStyle === 'italic') {
			token.format = TokenFormat.Italic;
		}

		// Проверяем на жирный курсив
		if (fontWeight >= '700' && fontStyle === 'italic') {
			token.format = TokenFormat.BoldItalic;
		}

		// Проверяем размер шрифта
		if (fontSize) {
			const size = this.convertPxToPt(fontSize);
			// Проверяем, соответствует ли значение одному из значений в FontSize
			const fontSizeEnum = Object.entries(FontSize).find(
				([_, value]) => value === size,
			);

			if (fontSizeEnum) {
				token.fontSize = size as FontSize;
			}
		}

		// Семейство шрифтов
		if (fontFamily) {
			const family = fontFamily.replace(/,\s*sans-serif$/, '');
			
			// Проверяем, соответствует ли значение одному из значений в FontFamily
			const fontFamilyEnum = Object.entries(FontFamily).find(
				([_, value]) => value === family,
			);

			if (fontFamilyEnum) {
				token.fontFamily = family as FontFamily;
			}
		}

		// Выравнивание текста
		if (textAlign) {
			token.textAlign = textAlign as TextAlign;
		}
	};

	/**
	 * Конвертирует px(пиксели) в pt(пункты).
	 * @param px Писксели, которые необходимо конвертировать.
	 */
	private convertPxToPt = (px: string): string => `${Math.ceil(parseFloat(px) * (3 / 4))}pt`;

	/**
	 * Возвращает текстуру ячейки по умолчанию.
	 * @param value Текст в ячейке.
	 * @param rowIndex Позиция ячейки по оси Y.
	 * @param columnIndex Позиция ячейки по оси X.
	 */
	private getDefaultCellTexture = (
		value: string,
		rowIndex: number,
		columnIndex: number,
	): ITableCellTexture => {
		const model = this.getDefaultModel(value);
		return {
			id: Utils.Generate.UUID4(),
			content: model,
			background: '#ffffff',
			row: rowIndex,
			column: columnIndex,
			rowSpan: 1,
			columnSpan: 1,
		};
	};

	/**
	 * Возвращает из двумерного массива текстур ячеек линейный массив.
	 * @param cells Двумерный массив ячеек, отражающий структуру таблицы.
	 */
	private flatCells = (cells: (ITableCellTexture | null)[][]): ITableCellTexture[] => cells
		.map((rowCells) => rowCells
			.filter((cell) => cell !== null) as ITableCellTexture[]).flat();

	/**
	 * Заполняет пустующие (не те, которые занимают ячейки в объединении, а с полным отсутствием в ней ячейки)
	 * координаты таблицы.
	 * @param cells Матрица текстур ячеек таблицы с пустующими координатами.
	 * @param columnCount Количество колонок.
	 * @param cellsGrid Карта ячеек (см. описание типа).
	 */
	private convertEmptyToEmptyCells = (
		cells: (ITableCellTexture | null)[][],
		columnCount: number,
		cellsGrid: TableGridMap,
	) => {
		for (let rowIndex = 0; rowIndex < cellsGrid.length; rowIndex++) {
			// Обязательно `columnIndex < columnCount` для заполнения ячейками крайних позиций таблицы.
			for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
				if (cellsGrid[rowIndex] === undefined) {
					cellsGrid[rowIndex] = [];
				}
				if (cellsGrid[rowIndex][columnIndex] === undefined) {
					cells[rowIndex][columnIndex] = this.getDefaultCellTexture(
						this.EMPTY_VALUE,
						rowIndex,
						columnIndex,
					);
				}
			}
		}
	};

	/**
	 * Возвращает модель текста по умолчанию.
	 * @param content Текст модели.
	 */
	private getDefaultModel = (content: string): Model => ({
		id: Utils.Generate.UUID4(),
		lineHeight: 1,
		tokens: [this.getDefaultTextToken(content)],
	});
	
	private getDefaultTextToken = (content: string): Token => ({
		type: TokenType.Text,
		value: content,
		color: '#000000',
		format: 0o00000100,
		fontSize: FontSize.Pt12,
		textAlign: TextAlign.LEFT,
		fontFamily: FontFamily.Default,
		lineHeight: 1,
		sticky: true,
	});

	/**
	 * Возврщает временный контейнер.
	 */
	private getTemporaryContainer = (): HTMLDivElement => {
		const container = document.createElement('div');
		container.style.display = 'none';
		container.style.top = '-5000px';
		container.style.left = '-5000px';
		document.body.appendChild(container);
		return container;
	};

	/**
	 * Удалает временный контейнер.
	 * @param container Удаляемый контейнер.
	 */
	private removeTemporaryContainer = (container: HTMLDivElement) => {
		document.body.removeChild(container);
	};

	/**
	 * Вычисляет параметры инжекции для компонента по позиции мыши.
	 * @param mousePosition Текущая позиция мыши.
	 */
	private calculateComponentInjectionParams = (
		mousePosition: IDescartesPosition,
	): IComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(mousePosition.y - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		const graphicPosition: IDescartesPosition = nearestPage.getGlobalPosition();
		const componentOffset: number = nearestPage.getOffset();
		return {
			x: graphicPosition.x,
			y: graphicPosition.y,
			componentOffset,
		};
	};
}

export default HTMLTableImporter;
