import ManipulatorCommand from '../ManipulatorCommand';
import CommandType from '../CommandType';
import ISketchNameChangeBody from './ISketchNameChangeBody';
import IBaseSketchManipulator from '../../../../SketchManipulators/IBaseSketchManipulator';

class SketchNameChangeCommand extends ManipulatorCommand<ISketchNameChangeBody> {
	public type: CommandType = CommandType.SKETCH_NAME_CHANGE;

	private readonly manipulator: IBaseSketchManipulator;
	private readonly name: string;

	constructor(manipulator: IBaseSketchManipulator, name: string) {
		super();
		this.name = name;
		this.manipulator = manipulator;
	}

	public exec = (): void => {
		this.manipulator.setName(this.name);
	};

	/**
	 * Конвертирует данные команды в формат, требуемый API.
	 */
	protected buildBody = (): ISketchNameChangeBody => {
		const sketchId = this.manipulator.getID();
		return {
			sketch: sketchId,
			name: this.name,
		};
	};
}

export default SketchNameChangeCommand;
