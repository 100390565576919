import React from 'react';
import TransitionActionsWithCards, { ActionsWithCardsProps } from '../actionsWithCards/TransitionActionsWithCards';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';

const AutoCloseActionsCards = ({
	...props
}: ActionsWithCardsProps) => {
	const r = useOutSideClick(props.onClose);
	return (
		<TransitionActionsWithCards ref={r} {...props} />
	);
};

export default AutoCloseActionsCards;
