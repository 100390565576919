import { classNames } from '../../../../shared/libs/classNames';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { CreateCategoryInput } from '../../../chengable-category-bar/ui/create-category-input/input';
import { useCreateWorkshopModuleCategory } from '../../services/useCreateWorkshopModuleCategory';
import { useBindWorkshopModuleCategory } from '../../services/useBindWorkshopModuleCategory';

interface CreateWorkshopCategoryInputProps {
	className?: string,
	moduleId?: string,
}

export const CreateWorkshopCategoryInput = ({ className, moduleId }: CreateWorkshopCategoryInputProps) => {
	const { onCreateCategory } = useCreateWorkshopModuleCategory();
	const { onBindCategory } = useBindWorkshopModuleCategory(moduleId);

	const createCategory = async (name: string) => {
		try {
			const res = await onCreateCategory(name);
			await onBindCategory(res);
		} catch (e) {
			notificationError('Произошла ошибка при создании категории', 'Не удалось создать категорию');
		}
	};

	return (
		<div className={classNames('', {}, [className])}>
			<CreateCategoryInput onCreateCategory={createCategory} />
		</div>
	);
};
