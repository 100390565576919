import { SELECTABLE_ITEM_CLASS, SELECTED_ITEM_CLASS } from "./useSelection";
import { useAppSelector } from '../../shared/libs/redux';

export const useCleanWorkshopTemplateSelection = () => {
	const selectedList = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);

	const cleanTemplateSelection = () => {
		if (selectedList.length) {
			const templates = document.querySelectorAll("." + SELECTABLE_ITEM_CLASS);

			templates?.forEach(el => {
				const elem = el as HTMLElement;

				elem.classList.remove(SELECTED_ITEM_CLASS);
			});
		}
	};

	return { cleanTemplateSelection };
};
