import HTMLGenerator from '../../../../utils/HTMLGenerator';
import SvgCollection from '../../../../utils/SvgCollection';
import LinePanel from './LinePanel';
import LineStyle from '../../../../graphic/line/LineStyle';

/**
 * Элемент выбора стиля обводки с графическими опциями.
 */
class LineSelect {
	public input: HTMLElement;
	public arrow: HTMLElement;
	public dropdown: HTMLElement;
	public solid: HTMLElement;
	public dashed: HTMLElement;
	public dotted: HTMLElement;
	public element: HTMLElement;
	public activeValue: LineStyle;

	private panelFigure: LinePanel;

	constructor(panelLine: LinePanel) {
		this.panelFigure = panelLine;
		// Родительский элемент
		const select = HTMLGenerator.getDiv({ className: 't1001-select' });

		// Обёртка для инпута и стрелки
		const inputWrapper = HTMLGenerator.getDiv({
			className: 't1001-select__input-wrapper',
			fnClick: () => {
				this.toggle();
			},
		});
		this.input = HTMLGenerator.getDiv({ className: 't1001-select__input' });
		inputWrapper.append(this.input);
		this.arrow = HTMLGenerator.getDiv({ className: 't1001-select__input-arrow' });
		this.arrow.insertAdjacentHTML('beforeend', SvgCollection.ARROW);
		inputWrapper.append(this.arrow);
		select.append(inputWrapper);

		// Выпадающее меню - захардкожены 3 стиля для border
		// 1. Solid
		this.solid = HTMLGenerator.getDiv({
			className: 't1001-select__item',
			fnClick: ev => { this.updateValue(this.solid, LineStyle.SOLID); },
		});
		this.solid.insertAdjacentHTML(
			'beforeend',
			SvgCollection.solid,
		);

		// 2.dashed
		this.dashed = HTMLGenerator.getDiv({
			className: 't1001-select__item',
			fnClick: () => { this.updateValue(this.dashed, LineStyle.DASHED); },
		});
		this.dashed.insertAdjacentHTML(
			'beforeend',
			SvgCollection.dashed,
		);

		// 3.dotted
		this.dotted = HTMLGenerator.getDiv({
			className: 't1001-select__item',
			fnClick: ev => { this.updateValue(this.dotted, LineStyle.DOTTED); },
		});
		this.dotted.insertAdjacentHTML(
			'beforeend',
			SvgCollection.dotted,
		);

		// Обёртка для "селектов"
		this.dropdown = HTMLGenerator.getDiv({ className: 't1001-select__dropdown' });
		this.dropdown.append(this.solid);
		this.dropdown.append(this.dashed);
		this.dropdown.append(this.dotted);
		select.append(this.dropdown);
		this.element = select;
	}

	public setValue = (element: HTMLElement, name: LineStyle) => {
		while (this.input.firstChild) {
			this.input.removeChild(this.input.firstChild);
		}
		const clone = element.cloneNode(true);
		this.input.append(clone);
		this.close();
		this.activeValue = name;
	};

	public updateValue = (element: HTMLElement, name: LineStyle) => {
		while (this.input.firstChild) {
			this.input.removeChild(this.input.firstChild);
		}
		const clone = element.cloneNode(true);
		this.input.append(clone);
		this.close();
		this.activeValue = name;
		this.panelFigure.onBorderStyleChange();
	};

	public getValue = (): LineStyle => this.activeValue;

	public open = () => {
		this.dropdown.classList.add('open');
		this.arrow.classList.add('open');
	};

	public close = () => {
		this.dropdown.classList.remove('open');
		this.arrow.classList.remove('open');
	};

	public toggle = () => {
		if (this.isOpen()) {
			this.close();
		} else {
			this.open();
		}
	};

	public getElement = () => this.element;
	public isOpen = (): boolean => this.dropdown.classList.contains('open');
}

export default LineSelect;
