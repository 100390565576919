import { useDispatch } from 'react-redux';
import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import {
	setWorkshopDeleteModalActive,
	setWorkshopTemplateSelectedList,
} from '../../../../../entities/templates/workshop/template/model/slice';
import { useAppSelector } from '../../../../../shared/libs/redux';

export const useDeleteWorkshopTemplate = () => {
	const dispatch = useDispatch();

	const selectedList = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);

	const setActive = (value: boolean) => dispatch(setWorkshopDeleteModalActive(value));

	const [deleteTemplate, { isLoading }] = workshopTemplateAPI.useDeleteWorkshopTemplateMutation();

	const onDeleteTemplate = async () => {
		const { length } = selectedList;

		if (length) {
			try {
				await deleteTemplate({ templates: selectedList })
					.unwrap();

				if (length === 1) {
					notificationSuccess('Шаблон успешно удален!');
				} else {
					notificationSuccess('Шаблоны успешно удалены!');
				}
			} catch (e) {
				if (length === 1) {
					notificationError('Возникла ошибка при удалении шаблона!');
				} else {
					notificationError('Возникла ошибка при удалении шаблонов!');
				}
			} finally {
				setActive(false);
				dispatch(setWorkshopTemplateSelectedList([]));
			}
		}
	};

	return {
		isLoading,
		setActive,
		onDeleteTemplate,
	};
};
