import React, { FC, useEffect, useState } from 'react';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getSloganOpen,
	setSloganOpen,
	setIsAiCategoriesModalOpen, getGreetingsResponses, getSloganResponses, setGreetingsResponses, setSloganResponses,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtnIcon } from '../../../../../shared/images/ai.svg';
import { AiSelect, IOption } from '../../ai-select/AiSelect';
import { styleTextSelectOptions } from '../../../model/styleTextSelectOptions';
import { getSocket } from '../../../../websocket/model/slice';
import { messageQueue } from '../../../../websocket/model/webSocketMiddleware';

type FormData = {
	nameService: string,
	detailsInfo: string,
	styleText: string,
};

/**
 * Компонент - модальное окно Слоган
 */
export const SloganModal: FC = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState,
		formState: { errors, isSubmitSuccessful },
		reset,
	} = useForm<FormData>();

	// States
	const [isLoading, setIsLoading] = useState(false);
	const [symbolsCount, setSymbolsCount] = useState(0);
	const [textStyle, setTextStyle] = useState<IOption>({
		title: 'По умолчанию',
		value: 'Нейтральный',
	});

	const active = useAppSelector(getSloganOpen);
	const socket = useAppSelector(getSocket);
	const responses = useAppSelector(getSloganResponses);

	// Обнулить значения полей
	const onResetForm = () => {
		// Обнуляем значения полей
		reset({ nameService: '', detailsInfo: '' });
		setTextStyle({
			title: 'По умолчанию',
			value: 'Нейтральный',
		});
	};

	// Для подсчета символов поля ввода доп. информации
	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setSymbolsCount(event.target.value.length);
	};

	const onClose = () => {
		dispatch(setSloganOpen(false));
		onResetForm();
	};

	const onGoBack = () => {
		dispatch(setSloganOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
		onResetForm();
	};

	const onSubmit: SubmitHandler<FormData> = (data) => {
		setIsLoading(true);
		const wsData = JSON.stringify({
			type: 'ai.generator.text.slogan',
			body: JSON.stringify({
				...data,
				styleText: textStyle.value,
			}),
			id: uuidv4(),
		});

		if	(socket?.readyState === WebSocket.OPEN) {
			socket.send(wsData);
		} else {
			messageQueue.enqueue(wsData);
		}
	};

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const message = JSON.parse(event.data);
			if (message.type === 'ai.generator.text.slogan' && message.status === 'ok') {
				setIsLoading(false);
				dispatch(setSloganResponses(message.body));
				// onResetForm();
			} else if (message.type === 'ai.generator.text.slogan' && message.status === 'error') {
				setIsLoading(false);
			}
		};

		if (socket) {
			socket.addEventListener('message', handleMessage);
		}

		return () => {
			if (socket) {
				socket.removeEventListener('message', handleMessage);
			}
		};
	}, [formState, reset, responses]);

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Слоган"
		>
			<form className="ai-form" onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="nameService" className="ai-form_label">
					Название компании/товара/услуги
					<textarea
						id="nameService"
						{...register('nameService', { required: true })}
						placeholder="Стройтехторг"
					/>
					{errors.nameService && (
						<span className="error" role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				{/* textarea - Дополнительная информация */}
				<label htmlFor="detailsInfo" className={css.addInfo}>
					Дополнительная информация
					<span className={css.symbolsCount}>
						{symbolsCount}
						/200
					</span>
					<textarea
						id="detailsInfo"
						{...register('detailsInfo', {
							required: true,
						})}
						placeholder="Лучшее предложение на рынке"
						onChange={handleChange}
						maxLength={200}
					/>
					{errors.detailsInfo && (
						<span className="error" role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiSelect
						title="Стиль текста"
						options={styleTextSelectOptions}
						onSelect={setTextStyle}
						option={textStyle}
					/>
				</div>

				<button className="aiSubmit" type="submit" disabled={isLoading}>
					<span className="aiSubmit_btn">Написать</span>
					<AiBtnIcon
						className={classNames('aiSubmit_icon', {
							'ai-modal-disabled': isLoading === true,
						})}
					/>
				</button>
			</form>

			{(isLoading && responses.length == 0) && (
				<Loader
					type="roller"
					color="rgba(57, 150, 218, 1)"
					size={100}
					className="alt-loader"
				/>
			)}

			{responses.length >= 1 && (
				<div className="responsesWrapper">
					{isLoading
						&& (
							<Loader
								type="roller"
								color="rgba(57, 150, 218, 1)"
								size={100}
								className="ai-modal-loader"
							/>
						)}
					<div>
						{responses.map((data) => (
							<div key={uuid()} className="ai-response">
								{data}
							</div>
						))}
					</div>
				</div>
			)}

		</ModalWrapper>
	);
};
