import React, { useLayoutEffect, useState } from 'react';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';
import { IContextMenuPosition } from '../model/types';

const initialPosition: IContextMenuPosition = {
	x: 0,
	y: 0,
};

export const useContextMenu = (onClose?: () => void) => {
	const [show, setShow] = useState(false);
	const [absolutePosition, setAbsolutePosition] = useState<IContextMenuPosition>(initialPosition);
	const [position, setPosition] = useState<IContextMenuPosition>(initialPosition);

	const onShowContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		e.preventDefault();

		setAbsolutePosition({
			x: e.clientX,
			y: e.clientY,
		});

		setShow(true);
	};

	const closeContextMenu = () => setShow(false);
	const onCloseMenu = () => {
		if (document.body.style.overflowY === 'hidden') return;
		closeContextMenu();
		onClose && onClose();
	};

	const menuRef = useOutSideClick(onCloseMenu, false);

	// * Если элемент выходит за границы экрана
	useLayoutEffect(() => {
		const menuEl = menuRef.current as HTMLDivElement;
		if (!menuEl) return;

		const {
			x,
			y,
		} = absolutePosition;

		const {
			innerWidth,
			innerHeight,
			scrollY,
		} = window;

		const xOverflow = x + menuEl.offsetWidth + 20 > innerWidth;
		const yOverflow = y + menuEl.offsetHeight + 20 > innerHeight;

		setPosition({
			x: xOverflow ? x - menuEl.offsetWidth + 1 : x,
			y: yOverflow ? y - menuEl.offsetHeight + 1 + scrollY : y + scrollY,
		});
	}, [absolutePosition, menuRef]);

	return {
		menuRef,
		show,
		position,
		closeContextMenu,
		onShowContextMenu,
	};
};
