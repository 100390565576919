import css from './styles.module.scss';

export const popUpTransitionClasses = {
	enter: css.exampleEnterPopUp,
	enterActive: css.exampleEnterActivePopUp,
	exit: css.exampleExitPopUp,
	exitActive: css.exampleExitActivePopUp,
};

export const dropDownTransitionClasses = {
	enter: css.exampleEnterDropDown,
	enterActive: css.exampleEnterActiveDropDown,
	exit: css.exampleExitDropDown,
	exitActive: css.exampleExitActiveDropDown,
};

export const rightAppearanceTransitionClasses = {
	enter: css.exampleEnterRightAppearance,
	enterActive: css.exampleEnterActiveRightAppearance,
	exit: css.exampleExitRightAppearance,
	exitActive: css.exampleExitActiveRightAppearance,
}
