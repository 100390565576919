import ISketchStructure from '../../Sketch/ISketchStructure';
import MultiPageManipulator from '../MultiPageManipulator';
import StaticGraphicFactory from '../../Sketch/factories/graphic/StaticGraphicFactory';
import IPagesComponentTree from '../../Sketch/component-tree/IPagesComponentTree';
import PagesComponentTree from '../../Sketch/component-tree/impl/PagesComponentTree';
import StaticComponentFactory from '../../Sketch/factories/component/StaticComponentFactory';

/**
 * Манипулятор компонентов, предназначенный только для поверхностной настройки коммерческого предложения.
 */
class OfferPresenting
	extends MultiPageManipulator<IPagesComponentTree, StaticGraphicFactory, StaticComponentFactory, null, null> {
	constructor(container: HTMLDivElement, structure: ISketchStructure) {
		super(container);
		const { id, name, root } = structure;

		this.componentTree = new PagesComponentTree(container);
		this.componentFactory = new StaticComponentFactory();
		this.graphicFactory = new StaticGraphicFactory();

		this.componentTree.connectDependencies({
			graphicFactory: this.graphicFactory,
			componentFactory: this.componentFactory,
		});
		this.graphicFactory.connectDependencies({
			componentTree: this.componentTree,
		});
		this.componentFactory.connectDependencies(null);

		this.componentTree.injectDependencies();
		this.graphicFactory.injectDependencies();
		this.componentFactory.injectDependencies();

		this.setID(id);
		this.setName(name);
		this.componentTree.load(root);

		const embedElement = this.componentTree.getElementForEmbedding();
		this.manipulatorElement.append(embedElement);
	}

	protected savePreviewFile: (fileID: string) => {
		// nothing
	};
}

export default OfferPresenting;
