import React, { useMemo, useState } from 'react';
import MainRegistration from './MainRegistration';
import { CurrentRegistrationPageData, PASSWORD_REGISTRATION_PAGE } from '../Registration';
import InputBlock from './InputBlock';
import { useInput, useInputValue } from '../../../../hooks/useInput';
import { RegistrationOffer } from '../RegistrationOffer';
import { authAPI } from '../../../../entities/user/auth/api/api';
import { notificationError } from '../../../Notifications/callNotifcation';

interface MainRegistrationContainerProps {
	email: useInputValue,
	registrationOffer: RegistrationOffer | null,
	setCurrentPage: (value: CurrentRegistrationPageData) => void
}

const MainRegistrationContainer = ({
	email,
	setCurrentPage,
	registrationOffer,
}: MainRegistrationContainerProps) => {
	const organization = useInput('Тестовая организация', {
		isEmpty: true,
		minLength: 4,
		maxLength: 255,
	});
	const name = useInput('', {
		isEmpty: true,
		minLength: 3,
		maxLength: 255,
	});
	const phone = useInput('', {
		isEmpty: true,
		minLength: 6,
		isPhone: true,
		maxLength: 16,
	});
	const birthday = useInput('', {
		isEmpty: true,
		minLength: 0,
		maxLength: 15,
		isDate: true,
	});

	const [simpleSignUp, { isLoading }] = authAPI.useSimpleSignUpMutation();

	const onSimpleSignUp = async () => {
		try {
			await simpleSignUp({
				email: email.value,
				name: name.value,
				phone: phone.value.split(' ')
					.join(''),
				onOffer: registrationOffer,
			})
				.unwrap();
			setCurrentPage(PASSWORD_REGISTRATION_PAGE);
		} catch (e) {
			notificationError('Ошибка!', 'Произошла ошибка при регистрации...');
		}
	};

	const [agreeWithPolicy, setAgreeWithPolicy] = useState(false);

	const isDataValid = useMemo(() => name.inputValid
		&& email.inputValid
		&& phone.inputValid
		&& agreeWithPolicy, [name, email, phone, agreeWithPolicy]);
	// organization.inputValid

	const handleKeyPressReg = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter' && isDataValid) {
			await onSimpleSignUp();
			// onSignUp();
		}
	};

	return (
		<MainRegistration
			setAgreeWithPolicy={setAgreeWithPolicy}
			agreeWithPolicy={agreeWithPolicy}
			isButtonActive={isDataValid}
			onSignUp={onSimpleSignUp}
			// onSignUp={onSignUp}
			isDataLoading={isLoading}
		>
			<InputBlock
				isDataLoading={isLoading}
				handleKeyPressReg={handleKeyPressReg}
				inputItems={{
					name,
					phone,
					birthday,
					organization,
					email,
				}}
			/>
		</MainRegistration>
	);
};

export default MainRegistrationContainer;
