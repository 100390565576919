import { useState } from 'react';
import { TemplateListWrapper } from '../../../../../../../../widgets/templates/list-wrapper';
import { ICustomTemplateData } from '../../../../../../../../entities/templates/custom/model/types';
import { CustomTemplate } from '../custom-template/custom-template';

interface CustomTemplateListProps {
	isDataLoading: boolean,
	templates: ICustomTemplateData[] | undefined,
}

export const CustomTemplateList = ({
	templates,
	isDataLoading,
}: CustomTemplateListProps) => {
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const [loadingTemplateId, setLoadingTemplateId] = useState<string | null>(null);

	const handleCardLoading = (templateId: string | null) => {
		if (templateId) {
			setIsGlobalLoading(true);
			setLoadingTemplateId(templateId);
		} else {
			setIsGlobalLoading(false);
			setLoadingTemplateId(null);
		}
	};

	return (
		<TemplateListWrapper isDataLoading={isDataLoading}>
			{
				templates?.map(template => (
					<CustomTemplate
						key={template.id}
						template={template}
						isLoading={loadingTemplateId === template.id}
						isGlobalLoading={isGlobalLoading}
						setGlobalLoading={handleCardLoading}
					/>
				))
			}
		</TemplateListWrapper>
	);
};
