import { useAppDispatch } from '../../../../../shared/libs/redux';
import { galleryAPI } from '../../../../../entities/templates/gallery/api/api';
import { templatePrivateAPI } from '../../../../../entities/templates/private/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useMoveGalleryTemplate = () => {
	const dispatch = useAppDispatch();

	const [move, { isLoading }] = galleryAPI.useDuplicateToPrivateMutation();

	const moveTemplate = async (id: string) => {
		try {
			const data = await move({ templates: [{ id }] })
				.unwrap();

			dispatch(templatePrivateAPI.util?.invalidateTags(['templates']));
			notificationSuccess('Шаблон скопирован успешно!');

			return data;
		} catch (e) {
			notificationError('Ошибка!', 'Произошла ошибка при переносе шаблона...');
		}

		return null;
	};

	return {
		moveTemplate,
		isLoading,
	};
};
