import InterfacePanel from '../../../InterfacePanel';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';

/**
 * Панель для управления характеристиками компонентов с изображениями.
 */
class PicturePanel extends InterfacePanel {
	private readonly applyElement: HTMLElement;
	private readonly cancelElement: HTMLElement;

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.setRootElementClassName('top-bar__img-panel img-panel');

		//  Кнопки "применить" и "отмена"
		const btnGroup = HTMLGenerator.getDiv({ className: 'img-panel__buttons-wrap' });
		this.applyElement = HTMLGenerator.getBtn({
			className: 'img-panel__apply-btn',
			text: 'Применить',
			fnClick: this.onApplyClick,
		});
		this.cancelElement = HTMLGenerator.getBtn({
			className: 'img-panel__cancel-btn',
			text: 'Отмена',
			fnClick: this.onCancelClick,
		});
		btnGroup.append(this.applyElement);
		btnGroup.append(this.cancelElement);

		this.rootElement.append(btnGroup);
	}

	private onApplyClick = () => {
		this.useCases.applyEditablePictureChange();
	};

	private onCancelClick = () => {
		this.useCases.cancelEditablePictureChange();
	};
}

export default PicturePanel;
