import LayerRepository from './LayerRepository';
import IComponentTree from '../../component-tree/IComponentTree';
import IGraphic from '../../graphic/IGraphic';
import { AnyComponentStructure, LayerSequences } from '../../Types';

/** Самостоятельная сущность для манипуляции со слоями графики. */
class ComponentLayers {
	private readonly componentTree: IComponentTree;
	private readonly layerRepository: LayerRepository;

	constructor(componentTree: IComponentTree) {
		this.componentTree = componentTree;
		this.layerRepository = new LayerRepository();
	}

	/**
	 * Синхронизирует структуру порядка слоев со значениями в графике.
	 */
	public sync = () => {
		this.layerRepository.sync();
	};

	/**
	 * Заполняет структуру для хранения порядка слоев, используя в качестве источника
	 * текущее состояние дерева компонентов. Рекомендуется для первичной загрузки структуры.
	 */
	public load = (rootStructure: AnyComponentStructure) => {
		const components = this.componentTree.getComponents();
		const structures: AnyComponentStructure[] = this.getComponentStructures(rootStructure);

		this.layerRepository.load(
			components,
			structures
				.map(structure => (structure.graphics === null ? [] : structure.graphics))
				.flat(),
		);
	};

	/**
	 * Устанавливает заданную последовательность слоев в манипулятор.
	 * @exception Если графика, идентификатор которой находится в последовательности, не найдена в дереве компонентов.
	 * @exception Если идентификатор существующей графики не находится в пришедшей последовательности.
	 * @param layerSequences Последовательность слоев.
	 */
	public setSequences = (layerSequences: LayerSequences) => {
		const components = this.componentTree.getComponents();
		this.layerRepository.setSequences(components, layerSequences);
	};

	/**
	 * Удаляет слой из последовательности.
	 * @param graphic - удаляемая графика.
	 */
	public removeLayer = (graphic: IGraphic) => {
		this.layerRepository.removeLayer(graphic);
		this.sync();
	};

	/**
	 * Добавляет графика в последний слой страницы.
	 * @param graphic - добавляемая графика
	 * @param pageNumber - номер страницы, на которую будет добавлена графика
	 */
	public appendLayer = (graphic: IGraphic, pageNumber: number): number => {
		const layerNumber = this.layerRepository.appendLayer(graphic, pageNumber);
		this.sync();
		return layerNumber;
	};

	/**
	 * Добавить новую графику в последовательность сразу после другой графики.
	 * @param graphic - добавляемая графика
	 * @param afterLayer - графика, после которой нужно добавить новую графику
	 */
	public appendLayerAfter = (graphic: IGraphic, afterLayer: IGraphic) => {
		this.layerRepository.appendLayerAfter(graphic, afterLayer);
		this.sync();
	};

	/**
	 * Перемещает графику на слой вниз.
	 * @param graphic - перемещаемая графика
	 */
	public moveBackGraphic = (graphic: IGraphic) => {
		this.layerRepository.moveBack(graphic);
	};

	/**
	 * Перемещает графику на слой вперед.
	 * @param graphic - перемещаемая графика
	 */
	public moveForwardGraphic = (graphic: IGraphic) => {
		this.layerRepository.moveForward(graphic);
	};

	/**
	 * Перемещает графику на самый дальний от пользователя слой.
	 * @param graphic - перемещаемая графика
	 */
	public moveGraphicToBackground = (graphic: IGraphic) => {
		this.layerRepository.moveToBackground(graphic);
	};

	/**
	 * Перемещает графику на самый ближний к пользователю слой.
	 * @param graphic - перемещаемая графика
	 */
	public moveGraphicToForeground = (graphic: IGraphic) => {
		this.layerRepository.moveToForeground(graphic);
	};

	/**
	 * Возвращает предыдущую графику согласно слою. В случае, если эта графика первая - null.
	 * @param graphic - графика, от которой начинается отсчет.
	 */
	public getPrevGraphic = (graphic: IGraphic): IGraphic | null => this.layerRepository
		.getPrevGraphic(graphic);

	/**
	 * Перемещает графика на слой после `prevGraphic`.
	 * @param prevGraphic - графика, после которой стоит вставить `graphic`.
	 * @param graphic - вставляемая графика.
	 */
	public moveGraphicAfter = (prevGraphic: IGraphic | null, graphic: IGraphic) => {
		this.layerRepository.moveGraphicAfter(prevGraphic, graphic);
		this.sync();
	};

	/**
	 * Добавляет новую страницу в последовательность слоев.
	 * @param graphic Графика страницы.
	 * @param graphicIndex Позиция страницы.
	 */
	public appendPage = (graphic: IGraphic, graphicIndex: number) => {
		this.layerRepository.appendPage(graphic, graphicIndex);
	};

	public reset = () => {
		this.layerRepository.reset();
	};

	/**
	 * Возвращает последовательность графики в виде списка ID.
	 */
	public getSequences = () => this.layerRepository.getSequences();

	/**
	 * Возвращает последнюю графику по слою на странице.
	 * @param pageNumber - Номер страницы.
	 */
	public getLastLayerGraphicFromPage = (pageNumber: number): IGraphic => this.layerRepository
		.getLastPageGraphic(pageNumber);

	private getComponentStructures = (rootStructure: AnyComponentStructure): AnyComponentStructure[] => {
		const structures: AnyComponentStructure[] = [rootStructure];
		this.fillChildComponents(rootStructure, structures);
		return structures;
	};

	private fillChildComponents = (
		component: AnyComponentStructure,
		structures: AnyComponentStructure[],
	) => {
		if (component.components === null) {
			return;
		}

		structures.push(...component.components);
		component.components.forEach(currentComponent => {
			this.fillChildComponents(currentComponent, structures);
		});
	};
}

export default ComponentLayers;
