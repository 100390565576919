import { useEffect, useRef } from 'react';
import { useAppSelector } from '../../shared/libs/redux';
import { getOnCloseAiModalFunc } from '../../features/ai/model/slice/aiSlice';

export const useModal = (
	setActive: (value: boolean) => void,
	isActive: boolean,
	modalInModalActive?: boolean,
) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const onCloseModal = useAppSelector(state => getOnCloseAiModalFunc(state));

	useEffect(() => {
		const handleEscapeKey = (e: KeyboardEvent) => {
			if (e.code === 'Escape' && isActive && !modalInModalActive) {
				setActive(false);
				if (onCloseModal !== null) onCloseModal();
			}
		};

		document.addEventListener('keydown', handleEscapeKey);

		return () => document.removeEventListener('keydown', handleEscapeKey);
	});

	useEffect(() => {
		if (isActive || modalInModalActive) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'auto';
		}
	}, [isActive, modalInModalActive]);

	return {
		modalRef,
	};
};
