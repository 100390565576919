import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { checkUsersRights, checkUsersRightsInterface } from '../../../../helpers/usersRights';
import Roles from './roles/Roles';
import { LINK_USERS_ROLE_LIST, LINK_USERS_USER_LIST } from '../model/consts';
import UsersListContainer from './usersList/UsersListContainer';
import { userTabs } from '../model/tabsData';
import { TabsHeaderLayout } from '../../layouts/tabs-header';
import { useAppSelector } from '../../../../shared/libs/redux';
import { ContentLayout } from '../../layouts/content';

export const Users = () => {
	const status = useAppSelector(state => state.personalAccount.account.status);

	const [statusRights, setStatusRights] = useState<checkUsersRightsInterface>(checkUsersRights(status));

	// * Обновляет права аккаунта при подтягивании статуса из state
	useEffect(() => {
		setStatusRights(checkUsersRights(status));
	}, [status]);

	return (
		<ContentLayout>
			<TabsHeaderLayout title="Пользователи" tabsData={userTabs}>
				<Routes>
					<Route
						path={LINK_USERS_USER_LIST}
						element={<UsersListContainer statusRights={statusRights} />}
					/>
					<Route
						path={LINK_USERS_ROLE_LIST}
						element={<Roles statusRights={statusRights} />}
					/>
				</Routes>
			</TabsHeaderLayout>
		</ContentLayout>
	);
};
