import { statusAPI } from '../../../../entities/user/status/api/api';
import { ACCOUNT_STATUS } from '../../../../entities/user/account/api/types';
import { employeeAPI } from '../../../../entities/employee/api/api';
import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useUpdateStatus = () => {
	const dispatch = useAppDispatch();

	const [updateStatus, { isLoading }] = statusAPI.useUpdateMutation();

	// * При обновлении статуса пользователя
	const onUpdateStatus = async (email: string, status: ACCOUNT_STATUS) => {
		try {
			await updateStatus({
				status,
				email,
			})
				.unwrap();
			dispatch(accountAPI.util?.invalidateTags(['account']));
			dispatch(adminAPI.util?.invalidateTags(['admin']));
			dispatch(employeeAPI.util?.invalidateTags(['employee']));
			notificationSuccess('Статус пользователя успешно изменен!');
		} catch (e) {
			notificationError('Произошла ошибка при обновлении статуса...');
		}
	};

	return {
		onUpdateStatus,
		isLoading,
	};
};
