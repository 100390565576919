import { useSelector } from 'react-redux';
import { workshopModuleTagApi, workshopTagActions, workshopTagSlice } from '../../../../entities/workshop-tag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleApi } from '../../../../entities/workshop-module';

export const useUnbindWorkshopTag = () => {
	const dispatch = useAppDispatch();

	const [unbindTag, requestData] = workshopModuleTagApi.useUnbindMutation();

	const tagId = useSelector(workshopTagSlice.selectors.getUnbindTagId);
	const moduleId = useSelector(workshopTagSlice.selectors.getUnbindModuleId);

	const onUnbindTag = async () => {
		try {
			if (tagId && moduleId) {
				await unbindTag({
					moduleId,
					tags: [Number(tagId)],
				}).unwrap();

				dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));

				dispatch(workshopTagActions.setIsUnbindingTagModalActive(false));
				dispatch(workshopTagActions.setUnbindTagsIds(undefined));
				dispatch(workshopTagActions.setUnbindModuleId(undefined));
			}
		} catch (e) {
			notificationError('Произошла ошибка', 'Не удалось отвязать тег');
		}
	};

	return { onUnbindTag, requestData };
};
