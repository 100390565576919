import React from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import css from './report-button.module.scss';
import { ReactComponent as TgIcon } from './images/tg-icon.svg';
import { useRedirectButton } from '../../../shared/libs/useRedirectButton';
import { DEFAULT_REPORT_REDIRECT_URL, reportRedirectData } from './model/data';

export const ReportButton = () => {
	const { pathname } = useLocation();

	const { onClick } = useRedirectButton(DEFAULT_REPORT_REDIRECT_URL, reportRedirectData);

	const inConstructor = pathname.includes('constructor');

	if (pathname.includes('presentation-for-pdf')) return null;

	return (
		<button
			type="button"
			onClick={onClick}
			className={cx(css.btn, inConstructor && css.constructor_btn)}
		>
			<TgIcon />
			Сообщить о проблеме
		</button>
	);
};
