import { useEffect, useState } from 'react';
import {
	DragEndEvent, MouseSensor, PointerSensor, useSensor, useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useUpdateCategories } from '../../../features/category/update/api/useUpdateCategories';
import { IWorkshopCategory } from '../../../entities/workshop-category/model/types/types';

export const useDNDCategories = (data: IWorkshopCategory[] | undefined) => {
	const [categories, setCategories] = useState<IWorkshopCategory[] | undefined>(data);

	useEffect(() => {
		const sortedData = (data && [...data].sort((a, b) => a.sequenceNumber - b.sequenceNumber)) || [];
		setCategories(sortedData);
	}, [data]);

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				distance: 5,
			},
		}),
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 5,
			},
		}),
	);

	const { onUpdateCategories } = useUpdateCategories();

	const onDragEnd = async (e: DragEndEvent) => {
		// * отправлять новый список на сервер
		const {
			active,
			over,
		} = e;

		if (over && active && over.id && active.id && active.id !== over.id) {
			setCategories(categories => {
				if (categories) {
					const oldIndex = categories.findIndex(el => el.id === active.id);
					const newIndex = categories.findIndex(el => el.id === over.id);

					const changedData = arrayMove(categories, oldIndex, newIndex);

					onUpdateCategories(changedData);

					return changedData;
				}
				return [];
			});
		}
	};

	return {
		categories,
		sensors,
		onDragEnd,
	};
};
