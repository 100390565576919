import React from 'react';
import { workshopModuleApi } from '../../../../entities/workshop-module';
import { useUnbindWorkshopModuleCategory } from '../../services/useUnbindWorkshopModuleCategory';
import { ChangeableCategoryList } from '../../../chengable-category-bar/ui/category-list/changeable-category-list';

interface WorkshopModuleBindTagListProps {
	className?: string,
	moduleId?: string,
}

export const WorkshopModuleBindCategoryList = ({ className, moduleId }: WorkshopModuleBindTagListProps) => {
	const { data: moduleData } = workshopModuleApi.useGetWorkshopModuleQuery({
		moduleId: moduleId || '',
	});

	const { onUnbindCategory } = useUnbindWorkshopModuleCategory(moduleId);

	return (
		<ChangeableCategoryList
			deleteCategory={onUnbindCategory}
			activeCategories={moduleData?.module?.categories || []}
		/>
	);
};
