import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import LineGraphic from '../../graphic/line/LineGraphic';

class LineComponent extends GraphicComponent<null, LineGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.LINE;

	constructor() {
		super();
		this.disableEditModeMechanic();
	}

	public getTexture = () => null;
	public getUniqueTexture = () => null;
	public setTexture = (fn: (prev: null) => null) => {
		// nothing
	};
}

export default LineComponent;
