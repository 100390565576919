import React from 'react';
import Button, { buttonsTypes } from '../../../ui/button/button';
import css from './createOrganization.module.scss';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';
import CreateInput from '../../../pages/features/moduleWindow/createInput/CreateInput';

export enum determineOrg {
	TEAM = 'команду',
	LEGAL_ENTITY = 'юр. лицо'
}

interface CreateBodyProps {
	name: string,
	setName: (value: string) => void,
	onCreateOrganization: () => void,
	isDataLoading: boolean,
	organization: determineOrg.TEAM | determineOrg.LEGAL_ENTITY,
}

export const MAX_INPUT_VALUE_LENGTH = 32;

const CreateOrganization = ({
	name,
	setName,
	isDataLoading,
	onCreateOrganization,
	organization,
}: CreateBodyProps) => {
	// * При создании команды
	const onCreatingOrganization = () => {
		if (name !== '') {
			onCreateOrganization();
		}
	};

	useCatchEnterClick(onCreatingOrganization, name.length > 0);

	return (
		<div className={css.wrapper}>
			<div className={css.title}>
				Создать
				{` ${organization === determineOrg.TEAM ? determineOrg.TEAM : determineOrg.LEGAL_ENTITY}`}
			</div>
			<CreateInput
				maxLength={MAX_INPUT_VALUE_LENGTH}
				text={name}
				subTitle="Введите название"
				defaultValue={`Название ${organization === determineOrg.TEAM
					? 'команды'
					: 'юр. лица'
				}`}
				onChange={setName}
			/>
			<Button
				onClick={onCreateOrganization}
				text={`Создать ${organization === determineOrg.TEAM
					? determineOrg.TEAM
					: determineOrg.LEGAL_ENTITY}`}
				buttonType={buttonsTypes.BRIGHT}
				cssClass={css.button}
				inActive={
					name.length > 0
				}
				inProcess={isDataLoading}
			/>
		</div>
	);
};

export default CreateOrganization;
