import { useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '../../shared/libs/redux';
import { ACCOUNT_STATUS } from '../../entities/user/account/api/types';

interface StatusAccessProps {
	children: React.ReactNode,
	accessStatus: ACCOUNT_STATUS[],
}

export const StatusAccess = ({
	children,
	accessStatus,
}: StatusAccessProps) => {
	const navigate = useNavigate();

	const status = useAppSelector(state => state.personalAccount.account.status);

	const accessable = useMemo(() => accessStatus.includes(status), [status]);

	useEffect(() => {
		if (!accessable) navigate('/');
	}, [accessable]);

	if (!accessable) return null;

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};
