import type ParserState from './state';
import { TokenType } from './types';
import { globalCodes } from './utils';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function parseRedLine(state: ParserState): boolean {
	const { pos } = state;
	if (consumeRedLine(state)) {
		const value = state.substring(pos);
		// console.log(state.peekPrev());
		// if (state.peek() === globalCodes.Tab) return false;

		state.push({
			type: TokenType.RedLine,
			format: state.startToken.format,
			value,
			textAlign: state.startToken.textAlign,
			color: state.startToken.color,
			fontFamily: state.startToken.fontFamily,
			fontSize: state.startToken.fontSize,
			lineHeight: state.startToken.lineHeight,

		});
		return true;
	}
}

export function consumeRedLine(state: ParserState): boolean {
	return state.consume(globalCodes.Tab);
	// return state.consume(globalCodes.At);
}
