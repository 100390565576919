import KeyboardObserver from '../../utils/observers/KeyboardObserver';
import PrivateTemplateUseCases from '../../use-cases/impl/PrivateTemplateUseCases';
import KeyCombination from '../../utils/KeyCombination';
import IComponentTree from '../../component-tree/IComponentTree';
import { onOpenAiModal } from '../../../../redux/thunk-creators/moduleWindow';
import store from '../../../../redux/store/store';
import { getAiMode } from '../../../../features/ai/model/slice/aiSlice';

class PrivateTemplateKeyboardObserver extends KeyboardObserver {
	constructor(
		private readonly componentTree: IComponentTree,
		private readonly useCases: PrivateTemplateUseCases,
	) {
		super();
		this.subscribeOnKeyUp(KeyCombination.CTRL_G, this.onCtrlG);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_T, useCases.runInjectText);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_K, useCases.runInjectPicture);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_F, useCases.runInjectFigure);
		this.subscribeOnKeyUp(KeyCombination.SHIFT_L, useCases.runInjectLine);
		this.subscribeOnKeyUp(KeyCombination.CTRL_SHIFT_G, useCases.ungroupFocusComponents);
		this.subscribeOnKeyUp(KeyCombination.DELETE, this.onDelete);

		this.subscribeOnKeyUp(KeyCombination.ESCAPE, this.onEscape);

		this.subscribeOnKeyUp(KeyCombination.CTRL_Z, useCases.undo);
		this.subscribeOnKeyUp(KeyCombination.CTRL_SHIFT_Z, useCases.redo);

		this.subscribeOnKeyDown(KeyCombination.CTRL_C, this.onCopy);
		this.subscribeOnKeyDown(KeyCombination.CTRL_V, this.onPaste);

		this.subscribeOnKeyDown(KeyCombination.CTRL_X, this.onCut);
		this.subscribeOnKeyDown(KeyCombination.CTRL, this.onCtrlDown);
		this.subscribeOnKeyUp(KeyCombination.CTRL, this.onCtrlUp);
		this.subscribeOnKeyDown(KeyCombination.SHIFT, this.onShiftDown);
		this.subscribeOnKeyDown(KeyCombination.SHIFT, this.enableMaintainAspectRatioFocusComponents);
		this.subscribeOnKeyUp(KeyCombination.SHIFT, this.onShiftUp);
		this.subscribeOnKeyUp(KeyCombination.SHIFT, this.disableMaintainAspectRatioFocusComponents);
		this.subscribeOnKeyDown(KeyCombination.ALT, this.onAltUp);
		this.subscribeOnKeyUp(KeyCombination.ALT, this.onAltUp);

		// AI
		this.subscribeOnKeyUp(KeyCombination.CTRL_ALT_M, this.onOpenAiModal);

		this.subscribeOnKeyUp(KeyCombination.CTRL_ARROW_UP, useCases.moveFocusTableCellUp);
		this.subscribeOnKeyUp(KeyCombination.CTRL_ARROW_DOWN, useCases.moveFocusTableCellDown);
		this.subscribeOnKeyUp(KeyCombination.CTRL_ARROW_LEFT, useCases.moveFocusTableCellLeft);
		this.subscribeOnKeyUp(KeyCombination.CTRL_ARROW_RIGHT, useCases.moveFocusTableCellRight);
	}

	private onCtrlG = (ev: Event) => {
		ev.preventDefault();
		this.useCases.groupFocusComponents();
	};

	private onDelete = (ev: Event) => {
		ev.preventDefault();
		this.useCases.removeFocusComponentsFromHotkey();
	};

	private onEscape = () => {
		this.useCases.disableInjectingProcess();
		this.useCases.disableComponentsFocus();
	};

	public onCopy = (ev: Event) => {
		// Обработать случай когда открыты модалки AI
		const state = store.getState();
		const aiMode = getAiMode(state);
		if	(aiMode) {
			return;
		}

		ev.preventDefault();
		this.useCases.copy();
	};

	public onPaste = (ev: Event) => {
		const { activeElement } = document;
		const state = store.getState();
		const aiMode = getAiMode(state);

		if	(aiMode && activeElement && activeElement.tagName === 'TEXTAREA') {
			return;
		}
		ev.preventDefault();
		this.useCases.pasteFromKeyboardHotkey();
	};

	public onCut = (ev: Event) => {
		ev.preventDefault();
	};

	private enableMaintainAspectRatioFocusComponents = () => {
		const focusComponents = this.componentTree.getFocusComponents();
		if (focusComponents !== null) {
			focusComponents.forEach((component) => {
				component.getGraphics().forEach((graphic) => {
					graphic.enableMaintainAspectRatio();
				});
			});
		}
	};

	private disableMaintainAspectRatioFocusComponents = () => {
		const focusComponents = this.componentTree.getFocusComponents();
		if (focusComponents !== null) {
			focusComponents.forEach((component) => {
				component.getGraphics().forEach((graphic) => {
					graphic.disableMaintainAspectRatio();
				});
			});
		}
	};

	private onOpenAiModal = () => {
		onOpenAiModal(() => console.log('close'));
	};
}

export default PrivateTemplateKeyboardObserver;
