import IContextMenuSection from '../section/IContextMenuSection';
import ContextMenuSection from '../section/ContextMenuSection';
import ContextMenuRowBuilder from './ContextMenuRowBuilder';
import ContextMenuBuilder from './ContextMenuBuilder';

/**
 * Сборщик секции контекстного меню.
 */
class ContextMenuSectionBuilder {
	private readonly section: IContextMenuSection;
	private readonly menuBuilder: ContextMenuBuilder;

	constructor(menuBuilder: ContextMenuBuilder) {
		this.menuBuilder = menuBuilder;
		this.section = new ContextMenuSection();
	}

	public row = (): ContextMenuRowBuilder => {
		const rowBuilder = new ContextMenuRowBuilder(this);
		const rowElement = rowBuilder.getElement();
		const sectionElement = this.section.getElement();
		sectionElement.append(rowElement);
		return rowBuilder;
	};

	public build = (): ContextMenuBuilder => this.menuBuilder;

	public getElement = () => this.section.getElement();
}

export default ContextMenuSectionBuilder;
