import { templateModuleCategoryApi } from '../../../../entities/template-module-category';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useDislikeCategory = () => {
	const [dislikeCategory] = templateModuleCategoryApi.useDeleteFavoriteCategoryMutation();

	const onDislikeCategory = async (categoryId?: number) => {
		try {
			if (categoryId) {
				await dislikeCategory({ categoryId }).unwrap();
			}
		} catch (error) {
			notificationError('Не удалось удалить категорию из избранного');
		}
	};

	return { onDislikeCategory };
};
