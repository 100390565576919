import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as TemplateIcon } from '../../images/template.svg';
import css from './controls.module.scss';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';

interface ModuleControlsProps {
	isHoverBlockVisible: boolean;
	deleteModule?: () => void;
	hideEditIcon?: boolean;
	hideTemplateIcon?: boolean;
}

export const ModuleControls = ({
	deleteModule,
	isHoverBlockVisible,
	hideEditIcon = false,
	hideTemplateIcon = false,
}: ModuleControlsProps) => {
	const hiddenBlockRef = useRef<HTMLDivElement>(null);

	const onClick = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const onDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		deleteModule?.();
	};

	return (
		<CSSTransition
			timeout={200}
			unmountOnExit
			nodeRef={hiddenBlockRef}
			in={isHoverBlockVisible}
			classNames={popUpTransitionClasses}
		>
			<div className={css.wrapper} ref={hiddenBlockRef}>
				{!hideTemplateIcon && (
					<div
						className={css.item}
						role="presentation"
						onClick={onClick}
					>
						<TemplateIcon />
					</div>
				)}
				{!hideEditIcon && (
					<div
						className={css.item}
						role="presentation"
						onClick={onClick}
					>
						<EditIcon />
					</div>
				)}
				<div
					className={css.item}
					role="presentation"
					onClick={onDelete}
				>
					<DeleteIcon />
				</div>
			</div>
		</CSSTransition>
	);
};
