import React from 'react';
import { useMultipleLegalEntityUsers } from '../../../lib/useMultipleLegalEntityUsers';
import { MultipleUserSelection } from '../../../../../../widgets/entity/multiple-selection';

const LegalEntityMultipleSelection = () => {
	const {
		onBind,
		isBinding,
		availableUsers,
		isLegalEntityUserSelectionModalActive,
		handleILegalEntityUserSelectionModalActive,
	} = useMultipleLegalEntityUsers();

	return (
		<MultipleUserSelection
			onBind={onBind}
			isBinding={isBinding}
			btnText="Добавить в юр. лицо"
			availableUsers={availableUsers?.accounts}
			isEntityUserSelectionModalActive={isLegalEntityUserSelectionModalActive}
			handleIsEntityUserSelectionModalActive={handleILegalEntityUserSelectionModalActive}
		/>
	);
};

export default LegalEntityMultipleSelection;
