import ImageState, { IImageStateDependencies } from '../foundation/image/ImageState';
import PageGraphic from './PageGraphic';
import IPagesComponentTree from '../../component-tree/IPagesComponentTree';

export interface IPageBackgroundImageStateDependencies extends IImageStateDependencies {}

/**
 * Хранит в себе текущее состояние отображения фонового изображения. Состояние представляется блоками,
 * наследованными от `ImageGraphicBlock`.
 */
class PageBackgroundImageState extends ImageState<IPageBackgroundImageStateDependencies> {
	constructor() {
		super();
	}
}

export default PageBackgroundImageState;
