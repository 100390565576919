import SpatialResizeArea from '../SpatialResizeArea';
import AreaResizeTrigger from '../AreaResizeTrigger';
import IFrameArea from '../IFrameArea';
import SpatialAreaType from '../SpatialAreaType';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import Graphic from '../../../../../graphic/Graphic';
import ImageState from '../../../../../graphic/foundation/image/ImageState';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import IGraphic from '../../../../../graphic/IGraphic';

interface ISpatialImageResizeAngularAreaData extends ISpatialAreaBaseData<IGraphic> {
	pictureState: ImageState<any>,
}

/** Область для изменения размеров изображения внутри компонента. */
class SpatialImageResizeAngularArea extends SpatialResizeArea<ISpatialImageResizeAngularAreaData> {
	public readonly type: SpatialAreaType = SpatialAreaType.PICTURE_ANGULAR_RESIZE;

	private readonly graphic: IGraphic;
	private readonly imageState: ImageState<any>;

	constructor(graphic: IGraphic, imageState: ImageState<any>, trigger: AreaResizeTrigger) {
		super(trigger);
		this.graphic = graphic;
		this.imageState = imageState;
	}

	public getData = (): ISpatialImageResizeAngularAreaData => ({
		graphic: this.graphic,
		pictureState: this.imageState,
	});

	public getGlobalFrameArea = (): IFrameArea => {
		const graphicPosition = this.graphic.getGlobalPosition();
		const pictureTexture = this.imageState.getTexture();
		return this.getTriggerArea(
			graphicPosition.x + pictureTexture.x,
			graphicPosition.y + pictureTexture.y,
			pictureTexture.width,
			pictureTexture.height,
		);
	};

	public getRelatedFrames = (): IAreaSizeMutable[] => [this.imageState];

	public isAllowMutatePosition = (): boolean => true;
}

export default SpatialImageResizeAngularArea;
