// * Создание шаблона
import { notificationError } from "../../components/Notifications/callNotifcation";
import store from "../../redux/store/store";
import { templatePrivateAPI } from "../../entities/templates/private/api/api";
import { generateEmptyTemplateStructure } from "./generateEmptyTemplateStructure";

const getConstructorLink = (id: string) => `/constructor/${id}`;

export const onPrivateTemplateCreate = async (navigate: any, setIsDataLoading: (value: boolean) => void) => {
	setIsDataLoading(true);
	const requestBody = generateEmptyTemplateStructure();

	try {
		const res = await store.dispatch(templatePrivateAPI.endpoints?.create.initiate({ rootComponent: requestBody.root }))
			.unwrap();

		const link = getConstructorLink(res.id);
		navigate(link);
	} catch (err) {
		notificationError("Шаблон", "Ошибка при переходе в конструктор...");
	} finally {
		setIsDataLoading(false);
	}
};
