import React from 'react';
import { useDispatch } from 'react-redux';
import Role from '../../../personalAccount/personalData/role/Role';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../Notifications/callNotifcation';
import { setDeletingRoleId, setIsDeleteRoleModalActive } from '../../../../../redux/reducers/usersReducer';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { IRoleItem } from '../../../../../entities/role/api/types';

interface RolesListProps {
	statusRights: checkUsersRightsInterface,
	roles: IRoleItem[],
}

const RolesList = ({
	statusRights,
	roles,
}: RolesListProps) => {
	const dispatch = useDispatch();

	const handleDeletingRoleId = (id: string) => {
		dispatch(setDeletingRoleId(id));
	};

	const handleDeleteRoleModal = (value: boolean) => {
		dispatch(setIsDeleteRoleModalActive(value));
	};

	// * При удалении роли
	const onDeletingRole = (id: string) => {
		if (statusRights.delete) {
			handleDeletingRoleId(id);
			handleDeleteRoleModal(true);
		} else {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'У вас недостаточно прав для совершения этого действия!');
		}
	};

	return (
		<div>
			{
				roles?.map(role => {
					const {
						name,
						background,
						id,
					} = role;
					return (
						<Role
							onDeleteRole={() => onDeletingRole(id)}
							text={name}
							bgColor={background}
							canDeleteRole
							isCursorPointed
							/* eslint-disable-next-line react/jsx-props-no-spreading */
							{...role}
							key={id}
						/>
					);
				})
			}
		</div>
	);
};

export default RolesList;
