import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { workshopModuleTagApi, workshopTagSlice } from '../../../../entities/workshop-tag';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useDeleteWorkshopTag = () => {
	const dispatch = useAppDispatch();

	const [deleteTag, requestData] = workshopModuleTagApi.useDeleteMutation();

	const id = useSelector(workshopTagSlice.selectors.getDeletingTagId);

	const onDeleteTag = async () => {
		try {
			if (id) {
				await deleteTag({ tagId: id }).unwrap();
				dispatch(workshopTagSlice.actions.setIsDeleteModalActive(false));
			}
		} catch (e) {
			notificationError('Возникла ошибка при удалении тега...');
		}
	};

	return {
		onDeleteTag,
		requestData,
	};
};
