import { LINK_USERS_ROLE_LIST, LINK_USERS_USER_LIST } from './consts';

export const userTabs = [
	{
		name: 'Список пользователей',
		link: LINK_USERS_USER_LIST,
	},
	{
		name: 'Роли',
		link: LINK_USERS_ROLE_LIST,
	},
];
