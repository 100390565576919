import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/reducers/reducer';
import css from './createModuleWindow.module.scss';
import HiddenBlock from './HiddenBlock';
import MainBlock from './MainBlock';
import Button, { buttonsTypes } from '../../../../ui/button/button';
import Utils from '../../../../Sketch/utils/impl/Utils';
import useCatchEnterClick from '../../../../../hooks/useCatchEnterClick';

interface CreateModuleWindowProps {
	onCreateModule: (
		name: string,
		description: string,
	) => void,
    isModalActive: boolean,
	choicedCategoryId: string[],
	choicedLegalEntityId: string[],
	onAddChoicedTag: (id: string) => void,
	onRemoveChoicedTag: (id: string) => void,
	setChoicedCategoryId: (value: string[]) => void
	onRemoveChoicedLegalEntity: (id: string) => void,
	onAddChoicedLegalEntity: (id: string) => void,
	choicedTagsIds: string[],
}

const CreateModuleWindow: React.FC<CreateModuleWindowProps> = ({
	isModalActive,
	choicedTagsIds,
	onRemoveChoicedLegalEntity,
	onAddChoicedTag,
	choicedCategoryId,
	onAddChoicedLegalEntity,
	setChoicedCategoryId,
	onRemoveChoicedTag,
	choicedLegalEntityId,
	onCreateModule,
}: CreateModuleWindowProps) => {
	const preview = useSelector((state: RootState) => state.moduleWindow.createTemplateWindowPreview);

	const [nameInputValue, setNameInputValue] = useState('');
	const [descriptionValue, setDescriptionValue] = useState('');

	const [showMore, setShowMore] = useState(false);

	const [isBtnActive, setIsBtnActive] = useState(false);

	const onCreate = () => {
		onCreateModule(nameInputValue, descriptionValue);
	};

	useEffect(() => {
		if (!isModalActive) {
			setNameInputValue('');
			setDescriptionValue('');
			setShowMore(false);
		}
	}, [isModalActive]);

	useEffect(() => {
		if (!nameInputValue || !choicedCategoryId?.length) {
			setIsBtnActive(false);
		} else {
			setIsBtnActive(true);
		}
	}, [nameInputValue, choicedCategoryId?.length]);

	useCatchEnterClick(onCreate, isBtnActive);

	return (
		<div className={css.createTemplateWrapper}>
			<img
				src={Utils.Backend.getImageURI(preview)}
				alt=""
				className={css.preview}
			/>
			<div className={css.wrapper}>
				<div className={css.content}>
					<div className={css.title}>
						Создать модуль
					</div>
					<MainBlock
						nameInputValue={nameInputValue}
						showMore={showMore}
						setShowMore={setShowMore}
						choicedTagId={choicedTagsIds}
						choicedCategoryId={choicedCategoryId}
						setNameInputValue={setNameInputValue}
						onAddChoicedTag={onAddChoicedTag}
						onRemoveChoicedTag={onRemoveChoicedTag}
						setChoicedCategoryId={setChoicedCategoryId}
					/>
					{
						showMore && (
							<HiddenBlock
								descriptionValue={descriptionValue}
								setDescriptionValue={setDescriptionValue}
								choicedLegalEntityId={choicedLegalEntityId}
								onAddChoicedLegalEntity={onAddChoicedLegalEntity}
								onRemoveChoicedLegalEntity={onRemoveChoicedLegalEntity}
							/>
						)
					}
					<Button
						text="Создать модуль"
						onClick={onCreate}
						buttonType={buttonsTypes.BRIGHT}
						cssClass={css.btn}
						inActive={isBtnActive}
					/>
				</div>
			</div>
		</div>
	);
};

export default CreateModuleWindow;
