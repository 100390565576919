import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import IComponent from '../../components/IComponent';
import ManipulatorError from '../manipulator-error/ManipulatorError';
import API from '../../../../api/API';
import ColorexColor from '../../../../libs/colorex/colors/ColorexColor';
import { compressImage } from './CompressImage';

class Generate {
	public ComponentPreview = async (component: IComponent, showPreview: (fileID: string) => void) => {
		try {
			const firstGraphic = component.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}

			const graphicElement = firstGraphic.getFrameElement();
			graphicElement.style.position = 'absolute';
			graphicElement.style.left = '-5000px';
			graphicElement.style.top = '-5000px';

			document.body.append(graphicElement);

			const canvas = await html2canvas(graphicElement, {
				backgroundColor: ColorexColor.Transparent,
				scale: 1,
			});
			let previewBytes = canvas.toDataURL('image/png', 1.0);

			previewBytes = await compressImage(previewBytes);
			graphicElement.remove();

			const blob = new Blob([previewBytes]);

			await API.file.create({
				name: `preview-${new Date().toISOString()}_${this.UUID4()}`,
				bytes: previewBytes,
			}, blob.size)
				.then(response => showPreview(response.id));
		} catch (error) {
			console.error('Ошибка при создании пред просмотра:', error);
		}
	};

	public UUID4 = (): string => uuidv4();
}

export default Generate;
