import SvgCollection from '../../../../../utils/SvgCollection';
import ElementContainer from '../../../../../utils/ElementContainer';
import Utils from '../../../../../utils/impl/Utils';
import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import HTMLElementName from '../../../../../utils/HTMLElementName';

/**
 * Кнопка для вызова модального окна ввода размера создаваемой таблицы.
 */
class TableSizeSetterButton extends ElementContainer<HTMLDivElement> {
	private readonly ELEMENT_CLASS_NAME = 'table-constructor__set-values';

	constructor() {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);

		Utils.DOM.injectSVG(this.rootElement, SvgCollection.CUSTOM_VALUES);

		const textElement = HTMLGenerator.getSpan({ text: 'Задать свои значения' });
		this.rootElement.append(textElement);
	}

	public addAction = (action: VoidFunction) => {
		this.rootElement.addEventListener('click', action);
	};
}

export default TableSizeSetterButton;
