import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import IFrameConfiguration from '../../../../../frame/IFrameConfiguration';
import IPictureTexture from '../../../../picture/IPictureTexture';

/**
 * Управляет светлыми областями изображения в режиме редактирования.
 */
class HighlightPictureArea {
	private readonly ELEMENT_CLASS_NAME = 'picture-block__highlight-area';
	private readonly LEFT_AREA_CLASS_NAME = 'area left';
	private readonly TOP_AREA_CLASS_NAME = 'area top';
	private readonly RIGHT_AREA_CLASS_NAME = 'area right';
	private readonly BOTTOM_AREA_CLASS_NAME = 'area bottom';
	private readonly ELEMENT_HIDE_CLASS_NAME = 'hide';
	private readonly AREA_WIDTH_PROPERTY = '--highlight-width';
	private readonly AREA_HEIGHT_PROPERTY = '--highlight-height';
	private readonly AREA_LEFT_PROPERTY = '--highlight-left';
	private readonly AREA_TOP_PROPERTY = '--highlight-top';
	private readonly CSS_BORDER_OFFSET = 2;

	private readonly element: HTMLElement;
	private readonly leftAreaElement: HTMLElement;
	private readonly topAreaElement: HTMLElement;
	private readonly rightAreaElement: HTMLElement;
	private readonly bottomAreaElement: HTMLElement;

	constructor() {
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
		});

		this.leftAreaElement = HTMLGenerator.getDiv({
			className: this.LEFT_AREA_CLASS_NAME,
		});

		this.topAreaElement = HTMLGenerator.getDiv({
			className: this.TOP_AREA_CLASS_NAME,
		});

		this.rightAreaElement = HTMLGenerator.getDiv({
			className: this.RIGHT_AREA_CLASS_NAME,
		});

		this.bottomAreaElement = HTMLGenerator.getDiv({
			className: this.BOTTOM_AREA_CLASS_NAME,
		});

		this.element.append(
			this.leftAreaElement,
			this.topAreaElement,
			this.rightAreaElement,
			this.bottomAreaElement,
		);
	}

	public updateAreas = (frameConfiguration: IFrameConfiguration, texture: IPictureTexture) => {
		this.updateLeftArea(frameConfiguration, texture);
		this.updateTopArea(frameConfiguration, texture);
		this.updateRightArea(frameConfiguration, texture);
		this.updateBottomArea(frameConfiguration, texture);
	};

	public enable = () => {
		this.element.classList.remove(this.ELEMENT_HIDE_CLASS_NAME);
	};

	public disable = () => {
		this.element.classList.add(this.ELEMENT_HIDE_CLASS_NAME);
	};

	public getElement = (): HTMLElement => this.element;

	private updateLeftArea = (frameConfiguration: IFrameConfiguration, texture: IPictureTexture) => {
		let width;

		const absTextureLeft = Math.abs(texture.x);

		if (texture.x > 0) {
			width = 0;
		} else if (absTextureLeft - texture.width > 0) {
			width = absTextureLeft - (absTextureLeft - texture.width);
		} else {
			width = absTextureLeft;
		}

		this.setAreaProperties(this.leftAreaElement, texture.x, texture.y, width, texture.height);
	};

	private updateTopArea = (frameConfiguration: IFrameConfiguration, texture: IPictureTexture) => {
		let left;
		let top;
		let height;
		let width;

		if (texture.x > 0) {
			left = texture.x;
		} else {
			left = 0;
		}

		if (texture.y > 0) {
			top = 0;
		} else {
			top = texture.y;
		}

		if (texture.x > 0) {
			width = frameConfiguration.width - texture.x;
		} else if (texture.x + texture.width > frameConfiguration.width) {
			width = frameConfiguration.width;
		} else {
			width = texture.x + texture.width;
		}

		if (texture.y > 0) {
			height = 0;
		} else if (Math.abs(texture.y) > texture.height) {
			height = texture.height;
		} else {
			height = texture.height - (texture.height + texture.y);
		}

		this.setAreaProperties(this.topAreaElement, left, top, width, height);
	};

	private updateRightArea = (frameConfiguration: IFrameConfiguration, texture: IPictureTexture) => {
		let left;
		let width;

		if (texture.x > frameConfiguration.width) {
			left = frameConfiguration.width + (texture.x - frameConfiguration.width);
		} else {
			left = frameConfiguration.width;
		}

		if (texture.x + texture.width > frameConfiguration.width) {
			if (texture.x > frameConfiguration.width) {
				width = texture.width;
			} else {
				width = texture.x + texture.width - frameConfiguration.width;
			}
		} else {
			width = 0;
		}

		this.setAreaProperties(
			this.rightAreaElement,
			left,
			texture.y,
			width,
			texture.height,
		);
	};

	private updateBottomArea = (frameConfiguration: IFrameConfiguration, texture: IPictureTexture) => {
		let left;
		let height;
		let top;
		let width;

		if (texture.x > 0) {
			left = texture.x;
		} else {
			left = 0;
		}

		if (texture.height + texture.y > frameConfiguration.height) {
			if (texture.y > frameConfiguration.height) {
				height = texture.height;
			} else {
				height = texture.y + texture.height - frameConfiguration.height;
			}
		} else {
			height = 0;
		}

		if (texture.width + texture.x > frameConfiguration.width) {
			if (texture.x > 0) {
				width = frameConfiguration.width - texture.x;
			} else {
				width = frameConfiguration.width;
			}
		} else {
			width = texture.x + texture.width;
		}

		if (texture.y + texture.height > frameConfiguration.height) {
			if (texture.y > frameConfiguration.height) {
				top = texture.y;
			} else {
				top = frameConfiguration.height;
			}
		} else {
			top = texture.height;
		}

		this.setAreaProperties(
			this.bottomAreaElement,
			left,
			top,
			width,
			height,
		);
	};

	private setAreaProperties = (area: HTMLElement, left: number, top: number, width: number, height: number) => {
		this.setAreaProperty(area, this.AREA_LEFT_PROPERTY, left);
		this.setAreaProperty(area, this.AREA_TOP_PROPERTY, top);
		this.setAreaProperty(area, this.AREA_WIDTH_PROPERTY, width);
		this.setAreaProperty(area, this.AREA_HEIGHT_PROPERTY, height);
	};

	private setAreaProperty = (area: HTMLElement, property: string, value: number) => {
		area.style.setProperty(property, `${value}px`);
	};
}

export default HighlightPictureArea;
