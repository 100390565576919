import HTMLGenerator from '../../utils/HTMLGenerator';
import GraphicComponent from '../GraphicComponent';
import SketchComponentType from '../SketchComponentType';
import PageGraphic from '../../graphic/page/PageGraphic';

/**
 * Корневой компонент в многостраничной структуре.
 */
class PagesContainer extends GraphicComponent<null, PageGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.PAGES_CONTAINER;

	private readonly CONTAINER_CLASS_NAME = 'sketch-manipulator__pages-container';
	private readonly RIGHT_MAGNETIC_CLASS_NAME = 'sketch-manipulator__right_magnetic';

	private readonly containerElement: HTMLElement;

	constructor() {
		super();
		this.containerElement = HTMLGenerator.getDiv({
			className: this.CONTAINER_CLASS_NAME,
		});
		this.offset = 0;
	}

	public reset = () => {
		const graphics = this.getGraphics();
		graphics.forEach(graphic => graphic.removeFrame());

		this.graphicList.clear();
		this.componentList.clear();
	};

	public enableRightMagneticMode = () => {
		this.containerElement.classList.add(this.RIGHT_MAGNETIC_CLASS_NAME);
	};

	public disableRightMagneticMode = () => {
		this.containerElement.classList.remove(this.RIGHT_MAGNETIC_CLASS_NAME);
	};

	public getPagesContainerElement = (): HTMLElement => this.containerElement;

	public getTexture = (): null => null;

	public getUniqueTexture = (): null => null;

	public setTexture = (fn: (prev: null) => null): void => {
		// nothing
	};
}

export default PagesContainer;
