import { renderCountWord } from '../../../helpers/renderCountWord';
import { calculateDuration } from '../../../shared/libs/utils/calculate-duration';

export const calculateTariffExpiration = (startDateStr: string | undefined, duration: number | undefined): string => {
	if (startDateStr && duration !== undefined) {
		const startDate: Date = new Date(startDateStr);

		// Рассчитываем дату окончания тарифа, добавляя продолжительность (duration) в минутах к начальной дате
		const endDate: Date = new Date(startDate.getTime() + duration * 60 * 1000); // duration в минутах
		const currentDate: Date = new Date();

		// Проверяем, если дата окончания раньше текущей даты, возвращаем "0 дней 0 часов 0 минут"
		if (endDate <= currentDate) {
			return '0 дней 0 часов 0 минут';
		}

		// Рассчитываем разницу в минутах между текущей датой и конечной датой тарифа
		const remainingMinutes = calculateDuration(currentDate, endDate);

		// Преобразуем оставшееся время в дни, часы и минуты
		const remainingDays = Math.floor(remainingMinutes / (60 * 24));
		const remainingHours = Math.floor((remainingMinutes % (60 * 24)) / 60);
		const remainingMinutesFormatted = remainingMinutes % 60;

		// Формируем строку для оставшегося времени
		let result = '';

		if (remainingDays > 0) {
			const daysWord = renderCountWord(remainingDays, ['день', 'дня', 'дней']);
			result += `${daysWord} `;
		}

		if (remainingHours > 0 || remainingDays > 0) {
			const hoursWord = renderCountWord(remainingHours, ['час', 'часа', 'часов']);
			result += `${hoursWord} `;
		}

		if (remainingMinutesFormatted > 0 || (remainingHours === 0 && remainingDays === 0)) {
			const minutesWord = renderCountWord(remainingMinutesFormatted, ['минута', 'минуты', 'минут']);
			result += `${minutesWord}`;
		}

		return result.trim();
	}

	return '0 дней 0 часов 0 минут';
};
