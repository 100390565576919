import React from 'react';
import ComponentOfferPresetting from '../../../../../SketchManipulators/OfferPresetting/ComponentOfferPresetting';
import ISketchStructure from '../../../../../Sketch/ISketchStructure';

interface PressetingProps {
    structure: ISketchStructure
}

const ComponentOfferPressetingContainer = (props: PressetingProps) => {
	const { structure } = props;

	return (
		<ComponentOfferPresetting structure={structure} />
	);
};

export default ComponentOfferPressetingContainer;
