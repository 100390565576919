import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategoryData } from '../../../entities/modules/category/api/types';

interface IModalsActive {
	isDeletingCategoryModalActive: boolean,
	isCreatingCategoryModalActive: boolean,
	isEditCategoryModalActive: boolean,
}

interface IInitialState {
	totalCount: number,
	searchValue: string,
	editingCategoryId: number | null,
	deletingCategoryId: number | null,
	categoriesData: ICategoryData[] | null,
	modalsActive: IModalsActive,
}

const initialState: IInitialState = {
	totalCount: 0,
	searchValue: '',
	categoriesData: null,
	editingCategoryId: null,
	deletingCategoryId: null,
	modalsActive: {
		isEditCategoryModalActive: false,
		isDeletingCategoryModalActive: false,
		isCreatingCategoryModalActive: false,
	},
};

export const categoryReducer = createSlice({
	name: 'category',
	initialState,
	selectors: {
		getIsEditCategoryModalActive: (state) => state.modalsActive.isEditCategoryModalActive,
		getIsDeletingCategoryModalActive: (state) => state.modalsActive.isDeletingCategoryModalActive,
		getIsCreatingCategoryModalActive: (state) => state.modalsActive.isCreatingCategoryModalActive,
	},
	reducers: {
		setCategoriesData: (state, action: PayloadAction<ICategoryData[]>) => {
			state.categoriesData = action.payload;
		},
		setTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		setEditingCategoryId: (state, action: PayloadAction<number>) => {
			state.editingCategoryId = action.payload;
		},
		setIsEditCategoryModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isEditCategoryModalActive = action.payload;
		},
		setDeletingCategoryId: (state, action: PayloadAction<number>) => {
			state.deletingCategoryId = action.payload;
		},
		setIsDeletingCategoryModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeletingCategoryModalActive = action.payload;
		},
		setIsCreatingCategoryModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreatingCategoryModalActive = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
	},
});

export const {
	setTotalCount,
	setSearchValue,
	setCategoriesData,
	setEditingCategoryId,
	setDeletingCategoryId,
	setIsEditCategoryModalActive,
	setIsCreatingCategoryModalActive,
	setIsDeletingCategoryModalActive,
} = categoryReducer.actions;

export default categoryReducer.reducer;
