import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useUnpublishGalleryWorkshopTemplate = () => {
	const [unpublish, { isLoading }] = workshopTemplateAPI.useDeleteFromGalleryMutation();

	const onUnpublish = async (templates: string[]) => {
		try {
			await unpublish({ templates })
				.unwrap();
			notificationSuccess('Шаблон мастерской успешно удален из галереи шаблонов!');
		} catch (e) {
			notificationError('Произошла ошибка при удалении опубликованного шаблона мастерской из галереи...');
		}
	};

	return {
		isLoading,
		onUnpublish,
	};
};
