import React from 'react';
import css from './redirectPage.module.scss';

interface RedirectPageProps {
	redirectCounter: number,
}

const RedirectPage: React.FC<RedirectPageProps> = (
	{ redirectCounter }: RedirectPageProps,
) => (
	<div className={css.wrapper}>
		<div className={css.picture}>
			<svg width="140" height="106" viewBox="0 0 140 106" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M122.654 59.3625C121.076 56.8243 118.158 57.9141
                    115.968 58.8821C113.778 59.85 109.895 69.4005 105.155 74.8425C100.415 80.2845 99.3196 79.5603
                    97.0143 71.2145C94.7089 62.8686 85.3508 62.1444 80.488 61.1765C75.6324 60.2085 75.51 65.163
                    76.1223 66.7332C76.7274 68.3035 75.6396 69.5152 76.4897 76.5274C77.3398 83.5397 82.5628 91.5199
                    84.3854 92.0002C86.2081 92.4806 118.403 88.616 122.416 87.8918C126.429 87.1677 131.774 85.5974
                    132.012 82.0913C132.25 78.5852 124.231 61.9007 122.654 59.3625Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M92.2377 16.6377C89.1759 14.3146 84.6373 12.8018
                    79.1766 12.8018C77.4692 12.8018 75.6682 12.9523 73.7951 13.2606C69.3142 14.0063 65.568 16.3437
                    61.6274 18.9966C57.7011 21.6137 53.6164 24.5103 48.3646 25.7221C46.5276 26.1379 44.7049 26.3745
                    42.9327 26.3745C36.9101 26.3674 31.4781 24.3454 27.5159 22.3737C26.7883 22.0152 26.1183 21.6495
                    25.4915 21.2982C27.2205 29.3429 34.1221 35.8748 41.7584 36.6778C33.1927 38.2193 27.2133 33.2864
                    23.8562 28.8123C21.8102 34.9928 25.8589 42.0338 29.2593 44.8659C32.6452 47.6622 36.0023 50.6306
                    41.8161 51.4479C41.8161 51.4479 40.332 58.4243 20.7656 65.2645C22.7396 68.448 30.2679 70.8427
                    34.295 70.8427C36.0672 70.8499 37.2559 71.2443 42.5653 70.011L42.803 84.4944L59.92 75.5391C62.1893
                    83.7989 65.5032 88.2156 66.9945 90.3451L74.4795 76.0123L72.1022 66.4475C71.6555 64.5905 72.0373
                    62.6546 73.1468 61.1274C74.2778 59.5787 76.014 58.6323 77.9807 58.4745H78.0023H78.024L79.8826
                    58.3168C81.0857 44.3783 84.2843 36.1687 88.1818 31.2645C90.9626 27.7297 94.4205 26.9194 97.1725
                    26.2383C97.1149 22.7609 95.2562 18.9536 92.2377 16.6377Z"
					fill="#FFAE00"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M110.663 35.9972C107.63 31.9892 103.841 30.1752
                    99.8499 30.1895C97.7175 30.2397 94.5477 30.5409 91.3707 34.4413C88.381 38.1554 84.2386 45.2967
                    83.0859 58.6042C97.487 62.7843 98.9494 75.2744 99.1439 76.1205C101.226 74.8083 104.266 72.6143
                    107.032 69.8682C111.658 65.2794 115.814 58.7261 115.793 51.4127C115.8 45.6767 113.682 39.9837
                    110.663 35.9972Z"
					fill="#353535"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M122.65 59.3625C121.072 56.8243 118.154 57.9141
                    115.964 58.8821C113.774 59.85 109.891 69.4005 105.151 74.8425C100.411 80.2845 99.3157 79.5603
                    97.0103 71.2145C94.705 62.8686 85.3469 62.1444 80.4841 61.1765C75.6285 60.2085 75.506 65.163
                    76.1184 66.7332C76.7235 68.3035 75.6357 69.5152 76.4858 76.5274C77.3359 83.5397 82.5589 91.5199
                    84.3815 92.0002C86.2042 92.4806 118.399 88.616 122.412 87.8918C126.425 87.1677 131.77 85.5974
                    132.008 82.0913C132.246 78.5852 124.228 61.9007 122.65 59.3625Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M134.376 80.0122L125.983 59.7426C125.428 58.4018
                    124.427 57.226 123.166 56.4158C122.266 55.8422 121.257 55.4765 120.241 55.3331C120.241 55.3331
                    123.037 45.2019 115.076 33.6582C111.935 29.1125 106.273 25.9648 100.963 25.4414C100.913 19.8775
                    98.6867 16.1706 94.7605 13.2166C90.6974 10.1622 85.1718 8.44141 78.8898 8.44141C76.9159 8.44141
                    74.8699 8.61349 72.7663 8.96482C67.1183 9.91842 62.7598 12.7577 58.8336 15.3891C54.8929 18.0492
                    51.3485 20.4726 47.4294 21.3545C46.0102 21.6772 44.591 21.8206 43.179 21.8206C38.6908 21.8278
                    34.3035 20.279 30.8744 18.7232C24.0809 15.6544 21.2425 11.926 21.2425 11.926C19.8377 14.5717
                    19.8233 19.182 20.5221 23.4984C19.2901 26.983 18.2888 30.3457 18.2888 33.2854C18.2672 40.3693
                    24.3835 49.7764 34.6709 53.261C23.1444 60.4525 11.3008 62.4457 11.3008 62.4457C19.6215 77.897
                    36.6449 75.9468 37.7975 75.8249L37.0195 94.3593L57.227 81.7186C59.9934 90.7241 67.9396 98.9696
                    67.9396 98.9696L75.5255 82.1201L77.1248 88.5731C77.5282 90.2653 78.544 91.7638 79.9776
                    92.7891C81.4545 93.8502 83.2411 94.3163 85.0061 94.1012L129.434 88.8528H129.463L129.492
                    88.8456C131.429 88.5946 133.094 87.4976 134.059 85.8414C135.075 84.0991 135.183 81.9768 134.376
                    80.0122ZM118.988 60.2302C119.118 60.223 119.19 60.2159 119.19 60.2159C119.622 60.18 120.09 60.3162
                    120.486 60.5743C120.89 60.8325 121.221 61.1981 121.401 61.6283L126.927 74.9788C123.231 73.4588
                    118.21 71.3867 113.65 69.5082C114.795 68.0025 115.919 66.339 116.985 64.5035C117.77 63.1627 118.44
                    61.7359 118.988 60.2302ZM99.8466 30.5106C103.78 30.4963 107.519 32.2816 110.509 36.2394C113.484
                    40.1686 115.573 45.7827 115.566 51.4326C115.58 58.6456 111.488 65.1058 106.928 69.63C104.205
                    72.3403 101.359 74.3551 99.3063 75.6457C99.1118 74.8139 97.5197 62.6536 83.3204 58.5309C84.4514
                    45.417 88.5433 38.3689 91.4898 34.7122C94.6164 30.8548 97.743 30.5608 99.8466 30.5106ZM71.7577
                    66.2315L74.0847 75.6528L66.8661 89.5411C65.4037 87.4474 62.45 83.3247 60.2167 75.1868L43.0349
                    83.9342L42.8044 69.7448C37.5742 70.9637 36.3999 70.5765 34.6565 70.5693C30.6943 70.5693 23.274
                    68.6047 21.3289 65.4643C40.6143 58.7245 42.6315 51.1387 42.6315 51.1387C36.9042 50.3284 33.0428
                    47.7186 29.7073 44.9653C26.3574 42.1762 22.2871 36.4975 24.3042 30.4102C27.6109 34.8269 35.4058
                    40.2331 43.9426 36.5908C36.4143 35.7949 27.7118 29.6789 26.0044 21.7489C26.6167 22.0931 27.2795
                    22.4516 27.9999 22.8029C31.9046 24.7459 37.25 26.7392 43.1862 26.7464C44.9368 26.7464 46.7306
                    26.5169 48.5389 26.1011C53.7114 24.9109 57.7313 22.0572 61.6071 19.476C65.4902 16.8661 69.1859
                    14.5574 73.5948 13.8261C75.439 13.5178 77.2113 13.3744 78.897 13.3744C84.2785 13.3744 88.7451
                    14.8585 91.7636 17.1529C94.7389 19.433 96.3382 22.3512 96.403 25.7856C93.6943 26.4524 90.5173
                    28.08 87.7725 31.5646C83.9327 36.3972 80.6188 44.4849 79.4373 58.2226L77.6075
                    58.3732H77.5859H77.5643C75.6264 58.5309 73.9118 59.463 72.8023 60.9902C71.6929 62.4887 71.3183
                    64.4031 71.7577 66.2315ZM76.815 65.0843C76.707 64.6326 76.9015 64.188 77.0384 63.808C77.2401
                    63.2488 77.9893 62.7469 78.8034 62.6895C82.6504 61.8721 86.2884 63.8582 87.9598 65.1488C89.624
                    66.4394 91.2305 68.4757 92.2318 70.4402C92.448 70.8561 92.6425 71.272 92.837 71.6878L80.7773
                    82.83L76.815 65.0843ZM129.78 83.3606C129.6 83.676 129.283 83.8911 128.858 83.9485L84.509
                    89.032C83.9615 89.1037 82.4775 89.4837 82.2397 87.5478L94.2706 75.3875C95.0126 78.0978 95.0198
                    81.482 94.674 84.1994C94.674 84.1994 102.901 80.973 111.135 72.4263C118.462 75.4449 126.084
                    78.7503 128.958 79.9333L129.794 81.8979C130.025 82.45 130.003 82.9805 129.78 83.3606ZM76.9591
                    37.9459C76.9591 33.3714 74.7907 30.3385 72.2981 30.4819C69.7838 30.6253 67.4641 33.9594 67.0462
                    38.247C66.5996 42.7641 68.4511 47.0733 71.4696 47.0733C74.5169 47.0733 76.9591 42.6351 76.9591
                    37.9459Z"
					fill="#0E0E0E"
				/>
			</svg>
		</div>
		<h2 className={css.title}>
			Регистрация прошла
			<br />
			{' '}
			успешно
		</h2>
		<div className={css.descr}>
			Перенаправим в сервис через...
			{redirectCounter}
		</div>
	</div>
);

export default RedirectPage;
