import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import ParrotExpired from '../images/expired-parrot.webp';
import css from './tariff-expired.module.scss';
import { ReactComponent as LogoIcon } from '../images/logo.svg';
import { ReactComponent as LogoTextIcon } from '../images/logo-text.svg';
import { ReactComponent as BackgroundIcon } from '../images/background.svg';
import { GoBackButton } from '../../../../shared/ui';
import { useLogout } from '../../../../shared/libs/useLogout';
import { ToTariffButton } from '../../../../shared/ui/to-tariff-button/to-tariff-button';
import { useAppSelector } from '../../../../shared/libs/redux';

export const TariffExpired = () => {
	const navigate = useNavigate();

	const tariff = useAppSelector(state => state.personalAccount.account.tariff);

	const { onLogOut } = useLogout();

	useEffect(() => {
		if (tariff) navigate('/');
	}, [tariff]);

	if (tariff) return null;

	return (
		<div className={css.wrapper}>
			<div className={css.main}>
				<div className={css.logo}>
					<LogoIcon />
					<LogoTextIcon />
				</div>
				<div className={css.content}>
					<div className={css.title}>
						Действие тарифа
						<br />
						{' '}
						закончилось
					</div>
					<div className={css.description}>
						Для дальнейшего использования сервиса,
						{' '}
						<br />
						{' '}
						просим Вас произвести оплату
					</div>
					<ToTariffButton />
				</div>
				<GoBackButton onClick={onLogOut}>
					Выйти из аккаунта
				</GoBackButton>
			</div>
			<BackgroundIcon className={css.background_picture} />
			<img loading="lazy" className={css.picture} src={ParrotExpired} alt="expired parrot" />
		</div>
	);
};
