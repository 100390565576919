import { useState } from 'react';
import { SearchTagBar } from '../../../search-tag-bar';
import { tagAPI } from '../../../../entities/modules/tag/api/api';
import { useCreateModuleTag } from '../../../../features/module/tags/create/api/useCreateModuleTag';
import { useBindModuleTag } from '../../../../features/module/tags/bind/api/useBindModuleTag';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import { ITagData } from '../../../../entities/modules/tag/api/types';
import { IModule } from '../../../../entities/modules/module/model/types';

interface ModuleSearchTagBarProps {
	module: IModule | undefined,
}

export const ModuleSearchTagBar = ({
	module,
}: ModuleSearchTagBarProps) => {
	const [search, setSearch] = useState('');

	const debounceSearch = useDebounce(search.trim(), 200);

	const { data: availableTagsData } = tagAPI.useGetAvailableQuery({
		moduleId: module?.id || '',
		search: debounceSearch,
	}, { skip: !module });

	const { createTag } = useCreateModuleTag();
	const { onBindTag } = useBindModuleTag(module?.id || '');

	return (
		<SearchTagBar
			search={search}
			bindTag={onBindTag}
			createTag={createTag}
			setSearch={setSearch}
			tags={availableTagsData?.moduleTags}
			templateTags={(module?.tags || []) as ITagData[]}
		/>
	);
};
