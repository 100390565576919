import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'react-ts-loaders';
import cx from 'classnames';
import { TemplateCard } from '../../../../../../../../widgets/templates/template-card';
import css from './private-template.module.scss';
import { TemplateCardProps } from '../../../../../../../../widgets/templates/template-card/ui/template-card';
import useContextMenu from '../../../../../../../../hooks/mouse/useContextMenu';
import { INFO_BOX_ID } from '../../../my/infoBox/InfoBox';
import { usePrivateContextMenuActions } from './utils/usePrivateContextMenuActions';
import { PrivateTemplateContextMenu } from './context-menu/context-menu';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/libs/redux';
import { setPrivateTemplateSelectedList } from '../../../../../../../../entities/templates/private/model/slice';
import { notificationError } from '../../../../../../../Notifications/callNotifcation';

interface PrivateTemplateProps extends TemplateCardProps {
	onShowContextMenu: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	isGlobalLoading: boolean;
	setGlobalLoading: (templateId: string | null) => void;
	isEditableTitle: boolean,
	onFinishEditTemplateTitle: (id: string, name: string) => void,
}

export const PrivateTemplate = ({
	template,
	selected,
	onShowContextMenu,
	isGlobalLoading,
	setGlobalLoading,
	isEditableTitle,
	onFinishEditTemplateTitle,
}: PrivateTemplateProps) => {
	const dispatch = useAppDispatch();

	const selectedTemplates = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);
	const [isLoading, setIsLoading] = useState(false);

	const { id } = template;

	const navigate = useNavigate();

	// * Перейти на панель шаблона
	const toTemplatePanel = async (event: React.MouseEvent<HTMLElement>) => {
		if (!isGlobalLoading) {
			setGlobalLoading(id);
			setIsLoading(true);
			try {
				const target = event.target as HTMLElement;
				const isInput = target.tagName === 'INPUT'; // Проверяем тег
				if (!isInput) {
					await navigate(`/collection/template-private-panel/${id}`);
				}
			} catch (error) {
				notificationError('Возникла ошибка при выборе шаблона!');
				console.error('Произошла ошибка: ', error);
			} finally {
				setIsLoading(false);
				setGlobalLoading(null);
			}
		}
	};

	const {
		isActionMenuOpened,
		onDelete,
		onSendOffer,
		onCopyLink,
		handleMenu,
		onMoveTemplate,
	} = usePrivateContextMenuActions(id);

	const {
		menuRef,
		menuPosition,
		wrapperRef,
	} = useContextMenu({
		setMenuVisible: handleMenu,
		// * Передаем id плашки info-box, чтобы при клике на нее не закрывалось контекстное меню
		exception_id: INFO_BOX_ID,
	});

	const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		onShowContextMenu(e);
		if (!selectedTemplates.length) {
			dispatch(setPrivateTemplateSelectedList([template.id]));
		}
	};

	return (
		<div
			ref={wrapperRef}
			role="presentation"
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onClick={!isGlobalLoading ? toTemplatePanel : undefined}
			// data-context-menu="openable"
			// onContextMenu={handleItemClick}
			onContextMenu={!isGlobalLoading ? onContextMenu : undefined}
		>
			<TemplateCard
				template={template}
				isEditableTitle={isEditableTitle}
				selected={selected}
				onFinishEditTemplateTitle={onFinishEditTemplateTitle}
			>
				{
					isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />
				}
			</TemplateCard>
			<PrivateTemplateContextMenu
				menuRef={menuRef}
				onDelete={onDelete}
				onCopyLink={onCopyLink}
				onSendOffer={onSendOffer}
				menuPosition={menuPosition}
				onMoveTemplate={onMoveTemplate}
				setIsActionMenuOpened={handleMenu}
				isActionMenuOpened={isActionMenuOpened}
			/>
		</div>
	);
};
