import { useState } from 'react';
import { useSelector } from 'react-redux';
import { classNames } from '../../../../shared/libs/classNames';
import { templateGalleryModuleApi } from '../../../../entities/template-gallery-module';
import SearchInput from '../../../../components/ui/searchInput/SearchInput';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import { templateModuleSelectors } from '../../../../entities/template-module';
import { useAppSelector } from '../../../../shared/libs/redux';
import { TagGroupsScrollSearch } from '../../../modules/tag-groups-scroll-search';
import css from './TemplateGalleryModules.module.scss';
import { TemplateModulesModalTabsEnum } from '../modal/TemplateModulesModal';
import { TypeOfTags } from '../../model/types';
import { GalleryModuleCard } from '../../../../entities/modules/gallery-module-card/gallery-module-card';
import { personalAccountSlice } from '../../../../redux/reducers/personalAccountReducer';
import { TTariffName } from '../../../../entities/tariff/model/data';

interface TemplateGalleryModulesProps {
	className?: string,
}

/**
 * Шаблон модулей галереи (опубликованные модули мастерской).
 */
export const TemplateGalleryModules = ({ className }: TemplateGalleryModulesProps) => {
	const [search, setSearch] = useState('');

	const [isGlobalLoading, setIsGlobalLoading] = useState(false);

	const debouncedSearch = useDebounce(search.trim(), 200);

	const onModuleClick = useSelector(templateModuleSelectors.getGalleryModuleClick);
	const categories = useAppSelector(templateModuleSelectors.getSelectedCategoryIds);
	const tags = useAppSelector(state => state.moduleWindow.currentTagsIds);
	const userTariff = useAppSelector(personalAccountSlice.selectors.tariff) as TTariffName;

	const { data: galleryModules } = templateGalleryModuleApi.useGetTemplateGalleryModulesQuery({
		limit: 127,
		page: 1,
		search: debouncedSearch,
		categories: categories.map(String),
		tags: tags.map(String),
	});

	const setGlobalLoadingState = (moduleId: string | null) => {
		setIsGlobalLoading(!!moduleId);
	};

	return (
		<div className={classNames('', {}, [className])}>
			<SearchInput
				onChange={setSearch}
				placeholder="Поиск по модулям"
				searchValue={search}
				styles={css.search}
			/>
			<TagGroupsScrollSearch scrollDistance={200} typeOfTags={TypeOfTags.WORKSHOP_TAGS} />
			<div className={css.list}>
				{
					galleryModules?.modules?.map(module => (
						<GalleryModuleCard
							key={module.id}
							module={module}
							onModuleClick={onModuleClick}
							currentTab={TemplateModulesModalTabsEnum.GALLERY}
							userTariff={userTariff}
							isGlobalLoading={isGlobalLoading}
							setGlobalLoading={setGlobalLoadingState}
						/>
					))
				}
			</div>
		</div>
	);
};
