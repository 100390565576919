import { ACCOUNT_STATUS } from '../../../../../../entities/user/account/api/types';
import { UserStatus } from '../../../../../../shared/ui';
import css from './status.module.scss';

interface AdminUserStatusProps {
	status?: ACCOUNT_STATUS,
}

export const AdminUserStatus = ({ status }: AdminUserStatusProps) => (
	<div className={css.wrapper}>
		<div className={css.title}>Статус</div>
		<UserStatus type={status || ACCOUNT_STATUS.EMPLOYEE} />
	</div>
);
