import cx from 'classnames';
import css from './hint.module.scss';

interface TagHintProps {
	text: string;
	show: boolean;
	styles?: string,
}

export const Hint = ({
	text,
	show,
	styles,
}: TagHintProps) => (
	<div className={cx(css.wrapper, styles, !show && css.hidden)}>
		<div className={css.content}>
			{text}
		</div>
	</div>
);
