import React, { forwardRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IContextMenuData, IContextMenuProps } from '../../../../../../../../widgets/context-menu/model/types';
import { ContextMenu } from '../../../../../../../../widgets/context-menu';
import { ReactComponent as SendKpIcon } from '../../../../../../../../shared/images/green-send-kp.svg';
import { ReactComponent as TrashIcon } from '../../../../../../../../shared/images/red-trash.svg';
import { ReactComponent as MoveIcon } from '../../../../../../../../shared/images/move.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../../../../../shared/images/copy-link.svg';
import { ReactComponent as EditIcon } from '../../../../../../../../shared/images/edit-icon.svg';
import {
	ReactComponent as DuplicateIcon,
} from '../../../../../../../../shared/images/collection/template/duplicate.svg';
import css from './private-template-context-menu.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../../../shared/libs/redux';
import { renderCountWord } from '../../../../../../../../helpers/renderCountWord';
import { copyBuffer, domainName } from '../../../../../../../../helper';
import { notificationSuccess } from '../../../../../../../Notifications/callNotifcation';
import {
	setIsChoicePlaceToMoveModalActive,
	setIsConfirmPrivateDeleteModalActive,
	setPrivateTemplateSelectedList,
} from '../../../../../../../../entities/templates/private/model/slice';
import { useDuplicateTemplates } from '../../api/useDuplicateTemplates';

let initialSelectedLength: undefined | number;

export const PrivateTemplateContextMenu = forwardRef<HTMLDivElement, Omit<IContextMenuProps, 'data'>>((
	props,
	ref,
) => {
	const { closeContextMenu, onRename } = props;

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const selectedTemplates = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);
	const singleTemplateId = selectedTemplates[0];

	const menuActionWrapper = (action: () => void) => () => {
		closeContextMenu();
		action();
	};

	const { duplicateTemplates } = useDuplicateTemplates();

	const onCopyLink = menuActionWrapper(() => {
		copyBuffer(`${domainName}presentation/template/${singleTemplateId}`);
		notificationSuccess('Ссылка скопирована в буфер обмена!');
		dispatch(setPrivateTemplateSelectedList([]));
	});

	const onSendOffer = menuActionWrapper(() => navigate(`/private-template-present/${singleTemplateId}`));
	const onMoveTemplate = menuActionWrapper(() => dispatch(setIsChoicePlaceToMoveModalActive(true)));
	const onDelete = menuActionWrapper(() => dispatch(setIsConfirmPrivateDeleteModalActive(true)));
	const onDuplicate = menuActionWrapper(() => duplicateTemplates());
	const onRenameTitle = menuActionWrapper(() => {
		onRename();
		dispatch(setPrivateTemplateSelectedList([]));
	});

	const templateCount = renderCountWord(selectedTemplates.length || 0, ['шаблон', 'шаблонов', 'шаблона']);
	const duplicateText = `Дублировать ${selectedTemplates.length > 1 ? templateCount : ''}`;

	useEffect(() => {
		if (selectedTemplates.length) {
			initialSelectedLength = selectedTemplates.length;
		}
	}, [selectedTemplates]);

	const data: IContextMenuData = [
		{
			text: 'Переместить в «Шаблоны команды»',
			icon: <MoveIcon />,
			onClick: onMoveTemplate,
		},
		{
			text: duplicateText,
			icon: <DuplicateIcon />,
			onClick: onDuplicate,
		},
		{
			text: 'Удалить',
			icon: <TrashIcon />,
			onClick: onDelete,
			textStyles: css.delete_text,
		},
	];

	if (initialSelectedLength === 1) {
		data.splice(0, 0, {
			text: 'Переименовать',
			icon: <EditIcon />,
			onClick: onRenameTitle,
		});
		data.unshift({
			text: 'Отправить КП',
			icon: <SendKpIcon />,
			onClick: onSendOffer,
			textStyles: css.send_text,
		});
		data.splice(3, 0, {
			text: 'Скопировать ссылку',
			icon: <CopyLinkIcon />,
			onClick: onCopyLink,
		});
	}

	return (
		<ContextMenu {...props} data={data} ref={ref} />
	);
});
