import { createApi } from '@reduxjs/toolkit/query/react';
import {
	ICreateWorkshopTagGroupRequest,
	IGetWorkshopTagGroupListRequest,
	IGetWorkshopTagGroupResponse,
} from '../model/types/types';
import { baseQuery, providesList } from '../../../shared/api/api';

export const URI_TAG_GROUP = '/workshop/module/tag/group';

export const workshopTagGroupApi = createApi({
	reducerPath: 'workshopTagGroupApi',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['workshopTagGroup'],
	endpoints: builder => ({
		create: builder.mutation<void, ICreateWorkshopTagGroupRequest>({
			query: (data) => ({
				url: URI_TAG_GROUP,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['workshopTagGroup'],
		}),
		getWorkshopGroups: builder.query<IGetWorkshopTagGroupResponse, IGetWorkshopTagGroupListRequest>({
			query: (data) => ({
				url: URI_TAG_GROUP,
				method: 'GET',
				params: data,
			}),
			providesTags: ['workshopTagGroup'],
		}),
		delete: builder.mutation<void, { id: number }>({
			query: ({ id }) => ({
				url: `${URI_TAG_GROUP}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['workshopTagGroup'],
		}),
		update: builder.mutation<void, { groupId: number, name: string }>({
			query: ({
				groupId,
				name,
			}) => ({
				url: `${URI_TAG_GROUP}/${groupId}`,
				method: 'PUT',
				body: {
					name,
				},
			}),
			invalidatesTags: ['workshopTagGroup'],
		}),
		bind: builder.mutation<void, {tags: number[], groupId: number}>({
			query: ({
				tags,
				groupId,
			}) => ({
				url: `${URI_TAG_GROUP}/${groupId}/tag`,
				method: 'POST',
				body: {
					tags,
				},
			}),
			invalidatesTags: ['workshopTagGroup'],
		}),
		unbind: builder.mutation<void, { groupId: number, tags: number[]}>({
			query: ({
				groupId,
				tags,
			}) => ({
				url: `${URI_TAG_GROUP}/${groupId}/tag`,
				method: 'DELETE',
				body: {
					tags,
				},
			}),
			invalidatesTags: ['workshopTagGroup'],
		}),
		// tags: builder.query({
		// 	query: ({
		// 		group,
		// 		...data
		// 	}) => ({
		// 		url: `${URI_TAG_GROUP}/${group}`,
		// 		method: 'GET',
		// 		params: {
		// 			...data,
		// 		},
		// 	}),
		// 	providesTags: ['workshopTagGroup'],
		// }),
	}),
});
