import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { URI_ACCOUNT, URI_ACCOUNT_UPDATE } from './consts';
import { IAccount, IUpdateAccountRequest } from './types';
import { setAccount, setAccountAuthorized } from '../../../../redux/reducers/personalAccountReducer';

export const accountAPI = createApi({
	reducerPath: 'accountAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['account'],
	endpoints: builder => ({
		get: builder.query<IAccount, void>({
			query: () => ({
				url: URI_ACCOUNT,
				method: 'GET',
			}),
			providesTags: ['account'],
			keepUnusedDataFor: 0,
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const { data } = await queryFulfilled;

					dispatch(setAccount(data));
					dispatch(setAccountAuthorized(true));
				} catch (error) {
					// 	nothing
				}
			},
		}),
		update: builder.mutation<void, IUpdateAccountRequest>({
			query: (data) => ({
				url: URI_ACCOUNT_UPDATE,
				method: 'PUT',
				body: { ...data },
			}),
			invalidatesTags: ['account'],
		}),
		delete: builder.mutation<void, void>({
			query: () => ({
				url: URI_ACCOUNT,
				method: 'DELETE',
			}),
			invalidatesTags: ['account'],
		}),
	}),
});
