import ContextMenuHandler, { IContextMenuHandlerDependencies } from '../ContextMenuHandler';
import IManipulatorInterface from '../../../interface/IManipulatorInterface';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import IGraphic from '../../../graphic/IGraphic';
import IComponent from '../../../components/IComponent';
import GraphicType from '../../../graphic/GraphicType';
import SketchComponentType from '../../../components/SketchComponentType';
import TableComponent from '../../../components/table/TableComponent';
import ContextMenuBuilder from '../upgrade/builders/ContextMenuBuilder';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import WorkshopTemplateUseCases from '../../../use-cases/impl/WorkshopTemplateUseCases';
import { AnySpatialArea } from '../../../Types';
import SpatialTableCellArea from '../../spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableCellArea';

interface IWorkshopTemplateContextMenuHandlerDependencies extends IContextMenuHandlerDependencies {
	useCases: WorkshopTemplateUseCases,
	manipulatorInterface: IManipulatorInterface,
}

class WorkshopTemplateContextMenuHandler extends ContextMenuHandler<IWorkshopTemplateContextMenuHandlerDependencies> {
	constructor() {
		super();
	}

	public coordinateMenuForShow = (
		mousePosition: IDescartesPosition,
		crossGraphic: IGraphic | null,
		activeArea: AnySpatialArea | null,
		crossComponent: IComponent | null,
	) => {
		if (crossComponent === null || crossGraphic === null || crossGraphic.type === GraphicType.PAGE) {
			this.showDefaultMenu(mousePosition);
			return;
		}

		if (crossComponent.type === SketchComponentType.TABLE && activeArea) {
			this.showTableMenu(mousePosition, crossComponent as TableComponent, activeArea, crossGraphic);
			return;
		}

		this.showComponentMenu(mousePosition, crossGraphic, crossComponent);
	};

	public hide = () => {
		if (this.activeMenu === null) {
			return;
		}
		this.activeMenu.remove();
	};

	private showDefaultMenu = (position: IDescartesPosition) => {
		const contextMenu = new ContextMenuBuilder()
			.sectionCopyPaste(this.onCopy, this.onPaste, this.onDelete)
			.sectionInterface(
				this.onInterfaceShow,
				this.onInterfaceHide,
				this.getIsShowedInterface,
			)
			.build();
		contextMenu.append(position);

		this.activeMenu = contextMenu;
	};

	private showTableMenu(
		position: IDescartesPosition,
		component: TableComponent,
		activeArea: AnySpatialArea,
		graphic: IGraphic,
	) {
		const contextMenu = new ContextMenuBuilder();

		if (component.isEnableEditMode()) {
			contextMenu
				.sectionTextPanel(this.dependencies.useCases)
				.sectionCells(
					this.onCellsMerge.bind(this, component),
					this.onCellsSplit.bind(this, component),
					this.onTableSplit.bind(this, component, activeArea),
					this.onAddColumnAfter.bind(this, component),
					this.onAddColumnBefore.bind(this, component),
					this.onAddRowUnder.bind(this, component),
					this.onAddRowOver.bind(this, component),
					this.onCellsDeleteRow.bind(this, component),
					this.onCellsDeleteColumn.bind(this, component),
					this.onCopy.bind(this, component),
					this.onPaste.bind(this, component),
				)
				.build();
		} else {
			contextMenu
				.sectionGrouping(this.onGroup, this.onUngroup, component)
				.sectionLayers(
					this.onGraphicForward.bind(this, graphic),
					this.onGraphicBack.bind(this, graphic),
					this.onGraphicForeground.bind(this, graphic),
					this.onGraphicBackground.bind(this, graphic),

				)
				.sectionModule(this.onModule.bind(this, component))
				.sectionCopyPaste(
					this.onCopy,
					this.onPaste,
					this.onDelete,
				)
				.build();
		}

		const menu = contextMenu.build();
		menu.append(position);

		this.activeMenu = menu;
	}

	private showComponentMenu = (position: IDescartesPosition, graphic: IGraphic, component: IComponent) => {
		const contextMenu = new ContextMenuBuilder()
			.sectionCopyPaste(this.onCopy, this.onPaste, this.onDelete)
			.sectionGrouping(this.onGroup, this.onUngroup, component)
			.sectionLayers(
				this.onGraphicForward.bind(this, graphic),
				this.onGraphicBack.bind(this, graphic),
				this.onGraphicForeground.bind(this, graphic),
				this.onGraphicBackground.bind(this, graphic),
			)
			.sectionModule(this.onModule.bind(this, component))
			.build();
		contextMenu.append(position);

		this.activeMenu = contextMenu;
	};

	private onCopy = () => {
		this.hide();
		this.dependencies.useCases.copy();
	};

	private onPaste = () => {
		if (this.activeMenu === null) {
			throw new ManipulatorError('active menu not found');
		}

		const menuPosition = this.activeMenu.getCurrentPosition();
		this.hide();
		this.dependencies.useCases.pasteAtPosition(menuPosition);
	};

	private onDelete = () => {
		this.hide();
		this.dependencies.useCases.removeFocusComponents();
	};

	private onGroup = () => {
		this.hide();
		this.dependencies.useCases.groupFocusComponents();
	};

	private onUngroup = () => {
		this.hide();
		this.dependencies.useCases.ungroupFocusComponents();
	};

	private onGraphicForward = (graphic: IGraphic) => {
		this.hide();
		this.dependencies.useCases.moveForwardGraphics(graphic);
	};

	private onGraphicBack = (graphic: IGraphic) => {
		this.hide();
		this.dependencies.useCases.moveBackGraphics(graphic);
	};

	private onGraphicForeground = (graphic: IGraphic) => {
		this.hide();
		this.dependencies.useCases.moveToForegroundGraphics(graphic);
	};

	private onGraphicBackground = (graphic: IGraphic) => {
		this.hide();
		this.dependencies.useCases.moveToBackgroundGraphics(graphic);
	};

	private onModule = (component: IComponent | null) => {
		if (component === null) {
			throw new ManipulatorError('component is null');
		}

		this.dependencies.useCases.createModule();

		this.hide();
	};

	private onInterfaceShow = () => {
		this.hide();
		this.dependencies.manipulatorInterface.show();
	};

	private onInterfaceHide = () => {
		this.hide();
		this.dependencies.manipulatorInterface.hide();
	};

	private onCellsMerge = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.mergeFocusCell(component as TableComponent);
	};

	private onCellsSplit = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.splitFocusCells(component as TableComponent);
	};

	private onTableSplit = (component: IComponent, activeCellArea: AnySpatialArea) => {
		this.hide();
		this.dependencies.useCases
			.splitTable(component as TableComponent, activeCellArea as SpatialTableCellArea);
	};

	private onCellsDeleteRow = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.deleteFocusRows(component as TableComponent);
	};

	private onAddColumnAfter = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.addColumnAfter(component as TableComponent);
	};

	private onAddColumnBefore = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.addColumnBefore(component as TableComponent);
	};

	private onAddRowUnder = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.addRowUnder(component as TableComponent);
	};

	private onAddRowOver = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.addRowOver(component as TableComponent);
	};

	private onCellsDeleteColumn = (component: IComponent) => {
		this.hide();
		this.dependencies.useCases.deleteFocusColumns(component as TableComponent);
	};

	private getIsShowedInterface = (): boolean => this.dependencies.manipulatorInterface.isShowed();
}

export default WorkshopTemplateContextMenuHandler;
