import { useAppSelector } from '../../../../shared/libs/redux';
import { changeStatusUserAbility } from '../../../../features/user/user-status/model/data';
import { ACCOUNT_STATUS } from '../../../../entities/user/account/api/types';

export const useChangeStatusAbility = (status: ACCOUNT_STATUS, email: string): boolean => {
	const {
		status: accountStatus,
		email: accountEmail,
	} = useAppSelector(state => state.personalAccount.account);

	if (accountEmail === email) {
		return false;
	}

	if (accountStatus) {
		const data = changeStatusUserAbility.get(accountStatus);
		return data ? data[status] : false;
	}

	return false;
};
