import React from 'react';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { setIsUsersCreateModalActive } from '../../../../../redux/reducers/usersReducer';
import CreateUser from '../createModalBody/CreateUser';
import { useCreateUser } from '../../api/useCreateUser';
import { CreateUserModal } from '../../../../../widgets/modals/create-user';

interface CreateUserWindowProps {
	statusRights: checkUsersRightsInterface,
}

export const CreateUserModalWrapper = ({ statusRights }: CreateUserWindowProps) => {
	const dispatch = useAppDispatch();

	const {
		createUserName,
		createUserPhone,
		createUserEmail,
		onCreateUser,
		isLoading,
	} = useCreateUser(statusRights);

	const isActive = useAppSelector(state => state.user.modalsActive.isUsersCreateModalActive);

	const setActive = (value: boolean) => dispatch(setIsUsersCreateModalActive(value));

	return (
		<CreateUserModal
			name={createUserName}
			email={createUserEmail}
			phone={createUserPhone}
			isActive={isActive}
			setActive={setActive}
			onCreating={onCreateUser}
			isDataLoading={isLoading}
			title="Создать пользователя"
		/>
	);
};

export default CreateUser;
