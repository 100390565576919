import IDescartesPosition from '../../utils/IDescartesPosition';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import { CopyToOriginalComponents, IdentityToGraphic, LayerSequences } from '../../Types';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import IComponentFactory from '../../factories/component/IComponentFactory';
import IGraphicFactory from '../../factories/graphic/IGraphicFactory';
import IComponent from '../../components/IComponent';
import ComponentBuilder from '../../factories/ComponentBuilder';
import IGraphic from '../../graphic/IGraphic';
import IClonerClipboard from './IClonerClipboard';
import MousePositionObserver from '../../utils/observers/MousePositionObserver';
import IComponentTreeMutator from '../../component-tree/IComponentTreeMutator';
import SketchComponentType from '../../components/SketchComponentType';
import TableComponent from '../../components/table/TableComponent';
import ITableGraphicTexture from '../../graphic/table/ITableGraphicTexture';
import IPostPasteLayers from './IPostPasteLayers';
import IComponentCloner from './IComponentCloner';

export interface IComponentClonerProps {
	toPasteSketchID: string,
	graphicFactory: IGraphicFactory,
	componentFactory: IComponentFactory,
}

/**
 * Утилита для управления копированием и вставкой компонентов.
 */
abstract class ComponentCloner implements IComponentCloner {
	private readonly FRAGMENT_MIME = 'web wakadoo/fragment';

	protected readonly toPasteSketchID: string;
	protected readonly graphicFactory: IGraphicFactory;
	protected readonly postPasteListeners: VoidFunction[];
	protected readonly componentFactory: IComponentFactory;
	protected readonly componentBuilders: ComponentBuilder[];

	protected constructor(props: IComponentClonerProps) {
		this.componentBuilders = [];
		this.postPasteListeners = [];
		this.graphicFactory = props.graphicFactory;
		this.toPasteSketchID = props.toPasteSketchID;
		this.componentFactory = props.componentFactory;
	}

	/**
	 * Корректирует позицию компонента на основе глобальной позиции оригинального компонента.
	 * @param component Скопированный компонент с оригинального без привязки к дереву
	 * @param copyToOriginalComponents Отражение копии компонента к его оригиналу
	 */
	protected correctComponentPositionInCopy = (
		component: IComponent,
		copyToOriginalComponents: CopyToOriginalComponents,
	) => {
		const originalComponent = copyToOriginalComponents.get(component);
		if (originalComponent === undefined) {
			throw new ManipulatorError('original component not found');
		}
		const graphics = component.getGraphics();
		const originalGraphics = originalComponent.getGraphics();

		if (!(graphics.length === originalGraphics.length)) {
			throw new ManipulatorError('incorrect graphs');
		}

		for (let i = 0; i < graphics.length; ++i) {
			const originalGraphicsConfiguration = originalGraphics[i].getGlobalPosition();
			graphics[i].setFrameConfiguration(prev => ({
				...prev,
				x: originalGraphicsConfiguration.x,
				y: originalGraphicsConfiguration.y,
			}));
		}
	};

	/**
	 * Заполняет отражение копии компонента к его оригиналу включая все его вложенные компоненты.
	 * @param component Оригинальный компонент, привязанный к дереву компонентов.
	 * @param copyComponent Скопированный компонент с оригинального без привязки к дереву.
	 * @param copyToOriginalComponents Отражение копии компонента к его оригиналу.
	 */
	protected fillCopyToOriginalComponents = (
		component: IComponent,
		copyComponent:IComponent,
		copyToOriginalComponents: CopyToOriginalComponents,
	) => {
		const allComponent: IComponent[] = [component, ...component.getComponentAll()];
		const allCopyComponent: IComponent[] = [copyComponent, ...copyComponent.getComponentAll()];

		if (allComponent.length !== allCopyComponent.length) {
			throw new ManipulatorError('count components mismatched');
		}

		allComponent.forEach((component, index) => {
			copyToOriginalComponents.set(allCopyComponent[index], component);
		});
	};

	/**
	 * Записывает компоненты в ComponentBuilders для последующей вставки.
	 * @param clonerClipboard - интерфейс для хранения коомпонентов
	 */
	protected pullComponentBuilders = async (clonerClipboard: IClonerClipboard): Promise<IClonerClipboard | null> => {
		if (clonerClipboard !== null) {
			clonerClipboard.structures.forEach(structure => {
				const builder = new ComponentBuilder();
				builder.connectDependencies({
					graphicFactory: this.graphicFactory,
					componentFactory: this.componentFactory,
				});
				builder.injectDependencies();
				builder.scanStructure(structure);

				this.componentBuilders.push(builder);
			});
		}

		return clonerClipboard;
	};

	protected callPostPasteListeners = () => {
		this.postPasteListeners.forEach(listener => { listener(); });
	};

	/**
	 * Перемещает структуру в позицию `position` на странице в фокусе.
	 * @param component Обрабатываемый компонент.
	 * @param positionOffset - Сдвиг, насколько должна измениться позиция графики.
	 */
	protected correctStructuresToPosition = (component: IComponent, positionOffset: IDescartesPosition):IComponent => {
		const graphics = component.getGraphics();
		graphics.forEach(graphic => {
			graphic.setFrameConfiguration(prev => ({
				...prev,
				x: prev.x + positionOffset.x,
				y: prev.y + positionOffset.y,
			}));
		});

		return component;
	};

	/**
	 * Вставляет компоненты в корневой компонент, предварительно обработав их пришедшим обработчиком.
	 * @param process Обработчик компонента.
	 */
	protected injectComponents = (process: (component: IComponent) => IComponent) => {
		const components = this.componentBuilders.map(builder => builder.getComponent());
		components.forEach(component => {
			process(component);
		});
		this.pasteComponents();
	};

	/**
	 * Сжимает компоненты таблиц до одной графики.
	 * @param tables Компоненты таблиц для сжатия.
	 */
	protected compressTableComponents = (...tables: TableComponent[]) => {
		tables.forEach(tableComponent => {
			const structure = tableComponent.getStructure();
			if (structure.graphics === null) {
				return;
			}

			const rowCount = structure.graphics
				.reduce((rowCount, graphic) => (graphic.texture as ITableGraphicTexture).rowCount + rowCount, 0);

			const firstGraphic = tableComponent.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}

			const graphics = tableComponent.getGraphics();
			for (let i = 1; i < graphics.length; i++) {
				tableComponent.removeGraphic(graphics[i]);
			}

			firstGraphic.setTexture(() => ({
				startRow: 0,
				rowCount,
			}));
		});
	};

	/**
	 * Сжимает компонент-объединитель до одной графики.
	 * @param copyToOriginalComponents Отражение копии компонента к его оригиналу.
	 * @param toCompressCopyComponent Копия компонента.
	 * @param toCompressOriginalComponent Оригинальный компонент.
	 */
	protected compressUniterComponents = (
		copyToOriginalComponents: CopyToOriginalComponents,
		toCompressCopyComponent: IComponent,
		toCompressOriginalComponent: IComponent,
	) => {
		if (!toCompressCopyComponent.isUniter || !toCompressOriginalComponent.isUniter) {
			return;
		}

		if (!(toCompressCopyComponent.isUniter && toCompressOriginalComponent.isUniter)) {
			throw new ManipulatorError('component types mismatched');
		}

		const toCompressCopyGraphics = toCompressCopyComponent.getGraphics();
		const toCompressOriginalGraphics = toCompressOriginalComponent.getGraphics();

		if (toCompressCopyGraphics.length <= 1) {
			return;
		}

		// Удаление всех график, кроме первой.
		for (let i = 1; i < toCompressCopyGraphics.length; i++) {
			toCompressCopyComponent.removeGraphic(toCompressCopyGraphics[i]);
		}

		// 0 - между 0 и 1 графикой.
		// 1 - между 1 и 2 графикой.
		const betweenGraphicsDistances: number[] = [];
		for (let i = 0; i < toCompressOriginalGraphics.length - 1; i++) {
			const currentGraphic = toCompressOriginalGraphics[i];
			const nextGraphic = toCompressOriginalGraphics[i + 1];
			const currentGraphicConfiguration = currentGraphic.getFrameConfiguration();
			const currentGraphicPosition = currentGraphic.getGlobalPosition();
			const nextGraphicPosition = nextGraphic.getGlobalPosition();

			const distance = nextGraphicPosition.y
				- (currentGraphicPosition.y + currentGraphicConfiguration.height);
			betweenGraphicsDistances.push(distance);
		}

		// Получаем все компоненты из компонента-объединителя.
		// TODO getComponentAll не подойдет при рекурсивном хождении, учитывая вложенные компоненты-объединители.
		const internalCopiedComponents = toCompressCopyComponent.getComponentAll();

		// Проходим по ним и ищем те, у кого offset !== 0.
		// Если такой нашелся - изменяем его offset и
		// корректируем его координаты относительно оригинальной графики компонента-объединителя.
		// TODO рекурсивно внутрь, потому что могут быть вложенные компоненты-объединители.
		internalCopiedComponents.forEach(copiedComponent => {
			const componentOffset = copiedComponent.getOffset();
			if (componentOffset === null) {
				throw new ManipulatorError('component offset is null');
			}

			if (componentOffset === 0) {
				return;
			}

			copiedComponent.setStructure(prev => ({
				...prev,
				offset: 0,
			}));

			const originalComponent = copyToOriginalComponents.get(copiedComponent);
			if (originalComponent === undefined) {
				throw new ManipulatorError('original component not found');
			}

			const firstOriginalGraphic = originalComponent.getFirstGraphic();
			if (firstOriginalGraphic === null) {
				throw new ManipulatorError('first original graphic not found');
			}
			const graphicGlobalPosition = firstOriginalGraphic.getGlobalPosition();

			const toCompressOriginalFirstGraphic = toCompressOriginalComponent.getFirstGraphic();
			if (toCompressOriginalFirstGraphic === null) {
				throw new ManipulatorError('to compress first original graphic not found');
			}
			const toCompressGraphicGlobalPosition = toCompressOriginalFirstGraphic.getGlobalPosition();

			const updatedPosition: IDescartesPosition = {
				x: graphicGlobalPosition.x - toCompressGraphicGlobalPosition.x,
				y: graphicGlobalPosition.y - toCompressGraphicGlobalPosition.y,
			};

			// Коррекция позиции с учетом расстояния разрыва между страницами.
			const graphics = copiedComponent.getGraphics();
			graphics.forEach((graphic, index) => {
				const firstDistanceIndex = componentOffset - 1;
				const lastDistanceIndex = componentOffset + index - 1;

				let sumBetweenGraphicDistance = 0;
				for (let i = firstDistanceIndex; i <= lastDistanceIndex; i++) {
					const betweenGraphicDistance = betweenGraphicsDistances[i];
					if (betweenGraphicDistance === undefined) {
						throw new ManipulatorError('distance not found');
					}

					sumBetweenGraphicDistance += betweenGraphicDistance;
				}

				graphic.setFrameConfiguration(prev => ({
					...prev,
					x: updatedPosition.x,
					y: updatedPosition.y - sumBetweenGraphicDistance,
				}));
			});
		});
	};

	protected saveToBuffer = (builders: ComponentBuilder[]) => {
		const clonerClipboard: IClonerClipboard = {
			structures: [],
			prevSketch: this.toPasteSketchID,
		};

		builders.forEach(builder => {
			clonerClipboard.structures.push(builder.getUniqueStructure());
		});

		const wakadooFragment = `JSON:${JSON.stringify(clonerClipboard)}`;
		const blob = new Blob([wakadooFragment], { type: this.FRAGMENT_MIME });
		const data = [new ClipboardItem({
			[this.FRAGMENT_MIME]: blob,
		})];
		navigator.clipboard.write(data);
	};

	protected getPostPasteLayers = (
		buildersLayerSequences: LayerSequences[],
		graphics: IGraphic[],
		lastPageGraphic: IGraphic,
	): IPostPasteLayers => {
		const layers: IPostPasteLayers = {
			baseGraphic: lastPageGraphic,
			graphicIdentities: [],
		};

		const buildersLayers: string[][] = buildersLayerSequences.map(sequence => sequence.flat());
		const identityToGraphic: IdentityToGraphic = new Map();
		graphics.forEach(graphic => identityToGraphic.set(graphic.getID(), graphic));

		buildersLayers.sort((a, b) => this.compareLayerSequenceFunction(a, b, identityToGraphic));

		layers.graphicIdentities = buildersLayers.flat();

		return layers;
	};

	private compareLayerSequenceFunction = (a: string[], b: string[], identityToGraphic: IdentityToGraphic): number => {
		if (a.length === 0 || b.length === 0) {
			throw new ManipulatorError('layers is empty');
		}

		const aFirstID = a[0];
		const bFirstID = b[0];

		if (aFirstID === undefined) {
			throw new ManipulatorError('a first id not found');
		}
		if (bFirstID === undefined) {
			throw new ManipulatorError('b first id not found');
		}

		const aMinLayerGraphic = identityToGraphic.get(aFirstID);
		if (aMinLayerGraphic === undefined) {
			throw new ManipulatorError('graphic not found');
		}
		const bMinLayerGraphic = identityToGraphic.get(bFirstID);
		if (bMinLayerGraphic === undefined) {
			throw new ManipulatorError('graphic not found');
		}

		const aConfiguration = aMinLayerGraphic.getFrameConfiguration();
		const bConfiguration = bMinLayerGraphic.getFrameConfiguration();

		return aConfiguration.layer < bConfiguration.layer ? -1 : 1;
	};

	protected syncLayers = (
		postPasteLayers: IPostPasteLayers,
		mutator: IComponentTreeMutator,
		identityToGraphic: IdentityToGraphic,
	) => {
		if (postPasteLayers.graphicIdentities.length === 0) {
			throw new ManipulatorError('graphics not found', { postPasteLayers });
		}

		const firstGraphic = identityToGraphic.get(postPasteLayers.graphicIdentities[0]);
		if (firstGraphic === undefined) {
			throw new ManipulatorError('first graphic not found');
		}

		mutator.mutateByChangeLayer(postPasteLayers.baseGraphic, firstGraphic);

		for (let i = 0; i < postPasteLayers.graphicIdentities.length - 1; ++i) {
			const prevGraphic = identityToGraphic.get(postPasteLayers.graphicIdentities[i]);
			const graphic = identityToGraphic.get(postPasteLayers.graphicIdentities[i + 1]);
			if (prevGraphic === undefined || graphic === undefined) {
				throw new ManipulatorError('graphic not found', { prevGraphic, graphic });
			}

			mutator.mutateByChangeLayer(prevGraphic, graphic);
		}
	};

	abstract pasteAtPosition: (
		position: IDescartesPosition,
		firstGraphic: IGraphic,
		clonerClipboard: IClonerClipboard,
	) => Promise<void>;

	abstract copy: (component: IComponent[]) => void;
	abstract pasteWithAlt: (components: IComponent[]) => void;
	abstract addPostPasteListener: (listener: VoidFunction) => void;

	protected abstract pasteComponents: () => void;
}

export default ComponentCloner;
