import { useCallback } from "react";
import { useAppSelector } from "../../shared/libs/redux";

export const useShowPermission = () => {
	const permissions = useAppSelector(state => state.permissions.permissionList);

	const showTeamPermission = useCallback((id: string) => {
		const team = permissions?.teams?.find(({ team }) => team.id === id);
		return team?.permission ?? 0;
	}, [permissions]);

	const showLegalEntityPermission = useCallback((id: string) => {
		const legalEntity = permissions?.legalEntities?.find(({ legalEntity }) => legalEntity.id === id);
		return legalEntity?.permission ?? 0;
	}, [permissions]);

	return {
		permissions,
		showTeamPermission,
		showLegalEntityPermission,
	};
};
