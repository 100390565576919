import FocusableFrame from '../focusing/FocusableFrame';
import IEditableFrame from './IEditableFrame';

/**
 * Фрейм, поддерживающий режим редактирования. Поведения режима определяется
 * в наследуемых классах.
 * @abstract
 */
abstract class EditableFrame extends FocusableFrame implements IEditableFrame {
	private readonly EDIT_MODE_CLASS_NAME = 'editable';
	private isEditMode: boolean;

	protected constructor() {
		super();
		this.isEditMode = false;
	}

	public enableEditMode = (): void => {
		if (this.isEditMode) {
			return;
		}
		this.isEditMode = true;
		this.startMutation();
		this.frameElement.classList.add(this.EDIT_MODE_CLASS_NAME);
	};

	public disableEditMode = (): void => {
		if (!this.isEditMode) {
			return;
		}
		this.isEditMode = false;
		this.finishMutation();
		this.frameElement.classList.remove(this.EDIT_MODE_CLASS_NAME);
	};

	public isEnableEditMode = (): boolean => this.isEditMode;

	protected abstract startMutation: VoidFunction;
	protected abstract finishMutation: VoidFunction;
}

export default EditableFrame;
