import SketchComponentType from '../SketchComponentType';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import UniterComponent from '../UniterComponent';
import IGroupComponentTexture from './IGroupComponentTexture';

/**
 * Групповой компонент, который служит для объединения других компонентов, в том числе и других групп.
 */
class GroupComponent extends UniterComponent<IGroupComponentTexture, GroupGraphic> {
	public readonly type: SketchComponentType = SketchComponentType.GROUP;

	private layerSequence: string[];

	constructor() {
		super();
		this.layerSequence = [];
	}

	public getTexture = (): IGroupComponentTexture => ({
		layerSequence: this.layerSequence,
	});

	public getUniqueTexture = (): IGroupComponentTexture => this.getTexture();

	public setTexture = (fn: (prev: IGroupComponentTexture) => IGroupComponentTexture) => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);
		if (updatedTexture === null) {
			return;
		}

		this.layerSequence = updatedTexture.layerSequence;
	};
}

export default GroupComponent;
