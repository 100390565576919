import React, { useState } from 'react';
import { notificationError } from '../../../../Notifications/callNotifcation';
import { useInput } from '../../../../../hooks/useInput';
import ResetPassword from './ResetPassword';
import { CurrentAuthorizationPageData, FINALLY_RESET_AUTHORIZATION_PAGE } from '../Authorization';
import { authAPI } from '../../../../../entities/user/auth/api/api';

interface ResetPasswordContainerProps {
	setCurrentPage: (value: CurrentAuthorizationPageData) => void,
}

const ResetPasswordContainer = ({ setCurrentPage }: ResetPasswordContainerProps) => {
	const email = useInput('', {
		isEmpty: true,
		minLength: 5,
		isEmail: true,
		maxLength: 255,
	});

	const [isEmailCorrect, setIsEmailCorrect] = useState<undefined | boolean>(undefined);

	const isEmailNotValid = (email.emailError
		|| email.isEmpty
		|| email.minLengthError
		|| email.maxLengthError);

	const [resetPassword, { isLoading }] = authAPI.useResetPasswordMutation();

	const onResetPassword = async () => {
		try {
			await resetPassword({ email: email.value })
				.unwrap();

			setIsEmailCorrect(true);
			setCurrentPage(FINALLY_RESET_AUTHORIZATION_PAGE);
		} catch (error) {
			notificationError('Ошибка!', 'Произошла ошибка при сбросе пароля');
			setIsEmailCorrect(false);
		}
	};

	const handleKeyPressGetPassword = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.code === 'Enter') {
			await onResetPassword();
		}
	};

	return (
		<ResetPassword
			email={email}
			isEmailCorrect={isEmailCorrect}
			setIsEmailCorrect={setIsEmailCorrect}
			isDataLoading={isLoading}
			onResetPassword={onResetPassword}
			handleKeyPressGetPassword={handleKeyPressGetPassword}
			isEmailNotValid={isEmailNotValid}
			setCurrentPage={setCurrentPage}
		/>
	);
};

export default ResetPasswordContainer;
