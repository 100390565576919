import InterfacePanel from '../../../InterfacePanel';
import AITool from './AITool';
import {
	onOpenAiModal,
} from '../../../../../../redux/thunk-creators/moduleWindow';
import store from '../../../../../../redux/store/store';

class AIPanel extends InterfacePanel {
	private aiTool: AITool;
	private isOpen: boolean;

	constructor(
		private enableAIModalMode: () => void,
		private disableAIModalMode: VoidFunction,
		addDestructListener: (event: () => void) => void,
	) {
		super();
		this.aiTool = new AITool();

		this.setRootElementClassName('panel-ai');
		this.rootElement.append(
			this.aiTool.getElement(),
		);
		this.rootElement.addEventListener('click', this.onClick);

		this.rootElement.id = 'ai-panel';

		this.rootElement.addEventListener('click', () => {
			const { aiMode } = store.getState().ai;
			if (aiMode) return;
			onOpenAiModal(disableAIModalMode);
			enableAIModalMode();
		});
		this.enableFlashing();
		addDestructListener(() => this.destruct);
	}

	private onClick = () => {
		onOpenAiModal(this.disableAIModalMode);
		this.enableAIModalMode();
		this.isOpen = true;
	};

	private destruct = () => {
		this.rootElement.removeEventListener('click', this.onClick);
	};

	public enableFlashing = () => {
		this.rootElement.classList.add('pulse');
	};

	public disableFlashing = () => {
		this.rootElement.classList.remove('pulse');
	};
}

export default AIPanel;
