import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { notificationError } from '../../../components/Notifications/callNotifcation';
import { URI_TEAM, URI_TEAM_BIND, URI_TEAM_UNBIND } from './consts';
import {
	IBindTeamListRequest, IGetTeamListRequest, IGetTeamListResponse, IUnbindTeamListRequest, 
} from './types';
import { setTeamsList as setPermissionsTeams } from '../../../redux/reducers/permissionsReducer';
import { setTeamsList } from '../../../redux/reducers/teamReducer';
import { IGetEmployeeListResponse } from '../../employee/api/types';

export const teamAPI = createApi({
	reducerPath: 'teamAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['team'],
	endpoints: builder => ({
		getAll: builder.query<IGetTeamListResponse, IGetTeamListRequest>({
			query: (data) => ({
				url: URI_TEAM,
				method: 'GET',
				params: data,
			}),
			async onQueryStarted(arg, {
				dispatch,
				queryFulfilled,
			}) {
				try {
					const { data } = await queryFulfilled;

					dispatch(setTeamsList(data.teams));
					dispatch(setPermissionsTeams(data));
				} catch (error) {
					notificationError('Возникла ошибка при загрузке юр. лиц....');
				}
			},
			providesTags: ['team'],
		}),
		getAvailableAccounts: builder.query<IGetEmployeeListResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_TEAM}/${id}/accounts`,
				method: 'GET',
			}),
			providesTags: ['team'],
		}),
		create: builder.mutation<{ id: string }, { name: string }>({
			query: (data) => ({
				url: URI_TEAM,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['team'],
		}),
		bind: builder.mutation<void, IBindTeamListRequest>({
			query: (data) => ({
				url: URI_TEAM_BIND,
				method: 'PUT',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['team'],
		}),
		unbind: builder.mutation<void, IUnbindTeamListRequest>({
			query: (data) => ({
				url: URI_TEAM_UNBIND,
				method: 'DELETE',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['team'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_TEAM}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['team'],
		}),
	}),
});
