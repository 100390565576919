import css from './item.module.scss';

interface AdminUserMainInfoItemProps {
	value: string | undefined,
	description: string,
}

export const AdminUserMainInfoItem = ({
	description,
	value,
}: AdminUserMainInfoItemProps) => {
	const a = '';
	return (
		<div className={css.wrapper}>
			<div className={css.value}>{value}</div>
			<div className={css.description}>{description}</div>
		</div>
	);
};
