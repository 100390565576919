import IFrameArea from '../spatial-tree/spatial-area/IFrameArea';
import IMutableEntity from './IMutableEntity';
import OnMutationListener from './events/OnMutationEvent';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IAreaSizeMutable from './IAreaSizeMutable';
import IAreaMutationEvent from './events/IAreaMutationEvent';
import AreaMutationEventType from './events/AreaMutationEventType';
import AreaMutationEventStage from './events/AreaMutationEventStage';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import BlindZoneDetector from './BlindZoneDetector';
import { OnStartMutationListener } from './events/OnStartMutationListener';
import { AnySpatialArea } from '../../../Types';
import AreaResizeTrigger from '../spatial-tree/spatial-area/AreaResizeTrigger';

/** Базовая сущность для изменения областей. */
abstract class AreaMutator<EventBody> {
	private readonly type: AreaMutationEventType;
	private readonly startMutationListeners: OnStartMutationListener[];
	private readonly postMutationListeners: OnMutationListener[];
	private readonly prevMutationListeners: OnMutationListener[];
	private readonly stopMutationListeners: OnMutationListener[];
	private readonly blindZoneDetector: BlindZoneDetector;

	protected readonly mutableEntities: IMutableEntity[];
	protected activeTrigger: AreaResizeTrigger | null;

	private isMutate: boolean;
	protected maintainAspectRatio: boolean;
	protected isDisableAspectRatio: boolean;
	private correctValues: IFrameArea | null;
	private initiatorArea: AnySpatialArea | null;

	// Изменения относительно начальной конфигурации (абсолютные изменения)
	protected absoluteAreaOffsets: IFrameArea | null;

	protected constructor(type: AreaMutationEventType) {
		this.type = type;
		this.isMutate = false;
		this.mutableEntities = [];
		this.initiatorArea = null;
		this.postMutationListeners = [];
		this.prevMutationListeners = [];
		this.stopMutationListeners = [];
		this.startMutationListeners = [];
		this.absoluteAreaOffsets = null;
		this.maintainAspectRatio = false;
		this.blindZoneDetector = new BlindZoneDetector();
		this.correctValues = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
		};
	}

	/**
	 * Начинает мутацию областей.
	 * @param initiatorArea - область, которая спровоцировала мутацию.
	 * @param areas - связанные для мутации области.
	 */
	public start = (initiatorArea: AnySpatialArea, ...areas: AnySpatialArea[]) => {
		const mutableAreas: IAreaSizeMutable[] = [initiatorArea, ...areas]
			.filter(area => area.isAllowMutatePosition())
			.map(area => area.getRelatedFrames())
			.flat();

		mutableAreas.forEach(frame => {
			const minWidth = frame.getMinWidth();
			const minHeight = frame.getMinHeight();
			const initialFrameArea = frame.getFrameArea();
			const postMutationEvents = frame.getPostMutationEvents();
			const aspectRatio = initialFrameArea.width / initialFrameArea.height;
			this.mutableEntities.push({
				initialFrameArea, postMutationEvents, frame, aspectRatio, minWidth, minHeight,
			});
		});

		this.maintainAspectRatio = this.getMaintainAspectRatio(mutableAreas);

		this.absoluteAreaOffsets = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
		};
		this.isMutate = true;
		this.initiatorArea = initiatorArea;

		this.blindZoneDetector.start();

		this.postStartEvent([initiatorArea, ...areas]);
		this.runStartMutationsEvents({ x: 0, y: 0 });
		this.runStartMutationListeners(initiatorArea, areas);
	};

	/** Закончить мутацию областей. */
	public stop = () => {
		if (!this.isMutate) {
			return;
		}
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		this.runStopMutationsListeners({ x: this.absoluteAreaOffsets.x, y: this.absoluteAreaOffsets.y });
		this.runStopMutationsLocalListeners({ x: this.absoluteAreaOffsets.x, y: this.absoluteAreaOffsets.y });

		this.blindZoneDetector.stop();

		this.isMutate = false;
		this.initiatorArea = null;
		this.absoluteAreaOffsets = null;
		this.mutableEntities.length = 0;
		this.maintainAspectRatio = false;
		this.postStopEvent();
	};

	/** Сообщить об изменениях в пространстве, тем самым инициировав мутацию области. */
	public mutate = (offsetX: number, offsetY: number) => {
		if (!this.isMutate) {
			return;
		}

		this.updateOffsets(offsetX, offsetY);
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('offsets is null');
		}
		this.blindZoneDetector.setOffsets(this.absoluteAreaOffsets);

		if (!this.blindZoneDetector.isBlindZoneOverstepping()) {
			return;
		}
		const mutationEvent = this.getMutationEvent(offsetX, offsetY);

		this.runPrevMutationListeners(mutationEvent);
		this.mutateFrames();
		this.runPostMutationListeners(mutationEvent);

		this.callEntitiesMutationEvents(mutationEvent);
	};

	/**
	 * Возвращает результат выхода за пределы слепой зоны.
	 */
	public isBlindZoneOverstepping = (): boolean => this.blindZoneDetector.isBlindZoneOverstepping();

	/** Добавить слушателя мутации области. */
	public addPostMutationListener = (listener: OnMutationListener) => {
		this.postMutationListeners.push(listener);
	};

	/**
	 * Добавляет слушателя, который оповещается перед началом движения мутатора.
	 * @param listener - слушатель.
	 */
	public addPrevMutationListener = (listener: OnMutationListener) => {
		this.prevMutationListeners.push(listener);
	};

	/** Добавить слушателя начала мутации. */
	public addStartMutationListener = (listener: OnStartMutationListener) => {
		this.startMutationListeners.push(listener);
	};

	/** Отключает возможность применить механику с сохранением соотношения сторон. */
	public disableMaintainAspectRatio = () => {
		this.isDisableAspectRatio = true;
	};

	/** Отключает задержку при начале движения. */
	public disableBlindMode = () => {
		this.blindZoneDetector.disableDetector();
	};

	/** Добавляет слушатель на остановку процесса мутации. */
	public addStopMutationListener = (listener: OnMutationListener) => {
		this.stopMutationListeners.push(listener);
	};

	/**
	 * Устанавливает корректирующие значения, которые применяются к сдвигам для передачи их областям.
	 * @param values - корректирующие значения.
	 */
	public setCorrectValues = (values: IFrameArea) => {
		this.correctValues = values;
	};

	/**
	 * Сбрасывает корректирующие значения.
	 */
	public clearCorrectValues = () => {
		this.correctValues = null;
	};

	/**
	 * Возвращает сухую конфигурацию области без учета дополнительных сдвигов.
	 * @param area - начальные значения области в момент начала мутации.
	 */
	public getDryConfiguration = (area: IFrameArea): IFrameArea => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offset is null');
		}

		return {
			x: area.x + this.absoluteAreaOffsets.x,
			y: area.y + this.absoluteAreaOffsets.y,
			width: area.width + this.absoluteAreaOffsets.width,
			height: area.height + this.absoluteAreaOffsets.height,
			rotate: area.rotate + this.absoluteAreaOffsets.rotate,
		};
	};

	/**
	 * Возвращает корректирующие сдвиг значения.
	 */
	public getCurrentCorrectOffsets = () => this.correctValues;

	public isRunningMutate = (): boolean => this.isMutate;
	public getInitiatorArea = (): AnySpatialArea | null => this.initiatorArea;

	/**
	 * На основании сущностей для мутации определяет, нужно ли сохранить соотношение сторон при мутации.
	 */
	private getMaintainAspectRatio = (mutableAreas: IAreaSizeMutable[]): boolean => {
		if (this.mutableEntities.length === 0) {
			return false;
		}

		return mutableAreas.find(area => area.isMaintainAspectRatio()) !== undefined;
	};

	/** Физическое изменение фреймов. */
	private mutateFrames = () => {
		if (this.absoluteAreaOffsets === null) {
			return;
		}
		for (let i = 0; i < this.mutableEntities.length; i++) {
			const entity = this.mutableEntities[i];

			let resultValues = this.getAbsoluteValues(entity);

			const isValidValues = this.correctMinMaxValues(resultValues, entity);
			// eslint-disable-next-line no-continue
			if (!isValidValues && entity.minWidth !== 0) continue;

			if (this.maintainAspectRatio) {
				resultValues = this.correctAspectRatioValues(resultValues, entity);
			}
			resultValues = this.applyCorrectValues(resultValues);
			entity.frame.mutateFrameArea(_ => resultValues);
		}
	};

	private correctMinMaxValues = (
		resultValues: IFrameArea,
		entity: IMutableEntity,
	): boolean => !(resultValues.width < entity.minWidth
			|| resultValues.height < entity.minHeight);

	/**
	 * Пересчитывает сдвиги с учетом корректирующих значений (Магнитные линии например).
	 */
	private applyCorrectValues = (offsets: IFrameArea): IFrameArea => {
		if (this.correctValues === null) {
			return offsets;
		}

		return {
			x: offsets.x + this.correctValues.x,
			y: offsets.y + this.correctValues.y,
			width: offsets.width + this.correctValues.width,
			height: offsets.height + this.correctValues.height,
			rotate: offsets.rotate + this.correctValues.rotate,
		};
	};

	/**
	 * Запустить события начала мутации.
	 * @param offsets - текущие сдвиги координат.
	 */
	private runStartMutationsEvents = (offsets: IDescartesPosition) => {
		const event: IAreaMutationEvent<EventBody> = {
			type: this.type,
			stage: AreaMutationEventStage.START,
			body: this.getMutationEventBody(),
			offsets,
		};
		this.callEntitiesMutationEvents(event);
	};

	/**
	 * Запускает события окончания мутации.
	 * @param offsets - текущие сдвиги координат.
	 */
	private runStopMutationsListeners = (offsets: IDescartesPosition) => {
		const event: IAreaMutationEvent<EventBody> = {
			type: this.type,
			stage: AreaMutationEventStage.STOP,
			body: this.getMutationEventBody(),
			offsets,
		};
		this.callEntitiesMutationEvents(event);
	};

	/**
	 * Запускает обработчики окончания мутации, загруженные в сам мутатор.
	 * @param offsets - текущие сдвиги координат.
	 */
	private runStopMutationsLocalListeners(offsets: IDescartesPosition) {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		const event: IAreaMutationEvent<EventBody> = {
			type: this.type,
			stage: AreaMutationEventStage.STOP,
			body: this.getMutationEventBody(),
			offsets,
		};
		this.stopMutationListeners.forEach(listener => listener(event, this.absoluteAreaOffsets!));
	}

	/**
	 * Запускает события после мутации, загруженные в сам мутатор.
	 */
	private runPostMutationListeners = (mutationEvent: IAreaMutationEvent<any>) => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		this.postMutationListeners.forEach(listener => {
			listener(mutationEvent, this.absoluteAreaOffsets!);
		});
	};

	private runPrevMutationListeners = (mutationEvent: IAreaMutationEvent<any>) => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		this.prevMutationListeners.forEach(listener => {
			listener(mutationEvent, this.absoluteAreaOffsets!);
		});
	};

	/**
	 * Оповещает слушателей начала мутации, загруженных в сам мутатор.
	 */
	private runStartMutationListeners = (initiator: AnySpatialArea, areas: AnySpatialArea[]) => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		this.startMutationListeners.forEach(event => {
			event({
				areas,
				initiator,
				mutator: this,
			});
		});
	};

	/**
	 * Уведомляет о событиях все подписанные сущности.
	 */
	private callEntitiesMutationEvents = (mutationEvent: IAreaMutationEvent<any>) => {
		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('frame area offsets not found');
		}
		this.mutableEntities.forEach(entity => {
			entity.postMutationEvents.forEach(event => event(mutationEvent, this.absoluteAreaOffsets!));
		});
	};

	/**
	 * Получить объект события процесса мутации.
	 * @param offsetX - сдвиг по оси x.
	 * @param offsetY - сдвиг по оси y.
	 */
	private getMutationEvent = (offsetX: number, offsetY: number): IAreaMutationEvent<EventBody> => ({
		type: this.type,
		stage: AreaMutationEventStage.PROCESS,
		offsets: {
			x: offsetX,
			y: offsetY,
		},
		body: this.getMutationEventBody(),
	});

	private getAbsoluteValues(entity: IMutableEntity): IFrameArea {
		const x = entity.initialFrameArea.x + this.absoluteAreaOffsets!.x;
		const y = entity.initialFrameArea.y + this.absoluteAreaOffsets!.y;
		const rotate = entity.initialFrameArea.rotate + this.absoluteAreaOffsets!.rotate;
		const width = entity.initialFrameArea.width + this.absoluteAreaOffsets!.width;
		const height = entity.initialFrameArea.height + this.absoluteAreaOffsets!.height;

		return {
			height,
			width,
			x,
			y,
			rotate,
		};
	}

	/** Обновить текущее смещение позиции. */
	protected abstract updateOffsets: (offsetX: number, offsetY: number) => void;
	/** Метод, для дополнительной смысловой нагрузки мутатора во время начала мутации. */
	protected abstract postStartEvent: (areas: AnySpatialArea[]) => void;
	/** Метод, для дополнительной смысловой нагрузки мутатора в конце мутации. */
	protected abstract postStopEvent: () => void;
	/** Метод, возвращающий тело объекта события во время мутации. */
	protected abstract getMutationEventBody: () => EventBody;

	protected abstract correctAspectRatioValues: (currentValues: IFrameArea, entity: IMutableEntity) => IFrameArea;
}

export default AreaMutator;
