import { workshopTagAPI } from '../../../../../entities/templates/workshop/tag/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';

export const useCreateWorkshopTemplateTag = () => {
	const [create, { isLoading }] = workshopTagAPI.useCreateMutation();

	const onCreateTag = async (name: string) => {
		try {
			const res = await create({ name })
				.unwrap();
			notificationSuccess('Тэг шаблона успешно создан!');

			return res;
		} catch (err) {
			notificationError('Произошла ошибка при создании тега...');
		}

		return null;
	};

	return {
		isLoading,
		onCreateTag,
	};
};
