import React from 'react';
import css from './choiceBlock.module.scss';
import { IRoleItem } from '../../entities/role/api/types';
import Role from '../../components/pages/personalAccount/personalData/role/Role';

interface RolesListProps {
    roles: IRoleItem[],
    onChoicedRole?: (role: IRoleItem) => void
}

const RolesList: React.FC<RolesListProps> = ({
	roles,
	onChoicedRole,
}: RolesListProps) => {
	RolesList.defaultProps = {
		onChoicedRole: undefined,
	};

	const onRoleClick = (role: IRoleItem) => {
		if (onChoicedRole) {
			onChoicedRole(role);
		}
	};

	return (
		<>
			{
				roles.map(role => {
					const {
						background,
						emoji,
						id,
						name,
						permission,
					} = role;

					return (
						<div
							className={css.roleWrapper}
							onClick={() => onRoleClick(role)}
							role="presentation"
							key={id}
						>
							<Role
								id={id}
								text={name}
								emoji={emoji}
								bgColor={background}
								isCursorPointed
								permission={permission}
								cssClass={css.roleScroll}
							/>
						</div>
					);
				})
			}
		</>
	);
};

export default RolesList;
