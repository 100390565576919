import React from 'react';
import cx from 'classnames';
import css from './main-button.module.scss';

interface MainButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'style'> {
	btnStyle: 'bright' | 'light' | 'transparent',
	styles?: string
}

export const MainButton = ({
	styles,
	type,
	btnStyle,
	children,
	...props
}: MainButtonProps) => (
	<button
		className={cx(css[btnStyle], styles)}
		type={type === 'submit' ? 'submit' : 'button'}
		{...props}
	>
		{children}
	</button>
);
