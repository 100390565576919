import { ACCOUNT_STATUS } from "../entities/user/account/api/types";

export interface checkUsersRightsInterface {
	status: ACCOUNT_STATUS,
	registration: boolean,
	delete: boolean,
	upToAdmin: boolean,
	upToSuperAdmin: boolean,
	upToServiceAdmin: boolean,
}

export const checkUsersRights = (status: ACCOUNT_STATUS) => {
	switch (status) {
	case ACCOUNT_STATUS.SUPER_ADMIN:
		return {
			registration: true,
			delete: true,
			upToAdmin: true,
			upToSuperAdmin: true,
			upToServiceAdmin: false,
			status: status
		} as checkUsersRightsInterface;

	case ACCOUNT_STATUS.ADMIN:
		return {
			registration: true,
			delete: true,
			upToAdmin: true,
			upToSuperAdmin: false,
			upToServiceAdmin: false,
			status: status
		} as checkUsersRightsInterface;

	case ACCOUNT_STATUS.EMPLOYEE:
		return {
			registration: false,
			delete: false,
			upToAdmin: false,
			upToSuperAdmin: false,
			upToServiceAdmin: false,
			status: status
		} as checkUsersRightsInterface;

	case ACCOUNT_STATUS.SERVICE_ADMIN:
		return {
			registration: true,
			delete: true,
			upToAdmin: true,
			upToSuperAdmin: true,
			upToServiceAdmin: true,
			status: status
		} as checkUsersRightsInterface;

	case ACCOUNT_STATUS.INTEGRATOR:
		return {
			registration: true,
			delete: true,
			upToAdmin: true,
			upToSuperAdmin: true,
			upToServiceAdmin: false,
			status: status
		} as checkUsersRightsInterface;

	default:
		return {
			registration: false,
			delete: false,
			upToAdmin: false,
			upToSuperAdmin: false,
			upToServiceAdmin: false,
			status: status
		} as checkUsersRightsInterface;
	}
};
