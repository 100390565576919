import ComponentCloner, { IComponentClonerProps } from '../ComponentCloner';
import IMutablePagesComponentTree from '../../../component-tree/IMutablePagesComponentTree';
import IComponent from '../../../components/IComponent';
import ComponentBuilder from '../../../factories/ComponentBuilder';
import { CopyToOriginalComponents, IdentityToGraphic, LayerSequences } from '../../../Types';
import IClonerClipboard from '../IClonerClipboard';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import IGraphic from '../../../graphic/IGraphic';
import MousePositionObserver from '../../../utils/observers/MousePositionObserver';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import SketchComponentType from '../../../components/SketchComponentType';
import TableComponent from '../../../components/table/TableComponent';

interface IMultiPageComponentClonerProps extends IComponentClonerProps {
	mouseObserver: MousePositionObserver,
	componentTree: IMutablePagesComponentTree,
}

class MultiPageComponentCloner extends ComponentCloner {
	private readonly mouseObserver: MousePositionObserver;
	private readonly componentTree: IMutablePagesComponentTree;

	constructor(props: IMultiPageComponentClonerProps) {
		super(props);
		this.mouseObserver = props.mouseObserver;
		this.componentTree = props.componentTree;
	}

	/**
	 * Получает компоненты в фокусе и записывает в буфер их структуры.
	 */
	public copy = (components: IComponent[]): void => {
		const componentBuilders: ComponentBuilder[] = [];
		const globalLayerSequences = this.componentTree.getLayerSequences();

		// Отражение компонента оригинала (с которого происходит копирование) и нового компонента на его основе.
		const copyToOriginalComponents: CopyToOriginalComponents = new Map();

		// Создание сборщиков компонентов на основе копируемых.
		components.forEach(component => {
			const builder = new ComponentBuilder();
			builder.connectDependencies({
				graphicFactory: this.graphicFactory,
				componentFactory: this.componentFactory,
			});

			builder.injectDependencies();
			builder.scanComponent(component, globalLayerSequences);

			const builderComponents = builder.getComponentAll();
			const tableComponents = builderComponents
				.filter(component => component.type === SketchComponentType.TABLE) as TableComponent[];

			this.fillCopyToOriginalComponents(
				component,
				builder.getComponent(),
				copyToOriginalComponents,
			);
			this.correctComponentPositionInCopy(builder.getComponent(), copyToOriginalComponents);

			if (tableComponents.length !== 0) {
				this.compressTableComponents(...tableComponents);
			}
			this.compressUniterComponents(
				copyToOriginalComponents,
				builder.getComponent(),
				component,
			);

			componentBuilders.push(builder);
		});

		this.saveToBuffer(componentBuilders);
	};

	/**
	 * Вставляет компоненты из буфера на страницу в переданную позицию.
	 */
	public pasteAtPosition = async (
		position: IDescartesPosition,
		firstGraphic: IGraphic,
		clonerClipboard: IClonerClipboard,
	): Promise<void> => {
		const focusPage = this.componentTree.getNearestPage(position);
		const pagePosition = focusPage.getGlobalPosition();
		const graphicConfiguration = firstGraphic.getFrameConfiguration();
		const x = position.x - pagePosition.x - graphicConfiguration.x;
		const y = position.y - pagePosition.y - graphicConfiguration.y;

		const positionOffset: IDescartesPosition = {
			x,
			y,
		};

		await this.pullComponentBuilders(clonerClipboard);

		this.injectComponents(component => this.correctStructuresToPosition(component, positionOffset));
	};

	public pasteWithAlt = (
		components: IComponent[],
	) => {
		const firstGraphic = components[0].getFirstGraphic();
		if (firstGraphic === null) {
			return;
		}

		if (this.componentTree.hasEditableComponent()) {
			return;
		}

		components.forEach(component => {
			const builder = new ComponentBuilder();
			builder.connectDependencies({
				graphicFactory: this.graphicFactory,
				componentFactory: this.componentFactory,
			});
			builder.injectDependencies();
			builder.scanStructure(component.getUniqueStructure());

			this.componentBuilders.push(builder);
		});

		this.injectComponents(component => component);
	};

	public addPostPasteListener = (...listeners: VoidFunction[]) => {
		this.postPasteListeners.push(...listeners);
	};

	/**
	 * Вставляет коллекцию компонентов на самый высокий слой страниц.
	 */
	protected pasteComponents = () => {
		const rootComponent = this.componentTree.getRootComponent();
		const mousePosition = this.mouseObserver.getCurrentPosition();
		const focusPage = this.componentTree.getNearestPage(mousePosition);
		const pageIndex = this.componentTree.getPageNumber(focusPage);
		if (pageIndex === null) {
			throw new ManipulatorError('page index not found', { pageIndex });
		}

		this.componentBuilders.forEach(componentBuilder => {
			componentBuilder.regenerate();
		});

		const components: IComponent[] = [];
		const buildersLayerSequences: LayerSequences[] = [];
		this.componentBuilders.forEach(componentBuilder => {
			const layerSequences = componentBuilder.getLayerSequences();
			components.push(...componentBuilder.getComponentAll());
			buildersLayerSequences.push(layerSequences);
		});

		const componentsGraphics: IGraphic[] = components.map(component => component.getGraphics()).flat();
		const lastPageGraphic = this.componentTree.getLastLayerFromRootGraphicNumber(pageIndex);
		const postPasteLayers = this.getPostPasteLayers(buildersLayerSequences, componentsGraphics, lastPageGraphic);

		// Подключает компоненты к дереву компонентов.
		this.componentTree.executeMutations(tools => {
			const graphics: IGraphic[] = [];

			this.componentBuilders.forEach(componentBuilder => {
				const componentStructure = componentBuilder.getStructure();
				componentStructure.offset = pageIndex;
				const component = tools.mutator.mutateByAppendMultiComponent(rootComponent, componentStructure);

				graphics.push(...[component, ...component.getComponentAll()]
					.map(component => component.getGraphics())
					.flat());

				this.componentTree.syncUniterComponentsSizeFromComponent(component);
			});
			const identityToGraphic: IdentityToGraphic = new Map();
			graphics.forEach(graphic => identityToGraphic.set(graphic.getID(), graphic));

			this.syncLayers(postPasteLayers, tools.mutator, identityToGraphic);
		});

		this.callPostPasteListeners();
		this.componentBuilders.length = 0;
	};
}

export default MultiPageComponentCloner;
