import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { ReactComponent as SmileIcon } from './images/smile.svg';
import { ReactComponent as ExitIcon } from './images/exit.svg';
import { setCollectionSectionTab } from '../../redux/reducers/collectionReducer';
import { LINK_PERSONAL_ACCOUNT, LINK_SECTION_TEMPLATE } from '../../widgets/tabs/collection-tabs/model/tabs-config';
import { useAppDispatch, useAppSelector } from '../../shared/libs/redux';
import { useLogout } from '../../shared/libs/useLogout';

const HeaderContainer = () => {
	const {
		name,
		photo,
		tariff,
	} = useAppSelector(state => state.personalAccount.account);

	const nameArray = name ? name.split(' ') : undefined;
	const renderedName = nameArray && nameArray[0] && nameArray[1]
	&& nameArray[2] ? `${nameArray[0]} ${nameArray[1]?.substring(0, 1)}. ${nameArray[2]?.substring(0, 1)}.` : name;

	const [isShowHiddenBlock, setIsShowHiddenBlock] = useState(false);

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const setSectionTab = (value: number) => dispatch(setCollectionSectionTab(value));

	// * При переходе в профиль
	const onProfile = () => {
		navigate(`/collection/${LINK_PERSONAL_ACCOUNT}`);
		setSectionTab(100);
		setIsShowHiddenBlock(false);
	};

	// * При клике на лого
	const onMainPage = () => navigate(`/collection/${LINK_SECTION_TEMPLATE}*`);

	// * При закрытии вспылвающего окна
	const onCloseHiddenBlock = () => setIsShowHiddenBlock(false);

	const { onLogOut } = useLogout();

	const ActionsWithCardsItems = [
		{
			text: 'Личные данные',
			smile: <SmileIcon />,
			onClick: onProfile,
		},
		{
			text: 'Выйти из аккаунта',
			smile: <ExitIcon />,
			onClick: onLogOut,
		},
	];

	return (
		<Header
			photo={photo}
			renderedName={renderedName}
			isShowHiddenBlock={isShowHiddenBlock}
			actionsWithCardsItems={ActionsWithCardsItems}
			onMainPage={onMainPage}
			onCloseHiddenBlock={onCloseHiddenBlock}
			setIsShowHiddenBlock={setIsShowHiddenBlock}
			tariff={tariff}
		/>
	);
};

export default HeaderContainer;
