import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import {
	IBindWorkshopCategoryRequest,
	ICreateWorkshopCategoryResponse,
	IGetWorkshopCategoriesRequest,
	IGetWorkshopCategoriesResponse, IUpdateModuleCategoryRequest,
} from '../model/types/types';

export const URI_WORKSHOP_CATEGORY = '/workshop/module/category';

export const workshopCategoryApi = createApi({
	reducerPath: 'workshopCategoryApi',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['workshopCategory'],
	endpoints: builder => ({
		getWorkshopCategories: builder.query<IGetWorkshopCategoriesResponse, IGetWorkshopCategoriesRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_CATEGORY,
				method: 'GET',
				params: data,
			}),
			providesTags: ['workshopCategory'],
		}),
		create: builder.mutation<ICreateWorkshopCategoryResponse, { name: string }>({
			query: (data) => ({
				url: URI_WORKSHOP_CATEGORY,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['workshopCategory'],
		}),
		update: builder.mutation<void, IUpdateModuleCategoryRequest>({
			query: (data) => ({
				url: URI_WORKSHOP_CATEGORY,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['workshopCategory'],
		}),
		delete: builder.mutation<void, { categoryId: number }>({
			query: ({ categoryId }) => ({
				url: `${URI_WORKSHOP_CATEGORY}/${categoryId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['workshopCategory'],
		}),
		bind: builder.mutation<void, IBindWorkshopCategoryRequest>({
			query: ({
				moduleId,
				categories,
			}) => ({
				url: `/workshop/module/${moduleId}/category`,
				method: 'POST',
				body: {
					categories,
				},
			}),
			invalidatesTags: ['workshopCategory'],
		}),
		unbind: builder.mutation<void, {moduleId: string, categories: number[]}>({
			query: ({
				moduleId,
				categories,
			}) => ({
				url: `/workshop/module/${moduleId}/category`,
				method: 'DELETE',
				body: {
					categories,
				},
			}),
			invalidatesTags: ['workshopCategory'],
		}),
	}),
});
