import React from 'react';
import cx from 'classnames';
import css from './inputArrowItem.module.scss';

interface InputArrowItemProps {
	name: string,
	cssWrapperClass?: string,
	cssTextClass?: string,
	id: string,
	isChoiced: boolean,
	onAddChoicedItem: (id: string) => void,
	onRemoveChoicedItem: (id: string) => void,
}

const InputArrowItem = ({
	name,
	id,
	isChoiced,
	cssWrapperClass,
	cssTextClass,
	onAddChoicedItem,
	onRemoveChoicedItem,
}: InputArrowItemProps) => {
	const onCheckBoxClick = () => {
		if (isChoiced) {
			onRemoveChoicedItem(id);
		} else {
			onAddChoicedItem(id);
		}
	};

	return (
		<div role="presentation" className={cx(css.wrapper, cssWrapperClass)} onClick={onCheckBoxClick}>
			<div
				className={css.checkbox}
				role="presentation"
			>
				{
					isChoiced && (
						<svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M11.3337 1L4.00033 8.33333L0.666992 5"
								stroke="#0075FF"
								strokeWidth="1.33333"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					)
				}
			</div>
			<div className={cx(css.text, cssTextClass)}>{name}</div>
		</div>
	);
};

export default InputArrowItem;
