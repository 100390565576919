import React from 'react';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from './images/close.svg';
import { Hint } from '../index';
import { useHint } from '../../libs/useHint';
import css from './editable-tag.module.scss';
import { ITagData } from '../../../entities/modules/tag/api/types';

type TagState = 'active' | 'disabled';

interface EditableTagProps {
	tag: ITagData,
	state: TagState,
	closeAble: boolean,
	onClick?: (id: number) => void,
	onClose?: (id: number) => void,
	styles?: string,
	hint?: string,
}

export const EditableTag = ({
	tag,
	hint,
	state,
	styles,
	onClose,
	onClick,
	closeAble,
}: EditableTagProps) => {
	const {
		id,
		name,
	} = tag;

	const onTagClick = () => onClick && onClick(id);
	const onTagClose = () => onClose && onClose(id);

	const {
		isHintVisible,
		onMouseEnter,
		onMouseLeave,
	} = useHint();

	return (
		<div>
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				role="presentation"
				className={cx(css.wrapper, styles, css[state])}
				onClick={onTagClick}
			>
				{name}
				{
					closeAble && (
						<div role="presentation" className={css.close_icon} onClick={onTagClose}>
							<CloseIcon />
						</div>
					)
				}
			</div>
			<div className={css.hint_wrapper}>
				{hint && <Hint show={isHintVisible} text={hint} />}
			</div>
		</div>
	);
};
