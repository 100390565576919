import React, { useState } from 'react';
import { IAccount } from '../../../../../entities/user/account/api/types';
import { UserStatusBar } from '../../../../../features/user/user-status';
import css from './user-card.module.scss';
import { copyToClipboard } from '../../../../../helpers/copyText';
import { UserCardRoles } from './roles/roles';
import { UserCardMenu } from './menu/menu';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { useChangeStatusAbility } from '../../libs/useChangeStatusAbility';

interface UserCardProps {
	user: IAccount,
	statusRights: checkUsersRightsInterface,
}

export const UserCard = ({
	user,
	statusRights,
}: UserCardProps) => {
	const {
		email,
		status,
		name,
		phone,
		photo,
		roles,
	} = user;

	const [isMenuVisible, setIsMenuVisible] = useState(false);

	const isOpenable = useChangeStatusAbility(status, email);

	const handleMenu = (value: boolean) => {
		if (isOpenable) setIsMenuVisible(value);
	};

	const onCopyEmail = () => copyToClipboard(email);

	return (
		<div className={css.wrapper}>
			<div className={css.status_bar}>
				<UserStatusBar userStatus={status} email={email} openable={isOpenable} />
				<UserCardMenu
					email={email}
					statusRights={statusRights}
					setActive={handleMenu}
					isActive={isMenuVisible}
				/>
			</div>
			<div className={css.main}>
				<div className={css.name_info}>
					{photo.length && <img src={`/api/file/user/${photo}`} alt="user" />}
					<div>{name}</div>
				</div>
				<div className={css.side_info}>
					<div className={css.phone}>{phone}</div>
					<div role="presentation" className={css.email} onClick={onCopyEmail}>{email}</div>
				</div>
			</div>
			<UserCardRoles statusRights={statusRights} email={email} roles={roles} />
		</div>
	);
};
