import React from 'react';
import cx from 'classnames';
import { ReactComponent as RightSmall } from './images/small-right-swipe-arrow.svg';
import { ReactComponent as RightBig } from './images/big-right-swipe-arrow.svg';
import { ReactComponent as LeftSmall } from './images/small-left-swipe-arrow.svg';
import { ReactComponent as LeftBig } from './images/big-left-swipe-arrow.svg';
import css from './swipe-button.module.scss';
import { TSwipeIconSize, TSwipeIconType } from '../../model/types';

interface SwipeButtonProps {
	type: TSwipeIconType;
	size: TSwipeIconSize;
	onClick: () => void,
}

type IconProps = Omit<SwipeButtonProps, 'onClick'>

const Icon = ({
	type,
	size,
}: IconProps) => {
	if (type === 'right' && size === 'small') return <RightSmall />;
	if (type === 'right' && size === 'big') return <RightBig />;
	if (type === 'left' && size === 'small') return <LeftSmall />;
	return <LeftBig />;
};

export const SwipeButton = ({
	type,
	size,
	onClick,
}: SwipeButtonProps) => (
	<button type="button" className={cx(css.wrapper, css[size])} onClick={onClick}>
		<Icon size={size} type={type} />
	</button>
);
