import ActionTool from '../action-panel/ActionTool';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';

class AITool extends ActionTool {
	private readonly HINT_TEXT = 'Искуственный интеллект';

	constructor() {
		super('panel-ai__icon');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.AI_BTN);
	}
}

export default AITool;
