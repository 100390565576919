import React, { useEffect } from 'react';
import { useAppDispatch } from '../../shared/libs/redux';
import { connect } from './model/slice';

const WebSocketConnector: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(connect());
	}, [dispatch]);

	return null; // Этот компонент не рендерит ничего
};

export default WebSocketConnector;
