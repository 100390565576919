import { IFilterItem } from '../../../../../widgets/filter/model/types';

type TAdminUsersFilterData = undefined | 'active' | 'disabled';

export interface IAdminUsersFilterItem extends IFilterItem {
	id: TAdminUsersFilterData,
}

export const adminUsersFilterData: IAdminUsersFilterItem[] = [
	{
		id: undefined,
		name: 'Все',
	},
	{
		id: 'active',
		name: 'Активные',
	},
	{
		id: 'disabled',
		name: 'Заблокированные',
	},
];
