import React from 'react';
import css from './sign.module.scss';

interface SignProps {
    type: 'text'
        | 'email'
        | 'tel'
        | 'number'
        | 'birthday'
        | 'hidePassword'
        | 'visiblePassword'
        | 'name'
        | 'organization'
        | 'role',
    status?: 'success' | 'error' | 'clear',
    signClick?: () => void,
    setRoleBgColor?: (color: string, e: React.ChangeEvent<HTMLInputElement>) => void,
    // setRoleBgColor?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeColorEnter?: () => void,
    onChangeColorLeave?: () => void
}

const Sign: React.FC<SignProps> = ({
	type,
	status,
	signClick,
	setRoleBgColor,
	onChangeColorEnter,
	onChangeColorLeave,
}: SignProps) => {
	Sign.defaultProps = {
		status: undefined,
		signClick: undefined,
		setRoleBgColor: undefined,
		onChangeColorEnter: undefined,
		onChangeColorLeave: undefined,
	};
	const isNeedSign = type === 'email'
        || type === 'tel'
        || type === 'number'
        || type === 'text'
        || type === 'organization';

	return (
		<div>
			{
				isNeedSign && status === 'error'
					? (
						<div className={css.sign}>
							<svg
								width="3"
								height="15"
								viewBox="0 0 3 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.375 0.909091L2.24716 10.3182H0.764205L0.636364 0.909091H2.375ZM1.50568
								14.1023C1.19034 14.1023 0.919744 13.9893 0.693892 13.7635C0.46804 13.5376 0.355114
								13.267 0.355114 12.9517C0.355114 12.6364 0.46804 12.3658 0.693892 12.1399C0.919744
								11.9141 1.19034 11.8011 1.50568 11.8011C1.82102 11.8011 2.09162 11.9141 2.31747
								12.1399C2.54332 12.3658 2.65625 12.6364 2.65625 12.9517C2.65625 13.1605 2.60298
								13.3523 2.49645 13.527C2.39418 13.7017 2.25568 13.8423 2.08097 13.9489C1.91051 14.0511
								1.71875 14.1023 1.50568 14.1023Z"
									fill="#FF3636"
								/>
							</svg>
						</div>
					)
					: <div />
			}

			{
				isNeedSign && status === 'success'
					? (
						<div className={css.sign}>
							<svg
								width="16"
								height="12"
								viewBox="0 0 16 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M15 1.53786L5.35321 11L1 6.73011L1.56247
								6.19225L5.35321 9.91044L14.4375 1L15 1.53786Z"
									fill="#1E7049"
									stroke="#63AA58"
								/>
							</svg>
						</div>
					)
					: <div />
			}

			{
				(type === 'hidePassword' || type === 'visiblePassword') && (
					<div
						className={`${css.sign} ${css.password}`}
						onClick={signClick}
						role="presentation"
					>
						{
							type === 'hidePassword'
								? (
									<svg
										width="22"
										height="20"
										viewBox="0 0 22 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M9.72262 3.09232C10.1355 3.03223 10.5614 3 11 3C16.1858 3 19.5887
											7.50484 20.7319 9.28682C20.8703 9.5025 20.9395 9.61034 20.9782
											9.77667C21.0073 9.90159 21.0073 10.0987 20.9782 10.2236C20.9394 10.3899
											20.8697 10.4985 20.7304 10.7156C20.4258 11.1901 19.9614 11.8571 19.3461
											12.5805M5.64038 4.71504C3.44409 6.1817 1.95305 8.21938 1.26905
											9.28528C1.13006 9.50187 1.06057 9.61016 1.02183 9.77648C0.992734 9.9014
											0.992722 10.0984 1.0218 10.2234C1.06052 10.3897 1.1297 10.4975 1.26806
											10.7132C2.41128 12.4952 5.81418 17 11 17C13.091 17 14.8921 16.2676 16.3721
											15.2766M1.85753 1L20.1425 19M8.8451 7.87868C8.29361 8.42157 7.95251 9.17157
											7.95251 10C7.95251 11.6569 9.31692 13 11 13C11.8415 13 12.6034 12.6642
											13.1549 12.1213"
											stroke="#BABABA"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)
								: (
									<svg
										width="22"
										height="16"
										viewBox="0 0 22 16"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1.26805 8.71318C1.12971 8.49754 1.06054 8.38972 1.02181
											8.22342C0.992729 8.0985 0.992729 7.9015 1.02181 7.77658C1.06054 7.61028
											1.12971 7.50246 1.26805 7.28682C2.41127 5.50484 5.81418 1 11 1C16.1858 1
											19.5887 5.50484 20.7319 7.28682C20.8703 7.50246 20.9395 7.61028 20.9782
											7.77658C21.0073 7.9015 21.0073 8.0985 20.9782 8.22342C20.9395 8.38972
											20.8703 8.49754 20.7319 8.71318C19.5887 10.4952 16.1858 15 11 15C5.81418
											15 2.41127 10.4952 1.26805 8.71318Z"
											stroke="#BABABA"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11 11C12.6831 11 14.0475 9.65685 14.0475 8C14.0475 6.34315
											12.6831 5 11 5C9.31692 5 7.95251 6.34315 7.95251 8C7.95251 9.65685 9.31692
											11 11 11Z"
											stroke="#BABABA"
											strokeWidth="2"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)

						}
					</div>
				)
			}
			{
				type === 'birthday' && (
					<div
						className={`${css.sign} ${css.calendar}`}
						onClick={signClick}
						role="presentation"
					>
						<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M16 8.33329H1M11.8333 1.66663V4.99996M5.16667 1.66663V4.99996M5 18.3333H12C13.4001
							18.3333 14.1002 18.3333 14.635 18.0608C15.1054 17.8211 15.4878 17.4387 15.7275 16.9683C16
							 16.4335 16 15.7334 16 14.3333V7.33329C16 5.93316 16 5.2331 15.7275 4.69832C15.4878 4.22791
							 15.1054 3.84546 14.635 3.60578C14.1002 3.33329 13.4001 3.33329 12 3.33329H5C3.59987
							 3.33329 2.8998 3.33329 2.36502 3.60578C1.89462 3.84546 1.51217 4.22791 1.27248 4.69832C1
							 5.2331 1 5.93316 1 7.33329V14.3333C1 15.7334 1 16.4335 1.27248 16.9683C1.51217 17.4387
							 1.89462 17.8211 2.36502 18.0608C2.8998 18.3333 3.59987 18.3333 5 18.3333Z"
								stroke="#939393"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>

					</div>
				)
			}
			{
				type === 'role' && (
					// <input
					// 	type="color"
					// 	className={`${css.colorInput} ${css.calendar}`}
					// 	onClick={signClick}
					// 	onChange={setRoleBgColor}
					// 	onMouseEnter={onChangeColorEnter}
					// 	onMouseLeave={onChangeColorLeave}
					// />
					// <SketchPicker
					// 	onChange={setRoleBgColor}
					// />
					<>rigv</>
				)
			}
		</div>
	);
};

export default Sign;
