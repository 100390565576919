import HTMLElementName from '../../utils/HTMLElementName';
import ElementContainer from '../../utils/ElementContainer';

/**
 * Поле для ввода числовых значений.
 */
class NumberInput extends ElementContainer<HTMLInputElement> {
	private minValue: number;
	private maxValue: number;

	private readonly validValueListeners: VoidFunction[];
	private readonly invalidValueListeners: VoidFunction[];
	private readonly changeValueListeners: ((value: number) => void)[];

	constructor() {
		super(HTMLElementName.INPUT);
		this.minValue = 0;
		this.maxValue = 0;
		this.validValueListeners = [];
		this.changeValueListeners = [];
		this.invalidValueListeners = [];
		this.rootElement.type = 'number';
		this.rootElement.addEventListener('input', this.onInput);
	}

	/**
	 * Устанавливает значение в поле ввода без запуска побочных действий.
	 * @param value Новое значение.
	 */
	public setValue = (value: number) => {
		const isCurrentValueValid = this.validateValue(value);
		if (!isCurrentValueValid) {
			return;
		}
		this.rootElement.value = value.toString();
	};

	/**
	 * Добавляет CSS класс в элемент ввода числа.
	 * @param className Имя класса.
	 */
	public appendClassName = (className: string) => {
		this.rootElement.classList.add(className);
	};

	public setMinValue = (value: number) => { this.minValue = value; };
	public setMaxValue = (value: number) => { this.maxValue = value; };

	/**
	 * Добавляет слушателя изменения значения поля пользователем.
	 * @param listener Слушатель события.
	 */
	public addChangeValueListener = (listener: (value: number) => void) => {
		this.changeValueListeners.push(listener);
	};

	/**
	 * Добавляет слушателя не корректного ввода значения пользователем.
	 * @param listener Слушатель события.
	 */
	public addInvalidValueListener = (listener: VoidFunction) => {
		this.invalidValueListeners.push(listener);
	};

	/**
	 * Добавляет слушателя корректного ввода значения пользователем.
	 * @param listener Слушатель события.
	 */
	public addValidValueListener = (listener: VoidFunction) => {
		this.validValueListeners.push(listener);
	};

	/**
	 * Обрабатывает событие ввода значений пользователей.
	 */
	private onInput = () => {
		const currentValue = Number(this.rootElement.value);
		const isCurrentValueValid = this.validateValue(currentValue);
		if (isCurrentValueValid) {
			this.validValueListeners.forEach(listener => listener());
			this.changeValueListeners.forEach(listener => listener(currentValue));
			return;
		}

		this.invalidValueListeners.forEach(listener => listener());
	};

	/**
	 * Проверяет значение на корректность. Новое значение должно быть:
	 * - числом;
	 * - не меньше минимального разрешенного значения;
	 * - не больше максимального разрешенного значения.
	 * @param value Значение на проверку.
	 */
	private validateValue = (value: number): boolean => !(!Number.isInteger(value)
		|| value < this.minValue
		|| value > this.maxValue);
}

export default NumberInput;
