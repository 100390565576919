import React from 'react';
import css from './user-card.module.scss';

interface UserCardProps {
	name: string,
	photo: string,
}

const UserCard = ({
	name,
	photo,
}: UserCardProps) => (
	<div
		role="presentation"
		className={css.userCard}
	>
		<img
			src={`/api/file/user/${photo}`}
			className={css.picture}
			alt="user"
		/>
		<div className={css.userCardText}>{name}</div>
	</div>
);

export default UserCard;
