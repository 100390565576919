import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { copyBuffer, domainName } from '../../../../../../../../../helper';
import { useAppDispatch } from '../../../../../../../../../shared/libs/redux';
import { notificationSuccess } from '../../../../../../../../Notifications/callNotifcation';
import {
	useCleanTemplateSelection,
} from '../../../../../../../../../hooks/template-selection/useCleanTemplateSelection';
import {
	setIsChoicePlaceToMoveModalActive,
	setIsConfirmPrivateDeleteModalActive,
	setPrivateTemplateSelectedList,
} from '../../../../../../../../../entities/templates/private/model/slice';

export const usePrivateContextMenuActions = (id: string) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [isActionMenuOpened, setIsActionMenuOpened] = useState(false);

	const { cleanTemplateSelection } = useCleanTemplateSelection();

	const closeActionMenu = () => setIsActionMenuOpened(false);

	// * Скопировать ссылку на превью шаблона
	const copyLink = () => copyBuffer(`${domainName}presentation/template/${id}`);

	const onCopyLink = () => {
		copyLink();
		closeActionMenu();
		dispatch(setPrivateTemplateSelectedList([]));
		notificationSuccess('Ссылка скопирована в буфер обмена!');
	};

	// * Создать из шаблона предложение
	const onSendOffer = () => navigate(`/private-template-present/${id}`);

	// * Удаление шаблона
	const onDelete = () => {
		dispatch(setIsConfirmPrivateDeleteModalActive(true));
		closeActionMenu();
	};

	const handleMenu = (value: boolean) => {
		if (value) {
			dispatch(setPrivateTemplateSelectedList([id]));
		} else {
			dispatch(setPrivateTemplateSelectedList([]));
			cleanTemplateSelection();
		}
		setIsActionMenuOpened(value);
	};

	const onMoveTemplate = () => {
		dispatch(setIsChoicePlaceToMoveModalActive(true));
		closeActionMenu();
	};

	return {
		isActionMenuOpened,
		handleMenu,
		onCopyLink,
		onDelete,
		onSendOffer,
		onMoveTemplate,
	};
};
