import cx from 'classnames';
import { ITariff } from '../../../../../../../entities/tariff/api/types';
import css from './status.module.scss';

interface UserCardAdminStatusProps {
	tariff: ITariff | null;
}

export const UserCardAdminStatus = ({ tariff }: UserCardAdminStatusProps) => {
	const isActive = tariff;

	return (
		<div className={cx(css.wrapper, isActive ? css.active : css.disabled)}>
			{isActive ? 'Активен' : 'Заблокирован'}
		</div>
	);
};
