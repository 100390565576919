import SearchInput from '../../../../../ui/searchInput/SearchInput';
import { adminUsersFilterData, IAdminUsersFilterItem } from '../../models/filter-data';
import { Filter } from '../../../../../../widgets/filter';
import css from './search-bar.module.scss';
import { IFilterItem } from '../../../../../../widgets/filter/model/types';

interface AdminUsersSearchBarProps {
	search: string,
	setSearch: (search: string) => void,
	filter: IAdminUsersFilterItem,
	setFilter: (filter: IFilterItem) => void
}

export const AdminUsersSearchBar = ({
	filter,
	search,
	setSearch,
	setFilter,
}: AdminUsersSearchBarProps) => (
	<div className={css.wrapper}>
		<Filter
			title="Фильтры"
			onSelect={setFilter}
			selectedItem={filter}
			data={adminUsersFilterData}
		/>
		<SearchInput
			placeholder="Поиск по пользователям"
			searchValue={search}
			onChange={setSearch}
			styles={css.search}
		/>
	</div>
);
