import React from 'react';
import { onSetSketchStructure } from '../../../../../../redux/thunk-creators/sketch';
import ISketchStructure from '../../../../../Sketch/ISketchStructure';
import ComponentOfferConstructor from '../../../../../SketchManipulators/OfferConstructor/ComponentOfferConstructor';
import css from './offer_settings.module.scss';
import ComponentOfferPressetingContainer from './ComponentOfferPresettingContainer';
import Header from './Header';
import { SendOfferButton } from '../../../../../../features/offer/send';

interface OfferSettingsProps {
	children: React.ReactNode,
	title: string,
	isChangeTemplateOpen: boolean,
	sketchStructure: ISketchStructure | undefined,
	onSendTemplate: () => void,
	onOpenChangeTemplate: () => void,
	setTitle: (title: string) => void,
	onGoBack: () => void,
	onCloseChangeTemplate: () => void,
}

const OfferSettings = ({
	children,
	onGoBack,
	setTitle,
	onCloseChangeTemplate,
	title,
	sketchStructure,
	isChangeTemplateOpen,
	onOpenChangeTemplate,
	onSendTemplate,
}: OfferSettingsProps) => {
	// const isSendBtnActive = choicedLegalEntity !== undefined
	// 	&& choicedManager !== undefined
	// 	&& deadLine !== ''
	// 	&& title.length > 0
	// 	&& title.length <= 60;

	const isSendBtnActive = title.length > 0
		&& title.length <= 60;

	// * При изменении структуры скетча
	const setStructure = (structure: ISketchStructure) => {
		onSetSketchStructure(structure);
	};

	return (
		<div className={css.wrapper}>
			<Header
				title={title}
				setTitle={setTitle}
				onGoBack={onGoBack}
				isChangeTemplateOpen={isChangeTemplateOpen}
				onCloseChangeTemplate={onCloseChangeTemplate}
			/>
			{
				isChangeTemplateOpen && sketchStructure
					? (
						<ComponentOfferConstructor
							structure={sketchStructure}
							unmountCallback={setStructure}
						/>
					)
					: (
						<>
							<div className={css.content}>
								<div className={css.main}>
									<div className={css.category}>
										{children}
										<div className={css.btnBlock}>
											{/* <div */}
											{/* 	className={css.text} */}
											{/* 	onClick={onOpenChangeTemplate} */}
											{/* 	role="presentation" */}
											{/* > */}
											{/* 	Изменить */}
											{/* </div> */}
											<SendOfferButton structure={sketchStructure} name={title} />
											{/* <MainButton */}
											{/* 	onClick={onSendTemplate} */}
											{/* 	disabled={!isSendBtnActive} */}
											{/* 	btnStyle="bright" */}
											{/* > */}
											{/* 	Перейти к отправке */}
											{/* </MainButton> */}
										</div>
									</div>
								</div>
							</div>
							{
								sketchStructure && <ComponentOfferPressetingContainer structure={sketchStructure} />
							}
						</>
					)
			}
		</div>
	);
};

export default OfferSettings;
