import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
    searchBoxValue: string
    sectionTab?: number
}

const initialState: IInitialState = {
	searchBoxValue: '',
	sectionTab: undefined,
};

export const collectionSlice = createSlice({
	name: 'collection',
	initialState,
	reducers: {
		setCollectionSearchBoxValue: (state, action: PayloadAction<string>) => {
			state.searchBoxValue = action.payload;
		},
		setCollectionSectionTab: (state, action: PayloadAction<number>) => {
			state.sectionTab = action.payload;
		},
	},
});

export const {
	setCollectionSearchBoxValue,
	setCollectionSectionTab,
} = collectionSlice.actions;

export default collectionSlice.reducer;
