// Функция для получения названия месяца на основе его номера
const getMonthName = (month: number) => {
	const monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
		'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
	return monthNames[month];
};

// Функция для форматирования даты и времени в формате "С дд месяц чч:мм гггг"
export const setTransactionHistoryDate = (date: string) => {
	const originalDate = new Date(date);

	const day = originalDate.getDate();
	const month = getMonthName(originalDate.getMonth());
	const year = originalDate.getFullYear();

	const hours = originalDate.getHours().toString().padStart(2, '0');
	const minutes = originalDate.getMinutes().toString().padStart(2, '0');

	return `С ${day} ${month} ${hours}:${minutes} ${year}`;
};
