import ManipulatorArea, { IManipulatorAreaDependencies } from '../../mechanics/ManipulatorArea';

interface ITextInjectAreaDependencies extends IManipulatorAreaDependencies {}

/**
 * Область, определяющая размеры создаваемого текста.
 */
class TextInjectArea extends ManipulatorArea<ITextInjectAreaDependencies> {
	private readonly AREA_CLASS_NAME = 'pages-container__text-area';

	constructor() {
		super();
		this.setAreaClassName(this.AREA_CLASS_NAME);
	}
}

export default TextInjectArea;
