import APIGroup from '../APIGroup';
import setHeaders from '../generalFunc';
import { notificationError } from '../../components/Notifications/callNotifcation';
import { MB } from '../../shared/types/convertBytes';

interface IAPIFileCreateRequest {
	name: string
	bytes: string
}

interface IAPIFileCreateResponse {
	id: string
}

class File extends APIGroup {
	private readonly URI_FILE = 'file/user';

	public create = async (
		params: IAPIFileCreateRequest,
		fileSize: number,
	): Promise<IAPIFileCreateResponse> => {
		if (fileSize > MB(20)) {
			notificationError('Сохранения файла', 'Загрузка файлов более 4MB запрещена');
		}
		const response = await this.axios(this.URI_FILE, {
			method: 'post',
			headers: setHeaders(true),
			data: params,
		});
		return response.data;
	};
}

export default File;
