import React, { useState } from 'react';
import { SERVER_ENVIRONMENT_TEST } from '../api/API';

const TestServerNotification = () => {
	const [isShow, setShow] = useState(true);
	return process.env.REACT_APP_ENVIRONMENT_SERVER === SERVER_ENVIRONMENT_TEST && isShow
		? (
			// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
			<div className="test-server-notification" onClick={() => setShow(false)}>
				<h1>Внимание: Вы находитесь на тестовой версии сайта!</h1>
				<p>
					Пожалуйста, обратите внимание, что данный сайт является тестовой версией и
					не предназначен для использования в производственных целях.
					Все данные, включая загруженные файлы, сообщения и настройки, могут быть утеряны
					в любой момент без предупреждения.
					Мы рекомендуем вам не вводить или загружать конфиденциальную или важную информацию на этом сайте.
					Используйте его исключительно для ознакомительных и тестовых целей.
				</p>
				<p>
					Приносим извинения за любые неудобства, которые могут возникнуть в процессе использования.
					Мы делаем все возможное, чтобы обеспечить стабильность и надежность, но не можем
					гарантировать сохранность данных.
				</p>
				<p>Спасибо за понимание!</p>
			</div>
		) : null;
};

export default TestServerNotification;
