import { useCallback } from 'react';
import { employeeAPI } from '../../../../entities/employee/api/api';
import { ERROR_MESSAGE_LIST, notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { teamAPI } from '../../../../entities/team/api/api';
import { setIsUsersCreateModalActive } from '../../../../redux/reducers/usersReducer';
import { cleanInputValues, useInput } from '../../../../hooks/useInput';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { checkUsersRightsInterface } from '../../../../helpers/usersRights';

export const useCreateUser = (statusRights: checkUsersRightsInterface) => {
	const dispatch = useAppDispatch();

	const createUserName = useInput('', {
		isEmpty: true,
		minLength: 3,
		maxLength: 255,
	});
	const createUserEmail = useInput('', {
		isEmpty: true,
		minLength: 5,
		isEmail: true,
		maxLength: 255,
	});
	const createUserPhone = useInput('', {
		isEmpty: true,
		minLength: 15,
		isPhone: true,
		maxLength: 16,
	});

	const [create, { isLoading }] = employeeAPI.useCreateMutation();
	const onCreateUser = useCallback(async () => {
		if (!statusRights.registration) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			try {
				await create({
					email: createUserEmail.value,
					name: createUserName.value,
					phone: createUserPhone.value.split(' ')
						.join(''),
				})
					.unwrap();
				notificationSuccess('Новый пользователь успешно добавлен!');

				dispatch(teamAPI.util?.invalidateTags(['team']));
				dispatch(employeeAPI.util?.invalidateTags(['employee']));

				dispatch(setIsUsersCreateModalActive(false));
				cleanInputValues(
					[createUserName, createUserPhone, createUserEmail],
				);
			} catch (e) {
				notificationError('Призошла ошибка при создании пользователя...');
			}
		}
	}, [createUserName, createUserPhone, createUserEmail, statusRights.registration]);

	return {
		onCreateUser,
		isLoading,
		createUserName,
		createUserPhone,
		createUserEmail,
	};
};
