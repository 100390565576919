import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';
import ToolBarTool from '../ToolBarTool';

class ModulesTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Модули';

	constructor() {
		super('panel-modules__modules');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.modulesTool);
	}
}

export default ModulesTool;
