import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { ICategoryData } from '../../../../entities/modules/category/api/types';
import { workshopCategoryApi } from '../../../../entities/workshop-category';

export const useUpdateCategories = () => {
	const [update, { isLoading }] = workshopCategoryApi.useUpdateMutation();

	const onUpdateCategories = async (categories: ICategoryData[]) => {
		try {
			const moduleCategories: ICategoryData[] = categories.map(category => ({
				...category,
				sequenceNumber: categories.indexOf(category),
			}));
			await update({ moduleCategories });
		} catch (err) {
			notificationError('Произошла ошибка при изменении порядка категорий...');
		}
	};

	return {
		onUpdateCategories,
		isLoading,
	};
};
