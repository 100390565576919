import React, { useEffect } from 'react';
import TransitionActionsWithCards, {
	IActionsWithCardsItem,
} from '../../../../../../ui/actionsWithCards/TransitionActionsWithCards';
import css from './context-menu.module.scss';
import { ReactComponent as TrashIcon } from '../../../../../../../shared/images/red-trash.svg';
import { ReactComponent as CopyLinkIcon } from '../../../../../../../shared/images/copy-link.svg';
import { ReactComponent as EditIcon } from '../../../../../../../shared/images/edit-icon.svg';
import {
	ReactComponent as DuplicateIcon,
} from '../../../../../../../shared/images/collection/template/duplicate.svg';
import { useDuplicateWorkshopTemplates } from '../api/useDuplicateWorkshopTemplates';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import { renderCountWord } from '../../../../../../../helpers/renderCountWord';

interface WorkshopTemplateContextMenuProps {
	onDelete: () => void,
	onCopyLink: () => void,
	isActionMenuOpened: boolean,
	menuPosition: { x: number; y: number },
	menuRef: React.RefObject<HTMLDivElement>,
	setIsActionMenuOpened: (value: boolean) => void,
	onContextMenuRename: () => void,
}

let initialSelectedLength: undefined | number;

export const WorkshopTemplateContextMenu = ({
	menuRef,
	onDelete,
	menuPosition,
	onCopyLink,
	isActionMenuOpened,
	setIsActionMenuOpened,
	onContextMenuRename,
}: WorkshopTemplateContextMenuProps) => {
	const selectedTemplates = useAppSelector(state => state.workshopTemplate.workshopTemplateSelectedList);
	const onCloseMenu = () => setIsActionMenuOpened(false);

	const { duplicateTemplates } = useDuplicateWorkshopTemplates();

	const menuActionWrapper = (action: () => void) => {
		onCloseMenu();
		action();
	};

	const onDuplicate = () => menuActionWrapper(() => duplicateTemplates());

	useEffect(() => {
		if (selectedTemplates.length) {
			initialSelectedLength = selectedTemplates.length;
		}
	}, [selectedTemplates]);

	const templateCount = renderCountWord(selectedTemplates.length || 0, ['шаблон', 'шаблонов', 'шаблона']);
	const duplicateText = `Дублировать ${selectedTemplates.length > 1 ? templateCount : ''}`;

	const actionsWithCardsItems: IActionsWithCardsItem[] = [
		{
			text: duplicateText,
			smile: <DuplicateIcon />,
			onClick: onDuplicate,
		},
		{
			text: 'Удалить',
			smile: <TrashIcon />,
			onClick: onDelete,
			cssClassSmile: css.deleteOfferIcon,
			cssClassText: css.deleteOfferText,
		},
	];

	if (initialSelectedLength === 1) {
		actionsWithCardsItems.unshift(
			{
				text: 'Скопировать ссылку',
				smile: <CopyLinkIcon />,
				onClick: onCopyLink,
			},
			{
				text: 'Переименовать',
				smile: <EditIcon />,
				onClick: onContextMenuRename,
			},
		);
	}

	return (
		<div
			ref={menuRef}
			style={{
				position: 'absolute',
				left: menuPosition.x,
				top: menuPosition.y,
				display: 'inline-block',
				zIndex: 100,
			}}
		>
			<TransitionActionsWithCards
				ref={menuRef}
				onClose={onCloseMenu}
				isActive={isActionMenuOpened}
				cssClassMain={css.cards_actions}
				items={actionsWithCardsItems}
			/>
		</div>
	);
};
