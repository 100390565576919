import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITeamTemplateState } from './types';
import { ITeamTemplateData } from '../api/types';

const initialState: ITeamTemplateState = {
	search: '',
	teamTemplates: undefined,
	selectedTeamTemplate: {} as ITeamTemplateData,

	isConfirmTeamMoveModalActive: false,
	isConfirmTeamDeleteModalActive: false,
};

export const teamTemplate = createSlice({
	name: 'teamTemplate',
	initialState,
	reducers: {
		setTeamTemplateSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setTeamTemplates: (state, action: PayloadAction<ITeamTemplateData[]>) => {
			state.teamTemplates = action.payload;
		},

		setSelectedTeamTemplate: (state, action: PayloadAction<ITeamTemplateData>) => {
			state.selectedTeamTemplate = action.payload;
		},

		setIsConfirmTeamDeleteModalActive: (state, action: PayloadAction<boolean>) => {
			state.isConfirmTeamDeleteModalActive = action.payload;
		},
		setIsConfirmTeamMoveModalActive: (state, action: PayloadAction<boolean>) => {
			state.isConfirmTeamMoveModalActive = action.payload;
		},
	},
});

export const {
	setTeamTemplates,
	setTeamTemplateSearch,

	setSelectedTeamTemplate,

	setIsConfirmTeamMoveModalActive,
	setIsConfirmTeamDeleteModalActive,
} = teamTemplate.actions;

export default teamTemplate.reducer;
