import { useEffect } from 'react';

// * Отлавливает клик на enter и вызывает функцию
const useCatchEnterClick = (func: () => void, isActive: boolean) => {
	useEffect(() => {
		const handleEnterKey = (e: KeyboardEvent) => {
			if (e.code === 'Enter' && isActive) {
				func();
			}
		};

		document.addEventListener('keydown', handleEnterKey);
		return () => document.removeEventListener('keydown', handleEnterKey);
	});
};

export default useCatchEnterClick;
