import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { setWorkshopSelectedPublishUser } from '../../../../../entities/templates/workshop/template/model/slice';
import { useIntegrateInAccount } from '../api/useIntegrateInAccount';
import { SelectUserModal } from '../../../../../widgets/select-user-modal';
import { IAccount } from '../../../../../entities/user/account/api/types';

interface SelectWorkshopTemplateModalProps {
	template: string;
	isActive: boolean;
	setActive: (active: boolean) => void,
}

export const IntegrateWorkshopTemplateModal = ({
	isActive,
	template,
	setActive,
}: SelectWorkshopTemplateModalProps) => {
	const dispatch = useAppDispatch();

	const selectedUser = useAppSelector(state => state.workshopTemplate.selectedPublishUser);

	const {
		onIntegrate,
		isLoading,
	} = useIntegrateInAccount(template);

	const onSelect = (user: IAccount) => {
		if (selectedUser === user.email) {
			dispatch(setWorkshopSelectedPublishUser(''));
		} else {
			dispatch(setWorkshopSelectedPublishUser(user.email));
		}
	};

	const integrateInAccount = async () => {
		await onIntegrate(selectedUser);
		setActive(false);
	};

	useEffect(() => {
		if (!isActive) dispatch(setWorkshopSelectedPublishUser(''));
	}, [isActive]);

	return (
		<SelectUserModal
			onSelect={onSelect}
			isActive={isActive}
			isLoading={isLoading}
			setActive={setActive}
			selectedUser={selectedUser}
			onSave={integrateInAccount}
		/>
	);
};
