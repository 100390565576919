import { useState } from 'react';
import { ITariffBitmapItem } from '../../../../../entities/tariff/api/types';

export const usePublishTariffSelected = () => {
	const [currentPermission, setCurrentPermission] = useState<number>(0);
	const [selectedTariffs, setSelectedTariffs] = useState<ITariffBitmapItem[]>([]);

	const selectTariff = (tariff: ITariffBitmapItem) => {
		const permission = currentPermission | (1 << tariff.bitPosition);

		setSelectedTariffs(prev => [...prev, tariff]);
		setCurrentPermission(permission);
	};
	const removeTariff = (tariff: ITariffBitmapItem) => {
		const permission = currentPermission & ~(1 << tariff.bitPosition);

		setSelectedTariffs([...selectedTariffs.filter(el => el.tariff !== tariff.tariff)]);
		setCurrentPermission(permission);
	};

	const cleanTariffs = () => setSelectedTariffs([]);

	return {
		selectTariff,
		removeTariff,
		cleanTariffs,
		selectedTariffs,
		currentPermission,
	};
};
