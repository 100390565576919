import PageGraphic from '../../graphic/page/PageGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Dependent from '../../utils/dependent/Dependent';
import IComponent from '../../components/IComponent';
import IGraphic from '../../graphic/IGraphic';
import IMutablePagesComponentTree from '../../component-tree/IMutablePagesComponentTree';
import IFrameArea from '../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import IGraphicOutlier from './IGraphicOutlier';
import { AnyOrganizerMutation } from './mutations/AnyOrganizerMutation';
import TableComponent from '../../components/table/TableComponent';
import OrganizerTableMutation from './mutations/OrganizerTableMutation';
import OrganizerOffsetMutation from './mutations/OrganizerOffsetMutation';

interface IComponentOrganizerDependencies {
	componentTree: IMutablePagesComponentTree,
}

/**
 * Механика, отвечающая за разделение компонентов в зависимости от позиции их графики,
 * чтобы компоненты всегда были привязаны к нужной странице с корректными координатами.
 */
class ComponentOrganizer extends Dependent<IComponentOrganizerDependencies> {
	private readonly postSyncListeners: VoidFunction[];

	constructor() {
		super();
		this.postSyncListeners = [];
	}

	/**
	 * Производит проверку выхода графики за границу страницы.
	 * Если первая графика таблицы вышла за пределы страницы - переносится весь компонент на ту страницу,
	 * на которую попала первая графика. Также происходит распределение строк таблицы согласно их
	 * физическому расположению.
	 * Запускает процесс проверки только для компонентов, расположенных на листьях дерева.
	 */
	public sync = () => {
		const rootComponent = this.dependencies.componentTree.getRootComponent();
		const components = rootComponent.getComponents();
		if (components === null) {
			return;
		}

		const graphicOutliers = this.getGraphicOutliers(rootComponent);

		const mutations: AnyOrganizerMutation[] = [];

		if (graphicOutliers !== null) {
			const outliersMutations = this.generateMutationsFromOutliers(graphicOutliers);
			const tableMutations = this.generateSyncTableMutations(graphicOutliers);
			if (tableMutations !== null) {
				mutations.push(...tableMutations);
			}
			mutations.push(...outliersMutations);
		} else {
			const tableMutations = this.generateSyncAllTableMutations();
			if (tableMutations !== null) {
				mutations.push(...tableMutations);
			}
		}

		this.runMutations(mutations);
		this.callPostSyncListeners();
	};

	/**
	 * Добавляет слушателя синхронизации компонентов.
	 * @param listener Слушатель.
	 */
	public addPostSyncListener = (listener: VoidFunction) => {
		this.postSyncListeners.push(listener);
	};

	/**
	 * Возвращает мутации на основе зафиксированных выходов за пределы страницы графики.
	 * @param outliers Зафиксированные случаи выхода за пределы страницы.
	 */
	private generateMutationsFromOutliers = (outliers: IGraphicOutlier[]): AnyOrganizerMutation[] => {
		const mutations: AnyOrganizerMutation[] = [];

		outliers.forEach(outlier => {
			mutations.push(new OrganizerOffsetMutation(
				outlier.graphic,
				outlier.moveOffset,
				outlier.component,
				this.dependencies.componentTree,
			));

			// В случае с таблицей после смены офсета необходимо запустить реорганизацию строк.
			if (outlier.component.type === SketchComponentType.TABLE) {
				mutations.push(new OrganizerTableMutation(
					outlier.graphic,
					outlier.moveOffset,
					outlier.component as TableComponent,
					this.dependencies.componentTree,
				));
			}
		});

		return mutations;
	};

	/**
	 * Возвращает мутации синхронизации строк всех таблиц, которые не вышли за пределы страницы.
	 * @param graphicOutliers Зафиксированные случаи выхода за пределы страницы.
	 */
	private generateSyncTableMutations = (graphicOutliers: IGraphicOutlier[]): AnyOrganizerMutation[] | null => {
		const outlierTables: IComponent[] = graphicOutliers
			.filter(outlier => outlier.component.type === SketchComponentType.TABLE)
			.map(outlier => outlier.component);
		const tables = this.dependencies.componentTree.getUniformComponents<TableComponent>(SketchComponentType.TABLE);

		if (tables === null) {
			return null;
		}

		const skippedTables = tables.filter(table => !outlierTables.includes(table));
		if (skippedTables.length !== 0) {
			return tables.map(table => {
				const firstGraphic = table.getFirstGraphic();
				if (firstGraphic === null) {
					throw new ManipulatorError('table first graphic not found');
				}

				return new OrganizerTableMutation(firstGraphic, 0, table, this.dependencies.componentTree);
			});
		}

		return null;
	};

	/**
	 * Возвращает коллекцию мутаций реорганизации строк для всех таблиц.
	 */
	private generateSyncAllTableMutations = (): AnyOrganizerMutation[] | null => {
		const tables = this.dependencies.componentTree.getUniformComponents<TableComponent>(SketchComponentType.TABLE);
		if (tables === null) {
			return null;
		}

		return tables.map(table => {
			const firstGraphic = table.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('table first graphic not found');
			}

			return new OrganizerTableMutation(firstGraphic, 0, table, this.dependencies.componentTree);
		});
	};

	/**
	 * Возвращает коллекцию объектов с информацией о выходе графики за пределы допустимой области.
	 * Для обычных компонентов это страница, а для таблиц внутренняя область страницы с учетом отступа.
	 */
	private getGraphicOutliers = (component: IComponent): IGraphicOutlier[] | null => {
		const outliers: IGraphicOutlier[] = [];
		this.recursiveInspectPageOutside(component, outliers);
		return outliers.length === 0 ? null : outliers;
	};

	private recursiveInspectPageOutside = (component: IComponent, outliers: IGraphicOutlier[]) => {
		const childComponents = component.getComponents();

		if (childComponents === null) {
			this.inspectPageOutsideComponent(component, outliers);
			return;
		}

		childComponents.forEach(child => this.recursiveInspectPageOutside(child, outliers));
	};

	/**
	 * Проверяет компонент на выход за пределы страницы, к которой он привязан структурно.
	 * @param component Проверяемый компонент.
	 * @param outliers Аккумулирующая коллекция зафиксированных выходов за границу.
	 */
	private inspectPageOutsideComponent = (component: IComponent, outliers: IGraphicOutlier[]) => {
		const graphics = component.getGraphics();

		if (component.type === SketchComponentType.TABLE) {
			const firstGraphic = component.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}

			const outlier = this.inspectTableGraphicOutline(component as TableComponent, firstGraphic);
			if (outlier === null) {
				return;
			}
			outliers.push(outlier);
			return;
		}

		graphics.forEach(graphic => {
			const outlier = this.inspectGraphicPageOutside(component, graphic);
			if (outlier === null) {
				return;
			}
			outliers.push(outlier);
		});
	};

	/**
	 * Проверка компонента с одной графикой на выход за пределы страницы.
	 * @param component Проверяемый компонент.
	 * @param graphic Проверяемая графика.
	 */
	private inspectGraphicPageOutside = (
		component: IComponent,
		graphic: IGraphic,
	): IGraphicOutlier | null => {
		const graphicDependPage = this.getDependGraphicToPage(graphic);
		if (graphicDependPage === null) {
			return null;
		}

		const currentPage = this.dependencies.componentTree.getPageFromGraphic(graphic);
		if (graphicDependPage === currentPage) {
			return null;
		}

		const moveOffset = this.getMoveGraphicOffset(currentPage, graphicDependPage);

		return { component, graphic, moveOffset };
	};

	/**
	 * Возвращает фиксацию выхода графики компонента за пределы страницы.
	 * Пределами страницы считается внутренняя область страницы с учетом внутреннего отступа.
	 * @param component Компонент таблицы.
	 * @param graphic Графика таблицы.
	 */
	private inspectTableGraphicOutline = (
		component: TableComponent,
		graphic: IGraphic,
	): IGraphicOutlier | null => {
		const currentPage = this.dependencies.componentTree.getPageFromGraphic(graphic);
		const currentPageTexture = currentPage.getTexture();
		const currentPageConfiguration = currentPage.getFrameConfiguration();
		const globalPagePosition = currentPage.getGlobalPosition();
		const graphicGlobalPosition = graphic.getGlobalPosition();
		const graphicFrameConfiguration = graphic.getFrameConfiguration();
		const downPageBoundary = globalPagePosition.y
			+ currentPageConfiguration.height - currentPageTexture.paddingBottom;
		const upPageBoundary = globalPagePosition.y + currentPageTexture.paddingTop;

		if (graphicGlobalPosition.y + graphicFrameConfiguration.height > downPageBoundary
		|| graphicGlobalPosition.y < upPageBoundary) {
			const pages = this.dependencies.componentTree.getPages();
			const dependPage = this.getDependGraphicToPage(graphic);
			if (dependPage === null) {
				throw new ManipulatorError('depend page not found');
			}
			const currentPageIndex = pages.indexOf(currentPage);
			const dependPageIndex = pages.indexOf(dependPage);
			if (currentPageIndex === undefined || dependPageIndex === undefined) {
				throw new ManipulatorError('page index not found');
			}

			return {
				graphic,
				component,
				moveOffset: dependPageIndex - currentPageIndex,
			};
		}
		return null;
	};

	/**
	 * Возвращает страницу, к которой в настоящий момент визуально относится графика.
	 * @param graphic Проверяемая графика.
	 */
	private getDependGraphicToPage = (graphic: IGraphic): PageGraphic | null => {
		// Получаем список всех страниц
		const pages = this.dependencies.componentTree.getPages();

		// Инициализирует Map'у для хранения страницы и площади компонента на ней
		const pageCrossedArea = new Map<PageGraphic, number>();
		pages.forEach(page => {
			// Получаем позицию и реальные размеры страницы
			const pagePosition = page.getGlobalPosition();
			const pageWidth = page.getRealWidth();
			const pageHeight = page.getRealHeight();

			// Получаем позицию и конфигурацию графики
			const graphicPosition = graphic.getGlobalPosition();
			const graphicConfiguration = graphic.getFrameConfiguration();

			const pageFrameArea: IFrameArea = {
				x: pagePosition.x,
				y: pagePosition.y,
				width: pageWidth,
				height: pageHeight,
				rotate: 0,
			};
			const graphicFrameArea: IFrameArea = {
				x: graphicPosition.x,
				y: graphicPosition.y,
				width: graphicConfiguration.width,
				height: graphicConfiguration.height,
				rotate: 0,
			};

			const crossedArea = Utils.Geometry.intersectArea(pageFrameArea, graphicFrameArea);
			pageCrossedArea.set(page, crossedArea);
		});

		const isNotCrossed = Array.from(pageCrossedArea.values()).every(area => area === 0);
		if (isNotCrossed) {
			return null;
		}

		let maxCrossPage: PageGraphic = pages[0];
		let maxCrossArea = 0;
		pageCrossedArea.forEach((area, page) => {
			if (area > maxCrossArea) {
				maxCrossPage = page;
				maxCrossArea = area;
			}
		});
		return maxCrossPage;
	};

	/**
	 * Возвращает сдвиг офсета для графики от текущего значения для перемещения на страницу `graphicDependPage`.
	 * @param currentPage Текущая страница, на которой расположена графика.
	 * @param graphicDependPage Страница, на которую должна попасть графика после изменения офсета.
	 */
	private getMoveGraphicOffset = (currentPage: PageGraphic, graphicDependPage: PageGraphic): number => {
		if (currentPage === graphicDependPage) {
			throw new ManipulatorError('page not change');
		}

		const pages = this.dependencies.componentTree.getPages();
		const currentPageIndex = pages.indexOf(currentPage);
		if (currentPageIndex === -1) {
			throw new ManipulatorError('current page not found');
		}
		const graphicDependPageIndex = pages.indexOf(graphicDependPage);
		if (graphicDependPageIndex === -1) {
			throw new ManipulatorError('graphic depend not found');
		}

		return graphicDependPageIndex - currentPageIndex;
	};

	private callPostSyncListeners = () => {
		this.postSyncListeners.forEach(listener => listener());
	};

	/**
	 * Запускает все переданные мутации и побочные эффекты для них.
	 * @param mutations Мутации для запуска.
	 */
	private runMutations = (mutations: AnyOrganizerMutation[]) => {
		this.dependencies.componentTree.executeMutations(_ => {
			mutations.forEach(mutation => mutation.inspectPostMovePlace());
			mutations.forEach(mutation => mutation.run());

			this.dependencies.componentTree.mutateByRemoveEmptyUniterGraphics();
		});

		setTimeout(this.callPostSyncListeners, 100);
	};
}

export default ComponentOrganizer;
