import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { legalEntityAPI } from '../../../../entities/legal-entity/api/api';
import { setIsLegalEntityUserSelectionModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useMultipleLegalEntityUsers = () => {
	const dispatch = useAppDispatch();

	const {
		bindingLegalEntityId,
		isLegalEntityUserSelectionModalActive,
	} = useAppSelector(state => state.legalEntity);

	const [bind, { isLoading: isBinding }] = legalEntityAPI.useBindMutation();

	const { data: availableUsers } = legalEntityAPI.useGetAvailableAccountsQuery({
		id: bindingLegalEntityId || '',
	}, {
		skip: !bindingLegalEntityId,
	});

	const handleILegalEntityUserSelectionModalActive = (value: boolean) => {
		dispatch(setIsLegalEntityUserSelectionModalActive(value));
	};

	const onBind = async (emails: string[]) => {
		if (bindingLegalEntityId && emails) {
			try {
				await bind({
					legalEntity: bindingLegalEntityId,
					emails,
				})
					.unwrap();
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				if (emails.length > 1) {
					notificationSuccess('Вы успешно добавили пользователей!');
				} else {
					notificationSuccess('Вы успешно добавили пользователя!');
				}
				handleILegalEntityUserSelectionModalActive(false);
			} catch (e) {
				if (emails.length > 1) {
					notificationError('Возникла ошибка при добавлении пользователей...');
				} else {
					notificationError('Возникла ошибка при добавлении пользователя!');
				}
			}
		}
	};

	return {
		onBind,
		isBinding,
		availableUsers,
		isLegalEntityUserSelectionModalActive,
		handleILegalEntityUserSelectionModalActive,
	};
};
