import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import React from 'react';
import css from './page-tabs.module.scss';
import { ITabData } from '../model/types';

interface TabsProps {
	data: ITabData[];
}

export const PageTabs = ({ data }: TabsProps) => {
	const { pathname } = useLocation();

	return (
		<div className={css.tabsBlock}>
			{
				data.map(({
					name,
					link,
					disabled,
				}) => {
					const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
						if (disabled) e.preventDefault();
					};
					
					return (
						<NavLink
							onClick={handleClick}
							to={link}
							className={cx(css.tab, pathname.includes(link) && css.activeTab, disabled && css.disabled)}
							key={link}
						>
							{name}
						</NavLink>
					);
				})
			}
		</div>
	);
};
