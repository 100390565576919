import React, { useEffect } from 'react';
import Button, { buttonsTypes } from '../button/button';
import css from './multipleSelectionBoard.module.scss';
import useCatchEnterClick from '../../../hooks/useCatchEnterClick';
import NotFound from '../../pages/features/moduleWindow/editModuleWindow/editBlocks/NotFound';

interface ItemI {
	id: string,
	elem: React.ReactNode,
}

interface MultipleSelectionBoardProps {
	btnText: string,
	bindingIds: string[],
	onBinding: () => void,
	notFoundText: string,
	isNeedClean?: boolean,
	singleSelection?: boolean,
	items: ItemI[] | undefined,
	setBindingIds: (value: string[]) => void,
}

const MultipleSelectionBoard = ({
	items,
	btnText,
	onBinding,
	bindingIds,
	isNeedClean,
	notFoundText,
	setBindingIds,
	singleSelection = false,
}: MultipleSelectionBoardProps) => {
	// * При выделении/отмены выделения пользователя
	const handleBinding = (id: string) => {
		if (bindingIds?.includes(id)) {
			setBindingIds([...bindingIds.filter(item => item !== id)]);
		} else if (bindingIds && !bindingIds?.includes(id)) {
			if (singleSelection) {
				setBindingIds([id]);
			} else {
				setBindingIds([...bindingIds, id]);
			}
		}
	};

	useCatchEnterClick(onBinding, bindingIds ? bindingIds.length > 0 : false);

	useEffect(() => {
		if (!isNeedClean) {
			setBindingIds([]);
		}
	}, [isNeedClean]);

	return (
		<div className={css.wrapper}>
			{
				items
					? (
						<div className={css.item_list}>
							{
								items.map(item => {
									const {
										id,
										elem,
									} = item;
									const onClick = () => handleBinding(id.toString());
									const border = bindingIds?.includes(id.toString()) ? '2px solid #0075FF' : '';

									return (
										<div
											key={id}
											onClick={onClick}
											style={{ border }}
											role="presentation"
											className={css.item}
										>
											{elem}
										</div>
									);
								})
							}
						</div>
					)
					: (
						<NotFound
							text={notFoundText}
							style={css.not_found}
						/>
					)
			}
			<Button
				text={btnText}
				cssClass={css.btn}
				buttonType={buttonsTypes.BRIGHT}
				onClick={onBinding}
				inActive={bindingIds ? bindingIds.length > 0 : false}
			/>
		</div>
	);
};

export default MultipleSelectionBoard;
