import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { copyBuffer, domainName } from '../../../../../../../helper';
import { notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { useMoveCustomTemplate } from '../../../../../../../features/templates/custom/move/api/useMoveCustomTemplate';

export const useCustomContextMenuActions = (id: string) => {
	const navigate = useNavigate();

	const [isActionMenuOpened, setIsActionMenuOpened] = useState(false);

	const closeActionMenu = () => setIsActionMenuOpened(false);

	// * Скопировать ссылку на шаблон
	const copyLink = () => copyBuffer(`${domainName}collection/template-custom-panel/${id}`);

	const onCopyLink = () => {
		copyLink();
		closeActionMenu();
		notificationSuccess('Ссылка скопирована в буфер обмена!');
	};

	// * Создать из шаблона предложение
	const onSendOffer = () => navigate(`/custom-template-present/${id}`);

	const { onMoveTemplate: moveTemplate } = useMoveCustomTemplate();

	const onMoveTemplate = async () => {
		await moveTemplate([{ id }]);
		// dispatch(setIsConfirmCustomMoveModalActive(true));
		closeActionMenu();
	};

	return {
		onCopyLink,
		onSendOffer,
		onMoveTemplate,
		isActionMenuOpened,
		setIsActionMenuOpened,
	};
};
