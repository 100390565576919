import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { setIsEditingTagModalActive } from '../../../../../../redux/reducers/module/tagReducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import EditTagWindow from './EditTagWindow';
import { all, tagModalsActive } from '../../../../../../redux/selectors/module/tag';
import { tagAPI } from '../../../../../../entities/modules/tag/api/api';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { moduleAPI } from '../../../../../../entities/modules/module/api/api';

const EditingTagWrapper = () => {
	const dispatch = useDispatch();

	const isEditingTagModalActive = useSelector((state: RootState) => tagModalsActive(state).isEditingTagModalActive);

	const editingTagId = useSelector((state: RootState) => all(state).editingTagId);

	const [update] = tagAPI.useUpdateMutation();

	const handleEditingTagModal = (value: boolean) => {
		dispatch(setIsEditingTagModalActive(value));
	};

	const onEditTag = async (
		inputValue: string,
		setInputValue: (value: string) => void,
	) => {
		if (editingTagId) {
			try {
				await update({
					id: editingTagId,
					name: inputValue,
				})
					.unwrap();
				dispatch(tagGroupAPI.util?.invalidateTags(['tag_group']));
				dispatch(moduleAPI.util?.invalidateTags(['modules']));
				setInputValue('');
				handleEditingTagModal(false);
				notificationSuccess('Тег изменен успешно!');
			} catch (e) {
				notificationError('Возникла ошибка при изменении тега...');
			}
		}
	};

	return (
		<EditTagWindow
			onEditTag={onEditTag}
			maxLength={20}
			inputTitle="Изменение тега"
			isActive={isEditingTagModalActive}
			setActive={handleEditingTagModal}
		/>
	);
};

export default EditingTagWrapper;
