import { IFilterItem } from '../../model/types';
import { ReactComponent as SelectedIcon } from '../../images/selected_item.svg';
import css from './item.module.scss';

interface FilterItemProps {
	data: IFilterItem,
	selected: boolean,
	onSelect: (item: IFilterItem) => void,
}

export const FilterItem = ({
	data,
	selected,
	onSelect,
}: FilterItemProps) => (
	<div className={css.wrapper} role="presentation" onClick={() => onSelect(data)}>
		{selected && <div className={css.picture}><SelectedIcon /></div>}
		<span>{data.name}</span>
	</div>
);
