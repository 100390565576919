import { useEffect, useState } from "react";
import { ERROR_MESSAGE_LIST } from "../components/Notifications/callNotifcation";

const useDynamicPagination = (
	fetchData: (currentPage: number, perPage: number) => Promise<any> | undefined,
	perPage: number,
	initialPage: number,
	listLength: number | undefined,
) => {
	const [currentPage, setCurrentPage] = useState(initialPage);
	const [totalCount, setTotalCount] = useState(0);
	const [isFetching, setIsFetching] = useState(false);
	const handleScroll = (e: any) => {
		if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 200
			&& listLength && listLength < totalCount
		) {
			setIsFetching(true);
		}
	};

	useEffect(() => {
		document.addEventListener("scroll", handleScroll);
		return () => document.removeEventListener("scroll", handleScroll);
	});

	useEffect(() => {
		if (isFetching) {
			fetchData(currentPage, perPage)
				?.then(() => {
					setCurrentPage(currentPage + 1);
				})
				.catch(() => {
					console.log(ERROR_MESSAGE_LIST.ERROR);
				})
				.finally(() => {
					setIsFetching(false);
				});
		}
	}, [isFetching]);

	return {
		setCurrentPage,
		setTotalCount,
	};
};

export default useDynamicPagination;
