import { tariffAPI } from '../../../../../../../entities/tariff/api/api';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import { checkPermission } from '../../../../../../../hooks/permissions/useGetPermission';
import { checkBitPosition } from '../../../../../../../helpers/checkBitPosition';
import { Tariff, TTariffName } from '../../../../../../../entities/tariff/model/data';

const availabilityDescription = (tariff: string) => `Доступен в ${tariff} тарифе`;

const availabilityDescriptionTariffMap = new Map<TTariffName, string>([
	[Tariff.TRIAL, 'Пробном'],
	[Tariff.BASIC, 'Базовом'],
]);

interface IAvailabilityLocked {
	isLocked: boolean,
	description: string,
}

const isTemplatePermitted = (templatePermission: number, currentTariffBitPosition: number) => {
	// * Если тариф пользователя "выше", чем тариф, который требуется для просмотра шаблона,
	// * то шаблон все равно будет доступен пользователю
	for (let i = currentTariffBitPosition; i >= 0; i--) {
		const { isPermitted } = checkPermission(templatePermission, i);
		if (isPermitted) {
			return true;
		}
	}
	return false;
};

export const useGalleryTemplateLocked = (templatePermission: number): IAvailabilityLocked => {
	const { data: bitmap } = tariffAPI.useGetBitmapQuery();

	const account = useAppSelector(state => state.personalAccount.account);

	const isLockedForUser = () => {
		const userTariff = bitmap?.tariffs.find(tariff => tariff.tariff === account?.tariff?.name);

		if (userTariff) {
			const isPermitted = isTemplatePermitted(templatePermission, userTariff.bitPosition);

			if (isPermitted) {
				return {
					isLocked: false,
					description: '',
				};
			}

			const suitableTariff = bitmap?.tariffs.find(tariff => (
				checkBitPosition(templatePermission, tariff.bitPosition)));

			if (suitableTariff) {
				return {
					isLocked: true,
					description: availabilityDescription(
						availabilityDescriptionTariffMap.get(suitableTariff.tariff) || '',
					),
				};
			}
		}

		return {
			isLocked: true,
			description: '',
		};
	};

	return isLockedForUser();
};
