import PagesComponentTree, { IPagesComponentTreeDependencies } from './PagesComponentTree';
import IMutablePagesComponentTree from '../IMutablePagesComponentTree';
import PageGraphic from '../../graphic/page/PageGraphic';
import GraphicType from '../../graphic/GraphicType';
import IComponent from '../../components/IComponent';
import SketchComponentType from '../../components/SketchComponentType';
import GroupGraphic from '../../graphic/group/GroupGraphic';
import IGraphic from '../../graphic/IGraphic';
import ModuleGraphic from '../../graphic/module/ModuleGraphic';
import IDescartesPosition from '../../utils/IDescartesPosition';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';

interface IMutablePagesComponentTreeDependencies extends IPagesComponentTreeDependencies {}

/**
 * Дерево компонентов, в основе которого лежит компонент с поддержкой страниц. Имеет возможность
 * для динамического изменения структуры.
 */
class MutablePagesComponentTree
	extends PagesComponentTree<IMutablePagesComponentTreeDependencies>
	implements IMutablePagesComponentTree {
	// eslint-disable-next-line no-useless-constructor
	constructor(manipulatorElement: HTMLElement) {
		super(manipulatorElement);
	}

	/**
	 * Мутирует структуру дерева через добавление новой страницы на последнее место.
	 */
	public mutateAddPageToEnd = (): PageGraphic => {
		let createdPage: PageGraphic;

		this.executeMutations(tools => {
			const pages = this.getPages();
			const lastPage = pages[pages.length - 1];
			const pageTexture = lastPage.getUniqueTexture();
			createdPage = tools.graphicFactory
				.createGraphic<PageGraphic>(GraphicType.PAGE, this.pagesContainerComponent);

			createdPage.setTexture(() => pageTexture);
			tools.mutator.mutateByAppendGraphic(this.pagesContainerComponent, createdPage);
		});
		this.syncPagePanels();

		return createdPage!;
	};

	/**
	 * Мутирует структуру дерева через добавление новой страницы в начало скетча.
	 */
	public mutateAddPageToStart = (): PageGraphic => {
		let createdPage: PageGraphic;

		this.executeMutations(tools => {
			const pages = this.getPages();
			const firstPage = pages[0];
			const pageTexture = firstPage.getUniqueTexture();
			createdPage = tools.graphicFactory
				.createGraphic<PageGraphic>(GraphicType.PAGE, this.pagesContainerComponent);

			createdPage.setTexture(() => pageTexture);
			tools.mutator.mutateByAppendGraphicBefore(this.pagesContainerComponent, firstPage, createdPage);
			this.componentLayers.appendPage(createdPage, 0);
		});
		this.syncPagePanels();

		return createdPage!;
	};

	/**
	 * Расширяет компонент, добавляя в конец одну дополнительную графику.
	 * @param component Расширяемый компонент.
	 */
	public mutateByExtendComponentToEnd = (component: IComponent): IGraphic => {
		switch (component.type) {
		case SketchComponentType.PAGES_CONTAINER: {
			return this.mutateAddPageToEnd();
		}
		case SketchComponentType.GROUP: {
			const groupGraphic = this.dependencies
				.graphicFactory.createGraphic<GroupGraphic>(GraphicType.GROUP, component);
			return this.mutateByAppendGraphic(component, groupGraphic);
		}
		case SketchComponentType.MODULE: {
			const moduleGraphic = this.dependencies
				.graphicFactory.createGraphic<ModuleGraphic>(GraphicType.MODULE, component);
			return this.mutateByAppendGraphic(component, moduleGraphic);
		}
		default:
			throw new ManipulatorError('extend component not implemented');
		}
	};

	/**
	 * Расширяет компонент, добавляя в начало одну дополнительную графику и изменяя офсет на -1.
	 * Добавляет +1 к офсету дочерних компонентов.
	 * @exception ManipulatorError В случае отсутствия родительской графики для смещения компонента на офсет -1.
	 * @param component Расширяемый компонент.
	 */
	public mutateByExtendComponentToStart = (component: IComponent): IGraphic => {
		const componentOffset = component.getOffset();
		if (componentOffset === null) {
			throw new ManipulatorError('component offset is null');
		}

		if (componentOffset === 0) {
			throw new ManipulatorError('component offset is zero');
		}

		let parentGraphic: IGraphic;
		switch (component.type) {
		case SketchComponentType.PAGES_CONTAINER: {
			parentGraphic = this.mutateAddPageToStart();
			break;
		}
		case SketchComponentType.GROUP: {
			const firstGraphic = component.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}
			const groupGraphic = this.dependencies
				.graphicFactory.createGraphic<GroupGraphic>(GraphicType.GROUP, component);
			parentGraphic = this.mutateByAppendGraphicBefore(component, firstGraphic, groupGraphic);
			break;
		}
		case SketchComponentType.MODULE: {
			const firstGraphic = component.getFirstGraphic();
			if (firstGraphic === null) {
				throw new ManipulatorError('first graphic not found');
			}
			const moduleGraphic = this.dependencies
				.graphicFactory.createGraphic<ModuleGraphic>(GraphicType.MODULE, component);
			parentGraphic = this.mutateByAppendGraphicBefore(component, firstGraphic, moduleGraphic);
			break;
		}
		default:
			throw new ManipulatorError('extend component not implemented');
		}

		component.syncGraphicsOffset();

		return parentGraphic;
	};

	public getNearestPage = (position: IDescartesPosition): PageGraphic => {
		let pageIndex: number | null = null;
		let minDistance = Number.MAX_SAFE_INTEGER;

		const pages = this.getPages();
		pages.forEach(page => {
			const {
				x, y, width, height,
			} = page.getFrameConfiguration();
			const pageCenterX = x + width / 2;
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от центра страницы до области вставки фигуры
			const distance = Math.sqrt((position.x - pageCenterX) ** 2 + (position.y - pageCenterY) ** 2);

			// Обновляем ближайшее смещение и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				pageIndex = page.getOffset();
			}
		});

		if (pageIndex === null) {
			throw new ManipulatorError('page index not found', { pagesCount: pages.length, position });
		}

		const page = pages[pageIndex];
		if (page === undefined) {
			throw new ManipulatorError('page not found', { pageCount: pages.length, pageIndex });
		}

		return page;
	};
}

export default MutablePagesComponentTree;
