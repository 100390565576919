import React, { useState } from 'react';
import { TemplateListWrapper } from '../../../../../../../../widgets/templates/list-wrapper';
import { IGalleryTemplate } from '../../../../../../../../entities/templates/gallery/model/types';
import { GalleryTemplate } from '../gallery-template/gallery-template';
import { useIsMounted } from '../../../../../../../../hooks/lifecycle/useIsMounted';

interface GalleryTemplateListProps {
	isDataLoading: boolean,
	templates: IGalleryTemplate[] | undefined,
}

export const GalleryTemplateList = ({
	templates,
	isDataLoading,
}: GalleryTemplateListProps) => {
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const [loadingTemplateId, setLoadingTemplateId] = useState<string | null>(null);

	const isMounted = useIsMounted();

	const handleCardLoading = (templateId: string | null) => {
		if (isMounted.current) {
			if (templateId) {
				setIsGlobalLoading(true);
				setLoadingTemplateId(templateId);
			} else {
				setIsGlobalLoading(false);
				setLoadingTemplateId(null);
			}
		}
	};

	return (
		<TemplateListWrapper isDataLoading={isDataLoading}>
			{
				templates?.map(template => (
					<GalleryTemplate
						data={template}
						key={template.id}
						isLoading={loadingTemplateId === template.id}
						isGlobalLoading={isGlobalLoading}
						setGlobalLoading={handleCardLoading}
					/>
				))
			}
		</TemplateListWrapper>
	);
};
