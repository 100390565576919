import { tagAPI } from '../../../../../entities/modules/tag/api/api';
import { moduleAPI } from '../../../../../entities/modules/module/api/api';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import {
	ERROR_MESSAGE_LIST,
	notificationError,
	notificationSuccess,
} from '../../../../../components/Notifications/callNotifcation';
import { ITagData } from '../../../../../entities/modules/tag/api/types';

export const useBindModuleTag = (id: string) => {
	const dispatch = useAppDispatch();

	const [bind, { isLoading }] = tagAPI.useBindMutation();

	const onBindTag = async (tag: ITagData) => {
		try {
			await bind({
				id,
				tags: [tag.id],
			})
				.unwrap();
			dispatch(moduleAPI.util?.invalidateTags(['modules']));
			dispatch(tagAPI.util?.invalidateTags(['tag']));
			notificationSuccess('Тег успешно добавлен модулю!');
		} catch (e) {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'Что-то пошло не так...');
		}
	};

	return {
		onBindTag,
		isLoading,
	};
};
