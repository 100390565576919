import IComponent from '../components/IComponent';
import IManipulatorAction from './actions/IManipulatorAction';
import ConstructorAction from './actions/ConstructorAction';
import ISketchStructure from '../ISketchStructure';
import SketchNameChangeCommand from './commands/sketch-change-name/SketchNameChangeCommand';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import ComponentAppendCommand from './commands/component-append/ComponentAppendCommand';
import ComponentRemoveCommand from './commands/component-remove/ComponentRemoveCommand';
import Utils from '../utils/impl/Utils';
import ComponentChangeCommand from './commands/component-change/ComponentChangeCommand';
import IBaseSketchManipulator from '../../SketchManipulators/IBaseSketchManipulator';
import Dependent from '../utils/dependent/Dependent';
import { AnyComponentStructure } from '../Types';
import ISketchSnapshot from './ISketchSnapshot';
import LayerSequencesCommand from './commands/layer-sequences/LayerSequencesCommand';
import IComponentTreeMutator from '../component-tree/IComponentTreeMutator';
import IComponentTree from '../component-tree/IComponentTree';
import ILayeredComponentTree from '../component-tree/ILayeredComponentTree';

export interface ISketchStateDependencies {
	componentTree: IComponentTree & IComponentTreeMutator & ILayeredComponentTree,
	manipulator: IBaseSketchManipulator,
}

/**
 * Класс для хранения состояния структуры скетча и генерации действий на основе изменений.
 */
class SketchState extends Dependent<ISketchStateDependencies> {
	private sketchID: string;

	private snapshot: ISketchSnapshot;

	constructor() {
		super();

		this.snapshot = {
			name: '',
			layerSequences: [],
			mapComponentChildParent: new Map<IComponent, IComponent>(),
			mapComponentStructure: new Map<IComponent, AnyComponentStructure>(),
		};

		this.addPostInjectDependenciesListener(dependencies => {
			this.sketchID = dependencies.manipulator.getID();
		});
	}

	/**
	 * Синхронизирует внутренние структуры данными из текущего состояния скетча.
	 */
	public syncState = () => {
		const name = this.dependencies.manipulator.getName();
		const components = this.dependencies.componentTree.getComponents();

		this.snapshot.mapComponentStructure.clear();
		this.snapshot.mapComponentChildParent.clear();

		this.snapshot.name = name;
		components.forEach((component) => {
			const structure: AnyComponentStructure = {
				...component.getStructure(),
				components: null,
			};
			const parent = component.getParentComponent();

			this.snapshot.mapComponentStructure.set(component, structure);
			this.snapshot.mapComponentChildParent.set(component, parent);
		});

		this.snapshot.layerSequences = this.dependencies.componentTree.getLayerSequences();
	};

	/**
	 * Сравнивает последнее сохраненное состояние с текущим и генерирует действие на основе изменений.
	 * @returns {IManipulatorAction} - Сгенерированное действие.
	 */
	public buildAction = (): IManipulatorAction => {
		const action = new ConstructorAction();
		const sketchStructure = this.dependencies.manipulator.getStructure();
		const currentComponents = this.dependencies.componentTree.getComponents();
		const snapshotComponents = Array.from(this.snapshot.mapComponentStructure.keys());

		this.scanSketch(action, sketchStructure);

		const actualComponents = this.scanRemoveComponents(action, snapshotComponents, currentComponents);
		const appendComponents = this.scanAppendComponents(action, snapshotComponents, currentComponents);

		const scanComponents = actualComponents.filter(x => !appendComponents.includes(x));
		scanComponents.forEach(component => {
			this.scanStructureComponent(action, component);
			this.scanChangeParentComponent(action, component);
		});

		if (!action.isEmpty()) {
			this.scanChangeLayers(action);
		}

		this.syncState();
		return action;
	};

	/**
	 * Записывает общие изменения характеристик скетча в действие.
	 * @param {ConstructorAction} action - Действие для записи изменений.
	 * @param {ISketchStructure} sketchStructure - Структура скетча.
	 */
	private scanSketch = (action: ConstructorAction, sketchStructure: ISketchStructure) => {
		const { name } = sketchStructure;
		if (name === this.snapshot.name) {
			return;
		}

		const undoCommand = new SketchNameChangeCommand(this.dependencies.manipulator, this.snapshot.name);
		const redoCommand = new SketchNameChangeCommand(this.dependencies.manipulator, name);

		action.pushUndoCommands(undoCommand);
		action.pushRedoCommands(redoCommand);
	};

	/**
	 * Записывает удаление компонентов в действие и возвращает актуальные компоненты, для которых
	 * целесообразна проверка на изменение характеристик (коллекция включает также только что добавленные компоненты).
	 * @param {ConstructorAction} action - Действие для записи изменений.
	 * @param {IComponent[]} snapshotComponents - Компоненты из предыдущего состояния.
	 * @param {IComponent[]} currentComponents - Текущие компоненты.
	 * @returns {IComponent[]} - Актуальные компоненты после удаления.
	 */
	private scanRemoveComponents = (
		action: ConstructorAction,
		snapshotComponents: IComponent[],
		currentComponents: IComponent[],
	): IComponent[] => {
		const removesComponents = this.getRemoveComponents(snapshotComponents, currentComponents);
		const actualComponents = currentComponents.filter(x => !removesComponents.includes(x));

		removesComponents.forEach(component => {
			const parent = this.snapshot.mapComponentChildParent.get(component);
			if (parent === undefined) {
				throw new ManipulatorError('component parent not found');
			}
			if (parent === null) {
				return;
			}

			const undoCommand = new ComponentAppendCommand(
				this.dependencies.componentTree,
				component,
				parent,
				this.sketchID,
			);
			const redoCommand = new ComponentRemoveCommand(
				this.dependencies.componentTree,
				component,
				parent,
				this.sketchID,
			);

			action.pushUndoCommands(undoCommand);
			action.pushRedoCommands(redoCommand);
		});

		return actualComponents;
	};

	/**
	 * Записывает появление компонентов в действие.
	 * @param {ConstructorAction} action - Действие для записи изменений.
	 * @param {IComponent[]} snapshotComponents - Компоненты из предыдущего состояния.
	 * @param {IComponent[]} currentComponents - Текущие компоненты.
	 * @returns {IComponent[]} - Компоненты, которые были добавлены.
	 */
	private scanAppendComponents = (
		action: ConstructorAction,
		snapshotComponents: IComponent[],
		currentComponents: IComponent[],
	): IComponent[] => {
		const appendsComponents = this.getAppendComponents(snapshotComponents, currentComponents);

		appendsComponents.forEach(component => {
			const parent = component.getParentComponent();
			if (parent === null) {
				throw new ManipulatorError('component parent not found');
			}

			const undoCommand = new ComponentRemoveCommand(
				this.dependencies.componentTree,
				component,
				parent,
				this.sketchID,
			);
			const redoCommand = new ComponentAppendCommand(
				this.dependencies.componentTree,
				component,
				parent,
				this.sketchID,
			);
			action.pushUndoCommands(undoCommand);
			action.pushRedoCommands(redoCommand);
		});

		return appendsComponents;
	};

	/**
	 * Возвращает компоненты, которые были удалены.
	 * @param {IComponent[]} snapshotComponents - Компоненты из предыдущего состояния.
	 * @param {IComponent[]} currentComponents - Текущие компоненты.
	 * @returns {IComponent[]} - Компоненты, которые были удалены.
	 */
	private getRemoveComponents = (
		snapshotComponents: IComponent[],
		currentComponents: IComponent[],
	): IComponent[] => snapshotComponents
		.filter(x => !currentComponents.includes(x));

	/**
	 * Возвращает компоненты, которые были добавлены.
	 * @param {IComponent[]} snapshotComponents - Компоненты из предыдущего состояния.
	 * @param {IComponent[]} currentComponents - Текущие компоненты.
	 * @returns {IComponent[]} - Компоненты, которые были добавлены.
	 */
	private getAppendComponents = (
		snapshotComponents: IComponent[],
		currentComponents: IComponent[],
	): IComponent[] => currentComponents
		.filter(x => !snapshotComponents.includes(x));

	/**
	 * Записывает изменения характеристик компонента в действие.
	 * @param {ConstructorAction} action - Действие для записи изменений.
	 * @param {IComponent} component - Компонент, для которого записываются изменения.
	 */
	private scanStructureComponent = (action: ConstructorAction, component: IComponent) => {
		const currentStructure: AnyComponentStructure = {
			...component.getStructure(),
			components: null,
		};
		const prevStructure = this.snapshot.mapComponentStructure.get(component);
		if (prevStructure === undefined) {
			throw new ManipulatorError('structure snapshot not found');
		}

		const notChanged = Utils.Object.deepEqual(prevStructure, currentStructure);
		if (notChanged) {
			return;
		}

		const undoCommand = new ComponentChangeCommand({
			component,
			sketchID: this.sketchID,
			structure: prevStructure,
			componentTree: this.dependencies.componentTree,
		});
		const redoCommand = new ComponentChangeCommand(
			{
				component,
				sketchID: this.sketchID,
				structure: currentStructure,
				componentTree: this.dependencies.componentTree,
			},
		);

		action.pushUndoCommands(undoCommand);
		action.pushRedoCommands(redoCommand);
	};

	/**
	 * Записывает смену родителя компонента в действие.
	 * @param {ConstructorAction} action - Действие для записи изменений.
	 * @param {IComponent} component - Компонент, для которого записывается смена родителя.
	 */
	private readonly scanChangeParentComponent = (action: ConstructorAction, component: IComponent) => {
		const snapshotParent = this.snapshot.mapComponentChildParent.get(component);
		const currentParent = component.getParentComponent();
		if (snapshotParent === undefined) {
			throw new ManipulatorError('snapshot parent not found');
		}
		if (snapshotParent === null || currentParent === null) {
			return;
		}
		if (snapshotParent === currentParent) {
			return;
		}

		const undoRemoveCommand = new ComponentRemoveCommand(
			this.dependencies.componentTree,
			component,
			currentParent,
			this.sketchID,
		);
		const undoAppendCommand = new ComponentAppendCommand(
			this.dependencies.componentTree,
			component,
			snapshotParent,
			this.sketchID,
		);
		const redoRemoveCommand = new ComponentRemoveCommand(
			this.dependencies.componentTree,
			component,
			snapshotParent,
			this.sketchID,
		);
		const redoAppendCommand = new ComponentAppendCommand(
			this.dependencies.componentTree,
			component,
			currentParent,
			this.sketchID,
		);

		if (currentParent.isUniter) {
			action.pushUndoCommands(undoAppendCommand);
		} else {
			action.pushUndoCommands(undoRemoveCommand, undoAppendCommand);
		}

		if (snapshotParent.isUniter) {
			action.pushRedoCommands(redoAppendCommand);
		} else {
			action.pushRedoCommands(redoRemoveCommand, redoAppendCommand);
		}
	};

	/**
	 * Ищет изменения в последовательности слоев. В случае обнаружения записывает соответствующие команды в action.
	 * @param action Сущность действия пользователя, в которую будут записаны команды изменения скетча.
	 */
	private scanChangeLayers = (action: ConstructorAction) => {
		const currentLayerSequences = this.dependencies.componentTree.getLayerSequences();
		const isNotLayersChanged = Utils.Object.deepEqual(this.snapshot.layerSequences, currentLayerSequences);

		if (isNotLayersChanged) {
			return;
		}

		const undoLayersCommand = new LayerSequencesCommand(
			this.dependencies.componentTree,
			this.snapshot.layerSequences,
		);
		const redoLayersCommand = new LayerSequencesCommand(
			this.dependencies.componentTree,
			currentLayerSequences,
		);

		action.pushRedoCommands(redoLayersCommand);
		action.pushUndoCommands(undoLayersCommand);
	};
}

export default SketchState;
