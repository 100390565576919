import { useEffect, useState } from 'react';

export const useUnbindVisibility = (delay: number, isIconShow: boolean, onUnbind?: (() => void) | undefined) => {
	const [isDeletePermitted, setIsDeletePermitted] = useState(false);

	const handleUnbind = () => {
		if (isDeletePermitted && onUnbind) {
			onUnbind();
			return;
		}
		setIsDeletePermitted(true);
	};

	useEffect(() => {
		if (!isIconShow) setIsDeletePermitted(false);

		if (isDeletePermitted) {
			const timeout = setTimeout(() => {
				setIsDeletePermitted(false);
			}, delay);

			return () => clearTimeout(timeout);
		}

		return () => undefined;
	}, [isIconShow, isDeletePermitted]);

	return {
		isDeletePermitted,
		handleUnbind,
	};
};
