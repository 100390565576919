import CommandType from './CommandType';
import ICommand from './ICommand';

abstract class ManipulatorCommand<Body> {
	public abstract type: CommandType;

	public abstract exec(): void

	public serialize = (): ICommand<any> => ({
		type: this.type,
		body: this.buildBody(),
	});

	protected abstract buildBody(): Body;
}

export default ManipulatorCommand;
