import React, { useEffect, useState } from 'react';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';
import CreateInput from '../../../pages/features/moduleWindow/createInput/CreateInput';
import css from '../../defaultModal.module.scss';
import { MainButton } from '../../../ui/main-button/main-button';

interface OneInputModalProps {
	isModalActive: boolean,
	maxLength?: number,
	onCloseModal: () => void,
	onBtnClick: (
		value: string,
		setInputValue: (value: string) => void
	) => void,
	title: string,
	btnText: string,
	placeholder?: string,
	isDataLoading?: boolean,
}

const OneInputModal: React.FC<OneInputModalProps> = ({
	title,
	btnText,
	maxLength,
	onBtnClick,
	placeholder,
	onCloseModal,
	isModalActive,
	isDataLoading,
}: OneInputModalProps) => {
	OneInputModal.defaultProps = {
		maxLength: 50,
		isDataLoading: false,
		placeholder: '',
	};

	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		if (!isModalActive) {
			setInputValue('');
		}
	}, [isModalActive]);

	const btnClick = () => {
		onBtnClick(inputValue, setInputValue);
	};

	const isBtnActive = inputValue.length > 0;

	useCatchEnterClick(btnClick, isModalActive);

	return (
		<div className={css.wrapper}>
			<div className={css.title}>
				{title}
			</div>
			<CreateInput
				maxLength={maxLength}
				text={inputValue}
				defaultValue={placeholder}
				subTitle="Введите название"
				onChange={setInputValue}
			/>
			<MainButton
				type="button"
				styles={css.btn}
				btnStyle="bright"
				onClick={btnClick}
				disabled={!isBtnActive || isDataLoading}
			>
				{btnText}
			</MainButton>
		</div>
	);
};

export default OneInputModal;
