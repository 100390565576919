import ToolBarTool from '../ToolBarTool';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';

/**
 * Инструмент для открытия порядка листов.
 */
export class ListTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Порядок листов';

	constructor() {
		super('panel-lists__order');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.pagesTool);
	}
}

export default ListTool;
