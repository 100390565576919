import React from 'react';
import css from './create-tag-dropdown.module.scss';
import { ITagData } from '../../../entities/modules/tag/api/types';
import { DropdownItem } from '../../../shared/ui';

interface CreateTageDropdownProps {
	creatingTagName: string,
	data: ITagData[] | undefined,
	createTag: () => void,
	selectedTags?: number[],
	onTagClick: (tag: ITagData) => void,
}

export const CreateTagDropdown = ({
	createTag,
	onTagClick,
	creatingTagName,
	selectedTags,
	data,
}: CreateTageDropdownProps) => {
	const a = '';

	return (
		<div className={css.tag_list}>
			{
				!data?.length && creatingTagName && (
					<DropdownItem
						data={{
							id: 'add-tag',
							name: `Добавить тег: ${creatingTagName}`,
						}}
						onClick={createTag}
						isActive
					/>
				)
			}
			{
				data?.map(item => {
					const preparedData = {
						...item,
						id: item.id.toString(),
					};

					const isActive = selectedTags?.includes(item.id);

					return (
						<DropdownItem
							key={item.id}
							data={preparedData}
							isActive={isActive}
							onClick={() => onTagClick(item)}
						/>
					);
				})
			}
		</div>
	);
};
