import React from 'react';
import css from './choiceBlock.module.scss';
import { ITEMS_ROLES, ITEMS_TEAMS } from './ChoiceBlock';
import { IRoleItem } from '../../entities/role/api/types';
import Role from '../../components/pages/personalAccount/personalData/role/Role';
import { IAccount } from '../../entities/user/account/api/types';

export interface DefineChoiceBlockProps {
	items: typeof ITEMS_TEAMS | typeof ITEMS_ROLES;
	choicedRole?: IRoleItem | undefined,
	choicedUser?: IAccount | undefined | null,
}

const DefineBlock = ({
	choicedRole,
	choicedUser,
	items,
}: DefineChoiceBlockProps) => {
	if (choicedRole && items === ITEMS_ROLES) {
		const {
			id,
			name,
			emoji,
			permission,
			background,
		} = choicedRole;
		return (
			<Role
				text={name}
				emoji={emoji}
				id={id}
				bgColor={background}
				cssClass={css.role}
				permission={permission}
			/>
		);
	}
	if (!choicedRole && items === ITEMS_ROLES) {
		return <div>Выбрать роль</div>;
	}
	if (choicedUser && items === ITEMS_TEAMS) {
		const { name } = choicedUser;
		return <div>{name}</div>;
	}
	return <div>Все пользователи</div>;
};

export default DefineBlock;
