import { useSelector } from 'react-redux';
import {
	workshopTagGroupActions,
	workshopTagGroupApi,
	workshopTagGroupSlice,
} from '../../../../entities/workshop-tag-group';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';

export const useUnbindWorkshopTagFromGroup = () => {
	const dispatch = useAppDispatch();

	const [unbindTag, requestData] = workshopTagGroupApi.useUnbindMutation();

	const groupId = useSelector(workshopTagGroupSlice.selectors.getUnbindingGroupId);
	const tags = useSelector(workshopTagGroupSlice.selectors.getUnbindingTags);

	const unbindTagFromGroup = async () => {
		try {
			if (groupId && tags) {
				await unbindTag({ groupId, tags }).unwrap();

				dispatch(workshopTagGroupActions.setIsUnbindingGroupModalActive(false));
				dispatch(workshopTagGroupActions.setUnbindingGroupId(undefined));
				dispatch(workshopTagGroupActions.setUnbindingTags([]));
			}
		} catch (e) {
			notificationError('Возникла ошибка при удалении тега из группы...');
		}
	};

	return {
		unbindTagFromGroup,
		requestData,
	};
};
