import ToolBarTool from '../ToolBarTool';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';

/**
 * Элемент интерфейса для создания нового компонента линии.
 */
class InjectLineTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Линия / Shift + L';

	constructor() {
		super('panel-components__figure');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.LINE_TOOL);
	}
}

export default InjectLineTool;
