import React from 'react';
import css from './infoBox.module.scss';
import { ReactComponent as DeleteTemplatesIcon } from '../../images/delete-templates.svg';
import { ReactComponent as DuplicateIcon } from '../../images/duplicate.svg';
import { ReactComponent as MoveToTeamIcon } from '../../images/move-to-teams.svg';
import { renderCountWord } from '../../../../../../../helpers/renderCountWord';
import { useAppSelector } from '../../../../../../../shared/libs/redux';

interface InfoBoxProps {
	onMoveTemplates: () => void,
	onDeleteTemplates: () => void,
	onDuplicateTemplates: () => void,
}

export const INFO_BOX_ID = 'template-info-box';

const InfoBox = ({
	onMoveTemplates,
	onDeleteTemplates,
	onDuplicateTemplates,
}: InfoBoxProps) => {
	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const count = renderCountWord(selectedList.length || 0, ['шаблон', 'шаблонов', 'шаблона']);

	return (
		<div id={INFO_BOX_ID} className={css.infoBoxWrapper}>
			<div className={css.infoBox}>
				<div className={css.item}>
					<div
						className={css.icon}
						onClick={onMoveTemplates}
						role="presentation"
					>
						<MoveToTeamIcon />
					</div>
					<div className={css.text}>Переместить в «Шаблоны команды»</div>
				</div>
				<div className={css.item}>
					<div
						className={css.icon}
						onClick={onDuplicateTemplates}
						role="presentation"
					>
						<DuplicateIcon />
					</div>
					<div className={`${css.text} ${css.dynamicText}`}>
						Дублировать
						{' '}
						{count}
					</div>
				</div>
				<div className={css.item}>
					<div
						className={css.icon}
						onClick={onDeleteTemplates}
						role="presentation"
					>
						<DeleteTemplatesIcon />
					</div>
					<div className={css.text}>Удалить</div>
				</div>
			</div>
		</div>
	);
};

export default InfoBox;
