import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITagData } from '../../../entities/modules/tag/api/types';

interface IModalsActive {
	isTagMenuModalActive: boolean,
	isEditingTagModalActive: boolean,
	isDeletingTagModalActive: boolean,
	isCreatingTagModalActive: boolean,
}

interface IInitialState {
	totalCount: number,
	searchValue: string,
	tagsData: ITagData[] | null,
	editingTagId: number | null,
	deletingTagId: number | null,
	modalsActive: IModalsActive,
}

const initialState: IInitialState = {
	totalCount: 0,
	searchValue: '',
	tagsData: null,
	editingTagId: null,
	deletingTagId: null,
	modalsActive: {
		isTagMenuModalActive: false,
		isEditingTagModalActive: false,
		isCreatingTagModalActive: false,
		isDeletingTagModalActive: false,
	},
};

export const tagReducer = createSlice({
	name: 'tag',
	initialState,
	reducers: {
		setTagData: (state, action: PayloadAction<ITagData[]>) => {
			state.tagsData = action.payload;
		},
		setTotalCount: (state, action: PayloadAction<number>) => {
			state.totalCount = action.payload;
		},
		setEditingTagId: (state, action: PayloadAction<number>) => {
			state.editingTagId = action.payload;
		},
		setDeletingTagId: (state, action: PayloadAction<number>) => {
			state.deletingTagId = action.payload;
		},
		setIsDeletingTagModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeletingTagModalActive = action.payload;
		},
		setIsCreatingTagModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isCreatingTagModalActive = action.payload;
		},
		setSearchValue: (state, action: PayloadAction<string>) => {
			state.searchValue = action.payload;
		},
		setIsTagMenuModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isTagMenuModalActive = action.payload;
		},
		setIsEditingTagModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isEditingTagModalActive = action.payload;
		},
	},
});

export const {
	setTotalCount,
	setSearchValue,
	setIsCreatingTagModalActive,
	setIsTagMenuModalActive,
	setIsDeletingTagModalActive,
	setDeletingTagId,
	setEditingTagId,
	setIsEditingTagModalActive,
	setTagData,
} = tagReducer.actions;

export default tagReducer.reducer;
