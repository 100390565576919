import {
	useEffect, useMemo, useRef, useState, 
} from 'react';
import { ReactComponent as ChangeIcon } from '../../../../shared/images/edit-icon.svg';
import css from './edit-template-name.module.scss';
import { measureElemWidth } from '../../../../helpers/measure-elem-width';

interface EditTemplateNameProps {
	name: string;
	editName: (name: string) => Promise<void>,
}

const styles = {
	'font-weight': '600',
	'font-size': '29px',
};

export const EditTemplateName = ({
	name,
	editName,
}: EditTemplateNameProps) => {
	const [updatedName, setUpdatedName] = useState('');
	const [isChanging, setIsChanging] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const handleChanging = () => {
		if (isChanging) {
			setIsChanging(false);
		} else {
			setIsChanging(true);
		}
	};

	const handleBlur = async () => {
		if (updatedName !== name) await editName(updatedName);
		setIsChanging(false);
	};

	useEffect(() => {
		if (isChanging) inputRef.current?.focus();
	}, [isChanging]);

	useEffect(() => {
		setUpdatedName(name);
	}, [name]);

	const width = useMemo(() => measureElemWidth(updatedName, styles) + 10, [updatedName]);

	return (
		<div className={css.edit_name_wrapper}>
			<input
				disabled={!isChanging}
				ref={inputRef}
				style={{ width: `${width}px` }}
				onBlur={handleBlur}
				className={css.input}
				value={updatedName}
				onChange={e => setUpdatedName(e.target.value)}
			/>
			<ChangeIcon onClick={handleChanging} />
		</div>
	);
};
