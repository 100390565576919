import { DoublyLinkedListNode, Fn } from './DoublyLinkedListNode';

export interface INodeList<T> {
    prepend(value: T): DoublyLinkedListNode<T>
    append(value: T): DoublyLinkedListNode<T>
    delete(value: T): DoublyLinkedListNode<T> | null
    find(value?: T | undefined): DoublyLinkedListNode<T> | null
    deleteTail(): DoublyLinkedListNode<T> | null
    deleteHead(): DoublyLinkedListNode<T> | null
    fromArray(values: Array<T>): this
    getElements(): T[]
    toArray(): DoublyLinkedListNode<T>[]
    toString(callback?: Fn): string
    reverse(): this
    moveBack(value: T): void
    moveForward(value: T): void
    moveHead(value: T): void
    moveTail(value: T): void
    length(): number
    getIndex(value: T): number
	addPostDeleteListener(listener: () => void): void
	addPostAppendListener(listener: () => void): void
}

export class DoublyLinkedList<T> implements INodeList<T> {
	private head: DoublyLinkedListNode<T> | null = null;
	private tail: DoublyLinkedListNode<T> | null = null;

	private readonly postDeleteListener: ((node: DoublyLinkedListNode<T>) => void)[];
	private readonly postAppendListener: ((node: DoublyLinkedListNode<T>) => void)[];

	constructor() {
		this.postDeleteListener = [];
		this.postAppendListener = [];
	}

	// Добавляем узел в начало списка.
	public prepend = (value: T): DoublyLinkedListNode<T> => {
		// Создаем новый узел, который будет head.
		const newNode = new DoublyLinkedListNode(value, this.head);

		// Если есть head, то он больше не будет head.
		// Поэтому делаем его предыдущую (previous) ссылку на новый узел (new head).
		// Затем делаем новый узел head.

		if (this.head) {
			this.head.previous = newNode;
		}
		this.head = newNode;

		// Если еще нет tail, сделаем новый узел tail.
		if (!this.tail) {
			this.tail = newNode;
		}

		return newNode;
	};

	// Добавляем узел в конец списка.
	public append(value: T): DoublyLinkedListNode<T> {
		const newNode = new DoublyLinkedListNode(value);

		if (this.tail) {
			// Присоединяем новый узел к концу связанного списка.
			this.tail.next = newNode;
		}

		// Присоединяем текущий tail к предыдущей (previous) ссылке нового узла.
		newNode.previous = this.tail;

		// Переназначаем tail на новый узел.
		this.tail = newNode;

		if (!this.head) {
			this.head = newNode;
		}

		this.postAppendListener.forEach(listener => listener(newNode));

		return newNode;
	}

	// Добавить node после элемента
	public after(value: T, target: T): DoublyLinkedList<T> {
		const targetNode = this.find(target);
		if (!targetNode) return this;

		if (targetNode === this.tail) {
			this.append(value);
			return this;
		}

		const newNode = new DoublyLinkedListNode(value);
		const nextTargetNode = targetNode.next;

		if (!nextTargetNode) return this;

		targetNode.next = newNode;
		newNode.previous = targetNode;
		newNode.next = nextTargetNode;
		nextTargetNode.previous = newNode;

		this.postAppendListener.forEach(listener => listener(newNode));

		return this;
	}

	// Добавить node после targetNode
	public afterNode(value: T, target: DoublyLinkedListNode<T>): DoublyLinkedList<T> {
		if (target === this.tail) {
			this.append(value);
			return this;
		}

		const newNode = new DoublyLinkedListNode(value);
		const nextTargetNode = target.next;

		if (!nextTargetNode) return this;

		const targetNode = target;
		targetNode.next = newNode;
		newNode.previous = target;
		newNode.next = nextTargetNode;
		nextTargetNode.previous = newNode;

		this.postAppendListener.forEach(listener => listener(newNode));

		return this;
	}

	// Добавить node до элемента
	public before(value: T, target: T): DoublyLinkedList<T> {
		const targetNode = this.find(target);
		if (!targetNode) return this;

		if (targetNode === this.head) {
			this.prepend(value);
			return this;
		}

		const newNode = new DoublyLinkedListNode(value);
		const prevTargetNode = targetNode.previous;

		if (!prevTargetNode) return this;

		prevTargetNode.next = newNode;
		newNode.previous = prevTargetNode;
		newNode.next = targetNode;
		targetNode.previous = newNode;

		this.postAppendListener.forEach(listener => listener(newNode));

		return this;
	}

	// Добавить node до targetNode
	public beforeNode(value: T, target: DoublyLinkedListNode<T>): DoublyLinkedList<T> {
		if (target === this.head) {
			this.prepend(value);
			return this;
		}

		const newNode = new DoublyLinkedListNode(value);
		const beforeTargetNode = target.previous;

		if (!beforeTargetNode) return this;
		const targetNode = target;
		targetNode.previous = newNode;
		newNode.next = target;
		newNode.previous = beforeTargetNode;
		beforeTargetNode.next = newNode;

		this.postAppendListener.forEach(listener => listener(newNode));

		return this;
	}

	// Заполнить список
	public fill(value: T, count: number): DoublyLinkedList<T> {
		for (let i = 0; i < count; i++) {
			this.append(value);
		}
		return this;
	}

	public delete(value: T): DoublyLinkedListNode<T> | null {
		if (!this.head) {
			return null;
		}

		let deletedNode: DoublyLinkedListNode<T> | null = null;
		let currentNode = this.head as DoublyLinkedListNode<T> | null;

		while (currentNode) {
			if (currentNode.value === value) {
				deletedNode = currentNode;

				if (deletedNode === this.head) {
					// Если head должен быть удален..

					// Сделать следующий узел, новым head

					this.head = deletedNode.next;

					// Установить в новом head сслыку (previous) на ноль.
					if (this.head) {
						this.head.previous = null;
					}

					// Если все узлы в списке имеют одинаковое значение,
					// которое передается в качестве аргумента,
					// тогда все узлы будут удалены, поэтому tail необходимо обновить.

					if (deletedNode === this.tail) {
						this.tail = null;
					}
				} else if (deletedNode === this.tail) {
					// Если tail должен быть удален.abs
					// Установить tail на предпоследний узел, который станет новым tail.

					this.tail = deletedNode.previous as DoublyLinkedListNode<T>;
					this.tail.next = null;
				} else {
					// Если средний узел будет удален ...
					const previousNode = deletedNode.previous as DoublyLinkedListNode<T>;
					const nextNode = deletedNode.next as DoublyLinkedListNode<T>;

					previousNode.next = nextNode;
					nextNode.previous = previousNode;
				}
			}

			currentNode = currentNode.next;
		}

		if (deletedNode !== null) {
			this.postDeleteListener.forEach(listener => listener(deletedNode!));
		}

		return deletedNode;
	}

	// Удаление элемента по его индексу
	public deleteByIndex(index: number): DoublyLinkedList<T> {
		// проверить не выход ли за длины массива
		const length = this.length();
		if (index < 0 || index > length - 1) {
			console.error('DoublyLinkedList(deleteByIndex): index out of range');
			return this;
		}
		// проверить голова ли
		if (index === 0) {
			this.deleteHead();
			return this;
		}
		// проверить хвост ли
		if (index === length - 1) {
			this.deleteTail();
			return this;
		}
		// перекинуть ссылки
		const list = this.toArray();
		const target = list[index];
		const prev = target.previous;
		const { next } = target;

		if (prev && next) {
			prev.next = next;
			next.previous = prev;
		} else {
			console.error('DoublyLinkedList(deleteByIndex): unknown error');
		}

		this.postDeleteListener.forEach(listener => listener(target));

		return this;
	}

	public find(value?: T | undefined): DoublyLinkedListNode<T> | null {
		if (!this.head) {
			return null;
		}

		let currentNode: DoublyLinkedListNode<T> | null = this.head;

		while (currentNode) {
			// Если указано значение, пробуем сравнить по значению.
			if (value !== undefined && currentNode.value === value) {
				return currentNode;
			}

			currentNode = currentNode.next;
		}

		return null;
	}

	public deleteTail(): DoublyLinkedListNode<T> | null {
		if (!this.tail) {
			return null;
		}

		const deletedTail = this.tail;

		if (this.tail.previous) {
			this.tail = this.tail.previous;
			this.tail.next = null;
		} else {
			this.head = null;
			this.tail = null;
		}

		this.postDeleteListener.forEach(listener => listener(deletedTail));

		return deletedTail;
	}

	public deleteHead(): DoublyLinkedListNode<T> | null {
		if (!this.head) {
			return null;
		}

		const deletedHead = this.head;

		if (this.head.next) {
			this.head = this.head.next;
			this.head.previous = null;
		} else {
			this.head = null;
			this.tail = null;
		}

		this.postDeleteListener.forEach(listener => listener(deletedHead));

		return deletedHead;
	}

	public fromArray = (values: Array<T>): this => {
		values.forEach((value: T) => this.append(value));
		return this;
	};

	public getElements(): T[] {
		const elements: T[] = [];

		let currentNode = this.head;
		while (currentNode) {
			elements.push(currentNode.value);
			currentNode = currentNode.next;
		}

		return elements;
	}

	public toArray(): DoublyLinkedListNode<T>[] {
		const nodes = [];

		let currentNode = this.head;
		while (currentNode) {
			nodes.push(currentNode);
			currentNode = currentNode.next;
		}

		return nodes;
	}

	public toString(callback?: Fn): string {
		return this.toArray()
			.map((node) => node.toString(callback))
			.toString();
	}

	public reverse = (): this => {
		let currNode = this.head;
		let prevNode = null;
		let nextNode = null;

		while (currNode) {
			// Сохраняем следующий и предыдуший узел.
			nextNode = currNode.next;
			prevNode = currNode.previous;

			// Меняем следующий узел текущего узла, чтобы он ссылался с предыдущий узел.
			currNode.next = prevNode;
			currNode.previous = nextNode;

			// Перемещаем узлы prevNode и currNode на один шаг вперед.
			prevNode = currNode;
			currNode = nextNode;
		}

		// Сбрасываем head и tail.
		this.tail = this.head;
		this.head = prevNode;

		return this;
	};

	public moveBack(value: T): void {
		const currentNode = this.find(value);
		if (!currentNode) return;

		const prevNode = currentNode.previous;
		if (!prevNode) return;

		currentNode.value = prevNode.value;
		prevNode.value = value;
	}

	public moveForward(value: T): void {
		const currentNode = this.find(value);
		if (!currentNode) return;

		const nextNode = currentNode.next;
		if (!nextNode) return;

		currentNode.value = nextNode.value;
		nextNode.value = value;
	}

	public moveHead(value: T): void {
		this.delete(value);
		this.prepend(value);
	}

	public moveTail(value: T): void {
		this.delete(value);
		this.append(value);
	}

	public length(): number {
		return this.getElements().length;
	}

	public getIndex(value: T): number {
		return this.getElements().indexOf(value);
	}

	public clear = () => {
		this.head = null;
		this.tail = null;
	};

	public addPostDeleteListener = (listener: (node: DoublyLinkedListNode<T>) => void): void => {
		this.postDeleteListener.push(listener);
	};

	public addPostAppendListener = (listener: (node: DoublyLinkedListNode<T>) => void): void => {
		this.postAppendListener.push(listener);
	};
}
