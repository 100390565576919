// // * апи, которые должны инвалидироваться при изменении данных пользователя (дата рождения, номер телефона, имя)
import { organizationAPI } from '../../entities/organization/api/api';
import { employeeAPI } from '../../entities/employee/api/api';
import { adminAPI } from '../../entities/admin/api/api';
import { managerAPI } from '../../entities/manager/api/api';
import { legalEntityAPI } from '../../entities/legal-entity/api/api';
import { teamAPI } from '../../entities/team/api/api';
import { permissionAPI } from '../../entities/permission/api/api';
import { moduleAPI } from '../../entities/modules/module/api/api';
import { offerAPI } from '../../entities/offer/api/api';
import { accountRolesAPI } from '../../entities/user/role/api/api';
import { teamTemplateAPI } from '../../entities/templates/team/api/api';
import { tariffAPI } from '../../entities/tariff/api/api';
import { statusAPI } from '../../entities/user/status/api/api';
import { galleryAPI } from '../../entities/templates/gallery/api/api';
import { sketchAPI } from '../../entities/sketch/api/api';
import { templatePrivateAPI } from '../../entities/templates/private/api/api';
import { fileAPI } from '../../entities/file/api/api';
import { tagAPI } from '../../entities/modules/tag/api/api';
import { tagGroupAPI } from '../../entities/modules/tag-group/api/api';
import { roleAPI } from '../../entities/role/api/api';
import { templateTagAPI } from '../../entities/templates/tags/api/api';
import { workshopTagAPI } from '../../entities/templates/workshop/tag/api/api';
import { workshopTemplateAPI } from '../../entities/templates/workshop/template/api/api';
import { customTemplateAPI } from '../../entities/templates/custom/api/api';
import { workshopModuleTagApi } from '../../entities/workshop-tag';
import { workshopTagGroupApi } from '../../entities/workshop-tag-group';
import { workshopModuleApi } from '../../entities/workshop-module';
import { workshopCategoryApi } from '../../entities/workshop-category';
import { templateGalleryModuleApi } from '../../entities/template-gallery-module';
import { templateModuleCategoryApi } from '../../entities/template-module-category';

export const updateUserAPIs = [organizationAPI, employeeAPI, adminAPI, managerAPI, legalEntityAPI, teamAPI];

export const apis = [
	moduleAPI,
	customTemplateAPI,
	permissionAPI,
	offerAPI,
	accountRolesAPI,
	teamTemplateAPI,
	statusAPI,
	workshopTagGroupApi,
	galleryAPI,
	sketchAPI,
	templatePrivateAPI,
	fileAPI,
	tagAPI,
	workshopCategoryApi,
	templateModuleCategoryApi,
	tagGroupAPI,
	roleAPI,
	templateGalleryModuleApi,
	templateTagAPI,
	workshopTagAPI,
	workshopModuleApi,
	workshopTemplateAPI,
	tariffAPI,
	workshopModuleTagApi,
	...updateUserAPIs,
];
