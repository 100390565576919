import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import store from '../../../redux/store/store';
import { moduleAPI } from '../../../entities/modules/module/api/api';

export interface OrganizationModuleConstructorProps {
	moduleID: string;
}

const ComponentOrganizationModuleConstructor = (props: OrganizationModuleConstructorProps) => {
	const { moduleID } = props;
	const refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();

	useEffect(() => {
		if (refManipulatorContainer.current === null || refManipulatorContainer.current === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}

		// let manipulator: OrganizationModuleConstructor | null = null;

		store.dispatch(moduleAPI.endpoints?.getModule.initiate({ id: moduleID }))
			.unwrap().then(res => {
				const { module, structure } = res;

				// manipulator = new OrganizationModuleConstructor({
				// 	structure,
				// 	moduleData: module,
				// 	manipulatorContainer: refManipulatorContainer.current as HTMLDivElement,
				// });
			}).catch(() => {
				// TODO показать ошибку загрузки модуля
			});

		return () => {
			// manipulator && manipulator.destruct();
		};
	}, []);

	return <div ref={refManipulatorContainer} className="sketch-manipulator organization-module-constructor" />;
};

export const ComponentOrganizationModuleConstructorWithRouter = () => {
	const { id } = useParams();
	if (id === undefined) {
		return <p>error get sketch id</p>;
	}

	return <ComponentOrganizationModuleConstructor moduleID={id} />;
};
