import React from 'react';
import cx from 'classnames';
import css from './scrollable-filter-content.module.scss';

interface ScrollableFilterContentProps {
	height: number,
	styles?: string,
	emptyData?: boolean,
	children: React.ReactNode,
}

export const ScrollableFilterContent = ({
	height,
	styles,
	children,
	emptyData,
}: ScrollableFilterContentProps) => (
	<div className={cx(css.wrapper, styles)} style={{ height: `${height}px` }}>
		{
			emptyData
				? <div className={css.not_found}>Данных не найдено</div>
				: children
		}
	</div>
);
