import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as GoBackIcon } from '../../../../../../shared/images/collection/go-back-gray-arrow.svg';
import { useTabs } from '../../../../../../shared/libs/useTabs';
import { adminTabsData } from '../../model/tabs-config';
import { CollectionTab } from '../../../../../../shared/ui';
import css from './tabs.module.scss';

export const AdminTabs = () => {
	const navigate = useNavigate();

	const {
		currentTab,
		onSetCurrentTab,
	} = useTabs(adminTabsData);

	return (
		<div className={css.wrapper}>
			{
				adminTabsData.map((tab, i) => {
					const isActive = currentTab === i;

					return (
						<CollectionTab
							index={i}
							tab={tab}
							key={tab.name}
							isActive={isActive}
							setTab={onSetCurrentTab}
						/>
					);
				})
			}
			<div
				role="presentation"
				className={css.go_back_btn}
				onClick={() => navigate('/')}
			>
				<div className={css.content}>
					<GoBackIcon />
					<span>Вернуться назад</span>
				</div>
			</div>
		</div>
	);
};
