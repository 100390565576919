import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { ReactComponent as FindIcon } from '../../images/find-icon.svg';
import css from './scroll-search-input.module.scss';
import { rightAppearanceTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';

interface TagGroupsScrollSearchInputProps {
	searchValue: string,
	isSearchBlockVisible: boolean,
	setIsSearchBlockVisible: (value: boolean) => void,
	setSearchValue: (value: string) => void,
}

const TIMEOUT_MS = 200;

export const TagGroupsScrollSearchInput = ({
	searchValue,
	setSearchValue,
	isSearchBlockVisible,
	setIsSearchBlockVisible,
}: TagGroupsScrollSearchInputProps) => {
	const searchBlockRef = useRef(null);
	const inputRef = useRef<HTMLInputElement>(null);

	const onFindIconClick = () => {
		if (!isSearchBlockVisible) {
			setIsSearchBlockVisible(true);
		}
	};

	// * При закрытии блока выбора
	const onCloseSearchBlock = () => {
		setIsSearchBlockVisible(false);
	};

	// useOutsideClick(searchBlockRef, onCloseSearchBlock, true);
	// const ref = useOutSideClick(onCloseSearchBlock);

	useEffect(() => {
		if (isSearchBlockVisible) {
			const focusing = () => inputRef.current?.focus();

			setTimeout(focusing, TIMEOUT_MS);
		}
	}, [isSearchBlockVisible]);

	return (
		<div
			// ref={ref}
			className={css.search_block}
		>
			<CSSTransition
				timeout={TIMEOUT_MS}
				unmountOnExit
				nodeRef={inputRef}
				in={isSearchBlockVisible}
				classNames={rightAppearanceTransitionClasses}
			>
				<input
					ref={inputRef}
					value={searchValue}
					className={css.input}
					onChange={e => setSearchValue(e.target.value)}
				/>
			</CSSTransition>
			<div
				role="presentation"
				onClick={onFindIconClick}
				className={cx(css.find_icon, (searchValue || isSearchBlockVisible) && css.active_icon)}
			>
				<FindIcon />
			</div>
		</div>
	);
};
