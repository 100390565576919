import { useSelector } from 'react-redux';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { workshopModuleActions, workshopModuleApi } from '../../../../../entities/workshop-module';
import {
	createWorkshopModuleActions,
	createWorkshopModuleSlice,
} from '../../create-module/slice/createWorkshopModuleSlice';
import store from '../../../../../redux/store/store';

export const useCreateWorkshopModule = () => {
	const dispatch = useAppDispatch();

	const [createModule, requestData] = workshopModuleApi.useCreateWorkshopModuleMutation();

	const structure = useSelector(createWorkshopModuleSlice.selectors.getStructure);
	const selectedCategoriesIds = useSelector(createWorkshopModuleSlice.selectors.getSelectedCategoriesIds);
	const name = useSelector(createWorkshopModuleSlice.selectors.getName);
	const description = useSelector(createWorkshopModuleSlice.selectors.getDescription);
	const selectedTagsIds = useSelector(createWorkshopModuleSlice.selectors.getSelectedTagsIds);
	const selectedLegalEntityId = useSelector(createWorkshopModuleSlice.selectors.getSelectedLegalEntitiesIds);
	const preview = useSelector(createWorkshopModuleSlice.selectors.getPreview);

	const onCreateModule = async () => {
		if (structure) {
			try {
				const preparedTags = selectedTagsIds.map(id => Number(id));
				const preparedCategories = selectedCategoriesIds.map(id => Number(id));

				const data = {
					name,
					tags: preparedTags,
					description,
					preview,
					rootComponent: structure,
					categories: preparedCategories,
					legalEntities: selectedLegalEntityId,
				};

				try {
					await createModule(data)
						.unwrap();
					dispatch(createWorkshopModuleActions.setIsWindowOpen(false));

					// dispatch(templateModuleActions.setIsTemplateModuleActive(true));
					store.dispatch(workshopModuleActions.setIsModulesModalActive(true));
				} catch (err) {
					notificationError(
						ERROR_MESSAGE_LIST.ERROR,
						'Мы уже устраняем эту проблему!',
					);
				}
			} catch (err) {
				notificationError(
					'Что-то пошло не так...',
					'Мы уже устраняем эту проблему!',
				);
			}
		}
	};

	return { requestData, onCreateModule };
};
