import { TokenType } from '../../mechanics/mext/parser';
import Utils from './Utils';
import ISketchStructure from '../../ISketchStructure';
import { AnyComponentStructure } from '../../Types';
import IComponentFactory from '../../factories/component/IComponentFactory';
import IGraphicFactory from '../../factories/graphic/IGraphicFactory';
import StaticGraphicFactory from '../../factories/graphic/StaticGraphicFactory';
import IComponent from '../../components/IComponent';
import DynamicComponentFactory from '../../factories/component/DynamicComponentFactory';
import {
	FontFamily, FontSize, Model, TextAlign,
} from '../../mechanics/mext/editor/types';
import SketchComponentType from '../../components/SketchComponentType';

class Component {
	private readonly graphicFactory: IGraphicFactory;
	private readonly componentFactory: IComponentFactory;

	constructor() {
		this.graphicFactory = new StaticGraphicFactory();
		this.componentFactory = new DynamicComponentFactory();
	}

	/**
	 * Возвращает структуру скетча с обновленными идентификаторами компонентов и графики, а также id скетча.
	 * @param structure Структура для обновления.
	 */
	public regenerateSketchStructure = (structure: ISketchStructure): ISketchStructure => {
		const updatedStructure = { ...structure };
		updatedStructure.id = Utils.Generate.UUID4();
		updatedStructure.root = this.regenerateComponentStructureIdentities(updatedStructure.root);

		return updatedStructure;
	};

	public getSomeComponentType = (components: IComponent[]): SketchComponentType | null => {
		const firstType = components[0].type;
		const isSomeComponents = components.some(component => component.type === firstType);
		return isSomeComponents ? firstType : null;
	};

	/**
	 * Возвращает стандартную модель текста.
	 */
	public getDefaultTextModel = (): Model => ({
		id: Utils.Generate.UUID4(),
		tokens: [{
			type: TokenType.Text,
			value: '',
			fontSize: FontSize.Pt12,
			fontFamily: FontFamily.Default,
			color: '#000000',
			format: 0,
			textAlign: TextAlign.LEFT,
			sticky: true,
			lineHeight: 1,

		}],
		lineHeight: 1,
	});

	/**
	 * Обновляет все идентификаторы во всей структуре компонента и его вложениях.
	 * @param structure - Обновляемая структура.
	 */
	public regenerateComponentStructureIdentities = (structure: AnyComponentStructure): AnyComponentStructure => {
		const component = this.generateComponent(structure);
		this.recursiveGenerateComponent(component, structure);
		return component.getUniqueStructure();
	};

	private recursiveGenerateComponent = (parent: IComponent, structure: AnyComponentStructure) => {
		structure.components?.forEach(componentStructure => {
			const component = this.generateComponent(componentStructure);
			parent.appendComponent(component);
			this.recursiveGenerateComponent(component, componentStructure);
		});
	};

	private generateComponent = (structure: AnyComponentStructure): IComponent => {
		const component = this.componentFactory.getClearComponent(structure.type);
		component.setStructure(() => structure);

		structure.graphics?.forEach(graphicStructure => {
			const graphic = this.graphicFactory.getClearGraphic(graphicStructure.type);
			graphic.setStructure(() => graphicStructure);
			component.appendGraphic(graphic);
		});

		return component;
	};
}

export default Component;
