import Graphic from '../Graphic';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import ITableGraphicTexture from './ITableGraphicTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import TableCellsLayer from './cells/TableCellsLayer';
import TableComponent from '../../components/table/TableComponent';
import { AnySpatialArea } from '../../Types';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import SpatialTableColumnResizeArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableColumnResizeArea';
import IFrameArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import TableCell from './cells/TableCell';
import SpatialTableRowResizeArea
	from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableRowResizeArea';
import SketchComponentType from '../../components/SketchComponentType';

/**
 * Графика таблицы.
 */
class TableGraphic extends Graphic<ITableGraphicTexture> {
	public readonly type: GraphicType = GraphicType.TABLE;

	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-table';

	protected readonly tableCellsLayer: TableCellsLayer;

	constructor() {
		super();
		this.tableCellsLayer = new TableCellsLayer(this);

		const cellLayerElement = this.tableCellsLayer.getElement();
		this.graphicElement.append(cellLayerElement);

		this.graphicElement.classList.add(this.GRAPHIC_CLASS_NAME);
	}

	public renderCellLayer = () => {
		this.tableCellsLayer.render();
	};

	/**
	 * Возвращает ячейки, которые в данный момент отрисованы внутри слоя ячеек.
	 */
	public getCells = (): TableCell[] => this.tableCellsLayer.getCurrentRenderCells();

	public setColumnMultipliers = (multipliers: number[]) => {
		this.tableCellsLayer.setColumnsMultipliers(multipliers);
		this.tableCellsLayer.setColumns(multipliers.length);
	};

	public setRowsMultipliers = (multipliers: number[] | null) => {
		if (multipliers === null) {
			return;
		}

		this.tableCellsLayer.setRowsMultipliers(multipliers);
	};

	public setStartRow = (startRow: number) => {
		this.tableCellsLayer.setStartRow(startRow);
	};

	public getStartRow = (): number => this.tableCellsLayer.getStartRow();

	public setRowCount = (count: number) => {
		this.tableCellsLayer.setRowCount(count);
	};

	public setBorderColor = (color: string) => {
		this.tableCellsLayer.setBorderColor(color);
	};

	public getColumnMultipliers = (): number[] => this.tableCellsLayer.getColumnMultipliers();

	public getRowsMultipliers = (): number[] => this.tableCellsLayer.getRowsMultipliers();

	public getRowsCount = (): number => this.tableCellsLayer.getRowCount();

	public getTexture = (): ITableGraphicTexture => {
		if (this.parentComponent === null) {
			return {
				rowCount: 0,
			};
		}

		return {
			rowCount: this.tableCellsLayer.getRowCount(),
		};
	};

	public setStructure = (
		fn: (prev: IGraphicStructure<ITableGraphicTexture>) => IGraphicStructure<ITableGraphicTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		this.id = id;
		this.offset = offset;
		if (texture === null) {
			throw new ManipulatorError('table graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('table graphic frame cannot be null');
		}

		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public setTexture = (fn: (prev: ITableGraphicTexture) => ITableGraphicTexture): void => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);
		const {
			rowCount,
		} = updatedTexture;

		this.tableCellsLayer.setRowCount(rowCount);
	};

	/**
	 * Возвращает все пространственные области, связанные с графикой таблицы.
	 * которые отвечают за изменение размеров столбцов и строк
	 */
	public getSpatialAreas = (): AnySpatialArea[] => {
		const areas: AnySpatialArea[] = [];

		const graphicArea = new SpatialGraphicArea(this);	// Область графики самой таблицы
		const resizeAreas = this.getResizeAreas();	// Области, за которые изменяем размер таблицы
		const columnMultipliers = this.getColumnMultipliers();	// Множители для колонок
		// const rowMultipliers = this.getRowsMultipliers();	// Множители для строк
		// const { startRow, rowCount } = this.getTexture();

		if (this.parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}

		areas.push(graphicArea, ...resizeAreas);
		for (let i = 0; i < columnMultipliers.length; i++) {
			areas.push(new SpatialTableColumnResizeArea(this.parentComponent as TableComponent, this, i));
		}
		// for (let i = startRow; i < startRow + rowCount; i++) {
		// 	areas.push(new SpatialTableRowResizeArea(this.parentComponent as TableComponent, this, i));
		// }

		return areas;
	};

	public getUniqueTexture = (): ITableGraphicTexture => this.getTexture();

	public override mutateFrameArea = (fn: (prev: IFrameArea) => IFrameArea): void => {
		const currentFrameArea = this.getFrameConfiguration();
		const updatedFrameArea = fn(currentFrameArea);
		this.setFrameConfiguration(prev => ({
			...prev,
			...updatedFrameArea,
			y: this.offset > 0 ? prev.y : updatedFrameArea.y,
		}));
	};

	public getDefaultRowHeight = () => {
		const parentComponent = this.getParentComponent();
		if (parentComponent === null) {
			throw new ManipulatorError('parent component not found');
		}
		if (parentComponent.type !== SketchComponentType.TABLE) {
			throw new ManipulatorError('parent component is not table');
		}

		return (parentComponent as TableComponent).getDefaultRowHeight();
	};

	protected startMutation = (): void => {
		this.tableCellsLayer.enableMutationMode();
	};

	protected finishMutation = (): void => {
		this.tableCellsLayer.disableMutationMode();
	};
}

export default TableGraphic;
