import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { teamAPI } from '../../../../entities/team/api/api';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { setIsTeamConfirmDeleteModalActive } from '../../../../redux/reducers/teamReducer';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useDeleteTeam = () => {
	const dispatch = useAppDispatch();

	const {
		deletingTeamId,
		isTeamConfirmDeleteModalActive,
	} = useAppSelector(state => state.team);

	const handleIsTeamConfirmDeleteModalActive = (value: boolean) => {
		dispatch(setIsTeamConfirmDeleteModalActive(value));
	};

	const [del] = teamAPI.useDeleteMutation();

	// * Удаление пользователя из команды
	const deleteTeam = async () => {
		if (deletingTeamId) {
			try {
				await del({ id: deletingTeamId })
					.unwrap();
				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				handleIsTeamConfirmDeleteModalActive(false);
				notificationSuccess('Вы успешно удалили команду!');
			} catch (err) {
				notificationError('Произошла ошибка при удалении команды...');
			}
		}
	};

	return {
		deleteTeam,
		isTeamConfirmDeleteModalActive,
		handleIsTeamConfirmDeleteModalActive,
	};
};
