import React from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import Loader from 'react-ts-loaders';
import { TemplateCard } from '../../../../../../../../widgets/templates/template-card';
import { GalleryTemplateContextMenu } from './context-menu/context-menu';
import useContextMenu from '../../../../../../../../hooks/mouse/useContextMenu';
import { useGalleryContextMenuActions } from '../../utils/useGalleryContextMenuActions';
import { IGalleryTemplate } from '../../../../../../../../entities/templates/gallery/model/types';
import { TemplateInfoIcon } from '../../../../../../../../shared/ui';
import {
	ReactComponent as TemplateLockIcon,
} from '../../../../../../../../shared/images/collection/template/template-lock.svg';
import { ReactComponent as TemplateUnlockIcon } from '../../images/template-unlock.svg';
import css from './gallery-template.module.scss';
import { useGalleryTemplateLocked } from '../../utils/useGalleryTemplateLocked';

interface GalleryTemplateProps {
	data: IGalleryTemplate;
	isLoading: boolean;
	isGlobalLoading: boolean;
	setGlobalLoading: (templateId: string | null) => void;
}

export const GalleryTemplate = ({
	data, isLoading, isGlobalLoading, setGlobalLoading,
}: GalleryTemplateProps) => {
	const { gallery, ...template } = data;
	const { id } = template;
	const navigate = useNavigate();

	const { isActionMenuOpened, setIsActionMenuOpened, onMoveTemplate } = useGalleryContextMenuActions(id);

	const onGalleryTemplateClick = async () => {
		if (!isLoading && !isGlobalLoading) {
			setGlobalLoading(id);
			setIsActionMenuOpened(false);
			try {
				const data = await onMoveTemplate();
				if (data) {
					navigate(`/constructor/${data.templates[0].id}`);
				}
			} finally {
				setGlobalLoading(null);
			}
		}
	};

	const {
		menuRef, menuPosition, handleItemClick, wrapperRef,
	} = useContextMenu({
		setMenuVisible: setIsActionMenuOpened,
	});

	const { isLocked, description } = useGalleryTemplateLocked(gallery.tariffPermission);

	return (
		<div
			ref={wrapperRef}
			role="presentation"
			className={cx(css.wrapper, { [css.disabled]: isGlobalLoading })}
			onClick={!isGlobalLoading ? onGalleryTemplateClick : undefined}
			onContextMenu={!isGlobalLoading ? handleItemClick : undefined}
		>
			{
				!!gallery.tariffPermission && isLocked && (
					<TemplateInfoIcon type="bright">
						<TemplateLockIcon />
					</TemplateInfoIcon>
				)
			}
			{
				!!gallery.tariffPermission && !isLocked && (
					<TemplateInfoIcon type="light">
						<TemplateUnlockIcon />
					</TemplateInfoIcon>
				)
			}
			<TemplateCard
				selected={false}
				template={template}
				styles={cx(gallery.tariffPermission && css.locked)}
				description={isLocked && description.length > 0 ? description : ''}
				isEditableTitle={false}
			>
				{
					isLoading && <Loader className={css.loader} type="ring" color="#d8d7d7" size={80} />
				}
			</TemplateCard>
			<GalleryTemplateContextMenu
				menuRef={menuRef}
				menuPosition={menuPosition}
				onMoveTemplate={onGalleryTemplateClick}
				setIsActionMenuOpened={setIsActionMenuOpened}
				isActionMenuOpened={isActionMenuOpened}
			/>
		</div>
	);
};
