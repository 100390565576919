import React from 'react';
import css from './logo.module.scss';

interface LogoProps {
    isPasswordSent: boolean,
}

const Logo: React.FC<LogoProps> = ({
	isPasswordSent,
}: LogoProps) => (
	<div className={css.logo}>
		<svg width="180" height="37" viewBox="0 0 180 37" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M78.2428 12.9919C77.9988 13.9486 77.7425 14.9252 77.47 15.9178C77.1975 16.9143 76.9209 17.883
			76.6362 18.8317C76.3514 19.7764 76.0789 20.6454 75.8186 21.4347C75.5176 20.5298 75.2207 19.5931 74.9319
			18.6244C74.639 17.6598 74.3706 16.6911 74.1184 15.7264C73.8662 14.7618 73.6303 13.821 73.4106
			12.9042C73.191 11.9874 72.9876 11.1263 72.8086 10.3171H70.002C69.819 11.1263 69.6156 11.9874 69.3838
			12.9042C69.1519 13.821 68.9038 14.7618 68.6435 15.7264C68.3832 16.6911 68.1025 17.6598 67.8137
			18.6244C67.5208 19.5891 67.2239 20.5259 66.9229 21.4347C66.6382 20.6454 66.3575 19.7804 66.0728
			18.8317C65.7881 17.887 65.5156 16.9143 65.2552 15.9178C64.9909 14.9212 64.7387 13.9486 64.4987
			12.9919C64.2546 12.0352 64.0431 11.1423 63.8641 10.3171H60C60.423 11.8359 60.8542 13.3227 61.2975
			14.7817C61.7409 16.2407 62.1843 17.6319 62.6276 18.9513C63.071 20.2707 63.5143 21.5144 63.9577
			22.6784C64.4011 23.8424 64.8241 24.8748 65.2268 25.7837H68.2245C68.7452 24.3247 69.274 22.7741 69.8068
			21.1278C70.3396 19.4815 70.8359 17.7913 71.2996 16.0573C71.7633 17.7913 72.2676 19.4815 72.8208 
			21.1278C73.374 22.7741 73.9109 24.3247 74.4316 25.7837H77.4009C77.8239 24.8788 78.255 23.8424 78.6984 
			22.6784C79.1418 21.5144 79.5851 20.2747 80.0285 18.9513C80.4718 17.6319 80.9152 16.2407 81.3586 
			14.7817C81.8019 13.3227 82.2331 11.8359 82.6561 10.3171H78.8855C78.6943 11.1423 78.4828 12.0352 78.2428 
			12.9919ZM94.9115 11.6166C94.4194 11.0825 93.7604 10.6719 92.9347 10.3729C92.109 10.0779 91.0962 9.93046 
			89.8881 9.93046C88.8835 9.93046 87.9357 10.0102 87.0531 10.1656C86.1664 10.3251 85.4952 10.4806 85.0315 
			10.64L85.483 13.538C85.9264 13.3785 86.4999 13.243 87.2036 13.1234C87.9073 13.0038 88.6801 12.944 89.5261 
			12.944C90.1485 12.944 90.6691 13.0238 91.0799 13.1792C91.4907 13.3387 91.8243 13.5579 92.0765 
			13.8449C92.3287 14.1319 92.5036 14.4628 92.6053 14.8335C92.7069 15.2082 92.7557 15.6029 92.7557 
			16.0174V16.6074C92.2514 16.4878 91.7795 16.4041 91.3362 16.3563C90.8928 16.3084 90.4698 16.2805 90.0671 
			16.2805C89.2007 16.2805 88.371 16.3642 87.5778 16.5317C86.7846 16.6991 86.085 16.9741 85.483 17.3608C84.881 
			17.7435 84.4011 18.2417 84.0513 18.8556C83.6974 19.4655 83.5225 20.2149 83.5225 21.1039C83.5225 22.0287 
			83.677 22.81 83.9902 23.4398C84.3034 24.0696 84.7346 24.5838 85.2878 24.9785C85.841 25.3731 86.508 25.6601 
			87.2931 25.8355C88.0781 26.0149 88.9404 26.1026 89.8881 26.1026C91.3158 26.1026 92.5971 26.0189 93.736 
			25.8515C94.8709 25.684 95.721 25.5405 96.2864 25.421V16.0493C96.2864 15.1444 96.1806 14.3113 95.9691 
			13.55C95.7576 12.7926 95.4037 12.1468 94.9115 11.6166ZM92.7557 23.089C92.1741 23.2086 91.3158 23.2684 
			90.1932 23.2684C89.2699 23.2684 88.5337 23.105 87.9927 22.7821C87.4476 22.4552 87.1792 21.8692 87.1792 
			21.0241C87.1792 20.5896 87.2809 20.2309 87.4802 19.9439C87.6795 19.6569 87.9479 19.4376 88.2815 
			19.2782C88.615 19.1187 88.9852 19.0111 89.396 18.9513C89.8068 18.8915 90.2135 18.8636 90.6162 
			18.8636C91.0799 18.8636 91.503 18.8915 91.8853 18.9513C92.2676 19.0111 92.5605 19.0709 92.7598 
			19.1307V23.089H92.7557ZM110.864 21.2235C110.242 20.4342 109.599 19.6927 108.936 18.9912C108.273 18.2936 
			107.643 17.6956 107.041 17.2013C108.164 16.0772 109.282 14.9252 110.397 13.7413C111.511 12.5574 112.569 
			11.4173 113.573 10.3131H109.258C108.936 10.6878 108.55 11.1263 108.094 11.6286C107.643 12.1309 107.163 
			12.6531 106.663 13.1952C106.158 13.7373 105.646 14.2794 105.125 14.8216C104.6 15.3637 104.108 15.8699 
			103.649 16.3443V2.83496L99.9959 3.42492V25.7757H103.644V18.6802C104.19 19.0749 104.739 19.5532 105.304 
			20.1153C105.865 20.6773 106.419 21.2753 106.964 21.9051C107.509 22.5349 108.013 23.1847 108.489 
			23.8584C108.961 24.528 109.38 25.1698 109.742 25.7797H113.964C113.602 25.11 113.15 24.3805 112.609 
			23.5913C112.068 22.802 111.487 22.0127 110.864 21.2235ZM126.134 11.6166C125.642 11.0825 124.983 10.6719 
			124.157 10.3729C123.331 10.0779 122.318 9.93046 121.11 9.93046C120.106 9.93046 119.158 10.0102 118.275 
			10.1656C117.389 10.3251 116.718 10.4806 116.254 10.64L116.705 13.538C117.149 13.3785 117.722 13.243 118.426 
			13.1234C119.13 13.0038 119.902 12.944 120.748 12.944C121.371 12.944 121.891 13.0238 122.302 13.1792C122.713 
			13.3387 123.047 13.5579 123.299 13.8449C123.551 14.1319 123.726 14.4628 123.828 14.8335C123.929 15.2082 
			123.978 15.6029 123.978 16.0174V16.6074C123.474 16.4878 123.002 16.4041 122.558 16.3563C122.115 16.3084 
			121.692 16.2805 121.289 16.2805C120.423 16.2805 119.593 16.3642 118.8 16.5317C118.007 16.6991 117.307 
			16.9741 116.705 17.3608C116.103 17.7435 115.623 18.2417 115.274 18.8556C114.92 19.4655 114.745 20.2149 
			114.745 21.1039C114.745 22.0287 114.899 22.81 115.213 23.4398C115.526 24.0696 115.957 24.5838 116.51 
			24.9785C117.063 25.3731 117.73 25.6601 118.515 25.8355C119.3 26.0149 120.163 26.1026 121.11 26.1026C122.538 
			26.1026 123.819 26.0189 124.958 25.8515C126.093 25.684 126.943 25.5405 127.509 25.421V16.0493C127.509 
			15.1444 127.403 14.3113 127.191 13.55C126.98 12.7926 126.63 12.1468 126.134 11.6166ZM123.978 23.089C123.396 
			23.2086 122.538 23.2684 121.416 23.2684C120.492 23.2684 119.756 23.105 119.215 22.7821C118.67 22.4552 
			118.401 21.8692 118.401 21.0241C118.401 20.5896 118.503 20.2309 118.702 19.9439C118.902 19.6569 119.17 
			19.4376 119.504 19.2782C119.837 19.1187 120.207 19.0111 120.618 18.9513C121.029 18.8915 121.436 18.8636 
			121.839 18.8636C122.302 18.8636 122.725 18.8915 123.104 18.9513C123.486 19.0111 123.779 19.0709 123.978 
			19.1307V23.089ZM140.903 10.8473C140.48 10.632 139.98 10.4327 139.394 10.2573C138.812 10.0779 138.137 
			9.99025 137.372 9.99025C136.246 9.99025 135.249 10.1816 134.387 10.5683C133.52 10.9509 132.788 11.501 
			132.186 12.2106C131.584 12.9201 131.125 13.7732 130.816 14.7697C130.502 15.7663 130.348 16.8745 130.348 
			18.0943C130.348 19.338 130.535 20.4541 130.905 21.4507C131.275 22.4472 131.812 23.2923 132.504 
			23.9939C133.199 24.6955 134.037 25.2296 135.021 25.6043C136.006 25.979 137.124 26.1664 138.369 
			26.1664C139.577 26.1664 140.728 26.0787 141.822 25.8993C142.916 25.7199 143.828 25.5246 144.552 
			25.3093V2.83496L140.903 3.42492V10.8473ZM140.903 22.8219C140.643 22.8817 140.305 22.9375 139.894 
			22.9854C139.483 23.0332 138.983 23.0611 138.401 23.0611C137.055 23.0611 135.998 22.6146 135.233 
			21.7177C134.468 20.8208 134.086 19.5851 134.086 18.0066C134.086 16.4878 134.391 15.288 135.005 
			14.399C135.619 13.5101 136.587 13.0676 137.917 13.0676C138.519 13.0676 139.089 13.1633 139.622 
			13.3467C140.155 13.534 140.582 13.7373 140.903 13.9526V22.8219ZM160.602 12.1468C159.939 11.4373 
			159.138 10.8911 158.202 10.5045C157.267 10.1218 156.246 9.92647 155.139 9.92647C154.033 9.92647 
			153.016 10.1178 152.093 10.5045C151.169 10.8872 150.368 11.4373 149.693 12.1468C149.018 12.8563 148.497 
			13.7094 148.123 14.706C147.749 15.7025 147.566 16.8107 147.566 18.0305C147.566 19.2543 147.753 20.3664 
			148.123 21.3709C148.493 22.3755 149.014 23.2325 149.677 23.9421C150.34 24.6516 151.133 25.2017 152.06 
			25.5963C152.984 25.991 154.013 26.1863 155.139 26.1863C156.266 26.1863 157.295 25.991 158.231 
			25.5963C159.166 25.2017 159.963 24.6516 160.63 23.9421C161.293 23.2325 161.806 22.3755 162.168 
			21.3709C162.53 20.3664 162.713 19.2543 162.713 18.0305C162.713 16.8067 162.526 15.6985 162.156 
			14.706C161.782 13.7094 161.265 12.8563 160.602 12.1468ZM157.962 21.7257C157.287 22.6306 156.347 23.085 
			155.143 23.085C153.935 23.085 152.996 22.6306 152.325 21.7257C151.649 20.8208 151.316 19.5891 151.316 
			18.0305C151.316 16.4918 151.653 15.276 152.325 14.3791C153 13.4822 153.939 13.0357 155.143 13.0357C156.351 
			13.0357 157.291 13.4862 157.962 14.3791C158.637 15.276 158.971 16.4918 158.971 18.0305C158.975 19.5891 
			158.637 20.8208 157.962 21.7257ZM179.443 14.706C179.069 13.7094 178.552 12.8563 177.889 12.1468C177.226 
			11.4373 176.425 10.8911 175.489 10.5045C174.554 10.1178 173.533 9.92647 172.426 9.92647C171.32 9.92647 
			170.303 10.1178 169.38 10.5045C168.456 10.8872 167.655 11.4373 166.98 12.1468C166.305 12.8563 165.784 
			13.7094 165.41 14.706C165.036 15.7025 164.853 16.8107 164.853 18.0305C164.853 19.2543 165.04 20.3664 165.41
			 21.3709C165.78 22.3755 166.301 23.2325 166.964 23.9421C167.627 24.6516 168.42 25.2017 169.347 
			 25.5963C170.27 25.991 171.3 26.1863 172.426 26.1863C173.553 26.1863 174.582 25.991 175.518 25.5963C176.453 
			 25.2017 177.25 24.6516 177.917 23.9421C178.58 23.2325 179.093 22.3755 179.455 21.3709C179.817 20.3664 180 
			 19.2543 180 18.0305C180 16.8107 179.813 15.7025 179.443 14.706ZM175.249 21.7257C174.574 22.6306 173.634
			  23.085 172.43 23.085C171.226 23.085 170.283 22.6306 169.612 21.7257C168.936 20.8208 168.603 19.5891 
			  168.603 18.0305C168.603 16.4918 168.94 15.276 169.612 14.3791C170.287 13.4822 171.226 13.0357 172.43 
			  13.0357C173.634 13.0357 174.578 13.4862 175.249 14.3791C175.924 15.276 176.258 16.4918 176.258 
			  18.0305C176.258 19.5891 175.92 20.8208 175.249 21.7257Z" 
				fill="black"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.1263 3.56735C31.8733 2.62436 30.0158 2.01025 27.7808 
			2.01025C27.0821 2.01025 26.345 2.07137 25.5784 2.19652C23.7445 2.49921 22.2113 3.44802 20.5985 
			4.52489C18.9916 5.58721 17.3198 6.76304 15.1704 7.25491C14.4186 7.42372 13.6726 7.51976 12.9473 
			7.51976C10.4824 7.51685 8.25931 6.6961 6.63768 5.89572C6.33989 5.7502 6.06568 5.60177 5.80917 
			5.45915C6.51679 8.72469 9.34138 11.3761 12.4667 11.7021C8.96104 12.3278 6.51384 10.3254 5.13988 
			8.50932C4.30252 11.0181 5.95954 13.8762 7.3512 15.0258C8.73696 16.1609 10.1109 17.3659 12.4903 
			17.6976C12.4903 17.6976 11.8829 20.5295 3.875 23.3061C4.68287 24.5984 7.76398 25.5705 9.41215 
			25.5705C10.1375 25.5734 10.624 25.7334 12.7969 25.2328L12.8942 31.112L19.8997 27.4768C20.8285 30.8297 
			22.1847 32.6225 22.7951 33.4869L25.8585 27.6689L24.8855 23.7863C24.7027 23.0325 24.859 22.2467 25.313 
			21.6268C25.7759 20.9981 26.4865 20.6139 27.2914 20.5499H27.3003H27.3091L28.0698 20.4859C28.5622 14.8279 
			29.8713 11.4955 31.4664 9.5047C32.6045 8.06984 34.0197 7.74096 35.146 7.46446C35.1224 6.05289 34.3617 
			4.50743 33.1263 3.56735Z" 
				fill="#FFAE00"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M40.6616 11.4254C39.4203 9.79843 37.8694 9.06208 36.236 
			9.06791C35.3633 9.08828 34.066 9.21052 32.7657 10.7938C31.5421 12.3014 29.8467 15.2003 29.375 
			20.6021C35.2689 22.2989 35.8674 27.3689 35.9471 27.7123C36.7992 27.1797 38.0434 26.2891 39.1756 
			25.1744C41.0685 23.3117 42.7697 20.6516 42.7609 17.6829C42.7638 15.3545 41.897 13.0436 40.6616 11.4254Z" 
				fill="#353535"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.5718 20.91C44.9261 19.8797 43.732 20.3221 42.8356 
			20.715C41.9393 21.1079 40.3501 24.9847 38.4101 27.1937C36.47 29.4028 36.0218 29.1088 35.0783 25.721C34.1348 
			22.3332 30.3048 22.0393 28.3146 21.6464C26.3274 21.2535 26.2773 23.2646 26.5279 23.902C26.7756 24.5394 
			26.3303 25.0312 26.6783 27.8777C27.0262 30.7241 29.1638 33.9635 29.9097 34.1585C30.6557 34.3535 43.8322 
			32.7847 45.4745 32.4908C47.1168 32.1968 49.3045 31.5594 49.4018 30.1362C49.4991 28.713 46.2175 21.9403 
			45.5718 20.91Z" 
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50.3709 29.2925L46.936 21.0646C46.709 20.5204 46.2992 
			20.043 45.7832 19.7142C45.4146 19.4813 45.0019 19.3329 44.5861 19.2747C44.5861 19.2747 45.7301 15.1622 
			42.4721 10.4763C41.1866 8.6311 38.8691 7.35341 36.6961 7.14095C36.6755 4.88243 35.7644 3.37772 34.1575 
			2.1786C32.4946 0.938746 30.2332 0.240234 27.6621 0.240234C26.8543 0.240234 26.0169 0.310086 25.156 
			0.452698C22.8444 0.83979 21.0606 1.99233 19.4537 3.06047C17.8409 4.14026 16.3903 5.12399 14.7864 
			5.48198C14.2055 5.61295 13.6247 5.67116 13.0468 5.67116C11.2099 5.67407 9.41433 5.04541 8.01088 
			4.41384C5.23051 3.16816 4.06883 1.65472 4.06883 1.65472C3.49389 2.72868 3.48799 4.60011 3.77399
			 6.35221C3.26981 7.7667 2.85997 9.1317 2.85997 10.325C2.85113 13.2005 5.35434 17.0191 9.5647 
			 18.4335C4.84722 21.3527 0 22.1619 0 22.1619C3.40543 28.4339 10.3726 27.6423 10.8443 27.5928L10.5259 
			 35.1163L18.7962 29.9852C19.9284 33.6407 23.1805 36.9878 23.1805 36.9878L26.2852 30.1482L26.9398 
			 32.7676C27.1049 33.4545 27.5206 34.0627 28.1074 34.4789C28.7118 34.9097 29.443 35.0989 30.1654 
			 35.0116L48.3483 32.8811H48.3601L48.3719 32.8782C49.165 32.7763 49.8461 32.331 50.2412 31.6587C50.6569 
			 30.9515 50.7012 30.09 50.3709 29.2925ZM44.0731 21.2625C44.1262 21.2596 44.1557 21.2567 44.1557 
			 21.2567C44.3326 21.2421 44.5242 21.2974 44.6864 21.4022C44.8515 21.507 44.9871 21.6554 45.0608 
			 21.8301L47.3223 27.2493C45.8097 26.6323 43.7547 25.7912 41.8883 25.0287C42.3571 24.4175 42.8171 23.7422 
			 43.2534 22.9972C43.5748 22.4529 43.849 21.8737 44.0731 21.2625ZM36.2391 9.19864C37.849 9.19282 39.3792 
			 9.91753 40.6028 11.5241C41.8205 13.119 42.6755 15.3979 42.6726 17.6914C42.6785 20.6193 41.0038 23.2416 
			 39.1374 25.0781C38.0229 26.1783 36.8583 26.9961 36.018 27.52C35.9384 27.1824 35.2868 22.2463 29.4754 
			 20.5727C29.9383 15.2495 31.613 12.3885 32.8189 10.9042C34.0986 9.33835 35.3782 9.21902 36.2391 
			 9.19864ZM24.7432 23.6986L25.6955 27.5229L22.7412 33.1605C22.1427 32.3106 20.9338 30.6371 20.0198 
			 27.3337L12.9878 30.8845L12.8935 25.1247C10.7529 25.6195 10.2723 25.4623 9.5588 25.4594C7.93717 25.4594 
			 4.90029 24.6619 4.10421 23.3872C11.9972 20.6513 12.8227 17.5721 12.8227 17.5721C10.4787 17.2432 8.89835 
			 16.1838 7.53323 15.0661C6.16221 13.934 4.49635 11.6289 5.32191 9.1579C6.67524 10.9507 9.86544 13.1452 
			 13.3593 11.6667C10.2782 11.3437 6.71652 8.86103 6.01774 5.64206C6.26836 5.78176 6.53961 5.92728 6.83446 
			 6.06989C8.4325 6.85863 10.6202 7.66774 13.0497 7.67065C13.7662 7.67065 14.5004 7.57752 15.2404 
			 7.40871C17.3574 6.92557 19.0026 5.76721 20.5889 4.71944C22.1781 3.66003 23.6906 2.72286 25.4951 
			 2.42599C26.2499 2.30084 26.9752 2.24263 27.6651 2.24263C29.8676 2.24263 31.6956 2.8451 32.931 
			 3.77645C34.1487 4.70198 34.8032 5.88654 34.8298 7.28065C33.7212 7.55132 32.4209 8.212 31.2976 
			 9.62648C29.726 11.5881 28.3698 14.8711 27.8862 20.4476L27.1373 20.5087H27.1285H27.1196C26.3265 20.5727 
			 25.6248 20.9511 25.1707 21.571C24.7167 22.1793 24.5634 22.9564 24.7432 23.6986ZM26.813 23.2329C26.7688 
			 23.0495 26.8484 22.8691 26.9044 22.7148C26.987 22.4878 27.2936 22.2841 27.6268 22.2608C29.2012 21.929 
			 30.6902 22.7352 31.3742 23.2591C32.0553 23.783 32.7128 24.6096 33.1226 25.407C33.2111 25.5758 33.2907 
			 25.7446 33.3703 25.9134L28.4346 30.4363L26.813 23.2329ZM48.4898 30.6517C48.4161 30.7797 48.2864 30.8671 
			 48.1124 30.8903L29.9619 32.9539C29.7378 32.983 29.1305 33.1372 29.0332 32.3514L33.957 27.4152C34.2607 
			 28.5154 34.2637 29.8891 34.1222 30.9922C34.1222 30.9922 37.4893 29.6825 40.8593 26.2132C43.8579 27.4385 
			 46.9773 28.7802 48.1537 29.2605L48.4957 30.0579C48.5901 30.282 48.5812 30.4974 48.4898 30.6517ZM26.872 
			 12.2168C26.872 10.3599 25.9845 9.12879 24.9643 9.187C23.9353 9.24521 22.9859 10.5986 22.8149 
			 12.339C22.6321 14.1726 23.3899 15.9218 24.6253 15.9218C25.8725 15.9218 26.872 14.1202 26.872 12.2168Z" 
				fill="#0E0E0E" 
			/>
		</svg>

	</div>
);

export default Logo;
