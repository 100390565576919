import React from 'react';
import css from './logo.module.scss';
import { ReactComponent as LogoIcon } from './images/logo.svg';
import { ReactComponent as LogoTextIcon } from './images/logo-text.svg';

export const Logo = () => (
	<div className={css.wrapper}>
		<LogoIcon />
		<LogoTextIcon className={css.text} />
	</div>
);
