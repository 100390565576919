import SpatialArea from './SpatialArea';
import AreaResizeTrigger from './AreaResizeTrigger';
import IFrameArea from './IFrameArea';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import ISpatialAreaBaseData from './ISpatialAreaBaseData';

/** Прослойка для областей, предназначенных для изменения ширины и высоты сущности. */
abstract class SpatialResizeArea<TData extends ISpatialAreaBaseData<any>> extends SpatialArea<TData> {
	private readonly TRIGGER_RADIUS = 6;

	protected readonly trigger: AreaResizeTrigger;

	protected constructor(trigger: AreaResizeTrigger) {
		super();
		this.trigger = trigger;
	}

	public getTrigger = (): AreaResizeTrigger => this.trigger;

	protected getTriggerArea = (x: number, y: number, width: number, height: number): IFrameArea => {
		switch (this.trigger) {
		case AreaResizeTrigger.LEFT:
			return {
				x: x - this.TRIGGER_RADIUS,
				y: y - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: height + this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.TOP:
			return {
				x: x - this.TRIGGER_RADIUS,
				y: y - this.TRIGGER_RADIUS,
				width: width + this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.RIGHT:
			return {
				x: x + width - this.TRIGGER_RADIUS,
				y: y - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: height + this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.BOTTOM:
			return {
				x: x - this.TRIGGER_RADIUS,
				y: y + height - this.TRIGGER_RADIUS,
				width: width + this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.LEFT_TOP:
			return {
				x: x - this.TRIGGER_RADIUS,
				y: y - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.RIGHT_TOP:
			return {
				x: x + width - this.TRIGGER_RADIUS,
				y: y - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.RIGHT_BOTTOM:
			return {
				x: x + width - this.TRIGGER_RADIUS,
				y: y + height - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		case AreaResizeTrigger.LEFT_BOTTOM:
			return {
				x: x - this.TRIGGER_RADIUS,
				y: y + height - this.TRIGGER_RADIUS,
				width: this.TRIGGER_RADIUS * 2,
				height: this.TRIGGER_RADIUS * 2,
				rotate: 0,
			};
		default: throw new ManipulatorError('trigger not found');
		}
	};

	public isResizeType = (): boolean => true;
}

export default SpatialResizeArea;
