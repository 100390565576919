import SpatialQuadrantsTree from './SpatialQuadrantsTree';
import IFrameArea from './spatial-area/IFrameArea';
import IDescartesPosition from '../../../utils/IDescartesPosition';
import SpatialAreaType from './spatial-area/SpatialAreaType';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IComponent from '../../../components/IComponent';
import SpatialGraphicArea from './spatial-area/areas/SpatialGraphicArea';
import IBoundingBox from './spatial-area/IBoundingBox';
import Utils from '../../../utils/impl/Utils';
import IGraphic from '../../../graphic/IGraphic';
import IComponentTree from '../../../component-tree/IComponentTree';
import { AnySpatialArea } from '../../../Types';
import ComponentFilterFunctionType from './ComponentFilterFunctionType';
import SketchComponentType from '../../../components/SketchComponentType';
import GraphicType from '../../../graphic/GraphicType';
import EditModeContext from '../../EditModeContext';

export interface ISpatialAreaTreeDependencies {
	componentTree: IComponentTree,
	rootSpatialElement: HTMLElement,
	editModeContext: EditModeContext,
}

/**
 * Дерево пространственных областей, хранит все активные области, которые находятся в конструкторе в реальном времени.
 */
abstract class SpatialAreaTree extends SpatialQuadrantsTree<ISpatialAreaTreeDependencies> {
	/** Текущая позиция курсора в пространстве */
	private readonly currentSpatialCursorPosition: IDescartesPosition;
	private readonly componentFilterFunctions: ComponentFilterFunctionType[];

	// Область, с которой взаимодействует пользователь в данный момент
	private activeArea: AnySpatialArea | null;

	private currentCursorAreasStack: AnySpatialArea[];
	private updateActiveAreaListener: (areas: AnySpatialArea[]) => AnySpatialArea | null;
	private isVisualization: boolean;

	protected constructor() {
		super();
		this.activeArea = null;
		this.isVisualization = false;
		this.componentFilterFunctions = [];
		this.currentCursorAreasStack = [];
		this.currentSpatialCursorPosition = {
			x: 0,
			y: 0,
		};
	}

	/**
	 * Добавляет обработчик события обновления активной области.
	 * @param listener Функция обработчика.
	 */
	public setUpdateActiveAreaListener = (listener: (areas: AnySpatialArea[]) => AnySpatialArea | null) => {
		this.updateActiveAreaListener = listener;
	};

	/**
	 * Синхронизирует реальное положение областей со значениями в дереве.
	 */
	public sync = () => {
		const components = this.dependencies.componentTree.getComponents();
		const filteredComponents = this.filterComponents(components);
		const areas = filteredComponents.map(component => component.getSpatialAreas()).flat();
		const rootBound = this.dependencies.rootSpatialElement.getBoundingClientRect();
		const targetArea: IFrameArea = {
			x: 0,
			y: 0,
			rotate: 0,
			width: rootBound.width,
			height: rootBound.height,
		};

		if (this.isVisualization) {
			this.removeVisualizeElements();
		}

		this.reconciliate(targetArea, areas);

		if (this.isVisualization) {
			this.appendVisualizeElements();
		}

		// обновить активную область
	};

	/**
	 * Обновляет активную область, которую пересекает позиция. Актуально вызывать при обновлении позиции.
	 */
	public updateAreaOnPositionChange = (position: IDescartesPosition) => {
		if (position.x === this.currentSpatialCursorPosition.x && position.y === this.currentSpatialCursorPosition.y) {
			return;
		}
		this.updateActiveArea(position);
	};

	/**
	 * Принудительно обновляет активную область, которую пересекает позиция.
	 */
	public forceUpdateActiveArea = (position: IDescartesPosition) => {
		this.updateActiveArea(position);
	};

	public getActiveArea = (): AnySpatialArea | null => this.activeArea;

	/**
	 * Возвращает самую близкую к пользователю пересеченную графику.
	 */
	public getHighestCrossGraphic = (): IGraphic | null => {
		if (this.currentCursorAreasStack.length === 0) {
			return null;
		}

		for (let i = 0; i < this.currentCursorAreasStack.length; i++) {
			const area = this.currentCursorAreasStack[i];
			const { graphic } = (area as SpatialGraphicArea).getData();
			const component = graphic.getParentComponent();
			if (component === null) throw new ManipulatorError('component not found', graphic);

			if (graphic) {
				if (this.dependencies.editModeContext.getCurrentEditContext() === component) {
					if (i !== this.currentCursorAreasStack.length - 1) {
						const nextArea = this.currentCursorAreasStack[i + 1];
						const nextGraphic = (nextArea as SpatialGraphicArea).getData().graphic;
						if (nextGraphic.type !== GraphicType.PAGE) {
							return nextGraphic;
						}
					}
				}
				return graphic;
			}
		}
		return null;
	};

	/**
	 * Возвращает самый близкий к пользователю пересеченный компонент,
	 * даже если активная область только лишь к нему относиться.
	 */
	public getHighestCrossComponent = (): IComponent | null => {
		const crossedGraphic = this.getHighestCrossGraphic();
		if (crossedGraphic === null) {
			return null;
		}

		return crossedGraphic.getParentComponent();
	};

	/**
	 * Возвращает все компоненты, которые пересекает `area`.
	 */
	public getCrossComponentsFromArea = (area: IFrameArea): IComponent[] | null => {
		const areas = this.getAreas();
		const graphicAreas = areas
			.filter(area => area.type === SpatialAreaType.GRAPHIC) as SpatialGraphicArea[];

		if (graphicAreas.length === 0) {
			return null;
		}

		const components: IComponent[] = [];

		graphicAreas.forEach(spatialArea => {
			const globalFrameArea = spatialArea.getGlobalFrameArea();
			const isCrossedArea = this.isCrossedAreas(area, globalFrameArea);

			if (isCrossedArea) {
				const { graphic } = spatialArea.getData();
				const component = graphic.getParentComponent();
				if (component === null) {
					return;
				}

				components.push(component);
			}
		});

		return components;
	};

	/**
	 * Добавляет функцию для фильтрации компонентов при определении активной области.
	 * @param fn Функция для метода `.filter()`.
	 */
	public addComponentFilterFunction = (fn: ComponentFilterFunctionType) => {
		this.componentFilterFunctions.push(fn);
	};

	public enableVisualization = () => {
		this.isVisualization = true;
	};

	public disableVisualization = () => {
		this.isVisualization = false;
	};

	public onScroll = () => {
		if (this.isVisualization) {
			this.removeVisualizeElements();
			this.appendVisualizeElements();
		}
	};

	/**
	 * Рекурсивно проверяет родителей компонента пока не найдет родителя типа PAGES_CONTAINER.
	 * Если родитель такого типа не найден, метод вернет null, иначе вернет найденный компонент.
	 * @param component
	 */
	private getPagesContainerChild = (component: IComponent): IComponent | null => {
		const parent = component.getParentComponent();
		if (parent === null) {
			return null;
		}
		if (parent.type === SketchComponentType.PAGES_CONTAINER) {
			return component;
		}
		return this.getPagesContainerChild(parent);
	};

	/**
	 * Обновляет активную область, основываясь на отправленной позиции.
	 * @param position Координаты точки для определения области.
	 */
	private updateActiveArea = (position: IDescartesPosition) => {
		this.currentSpatialCursorPosition.x = position.x;
		this.currentSpatialCursorPosition.y = position.y;

		// Получить все области, пересекаемые с курсором
		let areas = this.getCrossAreas(position);
		if (areas === null) {
			this.activeArea = null;
			this.currentCursorAreasStack.length = 0;
			return;
		}
		// Отсортировать области в контексте слоев
		areas = this.sortAreas(areas);

		this.currentCursorAreasStack = areas;
		/* Проверить активную область, т.к. область на которую наведена мышь может отличаться от активной
		например при зажатой клавише Ctrl и наведении на внутренний компонент группы */
		this.activeArea = this.updateActiveAreaListener(areas);
	};

	private removeVisualizeElements = () => {
		const nodes = this.getNodes();
		for (let i = 0; i < nodes.length; i++) {
			const visualizeElement = nodes[i].getVisualizeElement();
			visualizeElement.remove();
		}
	};

	private appendVisualizeElements = () => {
		const nodes = this.getNodes();
		for (let i = 0; i < nodes.length; i++) {
			const visualizeElement = nodes[i].getVisualizeElement();
			visualizeElement.style.top = `${nodes[i].getArea().y - document.body.scrollTop}px`;
			this.dependencies.rootSpatialElement.append(visualizeElement);
		}
	};

	private isCrossedAreas = (a: IFrameArea, b: IFrameArea): boolean => {
		const rect1 = this.getRotatedRectangle(a);
		const rect2 = this.getRotatedRectangle(b);

		return (
			rect1.left < rect2.right
			&& rect1.right > rect2.left
			&& rect1.top < rect2.bottom
			&& rect1.bottom > rect2.top
		);
	};

	private getRotatedRectangle(frame: IFrameArea): IBoundingBox {
		const angle = (frame.rotate * Math.PI) / 180;
		const cos = Math.cos(angle);
		const sin = Math.sin(angle);

		const width = frame.width * Math.abs(cos) + frame.height * Math.abs(sin);
		const height = frame.width * Math.abs(sin) + frame.height * Math.abs(cos);

		const cx = frame.x + frame.width / 2;
		const cy = frame.y + frame.height / 2;

		const left = cx - width / 2;
		const right = cx + width / 2;
		const top = cy - height / 2;
		const bottom = cy + height / 2;

		return {
			left, right, top, bottom,
		};
	}

	private sortAreas = (areas: AnySpatialArea[]): AnySpatialArea[] => {
		const sortedAreas = areas.sort((a, b) => {
			const aFrameConfig = a.getData().graphic.getFrameConfiguration();
			const bFrameConfig = b.getData().graphic.getFrameConfiguration();

			if (aFrameConfig.layer < bFrameConfig.layer) {
				return 1;
			}
			if (aFrameConfig.layer > bFrameConfig.layer) {
				return -1;
			}
			return 0;
		});

		const componentResizeArea: AnySpatialArea[] = [];
		const editableComponentAreas: AnySpatialArea[] = [];
		const focusComponent: AnySpatialArea[] = [];
		const componentAreas: AnySpatialArea[] = [];

		for (let i = 0; i < sortedAreas.length; i++) {
			const area = sortedAreas[i];
			const graphicArea = area.getData().graphic;
			if (graphicArea.isEnableEditMode()) {
				if (area.type === SpatialAreaType.PICTURE_RESIZE
					|| area.type === SpatialAreaType.GRAPHIC_RESIZE
					|| area.type === SpatialAreaType.PICTURE_ANGULAR_RESIZE
					|| area.type === SpatialAreaType.GRAPHIC_ANGULAR_RESIZE) {
					componentResizeArea.push(area);
					// eslint-disable-next-line no-continue
					continue;
				}
				editableComponentAreas.push(area);
			} else if (graphicArea.isEnableFocus()) {
				if (area.getData().graphic.type !== 'GRAPHIC_PAGE') {
					focusComponent.push(area);
				}
			} else {
				componentAreas.push(area);
			}
		}

		return [...this.groupAreasFromGraphic(componentResizeArea),
			...this.groupAreasFromGraphic(editableComponentAreas),
			...this.groupAreasFromGraphic(focusComponent),
			...this.groupAreasFromGraphic(componentAreas)];
	};

	private groupAreasFromGraphic = (areas: AnySpatialArea[]): AnySpatialArea[] => {
		const groupedAreas: Map<IGraphic, AnySpatialArea[]> = new Map();

		areas.forEach(area => {
			const { graphic } = area.getData();
			if (!groupedAreas.has(graphic)) {
				groupedAreas.set(graphic, []);
			}
			const graphicAreas = groupedAreas.get(graphic);
			if (graphicAreas === undefined) {
				throw new ManipulatorError('the graphic areas not found');
			}
			graphicAreas.push(area);
		});

		groupedAreas.forEach((graphicAreas, _) => {
			if (graphicAreas.length === 1) {
				return;
			}
			this.sortAreasForType(graphicAreas);
		});

		return Array.from(groupedAreas.values()).flat();
	};

	/**
	 * Сортирует области в заданной последовательности.
	 * @param areas Области для сортировки.
	 */
	private sortAreasForType = (areas: AnySpatialArea[])
		: AnySpatialArea[] => Utils.Object.sortByLambdaFunctions<AnySpatialArea>(
			areas,
			area => area.type === SpatialAreaType.TABLE_COLUMN_RESIZE,
			area => area.type === SpatialAreaType.TABLE_ROW_RESIZE,
			area => area.type === SpatialAreaType.TABLE_CELL,
			area => area.type === SpatialAreaType.PICTURE_ANGULAR_RESIZE,
			area => area.type === SpatialAreaType.PICTURE_RESIZE,
			area => area.type === SpatialAreaType.PICTURE,
			area => area.type === SpatialAreaType.GRAPHIC_ANGULAR_RESIZE,
			area => area.type === SpatialAreaType.GRAPHIC_RESIZE,
			area => area.type === SpatialAreaType.GRAPHIC,
		);

	/**
	 * Фильтрует компоненты по заранее сохраненным функциям фильтрации.
	 * @param components Компоненты для сортировки.
	 */
	private filterComponents = (components: IComponent[]): IComponent[] => {
		let filteredComponents = [...components];

		for (let i = 0; i < this.componentFilterFunctions.length; i++) {
			filteredComponents = filteredComponents.filter(this.componentFilterFunctions[i]);
		}

		// console.log(filteredComponents);
		return filteredComponents;
	};
}

export default SpatialAreaTree;
