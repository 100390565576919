import { useNavigate } from 'react-router-dom';
import css from './forbidden.module.scss';

export const Forbidden = () => {
	const navigate = useNavigate();

	const toMainPage = () => navigate('/');

	return (
		<div className={css.wrapper}>
			<div className={css.content}>
				<div className={css.title}>Доступ закрыт</div>
				<div className={css.description}>У вас нет прав для просмотра этой страницы</div>
				<button onClick={toMainPage} type="button" className={css.btn}>На главную</button>
			</div>
		</div>
	);
};
