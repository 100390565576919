import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import css from './authorization.module.scss';
import MainAuthorizationContainer from './mainAuthorizationPage/MainAuthorizationContainer';
import ResetPasswordContainer from './resetPasswordPage/ResetPasswordContainer';
import FinallyResetPage from './finallyResetPage/FinallyResetPage';
import { useAppSelector } from '../../../../shared/libs/redux';
import { personalAccountSlice } from '../../../../redux/reducers/personalAccountReducer';

export const AUTHORIZATION_LINK = '/authorization';
export const REGISTRATION_LINK = '/registration';
export const PATH_AUTH_SUCCESS = '/collection/template/gallery';

export const MAIN_AUTHORIZATION_PAGE = 'MAIN_AUTHORIZATION_PAGE';
export const RESET_PASSWORD_AUTHORIZATION_PAGE = 'RESET_PASSWORD_AUTHORIZATION_PAGE';
export const FINALLY_RESET_AUTHORIZATION_PAGE = 'FINALLY_RESET_AUTHORIZATION_PAGE';

export type CurrentAuthorizationPageData =
	typeof MAIN_AUTHORIZATION_PAGE
	| typeof RESET_PASSWORD_AUTHORIZATION_PAGE
	| typeof FINALLY_RESET_AUTHORIZATION_PAGE;

const Authorization = () => {
	const [currentPage, setCurrentPage] = useState<CurrentAuthorizationPageData>(MAIN_AUTHORIZATION_PAGE);

	const isAuthorized = useAppSelector(personalAccountSlice.selectors.authorized);

	if (isAuthorized) return <Navigate to={PATH_AUTH_SUCCESS} />;

	return (
		<div className={css.container}>
			{
				currentPage === MAIN_AUTHORIZATION_PAGE
				&& <MainAuthorizationContainer setCurrentPage={setCurrentPage} />
			}
			{
				currentPage === RESET_PASSWORD_AUTHORIZATION_PAGE
				&& <ResetPasswordContainer setCurrentPage={setCurrentPage} />
			}
			{
				currentPage === FINALLY_RESET_AUTHORIZATION_PAGE
				&& <FinallyResetPage setCurrentPage={setCurrentPage} />
			}
		</div>
	);
};

export default Authorization;
