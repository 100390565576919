import React from 'react';
import { useNavigate } from 'react-router-dom';
import Preview from './Preview';
import { fixCalendarDate } from '../../../../../../shared/libs/utils/fix-calendar-date';
import { useAppSelector } from '../../../../../../shared/libs/redux';

const PreviewContainer = () => {
	// const sketchStructure = useSelector((state: RootState) => state.sketch.sketch_structure);
	// const deadLine = useSelector((state: RootState) => state.sketch.deadline);
	// const managerId = useSelector((state: RootState) => state.sketch.managerId);
	// const name = useSelector((state: RootState) => state.sketch.name);
	// const preview = useSelector((state: RootState) => state.sketch.preview);

	const {
		name,
		deadline,
		managerId,
		preview,
		sketch_structure: structure,
	} = useAppSelector(state => state.sketch);

	const navigate = useNavigate();

	// * Обработчик даты
	const deadlineHandling = (deadLine: string | null) => {
		if (deadLine !== 'Бессрочно' && deadLine) {
			return fixCalendarDate(deadLine)
				.split('-')
				.reverse()
				.join('-');
		}
		return null;
	};

	// * Вернуться на предыдщую страницу
	const onGoBack = () => {
		navigate(-1);
	};

	// const [createOffer] = offerAPI.useCreateMutation();
	// // * При отправке кп
	// const onSendKP = async () => {
	// 	// if (structure && managerId && name && deadline !== undefined) {
	// 	if (structure && name) {
	// 		try {
	// 			const res = await createOffer({
	// 				name,
	// 				deadline: '',
	// 				// deadline: deadlineHandling(deadline) || '',
	// 				// manager: managerId,
	// 				manager: null,
	// 				template: structure.id,
	// 			})
	// 				.unwrap();
	//
	// 			navigate(`/collection/commercial-panel/${res.offer.id}`);
	// 		} catch (err) {
	// 			notificationError('Возникла ошибка при отправке КП...');
	// 		}
	// 	}
	// };

	return (
		structure
			? (
				<Preview
					onGoBack={onGoBack}
					// onSendKP={onSendKP}
					// newStructure={structure}
				/>
			)
			: null
	);
};

export default PreviewContainer;
