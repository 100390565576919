import { AdminUserCard } from '../user-card/user-card';
import { IAdminUsersFilterItem } from '../../models/filter-data';
import css from './users-list.module.scss';
import { useAdminUsersList } from '../../../../../../hooks/users/useAdminUsersList';

interface AdminUsersListProps {
	search: string,
	filter: IAdminUsersFilterItem,
}

export const AdminUsersList = ({
	search,
	filter,
}: AdminUsersListProps) => {
	const { users } = useAdminUsersList(search, filter);

	return (
		<table className={css.wrapper}>
			<tbody>
				{
					users.map((user, i) => {
						const isLast = i === (users.length - 1);
						const isFirst = i === 0;
						const single = users.length === 1;

						return (
							<AdminUserCard
								key={user.email}
								data={user}
								styles={`${
									(isLast && css.last_card)
								|| (isFirst && css.first_card)
								|| (single && css.singe)
								}`}
							/>
						);
					})
				}
			</tbody>
		</table>
	);
};
