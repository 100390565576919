import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { SKETCH_URI, SKETCH_URI_PDF } from './consts';
import { IGetSketchResponse, IUpdateSketchRequest } from './types';

export const sketchAPI = createApi({
	reducerPath: 'sketchAPI',
	baseQuery,
	refetchOnReconnect: true,
	endpoints: builder => ({
		update: builder.mutation<void, IUpdateSketchRequest>({
			query: ({
				sketchId,
				preview,
			}) => ({
				url: `${SKETCH_URI}/${sketchId}/preview`,
				method: 'POST',
				body: {
					preview,
				},
			}),
		}),
		get: builder.query<IGetSketchResponse, { sketchId: string }>({
			query: ({ sketchId }) => ({
				url: `${SKETCH_URI}/${sketchId}`,
				method: 'GET',
			}),
		}),
		pdf: builder.mutation<any, { url: string }>({
			query: ({ url }) => ({
				url: SKETCH_URI_PDF,
				method: 'POST',
				body: { url },
				responseHandler: async (
					response,
				) => response.blob(),
				cache: 'no-cache',
			}),
		}),

		// create: builder.mutation<ICreateSketchResponse, ICreateSketchRequest>({
		// 	query: (data) => ({
		// 		url: SKETCH_URI,
		// 		method: 'POST',
		// 		body: {
		// 			...data,
		// 		},
		// 	}),
		// }),
		// duplicate: builder.mutation<IGetPrivateTemplateListResponse, { idList: string[] }>({
		// 	query: (data) => ({
		// 		url: DUPLICATE_SKETCH_URI,
		// 		method: 'POST',
		// 		body: {
		// 			...data,
		// 		},
		// 	}),
		// }),
		// setPreview: builder.mutation<void, ISetSketchPreviewRequest>({
		// 	query: (data) => ({
		// 		url: SET_SKETCH_PREVIEW_URI,
		// 		method: 'PUT',
		// 		body: {
		// 			...data,
		// 		},
		// 	}),
		// }),
		// downloadPDF: builder.mutation<IDownloadPDFResponse, IDownloadPDFRequest>({
		// 	query: (data) => ({
		// 		url: GET_SKETCH_PDF,
		// 		method: 'POST',
		// 		body: {
		// 			...data,
		// 		},
		// 	}),
		// }),
	}),
});
