import Graphic from '../Graphic';
import GraphicType from '../GraphicType';
import IGraphicStructure from '../IGraphicStructure';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import { AnyGraphicStructure, AnySpatialArea } from '../../Types';
import UniterGraphic from '../UniterGraphic';

/**
 * Графика для объединения другой графики в группы.
 */
class GroupGraphic extends UniterGraphic<null> {
	public readonly type: GraphicType = GraphicType.GROUP;
	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-group';

	constructor() {
		super();
		this.graphicElement.classList.add(this.GRAPHIC_CLASS_NAME);
	}

	public getTexture = (): null => null;

	public setStructure = (fn: (prev: AnyGraphicStructure) => AnyGraphicStructure): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const { frame, id, offset } = updated;
		if (frame === null) {
			throw new ManipulatorError('group graphic frame cannot be null');
		}

		this.id = id;
		this.offset = offset;
		this.setFrameConfiguration(_ => frame);
	};

	public setTexture = (fn: (prev: null) => null): void => {
		// nothing
	};

	public getUniqueTexture = (): null => null;

	protected startMutation = (): void => {
		// TODO
	};

	protected finishMutation = (): void => {
		// TODO
	};
}

export default GroupGraphic;
