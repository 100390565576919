import { notificationError, notificationSuccess } from '../../../../components/Notifications/callNotifcation';
import { workshopCategoryApi } from '../../../../entities/workshop-category';

export const useCreateCategory = (closeModal: () => void) => {
	const [create, { isLoading }] = workshopCategoryApi.useCreateMutation();

	const createCategory = async (
		inputValue: string,
		setInputValue: (value: string) => void,
	) => {
		if (inputValue !== '') {
			try {
				await create({ name: inputValue })
					.unwrap();
				setInputValue('');
				closeModal();
				notificationSuccess('Категория создана успешно!', '', true);
			} catch (e) {
				notificationError('Возникла ошибка при создании категории...');
			}
		}
	};

	return {
		isLoading,
		createCategory,
	};
};
