import React from 'react';
import cx from 'classnames';
import css from './modal-title.module.scss';

interface ModalTitleProps {
	children: React.ReactNode,
	styles?: string,
}

export const ModalTitle = ({
	children,
	styles,
}: ModalTitleProps) => (
	<h5 className={cx(css.title, styles)}>{children}</h5>
);
