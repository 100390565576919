import React, { useState } from 'react';
import cx from 'classnames';
import { ReactComponent as SearchIcon } from './images/search.svg';
import css from './searchInput.module.scss';

interface SearchInputProps {
    styles?: string,
    maxLength?: number,
    placeholder: string,
    onClick?: () => void,
    searchValue: string | null,
    onChange: (text: string) => void,
}

const SearchInput = ({
	styles,
	onClick,
	onChange,
	maxLength,
	placeholder,
	searchValue,
}: SearchInputProps) => {
	const [focused, setFocused] = useState(false);

	// * При изменении значения
	const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
	};

	// * Когда инпут в фокусе
	const onFocusInput = () => {
		setFocused(true);
	};

	// * Когда инпут вне фокуса
	const onBlurInput = () => {
		setFocused(false);
	};

	const isFocused = (searchValue && searchValue?.length > 0) || focused;

	return (
		<div className={cx(css.search, isFocused && css.focus, styles)}>
			<div className={css.search_icon}>
				<SearchIcon />
			</div>
			<input
				onClick={onClick}
				type="text"
				value={searchValue || ''}
				placeholder={placeholder}
				maxLength={maxLength}
				onBlur={onBlurInput}
				onFocus={onFocusInput}
				onChange={onChangeValue}
			/>
		</div>
	);
};

export default SearchInput;
