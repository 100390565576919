import SpatialResizeArea from '../../spatial-tree/spatial-area/SpatialResizeArea';
import AreaResizeTrigger from '../../spatial-tree/spatial-area/AreaResizeTrigger';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';
import AreaMutator from '../AreaMutator';
import IAreaSizeMutationBody from '../events/size/IAreaSizeMutationBody';
import AreaMutationEventType from '../events/AreaMutationEventType';
import { AnySpatialArea } from '../../../../Types';
import IFrameArea from '../../spatial-tree/spatial-area/IFrameArea';
import IMutableEntity from '../IMutableEntity';

/** Отвечает за изменение размеров любой области. */
class AreaSizeMutator extends AreaMutator<IAreaSizeMutationBody> {
	constructor() {
		super(AreaMutationEventType.SIZE);
		this.activeTrigger = null;
		this.disableBlindMode();
	}

	public postStartEvent = (areas: AnySpatialArea[]) => {
		const firstArea = areas[0];
		if (firstArea === undefined) {
			throw new ManipulatorError('the area not found');
		}
		this.activeTrigger = (firstArea as SpatialResizeArea<any>).getTrigger();
	};

	public postStopEvent = () => {
		this.activeTrigger = null;
	};

	public getActiveTrigger = () => this.activeTrigger;

	/** Определить область, которая изменяется и установить соответствующие значения, сохраняя
	 * соотношения сторон фрейма.
	 *
	 * @param currentValues
	 * @param entity
	 */
	public correctAspectRatioValues = (currentValues: IFrameArea, entity: IMutableEntity): IFrameArea => {
		const res = currentValues;
		if (res.width !== 0) {
			// Рассчитать соответствующую высоту для сохранения соотношения сторон
			res.height = res.width / entity.aspectRatio;
			if (this.getActiveTrigger() === AreaResizeTrigger.LEFT_TOP) {
				res.y += entity.initialFrameArea.height - res.height;
			}
			if (this.getActiveTrigger() === AreaResizeTrigger.RIGHT_TOP) {
				res.y += entity.initialFrameArea.height - res.height;
			}
		} else if (this.absoluteAreaOffsets!.height !== 0) {
			// Рассчитать соответствующую ширину для сохранения соотношения сторон
			res.width = res.height * entity.aspectRatio;
		}
		return res;
	};

	protected updateOffsets = (offsetX: number, offsetY: number) => {
		if (this.activeTrigger === null) {
			return;
		}

		if (this.absoluteAreaOffsets === null) {
			throw new ManipulatorError('offsets not initialize');
		}
		switch (this.activeTrigger) {
		case AreaResizeTrigger.LEFT: {
			this.absoluteAreaOffsets.x += offsetX;
			this.absoluteAreaOffsets.width -= offsetX;
			break;
		}
		case AreaResizeTrigger.TOP: {
			this.absoluteAreaOffsets.y += offsetY;
			this.absoluteAreaOffsets.height -= offsetY;
			break;
		}
		case AreaResizeTrigger.RIGHT: {
			this.absoluteAreaOffsets.width += offsetX;
			break;
		}
		case AreaResizeTrigger.BOTTOM: {
			this.absoluteAreaOffsets.height += offsetY;
			break;
		}
		case AreaResizeTrigger.RIGHT_BOTTOM: {
			this.absoluteAreaOffsets.width += offsetX;
			this.absoluteAreaOffsets.height += offsetY;
			break;
		}
		case AreaResizeTrigger.RIGHT_TOP: {
			if (!this.maintainAspectRatio) {
				this.absoluteAreaOffsets.width += offsetX;
				this.absoluteAreaOffsets.y += offsetY;
				this.absoluteAreaOffsets.height -= offsetY;
			} else {
				this.absoluteAreaOffsets.width += offsetX;
			}
			break;
		}
		case AreaResizeTrigger.LEFT_TOP: {
			if (!this.maintainAspectRatio) {
				this.absoluteAreaOffsets.x += offsetX;
				this.absoluteAreaOffsets.width -= offsetX;
				this.absoluteAreaOffsets.y += offsetY;
				this.absoluteAreaOffsets.height -= offsetY;
			} else {
				this.absoluteAreaOffsets.x += offsetX;
				this.absoluteAreaOffsets.width -= offsetX;
			}
			break;
		}
		case AreaResizeTrigger.LEFT_BOTTOM: {
			this.absoluteAreaOffsets.x += offsetX;
			this.absoluteAreaOffsets.width -= offsetX;
			this.absoluteAreaOffsets.height += offsetY;
			break;
		}
		default: {
			throw new ManipulatorError(`mutate trigger "${this.activeTrigger}" not found, mutate impossible`);
		}
		}
	};

	protected getMutationEventBody = (): IAreaSizeMutationBody => ({
		trigger: this.activeTrigger,
	});
}

export default AreaSizeMutator;
