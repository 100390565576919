import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { URI_TEAM_TEMPLATE, URI_TEAM_TEMPLATE_BIND_TAG, URI_TEAM_TEMPLATE_DUPLICATE_PRIVATE } from './consts';
import {
	IBindTagToTeamTemplateRequest,
	ICreateTeamTemplateRequest,
	IDuplicateTeamTemplateToPrivateRequest,
	IGetTeamTemplateResponse,
	IGetTeamTemplatesRequest,
	IGetTeamTemplatesResponse,
} from './types';

export const teamTemplateAPI = createApi({
	reducerPath: 'teamTemplateAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['team'],
	endpoints: builder => ({
		create: builder.mutation<{ templateID: string }, ICreateTeamTemplateRequest>({
			query: (data) => ({
				url: URI_TEAM_TEMPLATE,
				method: 'POST',
				body: { ...data },
			}),
			invalidatesTags: ['team'],
		}),
		duplicateToPrivate: builder.mutation<void, IDuplicateTeamTemplateToPrivateRequest>({
			query: ({ templates }) => ({
				url: URI_TEAM_TEMPLATE_DUPLICATE_PRIVATE,
				method: 'POST',
				body: { templates },
			}),
			invalidatesTags: ['team'],
		}),
		bind: builder.mutation<void, IBindTagToTeamTemplateRequest>({
			query: ({
				templateId,
				tagId,
			}) => ({
				url: URI_TEAM_TEMPLATE_BIND_TAG(templateId, tagId),
				method: 'POST',
			}),
			invalidatesTags: ['team'],
		}),
		unbind: builder.mutation<void, IBindTagToTeamTemplateRequest>({
			query: ({
				templateId,
				tagId,
			}) => ({
				url: URI_TEAM_TEMPLATE_BIND_TAG(templateId, tagId),
				method: 'DELETE',
			}),
			invalidatesTags: ['team'],
		}),
		getAll: builder.query<IGetTeamTemplatesResponse, IGetTeamTemplatesRequest>({
			query: (data) => ({
				url: URI_TEAM_TEMPLATE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['team'],
		}),
		get: builder.query<IGetTeamTemplateResponse, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_TEAM_TEMPLATE}/${id}`,
				method: 'GET',
			}),
			providesTags: ['team'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_TEAM_TEMPLATE}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['team'],
		}),
		update: builder.mutation<void, { template: string, name: string }>({
			query: ({
				template,
				name,
			}) => ({
				url: `${URI_TEAM_TEMPLATE}/${template}`,
				method: 'PUT',
				body: { name },
			}),
			invalidatesTags: ['team'],
		}),
	}),
});
