import React from 'react';
import css from '../editModuleWindow.module.scss';

interface NotFoundProps {
	text: string,
	style?: string,
}

const NotFound: React.FC<NotFoundProps> = ({
	text,
	style,
}: NotFoundProps) => {
	NotFound.defaultProps = {
		style: undefined,
	};

	return (
		<div
			className={`${css.not_found} ${style ?? style}`}
		>
			{text}
		</div>
	);
};

export default NotFound;
