import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import {
	useUnbindVisibility,
} from '../../../../../components/pages/features/moduleWindow/module-tag/utils/useUnbindVisibility';
import css from './controls.module.scss';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';
import { ReactComponent as ConfirmUnbindIcon } from '../../images/confirm-unbind.svg';
import { ReactComponent as UnbindIcon } from '../../images/unbind.svg';
import { popUpTransitionClasses } from '../../../../../styles/transitionStyles/transitionStyles';

interface ModuleTagControlsProps {
	isIconShow: boolean,
	onDelete?: (() => void) | undefined,
	onUnbind?: (() => void) | undefined,
	onEdit?: (() => void) | undefined,
}

export const ModuleTagControls = ({
	isIconShow,
	onEdit,
	onUnbind,
	onDelete,
}: ModuleTagControlsProps) => {
	const unbindIconRef = useRef(null);

	const {
		handleUnbind,
		isDeletePermitted,
	} = useUnbindVisibility(2000, isIconShow, onUnbind);

	return (
		<div className={css.wrapper}>
			{
				onEdit && (
					<div
						role="presentation"
						onClick={onEdit}
					>
						<EditIcon />
					</div>
				)
			}
			{
				onDelete && (
					<div
						role="presentation"
						onClick={onDelete}
					>
						<DeleteIcon />
					</div>
				)
			}
			{
				onUnbind && (
					<CSSTransition
						nodeRef={unbindIconRef}
						in={isIconShow}
						timeout={200}
						classNames={popUpTransitionClasses}
						unmountOnExit
					>
						<div
							className={css.unbind}
							ref={unbindIconRef}
							role="presentation"
							onClick={handleUnbind}
						>
							{
								isDeletePermitted
									? <ConfirmUnbindIcon />
									: <UnbindIcon />
							}
						</div>
					</CSSTransition>
				)
			}
		</div>
	);
};
