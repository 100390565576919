import React, { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ComponentSketchStructureVisualizer
	from '../../../../../../../SketchManipulators/SketchStructureVisualizer/ComponentSketchStructureVisualizer';
import { galleryAPI } from '../../../../../../../../entities/templates/gallery/api/api';
import css from './gallery-template-panel.module.scss';
import { GalleryPanelHeader } from './header/gallery-panel-header';
import { ContentLayout } from '../../../../../../layouts/content';

export const GalleryTemplatePanel = () => {
	const navigate = useNavigate();

	const { id } = useParams();

	const {
		data,
		error,
	} = galleryAPI.useGetQuery({ id: id || '' }, { skip: !id });

	useLayoutEffect(() => {
		if (error && 'originalStatus' in error && error.originalStatus === 403) {
			navigate('/collection/forbidden');
		}
	}, [error]);

	return (
		<ContentLayout>
			<div className={css.wrapper}>
				<GalleryPanelHeader template={data?.template} />
				{
					data && (
						<ComponentSketchStructureVisualizer structure={{
							...data.template,
							root: data.rootComponent,
						}}
						/>
					)
				}
			</div>
		</ContentLayout>
	);
};
