import { useState } from 'react';
import { SearchTagBar } from '../../../../../../../../widgets/search-tag-bar';
import { useDebounce } from '../../../../../../../../shared/libs/useDebounce';
import {
	useCreateWorkshopTemplateTag,
} from '../../../../../../../../features/workshop/tags/create/api/useCreateWorkshopTemplateTag';
import { IWorkshopTemplate } from '../../../../../../../../entities/templates/workshop/template/model/types';
import { workshopTagAPI } from '../../../../../../../../entities/templates/workshop/tag/api/api';
import { ITagData } from '../../../../../../../../entities/modules/tag/api/types';
import {
	useBindTagToWorkshopTemplate,
} from '../../../../../../../../features/workshop/templates/bind-tag/api/useBindTagToWorkshopTemplate';

interface TemplateWorkshopSearchTagBarProps {
	template: IWorkshopTemplate | undefined,
}

export const TemplateWorkshopSearchTagBar = ({
	template,
}: TemplateWorkshopSearchTagBarProps) => {
	const [search, setSearch] = useState('');

	const debounceSearch = useDebounce(search.trim(), 200);

	const { data } = workshopTagAPI.useGetAllQuery({
		page: 1,
		limit: 127,
		search: debounceSearch,
	});

	const { onCreateTag } = useCreateWorkshopTemplateTag();
	const { onBindTag } = useBindTagToWorkshopTemplate(template?.id || '');

	return (
		<SearchTagBar
			search={search}
			bindTag={onBindTag}
			setSearch={setSearch}
			createTag={onCreateTag}
			tags={(data?.templateTags || []) as ITagData[]}
			templateTags={(template?.tags.fromTemplates || []) as ITagData[]}
		/>
	);
};
