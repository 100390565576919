import { roleAPI } from '../../../../entities/role/api/api';
import { employeeAPI } from '../../../../entities/employee/api/api';
import { ERROR_MESSAGE_LIST, notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { checkUsersRightsInterface } from '../../../../helpers/usersRights';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';
import { teamTemplateAPI } from '../../../../entities/templates/team/api/api';

export const useUnbindRole = (statusRights: checkUsersRightsInterface) => {
	const dispatch = useAppDispatch();

	const [unbind, { isLoading }] = roleAPI.useUnbindMutation();

	// * При удалении роли у пользователя
	const onUnbindRole = async (roleId: string, email?: string) => {
		if (statusRights.delete && email) {
			try {
				await unbind({
					role: roleId,
					email,
				})
					.unwrap();
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				dispatch(employeeAPI.util?.invalidateTags(['employee']));
				dispatch(teamTemplateAPI.util?.invalidateTags(['team']));
				notificationSuccess('Вы успешно удалили роль у пользователя!');
			} catch (e) {
				notificationError('Произошла ошибка при удалении роли у пользователя...');
			}
		} else {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'У вас недостаточно прав для совершения этого действия!');
		}
	};

	return {
		onUnbindRole,
		isLoading,
	};
};
