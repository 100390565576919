import { useAppDispatch, useAppSelector } from "../../shared/libs/redux";
import { useEffect } from "react";
import {
	addPrivateTemplateSelectedList,
	removePrivateTemplateSelectedList,
	setIsSelectionAreaActive,
	setPrivateTemplateSelectedList
} from "../../entities/templates/private/model/slice";

export const useHandleSelectedList = () => {
	const dispatch = useAppDispatch();

	const selectedList = useAppSelector(state => state.privateTemplate.privateTemplateSelectedList);

	const removeElem = (id: string) => {
		dispatch(removePrivateTemplateSelectedList(id));
	};
	const addElem = (id: string) => {
		dispatch(addPrivateTemplateSelectedList(id));
	};
	const setList = (ids: string[]) => {
		dispatch(setPrivateTemplateSelectedList(ids));
	};
	useEffect(() => {
		if (selectedList.length) {
			dispatch(setIsSelectionAreaActive(true));
		} else {
			dispatch(setIsSelectionAreaActive(false));
		}
	}, [selectedList]);

	return {
		setList,
		selectedList,
		removeElem,
		addElem,
	};
};
