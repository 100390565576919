import ITextOption from './line-height/ITextOption';
import ElementContainer from '../../../../utils/ElementContainer';
import HTMLElementName from '../../../../utils/HTMLElementName';

class TextOptionView<OptionValueType> extends ElementContainer<HTMLDivElement> {
	private readonly ACTIVE_CLASS_NAME = 'active';
	private readonly ELEMENT_CLASS_NAME = 'text-options__item';

	private option: ITextOption<OptionValueType>;

	constructor() {
		super(HTMLElementName.DIV);
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
	}

	public setOption = (option: ITextOption<OptionValueType>) => {
		this.option = option;
		this.rootElement.textContent = option.text;
	};

	public setEvent = (onClick: (option: ITextOption<OptionValueType>) => void) => {
		this.addRootElementClickListener(onClick.bind(this, this.option));
	};

	public getData = (): ITextOption<OptionValueType> => this.option;

	public enable = () => {
		this.rootElement.classList.add(this.ACTIVE_CLASS_NAME);
	};

	public disable = () => {
		this.rootElement.classList.remove(this.ACTIVE_CLASS_NAME);
	};
}

export default TextOptionView;
