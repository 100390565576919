import IComponentStructure from '../../components/IComponentStructure';
import ConstructorInjector, { IComponentInjectorDependencies } from '../ConstructorInjector';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import ITableCellTexture from '../../graphic/table/cells/ITableCellTexture';
import Utils from '../../utils/impl/Utils';
import SketchComponentType from '../../components/SketchComponentType';
import IGraphicStructure from '../../graphic/IGraphicStructure';
import ITableGraphicTexture from '../../graphic/table/ITableGraphicTexture';
import GraphicType from '../../graphic/GraphicType';
import { notificationError } from '../../../Notifications/callNotifcation';
import TableGraphic from '../../graphic/table/TableGraphic';
import TableComponent from '../../components/table/TableComponent';
import ITableComponentTexture from '../../components/table/ITableComponentTexture';
import IGraphic from '../../graphic/IGraphic';
import CSSCursor from '../../cursor/CSSCursor';

interface ITableDefaultStructureProps {
	rows: number,
	columns: number,
	componentOffset: number,
}

interface IGetLoadedStructureProps {
	rows: number,
	columns: number,
	componentOffset: number,
	cells: ITableCellTexture[],
}

export interface ITableInjectorDependencies extends IComponentInjectorDependencies {}

/**
 * Инжектор для добавления таблицы в структуру.
 */
class TableInjector extends ConstructorInjector<ITableInjectorDependencies> {
	private readonly MAX_TABLE_COLUMNS = 12;

	private loadedRowCount: number | null;
	private loadedColumnCount: number | null;
	private loadedCellTextures: ITableCellTexture[] | null;

	constructor() {
		super();
		this.loadedRowCount = null;
		this.loadedColumnCount = null;
		this.loadedCellTextures = null;
	}

	/**
	 * Загружает размер таблицы.
	 */
	public loadSize = (row: number, columns: number) => {
		if (columns > this.MAX_TABLE_COLUMNS) {
			notificationError(
				'Некорректный файл',
				`Максимально допустимое количество колонок в таблице - ${this.MAX_TABLE_COLUMNS}.`,
			);
			return;
		}
		this.loadedRowCount = row;
		this.loadedColumnCount = columns;
	};

	/**
	 * Загружает текстуры ячеек таблицы.
	 */
	public loadCellTextures = (cells: ITableCellTexture[]) => {
		this.loadedCellTextures = cells;
	};

	/**
	 * Инжектирует таблицу в структуру.
	 */
	public inject = () => {
		// Проверить загружены ли размеры таблицы
		if (this.loadedColumnCount === null || this.loadedRowCount === null) {
			throw new ManipulatorError('table size not loaded');
		}

		// Определить текущее смещение (на какой странице сейчас фокус)
		const parentComponent = this.dependencies.componentTree.getRootComponent();
		const mousePosition = this.dependencies.mousePositionObserver.getCurrentPosition();
		const injectionParams = this.calculateComponentInjectionParams(mousePosition);
		const injectionPageGraphic = parentComponent.getGraphics()[injectionParams.componentOffset];
		const pageGlobalPosition = injectionPageGraphic.getGlobalPosition();

		// Записать структуру таблицы
		const tableStructure: IComponentStructure<ITableComponentTexture> = this.loadedCellTextures === null
			? this.getDefaultStructure({
				rows: this.loadedRowCount,
				columns: this.loadedColumnCount,
				componentOffset: injectionParams.componentOffset,
			})
			: this.getLoadedStructure({
				rows: this.loadedRowCount,
				columns: this.loadedColumnCount,
				componentOffset: injectionParams.componentOffset,
				cells: this.loadedCellTextures,
			});

		let component: TableComponent;
		this.dependencies.componentTree.executeMutations(tools => {
			component = tools.componentFactory.createComponent<TableComponent>(tableStructure);

			const { paddingLeft, paddingRight } = injectionPageGraphic.getTexture();

			const graphicSequence = new Map<IGraphic, TableGraphic>();

			tableStructure.graphics?.forEach(graphicStructure => {
				const graphic = tools.graphicFactory.createGraphic<TableGraphic>(GraphicType.TABLE, component);
				graphic.setStructure(() => graphicStructure);
				component.appendGraphic(graphic);

				if (tableStructure.offset === null) {
					throw new ManipulatorError('the table structure not have offset');
				}
				const pageNumber = tableStructure.offset + graphicStructure.offset;
				const lastPageLayerGraphic = tools.componentLayers.getLastLayerGraphicFromPage(pageNumber);

				graphicSequence.set(lastPageLayerGraphic, graphic);

				graphic.setFrameConfiguration((prev) => ({
					...prev,
					x: paddingLeft,
					width: injectionPageGraphic.getFrameConfiguration().width - paddingLeft - paddingRight,
					y: mousePosition.y - pageGlobalPosition.y,
				}));
			});
			tools.mutator.mutateByAppendComponent(parentComponent, component);

			graphicSequence.forEach((tableGraphic, prevGraphic) => {
				tools.mutator.mutateByChangeLayer(prevGraphic, tableGraphic);
			});

			component.mutateCellTextures(tableStructure.texture.cells);
			component.applyMutations();

			this.stop();
			this.dependencies.componentTree.enableFocusOnly(component);
			this.callPostInjectListeners(component);
		});

		this.callSingleUseInjectListeners(component!);

		this.loadedRowCount = null;
		this.loadedColumnCount = null;
		this.loadedCellTextures = null;
	};

	public run = (): void => {
		// Включаем режим вставки компонента
		this.dependencies.cursorView.enableComponentInjectMode();
		// Устанавливаем курсор в режим определения области
		this.dependencies.cursorView.setCursor(CSSCursor.CROSSHAIR);
		// Устанавливаем флаг процесса вставки
		this.isProcess = true;
		this.setReadyInject();
	};

	public stop = (): void => {
		this.dependencies.cursorView.disableComponentInjectMode();
		this.isProcess = false;
	};

	/**
	 * Возвращает структуру таблицы по умолчанию.
	 */
	protected getDefaultStructure = (
		props: ITableDefaultStructureProps,
	): IComponentStructure<ITableComponentTexture> => {
		if (props.columns === 0 || props.rows === 0) {
			throw new ManipulatorError('size can not 0 or size not setup');
		}

		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();
		const cells: ITableCellTexture[] = [];

		for (let i = 0; i < props.rows; i++) {
			for (let j = 0; j < props.columns; j++) {
				const cellTexture = this.getDefaultCell(i, j);
				cells.push(cellTexture);
			}
		}

		const graphic: IGraphicStructure<ITableGraphicTexture> = {
			id: graphicId,
			type: GraphicType.TABLE,
			frame: {
				width: 601,
				height: 156,
				layer: 100,
				x: 60,
				y: 90,
				rotate: 0,
			},
			offset: 0,
			texture: {
				rowCount: props.rows,
			},
		};

		return {
			id: componentId,
			type: SketchComponentType.TABLE,
			offset: props.componentOffset,
			graphics: [graphic],
			components: null,
			texture: {
				startRows: [0],
				rowMultipliers: new Array(props.rows).fill(1),
				borderColor: 'black',
				columnMultipliers: new Array(props.columns).fill(1),
				cells,
			},
		};
	};

	/**
	 * Возвращает структуру таблицы с загруженными данными (методами `loadSize` и `loadCellTextures`).
	 */
	private getLoadedStructure = (props: IGetLoadedStructureProps): IComponentStructure<ITableComponentTexture> => {
		if (props.columns === 0 || props.rows === 0) {
			throw new ManipulatorError('size can not 0 or size not setup');
		}

		const componentId = Utils.Generate.UUID4();
		const graphicId = Utils.Generate.UUID4();

		const graphic: IGraphicStructure<ITableGraphicTexture> = {
			id: graphicId,
			type: GraphicType.TABLE,
			frame: {
				width: 601,
				height: 156,
				layer: 0,
				x: 60,
				y: 90,
				rotate: 0,
			},
			offset: 0,
			texture: {
				rowCount: props.rows,
			},
		};

		return {
			id: componentId,
			type: SketchComponentType.TABLE,
			offset: props.componentOffset,
			graphics: [graphic],
			texture: {
				cells: props.cells,
				startRows: [0],
				borderColor: 'black',
				columnMultipliers: new Array(props.columns).fill(1),
				rowMultipliers: new Array(props.rows).fill(1),
			},
			components: null,
		};
	};

	/**
	 * Возвращает текстуру ячейки по умолчанию.
	 */
	private getDefaultCell = (row: number, column: number): ITableCellTexture => ({
		id: Utils.Generate.UUID4(),
		content: Utils.Component.getDefaultTextModel(),
		row,
		column,
		rowSpan: 1,
		columnSpan: 1,
		background: '#ffffff',
	});
}

export default TableInjector;
