import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import {
	ICreateTemplateTagResponse,
	IGetTemplateTagsRequest,
	IGetTemplateTagsResponse,
	ITemplateBindTagRequest,
	ITemplateTag,
	IUpdateTemplateTagRequest,
} from './types';
import { URI_BIND_TEMPLATE_TAG, URI_DELETE_TEMPLATE_TAG, URI_TEMPLATE_TAG } from './consts';

export const templateTagAPI = createApi({
	reducerPath: 'templateTagAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['template_tags'],
	endpoints: builder => ({
		create: builder.mutation<ICreateTemplateTagResponse, { name: string }>({
			query: ({ name }) => ({
				url: URI_TEMPLATE_TAG,
				method: 'POST',
				body: { name },
			}),
			invalidatesTags: ['template_tags'],
		}),
		getAll: builder.query<IGetTemplateTagsResponse, IGetTemplateTagsRequest>({
			query: (data) => ({
				url: URI_TEMPLATE_TAG,
				method: 'GET',
				params: data,
			}),
			providesTags: ['template_tags'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_DELETE_TEMPLATE_TAG}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['template_tags'],
		}),
		unbind: builder.mutation<void, ITemplateBindTagRequest>({
			query: ({
				tag,
				template,
			}) => ({
				url: URI_BIND_TEMPLATE_TAG(tag, template),
				method: 'DELETE',
			}),
			invalidatesTags: ['template_tags'],
		}),
		update: builder.mutation<ITemplateTag, IUpdateTemplateTagRequest>({
			query: ({
				id,
				name,
			}) => ({
				url: `${URI_TEMPLATE_TAG}/${id}`,
				method: 'PUT',
				body: { name },
			}),
			invalidatesTags: ['template_tags'],
		}),
	}),
});
