export const URI_TARIFF_BITMAP = '/tariff/bitmap';

export const URI_CREATE_TARIFF_TRANSACTION = (
	organizationId: string,
) => `admin/organization/${organizationId}/tariff/transaction`;
export const URI_DISABLE_TARIFF_TRANSACTION = (
	transactionId: number,
) => `/admin/tariff/transaction/${transactionId}/disable`;
export const URI_ENABLE_TARIFF_TRANSACTION = (
	transactionId: number,
) => `/admin/tariff/transaction/${transactionId}/enable`;
