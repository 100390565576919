import HTMLGenerator from '../../../../../utils/HTMLGenerator';

/**
 * Отображает модифицированную рамку фрейма при режиме редактирования у графики изображения.
 */
class PictureTrimArea {
	private readonly ELEMENT_CLASS_NAME = 'picture-block__trim-area';
	private readonly LEFT_TOP_CLASS_NAME = 'left-top';
	private readonly LEFT_MIDDLE_CLASS_NAME = 'left-middle';
	private readonly LEFT_BOTTOM_CLASS_NAME = 'left-bottom';
	private readonly RIGHT_TOP_CLASS_NAME = 'right-top';
	private readonly RIGHT_MIDDLE_CLASS_NAME = 'right-middle';
	private readonly RIGHT_BOTTOM_CLASS_NAME = 'right-bottom';
	private readonly ELEMENT_HIDE_CLASS_NAME = 'hide';

	private readonly element: HTMLElement;
	private readonly leftTopElement: HTMLElement;
	private readonly leftMiddleElement: HTMLElement;
	private readonly leftBottomElement: HTMLElement;
	private readonly rightTopElement: HTMLElement;
	private readonly rightMiddleElement: HTMLElement;
	private readonly rightBottomElement: HTMLElement;

	constructor() {
		this.element = HTMLGenerator.getDiv({
			className: this.ELEMENT_CLASS_NAME,
		});

		this.leftTopElement = HTMLGenerator.getDiv({
			className: this.LEFT_TOP_CLASS_NAME,
		});

		this.leftMiddleElement = HTMLGenerator.getDiv({
			className: this.LEFT_MIDDLE_CLASS_NAME,
		});

		this.leftBottomElement = HTMLGenerator.getDiv({
			className: this.LEFT_BOTTOM_CLASS_NAME,
		});

		this.rightTopElement = HTMLGenerator.getDiv({
			className: this.RIGHT_TOP_CLASS_NAME,
		});

		this.rightMiddleElement = HTMLGenerator.getDiv({
			className: this.RIGHT_MIDDLE_CLASS_NAME,
		});

		this.rightBottomElement = HTMLGenerator.getDiv({
			className: this.RIGHT_BOTTOM_CLASS_NAME,
		});

		this.element.append(
			this.leftTopElement,
			this.leftMiddleElement,
			this.leftBottomElement,
			this.rightTopElement,
			this.rightMiddleElement,
			this.rightBottomElement,
		);
	}

	public enable = () => {
		this.element.classList.remove(this.ELEMENT_HIDE_CLASS_NAME);
	};

	public disable = () => {
		this.element.classList.add(this.ELEMENT_HIDE_CLASS_NAME);
	};

	public getElement = (): HTMLElement => this.element;
}

export default PictureTrimArea;
