import React from 'react';
import css from './changeable-category-list.module.scss';
import { ICategoryData } from '../../../../entities/modules/category/api/types';
import { EditableCategory } from '../../../../shared/ui/editable-category/editable-category';
import { ITemplateCategory } from '../../../../entities/templates/categories/api/types';

interface ChangeableCategoryListProps {
	activeCategories: ITemplateCategory[] | ICategoryData[],
	disabledCategories?: ITemplateCategory[] | ICategoryData[],
	deleteCategory?: (id: number) => void,
	disableCategoryHint?: string,
	isNeedHint?: boolean,
}

export const ChangeableCategoryList = ({
	deleteCategory,
	activeCategories,
	disabledCategories,
	isNeedHint = true,
	disableCategoryHint = 'Категории модуля не редактируются',
}: ChangeableCategoryListProps) => {
	/**
	 *  Проверяет, передано ли deleteCategory.
	 *  Если передано, то удаляем категорию.
	 */
	const onDeleteCategory = (id: number) => {
		if (deleteCategory) deleteCategory(id);
	};

	return (
		<div className={css.wrapper}>
			{
				activeCategories?.map(category => (
					<EditableCategory
						key={category.id}
						category={category}
						state="active"
						closeAble={!!deleteCategory}
						onClose={onDeleteCategory}
						styles={css.category}
					/>
				))
			}
			{
				disabledCategories?.map(category => (
					<EditableCategory
						key={category.id}
						category={category}
						state="disabled"
						closeAble={false}
						styles={css.category}
						hint={isNeedHint ? disableCategoryHint : undefined}
					/>
				))
			}
		</div>
	);
};
