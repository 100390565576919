import ToolBarTool from '../ToolBarTool';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';

/**
 * Элемент интерфейса для создания нового компонента фигуры.
 */
class InjectFigureTool extends ToolBarTool {
	private readonly HINT_TEXT = 'Фигура / Shift + F';

	constructor() {
		super('panel-components__figure');
		this.setHint(this.HINT_TEXT);
		Utils.DOM.injectSVG(this.rootElement, SvgCollection.FIGURE_TOOL);
	}
}

export default InjectFigureTool;
