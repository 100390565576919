import { useEffect, useRef } from 'react';

/**
 * Отслеживает состояние монтирования компонента.
 * Используется, где есть риск обновления состояния после размонтирования.
 */
export const useIsMounted = () => {
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	return isMounted;
};
