import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';
import SvgCollection from '../../utils/SvgCollection';
import Utils from '../../utils/impl/Utils';
import HTMLGenerator from '../../utils/HTMLGenerator';

/**
 * Квадратная кнопка.
 */
class SquareButton extends ElementContainer<HTMLLIElement> {
	private readonly SQUARE_BUTTON_HIDE = 'square-button-hide';

	private readonly hintElement: HTMLElement;
	private readonly showHintRules: (() => boolean)[];

	private classNames: string[];

	constructor() {
		super(HTMLElementName.LI);
		this.classNames = [];
		this.showHintRules = [];
		this.hintElement = HTMLGenerator.getDiv({ className: 'hint top-right' });
	}

	/**
	 * Добавляет CSS класс корневому элементу кнопки.
	 * @param className Имя класса.
	 */
	public appendClassName = (className: string) => {
		this.classNames.push(className);
		this.applyClassNames();
	};

	/**
	 * Удаляет CSS класс у корневого элемента кнопки.
	 * @param className Имя класса.
	 */
	public removeClassName = (className: string) => {
		this.classNames = this.classNames.filter(name => name !== className);
		this.applyClassNames();
	};

	public addClickListener = (listener: VoidFunction) => {
		this.addRootElementClickListener(listener);
	};

	public setIcon = (icon: SvgCollection) => {
		Utils.DOM.injectSVG(this.rootElement, icon);
	};

	public setHint = (text: string) => {
		this.hintElement.textContent = text;
		this.addRootElementMouseEnterListener(this.showHintWithRules);
		this.addRootElementMouseLeaveListener(this.hideHint);
	};

	public appendShowHintRule = (rule: () => boolean) => {
		this.showHintRules.push(rule);
	};

	public show = () => {
		this.rootElement.classList.remove(this.SQUARE_BUTTON_HIDE);
	};

	public hide = () => {
		this.rootElement.classList.add(this.SQUARE_BUTTON_HIDE);
	};

	protected showHint = () => {
		this.rootElement.append(this.hintElement);
	};

	protected hideHint = () => {
		this.hintElement.remove();
	};

	private applyClassNames = () => {
		this.setRootElementClassName(this.classNames.join(' '));
	};

	private showHintWithRules = () => {
		const isShowAccess = this.showHintRules.every(rule => rule());
		if (!isShowAccess) {
			return;
		}
		this.showHint();
	};
}

export default SquareButton;
