import { RootState } from '../../reducers/reducer';

const all = (state: RootState) => state.category;

const categoryModalsActive = (state: RootState) => all(state).modalsActive;

const categories = (state: RootState) => all(state).categoriesData;

export {
	all,
	categories,
	categoryModalsActive,
};
