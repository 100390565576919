import React, { useState } from 'react';
import css from './tagMenu.module.scss';
import SearchInput from '../../../../../ui/searchInput/SearchInput';
import { ITagGroupData } from '../../../../../../entities/modules/tag-group/api/types';
import { ITagData } from '../../../../../../entities/modules/tag/api/types';
import { ModuleTag } from '../../../../../../entities/modules/tag/ui/module-tag';

interface TagMenuModalProps {
	onCreate: () => void,
	searchHolder: string,
	createBtnText: string,
	onEditing: (id: number) => void,
	onDeleting: (id: number) => void,
	data: ITagGroupData[] | ITagData[] | null,
}

const TagMenuModal = ({
	data,
	onCreate,
	onEditing,
	onDeleting,
	searchHolder,
	createBtnText,
}: TagMenuModalProps) => {
	const [inputValue, setInputValue] = useState('');

	return (
		<div className={css.wrapper}>
			<SearchInput
				onChange={setInputValue}
				styles={css.input}
				placeholder={searchHolder}
				searchValue={inputValue}
			/>
			<div className={css.tagsBlock}>
				{
					data
						? data.map(tag => {
							const { id } = tag;

							return (
								<ModuleTag
									tag={tag}
									key={id}
									onDelete={() => onDeleting(id)}
									onEdit={() => onEditing(id)}
								/>
							);
						})
						: <div className={css.description}>Нет созданных тегов</div>
				}
			</div>
			<div
				className={css.createBtn}
				onClick={onCreate}
				role="presentation"
			>
				<span>{createBtnText}</span>
			</div>
		</div>
	);
};

export default TagMenuModal;
