import React from 'react';
import css from './confirmModal.module.scss';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';
import { MainButton } from '../../../ui/main-button/main-button';

interface ConfirmModalProps {
	text: string,
	title?: string,
	disabled?: boolean,
	onDelete: () => void,
	closeBtnText?: string,
	actionBtnText?: string,
	isModalActive: boolean,
	onCloseModal: () => void,
}

const ConfirmModal = ({
	text,
	disabled,
	title = 'Подтверждение удаления',
	onDelete,
	closeBtnText = 'Нет, не хочу',
	onCloseModal,
	actionBtnText = 'Да, хочу',
	isModalActive,
}: ConfirmModalProps) => {
	useCatchEnterClick(onDelete, isModalActive);

	return (
		<div className={css.wrapper}>
			<div className={css.title}>
				{title}
			</div>
			<div className={css.descr}>
				{text}
			</div>
			<div className={css.buttonBlock}>
				<MainButton
					btnStyle="bright"
					onClick={onCloseModal}
					type="button"
					styles={css.button}
					disabled={disabled}
				>
					{closeBtnText}
				</MainButton>

				<MainButton
					btnStyle="bright"
					onClick={onDelete}
					type="button"
					styles={css.button}
					disabled={disabled}
				>
					{actionBtnText}
				</MainButton>
			</div>
		</div>
	);
};

export default ConfirmModal;
