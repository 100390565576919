import Dependent from '../../utils/dependent/Dependent';
import { AnyComponentStructure } from '../../Types';
import IComponentTree from '../IComponentTree';

export interface IComponentTreeBackupServiceDependencies {
	componentTree: IComponentTree,
}

class ComponentTreeBackupService extends Dependent<IComponentTreeBackupServiceDependencies> {
	private backup: AnyComponentStructure;
	private postRestoreListeners: VoidFunction[];

	constructor() {
		super();
		this.postRestoreListeners = [];
	}

	public save = () => {
		this.backup = this.dependencies.componentTree.getStructure();
	};

	public restore = () => {
		this.dependencies.componentTree.reset();
		this.dependencies.componentTree.load(this.backup);
		this.callPostRestoreListeners();
	};

	public addPostRestoreListener = (listener: VoidFunction) => {
		this.postRestoreListeners.push(listener);
	};

	private callPostRestoreListeners = () => {
		this.postRestoreListeners.forEach(listener => listener());
	};
}

export default ComponentTreeBackupService;
