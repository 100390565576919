import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import DirectionVector from '../DirectionVector';
import MagneticCorrector from './MagneticCorrector';
import MagneticLineEntity from '../MagneticLineEntity';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import AreaPositionMutator from '../../spatial-quadrants/area-mutators/mutators/AreaPositionMutator';
import IMagneticLines from '../IMagneticLines';

/**
 * Реализует алгоритм реагирования на приближения областей к магнитным линиям при изменении их позиции.
 */
class MagneticPositionCorrector extends MagneticCorrector<AreaPositionMutator> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(magneticLines: IMagneticLines, areaPositionMutator: AreaPositionMutator) {
		super(magneticLines, areaPositionMutator);
	}

	protected calculateCorrectValues = (
		magneticLineEntities: MagneticLineEntity[],
		dryConfiguration: IFrameArea,
		correctValues: IFrameArea,
	): void => {
		for (let i = 0; i < magneticLineEntities.length; i++) {
			const lineEntity = magneticLineEntities[i];

			const isMagnetizationZone = lineEntity.isMagnetizationZone(dryConfiguration);
			if (isMagnetizationZone !== null) {
				switch (isMagnetizationZone) {
				case DirectionVector.LEFT:
					correctValues.x = -(dryConfiguration.x - lineEntity.linePosition.x);
					this.linesState.enabledLineX = lineEntity.line;
					break;
				case DirectionVector.RIGHT:
					correctValues.x = -(dryConfiguration.x + dryConfiguration.width - lineEntity.linePosition.x);
					this.linesState.enabledLineX = lineEntity.line;
					break;
				case DirectionVector.TOP:
					correctValues.y = -(dryConfiguration.y - lineEntity.linePosition.y);
					this.linesState.enabledLineY = lineEntity.line;
					break;
				case DirectionVector.BOTTOM:
					correctValues.y = -(dryConfiguration.y + dryConfiguration.height - lineEntity.linePosition.y);
					this.linesState.enabledLineY = lineEntity.line;
					break;
				case DirectionVector.MIDDLE_X:
					correctValues.x = -(dryConfiguration.x + (dryConfiguration.width / 2) - lineEntity.linePosition.x);
					this.linesState.enabledLineX = lineEntity.line;
					break;
				case DirectionVector.MIDDLE_Y:
					correctValues.y = -(dryConfiguration.y + (dryConfiguration.height / 2) - lineEntity.linePosition.y);
					this.linesState.enabledLineY = lineEntity.line;
					break;
				default: throw new ManipulatorError('direction not found');
				}

				this.magneticLines.setIsMagnet(true);
			} else {
				this.linesState.disabledLines.push(lineEntity.line);
			}
		}

		this.mutator.setCorrectValues(correctValues);

		if (!this.magneticLines.getIsMagnet()) {
			this.mutator.clearCorrectValues();
		}
	};
}

export default MagneticPositionCorrector;
