import React from 'react';
import SketchStructureVisualizer from './SketchStructureVisualizer';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import ISketchStructure from '../../Sketch/ISketchStructure';

export interface SketchStructureVisualizerProps {
	structure: ISketchStructure
}

class ComponentSketchStructureVisualizer extends React.Component<SketchStructureVisualizerProps, object> {
	private refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();
	private manipulator: SketchStructureVisualizer | null = null;

	override componentDidMount() {
		const { structure } = this.props;
		const container = this.refManipulatorContainer.current;
		if (container === null || container === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}
		this.manipulator = new SketchStructureVisualizer(container, structure);
	}

	override componentWillUnmount() {
		this.manipulator && this.manipulator.destruct();
	}

	override render() {
		return <div ref={this.refManipulatorContainer} className="sketch-manipulator sketch-structure-visualizer" />;
	}
}

export default ComponentSketchStructureVisualizer;
