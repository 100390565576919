import React, { ButtonHTMLAttributes, useState } from 'react';
import light from './styles/light.module.scss';
import bright from './styles/bright.module.scss';
import grey from './styles/grey.module.scss';
import generalCss from './styles/general.module.scss';
import Loading from './loading/Loading';

export enum buttonsTypes {
    BRIGHT = 'bright',
    LIGHT = 'light',
    GREY = 'grey'
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    cssClass?: string,
    inActive?: boolean,
    inProcess?: boolean,
    childCssClass?: string,
	buttonType?: buttonsTypes,
    onClick?: () => void,
}

const Button = ({
	cssClass,
	childCssClass,
	inActive,
	onClick,
	inProcess,
	children,
	text,
	buttonType = buttonsTypes.BRIGHT,
	...props
}: ButtonProps) => {
	const [disabled, setDisabled] = useState(false);
	const [processing, setProcessing] = useState(false);

	let css;
	switch (buttonType) {
	case buttonsTypes.BRIGHT:
		css = bright;
		break;
	case buttonsTypes.LIGHT:
		css = light;
		break;
	default:
		css = grey;
		break;
	}
	return (
		<button
			type="button"
			disabled={
				disabled || inProcess || processing || !inActive
			}
			onClick={onClick}
			style={{ cursor: `${inActive ? 'pointer' : 'default'}` }}
			className={`${css.button} ${css.active} ${generalCss.generalBtn} ${cssClass} 
			${children ? css.long : ''} ${processing ? css.process : ''}`}
			{...props}
		>
			{
				inProcess || processing
					? <Loading color={css === grey ? '#C1C1C1' : ''} />
					: (
						<div className={
							children
								? `${generalCss.blockWithChildren} ${childCssClass}`
								: generalCss.block
						}
						>
							{children}
							<span className={children ? css.text : ''}>{text}</span>
						</div>
					)
			}
		</button>
	);
};

export default Button;
