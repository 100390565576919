import React from 'react';
import cx from 'classnames';
import css from './title.module.scss';

interface TitleProps {
	children: React.ReactNode,
	underlined?: boolean,
	styles?: string,
}

export const Title = React.forwardRef<
	HTMLDivElement,
	TitleProps
>(({
	children,
	styles,
	underlined,
}, ref) => (
	<h3 ref={ref} className={cx(css.title, underlined && css.underlined, styles)}>
		{children}
	</h3>
));
