import { workshopTemplateAPI } from '../../../../entities/templates/workshop/template/api/api';
import { notificationError } from '../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../shared/libs/redux';

export const useEditWorkshopTemplateName = (template: string) => {
	const dispatch = useAppDispatch();

	const [edit, { isLoading }] = workshopTemplateAPI.useUpdateMutation();

	const editName = async (name: string) => {
		try {
			await edit({
				name,
				template,
			})
				.unwrap();
			dispatch(workshopTemplateAPI.util?.invalidateTags(['templates']));
		} catch (e) {
			notificationError('Произошла ошибка при изменении названия шаблона...');
		}
	};

	return {
		editName,
		isLoading,
	};
};
