import React from 'react';
import css from './editBtns.module.scss';
import { ReactComponent as EditCategoryIcon } from '../../../../../images/edit.svg';
import { ReactComponent as DeleteCategoryIcon } from '../../../../../images/delete.svg';

interface EditBtnsProps {
	openEditingTagGroupModal: () => void,
	openDeletingTagGroupModal: () => void,
}

const EditBtns: React.FC<EditBtnsProps> = ({
	openEditingTagGroupModal,
	openDeletingTagGroupModal,
}: EditBtnsProps) => (
	<>
		<div
			role="presentation"
			className={css.edit_btn}
			onClick={openEditingTagGroupModal}
		>
			<EditCategoryIcon />
		</div>
		<div
			role="presentation"
			className={css.edit_btn}
			onClick={openDeletingTagGroupModal}
		>
			<DeleteCategoryIcon />
		</div>
	</>
);

export default EditBtns;
