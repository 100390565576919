import ListTool from './ListTool';
import InterfacePanel from '../../../InterfacePanel';

class ListsPanel extends InterfacePanel {
	private readonly listTool: ListTool;

	constructor() {
		super();
		this.listTool = new ListTool();

		this.setRootElementClassName('panel-lists');
		this.rootElement.append(this.listTool.getElement());
	}
}

export default ListsPanel;
