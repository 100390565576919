import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/reducers/reducer';
import { setIsDeletingTagModalActive } from '../../../../../../redux/reducers/module/tagReducer';
import { notificationError, notificationSuccess } from '../../../../../Notifications/callNotifcation';
import DeletingTagWindow from './DeletingTagWindow';
import { all, tagModalsActive } from '../../../../../../redux/selectors/module/tag';
import { tagAPI } from '../../../../../../entities/modules/tag/api/api';
import { tagGroupAPI } from '../../../../../../entities/modules/tag-group/api/api';
import { moduleAPI } from '../../../../../../entities/modules/module/api/api';

const DeletingTagWrapper = () => {
	const dispatch = useDispatch();

	const isDeletingTagModalActive = useSelector((state: RootState) => tagModalsActive(state).isDeletingTagModalActive);

	const deletingTagId = useSelector((state: RootState) => all(state).deletingTagId);

	const [deleteTag] = tagAPI.useDeleteMutation();

	const handleDeletingModal = (value: boolean) => {
		dispatch(setIsDeletingTagModalActive(value));
	};

	const onDeleteTag = async () => {
		if (deletingTagId) {
			try {
				await deleteTag({ id: deletingTagId })
					.unwrap();
				dispatch(tagGroupAPI.util?.invalidateTags(['tag_group']));
				dispatch(moduleAPI.util?.invalidateTags(['modules']));
				handleDeletingModal(false);
				notificationSuccess('Тег удален успешно!');
			} catch (e) {
				notificationError('Возникла ошибка при удалении тега...');
			}
		}
	};

	return (
		<DeletingTagWindow
			onDelete={onDeleteTag}
			handleDeletingModal={handleDeletingModal}
			isDeletingModalActive={isDeletingTagModalActive}
			text="Вы действительно хотите удалить тег без возможности восстановления?"
		/>
	);
};

export default DeletingTagWrapper;
