import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { ITagData } from '../../../../../entities/modules/tag/api/types';

export const useBindTagToWorkshopTemplate = (template: string) => {
	const [bindTag, { isLoading }] = workshopTemplateAPI.useBindTagMutation();

	const onBindTag = async (tag: ITagData) => {
		try {
			await bindTag({
				tag: tag.id,
				template,
			})
				.unwrap();
			notificationSuccess('Тег успешно добавлен шаблону!');
		} catch (e) {
			notificationError('Произошла ошибка при добавлении тега шаблону...');
		}
	};

	return {
		isLoading,
		onBindTag,
	};
};
