import SpatialArea from '../SpatialArea';
import IFrameArea from '../IFrameArea';
import SpatialAreaType from '../SpatialAreaType';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import Graphic from '../../../../../graphic/Graphic';
import ImageState from '../../../../../graphic/foundation/image/ImageState';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import IGraphic from '../../../../../graphic/IGraphic';

interface ISpatialPictureAreaData extends ISpatialAreaBaseData<IGraphic> {
	pictureState: ImageState<any>,
}

/** Область для изображения внутри компонента. */
class SpatialPictureArea extends SpatialArea<ISpatialPictureAreaData> {
	public readonly type: SpatialAreaType = SpatialAreaType.PICTURE;

	private readonly graphic: IGraphic;
	private readonly pictureState: ImageState<any>;

	constructor(graphic: IGraphic, pictureState: ImageState<any>) {
		super();
		this.graphic = graphic;
		this.pictureState = pictureState;
	}

	public getData = (): ISpatialPictureAreaData => ({
		graphic: this.graphic,
		pictureState: this.pictureState,
	});

	public getGlobalFrameArea = (): IFrameArea => {
		const graphicPosition = this.graphic.getGlobalPosition();
		const pictureTexture = this.pictureState.getTexture();
		return {
			x: graphicPosition.x + pictureTexture.x,
			y: graphicPosition.y + pictureTexture.y,
			width: pictureTexture.width,
			height: pictureTexture.height,
			rotate: pictureTexture.rotate,
		};
	};

	public getRelatedFrames = (): IAreaSizeMutable[] => [this.pictureState];

	public isResizeType = (): boolean => false;

	public isAllowMutatePosition = (): boolean => true;
}

export default SpatialPictureArea;
