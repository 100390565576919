import React from 'react';
import css from './user-list.module.scss';
import skeletonUsers from './SkeletonUsers';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { setIsUsersCreateModalActive, setUsersSearchValue } from '../../../../../redux/reducers/usersReducer';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../Notifications/callNotifcation';
import SearchInput from '../../../../ui/searchInput/SearchInput';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import { CreateButton } from '../../../../../shared/ui';

interface UserListProps {
	children: React.ReactNode,
	isDataLoading: boolean,
	statusRights: checkUsersRightsInterface,
}

const SKELETON_ITEM_COUNT = 12;

const UserList = ({
	isDataLoading,
	children,
	statusRights,
}: UserListProps) => {
	const dispatch = useAppDispatch();

	const {
		users,
		usersSearchValue: search,
	} = useAppSelector(state => state.user);

	// * Определяет есть ли у человека права для создания пользователя
	const isHaveRightsToShowAddUserModal = () => {
		if (statusRights.registration) {
			dispatch(setIsUsersCreateModalActive(true));
		} else {
			notificationError(ERROR_MESSAGE_LIST.ERROR, 'У вас недостаточно прав для совершения этого действия!');
		}
	};

	const setSearch = (value: string) => dispatch(setUsersSearchValue(value));

	return (
		<div className={css.usersWrapper}>
			<div className={css.searchBlock}>
				<CreateButton onClick={isHaveRightsToShowAddUserModal}>
					Создать пользователя
				</CreateButton>
				<SearchInput
					onChange={setSearch}
					placeholder="Поиск по сотрудникам"
					searchValue={search}
				/>
			</div>
			<div className={css.usersBlock}>
				{
					isDataLoading && users === undefined
						? skeletonUsers(SKELETON_ITEM_COUNT)
							.map(item => <div key={item.id}>{item.jsx}</div>)
						: children
				}
			</div>
		</div>
	);
};

export default UserList;
