import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import {
	URI_CREATE_TARIFF_TRANSACTION,
	URI_DISABLE_TARIFF_TRANSACTION,
	URI_ENABLE_TARIFF_TRANSACTION,
	URI_TARIFF_BITMAP,
} from './consts';
import { ICreateTransactionRequest, ITariffBitmapResponse, ITransactionResponse } from './types';

export const tariffAPI = createApi({
	reducerPath: 'tariffAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['tariff'],
	endpoints: builder => ({
		createTransaction: builder.mutation<ITransactionResponse, ICreateTransactionRequest>({
			query: ({
				organizationId,
				...data
			}) => ({
				url: URI_CREATE_TARIFF_TRANSACTION(organizationId),
				method: 'POST',
				body: { ...data },
			}),
		}),
		disableTransaction: builder.mutation<ITransactionResponse, { transactionId: number }>({
			query: ({ transactionId }) => ({
				url: URI_DISABLE_TARIFF_TRANSACTION(transactionId),
				method: 'PUT',
			}),
		}),
		enableTransaction: builder.mutation<ITransactionResponse, { transactionId: number }>({
			query: ({ transactionId }) => ({
				url: URI_ENABLE_TARIFF_TRANSACTION(transactionId),
				method: 'PUT',
			}),
		}),
		getBitmap: builder.query<ITariffBitmapResponse, void>({
			query: () => ({
				url: URI_TARIFF_BITMAP,
				method: 'GET',
			}),
		}),
	}),
});
