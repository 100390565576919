import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { notificationError, notificationSuccess } from '../../../Notifications/callNotifcation';
import { legalEntityAPI } from '../../../../entities/legal-entity/api/api';
import { setIsLegalEntityUserConfirmDeleteModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { accountAPI } from '../../../../entities/user/account/api/api';
import { adminAPI } from '../../../../entities/admin/api/api';

export const useUnbindUserFromEntity = () => {
	const dispatch = useAppDispatch();

	const {
		untieEmail,
		untieLegalEntityId,
		isLegalEntityUserConfirmDeleteModalActive: isActive,
	} = useAppSelector(state => state.legalEntity);

	const [unbind] = legalEntityAPI.useUnbindMutation();

	const setActive = (value: boolean) => dispatch(setIsLegalEntityUserConfirmDeleteModalActive(value));

	const unbindUser = async () => {
		if (untieEmail && untieLegalEntityId) {
			try {
				await unbind({
					email: untieEmail,
					legalEntity: untieLegalEntityId,
				})
					.unwrap();
				setActive(false);
				dispatch(adminAPI.util?.invalidateTags(['admin']));
				dispatch(accountAPI.util?.invalidateTags(['account']));
				notificationSuccess('Вы успешно удалили пользователя!');
			} catch (e) {
				notificationError('Произошла ошибка при удалении пользователя...');
			}
		}
	};

	return {
		unbindUser,
		isActive,
		setActive,
	};
};
