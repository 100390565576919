import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';
import { useSelector } from 'react-redux';
import css from '../WorkshopCategoriesBar.module.scss';
import { classNames } from '../../../../../shared/libs/classNames';
import { IWorkshopCategory } from '../../../../../entities/workshop-category/model/types/types';
import { useDNDCategories } from '../../../libs/useDNDCategories';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { workshopModuleActions, workshopModuleSelectors } from '../../../../../entities/workshop-module';
import { WorkshopDndCategoryCard } from '../dnd-category-card/WorkshopDndCategoryCard';

interface WorkshopCategoriesDnDListProps {
	className?: string,
	data?: IWorkshopCategory[]
}

export const WorkshopCategoriesDnDList = ({ className, data }: WorkshopCategoriesDnDListProps) => {
	const dispatch = useAppDispatch();

	const currentCategory = useSelector(workshopModuleSelectors.getCategories);

	const handleCurrentCategory = (id: number) => {
		if (currentCategory.includes(id)) {
			dispatch(workshopModuleActions.setCategories(
				currentCategory.filter(categoryId => categoryId !== id),
			));
		} else {
			dispatch(workshopModuleActions.setCategories([...currentCategory, id]));
		}
	};

	const {
		sensors,
		onDragEnd,
		categories,
	} = useDNDCategories(data);

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			{
				data
					? (
						<div className={css.categories}>
							<DndContext
								sensors={sensors}
								collisionDetection={closestCenter}
								onDragEnd={onDragEnd}
								modifiers={[
									restrictToVerticalAxis,
									restrictToWindowEdges,
									restrictToFirstScrollableAncestor,
								]}
							>
								<SortableContext items={categories || []} strategy={verticalListSortingStrategy}>
									{
										categories?.map(category => {
											const { id } = category;

											return (
												<WorkshopDndCategoryCard
													key={id}
													category={category}
													isActive={currentCategory.includes(id)}
													handleCurrentCategory={handleCurrentCategory}
												/>
											);
										})
									}
								</SortableContext>
							</DndContext>
						</div>
					)
					: <div className={css.not_found}><span>Категорий не найдено</span></div>
			}
		</div>
	);
};
