import IFrameArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';

class Geometry {
	/**
	 * Возвращает пересекаемую площадь двух прямоугольников.
	 */
	public intersectArea = (a: IFrameArea, b: IFrameArea): number => {
		const xOverlap = Math.max(0, Math.min(a.x + a.width, b.x + b.width) - Math.max(a.x, b.x));
		const yOverlap = Math.max(0, Math.min(a.y + a.height, b.y + b.height) - Math.max(a.y, b.y));
		return xOverlap * yOverlap;
	};
}

export default Geometry;
