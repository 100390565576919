import HTMLGenerator from '../../../utils/HTMLGenerator';
import Graphic from '../../Graphic';
import IImageTexture from './IImageTexture';
import IGraphic from '../../IGraphic';

/**
 * Базовая сущность для блока состояния с изображением.
 */
abstract class ImageGraphicBlock {
	private readonly rootElement: HTMLElement;

	protected readonly element: HTMLElement;

	private isEnabled: boolean;

	protected constructor(graphic: IGraphic) {
		const graphicElement = graphic.getGraphicElement();
		this.element = HTMLGenerator.getDiv();
		this.rootElement = graphicElement;
		this.isEnabled = false;
	}

	public enable = () => {
		this.appendElement();
		this.isEnabled = true;
	};

	public disable = () => {
		this.removeElement();
		this.isEnabled = false;
	};

	public isBlockEnabled = () => this.isEnabled;
	public getElement = () => this.element;

	public getTexture = (): IImageTexture => ({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
		rotate: 0,
		source: null,
		radius: {
			topLeft: 0,
			topRight: 0,
			bottomLeft: 0,
			bottomRight: 0,
		},
	});

	protected setElementClassName = (className: string) => {
		this.element.className = className;
	};

	private appendElement = () => {
		this.rootElement.append(this.element);
	};

	private removeElement = () => {
		this.element.remove();
	};
}

export default ImageGraphicBlock;
