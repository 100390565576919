import SpatialArea from '../SpatialArea';
import TableComponent from '../../../../../components/table/TableComponent';
import IFrameArea from '../IFrameArea';
import IAreaSizeMutable from '../../../area-mutators/IAreaSizeMutable';
import SpatialAreaType from '../SpatialAreaType';
import ISpatialAreaBaseData from '../ISpatialAreaBaseData';
import TableGraphic from '../../../../../graphic/table/TableGraphic';
import TableCellContext from '../../../../../graphic/table/cells/context/TableCellContext';
import TableCell from '../../../../../graphic/table/cells/TableCell';

interface ISpatialTableCellData extends ISpatialAreaBaseData<TableGraphic> {
	areaCell: TableCell,
	component: TableComponent,
	areaCellContext: TableCellContext,
}

/**
 * Область для ячейки внутри таблицы.
 */
class SpatialTableCellArea extends SpatialArea<ISpatialTableCellData> {
	public readonly type: SpatialAreaType = SpatialAreaType.TABLE_CELL;

	constructor(
		private readonly component: TableComponent,
		private readonly graphic: TableGraphic,
		private readonly cellContext: TableCellContext,
		private readonly cell: TableCell,
	) {
		super();
	}

	public getGlobalFrameArea = (): IFrameArea => this.cell.getFrameArea();

	public getRelatedFrames = (): IAreaSizeMutable[] => [];

	public isAllowMutatePosition = (): boolean => false;

	public isResizeType = (): boolean => false;

	public getData = (): ISpatialTableCellData => ({
		graphic: this.graphic,
		areaCell: this.cell,
		component: this.component,
		areaCellContext: this.cellContext,
	});
}

export default SpatialTableCellArea;
