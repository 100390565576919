import ManipulatorArea, { IManipulatorAreaDependencies } from '../../mechanics/ManipulatorArea';

interface IFigureInjectAreaDependencies extends IManipulatorAreaDependencies {}

/**
 * Область, определяющая размеры создаваемой фигуры.
 */
class FigureInjectArea extends ManipulatorArea<IFigureInjectAreaDependencies> {
	private readonly AREA_CLASS_NAME = 'pages-container__figure-area';

	constructor() {
		super();
		this.setAreaClassName(this.AREA_CLASS_NAME);
	}
}

export default FigureInjectArea;
