import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useInput } from '../../../hooks/useInput';
import css from './registration.module.scss';
import MainRegistrationContainer from './mainRegistrationPage/MainRegistrationContainer';
import CodeRegistrationContainer from './codeRegistrationPage/CodeRegistrationContainer';
import PasswordRegistrationContainer from './passwordRegistrationPage/PasswordRegistrationContainer';
import Cookie from './cookie/Cookie';
import { RegistrationOffer } from './RegistrationOffer';

export const MAIN_REGISTRATION_PAGE = 'MAIN_REGISTRATION_PAGE';
export const CODE_REGISTRATION_PAGE = 'CODE_REGISTRATION_PAGE';
export const PASSWORD_REGISTRATION_PAGE = 'PASSWORD_REGISTRATION_PAGE';

export type CurrentRegistrationPageData =
	typeof MAIN_REGISTRATION_PAGE
	| typeof CODE_REGISTRATION_PAGE
	| typeof PASSWORD_REGISTRATION_PAGE;

const Registration = () => {
	const email = useInput('', {
		isEmpty: true,
		minLength: 5,
		isEmail: true,
		maxLength: 255,
	});

	const { offer } = useParams();
	const registrationOffer = offer !== undefined && (Object.values(RegistrationOffer) as string[]).includes(offer)
		? offer as RegistrationOffer
		: null;

	const [currentPage, setCurrentPage] = useState<CurrentRegistrationPageData>(MAIN_REGISTRATION_PAGE);

	return (
		<div className={css.container}>
			{
				currentPage === MAIN_REGISTRATION_PAGE
				&& (
					<MainRegistrationContainer
						email={email}
						setCurrentPage={setCurrentPage}
						registrationOffer={registrationOffer}
					/>
				)
			}
			{
				currentPage === CODE_REGISTRATION_PAGE
				&& <CodeRegistrationContainer email={email.value} setCurrentPage={setCurrentPage} />
			}
			{
				currentPage === PASSWORD_REGISTRATION_PAGE
				&& <PasswordRegistrationContainer email={email.value} />
			}
			<Cookie />
		</div>
	);
};

export default Registration;
