import PageHeaderPanel from './PageHeaderPanel';
import PageGraphic from '../../graphic/page/PageGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IGraphic from '../../graphic/IGraphic';
import Dependent from '../../utils/dependent/Dependent';
import IMultiPageUseCases from '../../use-cases/IMultiPageUseCases';

interface IPageHeaderDependencies {
	pageGraphic: PageGraphic,
	useCases: IMultiPageUseCases,
}

/**
 * Оболочка PageHeaderPanel с полным функционалом для управления страницами.
 */
class PageHeader extends Dependent<IPageHeaderDependencies> {
	private readonly SCROLL_PADDING = 150;

	private panel: PageHeaderPanel;

	private currentPageNumber: number;

	constructor() {
		super();
		this.addPostInjectDependenciesListener(dependencies => {
			this.panel = new PageHeaderPanel();
			this.panel.addScrollUpEvent(this.scrollUp);
			this.panel.addAppendEvent(this.onAppendPage);
			this.panel.addScrollDownEvent(this.scrollDown);
			this.panel.addDeleteEvent(dependencies.useCases.deletePage.bind(this, dependencies.pageGraphic));

			const panelElement = this.panel.getElement();
			const rootElement = dependencies.pageGraphic.getFrameElement();
			rootElement.append(panelElement);
		});
	}

	public sync = () => {
		const component = this.dependencies.pageGraphic.getParentComponent();
		if (component === null) {
			return;
		}

		const graphics = component.getGraphics();

		this.syncAllPageCount(graphics);
		this.syncCurrentPageNumber(graphics, this.dependencies.pageGraphic);
	};

	public disable = () => {
		const element = this.panel.getElement();
		element.remove();
	};

	public show = () => {
		this.panel.show();
	};

	public hide = () => {
		this.panel.hide();
	};

	public enableLocationBottom = () => {
		this.panel.enableLocationBottom();
	};

	public disableLocationBottom = () => {
		this.panel.disableLocationBottom();
	};

	private syncAllPageCount = (graphics: IGraphic[]) => {
		this.panel.setAllPageCount(graphics.length);
	};

	private syncCurrentPageNumber = (graphics: IGraphic[], currentGraphic: PageGraphic) => {
		const currentValue = this.getGraphicIndex(graphics, currentGraphic) + 1;

		this.currentPageNumber = currentValue;
		this.panel.setCurrentNumber(currentValue);
	};

	private onAppendPage = () => {
		this.dependencies.useCases.appendPageAfter(this.dependencies.pageGraphic);
	};

	private scrollUp = () => {
		if (this.currentPageNumber === 1) {
			return;
		}
		const component = this.dependencies.pageGraphic.getParentComponent();
		if (component === null) {
			return;
		}

		const graphics = component.getGraphics();
		const targetGraphic = graphics[this.currentPageNumber - 2];

		this.scrollToGraphic(targetGraphic);
	};

	private scrollDown = () => {
		const component = this.dependencies.pageGraphic.getParentComponent();
		if (component === null) {
			return;
		}

		const graphics = component.getGraphics();
		if (this.currentPageNumber === graphics.length) {
			return;
		}

		const targetGraphic = graphics[this.currentPageNumber];
		this.scrollToGraphic(targetGraphic);
	};

	private getGraphicIndex = (graphics: IGraphic[], graphic: IGraphic): number => {
		const index = graphics.indexOf(graphic);
		if (index === -1) {
			throw new ManipulatorError('current page graphic not found in graphic list');
		}
		return index;
	};

	private scrollToGraphic = (graphic: IGraphic) => {
		const { y } = graphic.getGlobalPosition();
		window.scrollTo(0, y - this.SCROLL_PADDING);
	};
}

export default PageHeader;
