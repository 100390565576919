import SpatialAreaTree from './SpatialAreaTree';
import IComponent from '../../../components/IComponent';

abstract class BaseSpatialAreaTree extends SpatialAreaTree {
	/**
	 * Фильтрует компоненты, которые находятся в компоненте-объединетеле.
	 * @param component Проверяемый компонент.
	 */
	protected filterUntilComponents = (component: IComponent): boolean => {
		const parentComponent: IComponent | null = component.getParentComponent();

		if (parentComponent && parentComponent.isUniter) {
			return parentComponent.isEnableEditMode();
		}
		return true;
	};
}

export default BaseSpatialAreaTree;
