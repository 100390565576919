import IManipulatorErrorBody from './IManipulatorErrorBody';
import IBaseSketchManipulator from '../../../SketchManipulators/IBaseSketchManipulator';

/**
 * Внутренняя ошибка манипулятора.
 */
class ManipulatorError extends Error {
	private static manipulator: IBaseSketchManipulator | null = null;

	private readonly extend: any[] | null = null;

	constructor(errorMessage: string, ...extend: any[]) {
		super(errorMessage);

		if (ManipulatorError.manipulator === null) {
			console.error('failed to send an error to the server because the id sketch was not installed');
			return;
		}

		this.extend = extend;

		this.sendError();
	}

	public getBody = (): IManipulatorErrorBody => ({
		message: this.name,
		extend: this.extend !== null ? JSON.stringify(this.extend) : this.extend,
		stack: this.stack === undefined ? null : this.stack,
		sketchStructure: ManipulatorError.manipulator === null
			? null
			: JSON.stringify(ManipulatorError.manipulator.getStructure()),
	});

	public static setSketchConstrictor = (manipulator: IBaseSketchManipulator) => {
		this.manipulator = manipulator;
	};

	public static resetManipulator = () => {
		this.manipulator = null;
	};

	private sendError = () => {
		// TODO
	};
}
export default ManipulatorError;
