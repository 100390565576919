import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../../shared/api/api';
import { URI_WORKSHOP_TEMPLATE_TAG } from './consts';
import { IGetWorkshopTemplateTags, IWorkshopTemplateTag } from './types';

export const workshopTagAPI = createApi({
	reducerPath: 'workshopTagAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['tags'],
	endpoints: builder => ({
		create: builder.mutation<IWorkshopTemplateTag, { name: string }>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE_TAG,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['tags'],
		}),
		getAll: builder.query<IGetWorkshopTemplateTags, { limit: number, page: number, search?: string }>({
			query: (data) => ({
				url: URI_WORKSHOP_TEMPLATE_TAG,
				method: 'GET',
				params: data,
			}),
			providesTags: ['tags'],
		}),
		update: builder.mutation<IWorkshopTemplateTag, { tag: string, name: string }>({
			query: ({
				tag,
				...data
			}) => ({
				url: `${URI_WORKSHOP_TEMPLATE_TAG}/${tag}`,
				method: 'DELETE',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['tags'],
		}),
		delete: builder.mutation<void, { tag: number }>({
			query: ({ tag }) => ({
				url: `${URI_WORKSHOP_TEMPLATE_TAG}/${tag}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['tags'],
		}),
	}),
});
