import {
	Fragment, useCallback, useEffect, useState, 
} from 'react';
import { WorkshopTemplate } from '../template/WorkshopTemplate';
import { TemplateListWrapper } from '../../../../../../widgets/templates/list-wrapper';
import { useIsMounted } from '../../../../../../hooks/lifecycle/useIsMounted';
import { useAppSelector } from '../../../../../../shared/libs/redux';
import { useWorkshopSelection } from '../../../../../../hooks/workshop-template-selection/useWorkshopSelection';
import { useWorkshopTemplatesList } from '../../hooks/useWorkshopTemplatesList';
import { IWorkshopTemplate } from '../../../../../../entities/templates/workshop/template/model/types';

interface WorkshopTemplatesListProps {
	isDataLoading: boolean,
	sortBy: string,
	templates: IWorkshopTemplate[] | undefined,
}

export const WorkshopTemplatesList = ({
	templates,
	sortBy,
	isDataLoading,
}: WorkshopTemplatesListProps) => {
	const selectedList = useAppSelector(
		state => state.workshopTemplate.workshopTemplateSelectedList,
	);
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const isMounted = useIsMounted();

	const setGlobalLoadingState = (id: string | null) => {
		if (isMounted.current) {
			setIsGlobalLoading(!!id);
		}
	};

	useWorkshopSelection();

	return (
		<TemplateListWrapper isDataLoading={isDataLoading}>
			{
				templates?.map(template => (
					<WorkshopTemplate
						key={template.id}
						template={template}
						selected={selectedList.includes(template.id)}
						isGlobalLoading={isGlobalLoading}
						setGlobalLoading={setGlobalLoadingState}
					/>
				))
			}
		</TemplateListWrapper>
	);
};
