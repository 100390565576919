import { useParams } from 'react-router-dom';
import OfferSettingsContainer from '../../../offerSettings/OfferSettingsContainer';
import { teamTemplateAPI } from '../../../../../../../../entities/templates/team/api/api';

export const TeamTemplateOfferSettings = () => {
	const { id } = useParams();

	const { data: template } = teamTemplateAPI.useGetQuery({ id: id || '' }, {
		skip: !id,
	});

	return (
		<OfferSettingsContainer template={template} />
	);
};
