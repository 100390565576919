import { useSelector } from 'react-redux';
import { classNames } from '../../../../shared/libs/classNames';
import { workshopModuleApi, workshopModuleSelectors } from '../../../../entities/workshop-module';
import { WorkshopModuleModalCard } from '../module-card/WorkshopModuleModalCard';
import css from './WorkshopModulesModalList.module.scss';

interface WorkshopModulesModalListProps {
	className?: string,
	search?: string,
	onModuleClick?: ((id: string) => void) | null,
}

/** Блок модулей, который отображаются в модалке модулей конструктора (появляется при клике на кнопку модулей на панели
 * инструментов в конструкторе */
export const WorkshopModulesModalList = ({ className, onModuleClick, search }: WorkshopModulesModalListProps) => {
	const tags = useSelector(workshopModuleSelectors.getTags);
	const categories = useSelector(workshopModuleSelectors.getCategories);

	const { data: modules } = workshopModuleApi.useGetWorkshopModulesQuery({
		limit: 127,
		page: 1,
		categories: categories.map(id => String(id)),
		tags: tags.map(id => String(id)),
		search,
	});

	return (
		<div className={classNames(css.wrapper, {}, [className])}>
			{
				modules?.modules?.map(module => (
					<WorkshopModuleModalCard
						key={module.id}
						data={module}
						onModuleClick={onModuleClick}
					/>
				))
			}
		</div>
	);
};
