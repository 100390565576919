import CursorView from '../cursor/CursorView';
import MousePositionObserver from '../utils/observers/MousePositionObserver';
import IComponent from '../components/IComponent';
import Dependent from '../utils/dependent/Dependent';
import { AnyComponentStructure } from '../Types';
import IFrameArea from '../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import ManipulatorError from '../utils/manipulator-error/ManipulatorError';
import IDescartesPosition from '../utils/IDescartesPosition';
import IComponentInjectionParams from './IComponentInjectionParams';
import IGraphic from '../graphic/IGraphic';
import IComponentTreeMutator from '../component-tree/IComponentTreeMutator';
import IComponentTree from '../component-tree/IComponentTree';
import ILayeredComponentTree from '../component-tree/ILayeredComponentTree';

export interface IComponentInjectorDependencies {
	cursorView: CursorView,
	componentTree: IComponentTreeMutator & IComponentTree & ILayeredComponentTree,
	mousePositionObserver: MousePositionObserver,
}

/**
 * Абстрактный базовый класс для инжекторов (классов, управляющих процессом создания новых компонентов).
 */
abstract class ConstructorInjector<Dependencies extends IComponentInjectorDependencies>
	extends Dependent<Dependencies> {
	private readonly postInjectListeners: ((component: IComponent) => void)[];
	private readonly singleUseInjectListeners: ((component:IComponent) => void)[];

	private isReady: boolean;

	protected isProcess: boolean;

	protected abstract getDefaultStructure(props: object): AnyComponentStructure;

	constructor() {
		super();
		this.isReady = false;
		this.isProcess = false;
		this.postInjectListeners = [];
		this.singleUseInjectListeners = [];
	}

	/**
	 * Абстрактный метод, который должен быть реализован в наследуемых классах.
	 * Выполняет процесс вставки компонента.
	 */
	public abstract inject(): void;
	/**
	 * Абстрактный метод, который должен быть реализован в наследуемых классах.
	 * Запускает процесс инжекции.
	 */
	public abstract run(): void;
	/**
	 * Абстрактный метод, который должен быть реализован в наследуемых классах.
	 * Останавливает процесс инжекции.
	 */
	public abstract stop(): void;

	/**
	 * Добавляет слушателя события успешной инжекции компонента.
	 */
	public addPostInjectListener = (listener: (component: IComponent) => void) => {
		this.postInjectListeners.push(listener);
	};

	/**
	 * Возвращает `true`, если процесс инжекции запущен, иначе `false`.
	 */
	public isInjecting = (): boolean => this.isProcess;

	/**
	 * Проверяет, готов ли инжектор к вставке. Например, в случае изображения или фигуры на `onMouseDown` не
	 * нужно сразу вставлять компонент (как в случае с текстом, когда по клику сразу добавляется стандартный текст),
	 * а необходимо выделить область для вставки.
	 */
	public isReadyInject = (): boolean => this.isReady;

	/**
	 * Устанавливает инжектор в состояние готовности к вставке.
	 */
	public setReadyInject = () => {
		this.isReady = true;
	};

	/**
	 * Устанавливает инжектор в состояние неготовности к вставке.
	 */
	public setNotReadyInject = () => {
		this.isReady = false;
	};

	/**
	 * Обработчик события нажатия кнопки мыши.
	 * Может быть переопределен в наследуемых классах.
	 */
	public onMouseDown = () => {
		// override
	};

	/**
	 * Обработчик события отпускания кнопки мыши.
	 * Может быть переопределен в наследуемых классах.
	 */
	public onMouseUp = () => {
		// override
	};

	/**
	 * Добавить одноразового слушателя на инжекцию компонента.
	 * @param listener слушатель.
	 */
	public addSingleUseInjectListener = (listener: (component:IComponent) => void) => {
		this.singleUseInjectListeners.push(listener);
	};

	/**
	 * Запускает слушателей успешной инжекции компонента.
	 */
	protected callPostInjectListeners = (component: IComponent) => {
		this.postInjectListeners.forEach((listener) => listener(component));
	};

	protected callSingleUseInjectListeners = (component: IComponent) => {
		if (this.singleUseInjectListeners.length !== 0) {
			this.singleUseInjectListeners.forEach(listener => listener(component));
			this.singleUseInjectListeners.length = 0;
		}
	};

	/**
	 * Вычисляет параметры инжекции для компонента с областью на основе ближайшей графики для вставки.
	 * @param area Область компонента.
	 */
	protected calculateAreaComponentInjectionParams = (area: IFrameArea): IComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		let nearestRootGraphic: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список корневой графики дерева
		const treeRootGraphics: IGraphic[] = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const {
				x, y, width, height,
			} = rootGraphic.getFrameConfiguration();
			const pageCenterX = x + width / 2;
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от центра страницы до области вставки компонента
			const distance = Math.sqrt((area.x - pageCenterX) ** 2 + (area.y - pageCenterY) ** 2);

			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestRootGraphic = rootGraphic;
			}
		}

		if (nearestRootGraphic === null) {
			throw new ManipulatorError('nearest page not found');
		}

		const graphicPosition: IDescartesPosition = nearestRootGraphic.getGlobalPosition();
		const componentOffset: number = nearestRootGraphic.getOffset();
		return {
			x: graphicPosition.x,
			y: graphicPosition.y,
			componentOffset,
		};
	};

	/**
	 * Вычисляет параметры инжекции для компонента по позиции мыши.
	 * @param mousePosition - Текущая позиция мыши.
	 * @returns Объект с параметрами: graphicPositionX, graphicPositionY, componentOffset.
	 */
	protected calculateComponentInjectionParams = (mousePosition: IDescartesPosition): IComponentInjectionParams => {
		// Переменная для хранения ближайшей страницы
		let nearestPage: IGraphic | null = null;
		// Переменная для хранения минимального расстояния
		let minDistance = Number.MAX_SAFE_INTEGER;

		// Получаем список страниц
		const treeRootGraphics = this.dependencies.componentTree.getRootGraphics();
		// Находим ближайшую страницу к области вставки компонента
		for (let i = 0; i < treeRootGraphics.length; i++) {
			const rootGraphic = treeRootGraphics[i];

			const { y, height } = rootGraphic.getFrameConfiguration();
			const pageCenterY = y + height / 2;
			// Вычисляем расстояние от текущей позиции мыши до центра текущей страницы
			const distance = Math.abs(mousePosition.y - pageCenterY);
			// Обновляем ближайшее страницу и минимальное расстояние
			if (distance < minDistance) {
				minDistance = distance;
				nearestPage = rootGraphic;
			}
		}

		if (nearestPage === null) {
			throw new ManipulatorError('nearest page not found');
		}

		const graphicPosition: IDescartesPosition = nearestPage.getGlobalPosition();
		const componentOffset: number = nearestPage.getOffset();
		return {
			x: graphicPosition.x,
			y: graphicPosition.y,
			componentOffset,
		};
	};
}

export default ConstructorInjector;
