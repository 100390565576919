import React from 'react';
import { LegalEntityCard } from './card';
import { ILegalEntityItem } from '../../../../../../entities/legal-entity/api/types';
import { checkUsersRightsInterface } from '../../../../../../helpers/usersRights';
import css from '../../../../teams/ui/list/ui/list.module.scss';
import SkeletonTeams from '../../../../../../widgets/entity/skeleton/skeleton';
import { EmptyList } from '../../../../../ui/empty-list/empty-list';
import { useAppDispatch } from '../../../../../../shared/libs/redux';
import { setIsCreateLegalEntityModalActive } from '../../../../../../redux/reducers/legalEntityReducer';

interface LegalEntityListProps {
	isLoading: boolean,
	legalEntities: ILegalEntityItem[] | undefined,
	statusRights: checkUsersRightsInterface,
}

export const LegalEntityList = ({
	isLoading,
	statusRights,
	legalEntities,
}: LegalEntityListProps) => {
	const dispatch = useAppDispatch();

	const handleCreateModal = () => dispatch(setIsCreateLegalEntityModalActive(true));

	if (isLoading) {
		return (
			<div className={css.skeleton_wrapper}>
				<SkeletonTeams count={7} />
			</div>
		);
	}

	if (!legalEntities) {
		return (
			<div className={css.empty_wrapper}>
				<EmptyList
					needBtn
					btnText="Создать юр. лицо"
					title="Создайте первое юридическое лицо"
					onBtnClick={handleCreateModal}
				/>
			</div>
		);
	}

	return (
		<div className={css.wrapper}>
			{
				legalEntities.map(legalEntity => (
					<LegalEntityCard key={legalEntity.id} legalEntity={legalEntity} statusRights={statusRights} />
				))
			}
		</div>
	);
};
