import React, { useEffect, useState } from 'react';
import EntityWrapper from '../../../ui/entity/entity-wrapper/entity-wrapper';
import { Title } from '../../../ui/title';
import { FindBlock } from '../../../../widgets/entity/find-block';
import { LegalEntityList } from './list';
import { checkUsersRights, checkUsersRightsInterface } from '../../../../helpers/usersRights';
import { legalEntityAPI } from '../../../../entities/legal-entity/api/api';
import CreateLegalEntityModal from './modals/create-entity/create-entity';
import LegalEntityMultipleSelection from './modals/multiple-user-selection/multiple-user-selection';
import CreateManagerModal from './modals/create-manager/create-manager';
import { useDebounce } from '../../../../shared/libs/useDebounce';
import MultipleManagerSelectionModal from './modals/multiple-manager-selection/multiple-manager-selection';
import { useAppDispatch, useAppSelector } from '../../../../shared/libs/redux';
import { DeleteEntityModal } from './modals/delete-entity/delete-entity';
import { UnbindUserFromEntityModal } from './modals/unbind-user/unbind-user';
import { setIsCreateLegalEntityModalActive } from '../../../../redux/reducers/legalEntityReducer';
import { IAccount } from '../../../../entities/user/account/api/types';
import { ContentLayout } from '../../layouts/content';

export const LegalEntity = () => {
	const dispatch = useAppDispatch();

	const status = useAppSelector(state => state.personalAccount.account.status);

	const [search, setSearch] = useState('');
	const debounceValue = useDebounce(search, 250);

	const [selectedUser, setSelectedUser] = useState<IAccount | null>(null);

	const [statusRights, setStatusRights] = useState<checkUsersRightsInterface>(checkUsersRights(status));

	const {
		data: legalEntities,
		isLoading,
	} = legalEntityAPI.useGetAllQuery({
		page: 1,
		limit: 127,
		search: debounceValue,
		employee: selectedUser?.email,
	});

	const onCreateEntity = () => dispatch(setIsCreateLegalEntityModalActive(true));

	// * Обновляет права аккаунта при подтягивании статуса из state
	useEffect(() => {
		setStatusRights(checkUsersRights(status));
	}, [status]);

	return (
		<ContentLayout>
			<EntityWrapper>
				<Title underlined>Юридические лица</Title>
				<FindBlock
					openCreateModal={onCreateEntity}
					btnText="Создать юр. лицо"
					searchPlaceholder="Поиск по юр. лицам"
					searchValue={search}
					registrationRight={statusRights.registration}
					choicedUser={selectedUser}
					setChoicedUser={setSelectedUser}
					setSearchValue={setSearch}
				/>
				<LegalEntityList
					isLoading={isLoading}
					legalEntities={legalEntities?.legalEntities}
					statusRights={statusRights}
				/>
				<CreateLegalEntityModal />
				<DeleteEntityModal />
				<UnbindUserFromEntityModal />
				<MultipleManagerSelectionModal />
				<CreateManagerModal />
				<LegalEntityMultipleSelection />
			</EntityWrapper>
		</ContentLayout>
	);
};
