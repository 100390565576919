import IGraphicStructure from '../IGraphicStructure';
import GraphicType from '../GraphicType';
import ITextTexture from './ITextTexture';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import Graphic from '../Graphic';
import Editor from '../../mechanics/mext/editor';
import SpatialGraphicArea from '../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialGraphicArea';
import Utils from '../../utils/impl/Utils';
import { AnySpatialArea } from '../../Types';
import { TokenFormat } from '../../mechanics/mext/parser';

/**
 * Графика для текстового компонента.
 */
class TextGraphic extends Graphic<ITextTexture> {
	public readonly type = GraphicType.TEXT;

	protected readonly GRAPHIC_CLASS_NAME = 'page-frame__graphic-text';
	protected readonly AUTO_WIDTH_CLASS_NAME = 'auto-width';

	private readonly postInputEvents: VoidFunction[];

	protected readonly editor: Editor;

	constructor() {
		super();
		this.postInputEvents = [];

		this.editor = new Editor(this.graphicElement, {
			parse: {},
			value: '',
			nowrap: false,
			resetFormatOnNewline: false,
			shortcuts: {
				'Cmd+Z': editor => editor.undo(),
				'Cmd+Shift+Z': editor => editor.redo(),
				'Cmd+B': editor => editor.toggleFormat(TokenFormat.Bold),
				'Cmd+I': editor => editor.toggleFormat(TokenFormat.Italic),
				'Cmd+U': editor => editor.toggleFormat(TokenFormat.Strike),
				// Enter: _ => this.editor.setParagraphWithRedLine(),
				'Shift+Enter': _ => this.editor.setParagraph(),
			},
		});
		this.editor.addPostInputEvent(this.callPostInputEvents);

		this.graphicElement.classList.add(this.GRAPHIC_CLASS_NAME);
	}

	public addChangeSelectedRangeEvent = (fn: VoidFunction) => {
		this.editor.addPostChangeRangeEvent(fn);
	};

	public setStructure = (
		fn: (prev: IGraphicStructure<ITextTexture>) => IGraphicStructure<ITextTexture>,
	): void => {
		const current = this.getStructure();
		const updated = fn(current);
		const {
			texture, frame, id, offset,
		} = updated;

		this.id = id;
		this.offset = offset;
		if (texture === null) {
			throw new ManipulatorError('text graphic texture cannot be null');
		}
		if (frame === null) {
			throw new ManipulatorError('text graphic frame cannot be null');
		}

		this.setFrameConfiguration(_ => frame);
		this.setTexture(_ => texture);
	};

	public getTexture = (): ITextTexture => ({
		content: this.editor.getCopyModel(),
	});

	public setTexture = (fn: (prev: ITextTexture) => ITextTexture) => {
		const currentTexture = this.getTexture();
		const updatedTexture = fn(currentTexture);
		const { content } = updatedTexture;
		this.editor.model = content;
	};

	public addPostInputListener = (listener: VoidFunction) => {
		this.postInputEvents.push(listener);
	};

	public enableAutoWidth = () => {
		this.graphicElement.classList.add(this.AUTO_WIDTH_CLASS_NAME);
	};

	public disableAutoWidth = () => {
		this.graphicElement.classList.remove(this.AUTO_WIDTH_CLASS_NAME);
	};

	public getUniqueTexture: () => ITextTexture = (): ITextTexture => {
		const texture = this.getTexture();
		texture.content.id = Utils.Generate.UUID4();
		return texture;
	};

	public getEditor = (): Editor => this.editor;

	protected startMutation = (): void => {
		this.editor.enableEditable();
	};

	protected finishMutation = (): void => {
		this.editor.disableEditable();
	};

	private callPostInputEvents = () => {
		this.postInputEvents.forEach((event) => event());
	};

	public getSpatialAreas = (): AnySpatialArea[] => {
		const graphicArea = new SpatialGraphicArea(this);
		const resizeAreas = this.getResizeAreas();
		return [graphicArea, ...resizeAreas];
	};
}

export default TextGraphic;
