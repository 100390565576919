import { SelectableFilterCard } from '../../../../../../shared/ui';
import { IAccount } from '../../../../../../entities/user/account/api/types';
import css from './integrated-user.module.scss';

interface WorkshopTemplateIntegratedUserProps {
	user: IAccount | undefined,
}

export const WorkshopTemplateIntegratedUser = ({ user }: WorkshopTemplateIntegratedUserProps) => (
	<div className={css.wrapper}>
		<div className={css.title}>Интегрирован пользователю</div>
		{
			user && (
				<SelectableFilterCard
					selected
					styles={css.user}
					selectedType="light-bordered"
					data={{
						text: user.name,
						id: user.email,
						photo: user.photo,
					}}
				/>
			)
		}
	</div>
);
