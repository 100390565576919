import React from 'react';
import cx from 'classnames';
import { IMultipleItemI } from '../../../model/types';
import css from './list.module.scss';

interface MultipleItemListProps {
	items: IMultipleItemI[],
	checkItemActive: (id: string) => boolean,
	handleBinding: (id: string) => void,
}

export const MultipleItemList = ({
	items,
	checkItemActive,
	handleBinding,
}: MultipleItemListProps) => (
	<div className={css.item_list}>
		{
			items.map(({
				id,
				elem,
			}) => {
				const isActive = checkItemActive(id);

				return (
					<div
						onClick={() => handleBinding(id)}
						role="presentation"
						key={id}
						className={cx(css.item, isActive && css.active)}
					>
						{elem}
					</div>
				);
			})
		}
	</div>
);
