import React from 'react';
import ManipulatorError from '../../Sketch/utils/manipulator-error/ManipulatorError';
import OfferPresenter from './OfferPresenter';
import ISketchStructure from '../../Sketch/ISketchStructure';

export interface OfferPresenterProps {
	structure: ISketchStructure
}

class ComponentOfferPresenter extends React.Component<OfferPresenterProps, object> {
	private refManipulatorContainer: React.RefObject<HTMLDivElement> = React.createRef();
	private manipulator: OfferPresenter| null = null;

	override componentDidMount() {
		const { structure } = this.props;
		const container = this.refManipulatorContainer.current;
		if (container === null || container === undefined) {
			throw new ManipulatorError('manipulator container not found');
		}
		this.manipulator = new OfferPresenter(container, structure);
	}

	override componentWillUnmount() {
		if (this.manipulator === null) {
			return;
		}
		this.manipulator.destruct();
	}

	override render() {
		return <div ref={this.refManipulatorContainer} className="sketch-manipulator offer-presenter" />;
	}
}

export default ComponentOfferPresenter;
