import React from 'react';
import css from './tabs-header.module.scss';
import { Title } from '../../../../ui/title';
import { PageTabs } from '../../../../../widgets/tabs/page-tabs';
import { ITabData } from '../../../../../widgets/tabs/page-tabs/model/types';

interface TabsHeaderLayoutProps {
	title: string,
	tabsData: ITabData[],
	children: React.ReactNode,
}

export const TabsHeaderLayout = ({
	tabsData,
	children,
	title,
}: TabsHeaderLayoutProps) => (
	<div className={css.wrapper}>
		<Title underlined styles={css.title}>{title}</Title>
		<PageTabs data={tabsData} />
		{children}
	</div>
);
