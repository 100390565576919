import { IPermissionProperties, TEntity, TFrom } from "../../components/pages/permissions/types";
import { useShowPermission } from "./useShowPermission";
import { useAppSelector } from "../../shared/libs/redux";
import { TBitmapEntityPermissionValues } from "../../entities/permission/api/types";
import { checkBitPosition } from "../../helpers/checkBitPosition";

export const checkPermission = (permission = 0, right = 0) => {
	const num = checkBitPosition(permission, right);

	return {
		isPermitted: !!num,
		num
	};
};

export const useGetPermission = () => {
	const {
		team,
		legalEntity,
	} = useAppSelector(state => state.permissions.bitmap);

	const {
		showLegalEntityPermission,
		showTeamPermission,
	} = useShowPermission();

	return (type: TEntity, from: TFrom, id: string): IPermissionProperties => {
		const permissionFunction = type === "team" ? showTeamPermission : showLegalEntityPermission;

		const role = type === "team" ? team : legalEntity;

		const transformedRoles: Record<TBitmapEntityPermissionValues, number> = {} as Record<
			TBitmapEntityPermissionValues, number
		>;
		role?.forEach(({
			name,
			bitPosition,
		}) => {
			transformedRoles[name] = bitPosition;
		});

		const {
			OFFER_READ,
			TEMPLATE_READ,
			TEMPLATE_DELETE,
			TEMPLATE_WRITE,
			OFFER_WRITE,
			OFFER_DISABLE
		} = transformedRoles;

		const permission = permissionFunction(id);

		const readPermission = from === "template" ? TEMPLATE_READ : OFFER_READ;
		const changePermission = from === "template" ? TEMPLATE_WRITE : OFFER_WRITE;
		const delPermission = from === "template" ? TEMPLATE_DELETE : OFFER_DISABLE;

		return {
			read: {
				isPermitted: checkPermission(permission, readPermission),
				right: readPermission,
			},
			change: {
				isPermitted: checkPermission(permission, changePermission),
				right: changePermission,
			},
			del: {
				isPermitted: checkPermission(permission, delPermission),
				right: delPermission,
			},
			permission,
		};
	};

};
