import React, { useEffect, useMemo, useState } from 'react';
import CreateInputArrow from '../createInputArrow/CreateInputArrow';
import css from './createModuleWindow.module.scss';
import { legalEntityAPI } from '../../../../../entities/legal-entity/api/api';

interface HiddenBlockProps {
	descriptionValue: string,
	choicedLegalEntityId: string[],
	setDescriptionValue: (value: string) => void,
	onAddChoicedLegalEntity: (value: string) => void,
	onRemoveChoicedLegalEntity: (value: string) => void,
}

const HiddenBlock = ({
	descriptionValue,
	setDescriptionValue,
	choicedLegalEntityId,
	onAddChoicedLegalEntity,
	onRemoveChoicedLegalEntity,
}: HiddenBlockProps) => {
	const { data: legalEntityData } = legalEntityAPI.useGetAllQuery({
		page: 1,
		limit: 127,
	});

	const [charCount, setCharCount] = useState(0);

	useEffect(() => {
		setCharCount(descriptionValue.length);
	}, [descriptionValue]);

	const preparedLegalEntities = useMemo(() => legalEntityData?.legalEntities?.map(tag => {
		const {
			id,
			name,
		} = tag;
		return {
			id: id.toString(),
			name,
		};
	}), [legalEntityData]);

	return (
		<>
			<CreateInputArrow
				text="Юридические лица"
				inputData={preparedLegalEntities}
				choicedItemsId={choicedLegalEntityId}
				onAddChoicedItem={onAddChoicedLegalEntity}
				onRemoveChoicedItem={onRemoveChoicedLegalEntity}
			/>
			<>
				<div className={css.area}>
					<textarea
						maxLength={100}
						data-limit-row-len="true"
						rows={8}
						value={descriptionValue}
						onChange={(e) => setDescriptionValue(e.target.value)}
					/>
					<div className={css.placeholder}>Описание</div>
				</div>
				<div className={css.charCounter}>
					{charCount}
					/100
				</div>
			</>
		</>
	);
};

export default HiddenBlock;
