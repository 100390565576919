import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRoleItem } from '../../entities/role/api/types';
import { IAccount } from '../../entities/user/account/api/types';

export interface IModalsActive {
	isDeleteUserModalActive: boolean,
	isDeleteRoleModalActive: boolean,
	isUsersCreateModalActive: boolean,
	isRoleCreateModalActive: boolean,
	isAddRoleModalActive: boolean,
}

interface IInitialState {
	users: IAccount[] | undefined,
	roles: IRoleItem[] | undefined
	modalsActive: IModalsActive,
	usersSearchValue: string,
	deletingUserEmail: string | null,
	untiengUserEmail: string | null,
	deletingRoleId: string | null,
	choicedEmoji: string | null,
	createRoleBgColor: string,
}

const initialState: IInitialState = {
	roles: undefined,
	users: undefined,
	modalsActive: {
		isDeleteUserModalActive: false,
		isDeleteRoleModalActive: false,
		isUsersCreateModalActive: false,
		isRoleCreateModalActive: false,
		isAddRoleModalActive: false,
	},
	usersSearchValue: '',
	deletingUserEmail: null,
	untiengUserEmail: null,
	deletingRoleId: null,
	choicedEmoji: null,
	createRoleBgColor: '#ffffff',
};

export const usersSlice = createSlice({
	name: 'template',
	initialState,
	reducers: {
		setRoles: (state, action: PayloadAction<Array<IRoleItem>>) => {
			state.roles = action.payload;
		},
		setUsers: (state, action: PayloadAction<IAccount[]>) => {
			state.users = action.payload;
		},
		setIsDeleteUserModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteUserModalActive = action.payload;
		},
		setIsDeleteRoleModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isDeleteRoleModalActive = action.payload;
		},
		setIsAddRoleModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isAddRoleModalActive = action.payload;
		},
		setIsUsersCreateModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isUsersCreateModalActive = action.payload;
		},
		setUsersSearchValue: (state, action: PayloadAction<string>) => {
			state.usersSearchValue = action.payload;
		},
		setIsRoleCreateModalActive: (state, action: PayloadAction<boolean>) => {
			state.modalsActive.isRoleCreateModalActive = action.payload;
		},
		setDeletingUserEmail: (state, action: PayloadAction<string>) => {
			state.deletingUserEmail = action.payload;
		},
		setUntiengUserEmail: (state, action: PayloadAction<string | null>) => {
			state.untiengUserEmail = action.payload;
		},
		setDeletingRoleId: (state, action: PayloadAction<string | null>) => {
			state.deletingRoleId = action.payload;
		},
		setChoicedEmoji: (state, action: PayloadAction<string | null>) => {
			state.choicedEmoji = action.payload;
		},
		setCreateRoleBgColor: (state, action: PayloadAction<string>) => {
			state.createRoleBgColor = action.payload;
		},
	},
});

export const {
	setRoles,
	setUsers,
	setIsUsersCreateModalActive,
	setIsDeleteUserModalActive,
	setIsAddRoleModalActive,
	setIsDeleteRoleModalActive,
	setIsRoleCreateModalActive,
	setUsersSearchValue,
	setDeletingUserEmail,
	setUntiengUserEmail,
	setDeletingRoleId,
	setChoicedEmoji,
	setCreateRoleBgColor,
} = usersSlice.actions;

export default usersSlice.reducer;
