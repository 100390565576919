import IAreaSizeMutable from '../../../mechanics/spatial-quadrants/area-mutators/IAreaSizeMutable';
import IFrameArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import OnMutationEvent from '../../../mechanics/spatial-quadrants/area-mutators/events/OnMutationEvent';
import TableComponent from '../../../components/table/TableComponent';
import SpatialTableColumnResizeArea
	from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialTableColumnResizeArea';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import TableMutator from './TableMutator';

/**
 * Сущность для изменения ширины колонок таблицы.
 */
class TableColumnMutator extends TableMutator {
	private readonly MIN_COLUMN_WIDTH = 10;

	private readonly initialLeftColumnWidth: number;
	private readonly initialRightColumnWidth: number;

	private readonly multipliers: number[] | null;
	private readonly targetTriggerIndex: number | null;
	private readonly defaultColumnWidth: number | null;

	constructor(area: SpatialTableColumnResizeArea) {
		super(area);

		const { multiplierIndex } = area.getData();

		this.multipliers = this.component.getColumnMultipliers();
		this.targetTriggerIndex = multiplierIndex;
		this.defaultColumnWidth = this.component.getDefaultColumnWidth();

		const leftMultiplier = this.multipliers[this.targetTriggerIndex];
		const rightMultiplier = this.multipliers[this.targetTriggerIndex + 1];
		if (rightMultiplier === undefined) {
			throw new ManipulatorError('right multiplier not found');
		}
		if (leftMultiplier === undefined) {
			throw new ManipulatorError('left multiplier not found');
		}

		this.initialLeftColumnWidth = this.defaultColumnWidth * leftMultiplier;
		this.initialRightColumnWidth = this.defaultColumnWidth * rightMultiplier;
	}

	public mutateFrameArea = (fn: (prev: IFrameArea) => IFrameArea): void => {
		const currentFrameArea = this.getFrameArea();
		const updatedFrameArea = fn(currentFrameArea);

		if (this.targetTriggerIndex === null || this.defaultColumnWidth === null
			|| this.multipliers === null || this.component === null) {
			return;
		}

		const updatedLeftColumnWidth = this.initialLeftColumnWidth + updatedFrameArea.x;
		const updatedRightColumnWidth = this.initialRightColumnWidth - updatedFrameArea.x;

		if (updatedLeftColumnWidth < this.MIN_COLUMN_WIDTH || updatedRightColumnWidth < this.MIN_COLUMN_WIDTH) {
			return;
		}

		const updatedLeftMultiplier = updatedLeftColumnWidth / this.defaultColumnWidth;
		const updatedRightMultiplier = updatedRightColumnWidth / this.defaultColumnWidth;

		this.multipliers[this.targetTriggerIndex] = updatedLeftMultiplier;
		this.multipliers[this.targetTriggerIndex + 1] = updatedRightMultiplier;

		this.component.mutateColumnMultipliers(this.multipliers);
		this.component.applyMutations();
	};
}

export default TableColumnMutator;
