import { useAppDispatch } from '../../../shared/libs/redux';
import { workshopCategoryApi } from '../../../entities/workshop-category';
import { workshopModuleApi } from '../../../entities/workshop-module';
import { notificationError } from '../../../components/Notifications/callNotifcation';

export const useUnbindWorkshopModuleCategory = (moduleId?: string) => {
	const dispatch = useAppDispatch();

	const [unbindCategory] = workshopCategoryApi.useUnbindMutation();

	const onUnbindCategory = async (categoryId: number) => {
		try {
			if (moduleId && categoryId) {
				await unbindCategory({ moduleId, categories: [categoryId] }).unwrap();

				dispatch(workshopModuleApi.util?.invalidateTags(['workshopModule']));
			}
		} catch (e) {
			notificationError('Произошла ошибка при отвязке категории', 'Не удалось отвязать категорию');
		}
	};

	return { onUnbindCategory };
};
