import React from 'react';
import BlockHeader from '../../blockHeader/BlockHeader';
import { setIsCreatingTagGroupModalActive } from '../../../../../../../redux/reducers/module/tagGroupReducer';
import Group from './group/Group';
import css from './tagGroupBlock.module.scss';
import { tagGroupAPI } from '../../../../../../../entities/modules/tag-group/api/api';
import { Slider } from '../../../../../../../widgets/slider';
import { EmptyList } from '../../../../../../ui/empty-list/empty-list';
import { useAppDispatch } from '../../../../../../../shared/libs/redux';

const TagGroupBlock = () => {
	const dispatch = useAppDispatch();

	const { data: tagsGroupData } = tagGroupAPI.useGetAllQuery({
		page: 1,
		limit: 127,
		search: '',
	});

	const openCreateModal = () => {
		dispatch(setIsCreatingTagGroupModalActive(true));
	};

	return (
		<BlockHeader
			title="Группа тегов"
			onBtnClick={openCreateModal}
			btnText="Добавить группу тегов"
		>
			{
				tagsGroupData?.tagGroups
					? (
						<div className={css.slider}>
							<Slider buttonScrollDistance={600} height={308} controlSize="big">
								{
									tagsGroupData?.tagGroups.map(group => (
										<Group
											key={group.id}
											group={group}
										/>
									))
								}
							</Slider>
						</div>
					)
					: (
						<EmptyList
							title="Создайте первую группу тегов"
							needBtn={false}
						/>
					)
			}
		</BlockHeader>
	);
};

export default TagGroupBlock;
