import { createApi } from '@reduxjs/toolkit/query/react';
import {
	IGetTemplateGalleryModulesRequest, IGetTemplateGalleryModulesResponse, ITemplateGalleryModule,
} from '../model/types/types';
import { baseQuery } from '../../../shared/api/api';

const URI_TEMPLATE_GALLERY_MODULE = '/template/module/gallery';

export const templateGalleryModuleApi = createApi({
	reducerPath: 'templateGalleryModuleApi',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['templateGalleryModule'],
	endpoints: builder => ({
		getTemplateGalleryModules: builder.query<
			IGetTemplateGalleryModulesResponse,
			IGetTemplateGalleryModulesRequest
		>({
			query: (data) => ({
				url: URI_TEMPLATE_GALLERY_MODULE,
				method: 'GET',
				params: data,
			}),
			providesTags: ['templateGalleryModule'],
		}),
		getTemplateGalleryModule: builder.query<ITemplateGalleryModule, { moduleId: string }>({
			query: ({ moduleId }) => ({
				url: `${URI_TEMPLATE_GALLERY_MODULE}/${moduleId}`,
				method: 'GET',
			}),
			providesTags: ['templateGalleryModule'],
		}),
	}),
});
