import {
	Middleware, MiddlewareAPI, Dispatch,
} from '@reduxjs/toolkit';
import { RootState } from '../../../redux/reducers/reducer';
import {
	callSendSuccessListeners,
	callStartSendListeners,
	connect,
	myURL,
	sendAuthTokenAsync,
	setSocket,
} from './slice';
import ManipulatorError from '../../../components/Sketch/utils/manipulator-error/ManipulatorError';
import Utils from '../../../components/Sketch/utils/impl/Utils';
import store from '../../../redux/store/store';

export enum WebSocketActionType {
	WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT',
	WEBSOCKET_AUTH = 'WEBSOCKET_AUTH',
	WEBSOCKET_SEND = 'WEBSOCKET_SEND',
	WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT',
	WEBSOCKET_MESSAGE_RECEIVED = 'WEBSOCKET_MESSAGE_RECEIVED',
	WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED',
	WEBSOCKET_ERROR = 'WEBSOCKET_ERROR',
	WEBSOCKET_DISCONNECTED = 'WEBSOCKET_DISCONNECTED',
}

export enum ServerWebSocketType {
	connect = 'connect',
	templateConnect = 'sketch.private_template.template.connect',
	templateDisconnect = 'sketch.private_template.template.disconnect',
}

export interface IWebSocketAction {
	type:
		WebSocketActionType.WEBSOCKET_CONNECT
		| WebSocketActionType.WEBSOCKET_SEND
		| WebSocketActionType.WEBSOCKET_DISCONNECT
		| WebSocketActionType.WEBSOCKET_MESSAGE_RECEIVED
		| WebSocketActionType.WEBSOCKET_CONNECTED
		| WebSocketActionType.WEBSOCKET_ERROR
		| WebSocketActionType.WEBSOCKET_DISCONNECTED
		| WebSocketActionType.WEBSOCKET_AUTH
		| 'webSocket/connect'
		| 'webSocket/sendAuthToken'
		| 'webSocket/setSocket'
		| 'webSocket/sendCommands'
		| 'webSocket/callStartSendListeners'
		| 'webSocket/callSendSuccessListeners'
		| 'webSocket/disconnect'
		| 'webSocket/setConnected'
		| 'webSocket/sendTemplateId'
	;
	payload?: any; // или более строгий тип в зависимости от ваших данных
}

const createMessageQueue = () => {
	const queue: string[] = [];

	return {
		enqueue: (message: string) => {
			queue.push(message);
		},
		dequeue: (): string | undefined => queue.shift(),
		isEmpty: (): boolean => queue.length === 0,
	};
};

const handleSendAuthToken = async () => {
	try {
		await store.dispatch(sendAuthTokenAsync()).unwrap();
	} catch (error) {
		console.error('Failed to send auth token:', error);
	}
};

export const messageQueue = createMessageQueue();

// eslint-disable-next-line max-len
// const websocketMiddleware: Middleware<NonNullable<unknown>, RootState> = (store: MiddlewareAPI<Dispatch<IWebSocketAction>, RootState>) => {
// eslint-disable-next-line max-len,@typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line max-len
const websocketMiddleware: Middleware<NonNullable<unknown>, RootState> = (store: MiddlewareAPI<Dispatch<IWebSocketAction>, RootState>) => {
	let socket: WebSocket | null = null;
	const state = store.getState().webSocket;

	return (next: Dispatch<IWebSocketAction>) => (action: IWebSocketAction) => {
		switch (action.type) {
		case 'webSocket/connect':
			if (socket && socket.readyState === WebSocket.OPEN) return; // Если уже подключены уже подключены
			socket = new WebSocket(myURL);
			store.dispatch(setSocket(socket));

			socket.onopen = () => {
				// После открытия соединения мы должны отправить токен авторизации
				handleSendAuthToken();
				// Отправка всех сообщений из очереди
				while (!messageQueue.isEmpty()) {
					const message = messageQueue.dequeue();
					if (message) {
						socket!.send(message);
					}
				}
			};

			socket.onmessage = (event) => {
				const data = JSON.parse(event.data);
				if (data.status === 'ok') {
					store.dispatch(callSendSuccessListeners());
				}
			};

			socket.onerror = (error) => {
				console.error('WebSocket error:', error);
			};

			socket.onclose = (event) => {
				/* Обработаем случай когда соединение прервалось. Тут проблема возникла с кодом 1005, мы инициируем
				* отключение с кодом 1000, но прилетает 1005. Поэтому используем флаг для проверки. */
				if (event.code !== 1000	// 1000 - код закрытия по инициативе клиента
					&& state.isOpen
				) {
					setTimeout(() => store.dispatch(connect()), 3000); // Попытка переподключения через 3 секунды
				}
			};
			break;

		case 'webSocket/sendCommands':
			if (socket !== null && socket.readyState === WebSocket.OPEN) {
				store.dispatch(callStartSendListeners());
			}
			break;

		case 'webSocket/sendAuthToken':
			if (!socket) {
				console.warn('WebSocket is not initialized.');
				return;
			}

			break;

		case 'webSocket/disconnect':
			if (socket !== null) {
				socket.close();
			}
			break;

		case 'webSocket/sendTemplateId':
			// eslint-disable-next-line no-case-declarations
			const message = JSON.stringify({
				id: Utils.Generate.UUID4(),
				type: ServerWebSocketType.templateConnect,
				body: JSON.stringify({
					sketch_id: action.payload,
				}),
			});
			if (state.isOpen && socket && socket.readyState === WebSocket.OPEN) {
				socket.send(message);
			} else {
				messageQueue.enqueue(message); // Добавляем в очередь, если WebSocket не подключен
			}
			break;

		default:
			return next(action);
		}

		return next(action);
	};
};

export default websocketMiddleware;
