import SvgCollection from '../../utils/SvgCollection';
import NumberInput from './NumberInput';
import ElementContainer from '../../utils/ElementContainer';
import HTMLElementName from '../../utils/HTMLElementName';
import HTMLGenerator from '../../utils/HTMLGenerator';
import Utils from '../../utils/impl/Utils';

/**
 * Расширенное по функционалу поле ввода числовых значений.
 */
class NumberInputField extends ElementContainer<HTMLDivElement> {
	private readonly LABEL_CLASSNAME = 'page-properties__repeating-image-size-label';
	private readonly SHOW_ERROR_CLASS_NAME = 'show';
	private readonly SHOW_FIELD_CLASS_NAME = 'show';

	private readonly labelElement: HTMLElement;
	private readonly input: NumberInput;

	private errorElement: HTMLElement | null;

	constructor() {
		super(HTMLElementName.DIV);
		this.input = new NumberInput();
		this.labelElement = HTMLGenerator.getSpan({ className: this.LABEL_CLASSNAME });
		this.input.addValidValueListener(this.hideError);
		this.input.addInvalidValueListener(this.showError);
		this.rootElement.append(this.input.getElement());
	}

	/**
	 * Устанавливает иконку.
	 * @param icon Значение иконки поля.
	 */
	public setIcon = (icon: SvgCollection) => {
		Utils.DOM.injectSVG(this.rootElement, icon);
	};

	public setLabel = (text: string) => {
		this.labelElement.textContent = text;
		this.rootElement.prepend(this.labelElement);
	};

	/**
	 * Добавляет CSS класс на элемент ввода значений.
	 * @param className Имя класса.
	 */
	public appendInputClassName = (className: string) => {
		this.input.appendClassName(className);
	};

	/**
	 * Устанавливает элемент визуализации ошибки ввода не корректного значения.
	 * @param className Имя класса.
	 * @param text Текст ошибки.
	 */
	public setValidateError = (className: string, text: string) => {
		this.errorElement = HTMLGenerator.getSpan({
			className, text,
		});
	};

	public setValue = (value: number) => {
		this.input.setValue(value);
	};

	public setMinValue = (value: number) => {
		this.input.setMinValue(value);
	};

	public setMaxValue = (value: number) => {
		this.input.setMaxValue(value);
	};

	/**
	 * Добавляет слушателя изменения значения пользователем.
	 * @param listener Слушатель события.
	 */
	public addChangeValueListener = (listener: (value: number) => void) => {
		this.input.addChangeValueListener(listener);
	};

	/**
	 * Устанавливает CSS класс корневому контейнеру поля.
	 * @param className Имя класса.
	 */
	public appendClassName = (className: string) => {
		this.rootElement.classList.add(className);
	};

	public show = () => {
		this.rootElement.classList.add(this.SHOW_FIELD_CLASS_NAME);
	};

	public hide = () => {
		this.rootElement.classList.remove(this.SHOW_FIELD_CLASS_NAME);
	};

	private showError = () => {
		this.errorElement && this.errorElement.classList.add(this.SHOW_FIELD_CLASS_NAME);
	};

	private hideError = () => {
		this.errorElement && this.errorElement.classList.remove(this.SHOW_FIELD_CLASS_NAME);
	};
}

export default NumberInputField;
