import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainButton } from '../../../../../../../../ui/main-button/main-button';
import { GoBackButton } from '../../../../../../../../../shared/ui';
import {
	useMoveGalleryTemplate,
} from '../../../../../../../../../features/templates/gallery/move/api/useMoveGalleryTemplate';
import { IGalleryTemplate } from '../../../../../../../../../entities/templates/gallery/model/types';
import {
	ReactComponent as TemplateLockIcon,
} from '../../../../../../../../../shared/images/collection/template/template-lock.svg';
import css from './gallery-panel-header.module.scss';
import { useGalleryTemplateLocked } from '../../../utils/useGalleryTemplateLocked';
import { DEFAULT_TO_TARIFF_URL } from '../../../../../../../../../shared/ui/to-tariff-button/model/data';
import {
	ChangeableTagList,
} from '../../../../../../../../../widgets/changeable-tag-bar/ui/tag-list/changeable-tag-list';

interface GalleryPanelHeaderProps {
	template: IGalleryTemplate | undefined,
}

export const GalleryPanelHeader = ({ template }: GalleryPanelHeaderProps) => {
	const navigate = useNavigate();

	const { isLoading, moveTemplate } = useMoveGalleryTemplate();

	const onMoveTemplate = () => moveTemplate(template?.id || '');

	const {
		isLocked,
		description,
	} = useGalleryTemplateLocked(template?.gallery?.tariffPermission || 0);

	const tags = template?.tags?.fromTemplates?.concat(template?.tags?.fromModules || []) || [];

	return (
		<div className={css.wrapper}>
			<GoBackButton onClick={() => navigate(-1)} />
			{/* <div className={css.content}> */}
			<div className={css.controls_wrapper}>
				<div className={css.title_wrapper}>
					<div className={css.title}>{template?.name}</div>
					{
						!!template?.gallery.tariffPermission && isLocked && (
							<div className={css.lock}>
								<TemplateLockIcon />
							</div>
						)
					}
				</div>
				<div className={css.controls}>
					<MainButton
						type="button"
						btnStyle="bright"
						onClick={onMoveTemplate}
						disabled={isLoading || (!!template?.gallery.tariffPermission && isLocked)}
					>
						Переместить в «Мои шаблоны»
					</MainButton>
					<div className={css.description}>
						{
							isLocked && !!template?.gallery.tariffPermission
								? description
								: 'Чтобы редактировать шаблон в конструкторе, сохраните его в раздел «Мои шаблоны»'
						}
					</div>
					{
						isLocked && !!template?.gallery.tariffPermission && (
							<MainButton
								type="button"
								btnStyle="transparent"
								onClick={() => window.open(DEFAULT_TO_TARIFF_URL, '_blank')}
							>
								Повысить тариф
							</MainButton>
						)
					}
				</div>
			</div>
			{
				(template?.tags?.fromTemplates || template?.tags?.fromModules) && (
					<div className={css.tags}>
						<h3>Теги</h3>
						<ChangeableTagList
							isNeedHint={false}
							disabledTags={tags}
							activeTags={[]}
						/>
					</div>
				)
			}
		</div>
	);
};
