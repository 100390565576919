import { useState, useEffect } from 'react';

export const useSliderWidth = (sliderRef: React.RefObject<HTMLDivElement>, children: React.ReactNode) => {
	const [sliderWidth, setSliderWidth] = useState(0);

	useEffect(() => {
		const updateSliderWidth = () => {
			if (sliderRef.current) {
				setSliderWidth(sliderRef.current.scrollWidth > 1000
					? 1000 : sliderRef.current.scrollWidth);
			}
		};

		updateSliderWidth();
		window.addEventListener('resize', updateSliderWidth);

		return () => {
			window.removeEventListener('resize', updateSliderWidth);
		};
	}, [children, sliderRef]);

	return sliderWidth;
};
