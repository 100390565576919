import IFrameArea from '../spatial-tree/spatial-area/IFrameArea';

/**
 * Детектор, определяющий, произошел ли выход за пределы 'слепой' зоны. Слепая зона - расстояние от стартовой точки для
 * движения курсора мыши, в котором не учитывается смещение - это нужно для исключения случайных перемещений мыши
 * пользователем.
 */
class BlindZoneDetector {
	private readonly DEFAULT_BLIND_SIZE = 4;

	private blindZoneSize: number;

	// Находится ли текущая позиция за пределами слепой зоны
	private isOverstepping: boolean;

	/* Включен ли механизм слепой зоны, если нет, то детектор будет всегда уведомлять,
	что позиция за пределами слепой зоны */
	private isDetectorEnable: boolean;

	private isMoveRun: boolean;

	private readonly currentOffset: IFrameArea;

	constructor() {
		this.blindZoneSize = this.DEFAULT_BLIND_SIZE;
		this.isMoveRun = false;
		this.isDetectorEnable = true;
		this.isOverstepping = false;
		this.currentOffset = {
			x: 0, y: 0, width: 0, height: 0, rotate: 0,
		};
	}

	public start = () => {
		this.isMoveRun = true;
	};

	/**
	 * Добавляет сдвиг к текущим значениям.
	 * @param offsets - сдвиг.
	 */
	public appendOffsets = (offsets: IFrameArea) => {
		if (!this.isMoveRun) {
			return;
		}

		this.currentOffset.x += offsets.x;
		this.currentOffset.y += offsets.y;
		this.currentOffset.width += offsets.width;
		this.currentOffset.height += offsets.height;
		this.currentOffset.rotate += offsets.rotate;
		this.checkOverstepping();
	};

	/**
	 * Устанавливает сдвиг как текущий.
	 * @param offsets - сдвиг.
	 */
	public setOffsets = (offsets: IFrameArea) => {
		if (!this.isMoveRun) {
			return;
		}

		this.currentOffset.x = offsets.x;
		this.currentOffset.y = offsets.y;
		this.currentOffset.width = offsets.width;
		this.currentOffset.height = offsets.height;
		this.currentOffset.rotate = offsets.rotate;
		this.checkOverstepping();
	};

	public stop = () => {
		this.currentOffset.x = 0;
		this.currentOffset.y = 0;
		this.currentOffset.width = 0;
		this.currentOffset.height = 0;
		this.currentOffset.rotate = 0;
		this.isOverstepping = false;
	};

	public setBlindZoneSize = (size: number) => {
		this.blindZoneSize = size;
	};

	/**
	 * Выключает работу детектора. Статус выхода за пределы зоны всегда будет положительным.
	 */
	public disableDetector = () => {
		this.isDetectorEnable = false;
	};

	public isBlindZoneOverstepping = (): boolean => (this.isDetectorEnable ? this.isOverstepping : true);

	private checkOverstepping = () => {
		if (this.isOverstepping) {
			return;
		}

		if (Math.abs(this.currentOffset.x) > this.blindZoneSize
			|| Math.abs(this.currentOffset.y) > this.blindZoneSize
			|| Math.abs(this.currentOffset.width) > this.blindZoneSize
			|| Math.abs(this.currentOffset.height) > this.blindZoneSize
			|| Math.abs(this.currentOffset.rotate) > this.blindZoneSize
		) {
			this.isOverstepping = true;
		}
	};
}

export default BlindZoneDetector;
