import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../shared/libs/redux';
import {
	workshopTagGroupActions,
	workshopTagGroupApi,
	workshopTagGroupSlice,
} from '../../../../entities/workshop-tag-group';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useEditWorkshopTagGroup = () => {
	const dispatch = useAppDispatch();

	const id = useSelector(workshopTagGroupSlice.selectors.getEditingGroupId);
	const name = useSelector(workshopTagGroupSlice.selectors.getEditGroupName);

	const [update, requestData] = workshopTagGroupApi.useUpdateMutation();

	const onEditTagGroup = async () => {
		try {
			if (id && name) {
				await update({
					groupId: id, name,
				}).unwrap();

				dispatch(workshopTagGroupActions.setIsEditingGroupModalActive(false));
				dispatch(workshopTagGroupActions.setEditGroupName(''));
			}
		} catch (e) {
			notificationError('Возникла ошибка при изменении группы тегов!');
		}
	};

	return {
		requestData,
		onEditTagGroup,
	};
};
