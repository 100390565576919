import PagesContainer from '../../components/pages-container/PagesContainer';
import PageGraphic from '../../graphic/page/PageGraphic';
import ManipulatorError from '../../utils/manipulator-error/ManipulatorError';
import IComponent from '../../components/IComponent';
import { AnyComponentStructure } from '../../Types';
import IPagesComponentTree from '../IPagesComponentTree';
import IFrameConfiguration from '../../frame/IFrameConfiguration';
import IGraphic from '../../graphic/IGraphic';
import GraphicType from '../../graphic/GraphicType';
import MutableComponentTree, { IMutableComponentTreeDependencies } from './MutableComponentTree';
import SketchComponentType from '../../components/SketchComponentType';
import IDescartesPosition from '../../utils/IDescartesPosition';

export interface IPagesComponentTreeDependencies extends IMutableComponentTreeDependencies {}

/**
 * Предоставляет методы для работы с многостраничной структурой компонентов.
 * @inheritDoc
 */
class PagesComponentTree<Dependencies extends IPagesComponentTreeDependencies>
	extends MutableComponentTree<Dependencies>
	implements IPagesComponentTree {
	protected pagesContainerComponent: PagesContainer;

	private readonly changeFocusPageListeners: VoidFunction[];

	private currentPageFocusIndex: number;

	constructor(manipulatorElement: HTMLElement) {
		super(manipulatorElement);
		this.currentPageFocusIndex = 0;
		this.changeFocusPageListeners = [];
		this.addPostInjectDependenciesListener(dependencies => {
			this.pagesContainerComponent = dependencies
				.componentFactory.getClearComponent<PagesContainer>(SketchComponentType.PAGES_CONTAINER);
			this.rootComponent = this.pagesContainerComponent;
		});
	}

	/**
	 * Загружает структуру корневого компонента и визуализирует её.
	 * @param structure - структура корневого компонента.
	 */
	public override load = (structure: AnyComponentStructure) => {
		const pagesContainerElement = this.pagesContainerComponent.getPagesContainerElement();

		this.pagesContainerComponent.setStructure(() => structure);

		structure.graphics?.forEach(graphicStructure => {
			const graphic = this.dependencies.graphicFactory.createGraphic(graphicStructure.type, this.rootComponent);
			graphic.setStructure(() => graphicStructure);

			const graphicElement = graphic.getFrameElement();

			pagesContainerElement.append(graphicElement);
			this.rootComponent.appendGraphic(graphic);
		});

		if (structure.components !== null) {
			this.recursiveInitializeAppendComponent(this.rootComponent, structure.components);
		}

		this.componentLayers.load(structure);
	};

	public override reset = () => {
		this.componentLayers.reset();
		this.pagesContainerComponent.reset();
	};

	/**
	 * Возвращает графику страницы, на которой находится фокус в момент вызова функции.
	 * @exception Если страница в фокусе не была найдена по индексу. */
	public getFocusPageGraphic = (): PageGraphic => {
		const graphics = this.pagesContainerComponent.getGraphics() as PageGraphic[];
		const graphic = graphics[this.currentPageFocusIndex];
		if (graphic === undefined) {
			throw new ManipulatorError('the current page cannot be undefined');
		}
		return graphic;
	};

	/**
	 * Добавляет событие в список событий, которые будут вызваны после изменения фокуса страницы.
	 * @param event - добавляемое событие. */
	public addPostChangeFocusPageListener = (event: () => void) => {
		this.changeFocusPageListeners.push(event);
	};

	/** Синхронизирует информацию внутри панелей страниц в соответствии с состоянием дерева в момент вызова функции. */
	public syncPagePanels = () => {
		const pages = this.pagesContainerComponent.getGraphics() as PageGraphic[];
		pages.forEach(page => {
			page.syncTopPageHeader();
			page.syncBottomPageHeader();

			if (this.isLastPage(page)) {
				page.showBottomPageHeader();
			} else {
				page.hideBottomPageHeader();
			}
		});
	};

	/**
	 * Возвращает графику страницы по её номеру.
	 * @param pageNumber
	 * @exception При отсутствии страниц.
	 * @return PageGraphic - при нахождении страницы.
	 * @return null - при отсутствии страницы с pageNumber позицией. */
	public getPageFromNumber = (pageNumber: number): PageGraphic | null => {
		const graphics = this.pagesContainerComponent.getGraphics();
		if (graphics.length === 0) {
			throw new ManipulatorError('graphics not found');
		}

		const foundPage = graphics[pageNumber];
		if (foundPage === undefined) {
			return null;
		}

		return foundPage as PageGraphic;
	};

	/**
	 * Возвращает номер страницы по её графике.
	 * @param pageGraphic Графика страницы.
	 */
	public getPageNumber = (pageGraphic: IGraphic): number | null => {
		const pagesContainer = this.getRootComponent();
		const pages = pagesContainer.getGraphics();

		const pageNumber = pages.findIndex(page => page === pageGraphic);

		return pageNumber === -1 ? null : pageNumber;
	};

	/**
	 * Обновляет текущий индекс страницы в фокусе в соответствии с позицией экрана пользователя.
	 */
	public updatePageFocusIndex = () => {
		const screenCenter = window.innerHeight / 2;

		const pages = this.pagesContainerComponent.getGraphics();

		pages.forEach((page, index) => {
			const { top, height } = page.getFrameElement().getBoundingClientRect();

			if (screenCenter > top && screenCenter < top + height) {
				const currentValue = this.currentPageFocusIndex;
				if (currentValue !== index) {
					this.currentPageFocusIndex = index;
					this.callChangeFocusPageListeners();
				}
			}
		});
	};

	/**
	 * Возвращает графику, находящуюся на странице с указанным индексом. В случае, если она отсутствует,
	 * метод вернет `null`.
	 * @param pageNumber - номер страницы, на которой будет осуществляться поиск
	 */
	public getPageEmbeddedGraphics = (pageNumber: number): IGraphic[] | null => {
		const components = this.getComponentsWithoutRoot();
		if (components === null) {
			return null;
		}
		const graphics = components.map(component => component.getGraphics()).flat();
		const pageGraphics = graphics.filter(graphic => {
			const graphicPageNumber = this.getGraphicPageNumber(graphic);
			return graphicPageNumber === pageNumber;
		});

		return pageGraphics.length === 0 ? null : pageGraphics;
	};

	/**
	 * Возвращает номер страницы, на которой расположена графика.
	 */
	public getGraphicPageNumber = (graphic: IGraphic): number => {
		let result = 0;
		// eslint-disable-next-line prefer-destructuring
		let parentComponent: IComponent | null = graphic.getParentComponent();
		const graphicOffset = graphic.getOffset();

		while (parentComponent !== null) {
			const { offset } = parentComponent.getStructure();
			if (offset === null) {
				break;
			}

			result += offset;
			parentComponent = parentComponent.getParentComponent();
		}
		return result + graphicOffset;
	};

	/**
	 * Возвращает конфигурацию с позицией, относительно текущей страницы.
	 * @param graphic - Графика, от которой будут производиться расчеты.
	 */
	public getConfigurationFromPage = (graphic: IGraphic): IFrameConfiguration => {
		const configuration = graphic.getFrameConfiguration();
		let { x, y } = configuration;
		const {
			width, height, rotate, layer,
		} = configuration;

		let parentGraphic = graphic.getParentGraphic();
		if (parentGraphic === null) {
			return {
				x, y, width, height, rotate, layer,
			};
		}

		while (parentGraphic !== null && parentGraphic.type !== GraphicType.PAGE) {
			const frameConfiguration = parentGraphic.getFrameConfiguration();
			x += frameConfiguration.x;
			y += frameConfiguration.y;

			parentGraphic = parentGraphic.getParentGraphic();
		}

		return {
			x, y, width, height, rotate, layer,
		};
	};

	/**
	 * Возвращает графику страницы, на которой находится искомая графика.
	 * @param graphic - искомая графика.
	 */
	public getPageFromGraphic = (graphic: IGraphic): PageGraphic => {
		const pageNumber = this.getGraphicPageNumber(graphic);
		const page = this.getPageFromNumber(pageNumber);
		if (page === null) {
			throw new ManipulatorError('page not found from index');
		}
		return page;
	};

	/**
	 * Возвращает позицию графики, относительно страницы, даже если страница не является прямым родителем.
	 * @param graphic Графика, для которой рассчитывается относительная позиция.
	 */
	public getRelativePagePosition = (graphic: IGraphic): IDescartesPosition => {
		const position: IDescartesPosition = { x: 0, y: 0 };

		let currentGraphic: IGraphic | null = graphic;
		let currentConfiguration = currentGraphic.getFrameConfiguration();
		while (currentGraphic.type !== GraphicType.PAGE) {
			position.x += currentConfiguration.x;
			position.y += currentConfiguration.y;

			currentGraphic = currentGraphic.getParentGraphic();
			if (currentGraphic === null) {
				throw new ManipulatorError('page not found');
			}
			currentConfiguration = currentGraphic.getFrameConfiguration();
		}

		return position;
	};

	/**
	 * Возвращает позицию `graphic` относительно `pageGraphic`.
	 * @param graphic Искомая графика, для которой рассчитывается позиция.
	 * @param pageGraphic Графика страницы, от которой будет рассчитана позиция `graphic`.
	 */
	public getRelativePositionFromPage = (graphic: IGraphic, pageGraphic: PageGraphic): IDescartesPosition => {
		const graphicGlobalPosition = graphic.getGlobalPosition();
		const pageGlobalPosition = pageGraphic.getGlobalPosition();

		return {
			x: graphicGlobalPosition.x - pageGlobalPosition.x,
			y: graphicGlobalPosition.y - pageGlobalPosition.y,
		};
	};

	public getPages = (): PageGraphic[] => this.pagesContainerComponent.getGraphics() as PageGraphic[];
	public getPageCount = (): number => this.pagesContainerComponent.getGraphics().length;
	public getPagesContainer = (): PagesContainer => this.pagesContainerComponent;
	public getFocusPageIndex = (): number => this.currentPageFocusIndex;
	/** Возвращает HTMLElement, который является основным "полотном", где отображаются все элементы. */
	public override getWorkAreaElement = (): HTMLElement => this.pagesContainerComponent.getPagesContainerElement();

	public override getElementForEmbedding = (): HTMLElement => this.pagesContainerComponent.getPagesContainerElement();

	/**
	 * Проверяет, является ли страница последней.
	 * @param graphic - графика страницы, для которой происходит проверка. */
	private isLastPage = (graphic: PageGraphic): boolean => {
		const graphics = this.pagesContainerComponent.getGraphics();
		return graphics.indexOf(graphic) === graphics.length - 1;
	};

	/**
	 * Проверяет, является ли страница первой.
	 * @param graphic - графика страницы, для которой происходит проверка. */
	private isFirstPage = (graphic: PageGraphic): boolean => {
		const graphics = this.pagesContainerComponent.getGraphics();
		return graphics.indexOf(graphic) === 0;
	};

	/** Сообщает всем подписчикам о смене страницы в фокусе */
	private callChangeFocusPageListeners = () => {
		this.changeFocusPageListeners.forEach((listener) => listener());
	};
}

export default PagesComponentTree;
