import React from 'react';
import css from './header.module.scss';
import { IActionsWithCardsItem } from '../ui/actionsWithCards/TransitionActionsWithCards';
import { ReactComponent as OpenArrowIcon } from '../../image/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../../image/close-arrow.svg';
import AutoCloseActionsCards from '../ui/autoclose-actions-cards/autoclose-actions-cards';
import { Logo } from '../../shared/ui';
import { TrialTariffNotification } from '../../shared/ui/trial-tariff-notification/trialTariffNotification';
import { Tariff } from '../../entities/tariff/model/data';
import { ITariff } from '../../entities/tariff/api/types';

interface HeaderProps {
	photo: string,
	renderedName: string,
	isShowHiddenBlock: boolean,
	actionsWithCardsItems: Array<IActionsWithCardsItem>,
	onMainPage: () => void,
	onCloseHiddenBlock: () => void,
	setIsShowHiddenBlock: (value: boolean) => void,
	tariff: ITariff | null,
}

const Header = ({
	renderedName,
	photo,
	isShowHiddenBlock,
	actionsWithCardsItems,
	setIsShowHiddenBlock,
	onMainPage,
	onCloseHiddenBlock,
	tariff,
}: HeaderProps) => {
	const headerClickHandler = () => {
		setIsShowHiddenBlock(!isShowHiddenBlock);
	};

	const isTrialTariff = tariff?.name === Tariff.TRIAL;

	return (
		<header>
			<div
				className={css.logo_box}
				onClick={onMainPage}
				role="presentation"
			>
				<Logo />
			</div>

			{isTrialTariff && <TrialTariffNotification />}

			<div className={css.data_box}>
				<div
					className={css.account_box}
					onClick={headerClickHandler}
					role="presentation"
				>
					{photo && <img className={css.picture} src={`/api/file/user/${photo}`} alt="user" />}
					<div className={css.name}>{renderedName}</div>
					{
						isShowHiddenBlock
							? <OpenArrowIcon />
							: <CloseArrowIcon />
					}
				</div>
				<AutoCloseActionsCards
					onClose={onCloseHiddenBlock}
					isActive={isShowHiddenBlock}
					items={actionsWithCardsItems}
					cssClassMain={css.block}
				/>
			</div>
		</header>
	);
};

export default Header;
