import React from 'react';
import cx from 'classnames';
import css from './dropdown-item.module.scss';

export interface IDropdownItem {
	id: string;
	name: string;
}

interface DropdownItemProps {
	data: IDropdownItem,
	isActive?: boolean,
	styles?: string,
	onClick: (id: string) => void,
}

export const DropdownItem = ({
	data,
	styles,
	isActive = false,
	onClick,
}: DropdownItemProps) => {
	const {
		name,
		id,
	} = data;

	const onAddTag = () => onClick(id);

	return (
		<div role="presentation" onClick={onAddTag} className={cx(css.wrapper, isActive && css.active, styles)}>
			{name}
		</div>
	);
};
