import ContextMenuSectionBuilder from './ContextMenuSectionBuilder';
import IContextMenu from '../IContextMenu';
import ContextMenu from '../ContextMenu';
import IComponent from '../../../../components/IComponent';
import TextPanel from '../../../../interface/bars/properties-bar/panel-text/TextPanel';
import SvgCollection from '../../../../utils/SvgCollection';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';

/**
 * Сборщик контекстного меню манипулятора.
 */
class ContextMenuBuilder {
	private readonly COPY_TEXT = 'Скопировать';
	private readonly COPY_COMBINATION = 'Ctrl+C';
	private readonly DELETE_TEXT = 'Удалить';
	private readonly DELETE_COMBINATION = 'Del';
	private readonly PASTE_TEXT = 'Вставить';
	private readonly PASTE_COMBINATION = 'Ctrl+V';

	private readonly ADD_GROUP_TEXT = 'Добавить в группу';
	private readonly ADD_GROUP_COMBINATION = 'Ctrl+G';
	private readonly UNGROUP_TEXT = 'Разгруппировать';
	private readonly UNGROUP_COMBINATION = 'Ctrl+Shift+G';

	private readonly MOVE_FORWARD_TEXT = 'Переместить вперед';
	private readonly MOVE_FORWARD_COMBINATION = '[';
	private readonly MOVE_BACK_TEXT = 'Переместить назад';
	private readonly MOVE_BACK_COMBINATION = ']';
	private readonly MOVE_FOREGROUND_TEXT = 'На передний план';
	private readonly MOVE_FOREGROUND_COMBINATION = 'Ctrl+[';
	private readonly MOVE_BACKGROUND_TEXT = 'На задний план';
	private readonly MOVE_BACKGROUND_COMBINATION = 'Ctrl+]';

	private readonly MODULE_TEXT = 'Создать модуль';

	private readonly SHOW_INTERFACE_TEXT = 'Показать интерфейс';
	private readonly SHOW_INTERFACE_COMBINATION = '';
	private readonly HIDE_INTERFACE_TEXT = 'Скрыть интерфейс';
	private readonly HIDE_INTERFACE_COMBINATION = 'Ctrl+\\';

	private readonly MERGE_CELLS = 'Объединить ячейки';
	private readonly MERGE_CELLS_COMBINATION = '';
	private readonly SPLIT_CELLS = 'Разделить ячейки';
	private readonly SPLIT_COMBINATION = '';
	private readonly SPLIT_TABLE = 'Разделить таблицу';
	private readonly SPLIT_TABLE_COMBINATION = '';
	private readonly DELETE_COLUMN = 'Удалить столбец';
	private readonly DELETE_COLUMN_COMBINATION = '';
	private readonly ADD_ROW_UNDER = 'Добавить строку ниже';
	private readonly ADD_ROW_UNDER_COMBINATION = '';
	private readonly ADD_ROW_OVER = 'Добавить строку выше';
	private readonly ADD_ROW_OVER_COMBINATION = '';
	private readonly ADD_COLUMN_ON_RIGHT = 'Добавить столбец справа';
	private readonly ADD_COLUMN_ON_RIGHT_COMBINATION = '';
	private readonly ADD_COLUMN_ON_LEFT = 'Добавить столбец слева';
	private readonly ADD_COLUMN_ON_LEFT_COMBINATION = '';
	private readonly DELETE_ROW = 'Удалить строку';
	private readonly DELETE_ROW_COMBINATION = '';

	private readonly contextMenu: IContextMenu;

	constructor() {
		this.contextMenu = new ContextMenu();
	}

	public section = (): ContextMenuSectionBuilder => {
		const sectionBuilder = new ContextMenuSectionBuilder(this);
		const sectionElement = sectionBuilder.getElement();
		const menuElement = this.contextMenu.getElement();
		menuElement.append(sectionElement);

		return sectionBuilder;
	};

	public build = (): IContextMenu => this.contextMenu;

	public sectionCopyPaste = (onCopy: VoidFunction, onPaste: VoidFunction, onDelete: VoidFunction) => this.section()
		.row()
		.text(this.COPY_TEXT)
		.combination(this.COPY_COMBINATION)
		.action(onCopy)
		.build()
		.row()
		.text(this.PASTE_TEXT)
		.combination(this.PASTE_COMBINATION)
		.action(onPaste)
		.build()
		.row()
		.text(this.DELETE_TEXT)
		.combination(this.DELETE_COMBINATION)
		.action(onDelete)
		.build()
		.build();

	public sectionGrouping = (
		onGroup: VoidFunction,
		onUngroup: VoidFunction,
		component: IComponent,
	) => (component.isUniter
		? this.section()
			.row()
			.text(this.UNGROUP_TEXT)
			.combination(this.UNGROUP_COMBINATION)
			.action(onUngroup)
			.build()
			.build()
		: this.section()
			.row()
			.text(this.ADD_GROUP_TEXT)
			.combination(this.ADD_GROUP_COMBINATION)
			.action(onGroup)
			.build()
			.build());

	public sectionLayers = (
		onComponentForward: VoidFunction,
		onComponentBack: VoidFunction,
		onComponentForeground: VoidFunction,
		onComponentBackground: VoidFunction,
	) => this.section()
		.row()
		.text(this.MOVE_FORWARD_TEXT)
		.combination(this.MOVE_FORWARD_COMBINATION)
		.action(onComponentForward)
		.build()
		.row()
		.text(this.MOVE_BACK_TEXT)
		.combination(this.MOVE_BACK_COMBINATION)
		.action(onComponentBack)
		.build()
		.row()
		.text(this.MOVE_FOREGROUND_TEXT)
		.combination(this.MOVE_FOREGROUND_COMBINATION)
		.action(onComponentForeground)
		.build()
		.row()
		.text(this.MOVE_BACKGROUND_TEXT)
		.combination(this.MOVE_BACKGROUND_COMBINATION)
		.action(onComponentBackground)
		.build()
		.build();

	public sectionModule = (onModule: VoidFunction) => this.section()
		.row()
		.text(this.MODULE_TEXT)
		.action(onModule)
		.build()
		.build();

	public sectionInterface = (
		onInterfaceShow: VoidFunction,
		onInterfaceHide: VoidFunction,
		getIsShowedInterface: () => boolean,
	) => {
		const isInterfaceShow = getIsShowedInterface();

		return isInterfaceShow
			? this.section()
				.row()
				.text(this.HIDE_INTERFACE_TEXT)
				.combination(this.HIDE_INTERFACE_COMBINATION)
				.action(onInterfaceHide)
				.build()
				.build()
			: this.section()
				.row()
				.text(this.SHOW_INTERFACE_TEXT)
				.combination(this.SHOW_INTERFACE_COMBINATION)
				.action(onInterfaceShow)
				.build()
				.build();
	};

	public sectionCells = (
		onCellsMerge: VoidFunction,
		onCellsSplit: VoidFunction,
		onTableSplit: VoidFunction,
		onColumnAddAfter: VoidFunction,
		onColumnAddBefore: VoidFunction,
		onRowAddUnder: VoidFunction,
		onRowAddOver: VoidFunction,
		onCellsDeleteRow: VoidFunction,
		onCellsDeleteColumn: VoidFunction,
		onCopy: VoidFunction,
		onPaste: VoidFunction,
	) => this.section()
		// Объединить ячейки
		.row()
		.icon(SvgCollection.MERGE_CELLS)
		.text(this.MERGE_CELLS)
		.combination(this.MERGE_CELLS_COMBINATION)
		.action(onCellsMerge)
		.build()
		// Разделить ячейки
		.row()
		.icon(SvgCollection.SPLIT_CELLS)
		.text(this.SPLIT_CELLS)
		.combination(this.SPLIT_COMBINATION)
		.action(onCellsSplit)
		.build()
		// Разделить таблицу
		.row()
		.icon(SvgCollection.SPLIT_TABLE)
		.text(this.SPLIT_TABLE)
		.combination(this.SPLIT_TABLE_COMBINATION)
		.action(onTableSplit)
		.build()
		// Добавить столбец справа
		.row()
		.icon(SvgCollection.COLUMN_RIGHT)
		.text(this.ADD_COLUMN_ON_RIGHT)
		.combination(this.ADD_COLUMN_ON_RIGHT_COMBINATION)
		.action(onColumnAddAfter)
		.build()
		// Добавить столбец слева
		.row()
		.icon(SvgCollection.COLUMN_LEFT)
		.text(this.ADD_COLUMN_ON_LEFT)
		.combination(this.ADD_COLUMN_ON_LEFT_COMBINATION)
		.action(onColumnAddBefore)
		.build()
		// Добавить строку снизу
		.row()
		.icon(SvgCollection.ROW_UNDER)
		.text(this.ADD_ROW_UNDER)
		.combination(this.ADD_ROW_UNDER_COMBINATION)
		.action(onRowAddUnder)
		.build()
		// Добавить строку сверху
		.row()
		.icon(SvgCollection.ROW_OVER)
		.text(this.ADD_ROW_OVER)
		.combination(this.ADD_ROW_OVER_COMBINATION)
		.action(onRowAddOver)
		.build()
		// Удалить строку
		.row()
		.icon(SvgCollection.DELETE_ROW)
		.text(this.DELETE_ROW)
		.combination(this.DELETE_ROW_COMBINATION)
		.action(onCellsDeleteRow)
		.build()
		// Удалить столбец
		.row()
		.icon(SvgCollection.DELETE_COLUMN)
		.text(this.DELETE_COLUMN)
		.combination(this.DELETE_COLUMN_COMBINATION)
		.action(onCellsDeleteColumn)
		.build()
		// Скопировать
		.row()
		.text(this.COPY_TEXT)
		.combination(this.COPY_COMBINATION)
		.action(onCopy)
		.build()
		// Вставить
		.row()
		.text(this.PASTE_TEXT)
		.combination(this.PASTE_COMBINATION)
		.action(onPaste)
		.build()
		.build();

	public sectionTextPanel = (useCases: IBaseUseCases): this => {
		const textPanel = new TextPanel(useCases);
		const menuElement = this.contextMenu.getElement();
		const panelElement = textPanel.getElement();
		menuElement.append(panelElement);
		return this;
	};
}

export default ContextMenuBuilder;
