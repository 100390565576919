import React from 'react';
import UserRolesSelection from '../../../../modals/organization/multipleUserSelectionWindowWrapper/UserRolesSelection';
import DeleteUserWindow from '../../../../modals/users/DeleteUserWindow';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';

interface UserListModalWrapperProps {
	statusRights: checkUsersRightsInterface,
}

const UserListModalWrapper = ({
	statusRights,
}: UserListModalWrapperProps) => (
	<>
		<UserRolesSelection />
		<DeleteUserWindow
			statusRights={statusRights}
		/>
	</>
);

export default UserListModalWrapper;
