import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGetLegalEntityResponse } from '../../entities/legal-entity/api/types';
import { IGetTeamListResponse } from '../../entities/team/api/types';
import { IRoleItem } from '../../entities/role/api/types';
import { IGetPermissionsListResponse, IPermissionsBitmap } from '../../entities/permission/api/types';

const emptyBitMap: IPermissionsBitmap = {
	role: [
		{
			name: 'ROLE_DELETE',
			bitPosition: 0,
		},
		{
			name: 'ROLE_ONLY_ME',
			bitPosition: 0,
		},
		{
			name: 'ROLE_READ',
			bitPosition: 0,
		},
		{
			name: 'ROLE_WRITE',
			bitPosition: 0,
		},
	],
	legalEntity: [
		{
			name: 'OFFER_DISABLE',
			bitPosition: 0,
		},
		{
			name: 'OFFER_READ',
			bitPosition: 0,
		},
		{
			name: 'OFFER_WRITE',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_DELETE',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_READ',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_WRITE',
			bitPosition: 0,
		},
	],
	team: [
		{
			name: 'OFFER_DISABLE',
			bitPosition: 0,
		},
		{
			name: 'OFFER_READ',
			bitPosition: 0,
		},
		{
			name: 'OFFER_WRITE',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_DELETE',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_READ',
			bitPosition: 0,
		},
		{
			name: 'TEMPLATE_WRITE',
			bitPosition: 0,
		},
	],
};

interface IInitialState {
	bitmap: IPermissionsBitmap,
	teams: IGetTeamListResponse | undefined,
	permissionList: IGetPermissionsListResponse | undefined,
	legalEntities: IGetLegalEntityResponse | undefined,
	roles: IRoleItem[] | undefined,
	isDataLoading: boolean
}

const initialState: IInitialState = {
	roles: undefined,
	bitmap: emptyBitMap,
	legalEntities: undefined,
	permissionList: undefined,
	teams: undefined,
	isDataLoading: false,
};

export const permissionsSlice = createSlice({
	name: 'permissions',
	initialState,
	reducers: {
		setRolesList: (state, action: PayloadAction<IRoleItem[] | undefined>) => {
			state.roles = action.payload;
		},
		setPermissionList: (state, action: PayloadAction<IGetPermissionsListResponse>) => {
			state.permissionList = action.payload;
		},
		setBitmap: (state, action: PayloadAction<IPermissionsBitmap>) => {
			state.bitmap = action.payload;
		},
		setTeamsList: (state, action: PayloadAction<IGetTeamListResponse | undefined>) => {
			state.teams = action.payload;
		},
		setLegalEntityList: (state, action: PayloadAction<IGetLegalEntityResponse | undefined>) => {
			state.legalEntities = action.payload;
		},
		setIsDataLoading: (state, action: PayloadAction<boolean>) => {
			state.isDataLoading = action.payload;
		},
	},
});

export const {
	setTeamsList,
	setBitmap,
	setRolesList,
	setPermissionList,
	setLegalEntityList,
	setIsDataLoading,
} = permissionsSlice.actions;

export default permissionsSlice.reducer;
