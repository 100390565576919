import React from 'react';
import { useDispatch } from 'react-redux';
import { checkUsersRightsInterface } from '../../../../../helpers/usersRights';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../Notifications/callNotifcation';
import css from './roles.module.scss';
import EmptyRoles from './EmptyRoles';
import SkeletonRoles from './SkeletonRoles';
import { setIsRoleCreateModalActive } from '../../../../../redux/reducers/usersReducer';
import RoleCreateModal from './RoleCreateModal';
import { roleAPI } from '../../../../../entities/role/api/api';
import RolesList from './RolesList';
import RoleDeleteConfirmModal from './RoleDeleteConfirmModal';
import { CreateButton } from '../../../../../shared/ui/buttons/create/create';

interface RolesListProps {
	statusRights: checkUsersRightsInterface,
}

const SKELETON_ITEM_COUNT = 66;

const Roles = ({
	statusRights,
}: RolesListProps) => {
	const dispatch = useDispatch();

	// const rolesList = useSelector((state: RootState) => roles(state));

	const {
		data: rolesData,
		isLoading,
	} = roleAPI.useGetAllQuery();

	const handleCreateRoleModal = (value: boolean) => {
		dispatch(setIsRoleCreateModalActive(value));
	};

	// * Определяет есть ли у человека права для создания роли
	const isHaveRightToShowCreateRoleModal = () => {
		if (statusRights.registration) {
			handleCreateRoleModal(true);
		} else {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		}
	};

	return (
		<div className={css.rolesWrapper}>
			<RoleCreateModal
				statusRights={statusRights}
			/>
			<RoleDeleteConfirmModal />
			<div className={css.searchBlock}>
				<CreateButton onClick={isHaveRightToShowCreateRoleModal}>
					Создать роль
				</CreateButton>
				{/* <SearchInput */}
				{/*    onChange={setRolesSearchValue} */}
				{/*    placeholder='Поиск по ролям' */}
				{/*    searchValue={rolesSearchValue} */}
				{/* /> */}
			</div>
			<div className={css.rolesBlock}>
				{
					isLoading
						? <SkeletonRoles count={SKELETON_ITEM_COUNT} />
						: null
				}
				{
					rolesData?.roles && !isLoading
						? <RolesList statusRights={statusRights} roles={rolesData.roles} />
						: <EmptyRoles isHaveRightToShowCreateRoleModal={isHaveRightToShowCreateRoleModal} />
				}
			</div>
		</div>
	);
};

export default Roles;
