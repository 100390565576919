import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGalleryTemplate, IGalleryTemplateState } from './types';
import { ITagData } from '../../../modules/tag/api/types';

const initialState: IGalleryTemplateState = {
	search: '',
	tagFilter: [],
	galleryTemplates: undefined,
};

export const galleryTemplate = createSlice({
	name: 'galleryTemplate',
	initialState,
	reducers: {
		setGalleryTemplateSearch: (state, action: PayloadAction<string>) => {
			state.search = action.payload;
		},
		setGalleryTemplates: (state, action: PayloadAction<IGalleryTemplate[]>) => {
			state.galleryTemplates = action.payload;
		},
		setGalleryTagFilter: (state, action: PayloadAction<ITagData[]>) => {
			state.tagFilter = action.payload;
		},
	},
});

export const {
	setGalleryTemplates,
	setGalleryTagFilter,
	setGalleryTemplateSearch,
} = galleryTemplate.actions;

export default galleryTemplate.reducer;
