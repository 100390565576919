import { useState } from 'react';

export const useChangeTariffSelectedData = () => {
	const [data, setData] = useState<string[]>([]);

	const handle = (id: string) => {
		if (data.includes(id)) {
			setData(data.filter(el => el !== id));
		} else {
			setData([id]);
		}
	};

	return {
		data,
		handle,
	};
};
