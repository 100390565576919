import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../../../shared/libs/redux';
import { notificationError, notificationSuccess } from '../../../../../../Notifications/callNotifcation';
import { teamTemplateAPI } from '../../../../../../../entities/templates/team/api/api';
import { setIsConfirmTeamDeleteModalActive } from '../../../../../../../entities/templates/team/model/slice';

export const useDeleteTeamTemplate = () => {
	const dispatch = useDispatch();

	const selectedTemplate = useAppSelector(state => state.teamTemplate.selectedTeamTemplate);

	const setActive = (value: boolean) => dispatch(setIsConfirmTeamDeleteModalActive(value));

	const [del, { isLoading }] = teamTemplateAPI.useDeleteMutation();

	const deleteTemplate = async () => {
		if (selectedTemplate) {
			try {
				await del({ id: selectedTemplate.id })
					.unwrap();
				notificationSuccess('Шаблон успешно удален!');
			} catch (e) {
				notificationError('Возникла ошибка при удалении шаблона!');
			} finally {
				setActive(false);
			}
		}
	};

	return {
		isLoading,
		setActive,
		deleteTemplate,
	};
};
