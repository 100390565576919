import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { ReactComponent as OpenArrowIcon } from '../images/open-arrow.svg';
import { ReactComponent as CloseArrowIcon } from '../images/close-arrow.svg';
import { ScrollableFilterContent } from './scrollable-filter-content/scrollable-filter-content';
import { dropDownTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';
import { useOutSideClick } from '../../../hooks/mouse/useNewOutSideClick';
import css from './dropdown-filter.module.scss';

interface DropdownFilterProps {
	styles?: string,
	selected: string,
	children: React.ReactNode,
	scrollContentHeight: number,
}

export const DropdownFilter = ({
	styles,
	children,
	selected,
	scrollContentHeight,
}: DropdownFilterProps) => {
	const [open, setOpen] = useState(false);

	const dropDownRef = useRef(null);
	const wrapperRef = useOutSideClick(() => setOpen(false), false, 'portal');

	return (
		<div className={cx(css.wrapper, open && css.opened_wrapper, styles)} ref={wrapperRef}>
			<div role="presentation" className={css.default} onClick={() => setOpen(!open)}>
				<div>{selected}</div>
				{
					open
						? <OpenArrowIcon />
						: <CloseArrowIcon />
				}
			</div>
			<CSSTransition
				in={open}
				classNames={dropDownTransitionClasses}
				nodeRef={dropDownRef}
				timeout={200}
				unmountOnExit
			>
				<div className={cx(css.content, styles)} ref={dropDownRef}>
					<ScrollableFilterContent styles={css.scroll_content} height={scrollContentHeight + 1}>
						{children}
					</ScrollableFilterContent>
				</div>
			</CSSTransition>
		</div>
	);
};
