import React, { useCallback } from 'react';
import { setIsDeleteUserModalActive, setUsers } from '../../../redux/reducers/usersReducer';
import { checkUsersRightsInterface } from '../../../helpers/usersRights';
import { ERROR_MESSAGE_LIST, notificationError, notificationSuccess } from '../../Notifications/callNotifcation';
import { employeeAPI } from '../../../entities/employee/api/api';
import { teamAPI } from '../../../entities/team/api/api';
import { DeleteModalWrapper } from '../../ui/delete-modal-wrapper/delete-modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../shared/libs/redux';

interface DeleteUserWindowProps {
	statusRights: checkUsersRightsInterface,
}

const DeleteUserWindow = ({
	statusRights,
}: DeleteUserWindowProps) => {
	const dispatch = useAppDispatch();

	const deletingUserEmail = useAppSelector(state => state.user.deletingUserEmail);
	const isActive = useAppSelector(state => state.user.modalsActive.isDeleteUserModalActive);

	const handleDeleteModal = (value: boolean) => {
		dispatch(setIsDeleteUserModalActive(value));
	};

	const [deleteUser] = employeeAPI.useDeleteMutation();

	// * При увольнении пользователя
	const onDeleteUser = useCallback(async () => {
		if (!statusRights.delete) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else if (deletingUserEmail) {
			try {
				await deleteUser({ email: deletingUserEmail })
					.unwrap();
				dispatch(setIsDeleteUserModalActive(false));
				notificationSuccess('Пользователь успешно удален!');

				dispatch(setUsers([]));
				dispatch(teamAPI.util?.invalidateTags(['team']));
				dispatch(employeeAPI.util?.invalidateTags(['employee']));
			} catch (err) {
				notificationError('Произошла ошибка при удалении пользователя...');
			}
		}
	}, [deletingUserEmail, statusRights.delete]);

	return (
		<DeleteModalWrapper
			description="Вы действильно хотите удалить пользователя без возможности восстановления?"
			isActive={isActive}
			setIsActive={handleDeleteModal}
			onDelete={onDeleteUser}
		/>
	);
};

export default DeleteUserWindow;
