import ElementContainer from '../../../../utils/ElementContainer';
import HTMLElementName from '../../../../utils/HTMLElementName';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import ContextMenuSectionBuilder from './ContextMenuSectionBuilder';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';

/**
 * Сборщик строки контекстного меню.
 */
class ContextMenuRowBuilder extends ElementContainer<HTMLDivElement> {
	private readonly CAPTION_CLASS_NAME = 'context-menu__row-caption';
	private readonly ELEMENT_CLASS_NAME = 'context-menu__row';
	private readonly COMBINATION_CLASS_NAME = 'context-menu__row-combination';

	private readonly sectionBuilder: ContextMenuSectionBuilder;

	constructor(sectionBuilder: ContextMenuSectionBuilder) {
		super(HTMLElementName.DIV);
		this.sectionBuilder = sectionBuilder;
		this.setRootElementClassName(this.ELEMENT_CLASS_NAME);
	}

	public icon = (icon: SvgCollection): this => {
		Utils.DOM.injectSVG(this.rootElement, icon);
		return this;
	};

	public text = (text: string): this => {
		const textElement = HTMLGenerator.getSpan({
			text,
			className: this.CAPTION_CLASS_NAME,
		});
		this.rootElement.append(textElement);
		return this;
	};

	public action = (action: VoidFunction): this => {
		this.addRootElementClickListener(action);
		return this;
	};

	public combination = (text: string): this => {
		const combinationElement = HTMLGenerator.getSpan({
			text,
			className: this.COMBINATION_CLASS_NAME,
		});
		this.rootElement.append(combinationElement);
		return this;
	};

	public build = (): ContextMenuSectionBuilder => this.sectionBuilder;
}

export default ContextMenuRowBuilder;
