import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { dropDownTransitionClasses } from '../../../../styles/transitionStyles/transitionStyles';
import { HiddenUserCard } from './user-card/user-card';
import css from './hidden-users.module.scss';
import AddBtn from '../../../../components/ui/addBtn/AddBtn';
import { IAccount } from '../../../../entities/user/account/api/types';

interface HiddenUsersProps {
	deleteRight: boolean,
	users: IAccount[],
	onAddUser: () => void,
	areUsersShown: boolean,
	onAddManager?: (() => void) | undefined,
	untieUser: (email: string) => void,
}

export const HiddenUsers = ({
	users,
	untieUser,
	onAddManager,
	onAddUser,
	deleteRight,
	areUsersShown,
}: HiddenUsersProps) => {
	const dropDownRef = useRef(null);

	return (
		<CSSTransition
			in={areUsersShown}
			classNames={dropDownTransitionClasses}
			nodeRef={dropDownRef}
			timeout={200}
			unmountOnExit
		>
			<div ref={dropDownRef}>
				{
					users?.map(user => (
						<HiddenUserCard
							key={user.email}
							data={user}
							deleteRight={deleteRight}
							untieUser={untieUser}
						/>
					))
				}
				<div className={onAddManager && css.multiple_buttons}>
					<AddBtn
						btnText="Добавить сотрудника"
						onBtnClick={onAddUser}
						styles={cx(css.add_btn, css.employee_btn)}
					/>
					{
						onAddManager && (
							<AddBtn
								btnText="Добавить менеджера"
								onBtnClick={onAddManager}
								styles={cx(css.add_btn, css.manager_btn)}
							/>
						)
					}
				</div>
			</div>
		</CSSTransition>
	);
};
