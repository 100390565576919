import { useSelector } from 'react-redux';
import {
	workshopTagGroupActions,
	workshopTagGroupApi,
	workshopTagGroupSlice,
} from '../../../../entities/workshop-tag-group';
import { useAppDispatch } from '../../../../shared/libs/redux';
import { notificationError } from '../../../../components/Notifications/callNotifcation';

export const useCreateWorkshopTagGroup = () => {
	const dispatch = useAppDispatch();

	const [createWorkshopTagGroup, requestData] = workshopTagGroupApi.useCreateMutation();

	const name = useSelector(workshopTagGroupSlice.selectors.getCreateGroupName);
	const tags = useSelector(workshopTagGroupSlice.selectors.getCreatedGroupTags);

	const onCreate = async () => {
		try {
			if (name) {
				await createWorkshopTagGroup({ tags, name }).unwrap();

				dispatch(workshopTagGroupActions.setIsCreateGroupModalActive(false));
				dispatch(workshopTagGroupActions.setCreateGroupName(''));
				dispatch(workshopTagGroupActions.setCreatedGroupTags([]));
			}
		} catch (e) {
			notificationError('Произошла ошибка', 'Не удалось создать группу тегов');
		}
	};

	return {
		onCreate,
		requestData,
	};
};
