import React, { useState } from 'react';
import css from './input.module.scss';
import { HandleInputSizeI, useHandleInputSize } from '../../../../hooks/useHandleInputSize';
import useCatchEnterClick from '../../../../hooks/useCatchEnterClick';

const CreateCategoryInputData: HandleInputSizeI = {
	initialInputWidth: 176,
	fontSize: 12,
};

interface CreateCategoryInputProps {
	onCreateCategory: (name: string) => void,
}

export const CreateCategoryInput = ({ onCreateCategory }: CreateCategoryInputProps) => {
	const [value, setValue] = useState('');

	const {
		inputRef,
		handleInputChange,
	} = useHandleInputSize(setValue, CreateCategoryInputData);

	const isFocused = document.activeElement === inputRef.current;

	const createCategory = () => {
		onCreateCategory(value);
		handleInputChange('');
		inputRef.current?.blur();
	};

	useCatchEnterClick(createCategory, isFocused && !!value.length);

	return (
		<input
			className={css.input}
			placeholder="Создать новую категорию"
			maxLength={32}
			ref={inputRef}
			value={value}
			onChange={e => handleInputChange(e.target.value)}
		/>
	);
};
