import React from 'react';
import css from './template-search-bar.module.scss';
import FilterBox, { FilterBoxData } from '../../../components/pages/collection/section/template/my/FilterBox';
import SearchInput from '../../../components/ui/searchInput/SearchInput';

interface TemplateSearchBarProps {
	sortingData: FilterBoxData,
	search: string,
	setSearch: (value: string) => void,
}

export const TemplateSearchBar = ({
	sortingData,
	search,
	setSearch,
}: TemplateSearchBarProps) => (
	<div className={css.wrapper}>
		<FilterBox sortingData={sortingData} />
		<SearchInput styles={css.search} placeholder="Поиск по шаблонам" searchValue={search} onChange={setSearch} />
	</div>
);
