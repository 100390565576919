import ImageGraphicBlock from '../../ImageGraphicBlock';
import HTMLGenerator from '../../../../../utils/HTMLGenerator';
import ManipulatorError from '../../../../../utils/manipulator-error/ManipulatorError';
import IDimensions2D from '../../../../../utils/IDimensions2D';
import Utils from '../../../../../utils/impl/Utils';
import HighlightPictureArea from './HighlightPictureArea';
import PictureTrimArea from './PictureTrimArea';
import Graphic from '../../../../Graphic';
import IImageTexture from '../../IImageTexture';
import IGraphic from '../../../../IGraphic';

/**
 * Блок для отображения состояния изображения на этапе показа изображения.
 */
class ImageTextureBlock extends ImageGraphicBlock {
	private readonly HIGHLIGHT_CLASS_NAME = 'highlight';
	private readonly ELEMENT_CLASS_NAME = 'picture-block__picture-container';
	private readonly PICTURE_CLASS_NAME = 'picture-block__picture';
	private readonly PICTURE_OVERFLOW_CLASS_NAME = 'overflow';
	private readonly CSS_PROPERTY_BORDER_RADIUS = 'border-radius';

	private readonly pictureElement: HTMLImageElement;

	private readonly highlightPictureArea: HighlightPictureArea;
	private readonly pictureTrimArea: PictureTrimArea;

	private pictureLoadEvents: VoidFunction[];

	protected readonly graphic: IGraphic;
	protected readonly texture: IImageTexture;

	constructor(graphic: IGraphic) {
		super(graphic);
		this.highlightPictureArea = new HighlightPictureArea();
		this.pictureTrimArea = new PictureTrimArea();
		this.graphic = graphic;
		this.pictureLoadEvents = [];
		this.pictureElement = HTMLGenerator.getImg({
			className: this.PICTURE_CLASS_NAME,
		}) as HTMLImageElement;
		this.texture = {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
			source: null,
			radius: {
				topLeft: 0,
				topRight: 0,
				bottomLeft: 0,
				bottomRight: 0,
			},
		};

		const highlightAreaElement = this.highlightPictureArea.getElement();
		const trimAreaElement = this.pictureTrimArea.getElement();
		this.highlightPictureArea.disable();
		this.pictureTrimArea.disable();

		this.element.append(this.pictureElement, highlightAreaElement, trimAreaElement);
		this.setElementClassName(this.ELEMENT_CLASS_NAME);
		this.disableOverflowPicture();

		this.pictureElement.addEventListener('load', this.onPictureLoad);
	}

	public setTexture = (fn: (prev: IImageTexture) => IImageTexture) => {
		const currentTexture = this.getTexture();
		if (currentTexture === null) {
			throw new ManipulatorError('texture is null');
		}
		const updatedTexture = fn(currentTexture);

		if (currentTexture.x !== updatedTexture.x) {
			this.pictureElement.style.left = `${updatedTexture.x}px`;
			this.texture.x = updatedTexture.x;
		}

		if (currentTexture.y !== updatedTexture.y) {
			this.pictureElement.style.top = `${updatedTexture.y}px`;
			this.texture.y = updatedTexture.y;
		}

		if (currentTexture.width !== updatedTexture.width) {
			this.pictureElement.style.width = `${updatedTexture.width}px`;
			this.texture.width = updatedTexture.width;
		}

		if (currentTexture.height !== updatedTexture.height) {
			this.pictureElement.style.height = `${updatedTexture.height}px`;
			this.texture.height = updatedTexture.height;
		}

		if (currentTexture.source !== updatedTexture.source) {
			this.pictureElement.src = updatedTexture.source === null
				? ''
				: Utils.Backend.getImageURI(updatedTexture.source);
			this.texture.source = updatedTexture.source;
		}

		if (updatedTexture.radius && !Utils.Object.deepEqual(currentTexture.radius, updatedTexture.radius)) {
			this.element.style.setProperty(
				this.CSS_PROPERTY_BORDER_RADIUS,
				`${updatedTexture.radius.topLeft}px ${updatedTexture.radius.topRight}px
				${updatedTexture.radius.bottomLeft}px ${updatedTexture.radius.bottomRight}px`,
			);
			this.texture.radius = updatedTexture.radius;
		}

		const frameConfiguration = this.graphic.getFrameConfiguration();
		this.highlightPictureArea.updateAreas(frameConfiguration, updatedTexture);
	};

	public getPictureNaturalAspectRatio = (): number => {
		const size = this.getNaturalPictureSize();
		return size.width / size.height;
	};

	public getNaturalPictureSize = (): IDimensions2D => {
		const width = this.pictureElement.naturalWidth;
		const height = this.pictureElement.naturalHeight;
		if (width === 0 || height === 0) {
			throw new ManipulatorError('not setup picture');
		}
		return {
			width, height,
		};
	};

	public enableEditMode = () => {
		this.enableHighlight();
		this.enableOverflowPicture();
		this.pictureTrimArea.enable();
		this.highlightPictureArea.enable();
	};

	public disableEditMode = () => {
		this.disableHighlight();
		this.disableOverflowPicture();
		this.pictureTrimArea.disable();
		this.highlightPictureArea.disable();
	};

	public enableHighlight = () => {
		this.pictureElement.classList.add(this.HIGHLIGHT_CLASS_NAME);
	};

	public disableHighlight = () => {
		this.pictureElement.classList.remove(this.HIGHLIGHT_CLASS_NAME);
	};

	public enableOverflowPicture = () => {
		this.element.classList.remove(this.PICTURE_OVERFLOW_CLASS_NAME);
	};

	public disableOverflowPicture = () => {
		this.element.classList.add(this.PICTURE_OVERFLOW_CLASS_NAME);
	};

	public addPostLoadPictureListener = (event: () => void) => {
		this.pictureLoadEvents.push(event);
	};

	override getTexture = (): IImageTexture => ({ ...this.texture });

	private onPictureLoad = () => {
		if (this.pictureLoadEvents === null) {
			return;
		}

		this.pictureLoadEvents.forEach(event => event());
		this.pictureLoadEvents = [];
	};
}

export default ImageTextureBlock;
