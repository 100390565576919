import { ReactComponent as WorkshopIcon } from '../images/workshop.svg';
import { ReactComponent as GalleryIcon } from '../images/gallery.svg';
import { ReactComponent as StarterKitsIcon } from '../images/starter-kits.svg';
import { ReactComponent as UserIcon } from '../../../../../shared/images/collection/user.svg';
import { ICollectionTab } from '../../../../../shared/ui/tab/model/types';

export const LINK_INTEGRATOR_WORKSHOP = 'workshop';
export const LINK_INTEGRATOR_USERS = 'users/';
export const LINK_INTEGRATOR_GALLERY = 'gallery';
export const LINK_INTEGRATOR_START_KITS = 'start-kits';

export const integratorTabsData: ICollectionTab[] = [
	{
		name: 'Мастерская',
		link: LINK_INTEGRATOR_WORKSHOP,
		picture: <WorkshopIcon />,
	},
	{
		name: 'Пользователи',
		link: LINK_INTEGRATOR_USERS,
		picture: <UserIcon />,
		disabled: true,
	},
	{
		name: 'Галерея',
		link: LINK_INTEGRATOR_GALLERY,
		picture: <GalleryIcon />,
		disabled: true,
	},
	{
		name: 'Стартовые наборы',
		link: LINK_INTEGRATOR_START_KITS,
		picture: <StarterKitsIcon />,
		disabled: true,
	},
];
