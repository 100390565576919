import React from 'react';
import { EntityCard } from '../../../../../../../../widgets/entity/card';
import { checkUsersRightsInterface } from '../../../../../../../../helpers/usersRights';
import { ILegalEntityItem } from '../../../../../../../../entities/legal-entity/api/types';
import { useAppDispatch } from '../../../../../../../../shared/libs/redux';
import { ERROR_MESSAGE_LIST, notificationError } from '../../../../../../../Notifications/callNotifcation';
import {
	setAddingManagerLegalEntityId,
	setBindingLegalEntityId,
	setDeletingLegalEntityId,
	setIsLegalEntityConfirmDeleteModalActive,
	setIsLegalEntityUserConfirmDeleteModalActive,
	setIsLegalEntityUserSelectionModalActive,
	setIsManagersModalActive,
	setLegalEntityUntieEmail,
	setLegalEntityUntieId,
} from '../../../../../../../../redux/reducers/legalEntityReducer';

interface LegalEntityCardProps {
	legalEntity: ILegalEntityItem,
	statusRights: checkUsersRightsInterface,
}

export const LegalEntityCard = ({
	legalEntity,
	statusRights,
}: LegalEntityCardProps) => {
	const dispatch = useAppDispatch();

	const { id } = legalEntity;

	const onDeleteLegalEntity = (id: string) => {
		if (!statusRights.delete) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setDeletingLegalEntityId(id));
			dispatch(setIsLegalEntityConfirmDeleteModalActive(true));
		}
	};

	const onUntieUser = (email: string, team: string) => {
		if (!statusRights.delete) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setLegalEntityUntieId(team));
			dispatch(setLegalEntityUntieEmail(email));
			dispatch(setIsLegalEntityUserConfirmDeleteModalActive(true));
		}
	};

	const onBindingLegalEntity = () => {
		if (!statusRights.registration) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setBindingLegalEntityId(id));
			dispatch(setIsLegalEntityUserSelectionModalActive(true));
		}
	};

	const onAddManager = () => {
		if (!statusRights.registration) {
			notificationError(
				ERROR_MESSAGE_LIST.ERROR,
				'У вас недостаточно прав для совершения этого действия!',
			);
		} else {
			dispatch(setIsManagersModalActive(true));
			dispatch(setAddingManagerLegalEntityId(id));
		}
	};

	return (
		<EntityCard
			entity={legalEntity}
			onAddManager={onAddManager}
			onUntieUser={onUntieUser}
			statusRights={statusRights}
			deleteText="Удалить юр. лицо"
			onDeleteEntity={onDeleteLegalEntity}
			onBindingEntity={onBindingLegalEntity}
		/>
	);
};
