import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError } from '../../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';

export const useEditTariffAvailability = (template: string) => {
	const dispatch = useAppDispatch();

	const [update] = workshopTemplateAPI.useUpdateMutation();

	const updateTariff = async (tariffPermission: number) => {
		try {
			await update({
				template,
				gallerySettings: {
					tariffPermission,
				},
			})
				.unwrap();
			dispatch(workshopTemplateAPI.util?.invalidateTags(['templates']));
		} catch (e) {
			notificationError('Произошла ошибка при изменении доступности шаблона в тарифах...');
		}
	};

	return {
		updateTariff,
	};
};
