import { v4 as uuid } from 'uuid';
import HTMLGenerator from '../../../../utils/HTMLGenerator';
import Utils from '../../../../utils/impl/Utils';
import SvgCollection from '../../../../utils/SvgCollection';
import store from '../../../../../../redux/store/store';
import { getSocket } from '../../../../../../features/websocket/model/slice';
import SocketStatus from '../../../../../../features/websocket/model/types/SocketStatus';
import { ServerWebSocketType } from '../../../../../../features/websocket/model/types/serverWebSocketType';
import { messageQueue } from '../../../../../../features/websocket/model/webSocketMiddleware';
import { tariff } from '../../../../../../redux/reducers/personalAccountReducer';
import { Tariff } from '../../../../../../entities/tariff/model/data';
import ManipulatorError from '../../../../utils/manipulator-error/ManipulatorError';

export interface IGenerationCounterData {
	max_balance: string,
	current_balance: string,
	next_date_update: string | null,
	current_time_server: string | null,
}

/** Элемент интерфейса верхней панели инструментов - счетчик генераций */
class AiGenerationCounter {
	private readonly rootElement: HTMLElement;	// Счётчик - обертка
	private readonly generationCounterElement: HTMLElement;	// Счетчик - оставшиеся генерации
	private readonly imgElement: HTMLElement;	// Счётчик - иконка

	// Всплывающая подсказка
	private readonly hintElement: HTMLElement;
	// Оставшиеся генерации во всплывающем окне
	private readonly remainingGenElement: HTMLElement;
	// Количество максимальных генераций во всплывающем окне
	private readonly totalGenElement: HTMLElement;
	// Время до обновления количества генераций (после ответа сервера будем запускать счетчик на клиенте)
	private readonly CountdownElement: HTMLElement;

	private readonly websocket: WebSocket | null;

	private isLoading = true; // Флаг загрузки данных
	private generationData: IGenerationCounterData | null = null;
	private isOpen: boolean;

	constructor(addDestructListener: (event: () => void) => void) {
		// websocket
		const state = store.getState();
		this.websocket = getSocket(state);
		if	(this.websocket?.readyState === WebSocket.OPEN) {
			this.websocket.onmessage = (event) => {
				const data = JSON.parse(event.data);
				if (data.type === ServerWebSocketType.aiGenerationsBalance && SocketStatus.OK) {
					this.updateUi(JSON.parse(data.body));
				}
			};
		}
		this.sendGenRequest();

		/* Основной элемент - счётчик */
		this.rootElement = HTMLGenerator.getDiv({
			className: 'gen-counter',
			fnMouseDown: this.onClick,
		});
		this.generationCounterElement = HTMLGenerator.getDiv({
			className: 'gen-counter__number',
		});
		this.imgElement = HTMLGenerator.getDiv({
			className: 'gen-counter__img',
			fnMouseDown: this.onClick,
		});
		Utils.DOM.injectSVG(this.imgElement, SvgCollection.generationCounter);
		this.rootElement.append(this.generationCounterElement);
		this.rootElement.append(this.imgElement);

		// Элемент подсказка
		this.hintElement = HTMLGenerator.getDiv({
			className: 'gen-hint',
		});

		// Обертка для генераций и ссылки
		const genWrapper = HTMLGenerator.getDiv({
			className: 'gen-wrapper',
		});
		const generations = HTMLGenerator.getDiv({ // Блок генераций (числа + "генераций")
			className: 'generations',
		});
		const numbersWrapper = HTMLGenerator.getDiv({
			className: 'generations__numbers',
		});
		this.remainingGenElement = HTMLGenerator.getDiv({
			className: 'generations__remaining',
		});
		numbersWrapper.append(this.remainingGenElement);
		numbersWrapper.append('/');
		this.totalGenElement = HTMLGenerator.getDiv({
			className: 'generations__total',
		});
		numbersWrapper.append(this.totalGenElement);
		generations.append(numbersWrapper);
		const text = HTMLGenerator.getDiv({
			className: 'generations__text',
		});
		text.append('Генераций');
		generations.append(text);

		// Блок со ссылкой
		const btn = HTMLGenerator.getDiv({
			className: 'tariffs-btn',
		});
		const link = HTMLGenerator.getHTMlElement<HTMLAnchorElement>({
			tag: 'a',
			className: 'tariffs-btn__link',
			text: 'Тарифы',
			target: 'blanc',
		});
		link.href = 'https://wakadoo.pro/tarif';
		btn.append(link);

		// Блок "восстановятся через"
		const timer = HTMLGenerator.getDiv({
			className: 'countdown',
		});
		const timerText = HTMLGenerator.getSpan({
			className: 'countdown__text',
			text: 'Восстановятся через',
		});
		this.CountdownElement = HTMLGenerator.getSpan({
			className: 'countdown__time',
		});
		timer.append(timerText, this.CountdownElement);

		genWrapper.append(generations);
		genWrapper.append(btn);
		this.hintElement.append(genWrapper);
		this.hintElement.append(timer);
		this.rootElement.append(this.generationCounterElement);
		this.rootElement.append(this.hintElement);

		/* Подсказка отличается стрелкой и расположением в зависимости от тарифа (пробный тариф содержит
		 уведомление и поэтому сдвигает блок) */
		const userTariff = tariff(state);
		if (userTariff === Tariff.TRIAL) {
			this.hintElement.classList.add('trial');
			this.rootElement.classList.add('trial');
		}

		document.body.addEventListener('mousedown', this.handleClickOutside);
		this.addClickOutsideListener();
		addDestructListener(() => this.removeClickOutsideListener());

		this.updateUi(null);
	}

	// Отправляет запрос на получение общего, текущего количества генераций, актуального времени и до конца обновления
	public sendGenRequest = async () => {
		const message = JSON.stringify({
			id: uuid(),
			type: 'ai.balance',
		});
		if	(this.websocket?.readyState === WebSocket.OPEN) {
			this.websocket.send(message);
		} else {
			messageQueue.enqueue(message);
		}
	};

	public getElement = () => this.rootElement;

	public startCountdown = (currentTime: string, endTime: string) => {
		const currentDate = new Date(currentTime);
		const targetDate = new Date(endTime);

		const now = currentDate.getTime();
		const distance = targetDate.getTime() - now;

		// Вычисляем оставшееся время
		const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

		if (hours > 0) {
			this.CountdownElement.innerHTML = ` ${hours}ч`;
		} else {
			this.CountdownElement.innerHTML = ` ${minutes}м`;
		}

		const interval = setInterval(() => {
			// Проверка на наличие оставшегося времени
			if (distance < 0) {
				clearInterval(interval);
			} else if (hours > 0) {
				this.CountdownElement.innerHTML = ` ${hours}ч`;
			} else {
				this.CountdownElement.innerHTML = ` ${minutes}м`;
			}
		}, 60000);
	};

	private addClickOutsideListener() {
		document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
	}

	private removeClickOutsideListener() {
		document.body.removeEventListener('mousedown', this.handleClickOutside);
	}

	private updateUi(data: IGenerationCounterData | null) {
		if (data) {
			this.generationData = data; // Сохраняем данные
			this.isLoading = false; // Данные загружены
			this.totalGenElement.innerText = data.max_balance.toString();
			this.remainingGenElement.innerText = data.current_balance.toString();
			this.generationCounterElement.innerHTML = this.renderGenerationCounter();
			if (data.current_time_server === null || !data.next_date_update) {
				throw new ManipulatorError(`Данные времени окончания тарифа
				не пришли с сервера`); 
			}
			this.startCountdown(data.current_time_server, data.next_date_update);
		} else {
			// Обработка случая, если данных нет
			this.generationData = {
				max_balance: '...',
				current_balance: '...',
				next_date_update: null,
				current_time_server: null,
			};
			this.isLoading = false;
			this.generationCounterElement.innerHTML = '...';

			setTimeout(() => {
				this.sendGenRequest();
			}, 2000);
		}
	}

	private renderGenerationCounter() {
		if (this.isLoading) {
			return 'Загрузка...';
		}
		if (this.generationData) {
			return `${this.generationData.current_balance}`;
		}
		return '...'; // На случай, если данные пустые или невалидные
	}

	private onClick = (e: MouseEvent) => {
		e.stopPropagation();
		if (this.isOpen && !this.hintElement.contains(e.target as HTMLElement)) {
			this.hideHint();
		} else {
			this.showHint();
		}
	};

	private handleClickOutside = (event: MouseEvent) => {
		if (
			!this.hintElement.contains(event.target as Node)
			&& this.isOpen
			&& event.target !== this.rootElement
			&& event.target !== this.generationCounterElement
			&& !this.imgElement.contains(event.target as Node)
		) {
			this.hideHint();
			this.isOpen = false;
		}
	};

	private showHint = () => {
		this.hintElement.classList.add('show');
		this.isOpen = true;
	};

	private hideHint = () => {
		this.hintElement.classList.remove('show');
		this.isOpen = false;
	};
}

export default AiGenerationCounter;
