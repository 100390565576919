import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch } from '../../../../../../../../../shared/libs/redux';
import { copyBuffer, domainName } from '../../../../../../../../../helper';
import { notificationSuccess } from '../../../../../../../../Notifications/callNotifcation';
import {
	setIsConfirmTeamDeleteModalActive,
	setIsConfirmTeamMoveModalActive,
} from '../../../../../../../../../entities/templates/team/model/slice';

export const useTeamContextMenuActions = (id: string) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const [isActionMenuOpened, setIsActionMenuOpened] = useState(false);

	const closeActionMenu = () => setIsActionMenuOpened(false);

	// * Скопировать ссылку на превью шаблона
	const copyLink = () => copyBuffer(`${domainName}presentation/template/${id}`);

	const onCopyLink = () => {
		copyLink();
		closeActionMenu();
		notificationSuccess('Ссылка скопирована в буфер обмена!');
	};

	// * Создать из шаблона предложение
	const onSendOffer = () => navigate(`/team-template-present/${id}`);

	// * Удаление шаблона
	const onDelete = () => {
		dispatch(setIsConfirmTeamDeleteModalActive(true));
		closeActionMenu();
	};

	const onMoveTemplate = () => {
		dispatch(setIsConfirmTeamMoveModalActive(true));
		closeActionMenu();
	};

	return {
		isActionMenuOpened,
		setIsActionMenuOpened,
		onCopyLink,
		onDelete,
		onSendOffer,
		onMoveTemplate,
	};
};
