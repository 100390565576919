import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import AreaSizeMutator from '../../spatial-quadrants/area-mutators/mutators/AreaSizeMutator';
import AreaResizeTrigger from '../../spatial-quadrants/spatial-tree/spatial-area/AreaResizeTrigger';
import IFrameArea from '../../spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import DirectionVector from '../DirectionVector';
import MagneticLineEntity from '../MagneticLineEntity';
import MagneticCorrector from './MagneticCorrector';
import IMagneticLines from '../IMagneticLines';

class MagneticSizeCorrector extends MagneticCorrector<AreaSizeMutator> {
	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(magneticLines: IMagneticLines, areaPositionMutator: AreaSizeMutator) {
		super(magneticLines, areaPositionMutator);
	}

	protected calculateCorrectValues = (
		magneticLineEntities: MagneticLineEntity[],
		dryConfiguration: IFrameArea,
		correctValues: IFrameArea,
	): void => {
		if (this.initiatorGraphic === null || this.startConfiguration === null) {
			throw new ManipulatorError('required entities not found');
		}

		for (let i = 0; i < magneticLineEntities.length; i++) {
			const lineEntity = magneticLineEntities[i];
			const realConfiguration = this.initiatorGraphic.getFrameArea();
			if (realConfiguration === undefined) {
				return;
			}
			const isVisualizePosition = lineEntity.isVisualizeZone(realConfiguration);
			const isMagnetizationZone = lineEntity.isMagnetizationZone(dryConfiguration);

			if (lineEntity.linePosition.x === dryConfiguration.width / 2 + dryConfiguration.x) {
				lineEntity.line.hide();
			}

			switch (this.mutator.getActiveTrigger()) {
			case AreaResizeTrigger.LEFT:
				if (this.leftTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeLeft(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.RIGHT:
				if (this.rightTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeRight(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.TOP:
				if (this.topTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeTop(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.BOTTOM:
				if (this.bottomTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeBottom(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.LEFT_TOP:
				if (this.leftTopTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeLeftTop(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.RIGHT_TOP:
				if (this.rightTopTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeRightTop(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.LEFT_BOTTOM:
				if (this.leftBottomTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeLeftBottom(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			case AreaResizeTrigger.RIGHT_BOTTOM:
				if (this.rightBottomTriggerReadyToVisualize(isVisualizePosition, dryConfiguration, lineEntity)) {
					lineEntity.line.show();
					this.magnetizeRightBottom(isMagnetizationZone, correctValues, dryConfiguration, lineEntity);
				} else {
					this.linesState.disabledLines.push(lineEntity.line);
				}
				break;
			default: throw new ManipulatorError('direction not found');
			}
			this.magneticLines.setIsMagnet(true);
		}

		this.mutator.setCorrectValues(correctValues);

		if (!this.magneticLines.getIsMagnet()) {
			this.mutator.clearCorrectValues();
		}
	};

	/**
	 * Вычисляет корректирующие значения для области намагничивания с левой стороны.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeLeft = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.LEFT) {
			correctValues.x = -(dryConfiguration.x - lineEntity.linePosition.x);
			correctValues.width = (dryConfiguration.x - lineEntity.linePosition.x);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии с левой стороны графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private leftTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null
			&& lineEntity.linePosition.x <= dryConfiguration.x
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_X
			&& lineEntity.line.getDirection() !== DirectionVector.TOP
			&& lineEntity.line.getDirection() !== DirectionVector.BOTTOM;

	/**
	 * Вычисляет корректирующие значения для области намагничивания с правой стороны.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeRight = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.RIGHT) {
			correctValues.width = (lineEntity.linePosition.x - dryConfiguration.x - dryConfiguration.width);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии с правой стороны графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private rightTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null
			&& lineEntity.linePosition.x > dryConfiguration.x + dryConfiguration.width
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_X;

	/**
	 * Вычисляет корректирующие значения для области намагничивания сверху.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeTop = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.TOP) {
			correctValues.y = -(dryConfiguration.y - lineEntity.linePosition.y);
			correctValues.height = (dryConfiguration.y - lineEntity.linePosition.y);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии сверху графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private topTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null
			&& lineEntity.linePosition.y < dryConfiguration.y
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_Y
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_X
			&& lineEntity.line.getDirection() !== DirectionVector.LEFT
			&& lineEntity.line.getDirection() !== DirectionVector.RIGHT;

	/**
	 * Вычисляет корректирующие значения для области намагничивания снизу.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeBottom = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.BOTTOM) {
			correctValues.height = (lineEntity.linePosition.y - dryConfiguration.y - dryConfiguration.height);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии снизу графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private bottomTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null
			&& lineEntity.linePosition.y > dryConfiguration.y + dryConfiguration.height
			&& lineEntity.line.getDirection() !== DirectionVector.LEFT
			&& lineEntity.line.getDirection() !== DirectionVector.RIGHT;

	/**
	 * Вычисляет корректирующие значения для области намагничивания левого верхнего угла.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeLeftTop = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.TOP) {
			correctValues.y = -(dryConfiguration.y - lineEntity.linePosition.y);
			correctValues.height = (dryConfiguration.y - lineEntity.linePosition.y);
		}
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.LEFT) {
			correctValues.x = -(dryConfiguration.x - lineEntity.linePosition.x);
			correctValues.width = (dryConfiguration.x - lineEntity.linePosition.x);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии рядом с левым верхним углом графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private leftTopTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null
			&& lineEntity.linePosition.y <= dryConfiguration.y
			&& lineEntity.linePosition.x <= dryConfiguration.x;

	/**
	 * Вычисляет корректирующие значения для области намагничивания правого верхнего угла.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeRightTop = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.TOP) {
			correctValues.y = -(dryConfiguration.y - lineEntity.linePosition.y);
			correctValues.height = (dryConfiguration.y - lineEntity.linePosition.y);
		}
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.RIGHT) {
			correctValues.width = (lineEntity.linePosition.x - dryConfiguration.x - dryConfiguration.width);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии рядом с правым верхним углом графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private rightTopTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => {
		if (isVisualizePosition !== null
			&& lineEntity.linePosition.x > dryConfiguration.x + dryConfiguration.width) {
			return true;
		} return isVisualizePosition !== null
			&& lineEntity.linePosition.y < dryConfiguration.y
			&& lineEntity.line.getDirection() === DirectionVector.TOP;
	};

	/**
	 * Вычисляет корректирующие значения для области намагничивания левого нижнего угла.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeLeftBottom = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.LEFT) {
			correctValues.x = -(dryConfiguration.x - lineEntity.linePosition.x);
			correctValues.width = (dryConfiguration.x - lineEntity.linePosition.x);
		}
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.BOTTOM) {
			correctValues.height = (lineEntity.linePosition.y - dryConfiguration.y - dryConfiguration.height);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии рядом с левым нижним углом графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private leftBottomTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => isVisualizePosition !== null && lineEntity.linePosition.x <= dryConfiguration.x;

	/**
	 * Вычисляет корректирующие значения для области намагничивания правого нижнего угла.
	 * @param isMagnetizationZone Находится ли полученная область в зоне притягивания графики к линии.
	 * @param correctValues Корректирующие значения для намагничивания.
	 * @param dryConfiguration Конфигурация графики, без учета примагничивания к линии.
	 * @param lineEntity Магнитная линия.
	 */
	private magnetizeRightBottom = (
		isMagnetizationZone: DirectionVector | null,
		correctValues: IFrameArea,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	) => {
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.BOTTOM) {
			correctValues.height = (lineEntity.linePosition.y - dryConfiguration.y - dryConfiguration.height);
		}
		if (isMagnetizationZone !== null && isMagnetizationZone === DirectionVector.RIGHT) {
			correctValues.width = (lineEntity.linePosition.x - dryConfiguration.x - dryConfiguration.width);
		}
	};

	/**
	 * Вычисляет готовность к визуализации магнитной линии рядом с правым нижним углом графики.
	 * @param isVisualizePosition Находится ли полученная область в зоне появления линии.
	 * @param frameConfiguration Конфигурация графики.
	 * @param lineEntity Магнитная линия.
	 * @returns Готовность к визуализации магнитной линии.
	 */
	private rightBottomTriggerReadyToVisualize = (
		isVisualizePosition: DirectionVector | null,
		dryConfiguration: IFrameArea,
		lineEntity: MagneticLineEntity,
	): boolean => {
		if (isVisualizePosition !== null
			&& lineEntity.linePosition.y >= dryConfiguration.y + dryConfiguration.height
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_Y) {
			return true;
		} return isVisualizePosition !== null
			&& lineEntity.linePosition.x >= dryConfiguration.x + dryConfiguration.width
			&& lineEntity.line.getDirection() !== DirectionVector.MIDDLE_X;
	};
}

export default MagneticSizeCorrector;
