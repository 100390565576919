import React from 'react';
import cx from 'classnames';
import { ReactComponent as WhitBtnPlus } from '../../../images/white-plus.svg';
import { MainButton } from '../../../../components/ui/main-button/main-button';
import css from './create.module.scss';

interface CreateButtonProps {
	styles?: string,
	disabled?: boolean,
	onClick: () => void,
	children: React.ReactNode,
}

export const CreateButton = ({
	styles,
	onClick,
	disabled,
	children,
}: CreateButtonProps) => (
	<MainButton
		type="button"
		onClick={onClick}
		btnStyle="bright"
		disabled={disabled}
		styles={cx(css.wrapper, styles)}
	>
		<div className={css.icon}>
			<WhitBtnPlus />
		</div>
		{children}
	</MainButton>
);
