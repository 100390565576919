class Backend {
	public static readonly ENVIRONMENT_DEV = 'dev';
	public static readonly ENVIRONMENT_TESTING = 'testing';
	public static readonly ENVIRONMENT_RELEASE = 'release';

	private imagePrefix = '/api/file/user/';

	/**
	 * Возвращает URI для получения изображения с сервера.
	 * @param source Идентификатор изображения.
	 */
	public getImageURI = (source: string): string => `${this.imagePrefix}${source}`;

	/**
	 * Возвращает URL для подключения к серверу по сокету.
	 */
	public getSocketConnectionString = (): string => {
		const env = process.env.REACT_APP_ENVIRONMENT_SERVER;
		if (env === undefined) {
			throw Error('react environment not initialized');
		}

		let socketProtocol = '';
		let domain = '';

		switch (env) {
		case Backend.ENVIRONMENT_DEV: {
			socketProtocol = 'ws';
			domain = 'localhost:25504';
			break;
		}
		case Backend.ENVIRONMENT_TESTING: {
			socketProtocol = 'wss';
			domain = window.location.hostname;
			break;
		}
		case Backend.ENVIRONMENT_RELEASE: {
			socketProtocol = 'wss';
			domain = window.location.hostname;
			break;
		}
		default: {
			throw new Error('invalid react environment');
		}
		}

		// return `${socketProtocol}://${domain}/api/sketch/ws`;
		return `${socketProtocol}://${domain}/api/ws`;
	};

	public static getDomainString = (): string => {
		const env = process.env.REACT_APP_ENVIRONMENT_SERVER;
		if (env === undefined) {
			throw Error('react environment not initialized');
		}

		let domain = '';

		switch (env) {
		case this.ENVIRONMENT_DEV: {
			domain = 'http://localhost:25504';
			break;
		}
		case this.ENVIRONMENT_TESTING: {
			domain = `https://${window.location.hostname}`;
			break;
		}
		case this.ENVIRONMENT_RELEASE: {
			domain = `https://${window.location.hostname}`;
			break;
		}
		default: {
			throw new Error('invalid react environment');
		}
		}

		return domain;
	};
}

export default Backend;
