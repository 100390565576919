import React from 'react';
import { ReactComponent as ArrowIcon } from '../../images/arrow-close-black.svg';
import css from './arrow-input.module.scss';

interface ArrowInputProps {
	onClick: () => void,
	children: React.ReactNode,
}

export const ArrowInput = ({
	children,
	onClick,
}: ArrowInputProps) => {
	const a = '';
	return (
		<div className={css.wrapper} role="presentation" onClick={onClick}>
			<div>{children}</div>
			<ArrowIcon />
		</div>
	);
};
