import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../shared/api/api';
import { URI_ROLE, URI_ROLE_UNBIND } from './consts';
import {
	IBindRoleRequest,
	ICreateRoleRequest,
	IGetRoleListResponse,
	IUnbindRoleRequest,
	IUpdateRoleRequest,
} from './types';
import { notificationError } from '../../../components/Notifications/callNotifcation';
import { setRolesList } from '../../../redux/reducers/permissionsReducer';
import { setRoles } from '../../../redux/reducers/usersReducer';

export const roleAPI = createApi({
	reducerPath: 'roleAPI',
	baseQuery,
	refetchOnReconnect: true,
	tagTypes: ['role'],
	endpoints: builder => ({
		getAll: builder.query<IGetRoleListResponse, void>({
			query: () => ({
				url: URI_ROLE,
				method: 'GET',
			}),
			providesTags: ['role'],
			async onQueryStarted(arg, {
				queryFulfilled,
				dispatch,
			}) {
				try {
					const res = await queryFulfilled;

					const { roles } = res.data;

					dispatch(setRolesList(roles));
					dispatch(setRoles(roles));
				} catch (error) {
					notificationError('Возникла ошибка при загрузке юр. лиц....');
				}
			},
		}),
		create: builder.mutation<{ id: string }, ICreateRoleRequest>({
			query: (data) => ({
				url: URI_ROLE,
				method: 'POST',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['role'],
		}),
		bind: builder.mutation<void, IBindRoleRequest>({
			query: (data) => ({
				url: URI_ROLE,
				method: 'PUT',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['role'],
		}),
		unbind: builder.mutation<void, IUnbindRoleRequest>({
			query: (data) => ({
				url: URI_ROLE_UNBIND,
				method: 'DELETE',
				body: {
					...data,
				},
			}),
			invalidatesTags: ['role'],
		}),
		update: builder.mutation<void, IUpdateRoleRequest>({
			query: ({
				id,
				...data
			}) => ({
				url: `${URI_ROLE}/${id}`,
				method: 'PUT',
				body: { ...data },
			}),
			invalidatesTags: ['role'],
		}),
		delete: builder.mutation<void, { id: string }>({
			query: ({ id }) => ({
				url: `${URI_ROLE}/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['role'],
		}),
	}),
});
