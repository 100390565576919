import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ModalTitle } from '../../../../../shared/ui';
import InputArrowItem
	from '../../../../../components/pages/features/moduleWindow/createInputArrow/inputArrowItem/InputArrowItem';
import { tariffMapData } from '../../../../../entities/tariff/model/data';
import { MainButton } from '../../../../../components/ui/main-button/main-button';
import { NewModal } from '../../../../../components/ui/modals/new-modal';
import {
	usePublishTariffSelected,
} from '../../../../workshop/templates/publish-to-gallery/libs/usePublishTariffSelected';
import { workshopModuleActions, workshopModuleSlice } from '../../../../../entities/workshop-module';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { tariffAPI } from '../../../../../entities/tariff/api/api';
import { usePublishWorkshopModule } from '../../../model/services/publish-module/usePublishWorkshopModule';
import css from './SelectPublishModuleTariff.module.scss';

export const SelectPublishModuleTariff = () => {
	const dispatch = useAppDispatch();

	const isActive = useSelector(workshopModuleSlice.selectors.getIsPublishingModuleModalActive);

	const { data: bitmap } = tariffAPI.useGetBitmapQuery();

	const { onPublishModule, requestData } = usePublishWorkshopModule();

	const {
		selectTariff,
		removeTariff,
		cleanTariffs,
		selectedTariffs,
		currentPermission,
	} = usePublishTariffSelected();

	const publishModal = async () => {
		await onPublishModule(currentPermission);
	};

	const handleModal = (value: boolean) => {
		dispatch(workshopModuleActions.setIsPublishingModuleModalActive(value));
	};

	useEffect(() => {
		if (!isActive) {
			cleanTariffs();
		}
	}, [isActive]);

	return (
		<NewModal
			active={isActive}
			styles={css.wrapper}
			closeModal={handleModal}
		>
			<ModalTitle>Выберите тарифы</ModalTitle>
			<div className={css.tariffs}>
				{
					bitmap?.tariffs.map(tariff => (
						<InputArrowItem
							key={tariff.tariff}
							id={tariff.tariff}
							isChoiced={selectedTariffs.includes(tariff)}
							onAddChoicedItem={() => selectTariff(tariff)}
							onRemoveChoicedItem={() => removeTariff(tariff)}
							name={tariffMapData.get(tariff.tariff) as string}
						/>
					))
				}
			</div>
			<MainButton
				type="button"
				btnStyle="bright"
				onClick={publishModal}
				disabled={requestData.isLoading || selectedTariffs.length === 0}
			>
				Опубликовать
			</MainButton>
		</NewModal>
	);
};
