import ParserState from './state';
import type { IParserOptions, Token } from './types';
import { TokenFormat, TokenType } from './types';
import newline from './newline';
import { defaultOptions } from './utils';
import { objectMerge } from '../utils/objectMerge';
import { createTree, type Tree } from './tree';
import { FontFamily, FontSize, TextAlign } from '../editor/types';
import parseParagraph from './paragraph';
import parseRedLine from './red-line';

/**
 * Парсит строку.
 * @param text
 * @param opt
 * @param startToken
 */
export default function parse(
	text: string,
	opt?: Partial<IParserOptions>,
	startToken: Token = {
		format: TokenFormat.None,
		type: TokenType.Text,
		fontFamily: FontFamily.Default,
		fontSize: FontSize.Pt12,
		color: '#000000',
		textAlign: TextAlign.LEFT,
		sticky: true,
		lineHeight: 1,
		value: '',
	},
): Token[] {
	const options: IParserOptions | undefined = objectMerge(defaultOptions, opt);
	if (options === undefined) throw new Error('parserOptions is undefined');
	/* Чтобы что-то распарсить нам нужен объект с состоянием */
	const state = new ParserState(text, options, 0, startToken);

	const { linkProtocols } = options;

	let protocols: Tree | undefined;
	if (linkProtocols) {
		protocols = Array.isArray(linkProtocols)
			? createTree(linkProtocols, true)
			: linkProtocols;
	}

	while (state.hasNext()) {
		parseParagraph(state)
		|| newline(state)
		|| parseRedLine(state)
		|| state.consumeText();
	}

	/* Сохраним накопленные токены */
	state.flushText(startToken);

	let { tokens } = state;
	if (!tokens.length) {
		tokens = [
			{
				format: TokenFormat.None,
				type: TokenType.Text,
				fontFamily: FontFamily.Default,
				fontSize: FontSize.Pt14,
				color: '#000000',
				textAlign: TextAlign.LEFT,
				sticky: true,
				lineHeight: 1,
				value: '',
			},
		];
	}

	return tokens;
}

export {
	normalize, getText, getLength, codePointAt,
} from './utils';

export type {
	IParserOptions, IEmoji,
	Token, ITokenCommand, ITokenHashTag, ITokenLink, ITokenMarkdown, ITokenMention, ITokenText, ITokenUserSticker,
} from './types';

export { TokenType, TokenFormat } from './types';
