import { useState } from 'react';
import { useSelector } from 'react-redux';
import { workshopModuleApi, workshopModuleSlice } from '../../../../entities/workshop-module';
import { useCreateWorkshopModuleCategory } from '../../services/useCreateWorkshopModuleCategory';
import { useBindWorkshopModuleCategory } from '../../services/useBindWorkshopModuleCategory';
import { ICategoryData } from '../../../../entities/modules/category/api/types';
import { SearchCategoryBar } from '../../../search-category-bar';

export const SearchWorkshopModuleCategoryBar = () => {
	const [search, setSearch] = useState('');

	const moduleId = useSelector(workshopModuleSlice.selectors.getBindingModuleId);

	const { data: availableCategoryData } = workshopModuleApi.useGetAvailableModuleCategoriesQuery({
		moduleId,
	});

	const { data: moduleData } = workshopModuleApi.useGetWorkshopModuleQuery({
		moduleId: moduleId || '',
	});

	const { onCreateCategory } = useCreateWorkshopModuleCategory();
	const { onBindCategory } = useBindWorkshopModuleCategory(moduleId);

	return (
		<SearchCategoryBar
			search={search}
			bindCategory={onBindCategory}
			createCategory={onCreateCategory}
			setSearch={setSearch}
			categories={availableCategoryData?.moduleCategories}
			templateCategories={(moduleData?.module?.categories || []) as ICategoryData[]}
		/>
	);
};
