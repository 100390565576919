import { isAutoLink, isCustomLink } from '../formatted-string/utils';
import type {
	ITokenCommand, ITokenHashTag, ITokenLink, ITokenMention, ITokenText, Token,
} from '../parser';
import { TokenFormat, TokenType } from '../parser';
import objectMerge from '../utils/objectMerge';
import {
	Model, TextAlign,
} from '../editor/types';
import ReconcileState, { IRenderOptions } from './ReconcileState';

const defaultOptions: IRenderOptions = {
	fixTrailingLine: false,
	link: getLink,
};

function getLink(token: Token): string {
	if (token.type === TokenType.HashTag) {
		return token.value;
	}

	if (token.type === TokenType.Link) {
		return token.link;
	}

	return '';
}
type ClassFormat = [type: TokenFormat, value: string]; // Для форматирования (жирный, ссылка и тд)

const formats: ClassFormat[] = [
	[TokenFormat.Bold, 'bold'],
	[TokenFormat.Italic, 'italic'],
	[TokenFormat.Monospace, 'monospace'],
	[TokenFormat.Strike, 'strike'],
	[TokenFormat.Underline, 'underline'],
	[TokenFormat.Heading, 'heading'],
	[TokenFormat.Marked, 'marked'],
	[TokenFormat.Highlight, 'highlight'],
	[TokenFormat.Link, 'md-link'],
	[TokenFormat.LinkLabel, 'md-link-label'],
];

const tokenTypeClass: Record<TokenType, string> = {
	[TokenType.Command]: 'command',
	[TokenType.HashTag]: 'hashtag',
	[TokenType.Link]: 'link',
	[TokenType.Markdown]: 'md',
	[TokenType.Mention]: 'mention',
	[TokenType.Text]: '',
	[TokenType.UserSticker]: 'user-sticker',
	[TokenType.Newline]: 'newline',
	[TokenType.Paragraph]: 'paragraph',
	[TokenType.RedLine]: 'red-line',
};

/**
 * Отрисовывает модель в DOM.
 * У нас есть один корневой div, например для текстового компонента это будет элемент с классом
 * page-frame__graphic-text.
 */
export default function render(elem: HTMLElement, model: Model, opt?: Partial<IRenderOptions>): void {
	const options: IRenderOptions = opt ? objectMerge(defaultOptions, opt) : defaultOptions;
	if (options.inline) {
		renderInline(elem, model.tokens, options);
		return;
	}

	const lineState = new ReconcileState(elem, options);

	/* Каждая строка будет оборачиваться в div */
	const line = () => lineState.elem('div');

	const state = new ReconcileState(line(), options);

	const finalizeLine = () => {
		if (state.pos === 0) {
			// Пустая строка, оставляем <br>, чтобы строка отобразилась
			state.elem('br');
		}
		state.trim();
	};

	// На случай непредвиденных модификаций дерева убедимся, что у первой строки
	// всегда отсутствует атрибут data-raw
	state.container.removeAttribute('data-raw');

	for (let i = 0; i < model.tokens.length; i++) {
		const token = model.tokens[i];

		if (token.type === TokenType.Newline) {
			/* Переход на новую строку - (по сути сам узел будет считаться при подсчете позиции -
			то есть учитываются дочерние узлы и символы */
			finalizeLine();
			state.prepare(line());
			state.container.setAttribute('data-raw', token.value);
		} else if (token.type === TokenType.Paragraph) {
			// Переход на новую строку
			finalizeLine();
			state.prepare(line());
			state.container.setAttribute('data-raw', token.value);
			state.container.classList.add(token.value);
		} else if (token.type === TokenType.RedLine) {
			renderTokens(model.tokens, i, state);
			state.container.setAttribute('data-raw', 't');
		} else {
			i = renderTokens(model.tokens, i, state);
			state.container.style.alignSelf = model.tokens[i].textAlign.toString();

			switch (model.tokens[i].textAlign) {
			case TextAlign.CENTER:
				state.container.style.textAlign = 'center';
				break;
			case TextAlign.LEFT:
				state.container.style.textAlign = 'left';
				break;
			case TextAlign.RIGHT:
				state.container.style.textAlign = 'right';
				break;
			case TextAlign.JUSTIFY:
				state.container.style.textAlign = 'center';
				break;
			}

			if (model.tokens[i].textAlign === 'stretch' && state.container.firstElementChild) {
				state.container.style.textAlign = 'justify';
				state.container.style.textAlignLast = 'justify';
			} else {
				state.container.style.textAlignLast = 'unset';
			}
		}
	}

	finalizeLine();
	lineState.trim();

	lineState.setFontSize(model.tokens[0].fontSize);
	lineState.setFontFamily(model.tokens[0].fontFamily);
}

function renderInline(elem: HTMLElement, tokens: Token[], options: IRenderOptions) {
	const state = new ReconcileState(elem, options);
	state.enter(state.elem('span'));
	for (let i = 0; i < tokens.length; i++) {
		i = renderTokens(tokens, i, state);
	}
	state.exit();
	state.trim();
}

/**
 * Отрисовывает переданный токен в DOM.
 * @param tokens - токены.
 * @param i - индекс токена для отрисовки.
 * @param state - текущий state.
 */
function renderTokens(tokens: Token[], i: number, state: ReconcileState): number {
	const token = tokens[i];
	if (!token.value) {
		// Скорее всего sticky-токен, пропускаем
		return i;
	}

	/* Найдем до какой позиции (индекс токена) мы можем объединить токены */
	const groupEnd: number = nextInGroup(tokens, i);
	if (groupEnd !== i) {
		// Можем схлопнуть несколько токенов в один
		const baseFormat = token.format;
		const baseFontFamily = token.fontFamily;
		const baseFontSize = token.fontSize;
		const baseColor = token.color;
		const baseLineHeight = token.lineHeight;
		state.enter(renderTokenContainer(token, state));

		while (i <= groupEnd) {
			const innerToken = tokens[i];
			if (
				innerToken.format === baseFormat
				|| innerToken.fontFamily === baseFontFamily
				|| innerToken.fontSize === baseFontSize
				|| innerToken.color === baseColor
				|| innerToken.lineHeight === baseLineHeight
			) {
				renderText(innerToken, state);
			} else {
				const innerElem = state.elem('span');
				innerElem.className = formatClassNames(innerToken.format);
				innerElem.style.fontFamily = innerToken.fontFamily;
				innerElem.style.fontSize = innerToken.fontSize;
				innerElem.style.color = innerToken.color;
				innerElem.style.lineHeight = innerToken.lineHeight.toString();
				renderTextToken(innerElem, innerToken, state);
			}
			i++;
		}

		state.exit();
		return groupEnd;
	}

	if (token.type === TokenType.Newline) {
		state.text(token.value);

		// Сделает без контейнера
		// } else if (isPlainText(token)) {
		// 	renderText(token, state);

		// Обернет в контейнер
	} else if (token.type === TokenType.RedLine) {
		// state.text(token.value);
		const elem = renderTokenContainer(token, state);
		renderTextToken(elem, token, state);
	} else {
		const elem = renderTokenContainer(token, state);
		if (token.type !== TokenType.UserSticker) {
			renderTextToken(elem, token, state);
		}
	}
	return i;
}

function renderTextToken(target: HTMLElement, token: Token, state: ReconcileState): void {
	state.enter(target);
	renderText(token, state);
	state.exit();
}

/**
 * Отрисовка текстового содержимого в указанном контейнере.
 */
function renderText(token: Token, state: ReconcileState): void {
	let { value } = token;
	// const { value } = token;
	const { options } = state;

	if (options.nowrap) {
		/* Для однострочных полей всегда заменяем пробельные символы на nbsp (иначе будет только один пробел,
		а остальные будут затираться */
		value = value
			// .replace(/\r\n?/g, '\n')
			// .replace(/\s/g, '\u00a0');
			.replace(/\r\n?/g, ' ')
			.replace(/\s/g, ' ');
	}

	state.text(value);
}

/**
 * Возвращает список классов форматирования для указанного формата токена
 */
function formatClassNames(format: TokenFormat): string {
	let result = '';
	let glue = ''; // для пробела между классами (первый без пробела).

	// Укажем классы с форматированием
	formats.forEach(([f, value]) => {
		if (format & f) { // Когда формат совпадает с переданным, тогда записываем.
			result += glue + value;
			glue = ' ';
		}
	});

	return result;
}

/**
 * Объединяет названия классов (которые лежат в массиве) в строку.
 */
function joinClassNames(classNames: string[]): string {
	let result = '';
	let glue = '';
	classNames.forEach(cl => {
		if (cl) {
			result += glue + cl;
			glue = ' ';
		}
	});

	return result;
}

/**
 * Возвращает позицию элемента, до которого можно сделать единую с элементом
 * в позиции `pos` группу. Используется, например, для того, чтобы сгруппировать
 * в единый `<a>` элемент ссылку с внутренним форматированием
 */
export function nextInGroup(tokens: Token[], pos: number): number {
	const token = tokens[pos];

	/* Проверим ссылки на токены и типы токенов на возможность объединения */
	while (pos < tokens.length - 1 && canGroup(token, tokens[pos + 1])) {
		pos++;
	}

	return pos;
}

/**
 * Вернёт `true`, если два токена можно сгруппировать в один
 */
function canGroup(t1: Token, t2: Token): boolean {
	// Сравним ссылки
	if (t1 === t2) {
		return true;
	}

	if (t1.type === t2.type) {
		return (t1.type === TokenType.Link && t1.link === (t2 as ITokenLink).link)
            || (t1.type === TokenType.Mention && t1.mention === (t2 as ITokenMention).mention)
            || (t1.type === TokenType.HashTag && t1.hashtag === (t2 as ITokenHashTag).hashtag);
	}

	return false;
}

/**
 * Возвращает контейнер для указанного токена
 */
function renderTokenContainer(token: Token, state: ReconcileState): HTMLElement {
	let elem: HTMLElement;
	// Ссылки рисуем только если нет моноширинного текста
	if (isRenderLink(token)) {
		elem = state.elem('a');
		elem.setAttribute('href', state.options.link(token));
		elem.setAttribute('target', '_blank');
		elem.addEventListener('mouseenter', onLinkEnter);
		elem.addEventListener('mouseleave', onLinkLeave);
	} else if (token.type === TokenType.Paragraph) {
		elem = state.elem('p');
	} else if (token.type === TokenType.RedLine) {
		elem = state.elem('span');
	} else {
		elem = state.elem('span');
	}

	elem.className = joinClassNames([
		getTokenTypeClass(token),
		formatClassNames(token.format),
	]);
	elem.style.fontFamily = token.fontFamily;
	elem.style.color = token.color;
	elem.style.fontSize = token.fontSize;
	elem.style.lineHeight = token.lineHeight.toString();
	return elem;
}

/**
 * Возвращает класс для указанного токена
 */
function getTokenTypeClass(token: Token): string {
	if (isAutoLink(token) && (token.format & TokenFormat.Monospace)) {
		return '';
	}

	if (isPrefixedToken(token) && token.value.length === 1) {
		return '';
	}

	if (isRenderLink(token)) {
		let { type } = token;
		if (isCustomLink(token) && token.link[0] === '@') {
			type = TokenType.Mention;
		}

		return type !== TokenType.Link ? `${tokenTypeClass.link} ${tokenTypeClass[type]}` : tokenTypeClass[type];
	}

	return tokenTypeClass[token.type];
}

/**
 * Если указанный токен является ссылкой, вернёт `true`, если его можно нарисовать
 * как ссылку
 */
export function isRenderLink(token: Token): boolean {
	if ((token.format & TokenFormat.Monospace)) {
		// Внутри моноширинного текста разрешаем только «ручные» ссылки либо
		// полные автоссылки (начинаются с протокола)
		return token.type === TokenType.Link && (!token.auto || /^[+a-z]+:\/\//i.test(token.value));
	}

	if (token.type === TokenType.Mention) {
		return token.value.length > 1;
	}

	return token.type === TokenType.Link;
}

function isPrefixedToken(token: Token): token is ITokenMention | ITokenCommand | ITokenHashTag {
	return token.type === TokenType.Mention
        || token.type === TokenType.Command
        || token.type === TokenType.HashTag;
}

export function isPlainText(token: Token): token is ITokenText {
	return token.type === TokenType.Text
		&& token.format === TokenFormat.None;
}

function onLinkEnter(evt: MouseEvent) {
	dispatch(evt.target as Element, 'linkenter');
}

function onLinkLeave(evt: MouseEvent) {
	dispatch(evt.target as Element, 'linkleave');
}

export function dispatch<T = unknown>(elem: Element, eventName: string, detail?: T): void {
	/* dispatchEvent Отправляет событие в общую систему событий. Это событие подчиняется тем же правилам поведения
	"Захвата" и "Всплывания" как и непосредственно инициированные события.
	 bubbles: true чтобы событие всплывало.
	 cancelable: true если мы хотим, чтобы event.preventDefault() работал.
	 Для генерации событий совершенно новых типов юзаем CustomEvent (если оно не браузерное, а пользовательское,
	 // то лучше использовать CustomEvent, чтобы явно об этом сказать.).
	 detail - тут можно указывать информацию для передачи в событие (все обработчики смогут получить к ней доступ
	 через event.detail.
	 */
	elem.dispatchEvent(new CustomEvent<T>(eventName, {
		bubbles: true,
		cancelable: true,
		detail,
	}));
}
