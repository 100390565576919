import ActionRepository from './ActionRepository';
import IManipulatorAction from './actions/IManipulatorAction';
import SketchStructureStabilizer from '../mechanics/mutation-observer/SketchStructureStabilizer';
import Dependent from '../utils/dependent/Dependent';

interface IActionStoreDependencies {
	sketchStabilizer: SketchStructureStabilizer,
}

/**
 * Хранилище последовательностей действий пользователя.
 */
class ActionStore extends Dependent<IActionStoreDependencies> {
	private readonly repository: ActionRepository;

	private readonly postUndoRedoListeners: VoidFunction[];

	constructor() {
		super();
		this.postUndoRedoListeners = [];
		this.repository = new ActionRepository();
	}

	public push = (action: IManipulatorAction) => {
		this.repository.push(action);
	};

	/**
	 * Отменяет последнее действие, возвращает состояние успеха отмены.
	 */
	public undo = () => {
		this.repository.undoAction(action => {
			this.dependencies.sketchStabilizer.startSystemAction();

			action.undo();

			const isChangesValid = this.dependencies.sketchStabilizer.stopSystemAction();
			if (isChangesValid) {
				this.callPostUndoRedoListeners();
			}

			const commands = action.getUndoCommands();
			this.dependencies.sketchStabilizer.sendCommands(commands);

			return true;
		});
	};

	/**
	 * Выполняет последнее действие, возвращает состояние успеха отмены.
	 */
	public redo = () => {
		this.repository.redoAction(action => {
			this.dependencies.sketchStabilizer.startSystemAction();

			action.redo();
			const isChangesValid = this.dependencies.sketchStabilizer.stopSystemAction();
			if (isChangesValid) {
				this.callPostUndoRedoListeners();
			}

			return true;
		});
	};

	public addPostUndoRedoListener = (listener: VoidFunction) => {
		this.postUndoRedoListeners.push(listener);
	};

	public getUndoCommandCount = (): number => this.repository.getUndoActionCount();
	public getRedoCommandCount = (): number => this.repository.getRedoActionCount();

	public addPostMoveTimeLineCursorEvent = (event: VoidFunction) => {
		this.repository.addPostMoveTimeLineCursorEvent(event);
	};

	private callPostUndoRedoListeners = () => {
		this.postUndoRedoListeners.forEach(listener => listener());
	};
}

export default ActionStore;
