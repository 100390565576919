import React, { useState } from 'react';
import CreateInputArrow from '../../../../../../../../features/moduleWindow/createInputArrow/CreateInputArrow';
import css from './select-move-team-content.module.scss';
import useCatchEnterClick from '../../../../../../../../../../hooks/useCatchEnterClick';
import { MainButton } from '../../../../../../../../../ui/main-button/main-button';
import { ITeamItem } from '../../../../../../../../../../entities/team/api/types';

interface SelectMoveTeamContentProps {
	isActive: boolean,
	movingCount: number,
	isDataLoading: boolean,
	onMoveTemplates: (teamIds: string[]) => void,
	teams: ITeamItem[] | undefined,
}

export const SelectMoveTeamContent = ({
	teams,
	isActive,
	movingCount,
	isDataLoading,
	onMoveTemplates,
}: SelectMoveTeamContentProps) => {
	const [selectedTeamsId, setSelectedTeamsId] = useState<string[]>([]);

	// * При перемещении шаблона
	const onMovingTemplate = () => {
		if (selectedTeamsId.length > 0) {
			onMoveTemplates(selectedTeamsId);
			setSelectedTeamsId([]);
		} else {
			console.log('Ошибка при перемещении шаблона!');
		}
	};

	useCatchEnterClick(onMovingTemplate, isActive);

	// * При добавлении элемента в список выбранных команд
	const onAddSelectedItems = (id: string) => {
		setSelectedTeamsId(selectedId => [...selectedId, id]);
	};

	// * При удалении элемента из списка выбранных команд
	const onRemoveSelectedItem = (id: string) => {
		setSelectedTeamsId(selectedId => selectedId.filter(el => el !== id));
	};

	return (
		<div className={css.wrapper}>
			<div className={css.title}>
				Подтверждение перемещения
			</div>
			<div className={css.description}>
				Выберите команду, в которую нужно перенести
				{' '}
				{movingCount > 1 ? 'шаблоны' : 'шаблон'}
			</div>
			<CreateInputArrow
				text={teams ? 'Команды' : 'Команд не найдено'}
				inputData={teams}
				choicedItemsId={selectedTeamsId}
				onAddChoicedItem={onAddSelectedItems}
				onRemoveChoicedItem={onRemoveSelectedItem}
			/>
			<div className={css.buttonBlock}>
				<MainButton
					type="button"
					onClick={onMovingTemplate}
					btnStyle="bright"
					styles={css.button}
					disabled={!isDataLoading && !(selectedTeamsId.length > 0)}
				>
					Переместить
				</MainButton>
			</div>
		</div>
	);
};
