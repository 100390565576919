import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { UserStatus } from '../../../../shared/ui';
import { ACCOUNT_STATUS } from '../../../../entities/user/account/api/types';
import { useOutSideClick } from '../../../../hooks/mouse/useNewOutSideClick';
import { popUpTransitionClasses } from '../../../../styles/transitionStyles/transitionStyles';
import css from './user-status-bar.module.scss';
import { changeStatusUserAbility } from '../model/data';
import { useUpdateStatus } from '../api/update-status';
import { useAppSelector } from '../../../../shared/libs/redux';

interface UserStatusBarProps {
	email: string,
	openable: boolean,
	userStatus: ACCOUNT_STATUS,
}

export const UserStatusBar = ({
	email,
	openable,
	userStatus,
}: UserStatusBarProps) => {
	const {
		status,
		email: currentUserEmail,
	} = useAppSelector(state => state.personalAccount.account);

	const [isOpen, setOpen] = useState(false);

	const ref = useOutSideClick(() => setOpen(false));

	const onOpen = () => {
		if (currentUserEmail !== email) setOpen(true);
	};

	const { onUpdateStatus } = useUpdateStatus();

	const updateStatus = async (status: ACCOUNT_STATUS) => {
		await onUpdateStatus(email, status);
		setOpen(false);
	};

	return (
		<div ref={ref}>
			<UserStatus
				isOpen={isOpen}
				type={userStatus}
				openable={openable}
				onClick={onOpen}
			/>
			<CSSTransition
				in={isOpen}
				classNames={popUpTransitionClasses}
				nodeRef={ref}
				timeout={200}
				unmountOnExit
			>
				<div
					className={css.change_status}
					ref={ref}
				>
					{
						changeStatusUserAbility.get(status)?.EMPLOYEE && (
							<UserStatus
								type={ACCOUNT_STATUS.EMPLOYEE}
								openable={false}
								onClick={updateStatus}
								isOpen={isOpen}
								styles={css.status}
							/>
						)
					}
					{
						changeStatusUserAbility.get(status)?.ADMIN && (
							<UserStatus
								type={ACCOUNT_STATUS.ADMIN}
								openable={false}
								onClick={updateStatus}
								isOpen={isOpen}
								styles={css.status}
							/>
						)
					}
					{
						changeStatusUserAbility.get(status)?.SUPER_ADMIN && (
							<UserStatus
								type={ACCOUNT_STATUS.SUPER_ADMIN}
								openable={false}
								onClick={updateStatus}
								isOpen={isOpen}
								styles={css.status}
							/>
						)
					}
					{
						changeStatusUserAbility.get(status)?.SERVICE_ADMIN && (
							<UserStatus
								type={ACCOUNT_STATUS.SERVICE_ADMIN}
								openable={false}
								onClick={updateStatus}
								isOpen={isOpen}
								styles={css.status}
							/>
						)
					}
				</div>
			</CSSTransition>
		</div>
	);
};
