import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../../../shared/api/api';
import { IUpdateStatus } from './types';

export const statusAPI = createApi({
	reducerPath: 'statusAPI',
	baseQuery,
	refetchOnReconnect: true,
	endpoints: builder => ({
		update: builder.mutation<void, IUpdateStatus>({
			query: ({
				email,
				status,
			}) => ({
				url: `account/${email}/status`,
				method: 'PUT',
				body: { status },
			}),
		}),
	}),
});
