import IAreaSizeMutable from '../../../mechanics/spatial-quadrants/area-mutators/IAreaSizeMutable';
import OnMutationEvent from '../../../mechanics/spatial-quadrants/area-mutators/events/OnMutationEvent';
import IFrameArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import TableComponent from '../../../components/table/TableComponent';
import { AnySpatialArea } from '../../../Types';

abstract class TableMutator implements IAreaSizeMutable {
	private readonly mutationListeners: OnMutationEvent[];

	protected readonly component: TableComponent;

	protected constructor(area: AnySpatialArea) {
		const { component } = area.getData();
		if (component === undefined) {
			throw new ManipulatorError('component not found');
		}

		this.mutationListeners = [];
		this.component = component;
	}

	public addMutationListener = (event: OnMutationEvent): void => {
		this.mutationListeners.push(event);
	};

	public getFrameArea = (): IFrameArea => ({
		x: 0,
		y: 0,
		width: 0,
		height: 0,
		rotate: 0,
	});

	public getMinWidth = (): number => 0;

	public getMinHeight = (): number => 0;
	public isMaintainAspectRatio = (): boolean => false;
	public getPostMutationEvents = (): OnMutationEvent[] => this.mutationListeners;

	public abstract mutateFrameArea: (fn: (prev: IFrameArea) => IFrameArea) => void;
}

export default TableMutator;
