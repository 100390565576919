import { IUserOrganizationItem } from '../../model/types';
import css from './organization-list.module.scss';

interface UserOrganizationInfoListProps {
	data: IUserOrganizationItem[];
}

export const UserOrganizationInfoList = ({ data }: UserOrganizationInfoListProps) => (
	<div className={css.wrapper}>
		{
			data.map(({
				name,
				id,
			}) => (
				<div className={css.item} key={id}>{name}</div>
			))
		}
	</div>
);
