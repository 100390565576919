import ImageGraphicBlock from './ImageGraphicBlock';
import PendingImageBlock from './state-blocks/pending/PendingImageBlock';
import LoadingPictureBlock from './state-blocks/loading/LoadingPictureBlock';
import ComponentAlignment from '../../../mechanics/component-alignment/ComponentAlignment';
import ImageTextureBlock from './state-blocks/texture/ImageTextureBlock';
import IImageTexture from './IImageTexture';
import ManipulatorError from '../../../utils/manipulator-error/ManipulatorError';
import IFrameConfiguration from '../../../frame/IFrameConfiguration';
import IComponent from '../../../components/IComponent';
import IDimensions2D from '../../../utils/IDimensions2D';
import SpatialImageResizeArea
	from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialImageResizeArea';
import AreaResizeTrigger from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/AreaResizeTrigger';
import IAreaSizeMutable from '../../../mechanics/spatial-quadrants/area-mutators/IAreaSizeMutable';
import IFrameArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/IFrameArea';
import OnMutationEvent from '../../../mechanics/spatial-quadrants/area-mutators/events/OnMutationEvent';
import SpatialImageResizeAngularArea
	from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/areas/SpatialImageResizeAngularArea';
import SpatialResizeArea from '../../../mechanics/spatial-quadrants/spatial-tree/spatial-area/SpatialResizeArea';
import IGraphic from '../../IGraphic';
import GraphicType from '../../GraphicType';
import PageGraphic from '../../page/PageGraphic';
import Dependent from '../../../utils/dependent/Dependent';

export interface IImageStateDependencies {
	graphic: IGraphic,
}

/**
 * Базовая сущность настройки состояния хранения и отображения изображений.
 */
abstract class ImageState<Dependencies extends IImageStateDependencies>
	extends Dependent<Dependencies>
	implements IAreaSizeMutable {
	private readonly ROOT_ELEMENT_CLASS_NAME = 'picture-block';
	private readonly DEFAULT_MIN_WIDTH = 40;
	private readonly DEFAULT_MIN_HEIGHT = 40;

	private readonly componentAlignment: ComponentAlignment;
	private readonly postMutationEvents: OnMutationEvent[];

	private readonly minWidth: number;
	private readonly minHeight: number;

	private readonly blocks: ImageGraphicBlock[];
	private pendingBlock: PendingImageBlock;
	private textureBlock: ImageTextureBlock;
	private loadingBlock: LoadingPictureBlock;

	protected constructor() {
		super();
		this.blocks = [];
		this.postMutationEvents = [];
		this.minWidth = this.DEFAULT_MIN_WIDTH;
		this.minHeight = this.DEFAULT_MIN_HEIGHT;
		this.componentAlignment = new ComponentAlignment();

		this.addPostInjectDependenciesListener(dependencies => {
			const graphicElement = dependencies.graphic.getGraphicElement();
			graphicElement.classList.add(this.ROOT_ELEMENT_CLASS_NAME);

			this.pendingBlock = new PendingImageBlock(dependencies.graphic);
			this.textureBlock = new ImageTextureBlock(dependencies.graphic);
			this.loadingBlock = new LoadingPictureBlock(dependencies.graphic);

			this.blocks.push(this.pendingBlock, this.textureBlock, this.loadingBlock);
		});
	}

	public enablePendingPicture = () => {
		this.disableBlocks();
		this.pendingBlock.enable();
	};

	public enableTextureBlock = (texture: IImageTexture) => {
		this.disableBlocks();
		this.textureBlock.setTexture(_ => texture);
		this.textureBlock.enable();
	};

	public enableLoadingBlock = () => {
		this.disableBlocks();
		this.loadingBlock.enable();
	};

	public getTexture = (): IImageTexture => {
		const texture = this.textureBlock.getTexture();
		return {
			...texture,
			y: Math.round(texture.y),
			x: Math.round(texture.x),
			height: Math.round(texture.height),
			width: Math.round(texture.width),
		};
	};

	public setLoadPictureEvent = (fn: VoidFunction) => {
		this.pendingBlock.setPlusElementClick(fn);
	};

	public enableEditMode = () => {
		const isPending = this.pendingBlock.isBlockEnabled();
		if (!isPending) {
			this.textureBlock.enableEditMode();
			return;
		}
		this.pendingBlock.enablePlus();
	};

	public disableEditMode = () => {
		const isPending = this.pendingBlock.isBlockEnabled();
		if (!isPending) {
			this.textureBlock.disableEditMode();
			return;
		}
		this.textureBlock.disableEditMode();
		this.pendingBlock.enablePicture();
	};

	public getFrameArea = (): IFrameArea => {
		const texture = this.textureBlock.getTexture();
		if (texture === null) {
			return {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
				rotate: 0,
			};
		}

		return texture;
	};

	/**
	 * Очищает состояние от изображения.
	 */
	public clear = () => {
		this.textureBlock.setTexture(_ => ({
			x: 0,
			y: 0,
			width: 0,
			height: 0,
			rotate: 0,
			source: null,
			radius: {
				topLeft: 0,
				topRight: 0,
				bottomLeft: 0,
				bottomRight: 0,
			},
		}));
	};

	public addMutationListener = (event: OnMutationEvent): void => {
		this.postMutationEvents.push(event);
	};

	public getPostMutationEvents = (): OnMutationEvent[] => [...this.postMutationEvents];

	public mutateFrameArea = (fn: (prev: IFrameArea) => IFrameArea): void => {
		const currentFrameArea = this.getTexture();
		if (currentFrameArea === null) {
			const updatedFrameArea = fn({
				x: 0,
				y: 0,
				width: 0,
				height: 0,
				rotate: 0,
			});
			this.textureBlock.setTexture(_ => ({
				...updatedFrameArea,
				source: '',
				radius: {
					topLeft: 0,
					topRight: 0,
					bottomLeft: 0,
					bottomRight: 0,
				},
			}));
			return;
		}

		const updatedFrameArea = fn(currentFrameArea);
		this.textureBlock.setTexture(prev => ({
			...prev,
			...updatedFrameArea,
		}));
	};

	public getResizeAreas = (): SpatialResizeArea<any>[] => [
		new SpatialImageResizeArea(this.dependencies.graphic, this, AreaResizeTrigger.LEFT),
		new SpatialImageResizeArea(this.dependencies.graphic, this, AreaResizeTrigger.TOP),
		new SpatialImageResizeArea(this.dependencies.graphic, this, AreaResizeTrigger.RIGHT),
		new SpatialImageResizeArea(this.dependencies.graphic, this, AreaResizeTrigger.BOTTOM),
		new SpatialImageResizeAngularArea(this.dependencies.graphic, this, AreaResizeTrigger.LEFT_TOP),
		new SpatialImageResizeAngularArea(this.dependencies.graphic, this, AreaResizeTrigger.RIGHT_TOP),
		new SpatialImageResizeAngularArea(this.dependencies.graphic, this, AreaResizeTrigger.RIGHT_BOTTOM),
		new SpatialImageResizeAngularArea(this.dependencies.graphic, this, AreaResizeTrigger.LEFT_BOTTOM),
	];

	/**
	 * Адаптирует изображение под размеры фрейма.
	 * @param frameConfiguration - конфигурация фрейма
	 * @param frameAspectRatio - соотношение сторон фрейма
	 */
	public adaptPicture = (frameConfiguration: IFrameConfiguration, frameAspectRatio: number) => {
		const pictureAspectRatio = this.textureBlock.getPictureNaturalAspectRatio();
		const naturalSize = this.textureBlock.getNaturalPictureSize();

		let texture = this.textureBlock.getTexture();
		if (frameAspectRatio < pictureAspectRatio) {
			texture = this.placePictureAtHeight(texture, naturalSize, frameConfiguration);
			texture = this.placePictureHorizontalCenter(texture, frameConfiguration);
		} else {
			texture = this.placePictureAtWidth(texture, naturalSize, frameConfiguration);
			texture = this.placePictureVerticalCenter(texture, frameConfiguration);
		}

		this.textureBlock.setTexture(_ => texture!);
	};

	/**
	 * Адаптирует размер фрейма под изображение, при этом, при необходимости, занимая
	 * все свободное (учитывая отступы страницы, если графика первая на странице) пространство.
	 * @param pictureGraphic - графика изображения
	 * @param pictureComponent - компонент изображения
	 */
	public adaptFrame = (pictureGraphic: IGraphic, pictureComponent: IComponent) => {
		const currentTexture = this.textureBlock.getTexture();
		if (currentTexture === null) {
			throw new ManipulatorError('texture is null');
		}
		let updatedTexture: IImageTexture = {
			...currentTexture,
			y: 0,
			x: 0,
		};

		const parentGraphic = pictureGraphic.getParentGraphic();
		if (parentGraphic === null) {
			throw new ManipulatorError('parent graphic is null');
		}

		let availableWidthFromPosition;
		let availableWidthFromFrame;
		const naturalSize = this.textureBlock.getNaturalPictureSize();
		if (parentGraphic.type === GraphicType.PAGE) {
			const pageGraphic = parentGraphic as PageGraphic;

			const pageTexture = pageGraphic.getTexture();
			const pageWidth = pageGraphic.getRealWidth();
			const graphicConfiguration = pictureGraphic.getFrameConfiguration();

			availableWidthFromFrame = pageGraphic.getAvailableSpaceX();
			availableWidthFromPosition = pageWidth - graphicConfiguration.x - pageTexture.paddingRight;
		} else {
			const { width } = parentGraphic.getFrameConfiguration();
			availableWidthFromFrame = width;
			availableWidthFromPosition = width;
		}

		if (naturalSize.width > availableWidthFromPosition) {
			this.componentAlignment.alignLeft(pictureComponent);
			if (naturalSize.width > availableWidthFromFrame) {
				updatedTexture = this.alignAndFillAvailableSpace(
					updatedTexture,
					availableWidthFromFrame,
					pictureGraphic,
				);
				this.textureBlock.setTexture(_ => updatedTexture);
				return;
			}
		}

		updatedTexture = this.adaptFromNaturalSize(updatedTexture, pictureGraphic, naturalSize);
		this.textureBlock.setTexture(_ => updatedTexture);
	};

	public addPostLoadPictureListener = (listener: () => void) => {
		this.textureBlock.addPostLoadPictureListener(listener);
	};

	public hasPicture = (): boolean => {
		const texture = this.textureBlock.getTexture();
		return texture.source !== null;
	};

	public getPictureAspectRatio = (): number | null => {
		const isTextureBlockEnable = this.textureBlock.isBlockEnabled();
		if (!isTextureBlockEnable) {
			return null;
		}
		return this.textureBlock.getPictureNaturalAspectRatio();
	};

	public disableBlocks = () => {
		this.blocks.forEach(block => block.disable());
	};

	public getMinWidth = (): number => this.minWidth;
	public getMinHeight = (): number => this.minHeight;
	public isMaintainAspectRatio = (): boolean => true;

	private placePictureAtHeight = (
		texture: IImageTexture,
		pictureSize: IDimensions2D,
		frame: IFrameConfiguration,
	): IImageTexture => {
		const multiplier = frame.height / pictureSize.height;
		const height = pictureSize.height * multiplier;
		const width = pictureSize.width * multiplier;

		return {
			...texture,
			width,
			height,
		};
	};

	private placePictureHorizontalCenter = (
		texture: IImageTexture,
		frame: IFrameConfiguration,
	): IImageTexture => {
		const x = (frame.width - texture.width) / 2;
		return {
			...texture,
			x,
			y: 0,
		};
	};

	private placePictureAtWidth = (
		texture: IImageTexture,
		pictureSize: IDimensions2D,
		frame: IFrameConfiguration,
	): IImageTexture => {
		const multiplier = frame.width / pictureSize.width;
		const height = pictureSize.height * multiplier;
		const width = pictureSize.width * multiplier;

		return {
			...texture,
			width,
			height,
		};
	};

	private placePictureVerticalCenter = (
		texture: IImageTexture,
		frame: IFrameConfiguration,
	): IImageTexture => {
		const y = (frame.height - texture.height) / 2;
		return {
			...texture,
			y,
			x: 0,
		};
	};

	private alignAndFillAvailableSpace = (
		texture: IImageTexture,
		availableWidthFromPage: number,
		pictureGraphic: IGraphic,
	): IImageTexture => {
		const pictureAspectRatio = this.textureBlock.getPictureNaturalAspectRatio();
		const pictureWidth = availableWidthFromPage;
		const pictureHeight = availableWidthFromPage / pictureAspectRatio;

		pictureGraphic.setFrameConfiguration(prev => ({
			...prev,
			width: pictureWidth,
			height: pictureHeight,
		}));

		return {
			...texture,
			width: pictureWidth,
			height: pictureHeight,
		};
	};

	private adaptFromNaturalSize = (
		texture: IImageTexture,
		pictureGraphic: IGraphic,
		naturalSize: IDimensions2D,
	): IImageTexture => {
		pictureGraphic.setFrameConfiguration(prev => ({
			...prev,
			width: naturalSize.width,
			height: naturalSize.height,
		}));
		return {
			...texture,
			width: naturalSize.width,
			height: naturalSize.height,
		};
	};
}

export default ImageState;
