import savePDF from './savePDF';
import { AnyComponentStructure } from '../../Sketch/Types';
import { notifyDownloadError, notifyDownloadStarted, notifyDownloadSuccess } from './PDFNotifications';

const savePDFWithNotifications = async (structure: AnyComponentStructure, name: string): Promise<void> => {
	notifyDownloadStarted();

	try {
		await savePDF(structure, name);
		notifyDownloadSuccess();
	} catch {
		notifyDownloadError();
	}
};

export default savePDFWithNotifications;
