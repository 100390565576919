import { CSSTransition } from 'react-transition-group';
import React, { useRef } from 'react';
import cx from 'classnames';
import { dropDownTransitionClasses } from '../../../styles/transitionStyles/transitionStyles';
import css from './tag-dropdown-wrapper.module.scss';

interface TagDropdownWrapperProps {
	styles?: string,
	children: React.ReactNode,
	isDropdownVisible: boolean,
}

export const TagDropdownWrapper = ({
	styles,
	children,
	isDropdownVisible,
}: TagDropdownWrapperProps) => {
	const dropdownRef = useRef<HTMLDivElement>(null);

	return (
		<CSSTransition
			in={isDropdownVisible}
			classNames={dropDownTransitionClasses}
			nodeRef={dropdownRef}
			timeout={200}
			unmountOnExit
		>
			<div ref={dropdownRef} className={cx(css.dropdown_wrapper, styles)}>
				<div className={css.dropdown}>
					{children}
				</div>
			</div>
		</CSSTransition>
	);
};
