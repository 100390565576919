import HTMLGenerator from '../../../../utils/HTMLGenerator';
import InterfacePanel from '../../../InterfacePanel';
import SvgCollection from '../../../../utils/SvgCollection';
import Utils from '../../../../utils/impl/Utils';
import IBaseUseCases from '../../../../use-cases/base/IBaseUseCases';

class LayerPanel extends InterfacePanel {
	private readonly moveBackElement: HTMLElement;
	private readonly moveForwardElement: HTMLElement;
	private readonly moveForegroundElement: HTMLElement;
	private readonly moveBackgroundElement: HTMLElement;
	private isShow: boolean;

	constructor(
		private readonly useCases: IBaseUseCases,
	) {
		super();

		this.setRootElementClassName('layers-panel');

		this.moveForwardElement = this.createOption(
			SvgCollection.moveForward,
			'Переместить вперед',
			this.onMoveForwardClick,
		);

		this.moveBackElement = this.createOption(
			SvgCollection.moveBack,
			'Переместить назад',
			this.onMoveBackClick,
		);

		this.moveForegroundElement = this.createOption(
			SvgCollection.moveForeground,
			'На передний план',
			this.onMoveForegroundClick,
		);

		this.moveBackgroundElement = this.createOption(
			SvgCollection.moveBackground,
			'На задний план',
			this.onMoveBackgroundClick,
		);

		this.rootElement.append(
			this.moveForwardElement,
			this.moveBackElement,
			this.moveForegroundElement,
			this.moveBackgroundElement,
		);

		this.isShow = false;
	}

	private createOption = (icon: SvgCollection, text: string, onClick: VoidFunction): HTMLElement => {
		const row = HTMLGenerator.getDiv({
			className: 'layers-panel__row',
			fnClick: onClick,
		});
		const textElement = HTMLGenerator.getSpan({
			className: 'layers-panel__row-text',
			text,
		});
		Utils.DOM.injectSVG(row, icon);
		row.append(textElement);
		return row;
	};

	private onMoveBackClick = () => {
		this.useCases.moveBackFocusComponents();
	};

	private onMoveForwardClick = () => {
		this.useCases.moveForwardFocusComponents();
	};

	private onMoveForegroundClick = () => {
		this.useCases.moveToForegroundFocusComponents();
	};

	private onMoveBackgroundClick = () => {
		this.useCases.moveToBackgroundFocusComponents();
	};
}

export default LayerPanel;
