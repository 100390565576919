import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TRedirectButtonData } from '../types/redirect-button';

export const useRedirectButton = (defaultLink: string, data: TRedirectButtonData) => {
	const { pathname } = useLocation();

	const [link, setLink] = useState(defaultLink);

	const handleLink = () => {
		if (link !== defaultLink) setLink(defaultLink);
		data.forEach(({
			redirectLink,
			currentURL,
		}) => {
			if (pathname.includes(currentURL)) setLink(redirectLink);
		});
	};

	useEffect(() => {
		handleLink();
	}, [pathname]);

	const onClick = () => window.open(link, '_blank');

	return { onClick };
};
