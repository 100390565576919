import { useState } from 'react';
import { useValidation, validation } from './useValidation';

export type inputTypes = 'organization' | 'name' | 'email' | 'phone' | 'code' | 'password' | 'birthday' | 'date'

export interface useInputValue {
    isEmpty: boolean;
    minLengthError: boolean;
    maxLengthError: boolean;
    emailError: boolean;
    phoneError: boolean;
    isCodeRight: boolean;
    inputValid: boolean;
    value: string;
    onChange: (e: any, type: inputTypes) => void;
    onBlur: () => void;
    cleanInput: () => void;
}

// * Очищает каждый инпут из массива
export const cleanInputValues = (props: useInputValue[]) => {
	props.forEach((item) => {
		item.cleanInput();
	});
};

export const useInput = (initialValue: string, validations?: validation) => {
	const [value, setValue] = useState(initialValue);
	const valid = useValidation(value, validations);

	const cleanInput = () => {
		setValue('');
	};

	const onChange = (e: any, type: inputTypes) => {
		const newValue = type === 'birthday' ? e : e.target.value.replaceAll('  ', ' ');
		switch (type) {
		case 'organization':
			if (newValue.length <= 255) {
				setValue(newValue);
			}
			break;
		case 'name':
			if (newValue.length <= 255) {
				setValue(newValue);
			}
			break;
		case 'email':
			if (newValue.length <= 40) {
				setValue(newValue);
			}
			break;
		case 'phone':
			if (newValue.length <= 20) {
				setValue(newValue);
			}
			break;
		case 'code':
			if (newValue.length <= 6) {
				setValue(newValue);
			}
			break;

		case 'password':
			if (newValue.length <= 25) {
				setValue(newValue);
			}
			break;

		case 'birthday':
			setValue(newValue);
			break;

		case 'date':
			if (newValue.length <= 20) {
				setValue(newValue);
			}
			break;

		default:
			break;
		}
	};

	const onBlur = () => {
		setValue(value.trim());
	};

	return {
		value,
		onChange,
		onBlur,
		cleanInput,
		...valid,
	};
};
