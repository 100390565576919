import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import Loader from 'react-ts-loaders';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ModalWrapper } from '../../modal-wrapper/modal-wrapper';
import { useAppDispatch, useAppSelector } from '../../../../../shared/libs/redux';
import {
	getGreetingsResponses,
	getIsWorkStagesOpen, getWorkStagesResponses,
	setIsAiCategoriesModalOpen,
	setWorkStagesOpen, setWorkStagesResponses,
} from '../../../model/slice/aiSlice';
import css from './css.module.scss';
import { ReactComponent as AiBtnIcon } from '../../../../../shared/images/ai.svg';
import { symbolsCountSelectOptions } from '../../../model/symbolsCountSelectOptions';
import { AiSelect, IOption } from '../../ai-select/AiSelect';
import { getSocket } from '../../../../websocket/model/slice';
import { messageQueue } from '../../../../websocket/model/webSocketMiddleware';

// Данные формы
type FormData = {
	titleStages: string,
	numCharacters: string,
};

export const WorkStagesModal: FC = () => {
	const dispatch = useAppDispatch();
	const {
		register,
		handleSubmit,
		formState,
		formState: { errors, isSubmitSuccessful },
		reset,
	} = useForm<FormData>();

	// States
	const [isLoading, setIsLoading] = useState(false);
	const [symbolsCount, setSymbolsCount] = useState<IOption>({
		title: 'По умолчанию',
		value: '3000',
	});

	const active = useAppSelector(getIsWorkStagesOpen);
	const responses = useAppSelector(getWorkStagesResponses);
	const socket = useAppSelector(getSocket);

	// Обнулить значения полей
	const onResetForm = () => {
		reset({ titleStages: '', numCharacters: '' });
		setSymbolsCount({
			title: 'По умолчанию',
			value: '3000',
		});
	};

	const onClose = () => {
		dispatch(setWorkStagesOpen(false));
	};

	const onGoBack = () => {
		dispatch(setWorkStagesOpen(false));
		dispatch(setIsAiCategoriesModalOpen(true));
	};

	const onSubmit: SubmitHandler<FormData> = (data) => {
		setIsLoading(true);
		const wsData = JSON.stringify({
			type: 'ai.generator.text.stages_work',
			body: JSON.stringify({
				...data,
				numCharacters: symbolsCount.value,
			}),
			id: uuidv4(),
		});

		if	(socket?.readyState === WebSocket.OPEN) {
			socket.send(wsData);
		} else {
			messageQueue.enqueue(wsData);
		}
	};

	useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const message = JSON.parse(event.data);
			if (message.type === 'ai.generator.text.stages_work' && message.status === 'ok') {
				setIsLoading(false);
				dispatch(setWorkStagesResponses(message.body));
				// onResetForm();
			} else if (message.type === 'ai.generator.text.stages_work' && message.status === 'error') {
				setIsLoading(false);
			}
		};

		if (socket) {
			socket.addEventListener('message', handleMessage);
		}

		return () => {
			if (socket) {
				socket.removeEventListener('message', handleMessage);
			}
		};
	}, [formState, reset, responses]);

	return (
		<ModalWrapper
			active={active}
			closeModal={onClose}
			onGoBack={onGoBack}
			title="Этапы работы"
		>
			<form className="ai-form" onSubmit={handleSubmit(onSubmit)}>
				<label htmlFor="titleStages" className="ai-form_label">
					Название этапов/перечень
					<textarea
						id="titleStages"
						{...register('titleStages', { required: true })}
						placeholder="Подготовка, разработка макета..."
					/>
					{errors.titleStages && (
						<span className="error" role="alert">
							Это поле обязательно для заполнения
						</span>
					)}
				</label>

				<div className={css.selectWrapper}>
					<AiSelect
						title="Количество символов"
						options={symbolsCountSelectOptions}
						option={symbolsCount}
						onSelect={setSymbolsCount}
					/>
				</div>

				<button className="aiSubmit" type="submit" disabled={isLoading}>
					<span className="aiSubmit_btn">Написать</span>
					<AiBtnIcon
						className={classNames('aiSubmit_icon', {
							'ai-modal-disabled': isLoading === true,
						})}
					/>
				</button>
			</form>

			{(isLoading && responses.length == 0) && (
				<Loader
					type="roller"
					color="rgba(57, 150, 218, 1)"
					size={100}
					className="ai-modal-loader"
				/>
			)}

			{responses.length >= 1 && (
				<div className="responsesWrapper">
					{isLoading
						&& (
							<Loader
								type="roller"
								color="rgba(57, 150, 218, 1)"
								size={100}
								className="ai-modal-loader"
							/>
						)}
					<div>
						{responses.map((data) => (
							<div key={uuid()} className="ai-response">
								{data}
							</div>
						))}
					</div>
				</div>
			)}

		</ModalWrapper>
	);
};
