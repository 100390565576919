import { workshopTemplateAPI } from '../../../../../entities/templates/workshop/template/api/api';
import { notificationError, notificationSuccess } from '../../../../../components/Notifications/callNotifcation';
import { useAppDispatch } from '../../../../../shared/libs/redux';
import { galleryAPI } from '../../../../../entities/templates/gallery/api/api';

export const usePublishWorkshopTemplate = () => {
	const dispatch = useAppDispatch();

	const [duplicate, { isLoading }] = workshopTemplateAPI.usePublishToGalleryMutation();

	const onDuplicate = async (template: string, tariffPermission: number) => {
		try {
			await duplicate({
				template,
				tariffPermission,
			})
				.unwrap();
			
			dispatch(galleryAPI.util?.invalidateTags(['gallery']));
			notificationSuccess('Шаблон успешно опубликован в галереи!');
		} catch (e) {
			notificationError('Произошла ошибка при публикации шаблона...');
		}
	};

	return {
		isLoading,
		onDuplicate,
	};
};
