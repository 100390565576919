import MultiPageManipulator from './MultiPageManipulator';
import ComponentAlignment from '../Sketch/mechanics/component-alignment/ComponentAlignment';
import DynamicGraphicFactory from '../Sketch/factories/graphic/DynamicGraphicFactory';
import IMutablePagesComponentTree from '../Sketch/component-tree/IMutablePagesComponentTree';
import DynamicComponentFactory from '../Sketch/factories/component/DynamicComponentFactory';
import PageScaleListener from '../Sketch/interface/bars/tool-bar/panel-zoom/PageScaleListener';
import DesktopInterface from '../Sketch/interface/DesktopInterface';

/**
 * Реализация манипулятора скетча со всеми возможностями изменения структуры скетча учитывая многостраничную структуру.
 */
abstract class DynamicMultiPageManipulator<UseCasesType, SpatialAreaTreeType>
	extends MultiPageManipulator<
		IMutablePagesComponentTree,
		DynamicGraphicFactory,
		DynamicComponentFactory,
		UseCasesType,
		SpatialAreaTreeType
	> {
	protected readonly componentAlignment: ComponentAlignment;

	protected interface: DesktopInterface<any>;

	protected constructor(manipulatorContainer: HTMLDivElement) {
		super(manipulatorContainer);
		this.componentFactory = new DynamicComponentFactory();
		this.componentAlignment = new ComponentAlignment();

		PageScaleListener.start();
		this.addDestructListener(PageScaleListener.stop);
	}

	public enableAIModalMode = () => {
		const pagesContainer = this.componentTree.getPagesContainer();
		pagesContainer.enableRightMagneticMode();
		this.interface.hidePropertiesBar();
	};

	public disableAIModalMode = () => {
		const pagesContainer = this.componentTree.getPagesContainer();
		pagesContainer.disableRightMagneticMode();
		this.interface.showPropertiesBar();
	};
}

export default DynamicMultiPageManipulator;
