import { useState, useEffect, useCallback } from 'react';
import { adminAPI } from '../../entities/admin/api/api';
import { IAdminUsersFilterItem } from '../../components/pages/admin-panel/users/models/filter-data';
import { IAdminAccountsItem } from '../../entities/admin/api/types';

const ITEM_LIMIT_PER_PAGE = 40;
const PRELOAD_THRESHOLD = 400;

export const useAdminUsersList = (search: string, filter: IAdminUsersFilterItem) => {
	const [users, setUsers] = useState<IAdminAccountsItem[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [page, setPage] = useState(1);

	const [getAccounts, { data, isFetching }] = adminAPI.useLazyGetAccountsQuery();

	const fetchAccounts = useCallback(() => {
		getAccounts({
			search,
			page,
			limit: ITEM_LIMIT_PER_PAGE,
			filterBy: filter.id,
		});
	}, [search, page, filter.id, getAccounts]);

	useEffect(() => {
		setUsers([]);
		setPage(1);
		setHasMore(true);
	}, [search, filter]);

	useEffect(() => {
		fetchAccounts();
	}, [search, filter, page, fetchAccounts]);

	useEffect(() => {
		if (data?.accounts) {
			setUsers((prevUsers) => (page === 1 ? data.accounts : [...prevUsers, ...data.accounts]));
			if (data.accounts.length < ITEM_LIMIT_PER_PAGE) {
				setHasMore(false);
			}
		}
	}, [data, page]);

    const loadMoreOnScroll = useCallback(() => {
        const { scrollTop, scrollHeight, clientHeight } = document.documentElement;

        if (scrollTop + clientHeight >= scrollHeight - PRELOAD_THRESHOLD && hasMore && !isFetching) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [hasMore, isFetching]);

	useEffect(() => {
		window.addEventListener('scroll', loadMoreOnScroll);
		return () => window.removeEventListener('scroll', loadMoreOnScroll);
	}, [loadMoreOnScroll]);

	return { users, hasMore, isFetching };
};
