import React from 'react';
import Logo from '../logo/Logo';
import css from '../registration.module.scss';
import InputField from '../InputField/InputField';
import { useInputValue } from '../../../../hooks/useInput';
import { MainButton } from '../../../ui/main-button/main-button';

interface PasswordRegistrationProps {
	isPasswordHide: boolean,
	handleKeyPressSendPassword: (e: React.KeyboardEvent<HTMLInputElement>) => void,
	password: useInputValue,
	isDataLoading: boolean,
	handlePasswordVisibility: () => void,
	onSignIn: () => void
}

const PasswordRegistration = ({
	handleKeyPressSendPassword,
	isPasswordHide,
	password,
	isDataLoading,
	handlePasswordVisibility,
	onSignIn,
}: PasswordRegistrationProps) => (
	<div className={css.wrapper}>
		<Logo isPasswordSent={false} />
		<h2 className={css.title}>
			Проверьте почту, мы
			<br />
			{' '}
			отправили Вам пароль
		</h2>
		<div className={`${css.descr} ${css.passwordDescr}`}>
			Для завершения регистрации нужно ввести
			<br />
			{' '}
			пароль из письма на почте
		</div>
		<InputField
			placeholder="Введите пароль из письма"
			type={isPasswordHide ? 'hidePassword' : 'visiblePassword'}
			status="clear"
			keyDown={e => handleKeyPressSendPassword(e)}
			value={password.value}
			onChange={e => password.onChange(e, 'password')}
			onBlur={password.onBlur}
			disabled={isDataLoading}
			signClick={handlePasswordVisibility}
		/>
		<MainButton
			type="button"
			btnStyle="bright"
			onClick={onSignIn}
			styles={css.button}
			disabled={isDataLoading || !password.inputValid}
		>
			Зарегистрироваться
		</MainButton>
	</div>
);

export default PasswordRegistration;
